import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { t } from 'i18next';

import { ReactGridDataType, ReactGridFileDataType } from '@/components/ReactGrid/ReactGridTypes';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import TileRenderer from '@/partials/TileRenderers/TileRendererTemplate';
import { BasicTileType, GenericWidgetDataType } from '@/partials/TileRenderers/TileRendererTypes';
import loadData from '@/scripts/ReadInitialData';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { MockDashboardData } from '@/mocks/Dashboards/DashboardData';

import { WidgetTileTemplateType } from './WidgetTileTypes';

import styles from './WidgetTile.module.css';

const unknownWidget: ReactGridDataType = {
  type: 'other',
  title: 'Unknown Widget',
  data: [],
};

const WidgetTileFC: React.FC<WidgetTileTemplateType> = (props: WidgetTileTemplateType): React.ReactElement => {
  const { item, dataId, record, isEditMode, onRemoveItem, widgetMappings } = props;
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage } = systemMessageStore;
  const { isDeveloperMode } = appSettingsStore;

  const defaultPanelData: ReactGridFileDataType = {};
  const [panelData, setPanelData] = React.useState(defaultPanelData);

  const dashboardDataSuccess = 'dashboard-data-success';
  const dashboardDataFail = 'dashboard-data-fail';
  const successGroupId = 'cosmos-initial-success';
  const failGroupId = 'cosmos-initial-fail';
  const namespace = NS.COSMOS;

  React.useEffect(() => {
    loadData()
      .then((result: any) => {
        setPanelData(JSON.parse(result).MOCK_DASHBOARD_DATA);

        const successMessage: SystemMessageType = {
          message: dashboardDataSuccess,
          type: FluentMessageBarType.success,
          groupId: successGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(successMessage);
          console.log(t(dashboardDataSuccess, { ns: namespace }));
        }
      })
      .catch(() => {
        setPanelData(MockDashboardData);

        const errorMessage: SystemMessageType = {
          message: dashboardDataFail,
          type: FluentMessageBarType.error,
          groupId: failGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(errorMessage);
          console.log(t(dashboardDataFail, { ns: namespace }));
        }
      });
  }, []);

  const widgetTileId: string = widgetMappings[dataId as string];
  const libraryWidget: ReactGridDataType = panelData[widgetTileId as string] || unknownWidget;
  const type: BasicTileType = libraryWidget.type;
  const isUnknownTile = type === 'other';
  const title: string = isUnknownTile ? '' : libraryWidget.title;
  const widgetData: GenericWidgetDataType = libraryWidget.data;
  const iconProps: IIconProps = { iconName: SystemIcons.MORE };
  const onContextClick = onRemoveItem;

  const menuProps: IContextualMenuProps = {
    items: [
      {
        className: styles['widget-no-select'],
        key: 'autoResize',
        text: t('auto-resize', { ns: NS.WIDGETS }),
        iconProps: { iconName: SystemIcons.BACK_TO_WINDOW },
        disabled: true,
      },
      {
        className: styles['widget-no-select'],
        key: 'changeTitle',
        text: t('change-title', { ns: NS.WIDGETS }),
        iconProps: { iconName: SystemIcons.EDIT },
        disabled: true,
      },
      {
        className: styles['widget-no-select'],
        key: 'removeTile',
        text: t('dashboard-remove', { ns: NS.WIDGETS }),
        iconProps: { iconName: SystemIcons.DELETE },
        onClick: (item: any) => {
          onContextClick && onContextClick(item, dataId);
        },
      },
    ],
    directionalHintFixed: true,
  };

  const onRenderMenuIcon = () => {
    return <></>;
  };

  // NOTE: Currently, we display the same IconButton whether we are showing a Tile that is embedded in the Dashboard, or
  // dragging around. We have laid the foundation so that, if we choose not to have the button visible when the Tile is
  // being dragged (but not dropped), we have the option to not show anything at all. Once we know that we are in the
  // middle of a Drag Event, we will be able to differentiate between an Unknown Tile in a Dashboard, and one that is
  // being dragged around. At that point, we could display no button for the isUnknownTile path.
  const isTileDragging = false;

  const button =
    isUnknownTile && isTileDragging ? (
      <></>
    ) : (
      <IconButton
        className={styles['widget-menu']}
        menuProps={isEditMode ? menuProps : undefined}
        onRenderMenuIcon={onRenderMenuIcon}
        iconProps={iconProps}
        title={t('options', { ns: NS.COMMON })}
        ariaLabel={t('options', { ns: NS.COMMON })}
        disabled={false}
        checked={false}
        hidden={!isEditMode}
      />
    );

  return (
    <div
      className={`${styles['widget-tile']} ${isEditMode ? styles['edit-mode'] : styles['read-only']}`}
      data-id={dataId}
      data-grid={item}
    >
      {/* This comes from our database, so does not need i18n.  */}
      <div className={styles['widget-title']}>{title}</div>
      {button}
      <TileRenderer
        id={dataId}
        item={item}
        type={type}
        record={record}
        widgetTileId={widgetTileId}
        widgetData={widgetData}
        panelData={panelData}
      />
    </div>
  );
};

const WidgetTile = observer(WidgetTileFC);

export default WidgetTile;
