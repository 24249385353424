import React from 'react';
import ReactJson from 'react-json-view';
import { observer } from 'mobx-react-lite';
import JSON5 from 'json5';

import customStyle from '@/components/PreviewExperiment/PreviewExperimentStyles';
import { PreviewExperimentVCType } from '@/components/PreviewExperiment/PreviewExperimentType';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';

import styles from './PreviewExperiment.module.css';

const PreviewExperimentTemplateFC: React.FC<PreviewExperimentVCType> = (props: PreviewExperimentVCType) => {
  const { convertedExperiment, isExperimentLoading, buttons, jsonStyle } = props;

  const experimentContent = () => {
    if (!convertedExperiment) {
      return <></>;
    }

    if (isExperimentLoading) {
      return <LoadingSpinner />;
    }

    try {
      JSON5.parse(convertedExperiment);

      const json: object = JSON5.parse(convertedExperiment);

      return (
        <>
          <pre className={styles['json-block']}>
            <ReactJson src={json} style={customStyle.jsonBackground} theme={jsonStyle} />
          </pre>
        </>
      );
    } catch (error) {
      return <>{convertedExperiment}</>;
    }
  };

  return (
    <>
      <div className={styles.height}>
        <div className={styles.full}>
          <ScrollableContent scrollable={true} scrollableX={true}>
            {experimentContent()}
          </ScrollableContent>
        </div>
      </div>
      {buttons}
    </>
  );
};

const PreviewExperimentTemplate = observer(PreviewExperimentTemplateFC);

export default PreviewExperimentTemplate;
