import React from 'react';
import { observer } from 'mobx-react-lite';
import { Toggle } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';

import { WidgetCommandBarType } from './WidgetCommandBarTypes';

import styles from '@/partials/PageCommandBar/PageCommandBar.module.css';

const WidgetCommandBarFC: React.FC<WidgetCommandBarType> = (props: WidgetCommandBarType): React.ReactElement => {
  const { showPanel, hidePanel, isPanelOpen, isEditMode, toggleEditMode, doDashboardReset } = props;

  // TODO: It looks like this CommandBarTemplate should go MVVM and start use a ViewController.
  // Then a lot of this logic can move into it.
  const addTile = () => {
    !isEditMode && toggleEditMode(); // If we can add tiles, turn on Edit Mode.
    showPanel();
  };

  const toggleEditDashboard = () => {
    isEditMode && hidePanel(); // If Edit Mode is turning off, hide the Panel.
    toggleEditMode();
  };

  const items: ICommandBarItemProps[] = [
    {
      key: 'edit',
      onClick: toggleEditDashboard,
      commandBarButtonAs: () => (
        <Toggle
          inlineLabel
          className={styles['pagecommandbar-toggle']}
          checked={isEditMode}
          onText={t('edit-dashboard', { ns: NS.WIDGETS })}
          offText={t('edit-dashboard', { ns: NS.WIDGETS })}
          onClick={toggleEditDashboard}
        />
      ),
    },
    {
      key: 'add-tile',
      text: t('add-tile', { ns: NS.WIDGETS }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: addTile,
      disabled: isPanelOpen,
    },
    {
      key: 'new',
      text: t('new', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: () => {
        console.log('new');
      },
      disabled: true,
    },
    {
      key: 'clone',
      text: t('clone', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.COPY },
      onClick: () => {
        console.log('clone');
      },
      disabled: true,
    },
    {
      key: 'delete',
      text: t('delete', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.DELETE },
      onClick: () => {
        console.log('delete');
      },
      disabled: true,
    },
  ];

  const overflowItems: ICommandBarItemProps[] = [
    {
      key: 'reset',
      text: t('reset-dashboard', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.RESET_DASHBOARD },
      onClick: () => {
        if (confirm(t('confirm-dashboard-reset', { ns: NS.ERRORS }))) {
          doDashboardReset();
        }
      },
    },
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: t('refresh', { ns: NS.COMMON }),
      title: t('refresh', { ns: NS.COMMON }),
      ariaLabel: t('refresh', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        console.log('refresh');
      },
      disabled: true,
    },
  ];

  return (
    <div>
      <PageCommandBar items={items} farItems={farItems} overflowItems={overflowItems}></PageCommandBar>
    </div>
  );
};

const WidgetCommandBar = observer(WidgetCommandBarFC);

export default WidgetCommandBar;
