import { CancelToken } from 'axios';

import BaseRequestService from '@/services/request-services/RequestService';
import { Auth } from '@/types/_air/Auth';
import { backendEndpoint } from '@/utils/Env';
import MsalAuthorization from '@/utils/MsalAuthorization';

class AuthRequestService extends BaseRequestService {
  async getAuth(cancelToken?: CancelToken): Promise<Auth> {
    const { data } = await this.get('auth/GetAuth', null, { cancelToken });

    return data;
  }

  public async getXSRFToken(): Promise<any> {
    try {
      const token = await MsalAuthorization.generateToken();
      const response = await fetch(backendEndpoint + 'antiforgery/token', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
          Vary: 'Origin',
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (response.ok) {
        this.xsrfToken = await response.text();
        console.log(`[XSRFRequestService] Providing CSRFtoken: "${this.xsrfToken}"`);
      } else {
        console.error('[XSRFRequestService] CSRFToken retrieval failed');
      }
    } catch (e) {
      console.error(`[XSRFRequestService] CSRFToken retrieval failed: ${e}`);
    }

    return this.xsrfToken;
  }
}

export const authRequestService = new AuthRequestService(backendEndpoint);
