import React from 'react';
import { t } from 'i18next';
import moment from 'moment-timezone';
import format from 'string-template';

import { DateFormats, TimeRangeOptions } from '@/constants/DateFormatConstants';
import { FilterOptions, Namespaces as NS } from '@/constants/SystemConstants';

const formatDate = (date: string, timeZone: string): string => {
  return date ? moment(date).tz(timeZone).format(DateFormats.STANDARD_DATE_TIME) : t('not-specified', { ns: NS.DEFAULT });
};

const formatDateTime = (date: string, dateRange: string, timeZone: string): string => {
  if (date) {
    if (dateRange === FilterOptions.CUSTOM) {
      return moment(date).format(DateFormats.DEFAULT_DATE_TIME);
    } else {
      return moment(date).tz(timeZone).format(DateFormats.STANDARD_DATE_TIME);
    }
  }

  return t('not-specified', { ns: NS.DEFAULT });
};

const formatDateToISOStandard = (date: Date): string => {
  const formattedISO = date.toISOString();
  return formattedISO;
};

const getDuration = (startTime: string, lastModified: string): string => {
  if (!startTime || !lastModified) {
    return DateFormats.DEFAULT_TIME;
  }

  const duration = moment.duration(moment(lastModified).diff(moment(startTime)));

  const months = duration.months() > 0 ? format(t('months-template', { ns: NS.EXPERIMENTS }), { count: duration.months() }) : '';
  const days = duration.days() > 0 ? format(t('days-template', { ns: NS.EXPERIMENTS }), { count: duration.days() }) : '';
  const time = moment.utc(duration.asMilliseconds()).format(DateFormats.TIME_FORMAT);

  return `${months} ${days} ${time}`;
};

const getRunTimeRangeDates = (runTimeRange: string, timeZone: string) => {
  const hour: moment.unitOfTime.DurationConstructor = 'h';
  const isDay: boolean = TimeRangeOptions.some((f) => f.key === runTimeRange && f.isDay);
  const now: moment.Moment = moment.tz(timeZone);

  let startDate: moment.Moment = moment.tz(timeZone).subtract(Number(runTimeRange), hour);

  if (isDay) {
    startDate = startDate.startOf('day');
  }

  const runTimeStartDate = startDate.toDate();
  const runTimeEndDate = now.toDate();

  return { runTimeStartDate, runTimeEndDate };
};

const sortByDate = <T extends { lastModified: string }>(date: T[]): T[] => {
  return date.sort((a: T, b: T) => b.lastModified.localeCompare(a.lastModified));
};

const sortByCreatedDate = <T extends { created: string }>(date: T[]): T[] => {
  return date.sort((a: T, b: T) => b.created.localeCompare(a.created));
};

export { formatDate, formatDateTime, formatDateToISOStandard, getDuration, getRunTimeRangeDates, sortByDate, sortByCreatedDate };
