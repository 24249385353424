import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

import { Tooltip } from '@/components/_air/common';
import { Routes } from '@/constants/AirConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ExperimentInfo, Semaphore } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  experiment: ExperimentInfo;
}

export const ExperimentStatusIndicator = ({ experiment }: Props): JSX.Element => {
  const [sortedStatuses, setSortedStatuses] = React.useState<Semaphore[]>([]);

  React.useEffect(() => {
    const sorted = experiment.semaphores?.sort((a: Semaphore, b: Semaphore) => {
      if (a.shortName < b.shortName) return -1;

      if (a.shortName > b.shortName) return 1;

      return 0;
    });

    const data = sorted.filter((i) => i.shortName !== 'OTH');

    setSortedStatuses(data);
  }, [experiment.semaphores]);

  const getTooltipContent = ({ friendlyName, description }: Semaphore): JSX.Element => (
    <span className="tooltip-content-container">
      <span className="status-name">{friendlyName}</span>
      <span>{description}</span>
    </span>
  );

  const getColorsByName = React.useCallback(
    (): { [key: string]: string } =>
      experiment.semaphores?.reduce((map: { [index: string]: any }, el) => {
        map[el.shortName] = el.overallSignal.toLowerCase();

        return map;
      }, {}),
    [experiment.semaphores],
  );

  return (
    <div className="statuses-container">
      {sortedStatuses?.map((status, index) => (
        <Tooltip key={index} content={getTooltipContent(status)}>
          <Link
            to={{
              pathname: `${Navigation.AIR.SCENARIO}/${experiment.experimentName}/${experiment.revision}/${status.shortName}`,
              state: { colorsByName: getColorsByName() },
            }}
            className="status-link"
          >
            <Chip
              key={index}
              size="small"
              component="span"
              label={status.shortName || 'N/A'}
              className={status.overallSignal.toLowerCase()}
              style={{ cursor: 'pointer' }}
            />
          </Link>
        </Tooltip>
      ))}
    </div>
  );
};
