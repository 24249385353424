import React from 'react';
import { CartesianGrid, ComposedChart, Legend, ResponsiveContainer, XAxis } from 'recharts';

import { GraphBaseTemplateType } from './GraphBaseType';

const GraphBaseTemplate: React.FC<GraphBaseTemplateType> = (props: GraphBaseTemplateType): React.ReactElement => {
  const { xAxisProp, responsiveContainerProp, cartesianGridProp } = props.chartProp;
  const { renderYAxis, renderTooltip, children } = props;

  return (
    <ResponsiveContainer {...responsiveContainerProp}>
      <ComposedChart data={props.data} margin={props.marginBox}>
        <CartesianGrid {...cartesianGridProp} />
        <XAxis {...xAxisProp} />
        {renderYAxis}
        {renderTooltip}
        {children}
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default GraphBaseTemplate;
