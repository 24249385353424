/* eslint-disable max-len */
import React from 'react';
import { ReactI18NextChild } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Icon, IconButton, MessageBarType } from '@fluentui/react';
import { SpinnerSize } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean } from '@fluentui/react-hooks';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';
import JSON5 from 'json5';
import moment from 'moment';
import * as qs from 'query-string';
import format from 'string-template';
import { utils, write } from 'xlsx';

import { FriendlyStatus } from '@/components/_labs/LabsHelper/FriendlyStatus';
import { populateHealthStatus } from '@/components/_labs/LabsHelper/LabsControlHelper';
import { AddTagComponent } from '@/components/_labs/LabsHelper/ModalComponents/AddTagComponent';
import { BasicModalComponent } from '@/components/_labs/LabsHelper/ModalComponents/BasicModalComponent';
import { BatchOperationComponent } from '@/components/_labs/LabsHelper/ModalComponents/BatchOperationComponent';
import { ConfirmationComponent } from '@/components/_labs/LabsHelper/ModalComponents/ConfirmationComponent';
import { FirmwareInfoComponent } from '@/components/_labs/LabsHelper/ModalComponents/FirmwareInfoComponent';
import { PeoplePickerComponent } from '@/components/_labs/LabsHelper/ModalComponents/PeoplePickerComponent';
import { SelectComponent } from '@/components/_labs/LabsHelper/ModalComponents/SelectComponent/SelectComponent';
import { SocInfoComponent } from '@/components/_labs/LabsHelper/ModalComponents/SocInfoComponent';
import { UserTypedComponent } from '@/components/_labs/LabsHelper/ModalComponents/UserTypedComponent';
import { fetchAfterTimeout, labDownloadHelper } from '@/components/_labs/LabsHelper/Net';
import { ExperimentFailedReportType, ExperimentSuccessReportType } from '@/components/MachineSelect/MachineSelectTypes';
import { DateFormats } from '@/constants/DateFormatConstants';
import { LabUpgrade } from '@/constants/ExperimentEditorConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Buttons, Defaults, HealthDiffInMins, Labels, Messages, TableHead } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { DataType, FailGroupIds, InfoGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { TrueFalse } from '@/constants/SystemConstants';
import { Common, Labs } from '@/constants/TranslationConstants';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { AddEditSARMIPAddress } from '@/partials/LabComponents/AddEditSARMIPAddress/AddEditSARMIPAddress';
import { RepairLabMachine } from '@/partials/LabComponents/RepairLabMachine/RepairLabMachine';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { SpinLocation } from '@/partials/LoadingSpinner/SpinnerStyles';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import { firmwareRequestService, labsRequestService } from '@/services/_labs/request-services';
import { useCancellationToken } from '@/services/_labs/screen-service';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { exportToExcel } from '@/utils/Helpers';

import { LabUpgradeComponent } from '../LabsHelper/ModalComponents/LabUpgradeComponent';
import { ManageRackManager } from './ManageRackManager';

import machine from '@/images/_labs/Machine Icon.svg';
import purpleMachine from '@/images/_labs/machineIconPurple.png';
import rack from '@/images/_labs/Rack icon.svg';

import '@/styles/Labs.css';
import style from '@/components/_labs/Labs/LabsControl/LabsControl.module.css';
import commandBarStyles from '@/partials/PageCommandBar/PageCommandBar.module.css';

const LabsControlFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labsControlStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { isPartnerMode, isDebugMode, isDeveloperMode } = appSettingsStore;
  const { setIsSARMAddEditOpen, setIsSARMAddEditClose, isSARMAddEditOpen, setSelectedMachines, setSelectedLabId } =
    labsControlStore;

  const { pathname } = useLocation();
  const routeString = `${Navigation.LABS.LAB_CONTROL}/labId`;
  const { [routeString]: labId, isVirtual } = qs.parse(pathname);
  const hoverDelay = 250;
  const [allSystemData, setAllSystemData] = React.useState<any[]>([]);
  const [systemData, setSystemData] = React.useState<any[]>([]);
  const [userTypedLabName, setUserTypedLabName] = React.useState<string>('');
  const [labName, setLabName] = React.useState<string>('');
  const [labVersion, setLabVersion] = React.useState<string | null>(null);
  const [defaultOSImage, setDefaultOSImage] = React.useState<string>('');
  const [labHealthStatus, setLabHealthStatus] = React.useState<string>('');
  const [labDetailData, setLabDetailData] = React.useState<any>({});
  const [rackManagerList, setRackManagerList] = React.useState<any[]>([]);
  const [rackMachinesList, setRackMachinesList] = React.useState<any[]>([-1]);
  const [OSImageList, setOSImageList] = React.useState<any[]>([]);
  const [isDeleteLabModalOpen, { setTrue: showDeleteLabModal, setFalse: hideDeleteLabModal }] = useBoolean(false);
  const [isUpdateOSImageModalOpen, { setTrue: showUpdateOSImageModal, setFalse: hideUpdateOSImageModal }] = useBoolean(false);
  const [isFirmwareInfoModalOpen, { setTrue: showFirmwareInfoModal, setFalse: hideFirmwareInfoModal }] = useBoolean(false);
  const [isSocInfoModalOpen, { setTrue: showSocInfoModal, setFalse: hideSocInfoModal }] = useBoolean(false);
  const [isRackManagerInfoModalOpen, { setTrue: showRackManagerInfoModal, setFalse: hideRackManagerInfoModal }] = useBoolean(false);
  const [isUpdateModalOpen, { setTrue: showUpdateModal, setFalse: hideUpdateModal }] = useBoolean(false);
  const [isLabAddUserModalOpen, { setTrue: showLabAddUserModal, setFalse: hideLabAddUserModal }] = useBoolean(false);
  const [isBatchOperationModalOpen, { setTrue: showBatchOperationModal, setFalse: hideBatchOperationModal }] = useBoolean(false);
  const [isFixIpAddressModalOpen, { setTrue: showFixIpAddressModal, setFalse: hideFixIpAddressModal }] = useBoolean(false);
  const [isFixMacAddressModalOpen, { setTrue: showFixMacAddressModal, setFalse: hideFixMacAddressModal }] = useBoolean(false);
  const [isAddTagModalOpen, { setTrue: showAddTagModal, setFalse: hideAddTagModal }] = useBoolean(false);
  const [isAddRackManagerModalOpen, { setTrue: showAddRackManagerModal, setFalse: hideAddRackManagerModal }] = useBoolean(false);
  const [creationMessage, setCreateMessage] = React.useState<string>('');
  const [successExperiment, setSuccessExperiment] = React.useState<ExperimentSuccessReportType[]>([]);
  const [failedExperiment, setFailedExperiment] = React.useState<ExperimentFailedReportType[]>([]);
  const [isLabUpgradeModalOpen, setLabUpgradeModalOpen] = React.useState<boolean>(false);
  const [isExperimentLoading, setExperimentLoading] = React.useState<boolean>(false);

  const [isEditVirtualLabModalOpen, { setTrue: showEditVirtualLabModal, setFalse: hideEditVirtualLabModal }] = useBoolean(false);
  const [selectedOSImageKey, setSelectedOSImageKey] = React.useState<string>('');
  const [selectedMachineKey, setSelectedMachineKey] = React.useState<any[]>([]);
  const [selectedRackKey, setSelectedRackKey] = React.useState<any[]>([]);
  const [selectedMachineName, setSelectedMachineName] = React.useState<string>('');
  const [selectedMachineInfo, setSelectedMachineInfo] = React.useState<any[]>([]);
  const [rackManagerInfo, setRackManagerInfo] = React.useState<any[]>([]);
  const [updateAvailable, setUpdateAvailable] = React.useState<boolean>(false);
  const [manifestVersion, setManifestVersion] = React.useState<string>('');
  const [selectedItem, toggleItem] = React.useState<number>(-1);
  const [selectedSubItem, toggleSubItem] = React.useState<number>(-1);
  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDeleteSARMLoading, setIsDeleteSARMLoading] = React.useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  const [selectedMachineHasRack, setSelectedMachineHasRack] = React.useState<boolean>(true);
  const [tagOptions, setTagOptions] = React.useState<any[]>([]);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [virtualMenuVisible, setVirtualMenuVisible] = React.useState<boolean>(false);
  const [vmMenuDisable, setVMMenuDisable] = React.useState<boolean>(false);
  const [labUserList, setLabUserList] = React.useState<any[]>([]);
  const [adminMenu, setAdminMenu] = React.useState<boolean>(true);
  const [loadLab, setLoadLab] = React.useState<boolean>(true);
  const [labOnly, setLabOnly] = React.useState<boolean>(false);
  const [SARMIP, setSARMIP] = React.useState<string>('');
  const cancellationToken = useCancellationToken();

  const { userInfoStore } = React.useContext(RootStoreContext);
  const userName = userInfoStore.userName;
  const pageTitle = `${t('labs', { ns: NS.TITLES })}`;
  const subTitle = `${labName} ${TITLE_DIVIDER} ${Labels.devices} (Total ${allSystemData.length} machines)`;
  const newLabStatus = 'New';

  const [lastRefresh, setLastRefresh] = React.useState(moment(Date()).format(DateFormats.STANDARD_DATE_TIME));
  const failGroupId = FailGroupIds.LAB_CONTROL;
  const successGroupId = SuccessGroupIds.LAB_CONTROL;
  const commandItems: ICommandBarItemProps[] = [
    {
      key: t('download-success-report', { ns: NS.EDITOR }),
      text: t('download-success-report', { ns: NS.EDITOR }),
      ariaLabel: t('download-success-report', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      title: t('download-success-report', { ns: NS.EDITOR }),
      disabled: successExperiment.length === 0,
      onClick: (event) => {
        exportToExcel(successExperiment, 'SuccessfulInstances');
      },
    },
    {
      key: t('download-failed-report', { ns: NS.EDITOR }),
      text: t('download-failed-report', { ns: NS.EDITOR }),
      ariaLabel: t('download-failed-report', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      title: t('download-failed-report', { ns: NS.EDITOR }),
      disabled: failedExperiment.length === 0,
      onClick: (event) => {
        exportToExcel(failedExperiment, 'FailedInstances');
      },
    },
  ];

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const moreIcon = { iconName: SystemIcons.MORE };
  const history = useHistory();

  const downloadLab = React.useCallback(
    (isExe: boolean) => {
      labDownloadHelper(isExe, (labDetailData as any).LabId, systemMessageStore);
    },
    [labDetailData],
  );

  const getHeartBeat = async (item) => {
    const agentId = `${item.LabControllerId},${item.MacAddress},${item.SlotNumber},${item.IPAddress}`;
    const date = await labsRequestService
      .getAgentHeartbeat(agentId)
      .then((result) => {
        return result.lastModified;
      })
      .catch((error) => {
        return null;
      });

    return date;
  };

  React.useEffect(() => {
    const host = allSystemData?.filter((item) => item.HostSystemId == null);
    const vms = allSystemData?.filter((item) => item.HostSystemId !== null);

    for (let i = 0; i < host.length; i++) {
      if (host[i as number].children) {
        host[i as number].children = [];
      }
    }

    // Adding VM machines under host machine
    for (let i = 0; i < vms.length; i++) {
      const system = host.find((item) => item.MachineId === vms[i as number].HostSystemId);

      if (system) {
        if (system.children) {
          system.children.push(vms[i as number]);
        } else {
          system.children = [];
          system.children.push(vms[i as number]);
        }
      }
    }

    // Adding systems under Rack Manager if machine belongs to it
    if (rackManagerList.length > 0) {
      for (let i = 0; i < rackManagerList.length; i++) {
        const rackMachines = [];
        let j = 0;

        while (j < host.length) {
          if (host[j as number].RackId === rackManagerList[i as number].RackId) {
            const data = host.splice(j, 1);

            rackMachines.push(data[0]);
          } else {
            j++;
          }
        }

        const copiedRackManager = [...rackManagerList];

        copiedRackManager[i as number].machines = rackMachines;
        setRackMachinesList(copiedRackManager);
        setSystemData(host);

        if (host.length === 0 && rackMachinesList.length > 0) {
          toggleItem(0);
        }
      }
    } else {
      setRackMachinesList(rackManagerList);
      setSystemData(host);
    }

    setDataLoaded(true);
  }, [allSystemData, rackManagerList.length, rackMachinesList.length, rackManagerList]);

  const fetchLabDetail = React.useCallback(async () => {
    await ganymedeLabRequestService
      .getLabDetails(labId)
      .then(async (result) => {
        result.LastHeartBeat = await labsRequestService
          .getAgentHeartbeat(labId.toString())
          .then((response) => {
            return response.lastModified;
          })
          .catch((error) => {
            return null;
          });

        setLabDetailData(result);
        setLabName(result.LabName);
        setLabVersion(result.Version);
        setDefaultOSImage(result.DefaultOsImageId);

        if (isVirtual === TrueFalse.FALSE) {
          setLabHealthStatus(checkMachineHealth(result.LastHeartBeat));
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [labId]);

  const fetchRackManager = React.useCallback(async () => {
    if (isVirtual === 'true') {
      await ganymedeLabRequestService
        .getVirtualRackManager(labId)
        .then((result) => {
          if (result.length > 0) {
            setRackManagerInfo(JSON.parse(result[0].FirmwareInfo));
            setRackManagerList(result);
          }
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
          };

          HandleError(handleErrorProps);
        });
      return;
    }

    await ganymedeLabRequestService
      .getRackManager(labId)
      .then((result) => {
        if (result.length > 0) {
          setRackManagerInfo(JSON.parse(result[0].FirmwareInfo));
          setRackManagerList(result);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [labId, isVirtual]);

  const fetchSystem = React.useCallback(
    async (trigger: string | null = null) => {
      // not blocking the view when user added tag
      if (trigger !== 'tag') {
        setIsLoading(true);
      }

      if (isVirtual === 'true') {
        await ganymedeLabRequestService
          .getVirtualSystem(labId)
          .then(async (result) => {
            fetchRackManager();

            // Gather heartbeats here
            for (let i = 0; i < result.length; i++) {
              const data = result[i as number];
              await getHeartBeat(data).then((result) => {
                data.LastHeartBeat = result;
              });

              result[i as number] = data;
            }

            setAllSystemData(result);
          })
          .catch((error) => {
            const handleErrorProps = {
              error,
              systemMessageStore,
              appSettingsStore,
              failGroupId,
            };

            HandleError(handleErrorProps);
          })
          .finally(() => {
            setIsLoading(false);
            setBusy(false);
          });

        return;
      }

      await ganymedeLabRequestService
        .getSystem(labId)
        .then(async (result) => {
          fetchRackManager();
          result.sort((item1: any, item2: any) => item1.SlotNumber - item2.SlotNumber);

          // Gather heartbeats here
          for (let i = 0; i < result.length; i++) {
            const data = result[i as number];
            await getHeartBeat(data).then((result) => {
              data.LastHeartBeat = result;
            });

            result[i as number] = data;
          }

          setAllSystemData(result);
        })
        .catch((error) => {
          handleLabUserError(error);
        })
        .finally(() => {
          setIsLoading(false);
          setBusy(false);
        });
    },
    [labId, fetchRackManager, isVirtual],
  );

  const handleLabUserError = (error) => {
    const errorDetail = error?.response?.data?.Detail;

    if (errorDetail) {
      const noAccessToLabResponse = Messages.noAccessToLabResponse
        .replace('${userName}', `'${userName}'`)
        .replace('${labId}', `'${labId}'`);
      const labNotExistResponse = Messages.labNotExistResponse.replace('${labId}', `'${labId}'`);

      if (errorDetail === noAccessToLabResponse) {
        const noAccessToLab = `${Messages.noAccessToLab
          .replace('${userName}', `'${userName}'`)
          .replace('${labId}', `'${labId}'`)
          .replace('${mailId}', Messages.supportEmailId)}`;

        addFailMessage(noAccessToLab);
        setLoadLab(false);

        return;
      } else if (errorDetail === labNotExistResponse) {
        const labNotExist = Messages.labNotExist.replace('${labId}', `'${labId}'`);

        addFailMessage(labNotExist);
        setLoadLab(false);

        return;
      }
    }

    const handleErrorProps = {
      error,
      systemMessageStore,
      appSettingsStore,
      failGroupId,
    };

    HandleError(handleErrorProps);
  };

  const refreshRMInfo = React.useCallback(
    (
      ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined,
      item?: IContextualMenuItem | undefined,
    ) => {
      ev?.preventDefault();
      firmwareRequestService
        .refreshRMInfo(selectedMachineKey)
        .then(() => {
          addSuccessMessage(Messages.RMRefreshSuccess);
          fetchAfterTimeout(fetchSystem);
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
          };

          HandleError(handleErrorProps);
        });
    },
    [fetchSystem, selectedMachineKey],
  );

  const fetchAvailableOSImages = React.useCallback(async () => {
    await ganymedeLabRequestService
      .getOSImages()
      .then((result) => {
        if (result) {
          let osOptions = result;

          if (!(labDetailData as any).IsInternal) {
            osOptions = result.filter((osImage: { IsInternal: any }) => !osImage.IsInternal);
          }

          setSelectedOSImageKey(osOptions[0].OsImageId);
          setOSImageList(osOptions);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [labId, defaultOSImage]);

  const fetchExistingTags = React.useCallback(async () => {
    await ganymedeTagRequestService
      .getTags()
      .then((result) => {
        setTagOptions(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  const getLabUser = React.useCallback(async () => {
    ganymedeLabRequestService
      .getLabsUser(labId)
      .then((response) => {
        if (response) {
          setLabUserList(response);
        }
      })
      .catch(() => {
        setAdminMenu(false);
      });
  }, [labId]);

  const isUserLabAdmin = (): boolean => {
    const admins = labUserList && labUserList.filter((user) => user.RoleName === Defaults.LAB_ADMIN && user.UserName === userName);
    return admins.length > 0;
  };

  const checkUpdate = React.useCallback(
    (manifestVer) => {
      if (labVersion === manifestVer) {
        setUpdateAvailable(false);

        return; // version is same, no need to check further
      }

      if (labVersion === null) {
        setUpdateAvailable(false);

        return;
      }

      const manifestVerArr = manifestVer.split('.');

      if (labVersion !== null) {
        const labVerArr = (labVersion as any).split('.');

        for (let i = 0; i < manifestVerArr.length; i++) {
          if (labVerArr[i as number] < manifestVerArr[i as number]) {
            setUpdateAvailable(true);

            return;
          }
        }
      }
    },
    [labVersion],
  );

  const fetchLabManifest = React.useCallback(async () => {
    await ganymedeLabRequestService
      .getLabManifest()
      .then((result) => {
        setManifestVersion(result);
        checkUpdate(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [checkUpdate]);

  const updateLabName = async (evt: any) => {
    evt.preventDefault();
    await ganymedeLabRequestService
      .updateLabName(labId, labName.trim())
      .then((result) => {
        setEditMode(false);
        fetchLabDetail();
        addSuccessMessage(Messages.labNameUpdated);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  };

  const getAddedRackManagerState = async () => {
    labsRequestService
      .getAddRackManagerState(labId)
      .then((response) => {
        if (response) {
          const addRackManagerError = response.filter(
            (item: { MessageType: string; Error: null }) =>
              item.MessageType !== Labels.deleteRackManagerAndSystems && item.Error !== null,
          );
          const deleteRackManagerError = response.filter(
            (item: { MessageType: string; Error: null }) =>
              item.MessageType === Labels.deleteRackManagerAndSystems && item.Error !== null,
          );

          if (addRackManagerError.length > 0) {
            addFailMessage(Messages.manuallyAddedRackHasIssue);
          }

          if (deleteRackManagerError.length > 0) {
            addFailMessage(Messages.manuallyDeletedRackHasIssue);
          }
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  };

  React.useEffect(() => {
    fetchSystem();
    fetchLabDetail();
    fetchAvailableOSImages();

    if (!labDetailData.IsVirtualLab) {
      fetchLabManifest();
    }

    fetchExistingTags();
    getLabUser();
    getAddedRackManagerState();
  }, [
    fetchLabDetail,
    fetchLabManifest,
    fetchAvailableOSImages,
    fetchSystem,
    fetchExistingTags,
    labDetailData.IsVirtualLab,
    getLabUser,
  ]);

  React.useEffect(() => {
    setSelectedLabId(labId.toString());

    const interval = setInterval(() => {
      fetchLabDetail();
      fetchSystem();
      setLastRefresh(moment(Date()).format(DateFormats.STANDARD_DATE_TIME));
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const setMachineIdprop = React.useCallback(
    (e, item: any, machineUnderRack: boolean) => {
      setSelectedMachineHasRack(machineUnderRack);

      if (machineUnderRack) {
        setSelectedMachineKey(item.MachineId);
        setSelectedMachines([item]);
      } else {
        setSelectedMachineKey(item.RackId);
      }
    },
    [setSelectedMachineKey, setSelectedMachineHasRack],
  );

  const handleFirmwareInfo = (machineName: any, machineId: any) => {
    setSelectedMachineKey(machineId);
    setSelectedMachineName(machineName);

    if (machineId === null) {
      showRackManagerInfoModal();
    } else {
      showFirmwareInfoModal();
    }
  };

  const handleSocInfo = (item: any) => {
    setSelectedMachineInfo(item);
    showSocInfoModal();
  };

  const repairLabSubMenuItems = [
    {
      key: Labs.FIX_IP_ADDRESS,
      text: t(Labs.FIX_IP_ADDRESS, { ns: NS.LABS }),
      onClick: showFixIpAddressModal,
    },
    {
      key: Labs.FIX_MAC_ADDRESS,
      text: t(Labs.FIX_MAC_ADDRESS, { ns: NS.LABS }),
      onClick: showFixMacAddressModal,
    },
  ];

  const machineMenuItems = React.useMemo(() => {
    const items = [
      {
        key: Labs.REPAIR_MACHINE,
        text: Labels.repairMachine,
        subMenuProps: {
          items: repairLabSubMenuItems,
        },
      },
      {
        key: 'AddSystemTag',
        text: Labels.addSystemTag,
        onClick: showAddTagModal,
      },
      {
        key: 'CreateExperimentSteps',
        text: Buttons.createExperimentSteps,
        onClick: () => history.push(`${Navigation.GANYMEDE.EXPERIMENT_EDITOR}/${labId}`),
      },
    ];

    // This condition will be removed at the time of Bulk SARM changes
    if (!isBatchOperationModalOpen) {
      items.push({
        key: Labs.SARM_UPDATE,
        text: t(Labs.SARM_UPDATE, { ns: NS.LABS }),
        onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
          setIsSARMAddEditOpen();
          event.stopPropagation();
        },
      });
    }

    return isDeveloperMode && isUserLabAdmin() && !isBatchOperationModalOpen
      ? items
      : items.filter((item) => item.key !== Labs.REPAIR_MACHINE);
  }, [
    showAddTagModal,
    virtualMenuVisible,
    labDetailData,
    showFixIpAddressModal,
    showFixMacAddressModal,
    isDeveloperMode,
    isBatchOperationModalOpen,
  ]);

  const rackMenuItems = React.useMemo(
    () => [
      {
        key: 'RefreshRMInfo',
        text: Buttons.refreshRackManagerInfo,
        onClick: (e: any) => refreshRMInfo(e),
      },
    ],
    [refreshRMInfo],
  );

  const menuPropsBase = React.useMemo(
    () => ({
      shouldFocusOnMount: true,
      subMenuHoverDelay: hoverDelay,
    }),
    [hoverDelay],
  );

  const MachineMenuProps = React.useMemo(
    () => ({
      ...menuPropsBase,
      items: machineMenuItems,
    }),
    [machineMenuItems, menuPropsBase],
  );

  const RackMenuProps = React.useMemo(
    () => ({
      ...menuPropsBase,
      items: rackMenuItems,
    }),
    [rackMenuItems, menuPropsBase],
  );

  const openItem = (index: number): void => {
    toggleItem(selectedItem === index ? -1 : index);
  };

  const openSubItem = (index: number): void => {
    toggleSubItem(selectedSubItem === index ? -1 : index);
  };

  const getOsFriendlyName = (name: any) => {
    const os = OSImageList.filter((osImage) => osImage.OsImageName.includes(name));

    return os.length > 0 ? os[0].OsImageFriendlyName : '';
  };

  const getOsFriendlyNameById = (id: string) => {
    const os = OSImageList.filter((osImage) => osImage.OsImageId === id);

    return os.length > 0 ? os[0].OsImageFriendlyName : '';
  };

  const handleCreate = async (systemId: number[], tagName: string, tagList: any[], strAdded = false) => {
    await ganymedeTagRequestService
      .createSystemsTag(systemId, tagName)
      .then((result) => {
        if (result) {
          if (strAdded) {
            tagList.push({ TagId: result, TagName: tagName });
          }

          fetchExistingTags();
          fetchSystem('tag');
          setSelectedMachineKey([]);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  };

  const handleDelete = async (e: any, systemId?: number) => {
    if (systemId === undefined) {
      // handle the case when systemId is undefined, possibly throw an error or return
      console.error('systemId is undefined');
      return;
    }

    const deletedItem = tagOptions.find(
      (item) => (item as any).TagName.toLowerCase() === e.currentTarget.parentElement.innerText.toLowerCase(),
    );

    if (deletedItem) {
      await ganymedeTagRequestService
        .deleteSystemTag(systemId, deletedItem.TagId)
        .then((result) => {
          if (result) {
            fetchSystem('tag');
          }
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
          };

          HandleError(handleErrorProps);
        });
    }
  };

  const handleTagChanges = async (e: any, systemId: number[], tagName: any, reason: any) => {
    if (systemId.length === 0) {
      addFailMessage(Messages.selectMachine);

      return;
    }

    // handling when user choose one of dropdown option
    if (reason === 'selectOption' || reason === 'select-option') {
      if (
        tagName.filter((name: { TagName: string }) => name.TagName.toLowerCase().includes(tagName.at(-1).TagName.toLowerCase()))
          .length > 1
      ) {
        addFailMessage(Messages.tagExist);
        tagName.pop();

        return;
      }

      handleCreate(systemId, tagName.at(-1).TagName, tagName, false);

      return;
    }

    if (reason === 'removeOption' || reason === 'remove-option') {
      handleDelete(e, systemId.at(-1));

      return;
    }

    // handling when user typed free form text
    if (typeof tagName.at(-1) === DataType.STRING || tagName.at(-1).TagName !== null) {
      const tName = typeof tagName.at(-1) === DataType.STRING ? tagName.at(-1) : tagName.at(-1).TagName;

      tagName.pop();

      if (tagName.filter((name: { TagName: string }) => name.TagName.toLowerCase().includes(tName.toLowerCase())).length > 1) {
        addFailMessage(Messages.tagExist);

        return;
      }

      handleCreate(systemId, tName, tagName, true);
    }
  };

  const addSuccessMessage = (message: string) => {
    const successMessage: SystemMessageType = {
      message: message,
      type: MessageBarType.success,
      groupId: successGroupId,
    };

    addGlobalMessage(successMessage);
  };

  const addFailMessage = (message: string) => {
    const failMessage: SystemMessageType = {
      message: message,
      type: MessageBarType.error,
      groupId: failGroupId,
    };

    addGlobalMessage(failMessage);
  };

  const label = (option) => (
    <Typography aria-label={option.TagName} style={{ whiteSpace: 'normal' }}>
      <span>{option.TagName}</span>
    </Typography>
  );

  const getAutoComplete = (item: any) => (
    <td className="lab-control-w10">
      <Autocomplete
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        options={tagOptions}
        getOptionLabel={(option) => option.TagName}
        defaultValue={(item as any).Tags}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              id={option.TagName + ' on ' + item.Name}
              variant="outlined"
              color="primary"
              label={label(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Tags' }} variant="standard" />
        )}
        onChange={(event, newValue, reason) => {
          handleTagChanges(event, [(item as any).MachineId], newValue, reason);
        }}
      />
    </td>
  );

  const handleLabNameChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setLabName(event.target.value);
  };

  const getRackStatus = (item) => {
    const machines = item.machines;
    const list: string[] = [];
    machines &&
      machines.forEach((mac) => {
        const MachinelabHealth = checkMachineHealth(mac.LastHeartBeat);
        list.push(MachinelabHealth);
        const childern = mac.children;
        childern &&
          childern.forEach((vm) => {
            const VMlabHealth = checkMachineHealth(vm.LastHeartBeat);
            list.push(VMlabHealth);
          });
      });
    let finalRackSatus;
    let labHealthstr;
    let labStatusIcon;

    if (list.includes(Labels.unhealthy) || list.includes(Labels.warning)) {
      finalRackSatus = Labels.unhealthy;
      labHealthstr = Labels.labUnhealthy;
      labStatusIcon = Labels.statusErrorFull;
    } else if (list.every((e) => e === Labels.healthy) || (list.includes(Labels.healthy) && list.includes(Labels.notContacted))) {
      finalRackSatus = Labels.healthy;
      labHealthstr = Labels.labHealthy;
      labStatusIcon = Labels.skypeCircleCheck;
    } else {
      finalRackSatus = 'Not Available';
      labHealthstr = Labels.labNeutral;
      labStatusIcon = Labels.statusErrorFull;
    }

    return (
      <p>
        <Icon iconName={labStatusIcon} className={finalRackSatus} />
        <b className={labHealthstr}>{finalRackSatus}</b>
      </p>
    );
  };

  const checkMachineHealth = (item) => {
    const currentDate = new Date().getTime();
    const heartbeat = new Date(`${item}Z`).getTime();
    const diffInMs = Math.abs(currentDate - heartbeat) / 60000;
    let labHealth;

    if (item === null || item === undefined) {
      labHealth = Labels.unhealthy;
    } else if (diffInMs <= HealthDiffInMins.warning) {
      labHealth = Labels.healthy;
    } else if (diffInMs <= HealthDiffInMins.unhealthy) {
      labHealth = Labels.warning;
    } else {
      labHealth = Labels.unhealthy;
    }

    return labHealth;
  };

  const replaceLabUpgradeValues = async (templateExperiment: any) => {
    const updateValues = (object: any) => {
      const traverseArray = (array: any) => {
        array.forEach((item: any) => {
          if (typeof item === DataType.OBJECT) {
            updateValues(item);
          }
        });
      };

      for (const key in object) {
        if (Array.isArray(object[key])) {
          traverseArray(object[key]);
        } else if (typeof object[key] === DataType.OBJECT) {
          updateValues(object[key]);
        } else {
          if (key.toLowerCase() === LabUpgrade.LAB_ID) {
            object[key] = labId;
          }

          if (key.toLowerCase() === LabUpgrade.MANIFEST_VERSION) {
            object[key] = manifestVersion;
          }
        }
      }
    };

    templateExperiment?.workflow.forEach((workflow: any) => {
      if (workflow.parameters) {
        updateValues(workflow.parameters);
      }
    });

    return templateExperiment;
  };

  const createExperiment = async (json: any) => {
    try {
      const response = await ganymedeExperimentRequestService.queueExperiment(labId.toString(), json, [], false, cancellationToken);
      const result = JSON5.parse(response[0].second);

      if (result.created) {
        const successfulResult = [
          {
            labId: labId.toString(),
            instanceId: result.id,
          },
        ];

        setSuccessExperiment(successfulResult);
        setCreateMessage(t('queued-experiment-successfully', { ns: NS.EDITOR }));
        setExperimentLoading(false);
      } else {
        const errorMessage = result?.Detail || result?.detail;
        const errorResult = [
          {
            machineId: labId.toString(),
            error: errorMessage,
          },
        ];

        setFailedExperiment(errorResult);
        setCreateMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data?.Detail || error.response.data?.detail : error.message;
      const errorResult = [
        {
          labID: labId.toString(),
          error: errorMessage,
        },
      ];

      setFailedExperiment(errorResult);
      setCreateMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
      setExperimentLoading(false);
    }
  };

  const upgradeLab = async () => {
    const message: string = t(Labs.UPDATE_LAB_CONFIRMATION, { ns: NS.LABS });

    if (!confirm(message)) {
      isDebugMode && console.log('[LabControl] User has aborted the updation of lab');
      return;
    }

    setLabUpgradeModalOpen(true);

    try {
      setExperimentLoading(true);
      const templateId = LabUpgrade.LAB_UPGRADE_TEMPLATE_NAME;
      const response = await ganymedeExperimentRequestService.getExperimentTemplateByName(templateId);
      const template = response[0];

      const updatedTemplate = await replaceLabUpgradeValues(template?.experiment);
      createExperiment(updatedTemplate);
    } catch (error) {
      console.error('[LabControl] Lab Upgrade failure :', error.message);
      setExperimentLoading(false);
    }
  };

  const getModalComponent = () => {
    if (isSARMAddEditOpen) {
      return (
        <DialogBox
          title={t(Labs.SARM_IP, { ns: NS.LABS })}
          subtext={t(Labs.SARM_SUB_TITLE, { ns: NS.LABS })}
          minWidth={'20%'}
          body={<AddEditSARMIPAddress hideModal={setIsSARMAddEditClose} />}
          hidden={!isSARMAddEditOpen}
          modalProps={{
            isBlocking: true,
          }}
          onDismiss={setIsSARMAddEditClose}
        />
      );
    }

    if (isUpdateModalOpen) {
      return (
        <ConfirmationComponent
          isConfirmModalOpen={isUpdateModalOpen}
          hideConfirmModal={hideUpdateModal}
          selectedItemKey={selectedMachineKey}
          labId={labId}
          action={Labels.update}
        />
      );
    }

    if (isSocInfoModalOpen) {
      return (
        <SocInfoComponent
          isModalOpen={isSocInfoModalOpen}
          hideModal={hideSocInfoModal}
          machineInfo={selectedMachineInfo}
          title={t('soc-info', { ns: NS.TITLES })}
        />
      );
    }

    if (isRackManagerInfoModalOpen) {
      return (
        <FirmwareInfoComponent
          isModalOpen={isRackManagerInfoModalOpen}
          hideModal={hideRackManagerInfoModal}
          rackManagerInfo={rackManagerInfo}
          selectedItemName={selectedMachineName}
        />
      );
    }

    if (isDeleteLabModalOpen) {
      return (
        <UserTypedComponent
          isModalOpen={isDeleteLabModalOpen}
          hideModal={hideDeleteLabModal}
          userTypedName={userTypedLabName}
          setUserTypedName={setUserTypedLabName}
          labId={labId}
          labName={labName}
          action={Labels.delete}
        />
      );
    }

    if (isLabAddUserModalOpen) {
      return (
        <PeoplePickerComponent
          isModalOpen={isLabAddUserModalOpen}
          hideModal={hideLabAddUserModal}
          selectedItemKey={labId}
          titleName={labName}
          action={Labels.addLabUser}
          labUserList={labUserList}
          getLabUser={getLabUser}
          title={t('manage-users', { ns: NS.TITLES })}
        />
      );
    }

    if (isUpdateOSImageModalOpen) {
      return (
        <SelectComponent
          isModalOpen={isUpdateOSImageModalOpen}
          hideModal={hideUpdateOSImageModal}
          labId={labId}
          defaultOsImage={defaultOSImage}
          action={Labels.updateOSImage}
          osImageList={OSImageList}
          fetch={fetchSystem}
          title={t('update-os-image', { ns: NS.TITLES })}
        />
      );
    }

    if (isAddTagModalOpen) {
      return (
        <AddTagComponent
          isModalOpen={isAddTagModalOpen}
          hideModal={hideAddTagModal}
          setTagOptions={setTagOptions}
          selectedItemKey={selectedMachineKey}
          labName={labName}
          fetch={fetchSystem}
          handleTagChanges={handleTagChanges}
        />
      );
    }

    if (isBatchOperationModalOpen) {
      return (
        <BatchOperationComponent
          isModalOpen={isBatchOperationModalOpen}
          hideModal={hideBatchOperationModal}
          rackMachineList={rackMachinesList}
          machineList={systemData}
          menuProps={MachineMenuProps}
          setSelectedMachineKey={setSelectedMachineKey}
          setSelectedRackKey={setSelectedRackKey}
          getosFriendlyName={getOsFriendlyName}
          selectedItemKey={selectedMachineKey}
          selectedRackKey={selectedRackKey}
          labName={labName}
          labId={labId}
          fetch={fetchSystem}
        />
      );
    }

    if (isAddRackManagerModalOpen) {
      return (
        <BasicModalComponent
          isModalOpen={isAddRackManagerModalOpen}
          hideModal={hideAddRackManagerModal}
          labId={labId}
          page={ManageRackManager}
          fetchLists={fetchSystem}
          action={Labels.addRackManager}
          title={t('rack-manager-info', { ns: NS.TITLES })}
        />
      );
    }

    if (isLabUpgradeModalOpen) {
      return (
        <LabUpgradeComponent
          isModalOpen={isLabUpgradeModalOpen}
          hideModal={() => {
            setLabUpgradeModalOpen(false);
            setFailedExperiment([]);
            setSuccessExperiment([]);
          }}
          experimentLoading={isExperimentLoading}
          commandItems={commandItems}
          creationMessage={creationMessage}
          successfulExperiments={successExperiment}
          failedExperiments={failedExperiment}
        />
      );
    }

    if (isFixIpAddressModalOpen) {
      return (
        <DialogBox
          title={t(Labs.FIX_IP_ADDRESS, { ns: NS.LABS })}
          subtext={''}
          minWidth={'40%'}
          body={
            <RepairLabMachine
              labId={labId}
              isIPAddressFix={true}
              hideModal={hideFixIpAddressModal}
              machineInfo={selectedMachineInfo}
            />
          }
          hidden={!isFixIpAddressModalOpen}
          modalProps={{
            isBlocking: true,
          }}
          onDismiss={hideFixIpAddressModal}
        />
      );
    }

    if (isFixMacAddressModalOpen) {
      return (
        <DialogBox
          title={t(Labs.FIX_MAC_ADDRESS, { ns: NS.LABS })}
          subtext={''}
          minWidth={'40%'}
          body={
            <RepairLabMachine
              labId={labId}
              isIPAddressFix={false}
              hideModal={hideFixMacAddressModal}
              machineInfo={selectedMachineInfo}
            />
          }
          hidden={!isFixMacAddressModalOpen}
          modalProps={{
            isBlocking: true,
          }}
          onDismiss={hideFixMacAddressModal}
        />
      );
    }
  };

  const subTableHelper = (
    item: {
      children: any;
      SlotNumber:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | Iterable<ReactI18NextChild>
        | null
        | undefined;
      MacAddress:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | Iterable<ReactI18NextChild>
        | null
        | undefined;
      Name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined;
      OsImageName: any;
      IPAddress:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | Iterable<ReactI18NextChild>
        | null
        | undefined;
      // eslint-disable-next-line @typescript-eslint/ban-types
      Status: {} | null | undefined;
      LabName:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | Iterable<ReactI18NextChild>
        | null
        | undefined;
      ModelName:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | Iterable<ReactI18NextChild>
        | null
        | undefined;
      LastHeartBeat: any;
      MachineId: any;
      HostSystemId: any;
      SocIPAddress: any;
      SocMACAddress: any;
    },
    index: number,
    vm: boolean,
    subTable: boolean,
  ) => (
    <tr key={`item2-${index}`}>
      <td className="lab-control-colxsm text-nowrap accent-color">
        <div className="machineIcon">
          <div>
            {item.children ? (
              <b>
                <Icon
                  iconName={selectedSubItem === index ? 'Remove' : 'Add'}
                  className="ChevronDown pointer clickable purple"
                  onClick={() => {
                    openSubItem(index);
                  }}
                />
              </b>
            ) : null}
          </div>
          <div>
            {subTable ? (
              <Icon iconName="Childof" className={`ChevronDown clickable pl-2 pr-2 ${vm ? 'purple ml-5' : 'ml-1'}`} />
            ) : null}
            <img src={vm ? purpleMachine : machine} className={`device-icon ${!subTable ? 'ml-4' : ''}`} alt="Machine" />
          </div>
        </div>
      </td>
      <td className="lab-control-colxsm">{item.SlotNumber}</td>
      <td className="lab-control-w10">
        <div>{item.Name}</div>
      </td>
      {isVirtual === 'true' ? <td className="lab-control-colsm text-nowrap">{item.LabName}</td> : null}
      {getAutoComplete(item)}
      <td className="lab-control-colsm">{item.IPAddress}</td>
      <td className="lab-control-w10">{item.MacAddress}</td>
      <td className="lab-control-w10">{item.ModelName}</td>
      <td className="lab-control-w15">{getOsFriendlyName(item.OsImageName)}</td>
      <td className="lab-control-w20">
        {(typeof item.Status === DataType.STRING || typeof item.Status === DataType.NUMBER) &&
        FriendlyStatus[item.Status as string] !== null
          ? FriendlyStatus[item.Status as string]
          : item.Status}
      </td>
      <td className="lab-control-w10 text-nowrap">{populateHealthStatus(item.LastHeartBeat, 'td')}</td>
      <td className="lab-control-colsm text-right">
        {/* <Icon iconName="Info" className="primaryLgIcon" onClick={() => handleFirmwareInfo(item.Name, item.MachineId)} /> */}
        {Labels.na}
      </td>
      <td className="lab-control-colsm text-right">
        {item.SocIPAddress && item.SocMACAddress ? (
          <Icon iconName="Info" title="Info" className="primaryLgIcon" onClick={() => handleSocInfo(item)} />
        ) : (
          Labels.na
        )}
      </td>
      <td className="lab-control-colsm">
        <IconButton
          menuProps={MachineMenuProps}
          iconProps={moreIcon}
          title="More"
          ariaLabel="More"
          onMenuClick={(e) => {
            setMachineIdprop(e, item, true);
            setVirtualMenuVisible(false);
            setSelectedMachineInfo([item]);
            item.HostSystemId ? setVMMenuDisable(true) : setVMMenuDisable(false);
          }}
        />
      </td>
    </tr>
  );

  const notifyUpdateIsAvailable =
    updateAvailable === true && (labDetailData as any).LabStatus !== newLabStatus && !(labDetailData as any).IsVirtualLab;
  const notifyAboutNewLabStatus = (labDetailData as any).LabStatus === newLabStatus;

  const allItems: ICommandBarItemProps[] = [
    {
      key: 'refresh-list',
      text: Buttons.refreshMachineList,
      title: Buttons.refreshMachineList,
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        fetchSystem();
      },
      disabled: allSystemData.length === 0,
    },
    {
      key: 'view-experiment-status',
      text: Buttons.viewExperimentStatus,
      title: Buttons.viewExperimentStatus,
      iconProps: { iconName: SystemIcons.VIEW_STATUS },
      onClick: () => {
        history.push({
          pathname: `${Navigation.GANYMEDE.EXPERIMENTS}/${labId}`,
        });
      },
    },
    {
      key: 'create-experiment',
      text: Buttons.createExperimentSteps,
      title: Buttons.createExperimentSteps,
      iconProps: { iconName: SystemIcons.ADD },
      onClick: () => history.push(`${Navigation.GANYMEDE.EXPERIMENT_EDITOR}/${labId}`),
    },
    {
      key: 'download-exe',
      text: Buttons.downloadExeLab,
      title: Buttons.downloadExeLab,
      iconProps: { iconName: SystemIcons.DOWNLOAD_DOCUMENT },
      onClick: () => downloadLab(true),
    },
    {
      key: 'download-msi',
      text: Buttons.downloadMsiLab,
      title: Buttons.downloadMsiLab,
      iconProps: { iconName: SystemIcons.DOWNLOAD_DOCUMENT },
      onClick: () => downloadLab(false),
    },
    {
      key: 'delete-lab',
      text: Buttons.deleteLab,
      title: Buttons.deleteLab,
      iconProps: { iconName: SystemIcons.DELETE },
      onClick: showDeleteLabModal,
    },
  ];
  const farItems: ICommandBarItemProps[] = [
    {
      key: 'edit-lab-name',
      text: t('edit-lab-name', { ns: NS.DEFAULT }),
      title: t('edit-lab-name', { ns: NS.DEFAULT }),
      iconProps: { iconName: SystemIcons.EDIT_LAB },
      onClick: () => {
        setEditMode(true);
      },
    },
    {
      key: 'manage-user',
      text: Buttons.manageUsers,
      title: Buttons.manageUsers,
      iconProps: { iconName: SystemIcons.MANAGE_USER },
      onClick: showLabAddUserModal,
      hidden: isPartnerMode ? true : !adminMenu,
    },
    {
      key: 'manage-rack-manager',
      text: Buttons.manageRackManager,
      title: Buttons.manageRackManager,
      onClick: showAddRackManagerModal,
      iconProps: { iconName: SystemIcons.MANAGE_RACK },
      hidden: (labDetailData as any).IsVirtualLab ? true : (labDetailData as any).LabStatus === newLabStatus,
    },
    {
      key: 'update-os-image',
      text: Buttons.updateDefaultOSImage,
      title: Buttons.updateDefaultOSImage,
      iconProps: { iconName: SystemIcons.UPDATE_OS_IMAGE },
      onClick: showUpdateOSImageModal,
    },
    {
      key: 'machine-batch-operation',
      text: Buttons.machineBatchOperation,
      title: Buttons.machineBatchOperation,
      iconProps: { iconName: SystemIcons.MACHINE_BATCH },
      onClick: showBatchOperationModal,
    },
  ];

  // NOTE: Currently unused.
  const filterItems: ICommandBarItemProps[] = [];

  return isBusy ? (
    <LoadingSpinner />
  ) : !loadLab ? (
    <div className="row">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
    </div>
  ) : (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages.filter((i) => !i.showInPopup)}</MessageBarTemplate>
      <main className="fullscreen container labs-wrapper">
        {isDeleteSARMLoading && (
          <div className={style['overlay-spinner']}>
            <LoadingSpinner size={SpinnerSize.medium} />
          </div>
        )}
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={subTitle}>
            {pageTitle}
          </PageHeader>
          <PageContent scrollable={false}>
            <div className="fullscreen">
              <PageCommandBar items={allItems} overflowItems={farItems}></PageCommandBar>
              {/* NOTE: We do not yet have filter items so keep this */}
              {/* <PageFilterBar items={filterItems}></PageFilterBar> */}

              {notifyUpdateIsAvailable ? (
                <div className="info-line text-center">
                  <span>
                    {Messages.updateIsAvailable}
                    {manifestVersion}
                  </span>
                  <span className="pointer" onClick={() => upgradeLab()}>
                    <u>{Labels.updateNow}</u>
                  </span>
                </div>
              ) : notifyAboutNewLabStatus ? (
                <div className="info-line-warning text-center">
                  <Icon iconName="Error" title="Error" />
                  <span className="pointer">
                    {Messages.please}
                    {Labels.downloadInstaller}
                    &nbsp;
                    <u onClick={() => downloadLab(true)}>
                      <b>{Labels.downloadExe}</b>
                    </u>
                    &nbsp; or &nbsp;
                    <u onClick={() => downloadLab(false)}>
                      <b>{Labels.downloadMsi}</b>
                    </u>
                    {Messages.forThisLab}
                    <a
                      href="https://msazure.visualstudio.com/One/_wiki/wikis/One.wiki/98781/Onboarding"
                      target="_blank"
                      rel="noreferrer"
                      className="info-line-warning"
                    >
                      <b>{Messages.onboardingWiki}</b>
                    </a>
                  </span>
                </div>
              ) : null}
              <br />
              <div className="row">
                <div className="col-sm-8">
                  <h3 className="text-left text-nowrap text-color">
                    {editMode ? (
                      <>
                        <TextField
                          label={Labels.editLabName}
                          variant="outlined"
                          value={labName}
                          className="w-50"
                          onChange={handleLabNameChange}
                        />
                        <span>
                          <Icon
                            iconName="CheckMark"
                            className="pointer ml-2 text-success"
                            title="Update Lab Name"
                            onClick={(e) => {
                              updateLabName(e);
                            }}
                          />
                          <Icon
                            iconName="Cancel"
                            className="pointer ml-2 text-secondary"
                            title="Cancel"
                            onClick={() => {
                              setEditMode(false);
                            }}
                          />
                        </span>
                      </>
                    ) : null}
                  </h3>
                  {(labDetailData as any).IsVirtualLab}
                  <p className="lab-version-text">
                    <Icon iconName="Info" className="mr-1" />
                    {`${Labels.labID}: `}
                    {labId}
                    {` | ${Labels.labVersion}: `}
                    {(labDetailData as any).IsVirtualLab ? Labels.virtualLab : labVersion === null ? Labels.na : labVersion}
                    {` |  ${Labels.defaultOS}: ${
                      (labDetailData as any).IsVirtualLab ? Labels.na : getOsFriendlyNameById(defaultOSImage)
                    }`}
                  </p>
                </div>
                <div className="col-sm-4 text-right">
                  {(labDetailData as any).IsVirtualLab ? null : populateHealthStatus((labDetailData as any).LastHeartBeat, 'main')}
                  <p className="lab-last-refresh">
                    <Icon iconName="Info" className="mr-1" />
                    {Messages.lastRefreshTime} {lastRefresh}
                  </p>
                  {/* <PrimaryButton className="btn-lg pt-2 pb-2 lab-action-btn" menuProps={LabMenuProps}>
                    <span
                      onClick={() => {
                        setVirtualMenuVisible(true);
                      }}
                    >
                      {` ${buttons.labAction} `}
                      <Icon iconName="CaretSolidDown" className="ml-2" />
                    </span>
                  </PrimaryButton> */}
                </div>
              </div>
              {!dataLoaded ? (
                <LoadingSpinner />
              ) : dataLoaded && allSystemData.length === 0 && rackMachinesList.length === 0 ? (
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <h5 className="pl-3">
                      <Icon iconName="Error" />
                      <span className="error-message">{` ${Messages.noDevicesFound}`}</span>
                    </h5>
                  </div>
                </div>
              ) : isLoading ? (
                <LoadingSpinner location={SpinLocation.BelowTableHeader} />
              ) : (
                <ScrollableContent scrollable={true} scrollableX={true}>
                  <Table className="sticky-header" striped>
                    <thead>
                      <tr>
                        <th>{TableHead.type}</th>
                        <th>{TableHead.slotNumber}</th>
                        <th>{TableHead.hostName}</th>
                        {isVirtual === 'true' ? <th>{TableHead.parentLabName}</th> : null}
                        <th>{TableHead.tags}</th>
                        <th>{TableHead.ipAddress}</th>
                        <th className="text-nowrap">{TableHead.macAddress}</th>
                        <th>{TableHead.modelName}</th>
                        <th>{TableHead.osImageName}</th>
                        <th>{TableHead.state}</th>
                        <th>{TableHead.machineStatus}</th>
                        <th>{TableHead.hardwareInfo}</th>
                        <th>{TableHead.socInformation}</th>
                        <th>{TableHead.actions}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rackMachinesList.map((item, index) => (
                        <React.Fragment key={`parent-${index}`}>
                          <tr key={index}>
                            <td className="lab-control-colxsm text-nowrap">
                              <b>
                                <Icon
                                  iconName={selectedItem === index ? 'Remove' : 'Add'}
                                  className="ChevronDown pointer clickable pr-2 mr-1"
                                  onClick={() => {
                                    openItem(index);
                                  }}
                                />
                              </b>
                              <img src={rack} className="device-icon" alt="Rack" />
                              <b>{` (${(item as any).machines.length})`}</b>
                            </td>
                            <td className="lab-control-colxsm" />
                            <td className="lab-control-w10">
                              <b>{(item as any).SerialNumber}</b>
                            </td>
                            {isVirtual === 'true' ? <td className="lab-control-colsm">{(item as any).LabName}</td> : null}
                            <td className="lab-control-w10 accent-color" />
                            <td className="lab-control-w10">
                              <b>{(item as any).IpAddress}</b>
                            </td>
                            <td className="lab-control-w10">{Labels.na}</td>
                            <td className="lab-control-w10">
                              <b>{(item as any).Name}</b>
                            </td>
                            <td className="lab-control-w20">{Labels.na}</td>
                            <td className="lab-control-w20">live</td>
                            <td className="lab-control-w15 text-nowrap">{getRackStatus(item)}</td>
                            <td className="lab-control-colsm text-right">
                              <Icon
                                iconName="Info"
                                className="primaryLgIcon"
                                onClick={() => handleFirmwareInfo((item as any).SerialNumber, null)}
                              />
                            </td>
                            <td className="lab-control-w10">{Labels.na}</td>
                            <td className="lab-control-colsm">
                              <IconButton
                                menuProps={RackMenuProps}
                                iconProps={moreIcon}
                                title="More"
                                ariaLabel="More"
                                onMenuClick={(e) => {
                                  setMachineIdprop(e, item, false);
                                }}
                              />
                            </td>
                          </tr>
                          {(item as any).machines ? (
                            <React.Fragment key={`fragment-${index}`}>
                              <tr key={`sub-${index}`} className="subset" />
                              <tr key={`item-${index}`} className={`item ${+selectedItem === index ? 'open' : ''}`}>
                                <td className="subset" colSpan={isVirtual === 'true' ? 14 : 13} id="rack-machines-table">
                                  <Table>
                                    <tbody>
                                      {(item as any).machines.map((item: any, index: number) => (
                                        <React.Fragment key={`machine-${index}`}>
                                          {subTableHelper(item, index, false, true)}
                                          {(item as any).children ? (
                                            <React.Fragment key={`children-${index}`}>
                                              <tr key={`sub-${index}`} className="subset" />
                                              <tr key={`item-${index}`}>
                                                <td
                                                  className={`${selectedSubItem === index ? '' : 'd-none'}`}
                                                  colSpan={isVirtual === 'true' ? 14 : 13}
                                                  id="vm-table"
                                                >
                                                  {(item as any).children.map((subItem: any, subIndex: any) => (
                                                    <React.Fragment key={`subItem-${subIndex}`}>
                                                      {subTableHelper(subItem, subIndex, true, true)}
                                                    </React.Fragment>
                                                  ))}
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          ) : null}
                                        </React.Fragment>
                                      ))}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      ))}
                      {systemData.map((item, index) => subTableHelper(item, index, false, false))}
                    </tbody>
                  </Table>
                </ScrollableContent>
              )}
              {getModalComponent()}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

export const LabsControl = observer(LabsControlFC);
