import { MessageBarType } from '@fluentui/react';
import { t } from 'i18next';

import LabListStore from '@/components/LabList/LabListStore';
import LabPanelStore from '@/components/LabPanel/LabPanelStore';
import { EventKeys, FailGroupIds, KeyTextPair, SuccessGroupIds, SystemConstants } from '@/constants/SystemConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Common, Labs } from '@/constants/TranslationConstants';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { isValidEmail } from '@/utils/Helpers';

import CreateLabStore from './CreateLabStore';
import { CreateLabType, LabUser, OSImageType } from './CreateLabType';

class CreateLabViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected createLabStore: CreateLabStore;
  protected labListStore: LabListStore;
  protected labPanelStore: LabPanelStore;
  protected systemMessageStore: SystemMessageStore;

  protected setOsImageList: (images: OSImageType[]) => void;
  protected setOsImageFilteredList: (images: OSImageType[]) => void;
  protected setLabUsers: (users: string[]) => void;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, createLabStore, labListStore, labPanelStore, systemMessageStore } = rootStore;
    const { setOsImageList, setOsImageFilteredList, setLabUsers, setCompanyValue, setIsInternal } = createLabStore;

    this.appSettingsStore = appSettingsStore;
    this.createLabStore = createLabStore;
    this.labListStore = labListStore;
    this.labPanelStore = labPanelStore;
    this.systemMessageStore = systemMessageStore;

    this.setOsImageList = setOsImageList;
    this.setOsImageFilteredList = setOsImageFilteredList;
    this.setLabUsers = setLabUsers;

    setCompanyValue(SystemConstants.MS_COMPANY_ID);
    setIsInternal(true);
  }

  getOSImages = async (): Promise<void> => {
    await ganymedeLabRequestService
      .getOSImages()
      .then((result) => {
        if (result?.length > 0) {
          const mappedData: OSImageType[] = this.buildDataMapping(result);

          this.setOsImageList(mappedData);
          this.setOsImageFilteredList(mappedData);
          this.populateOSDropdown();
        }
      })
      .catch((error) => {
        console.error('Error while fetching OS Images :', error);
      });
  };

  protected buildDataMapping = (results: any): OSImageType[] => {
    const mappedData: OSImageType[] = results.map((result: any) => ({
      imageId: result.OsImageId,
      imageName: result.OsImageName,
      friendlyName: result.OsImageFriendlyName,
      imageBuild: result.OsImageBuild,
      description: result.OsImageDescription,
      isInternal: result.IsInternal,
    }));

    return mappedData;
  };

  onCompanyChange = (event: React.FormEvent<HTMLDivElement>, item: KeyTextPair): void => {
    const { osImageList, setCompanyValue, setIsInternal, setOsImageFilteredList } = this.createLabStore;

    const companyId = Number(item.key);

    setCompanyValue(companyId);

    if (companyId !== SystemConstants.MS_COMPANY_ID) {
      const imageExternalList = osImageList.filter((osImage: OSImageType) => !osImage.isInternal);

      setOsImageFilteredList(imageExternalList);
      setIsInternal(false);

      this.populateOSDropdown();

      return;
    }

    setIsInternal(true);
    setOsImageFilteredList(osImageList);

    this.populateOSDropdown();
  };

  protected populateOSDropdown = (imageList?: OSImageType[]): void => {
    const { osImageFilteredList, setOsImages } = this.createLabStore;

    const options: KeyTextPair[] = [];

    imageList = imageList || osImageFilteredList;
    imageList.map((image: OSImageType) => options.push({ key: image.imageId.toString(), text: image.friendlyName }));
    setOsImages(options);
  };

  getCompanies = async (): Promise<void> => {
    await ganymedeLabRequestService
      .getCompanies()
      .then((result) => {
        if (result.length > 0) {
          this.populateCompanyDropdown(result);
        }
      })
      .catch((error) => {
        console.error('Error fetching companies :', error);
      });
  };

  getAvailableOSPerCompany = (): any => {
    const { companyValue, osImageList } = this.createLabStore;

    if (companyValue) {
      const os =
        companyValue !== SystemConstants.MS_COMPANY_ID
          ? osImageList.filter((image: OSImageType) => !image.isInternal)
          : osImageList;

      return os;
    }

    return osImageList;
  };

  populateCompanyDropdown = (company: any[]): void => {
    const { setCompanies } = this.createLabStore;

    const options: KeyTextPair[] = [];

    company.map((item: any) => options.push({ key: (item as any).CompanyId, text: (item as any).CompanyName }));
    setCompanies(options);
  };

  getLabUsers = async () => {
    await ganymedeLabRequestService
      .getUsers()
      .then((response: string[]) => {
        if (response) {
          this.setLabUsers(response);
        }
      })
      .catch((error) => {
        console.error('Error while fetching users :', error);
      });
  };

  protected validateMandate = (): boolean => {
    const { labNameValue, osImageValue } = this.createLabStore;
    const { addGlobalMessage } = this.systemMessageStore;

    const isValidData: boolean = !!labNameValue && !!osImageValue;

    if (!isValidData) {
      const failMessage: SystemMessageType = {
        message: t(Common.ENTER_FIELD, { ns: NS.COMMON }),
        type: MessageBarType.error,
        groupId: FailGroupIds.LAB_CREATE,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);
    }

    return isValidData;
  };

  saveLab = async (): Promise<void> => {
    const { labNameValue, adminsValue, osImageValue, companyValue, isInternal, setIsCreatingLab } = this.createLabStore;
    const { setIsLabRefreshed } = this.labListStore;
    const { closeLabPanel } = this.labPanelStore;
    const { addGlobalMessage } = this.systemMessageStore;

    const isValidData: boolean = this.validateMandate();

    if (isValidData) {
      setIsCreatingLab(true);

      const createLabData: CreateLabType = {
        LabName: labNameValue.trim(),
        IsInternal: isInternal,
        CsiToolkitId: 0,
        DefaultOsImageId: Number(osImageValue),
        CompanyId: companyValue,
      };

      try {
        const labID: number = await ganymedeLabRequestService.createNewLab(createLabData);

        if (labID) {
          setIsCreatingLab(false);
          setIsLabRefreshed(true);

          if (adminsValue) {
            const labAdmins: LabUser[] = adminsValue.map((email: string) => ({
              UserEmail: email,
              IsAdmin: true,
            }));

            const response = ganymedeLabRequestService.addUsers(labID, labAdmins);
            console.log(response);
          }

          const successMessage: SystemMessageType = {
            message: t(Labs.CREATE_LAB_SUCCESS, { ns: NS.LABS }),
            type: MessageBarType.success,
            groupId: SuccessGroupIds.LAB,
          };

          addGlobalMessage(successMessage);
          closeLabPanel();
        }
      } catch (error) {
        setIsCreatingLab(false);
        console.error('Error while saving lab :' + error);
      }
    }
  };

  isValidEmailAddress = (event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
    if (event.key.toLowerCase() === EventKeys.ENTER) {
      return isValidEmail(newValue, this.systemMessageStore, FailGroupIds.LAB);
    }

    return false;
  };
}

export default CreateLabViewModel;
