import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { DefaultButton } from '@fluentui/react';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import config from '@/components/_labs/LabsHelper/LabUpgrade.config.json';
import { LabUpgrade } from '@/constants/ExperimentEditorConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';

import styles from '@/components/MachineSelect/MachineSelect.module.css';

interface LabUpgradeComponentProps {
  commandItems: any[];
  experimentLoading: boolean;
  successfulExperiments: any[];
  failedExperiments: any[];
  creationMessage: string;
  isModalOpen: boolean;
  hideModal: () => void;
}

export const LabUpgradeComponent = (props: LabUpgradeComponentProps) => {
  const { commandItems, experimentLoading, successfulExperiments, failedExperiments, creationMessage, isModalOpen, hideModal } =
    props;

  return (
    <div>
      <DialogBox
        title={t('queue-experiment', { ns: NS.EDITOR })}
        subtext={''}
        minWidth={'75%'}
        body={
          <>
            <div className={styles.height}>
              <PageCommandBar items={commandItems} />
              {experimentLoading ? (
                <div>
                  <h6 className="mb-6">
                    <Trans ns={NS.EDITOR as Namespace}>queuing-for-execution</Trans>
                  </h6>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className={styles.report}>
                  <div>
                    <h6 className="mb-6">{`${LabUpgrade.LAB_UPGRADE_TEMPLATE_NAME} ${t('experiment-creation-report', {
                      ns: NS.EDITOR,
                    })}`}</h6>
                  </div>
                  <h5>{creationMessage}</h5>
                  {successfulExperiments.length !== 0 && (
                    <>
                      <h6>
                        <Trans ns={NS.TITLES as Namespace}>success-experiment-report</Trans>
                      </h6>
                      <TableViewController
                        tableStyle={styles['table-scrollable']}
                        items={successfulExperiments}
                        columns={config.creationSuccessfulColumns}
                        enableToolBar={false}
                        selectionMode={SelectionMode.none}
                      />
                    </>
                  )}

                  {failedExperiments.length !== 0 && (
                    <>
                      <h6>
                        <Trans ns={NS.TITLES as Namespace}>failed-experiment-report</Trans>
                      </h6>
                      <TableViewController
                        tableStyle={styles['table-scrollable']}
                        items={failedExperiments}
                        columns={config.creationFailedColumns}
                        enableToolBar={false}
                        selectionMode={SelectionMode.none}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
            <DefaultButton onClick={hideModal} className={styles.button} text={t('close', { ns: NS.COMMON })} />
          </>
        }
        hidden={!isModalOpen}
        modalProps={{
          isBlocking: true,
        }}
        onDismiss={hideModal}
      />
    </div>
  );
};
