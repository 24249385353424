import { message } from 'antd';

import { Navigation } from '@/constants/NavigationConstants';
import { copyToClipboard } from '@/utils/Helpers';

import '@/styles/Labs.css';

export const copyTestRunURL = (resultCollectionId: string, templateId: string, companyName: string) => {
  const copyText = `${window.location.origin.toLowerCase()}${
    Navigation.LABS.TEST_RUN
  }/${templateId}=${resultCollectionId}&companyName=${companyName}`;

  copyToClipboard(copyText);

  message.success({
    content: `Result link copied - ${copyText}`,
    className: 'custom-class',
    style: {
      marginTop: '20vh',
    },
  });
};

export const decodeBase64 = (s) => {
  const e = {};
  let i;
  let b = 0;
  let x;
  let l = 0;
  let a;
  let r = '';
  const w = String.fromCharCode;
  const L = s.length;
  const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i;
  }

  for (x = 0; x < L; x++) {
    const c = e[s.charAt(x)];
    b = (b << 6) + c;
    l += 6;

    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
    }
  }

  return r;
};

export const convertDateFormatFromDMYToMDY = (dateTimeString: string) => {
  const temp = dateTimeString.split(' ');
  const dmy = temp[0].split('/');
  const mdy = `${dmy[1]}/${dmy[0]}/${dmy[2]}`;
  const mdyDate = `${mdy} ${temp[1]} ${temp[2]}`;

  return mdyDate;
};
