import React from 'react';

import ExperimentResult from '@/components/_air/experiments/Result/ExperimentResult';

import '@/styles/Air.css';
import './experimentsPage.css';

export const ExperimentsPage = () => (
  <>
    <ExperimentResult />
  </>
);
