import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { APIErrorInterface } from '@/types/_air';
import { ExperimentStatus } from '@/types/_air/ExperimentStatus';
import { backendEndpoint } from '@/utils/Env';

class ExperimentRequestService extends BaseRequestService {
  async getListData(dataArr: any, cancelToken?: CancelToken): Promise<ExperimentStatus[] | APIErrorInterface> {
    const { data } = await this.post('Experiment/GetListData', dataArr, { cancelToken });

    return data;
  }
}

export const experimentRequestService = new ExperimentRequestService(backendEndpoint);
