import React from 'react';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection } from '@fluentui/react/lib/Selection';

import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import { PaginationType } from '@/partials/Pagination/PaginationTypes';
import { ActionTypeWithParamReturn, ActionTypeWithTParam } from '@/types/AppSettingsTypes';

enum ExperimenDetailsAccordionKeys {
  ESSENTIALS = 'essentials',
  JSON = 'json',
}

type ExperimentDetailsVMType = {
  title: string;
  labId: string;
  instanceId: string;
  loadMessage?: string;
  filterAgentStepData?: (
    steps: any[],
    search: string,
    status: string[],
    stepType: string[],
    startDate,
    endDate,
    timeRange,
  ) => any[];
};

type ExperimentDetailsVCType = {
  title: string;
  labId: string;
  instanceId: string;
  content: React.ReactElement;
  setContent: any;
  localMessage: string;
  setLocalMessage: ActionTypeWithTParam<string>;
  onDismissMessage: onDismissType;
  showModalCloseButton: boolean;
  copyButton: ActionTypeWithParamReturn;
  commandItems: any;
  isLoading: boolean;
  onLinkClick: any;
  agentFilterItems: IOverflowSetItemProps[];
  agentStepChanges: string | string[] | Date;
  selection: Selection;
  filteredSteps: any;
  chart: React.ReactElement;
  paginationType: PaginationType;
};

type ExperimentRowType = {
  name?: string;
  status: string;
  stepType: string;
  startTime?: string;
  endTime?: string;
  timestamp: string;
};

export { ExperimenDetailsAccordionKeys, ExperimentDetailsVCType, ExperimentDetailsVMType, ExperimentRowType };
