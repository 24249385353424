import { action, makeObservable, observable } from 'mobx';

import { RootStore } from '@/stores/RootStore';

class RawLogsTreeViewStore {
  public isLargeFile = false;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      isLargeFile: observable,

      // These values are persisted.
      setIsLargeFile: action,
    });
  }
  public setIsLargeFile = (largeFile: boolean) => {
    this.isLargeFile = largeFile;
  };
}

export default RawLogsTreeViewStore;
