import { CancelToken } from 'axios';

import BaseRequestService from './RequestService';

class ADOQueryRequestService extends BaseRequestService {
  async runQuery(queryParams: object, cancelToken?: CancelToken): Promise<string> {
    const response = await this.get('ado', queryParams, { cancelToken });
    return response?.data as string;
  }
}

export const adoQueryRequestService = new ADOQueryRequestService();
