import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Tab, TabList, Tabs } from 'react-tabs';

import { LazyTabPanel } from '@/components/_air/common/LazyTabPanel';
import { ExperimentStatusKeys, ExperimentStatusLabels, Labels, Routes, TenantIds } from '@/constants/AirConstants';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { tenantId as GanymedeTenantId } from '@/utils/Env';

import { ReportDashboard } from './ReportDashboard';

import 'react-tabs/style/react-tabs.css';
import '@/styles/Air.css';
import './result.css';

const statusTabsMap = new Map([
  [Labels.summary, 0],
  [ExperimentStatusKeys.AVA, 1],
  [ExperimentStatusKeys.INF, 2],
  [ExperimentStatusKeys.PER, 3],
  [ExperimentStatusKeys.REL, 4],
  [Labels.nodelg, 5],
  [Labels.debug, 6],
]);

const defaultState = {
  colorsByName: {},
};

interface ScenarioParams {
  name: string;
  revision: string;
  tab: string;
}

export const Scenario = (data: any): JSX.Element => {
  const params: ScenarioParams = data.match.params;
  const { name: experimentName, revision, tab } = params;
  const history = useHistory();
  const actualState = { ...defaultState };
  const { colorsByName } = actualState;

  const [tabIndex, setTabIndex] = React.useState(statusTabsMap.get(tab) ?? 0);
  const [color, setColor] = React.useState('');
  const [tabMargin, setTabMargin] = React.useState(0);
  const [tenantId, setTenantId] = React.useState('');

  React.useEffect(() => {
    setTenantId(GanymedeTenantId);
  });

  const onTabClick = (tab: string, color: string) => {
    history.push(`${Routes.scenario.link}/${experimentName}/${revision}/${tab}`, { colorsByName });

    setColor(color);
  };

  const onTabSelect = (tab) => {
    setTabIndex(tab);

    setTabMargin(1);

    setTimeout(() => {
      setTabMargin(0);
    }, 5);
  };

  return (
    <div className="air-wrapper">
      <div className="result-container">
        <h4 className="text-left all-in-one text-nowrap">
          <Link to={{ pathname: Routes.experimentsPage.link }} className="breadcrumbs">
            {Labels.experiments}
          </Link>
          {` ${TITLE_DIVIDER} ${experimentName} (${revision})`}
        </h4>
        <Tabs
          className="tabs"
          selectedIndex={tabIndex}
          onSelect={(tab) => {
            onTabSelect(tab);
          }}
        >
          <TabList className={`react-tabs__tab-list ${color}`}>
            <Tab key={Labels.summary} onClick={() => onTabClick(Labels.summary, '')}>
              {Labels.summary}
            </Tab>
            {Object.keys(ExperimentStatusLabels).map((key: string) => (
              <Tab
                key={ExperimentStatusLabels[key as string]}
                className={`react-tabs__tab status-tab ${colorsByName[key as string] ?? ''}`}
                selectedClassName={`react-tabs__tab--selected ${colorsByName[key as string] ?? ''}`}
                onClick={() => onTabClick(key, colorsByName[key as string] ?? '')}
              >
                {ExperimentStatusLabels[key as string]}
              </Tab>
            ))}
            <Tab key={Labels.nodelg} onClick={() => onTabClick(Labels.nodelg, '')}>
              {Labels.nodelg}
            </Tab>
            <Tab
              key={Labels.debug}
              style={{ display: [TenantIds.MS, TenantIds.AME].includes(tenantId) ? '' : 'none' }}
              onClick={() => onTabClick(Labels.debug, '')}
            >
              {Labels.debug}
            </Tab>
          </TabList>
          <LazyTabPanel style={{ marginRight: tabMargin } as React.CSSProperties}>
            <ReportDashboard experimentName={experimentName} revision={revision} page="SUM" />
          </LazyTabPanel>
          {Object.keys(ExperimentStatusLabels).map((key) => (
            <LazyTabPanel key={ExperimentStatusLabels[key as string]} style={{ marginRight: tabMargin } as React.CSSProperties}>
              <ReportDashboard experimentName={experimentName} revision={revision} page={key} />
            </LazyTabPanel>
          ))}
          <LazyTabPanel style={{ marginRight: tabMargin } as React.CSSProperties}>
            <ReportDashboard experimentName={experimentName} revision={revision} page={Labels.nodelg} />
          </LazyTabPanel>
          <LazyTabPanel style={{ marginRight: tabMargin } as React.CSSProperties}>
            <ReportDashboard experimentName={experimentName} revision={revision} page={Labels.debug} />
          </LazyTabPanel>
        </Tabs>
      </div>
    </div>
  );
};
