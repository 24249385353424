import React from 'react';
import { mergeStyleSets, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { ITooltipHostProps, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

import { Tooltip } from './Tooltip';

import '@/styles/Air.css';

const overflowTooltipStyles: ITooltipHostStyles = {
  root: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const OverflowTooltip = (props: ITooltipHostProps): JSX.Element => {
  const styles: ITooltipHostStyles = React.useMemo(() => mergeStyleSets(overflowTooltipStyles, props.styles), [props.styles]);

  return (
    <Tooltip overflowMode={TooltipOverflowMode.Self} content={props.content || (props.children as JSX.Element)} styles={styles}>
      {props.children}
    </Tooltip>
  );
};
