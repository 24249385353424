import React from 'react';
import { observer } from 'mobx-react-lite';

import GraphTile from './GraphRenderer';
import TableTile from './TableRenderer';
import { TileRendererGraphDataType, TileRendererTableDataType, TileRendererTemplateType } from './TileRendererTypes';

import styles from './TileRenderer.module.css';

const TileRendererFC: React.FC<TileRendererTemplateType> = (props: TileRendererTemplateType): React.ReactElement => {
  const { id, item, type, widgetTileId, record, widgetData, panelData } = props;

  const height = 200;
  const width = 200;
  const margin = { left: 10, right: 10 };

  // NOTE: Do not delete the following comment, necessary due to linting rules.
  /* eslint-disable padding-line-between-statements */
  switch (type) {
    case 'shadow':
      return (
        <div className={styles['tile-data']}>
          <div>
            <span className={styles['tile-header']}>id</span>: {id}
          </div>
          <div>
            <span className={styles['tile-header']}>widgetTileId</span>: {widgetTileId}
          </div>
          <div>
            <span className={styles['tile-header']}>type</span>: {type}
          </div>
          <div>
            <span className={styles['tile-header']}>item</span>: {JSON.stringify(item, null, 2)}
          </div>
          <div>
            <span className={styles['tile-header']}>record</span>: {JSON.stringify(record, null, 2)}
          </div>
          <div>
            <span className={styles['tile-header']}>widgetData</span>: {JSON.stringify(widgetData, null, 2)}
          </div>
          <div>
            <span className={styles['tile-header']}>panelData</span>: {JSON.stringify(panelData, null, 2)}
          </div>
        </div>
      );

    case 'clock':
      return <div>clock</div>;

    case 'line':
    case 'bar':
    case 'chart':
    case 'area':
    case 'pie':
    case 'graph': {
      const data: TileRendererGraphDataType[] = widgetData as TileRendererGraphDataType[];

      return <GraphTile type={type} data={data} width={width} height={height} margin={margin} />;
    }

    case 'table': {
      const data: TileRendererTableDataType = widgetData as TileRendererTableDataType;

      return <TableTile fullView={false} items={data.items} columns={data.columns} displayColumns={data.displayColumns} />;
    }

    default:
      // NOTE: We are rendering a Tile that has an unsupported or unknown type.
      console.log(`[TileRendererFC] Warning! Could not load the widget information for ${id} of type ${type}`);

      return <></>;
  }
};

const TileRenderer = observer(TileRendererFC);

export default TileRenderer;
