import React from 'react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';
import JSON5 from 'json5';

import {
  ExperimentFailedReportType,
  ExperimentSuccessReportType,
  LabsType,
  MachinesType,
  OSImagesType,
} from '@/components/MachineSelect/MachineSelectTypes';
import { Labels, Namespaces as NS } from '@/constants/SystemConstants';
import { labsRequestService } from '@/services/_labs/request-services/LabsRequestService';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { setTableDataGroupBy } from '@/utils/GroupBy';

import MachineSelectStore from './MachineSelectStore';

class MachineSelectViewModel {
  protected _searchValue: string;
  protected _windowState: string;
  protected _labs: LabsType[];
  protected _isLabsLoading: boolean;
  protected _machines: MachinesType[];
  protected _machinesGroups: any[];
  protected _isMachinesLoading: boolean;
  protected _experiment: string;

  protected _isExperimentLoading: boolean;
  protected _createdName: string;
  protected _creationMessage: string;
  protected _successfulExperiments: ExperimentSuccessReportType[];
  protected _failedExperiments: ExperimentFailedReportType[];

  protected appSettingsStore: AppSettingsStore;
  protected systemMessageStore: SystemMessageStore;
  protected machineSelectStore: MachineSelectStore;

  protected _setSearchValue: ActionTypeWithParam;
  protected _setWindowState: ActionTypeWithParam;

  failGroupId = 'experiment-queue-labs-fail';

  machinesGroupColumns: IColumn[] = [
    {
      key: 'rackName',
      name: 'rackName',
      fieldName: 'RackName',
      minWidth: 130,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  constructor(rootStore: RootStore) {
    const { machineSelectStore, appSettingsStore, systemMessageStore, experimentEditorStore } = rootStore;
    const {
      searchValue,
      setWindowState,
      setSearchValue,
      labs,
      isLabsLoading,
      machines,
      machinesGroups,
      isMachinesLoading,
      isExperimentLoading,
      createdName,
      creationMessage,
      successfulExperiments,
      failedExperiments,
    } = machineSelectStore;
    const { getExperiment } = experimentEditorStore;

    this.appSettingsStore = appSettingsStore;
    this.machineSelectStore = machineSelectStore;
    this.systemMessageStore = systemMessageStore;

    this._searchValue = searchValue;
    this._labs = labs;
    this._isLabsLoading = isLabsLoading;
    this._machines = machines;
    this._machinesGroups = machinesGroups;
    this._isMachinesLoading = isMachinesLoading;
    this._experiment = getExperiment;
    this._isExperimentLoading = isExperimentLoading;
    this._createdName = createdName;
    this._creationMessage = creationMessage;
    this._successfulExperiments = successfulExperiments;
    this._failedExperiments = failedExperiments;

    this._setSearchValue = setSearchValue;
    this._setWindowState = setWindowState;
  }

  public get searchValue(): string {
    return this._searchValue;
  }

  public get windowState(): string {
    return this._windowState;
  }

  public get labs(): LabsType[] {
    return this._labs;
  }

  public get isLabsLoading(): boolean {
    return this._isLabsLoading;
  }

  public get machines(): MachinesType[] {
    return this._machines;
  }

  public get machinesGroups(): any[] {
    return this._machinesGroups;
  }

  public get isMachinesLoading(): boolean {
    return this._isMachinesLoading;
  }

  public get experiment(): string {
    return this._experiment;
  }

  public get isExperimentLoading(): boolean {
    return this._isExperimentLoading;
  }

  public get createdName(): string {
    return this._createdName;
  }

  public get creationMessage(): string {
    return this._creationMessage;
  }

  public get successfulExperiments(): ExperimentSuccessReportType[] {
    return this._successfulExperiments;
  }

  public get failedExperiments(): ExperimentFailedReportType[] {
    return this._failedExperiments;
  }

  public setSearchValue = (searchOpt: string) => {
    this._setSearchValue(searchOpt);
  };

  public setWindowState = (windowState: number) => {
    this._setWindowState(windowState);
  };

  // Gather the users' labs
  public loadLabs = async (labSelection: Selection) => {
    const { isDebugMode } = this.appSettingsStore;
    const { setLabs, setLabsLoading } = this.machineSelectStore;

    isDebugMode && console.log('[MachineSelectFC] Fetching Labs Data.');

    setLabsLoading(true);

    try {
      const labsLists: LabsType[] = await ganymedeLabRequestService.getLabs();

      labsLists.forEach(async (lab) => {
        lab.LastHeartBeat = Labels.UNKNOWN;
      });

      // In order for react to recognize the change in the state (and rerender), foreach loop cannot be used
      for (let i = 0; i < labsLists.length; i++) {
        const lab = labsLists[i as number];

        lab.LastHeartBeat = await labsRequestService
          .getAgentHeartbeat(lab.LabId.toString())
          .then((response) => {
            return response.lastModified;
          })
          .catch((error) => {
            return null;
          });
      }

      setLabs(labsLists);
    } catch (error) {
      console.error('[MachineSelectFC] Error fetching lab lists:', error);
    }

    setLabsLoading(false);
  };

  public loadMachines = async (labId: number, machinesSelection: Selection) => {
    const { isDebugMode } = this.appSettingsStore;
    const { setMachines, setMachinesLoading } = this.machineSelectStore;

    isDebugMode && console.log('[MachineSelectFC] Fetching Machine Data.');
    setMachinesLoading(true);
    setMachines([]);

    let returnedRacks = [];
    let returnedMachines = [];

    await ganymedeLabRequestService
      .getRackManager(labId)
      .then((result) => {
        if (result.length > 0) {
          returnedRacks = result;
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore: this.systemMessageStore,
          appSettingsStore: this.appSettingsStore,
          failGroupId: this.failGroupId,
        };

        HandleError(handleErrorProps);
      });

    await this.fetchAvailableOSImages().then(async (osImages) => {
      await ganymedeLabRequestService.getSystem(labId).then(async (result) => {
        result.sort((item1: MachinesType, item2: MachinesType) => item1.SlotNumber - item2.SlotNumber);

        for (let i = 0; i < result.length; i++) {
          const data = result[i as number];

          data.LastHeartBeat = Labels.UNKNOWN;

          // Tags
          let tagString = '';

          data.Tags.map((tag, index) =>
            index !== data.Tags.length - 1 ? (tagString += tag.TagName + ', ') : (tagString += tag.TagName),
          );
          data.Tags = tagString;

          // OS Image Name
          data.OsImageName = this.getOsFriendlyName(data.OsImageName, osImages);

          // Rack Name
          const rack = returnedRacks.find((item) => item.RackId === data.RackId);
          data.RackName = rack ? rack.SerialNumber : '';

          result[i as number] = data;
        }

        returnedMachines = result;
      });
    });

    this.setGroupByData(returnedMachines, this.machinesGroupColumns[0].key);

    setMachinesLoading(false);

    // In order for react to recognize the change in the state (and rerender) foreach cannot be used
    for (let i = 0; i < returnedMachines.length; i++) {
      const machine = returnedMachines[i as number];

      machine.LastHeartBeat = await this.getMachineHeartBeat(machine);
    }

    // We need to reset the selection as react believes the data has changed completely and the selection is invalid
    const selectedMachines = machinesSelection.getSelection();

    this.setGroupByData(returnedMachines, this.machinesGroupColumns[0].key);

    if (selectedMachines.length === 0) {
      return;
    }

    await selectedMachines.forEach(async (machine) => {
      const index = machinesSelection
        .getItems()
        .findIndex((item) => (item as MachinesType).MachineId === (machine as MachinesType).MachineId);

      if (index !== -1) {
        machinesSelection.setIndexSelected(index, true, false);
      }
    });
  };

  setGroupByData = (tableData: any[], groupByField: string) => {
    const { setMachines, setMachinesGroups } = this.machineSelectStore;

    const groupByColumn = groupByField;
    const { returnData, groups } = setTableDataGroupBy(tableData, groupByColumn, this.machinesGroupColumns);

    // Name the individual devices group
    const index = groups.findIndex((group) => group.name == '');

    if (index !== -1) {
      groups[index as string].name = t('individual-devices', { ns: NS.TABLE });
      groups.push(groups.shift()); // Swap the individual devices to the last element in the groups so it shows up on the bottom
    }

    setMachines(returnData);
    setMachinesGroups(groups);
  };

  getMachineHeartBeat = async (item) => {
    const agentId = `${item.LabControllerId},${item.MacAddress},${item.SlotNumber},${item.IPAddress}`;
    const date = await labsRequestService
      .getAgentHeartbeat(agentId)
      .then((result) => {
        return result.lastModified;
      })
      .catch((error) => {
        return null;
      });

    return date;
  };

  getOsFriendlyName = (name: string, osImageList: OSImagesType[]) => {
    const os = osImageList.filter((osImage) => osImage.OsImageName.includes(name));

    return os.length > 0 ? os[0].OsImageFriendlyName : '';
  };

  fetchAvailableOSImages = async () => {
    return await ganymedeLabRequestService
      .getOSImages()
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore: this.systemMessageStore,
          appSettingsStore: this.appSettingsStore,
          failGroupId: this.failGroupId,
        };

        HandleError(handleErrorProps);
        return [];
      });
  };

  public createExperiment = async (isLab: boolean, selectedLabId: number, selectedMachines: MachinesType[]) => {
    const { setExperimentLoading, setCreatedName, setCreationMessage, setFailedExperiments, setSuccessfulExperiments } =
      this.machineSelectStore;

    setExperimentLoading(true);
    setFailedExperiments([]);
    setSuccessfulExperiments([]);
    setCreationMessage('');
    setCreatedName('');

    try {
      const json = JSON5.parse(this.experiment);

      if (json.name === undefined) {
        const errorResult = [
          {
            machineId: selectedLabId.toString(),
            error: t('queued-experiment-name-error', { ns: NS.ERRORS }),
          },
        ];

        setFailedExperiments(errorResult);
        setCreationMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
        setExperimentLoading(false);
        return;
      }

      setCreatedName(json.name ? json.name : t('experiment', { ns: NS.COMMON }));

      if (isLab) {
        //Lab Experiment
        try {
          const response = await ganymedeExperimentRequestService.queueExperiment(
            selectedLabId.toString(),
            this.experiment,
            selectedMachines,
            false,
          );

          const result = JSON5.parse(response[0].second);

          if (result.created) {
            const successfulResult = [
              {
                machineId: selectedLabId.toString(),
                instanceId: result.id,
              },
            ];

            setSuccessfulExperiments(successfulResult);
            setCreationMessage(t('queued-experiment-successfully', { ns: NS.EDITOR }));
          } else {
            const errorMessage = result?.Detail || result?.detail || t('experiment-queued-failed', { ns: NS.EDITOR });
            const errorResult = [
              {
                machineId: selectedLabId.toString(),
                error: errorMessage,
              },
            ];

            setFailedExperiments(errorResult);
            setCreationMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
          }

          setExperimentLoading(false);
        } catch (error) {
          const errorMessage = this.getErrorMessage(error);

          const errorResult = [
            {
              machineId: selectedLabId.toString(),
              error: errorMessage,
            },
          ];

          setFailedExperiments(errorResult);
          setCreationMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
          setExperimentLoading(false);
        }
      } else {
        if (selectedMachines.length === 0) {
          const handleErrorProps = {
            error: t('queued-experiment-error', { ns: NS.ERRORS }),
            systemMessageStore: this.systemMessageStore,
            appSettingsStore: this.appSettingsStore,
            failGroupId: this.failGroupId,
          };

          HandleError(handleErrorProps);
          setExperimentLoading(false);
        } else {
          try {
            const machineMap = new Map(this.machines?.map((machine) => [machine.MachineId, machine]));
            const successfulResults = [];
            const errorResults = [];
            const successIds = [];

            const networkingWorkload = this.checkNetworkingWorkload();

            if (networkingWorkload && selectedMachines.length % 2 !== 0) {
              const handleErrorProps = {
                error: t('select-machines-even-error', { ns: NS.ERRORS }),
                systemMessageStore: this.systemMessageStore,
                appSettingsStore: this.appSettingsStore,
                failGroupId: this.failGroupId,
              };

              HandleError(handleErrorProps);
              setExperimentLoading(false);
              return;
            }

            const response = await ganymedeExperimentRequestService.queueExperiment(
              selectedLabId.toString(),
              this.experiment,
              selectedMachines,
              networkingWorkload,
            );

            for (let i = 0; i < response.length; i++) {
              const result = JSON5.parse(response[i as number].second);
              const machine = Number(response[i as number].first);
              const system = machineMap.get(machine);

              if (result.created) {
                successfulResults.push({
                  machineId: machine,
                  machineName: system?.Name,
                  ipAddress: system?.IPAddress,
                  instanceId: result.id,
                });

                successIds.push(result.id);
              } else {
                const errorMessage = result?.Detail || result?.detail || t('experiment-queued-failed', { ns: NS.EDITOR });

                errorResults.push({
                  machineId: machine,
                  machineName: system?.Name,
                  ipAddress: system?.IPAddress,
                  error: errorMessage,
                });
              }
            }

            const successList = [...new Set(successIds)];

            setSuccessfulExperiments(successfulResults);
            setFailedExperiments(errorResults);
            setCreationMessage(
              `${t('queued', { ns: NS.EDITOR })} ${successList.length} ${t('experiment-instances', {
                ns: NS.EDITOR,
              })}`,
            );

            setExperimentLoading(false);
          } catch (error) {
            const errorMessage = this.getErrorMessage(error);

            const errorResult = [
              {
                machineId: selectedLabId.toString(),
                error: errorMessage,
              },
            ];

            setFailedExperiments(errorResult);
            setCreationMessage(t('queued-experiment-error', { ns: NS.ERRORS }));
            setExperimentLoading(false);
          }
        }
      }
    } catch (error) {
      setExperimentLoading(false);

      const handleErrorProps = {
        error,
        systemMessageStore: this.systemMessageStore,
        appSettingsStore: this.appSettingsStore,
        failGroupId: this.failGroupId,
      };

      HandleError(handleErrorProps);
    }
  };

  checkNetworkingWorkload = () => {
    const json = JSON5.parse(this.experiment);

    const metadata = json.metadata;

    if (Object.prototype.hasOwnProperty.call(metadata, 'workloadTopology')) {
      if (metadata.workloadTopology === 'ClientServer') {
        return true;
      }
    }

    return false;
  };

  getErrorMessage = (error) => {
    let errorMessage = t('experiment-queued-failed', { ns: NS.EDITOR });

    if (error.response) {
      if (error.response.data?.Detail) {
        errorMessage = error.response.data.Detail;
      } else if (error.response.data?.detail) {
        errorMessage = error.response.data.detail;
      } else {
        errorMessage = error.response.data || t('experiment-queued-failed', { ns: NS.EDITOR });
      }
    } else {
      errorMessage = error.message;
    }

    return errorMessage;
  };
}

export default MachineSelectViewModel;
