/*----------------------------------------------------------------------------------------------------------------------------
# ExperimentEditorTemplate.tsx: 
# This Template is our ExperimentEditor Page. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { LabUpgradeTemplateInfo } from '@/components/ExperimentEditor/ExperimentEditorTypes';
import ExperimentEditorViewController from '@/components/ExperimentEditor/ExperimentEditorViewController';
import ExperimentEditorViewModel from '@/components/ExperimentEditor/ExperimentEditorViewModel';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const ExperimentEditorFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages, addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const experimentEditorViewModel = new ExperimentEditorViewModel(rootStore);
  const { fileHandle, fileName } = experimentEditorViewModel;

  const labUpgradeTemplateInfo = useLocation<LabUpgradeTemplateInfo>();
  const title = t('experiment-editor', { ns: NS.TITLES });
  const subTitle = fileHandle ? fileHandle.name : fileName;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.EXPERIMENT_EDITOR]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent>
            <ExperimentEditorViewController
              viewModel={experimentEditorViewModel}
              labUpgradeTemplateInfo={labUpgradeTemplateInfo?.state}
            ></ExperimentEditorViewController>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ExperimentEditor = observer(ExperimentEditorFC);

export default ExperimentEditor;
