import React from 'react';
import { Table } from 'reactstrap';

import { OverflowTooltip } from '@/components/_air/common';
import CustomPaginate from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/CustomPaginate';
import { ExperimentInstanceResourceData, ExperimentInstanceResources, Labels, Statuses } from '@/constants/AirConstants';
import { ExperimentResources } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  experimentResources: ExperimentResources[];
}

export const ExperimentResourcesStatus = ({ experimentResources }: Props): JSX.Element => {
  const [totalItem, setTotalItem] = React.useState(0);
  const itemPerPage = 10;

  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [currentPage, setcurrentPage] = React.useState(0);

  const experimentResourceData = React.useMemo(() => {
    const computedData = experimentResources;

    if (computedData !== undefined) {
      setPageCount(Math.ceil(computedData.length / itemPerPage));
      setTotalItem(computedData.length);

      const endOffset = itemOffset + itemPerPage;

      return computedData.slice(itemOffset, endOffset);
    }

    return computedData;
  }, [experimentResources, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemPerPage;

    setcurrentPage(event.selected);
    setItemOffset(newOffset);
  };

  const renderResources = (): JSX.Element[] =>
    experimentResourceData.length > 0 &&
    experimentResourceData.map((resources, ind) => (
      <tr key={ind}>
        {ExperimentInstanceResourceData.map((field) => {
          const singleField = field.split('.').reduce((r, val) => (r ? r[val as string] : Statuses.notSpecified), resources);

          return (
            <td key={field} className="status-table-field">
              <OverflowTooltip>{singleField ?? Statuses.notSpecified}</OverflowTooltip>
            </td>
          );
        })}
      </tr>
    ));

  const renderHeaders = () =>
    ExperimentInstanceResources.map((header) => (
      <th key={header} className="gbs-status-table-label">
        {header}
      </th>
    ));

  return (
    <div id="wizard-page" className="gbs-status">
      <h5 className="GBS-tab-header">{Labels.experimentInstanceResources}</h5>

      <div>
        <Table hover striped size="sm" className="gbs-status-table text-nowrap">
          <thead>
            <tr>{renderHeaders()}</tr>
          </thead>
          {experimentResources && <tbody>{renderResources()}</tbody>}
        </Table>
        {experimentResources && totalItem > 0 && (
          <CustomPaginate
            breakLabel="..."
            nextLabel="next >"
            pageRangeDisplayed={10}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            containerClassName="pagination"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            onPageChange={handlePageClick}
          />
        )}
      </div>
    </div>
  );
};
