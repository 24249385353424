import React from 'react';
import { Icon } from '@fluentui/react';

import '@/styles/Air.css';

export const TableHeader = (props: any): JSX.Element => {
  const [sortingKey, setSortingKey] = React.useState('');
  const [sortingOrder, setSortingOrder] = React.useState('asc');

  const onSortingChange = (key) => {
    const order = key === sortingKey && sortingOrder === 'asc' ? 'desc' : 'asc';

    setSortingKey(key);
    setSortingOrder(order);
    props.onSorting(key, order);
  };

  return (
    <tr>
      {props.headers.map(({ key, value, sortable }) => (
        <th key={key} className="gbs-status-table-label" onClick={() => (sortable ? onSortingChange(key) : null)}>
          {value}
          {sortingKey && sortingKey === key ? (
            <Icon iconName={sortingOrder === 'asc' ? 'SortUp' : 'SortDown'} />
          ) : (
            sortable && <Icon iconName="Sort" />
          )}
        </th>
      ))}
    </tr>
  );
};
