import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { container } from 'tsyringe';

// Add language translation files here.
import en from '@/lang/en.json';
import jp from '@/lang/jp.json';
import { RootStore } from '@/stores/RootStore';

import packageJson from '../../package.json';

const defaultNS = 'default'; // Namespace must exist in each lang resource file.
const fallbackLng = ['en', 'jp'];
const resources = {
  en,
  jp,
} as const;

const rootStore: RootStore = container.resolve(RootStore);
const { appSettingsStore } = rootStore;
const { isAdvancedMode, isDebugMode, isDeveloperMode } = appSettingsStore;

const appVersion = packageJson.version;
const appName = packageJson.name;

// Display a short information line on first page load.
console.log(
  `Loading ${appName} version ${appVersion}, debug: ${isDebugMode}, developer: ${isDeveloperMode}, advanced: ${isAdvancedMode}`,
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Initialize our JSON language resources.
    resources,
    fallbackLng,
    fallbackNS: defaultNS,
    debug: isAdvancedMode, // We only want to show this information in Advanced Mode.

    // Declare our namespaces at the Application level.
    ns: [defaultNS],
    defaultNS: defaultNS,

    keySeparator: false, // We use content as keys

    interpolation: {
      escapeValue: false, // Nnot needed for React
    },
    react: {
      transEmptyNodeValue: '---',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });

export { defaultNS, i18n, resources };
