import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { MessageBarType } from '@fluentui/react';
import { Button, Card, Form, Input, Select, Space, Table, Tag, Tooltip } from 'antd';
import * as qs from 'query-string';

import { BringYourOwnRules, Labels, Messages, TableHead, TabPaneKeys } from '@/constants/LabsConstants';
import { FailGroupIds, SuccessGroupIds } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { RuleDetails, RuleExpression } from '@/utils/_labs/RuleDetails';

import './RuleForm.css'; // Create a CSS file for styling

const { Option } = Select;

const AddRuleFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [form] = Form.useForm();
  const history = useHistory();
  const [tags, setTags] = React.useState<string[]>([]);
  const { accounts } = useMsal();
  const [expressionData, setExpressionData] = React.useState<RuleExpression[]>([]);
  const [fileDetails, setFileDetails] = React.useState([]);
  const [metrics, setMetrics] = React.useState({});
  const [companyName, setCompanyName] = React.useState<any>();
  const [userCompanyName, setUserCompanyName] = React.useState('');

  const { search } = useLocation();
  const queryParams = qs.parse(props.location.pathname);
  const company = queryParams['/labs/addRule/companyName'];
  const successGroupId = SuccessGroupIds.ADD_RULE;
  const failGroupId = FailGroupIds.ADD_RULE;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    setCompanyName(company);

    logManagementRequestService.getCompanyNameByUserId().then((company) => {
      setUserCompanyName(company);
    });

    logManagementRequestService.getFilesMetricsMetadata(company.toString()).then((response) => {
      setFileDetails(response);
    });
    form.setFieldValue('Category', 'User');
  }, []);

  React.useEffect(() => {
    const selectElement = document.querySelectorAll('.ant-select');

    selectElement.forEach((selectElement) => {
      if (selectElement) {
        selectElement.removeAttribute('aria-required');
      }
    });

    const multipleselectElement = document.querySelectorAll('.ant-select-selection-search-input');

    multipleselectElement.forEach((selectElement) => {
      if (selectElement) {
        const id = selectElement.getAttribute('id');
        selectElement.setAttribute('aria-expanded', 'false');
        selectElement.setAttribute('aria-activedescendant', id);
      }
    });
  });

  const formSetup = () => {
    const getUserName = () => (accounts.length >= 1 ? accounts[0].username : '');

    form.setFieldValue('Company', userCompanyName);
    form.setFieldValue('Author', getUserName());
    form.setFieldValue('Owner', getUserName());
    form.setFieldValue('ExpressionData', expressionData);
  };

  const onFinish = (values) => {
    expressionData.forEach((expression) => {
      delete expression.index;
    });

    formSetup();

    if (form.getFieldValue('Category') === 'User') {
      tags.push('User');
    }

    const ruleData: RuleDetails = {
      Name: values.ruleName,
      id: '',
      Version: 'v1',
      TimeStamp: new Date().toJSON().toString(),
      Description: values.description,
      Category: form.getFieldValue('Category'),
      SubCategory: values.SubCategory,
      Owner: form.getFieldValue('Owner'),
      Author: form.getFieldValue('Author'),
      Company: form.getFieldValue('Company'),
      Tags: tags,
      FilesToSearch: form.getFieldValue('FilesToSearch'),
      IgnoreCase: 'true',
      Expressions: [expressionData],
      ErrorMessage: '',
      SuccessMessage: '',
    };

    logManagementRequestService
      .addRules(ruleData, companyName)
      .then((res) => {
        const jsonResult = res;

        const successMessage: SystemMessageType = {
          message: Messages.ruleAdded,
          type: MessageBarType.success,
          groupId: successGroupId,
        };

        addGlobalMessage(successMessage);
        form.resetFields();
        form.setFieldValue('Category', 'User');
        setTags([]);
        setExpressionData([]);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  };

  const columns = [
    {
      title: 'Metric Name',
      dataIndex: 'metricKeyword',
      index: 'metricKeyword',
      render: (_, record) => (
        <Select
          aria-label="metricKeyword"
          style={{ width: 180 }}
          onChange={(value) => handleExpressionChange(record.index, 'metricKeyword', value)}
        >
          {' '}
          {Object.keys(metrics).map((metric) => (
            <Option key={metric} value={metric}>
              {metric}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Operator',
      dataIndex: 'metricOperation',
      index: 'metricOperation',
      render: (_, record) => (
        <Select
          aria-label="metricOperation"
          style={{ width: 120 }}
          onChange={(value) => handleExpressionChange(record.index, 'metricOperation', value)}
        >
          <Option value="==">==</Option>
          <Option value=">">{'>'}</Option>
          <Option value="<">{'<'}</Option>
          <Option value="<=">{'<='}</Option>
          <Option value=">=">{'>='}</Option>
          <Option value="!=">!=</Option>
        </Select>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'metricValue',
      index: 'metricValue',
      render: (_, record) => (
        <Input
          type="number"
          aria-label="number"
          style={{ width: 120 }}
          onChange={(e) => handleExpressionChange(record.index, 'metricValue', e.target.value)}
        />
      ),
    },
    {
      title: TableHead.actions,
      index: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleDeleteExpression(record.index)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handleAddExpression = () => {
    setExpressionData((prevData) => [
      ...prevData,
      {
        index: new Date().getTime(),
        metricKeyword: '',
        metricOperation: '',
        metricValue: undefined,
      },
    ]);
  };

  const handleDeleteExpression = (index) => {
    setExpressionData((prevData) => prevData.filter((item) => item.index !== index));
  };

  const handleExpressionChange = (index, fieldName, value) => {
    setExpressionData((prevData) => prevData.map((item) => (item.index === index ? { ...item, [fieldName]: value } : item)));
  };

  const handleOnChange = (value, event) => {
    const fileDetail = fileDetails.filter((file) => file.Id === value);

    form.setFieldValue('SubCategory', fileDetail[0].FriendlyFileName);
    setMetrics(fileDetail[0].MetricNames);
    setExpressionData([]);
  };

  const goBackToPrevPage = () => {
    const resAnalyzerData = props.location.state.resAnalyzerData;

    history.push({
      pathname: resAnalyzerData.resAnalyzerUrl,
      state: { prevPageDetails: resAnalyzerData.prevPageDetails, activeTab: TabPaneKeys.rulesPage },
    });
  };

  return (
    <div className="row">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <Card
        className="background wrapper"
        bodyStyle={{ padding: '10' }}
        title={<span style={{ fontSize: 18 }}>{Labels.resultReviewTool}</span>}
        headStyle={{ textAlign: 'center' }}
        extra={<Button onClick={goBackToPrevPage}>Back</Button>}
      >
        <div className="container">
          <Form
            name="basic"
            labelCol={{
              xs: { span: 24 },
              sm: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            style={{ maxWidth: 800, padding: 20 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <div className="rule-form-title">Rule Details</div>
            <Form.Item
              label={
                <span>
                  {' '}
                  Rule Name
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.ruleName}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="ruleName"
              rules={[{ required: true, message: 'Rule Name is required' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {' '}
                  Description
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.ruleDescription}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
            >
              <Input />
            </Form.Item>
            <div className="rule-form-title">Rule Metadata</div>

            <Form.Item
              label={
                <span>
                  {' '}
                  Files To Search
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.filesToSearch}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="FilesToSearch"
              rules={[{ required: true, message: 'Files to Search is required' }]}
            >
              <Select onSelect={(value, event) => handleOnChange(value, event)}>
                {fileDetails &&
                  fileDetails.map((file) => (
                    <Option key={file.Id} value={file.Id}>
                      {file.Id}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {' '}
                  Category
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.category}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="Category"
              rules={[{ required: true, message: 'Category is required' }]}
            >
              <Select disabled>
                <Option value="User">User</Option>
                <Option value="System">System</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {' '}
                  SubCategory
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.subCategory}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="SubCategory"
              rules={[{ required: true, message: 'SubCategory is required' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {' '}
                  Add Tags
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.addTags}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
            >
              <Select
                mode="tags"
                placeholder="Add Tags"
                aria-label="add tags"
                style={{ width: '100%' }}
                value={tags}
                getPopupContainer={() => document.getElementById('rc_select_2')}
                onChange={(value) => setTags(value)}
              />
            </Form.Item>

            <div className="rule-form-title">Rule Expression</div>
            <Form.Item
              label={
                <span>
                  {' '}
                  Expressions
                  <Tooltip
                    overlayInnerStyle={{
                      background: 'rgba(0, 0, 0, 1)',
                    }}
                    placement="right"
                    title={BringYourOwnRules.ruleExpression}
                  >
                    <Button className="custom-button" icon={<QuestionCircleOutlined />} size="small" />
                  </Tooltip>
                </span>
              }
              labelAlign="left"
              name="ExpressionData"
            >
              <Button style={{ marginBottom: '2%' }} onClick={handleAddExpression}>
                Add Expression
              </Button>
              {expressionData.length > 0 ? (
                <Table bordered dataSource={expressionData} columns={columns} pagination={false} size="middle" />
              ) : null}
            </Form.Item>

            <Form.Item>
              <Button type="primary" className="actionButton" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export const AddRule = observer(AddRuleFC);
