import { IDropdownProps } from '@fluentui/react/lib/Dropdown';

import { ActionTypeWithParam } from '@/types/AppSettingsTypes';
import { TableType } from '@/types/TableTypes';

type DropdownType = {
  dropdownProps: IDropdownProps;
  callBack?: ActionTypeWithParam;
};

type ReliabilityVCType = {
  errorsListTable: TableType;
  errorsCountTable: TableType;
};

type ReliabilityVMType = {
  errorsListTable: TableType;
  errorsCountTable: TableType;
  dateRange: string;

  setPages: ActionTypeWithParam;
  setUsers: ActionTypeWithParam;
  setErrors: ActionTypeWithParam;
  setGraphData: ActionTypeWithParam;
  setReleaseAnnotations: ActionTypeWithParam;
  setDateRange: ActionTypeWithParam;
};

type NameType = {
  name: string;
};

type PagesType = NameType;
type UsersType = NameType;
type ErrorsDataType = ErrorsListType & ErrorsCountType;

// Error Types
type ErrorsType = {
  exceptionType: string;
  errorMessage: string;
  count: number;
  usersImpacted: number;
  pagesImpacted: number;
  firstSeen: string;
  lastSeen: string;
};

type ErrorsListType = {
  errors: Array<ErrorsType>;
};

type ErrorsCountType = {
  totalErrors: number;
  totalPageViews: number;
  errorsPerPageView: string;
};

// Methods Types
type SetListType = (list: Array<any>) => void;
type GetListType = () => Array<any>;
type GetNumberType = () => number;
type SetNumberType = (value: number) => void;
type GetStringType = () => string;
type SetStringType = (value: string) => void;
type GetDurationType = () => DurationType;
type SetDurationType = (value: DurationType) => void;

// Query Param Types
type PagesRequestQueryParams = {
  durationType: DurationType;
  userName?: string;
};

type UsersRequestQueryParams = {
  durationType: DurationType;
  pageNames?: string[];
};

type ErrorsRequestQueryParams = {
  durationType: DurationType;
  pageNames?: string[];
  userName?: string;
};

type ErrorsGraphRequestQueryParams = {
  durationType: DurationType;
  timeBucket: number;
  timeBucketType: DurationType;
  pageNames?: string[];
  userName?: string;
};

type AnnotationsQueryParams = {
  timeDuration: number;
  durationType: DurationType;
};

enum DurationType {
  Day,
  Hour,
  Minute,
  Second,
}

export {
  AnnotationsQueryParams,
  DropdownType,
  DurationType,
  ErrorsCountType,
  ErrorsDataType,
  ErrorsListType,
  ErrorsRequestQueryParams,
  ErrorsGraphRequestQueryParams,
  ErrorsType,
  GetDurationType,
  GetListType,
  GetNumberType,
  GetStringType,
  NameType,
  PagesRequestQueryParams,
  PagesType,
  ReliabilityVCType,
  ReliabilityVMType,
  SetDurationType,
  SetListType,
  SetNumberType,
  SetStringType,
  UsersRequestQueryParams,
  UsersType,
};
