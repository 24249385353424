import React from 'react';
import { observer } from 'mobx-react-lite';
import { TagPicker, TextField, TooltipHost } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Tag, TagGroup } from '@fluentui/react-components';
import { t } from 'i18next';

import { IComboBoxOption } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import { Labels, Messages } from '@/constants/LabsConstants';
import { FormLimits, KeyTextPair, Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { populateHealthStatus } from '@/utils/Helpers';

import style from '@/components/ManageLab/LabGeneral/LabGeneral.module.css';

interface LabGeneralProps {
  commandBarItems: ICommandBarItemProps[];
  selectedTags: IComboBoxOption[];
  handleTagDelete: (event: React.MouseEvent<HTMLButtonElement>, tag: { value: string | number }) => void;
  handleTagChange: (items: IComboBoxOption[]) => void;
  labAdminTooltipContent: JSX.Element;
}

const LabGeneralTemplateFC: React.FC<LabGeneralProps> = (props: LabGeneralProps) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labGeneralStore } = rootStore;
  const { lastRefresh, labDetailsData, osImageValue, osImages, selectedTags, setLabDetailsData, setOsImageValue, tagOptions } =
    labGeneralStore;
  const { commandBarItems, handleTagDelete, handleTagChange, labAdminTooltipContent } = props;

  const tagOptionsComboBox: IComboBoxOption[] = tagOptions.map((tag) => ({
    key: tag.TagId,
    name: tag.TagName,
  }));

  const renderLabDetails = () => {
    return (
      <div className={style.gridContainer}>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.labID}</label>
          <Tag appearance="brand">{labDetailsData?.labId}</Tag>
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.labVersion}</label>
          <Tag appearance="brand">{labDetailsData?.version}</Tag>
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.companyLabel}</label>
          <Tag appearance="brand">{labDetailsData?.companyName}</Tag>
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.labName}</label>
          <TextField
            value={labDetailsData?.labName}
            className={style.fieldGroup}
            maxLength={FormLimits.LAB_NAME_MAX_LENGTH}
            onChange={(e) => setLabDetailsData({ ...labDetailsData, labName: e.target.value })}
          />
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.programName}</label>
          <TextField className={style.fieldGroup} />
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.labAdmin}</label>
          <TooltipHost content={labAdminTooltipContent} className={style.tooltipHost}>
            <Tag appearance="brand">
              {labDetailsData?.labAdminList?.length > 0 ? labDetailsData.labAdminList[0].UserName : Labels.noAdmin}
            </Tag>
          </TooltipHost>
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.location}</label>
          <TextField className={style.fieldGroup} />
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.addTags}</label>
          <TagPicker
            className={style.tagPickerStyle}
            onResolveSuggestions={(filterText) =>
              tagOptionsComboBox.filter((tag) => tag.name.toLowerCase().includes(filterText.toLowerCase()))
            }
            onEmptyInputFocus={() => tagOptionsComboBox}
            pickerSuggestionsProps={{
              suggestionsHeaderText: Labels.suggestedTags,
              noResultsFoundText: Labels.noTagsFound,
            }}
            onChange={handleTagChange}
            selectedItems={[]}
          />
        </div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}>{Labels.osImage}</label>
          <Dropdown
            selectedKey={osImageValue}
            placeholder={t(Labs.SELECT_OS_IMAGE, { ns: NS.LABS })}
            title={t(Labs.SELECT_OS_IMAGE, { ns: NS.LABS })}
            options={osImages}
            className={style.osDropDown}
            onChange={(event: React.FormEvent<HTMLDivElement>, item: KeyTextPair) => setOsImageValue(item.key)}
          />
        </div>
        <div className={style.itemContainer}></div>
        <div className={style.itemContainer}>
          <label className={style.labelStyle}></label>
          <div>
            <TagGroup className={style.tagGroupStyle} onDismiss={handleTagDelete}>
              {selectedTags.map((tag) => (
                <Tag
                  key={tag.key}
                  value={tag.key}
                  appearance="brand"
                  size="extra-small"
                  dismissible
                  dismissIcon={{ 'aria-label': 'remove' }}
                  className={style.tagStyle}
                >
                  {tag.name}
                </Tag>
              ))}
            </TagGroup>
          </div>
        </div>
        <div className={style.itemContainer}></div>
      </div>
    );
  };

  return (
    <>
      <PageCommandBar items={commandBarItems} />
      <PageDivider />
      {!labDetailsData && <LoadingSpinner />}
      {labDetailsData && (
        <>
          <div className={style.labDetailsRow}>
            <div className={style.healthStatusContainer}>
              <span className={style.lastRefreshContainer}>
                {Messages.lastRefreshTime} {lastRefresh}
              </span>
              {!labDetailsData.isVirtualLab && populateHealthStatus(labDetailsData.lastHeartBeat, 'main')?.displayHealthStatus}
            </div>
          </div>
          {renderLabDetails()}
        </>
      )}
    </>
  );
};

export const LabGeneralTemplate = observer(LabGeneralTemplateFC);
