import React from 'react';
import { observer } from 'mobx-react-lite';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import { FocusZone, FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import { IOverflowSetItemProps, OverflowSet } from '@fluentui/react/lib/OverflowSet';
import { ResizeGroup } from '@fluentui/react/lib/ResizeGroup';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';

import { PageFilterBarType } from './PageFilterBarTypes';

import styles from './PageFilterBar.module.css';

export interface IOverflowData {
  primary: IOverflowSetItemProps[];
  overflow: IOverflowSetItemProps[];
}

const PageFilterBarFC: React.FC<PageFilterBarType> = (props: PageFilterBarType): React.ReactElement => {
  const { items } = props;

  const initializeData = (items: IOverflowSetItemProps[]): IOverflowData => {
    return {
      primary: items,
      overflow: [],
    };
  };

  const onReduceData = (currentData: IOverflowData) => {
    if (currentData.primary.length === 0) {
      return undefined;
    }

    const overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
    const primary = currentData.primary.slice(0, -1);

    return { primary, overflow };
  };

  const onGrowData = (currentData: IOverflowData) => {
    if (currentData.overflow.length === 0) {
      return undefined;
    }

    const overflow = currentData.overflow.slice(1);
    const primary = [...currentData.primary, ...currentData.overflow.slice(0, 1)];

    return { primary, overflow };
  };

  const onRenderOverflowButton = (overflowItems: IOverflowSetItemProps[]) => (
    <CommandBarButton role="menuitem" menuProps={{ items: overflowItems! }} />
  );

  const onRenderData = (data: IOverflowData) => {
    return (
      <OverflowSet
        items={data.primary}
        overflowItems={data.overflow?.length ? data.overflow : null}
        onRenderItem={onRenderItem}
        onRenderOverflowButton={onRenderOverflowButton}
        styles={filterBar.filterBar}
        className={styles['pagefilterbar']}
      />
    );
  };

  const onRenderItem = (item: IOverflowSetItemProps) => {
    if (item.onRender) {
      return item.onRender(item);
    }
  };

  return (
    <div className={styles['pagefilterbar-wrapper']}>
      <FocusZone direction={FocusZoneDirection.horizontal}>
        <ResizeGroup
          title={t('filter-bar', { ns: NS.COMMON })}
          data={initializeData(items)}
          onReduceData={onReduceData}
          onGrowData={onGrowData}
          onRenderData={onRenderData}
        />
      </FocusZone>
    </div>
  );
};

const PageFilterBar = observer(PageFilterBarFC);

export default PageFilterBar;
