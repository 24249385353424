import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';

import { ActionTypeVoid, ActionTypeWithParam } from '@/types/AppSettingsTypes';

type ExperimentEditorVMType = {
  // No properties
};

type ExperimentEditorVCType = {
  labId: number;
  hideTemplate: boolean;
  toggleHideTemplate: ActionTypeWithParam;
  hidePublish: boolean;
  hidePreview: boolean;
  labToggle: boolean;
  toggleHidePublish: ActionTypeWithParam;
  toggleHidePreview: ActionTypeWithParam;
  filterItems: IOverflowSetItemProps[];
  farItems: ICommandBarItemProps[];

  hideConfirmModal: boolean;
  isConfirmModalOpen: boolean;
  confirmedAction: ActionTypeVoid;

  loadingTemplates: boolean;
  loadingExperiment: boolean;
  templateColumns: IColumn[];
  templates: ExperimentTemplateInfo[];
  loadTemplate: ActionTypeWithParam;

  editorMount: ActionTypeWithParam;
  handleEditorChange: ActionTypeWithParam;
  monacoEditorOptions: any;
};

type ExperimentTemplate = {
  experiment: string;
};

type ExperimentTemplateInfo = {
  id: string;
  schema: string;
  description: string;
};

type LabUpgradeTemplateInfo = {
  labId: string;
  templateName: string;
  manifestVersion: string;
};

enum ConfirmationAction {
  New,
  LocalFile,
  Template,
  None,
}

type ExperimentEditorRouteType = {
  labId: string;
};

export {
  ConfirmationAction,
  ExperimentEditorRouteType,
  ExperimentEditorVCType,
  ExperimentEditorVMType,
  ExperimentTemplate,
  ExperimentTemplateInfo,
  LabUpgradeTemplateInfo,
};
