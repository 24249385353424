import { cosmosRequestService } from '@/services/request-services/CosmosRequestService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

import CosmosStore from './CosmosStore';
import { CreateEntryRequestQueryParams, GetEntryRequestQueryParams } from './CosmosTypes';

class CosmosViewModel {
  public getEntryKey: string;
  public getEntryValue: string;
  public createEntryKey: string;
  public createEntryValue: string;
  public updateEntryKey: string;
  public updateEntryValue: string;
  public deleteEntryKey: string;
  public isGlobal: boolean;

  protected _setGetEntryKey: ActionTypeWithParam;
  protected _setGetEntryValue: ActionTypeWithParam;
  protected _setCreateEntryKey: ActionTypeWithParam;
  protected _setCreateEntryValue: ActionTypeWithParam;
  protected _setUpdateEntryKey: ActionTypeWithParam;
  protected _setUpdateEntryValue: ActionTypeWithParam;
  protected _setDeleteEntryKey: ActionTypeWithParam;
  protected _setIsGlobal: ActionTypeWithParam;
  protected _toggleIsGlobal: ActionTypeWithParam;

  constructor(rootStore: RootStore) {
    const { cosmosStore } = rootStore;

    this.getEntryKey = cosmosStore.getEntryKey;
    this.getEntryValue = cosmosStore.getEntryValue;
    this.createEntryKey = cosmosStore.createEntryKey;
    this.createEntryValue = cosmosStore.createEntryValue;
    this.updateEntryKey = cosmosStore.updateEntryKey;
    this.updateEntryValue = cosmosStore.updateEntryValue;
    this.deleteEntryKey = cosmosStore.deleteEntryKey;
    this.isGlobal = cosmosStore.isGlobal;

    this._setGetEntryKey = cosmosStore.setGetEntryKey;
    this._setGetEntryValue = cosmosStore.setGetEntryValue;
    this._setCreateEntryKey = cosmosStore.setCreateEntryKey;
    this._setCreateEntryValue = cosmosStore.setCreateEntryValue;
    this._setUpdateEntryKey = cosmosStore.setUpdateEntryKey;
    this._setUpdateEntryValue = cosmosStore.setUpdateEntryValue;
    this._setDeleteEntryKey = cosmosStore.setDeleteEntryKey;
    this._setIsGlobal = cosmosStore.setIsGlobal;
    this._toggleIsGlobal = cosmosStore.toggleIsGlobal;
  }

  public setIsGlobal = (isGlobal: boolean) => {
    this._setIsGlobal(isGlobal);
  };

  public toggleIsGlobal = () => {
    this._toggleIsGlobal();
  };

  public setGetEntryKey = (set: string) => {
    this._setGetEntryKey(set);
  };

  public setGetEntryValue = (set: string) => {
    this._setGetEntryValue(set);
  };

  public getEntry: ActionTypeWithParam = async (databaseId: string, id: string, key: string): Promise<string> => {
    const queryParams: GetEntryRequestQueryParams = {
      databaseId: databaseId,
      id: id,
      key: key,
    };

    const entry = await cosmosRequestService.getEntry(queryParams);

    this.setGetEntryValue(entry);

    return entry;
  };

  public setCreateEntryKey = (set: string) => {
    this._setCreateEntryKey(set);
  };

  public setCreateEntryValue = (set: string) => {
    this._setCreateEntryValue(set);
  };

  public createEntry: ActionTypeWithParam = async (
    databaseId: string,
    id: string,
    key: string,
    value: string,
  ): Promise<boolean> => {
    const queryParams: CreateEntryRequestQueryParams = {
      databaseId: databaseId,
      id: id,
      key: key,
      value: value,
    };

    const success = await cosmosRequestService.createEntry(queryParams);
    return success;
  };

  public setUpdateEntryKey = (set: string) => {
    this._setUpdateEntryKey(set);
  };

  public setUpdateEntryValue = (set: string) => {
    this._setUpdateEntryValue(set);
  };

  public updateEntry: ActionTypeWithParam = async (
    databaseId: string,
    id: string,
    key: string,
    value: string,
  ): Promise<boolean> => {
    const queryParams: CreateEntryRequestQueryParams = {
      databaseId: databaseId,
      id: id,
      key: key,
      value: value,
    };

    const success = await cosmosRequestService.updateEntry(queryParams);
    return success;
  };

  public setDeleteEntryKey = (set: string) => {
    this._setDeleteEntryKey(set);
  };

  public deleteEntry: ActionTypeWithParam = async (databaseId: string, id: string, key: string): Promise<boolean> => {
    const queryParams: GetEntryRequestQueryParams = {
      databaseId: databaseId,
      id: id,
      key: key,
    };

    const success = await cosmosRequestService.deleteEntry(queryParams);

    return success;
  };
}

export default CosmosViewModel;
