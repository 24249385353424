export class UrlArray {
  public default: any;
  private key: any;
  private split: string;
  private value?: any[];

  constructor(key: any, split: string, defaultValue: any) {
    this.key = key;
    this.split = split;
    this.default = defaultValue;
    this.value = this.get();
  }

  set(val: any[]) {
    const searchParams = new URLSearchParams(location.search);

    this.value = val;

    if (this.compare(val, this.default)) {
      searchParams.delete(this.key);
    } else {
      searchParams.set(this.key, this.value.toString());
    }

    history.replaceState({}, '', `${location.pathname}?${searchParams}`);
  }

  compare(array1: any[], array2: any[]): boolean {
    // If one array exists and the other doesn't return false, if neither exits return true
    if (array1 && !array2) {
      return false;
    }

    if (!array1 && array2) {
      return false;
    }

    if (!array1 && !array2) {
      return true;
    }

    // If the lengths don't match return false
    if (array1.length !== array2.length) {
      return false;
    }

    // Compare elements independent of place in array
    array1.forEach((element1) => {
      let found = false;

      array2.forEach((element2) => {
        if (element1 === element2) {
          found = true;
        }
      });

      if (!found) {
        return false;
      }
    });

    return true;
  }

  get() {
    const searchParams = new URLSearchParams(location.search);

    this.value = searchParams.get(this.key)?.split(this.split);

    if (this.value) {
      return this.value;
    } else {
      return this.default;
    }
  }
}
