import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Icon, Label, Link, MessageBar, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { t } from 'i18next';

import { Labels } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';

import { UploadFilePanelTypes } from './UploadFilePanelTypes';

import styles from './UploadFilePanel.module.css';

const UploadFilePanelTemplateFC: React.FC<UploadFilePanelTypes> = (props) => {
  const {
    hiddenFileInput,
    isBusy,
    uploadPercentage,
    fileNames,
    siName,
    programName,
    componentName,
    isRequiredData,
    handleClick,
    uploadFile,
    handleLocalDelete,
    handleUpload,
    setSIName,
    setProgramName,
    setComponentName,
  } = props;

  const uploadStyle = uploadPercentage ? styles['upload-file-percentage'] : styles['upload-file-input'];

  return (
    <div>
      <Stack>
        <div className={styles['upload-file-box']}>
          <Icon iconName="CloudUpload" className={styles['upload-file-icon']} />
          <div>
            {fileNames.length > 0 ? <Label>{`${fileNames.length} ${t('files-selected', { ns: NS.COMMON })} `}</Label> : null}
            {fileNames.map((item, index) => (
              <div key={`${index}`}>
                <MessageBar key={index} isMultiline={false} onDismiss={() => handleLocalDelete(index)}>
                  {item}
                </MessageBar>
              </div>
            ))}
          </div>
          <span>{Labels.dropFiles}</span>
          <span>or</span>
          <Link onClick={handleClick}>{Labels.browseFiles}</Link>
          <br />
          <input
            ref={hiddenFileInput}
            multiple
            type="file"
            accept=".zip,.7zip"
            className={styles['upload-file-input']}
            onChange={(e) => {
              uploadFile(e);
            }}
          />
        </div>
        <br />
        <div>
          <TextField
            required
            type="text"
            title={t('si', { ns: NS.TABLE })}
            placeholder={t('si', { ns: NS.TABLE })}
            className={styles['upload-file-textbox']}
            value={siName}
            onChange={(e) => setSIName((e.target as any).value)}
          />
          <TextField
            required
            type="text"
            title={t('program', { ns: NS.TABLE })}
            placeholder={t('program', { ns: NS.TABLE })}
            className={styles['upload-file-textbox']}
            value={programName}
            onChange={(e) => setProgramName((e.target as any).value)}
          />
          <TextField
            required
            type="text"
            title={t('component', { ns: NS.TABLE })}
            placeholder={t('component', { ns: NS.TABLE })}
            className={styles['upload-file-textbox']}
            value={componentName}
            onChange={(e) => setComponentName((e.target as any).value)}
          />
        </div>
        <br />
        <div>
          {isBusy ? (
            <>
              <div>
                <PrimaryButton disabled>{t('uploading-files', { ns: NS.COMMON })}</PrimaryButton>
                <div className={`${styles['upload-file-progress']} ${uploadStyle}`}>
                  <ProgressBar now={uploadPercentage} label={`${uploadPercentage.toFixed(2)}%`} />
                </div>
              </div>
            </>
          ) : (
            <PrimaryButton disabled={!isRequiredData()} type="submit" onClick={handleUpload}>
              {t('upload', { ns: NS.COMMON })}
            </PrimaryButton>
          )}
        </div>
      </Stack>
    </div>
  );
};

const UploadFilePanelTemplate = observer(UploadFilePanelTemplateFC);

export default UploadFilePanelTemplate;
