import React from 'react';

const copilot = {
  main: { backgroundColor: '#222', color: 'var(--textColor)', padding: '0px', width: '800px' } /* Not Dark Mode compliant */,
  content: { overflow: 'hidden', height: '100%', padding: '0px' },
};

const experimentInfo = {
  main: {
    width: '1024px', // NOTE: Width can be removed to make Panel full-width.
  },
  scrollableContent: {
    height: 'calc(100% - 122px)',
    overflow: 'visible',
  },
  content: {
    height: 'calc(100% - 122px)',
    overflow: 'visible',
  },
};

const settings = {
  main: { width: '400px' },
};

const smallPanel = {
  main: { marginTop: '42px' },
  headerText: { paddingBottom: '35px' },
};

const columnEditorPanel = {
  main: { width: '400px', marginTop: '42px' },
  headerText: { paddingBottom: '35px' },
};

const sessionStepColumnEditorPanel = {
  main: { width: '400px' },
  headerText: { paddingBottom: '35px' },
};

const resultExplorer = {
  main: { marginTop: '42px' },
  root: {
    overflowY: 'hidden',
  },
  scrollableContent: {
    overflowY: 'hidden',
  },
};

const standard = {
  main: { marginTop: '42px' },
};

const createLabPanel = {
  main: { width: '900px', marginTop: '42px' },
  headerText: { paddingBottom: '35px' },
};

const logView: React.CSSProperties = {
  maxHeight: '70vh',
  overflowY: 'auto',
};

const mediaQueries = {
  smallScreen: '@media screen and (maxWidth: 768px)',
  mediumScreen: '@media screen and (minWidth: 769px) and (maxWidth: 1024px)',
  largeScreen: '@media screen and (minWidth: 1025px)',
};

const logViewWithMediaQueries = {
  ...logView,
  [mediaQueries.smallScreen]: {
    maxHeight: '30vh',
  },
  [mediaQueries.mediumScreen]: {
    maxHeight: '60vh',
  },
  [mediaQueries.largeScreen]: {
    maxHeight: '70vh',
  },
};

const styles = {
  columnEditorPanel,
  copilot,
  createLabPanel,
  experimentInfo,
  logView,
  logViewWithMediaQueries,
  resultExplorer,
  sessionStepColumnEditorPanel,
  settings,
  standard,
  smallPanel,
};

export default styles;
