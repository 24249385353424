import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from './SettingsButton.module.css'; // Apply any Navigation level CSS Module styling here.

type SettingsButtonType = {
  children?: React.ReactElement;
};

const SettingsButtonFC: React.FC<SettingsButtonType> = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { setSettingsOpen } = appSettingsStore;

  const icon: IIconProps = { iconName: SystemIcons.SETTINGS };

  const openSettings = () => {
    setSettingsOpen(true);
  };

  return (
    <IconButton
      className={styles['icon-button']}
      iconProps={icon}
      title={t('settings', { ns: NS.TITLES })}
      ariaLabel={t('settings', { ns: NS.TITLES })}
      disabled={false}
      checked={false}
      onClick={openSettings}
    />
  );
};

const SettingsButton = observer(SettingsButtonFC);

export default SettingsButton;
