import { SnackbarOrigin } from '@material-ui/core/Snackbar';

type NotificationPositionsType = {
  topRight: SnackbarOrigin;
  topCenter: SnackbarOrigin;
  topLeft: SnackbarOrigin;
  bottomRight: SnackbarOrigin;
  bottomCenter: SnackbarOrigin;
  bottomLeft: SnackbarOrigin;
};

type NotificationSeverityType = 'success' | 'info' | 'warning' | 'error';

type NotificationSeveritiesType = {
  success: NotificationSeverityType;
  info: NotificationSeverityType;
  warning: NotificationSeverityType;
  error: NotificationSeverityType;
};

const NotificationSeverities: NotificationSeveritiesType = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const NotificationPositions: NotificationPositionsType = {
  topRight: { vertical: 'top', horizontal: 'right' },
  topCenter: { vertical: 'top', horizontal: 'center' },
  topLeft: { vertical: 'top', horizontal: 'left' },
  bottomRight: { vertical: 'bottom', horizontal: 'right' },
  bottomCenter: { vertical: 'bottom', horizontal: 'center' },
  bottomLeft: { vertical: 'bottom', horizontal: 'left' },
};

export { NotificationPositions, NotificationSeverities, NotificationSeverityType };
