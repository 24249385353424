import React from 'react';
import SplitPane from 'react-split-pane';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { SplitPanelVCType } from './SplitPanelTypes';

import styles from './SplitPanel.module.css';

const SplitPanelTemplateFC: React.FC<SplitPanelVCType> = (props: SplitPanelVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, localStorage } = rootStore;
  const { isDebugMode } = appSettingsStore;
  const { children, config, index, isAllowResize, split } = props;
  const offModeSize = config.offModeSize;

  const saveValue = (position: number, value: number) => {
    const key = config.keys[position as number];

    return localStorage.setValue(key, Math.round(value));
  };

  const checkSizes = (position: number) => {
    const key = config.keys[position as number];
    const storedValue = Math.round(parseInt(localStorage.getValue(key), 10));
    const windowHeight = window.innerHeight; // Get the height of the window in pixels.
    const isValueTooSmall = storedValue < config.minSize[position as number];
    const isValueTooLarge = storedValue > windowHeight - config.padding;

    // If the stored value is not set or is less than the minSize, set it to the default size.
    // If the value is greater than the window height minus the padding, also reset the value.
    // This allows us to cleanse bad or invalid values from the local storage.
    // These checks are important to prevent the pane from being resized to an invalid size,
    // which can happen if the window height changes after the value is stored.
    if (!storedValue || isValueTooSmall || isValueTooLarge) {
      const defaultValue = config.defaultSize[position as number];

      isDebugMode && console.log('[checkSizes]', position, storedValue, '==>', defaultValue);

      return saveValue(position, defaultValue);
    }

    // Make sure the storedValue is a integer.
    saveValue(index, storedValue);

    isDebugMode && console.log('[checkSizes]', position, storedValue, ' OK');

    return storedValue;
  };

  // If we are not allowed to resize, then we default to a full height or width;
  const defaultSize = isAllowResize ? checkSizes(index) : offModeSize[index as number]; // This will be updated next PR.

  return (
    <SplitPane
      split={split}
      minSize={config.minSize[index as number]}
      maxSize={config.maxSize[index as number]}
      allowResize={isAllowResize}
      defaultSize={defaultSize}
      onChange={(size: number) => saveValue(index, size)}
    >
      {children}
    </SplitPane>
  );
};

const SplitPanelTemplate = observer(SplitPanelTemplateFC);

export default SplitPanelTemplate;
