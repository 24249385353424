import React from 'react';
import { IPaginationProps, Pagination } from '@fluentui/react-experiments';

import { SystemIcons } from '@/constants/IconConstants';

import { PaginationType } from './PaginationTypes';

import paginationStyles from './Pagination.module.css';

const PaginationTemplate: React.FC<PaginationType> = (props: PaginationType): React.ReactElement => {
  const { paginationProps, paginationLabels, onPageChange } = props;

  const { currentPage, pageSize, totalItems, pageCount } = paginationProps ?? {};
  const { previousPage, nextPage, firstPage, lastPage, recordsInformation } = paginationLabels;

  return (
    <>
      <div className={paginationStyles['pagination-wrapper']}>
        <div className={paginationStyles['pagination-controls']}>
          <div className={paginationStyles['pagination-left']}>
            <span>{recordsInformation}</span>
          </div>
          <div className={paginationStyles['pagination-center']}>
            <Pagination
              selectedPageIndex={currentPage}
              pageCount={pageCount}
              itemsPerPage={pageSize}
              totalItemCount={totalItems}
              format={'buttons'}
              previousPageAriaLabel={previousPage}
              nextPageAriaLabel={nextPage}
              firstPageAriaLabel={firstPage}
              lastPageAriaLabel={lastPage}
              onPageChange={onPageChange}
              firstPageIconProps={{ iconName: SystemIcons.ARROW_FIRST_PAGE }}
              previousPageIconProps={{ iconName: SystemIcons.ARROW_PREVIOUS_PAGE }}
              nextPageIconProps={{ iconName: SystemIcons.ARROW_NEXT_PAGE }}
              lastPageIconProps={{ iconName: SystemIcons.ARROW_LAST_PAGE }}
              onRenderVisibleItemLabel={(props: IPaginationProps) => null}
            />
          </div>
          <div className={paginationStyles['pagination-right']}></div>
        </div>
      </div>
    </>
  );
};

export default PaginationTemplate;
