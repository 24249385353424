/*----------------------------------------------------------------------------------------------------------------------------
# IssuesTemplate.tsx: 
# This Component is our Issues Component.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react-lite';
import remarkGfm from 'remark-gfm';

import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const file = './ISSUES.md'; // This file must be stored in the /public folder.

const IssuesFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages, addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const [block, setBlock] = React.useState<string>('');

  const loadMarkdownFile = () => {
    fetch(file)
      .then((response: Response) => {
        if (response.ok) {
          return response.text();
        } else {
          return Promise.reject('Did not fetch data correctly.');
        }
      })
      .then((text: string) => {
        setBlock(text);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    loadMarkdownFile();
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="ganymede-wrapper">
        <div className="container padding-top padding-bottom">
          <div className="row align-items-md-stretch">
            <div className="col-md-12">
              <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{block}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const Issues = observer(IssuesFC);

export default Issues;
