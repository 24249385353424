import React from 'react';
import { Bar, Line } from 'recharts';

import GraphBaseViewController from '../GraphBaseViewController';
import { BarLineGraphTemplateType } from './BarLineGraphType';

const BarLineGraphTemplate: React.FC<BarLineGraphTemplateType> = (props: BarLineGraphTemplateType): React.ReactElement => {
  const { data, chartProp } = props;
  const { xAxisProp, yAxisProp, barProp, lineProp, responsiveContainerProp, cartesianGridProp, tooltipProps } = chartProp;

  return (
    <GraphBaseViewController
      data={data}
      chartProp={{
        responsiveContainerProp,
        cartesianGridProp,
        xAxisProp,
        yAxisProp,
        tooltipProps,
      }}
    >
      {barProp && <Bar {...barProp} />}
      {lineProp && <Line {...lineProp} />}
    </GraphBaseViewController>
  );
};

export default BarLineGraphTemplate;
