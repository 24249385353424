import { toJS } from 'mobx';

import { RootStore } from '@/stores/RootStore';

import { LayoutItemType, SetLayoutType } from './ReactGridTypes';

class ReactGridViewModel {
  public layout: LayoutItemType[];
  public setLayout: SetLayoutType;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;
    const { layout, setLayout } = appSettingsStore;

    // We want this observable layout object to be used and iterated on,
    // so we set this optional method to make it a JS object.
    // Reference: https://mobx.js.org/api.html#tojs
    this.layout = toJS(layout);
    this.setLayout = setLayout;
  }
}

export default ReactGridViewModel;
