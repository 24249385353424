import React from 'react';
import { Card } from 'antd';

import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';

import Result from './Result/Result';

import '@/styles/Labs.css';

export default function Results(props: any) {
  const results = props.documents.map((result: any, index: number) => (
    <Result key={index} document={result.Document} content={result.Highlights ? result.Highlights.content[0] : ''} />
  ));

  const beginDocNumber = Math.min(props.skip + 1, props.count);
  const endDocNumber = Math.min(props.skip + props.top, props.count);

  return (
    <Card>
      <p>
        Showing {beginDocNumber}-{endDocNumber} of {props.count.toLocaleString()} results
      </p>
      <ScrollableContent scrollable={true} scrollableX={true}>
        {results}
      </ScrollableContent>
    </Card>
  );
}
