import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Dropdown, Input, Label, makeStyles, Option, shorthands, useId } from '@fluentui/react-components';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { MessageBarTesterTemplateType } from './MessageBarTesterType';

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: 'grid',
    gridTemplateRows: 'repeat(1fr)',
    justifyItems: 'start',
    ...shorthands.gap('2px'),
    maxWidth: '400px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
  },
});

const MessageBarTesterFC: React.FC<MessageBarTesterTemplateType> = (props: MessageBarTesterTemplateType): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const {
    addMessage,
    clearAllMessages,
    clearNonPersistentMessages,
    toggleNamespace,
    togglePersistent,
    onModeSelect,
    onTypeSelect,
    onMessageChange,
    onGroupIdChange,
  } = props;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const dropdownId = useId('dropdown-default');
  const inputId = useId('input');

  const modeOptions = ['displayOnceOnly', 'cannotDismiss', 'rememberState'];
  const typeOptions = ['info', 'warning', 'error'];
  const styles = useStyles();

  return (
    <>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>messagebuttons-controller</Trans>
      </h2>

      <Checkbox label="Persistent" onChange={togglePersistent} />
      <br />

      <Checkbox label="Namespace ('playground')" onChange={toggleNamespace} />
      <br />

      <div className={styles.root}>
        <label id={dropdownId}>Mode</label>
        <Dropdown aria-labelledby={dropdownId} placeholder="rememberState" onOptionSelect={onModeSelect}>
          {modeOptions.map((modeOption, index) => (
            <Option key={index}>{modeOption}</Option>
          ))}
        </Dropdown>
      </div>

      <div className={styles.root}>
        <label id={dropdownId}>Type</label>
        <Dropdown aria-labelledby={dropdownId} placeholder="info" onOptionSelect={onTypeSelect}>
          {typeOptions.map((typeOption, index) => (
            <Option key={index}>{typeOption}</Option>
          ))}
        </Dropdown>
      </div>

      <div className={styles.root}>
        <Label htmlFor={inputId}>Message</Label>
        <Input id={inputId} {...props} placeholder="insert message here" onChange={onMessageChange} />
      </div>
      <br />

      <Checkbox label="Group" onChange={props.toggleGroup} />
      <div className={styles.root}>
        <Label htmlFor={inputId}>Group ID</Label>
        <Input id={inputId} {...props} placeholder="insert groupId" onChange={onGroupIdChange} />
      </div>
      <br />

      <div className={styles.wrapper}>
        <Button onClick={addMessage}>Add Message</Button>
        <Button onClick={clearAllMessages}>Clear All</Button>
        <Button onClick={clearNonPersistentMessages}>Clear Non-Persistent</Button>
      </div>
    </>
  );
};

const MessageBarTester = observer(MessageBarTesterFC);

export default MessageBarTester;
