import React from 'react';
import { Icon } from '@fluentui/react';

import { Messages } from '@/constants/LabsConstants';

export const NoRecordsFound = () => (
  <div className="text-center text-secondary mt-5">
    <h5>
      <Icon iconName="Error" /> {Messages.noRecordsFound}
    </h5>
  </div>
);
