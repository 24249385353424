import React from 'react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import dashify from 'dashify';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { TableRendererFCType } from '@/partials/TileRenderers/TileRendererTypes';

import styles from '@/partials/TileRenderers/TileRenderer.module.css';

const TableTile: React.FC<TableRendererFCType> = (props: TableRendererFCType): React.ReactElement => {
  const {
    fullView = true,
    namespace = NS.TABLE,
    blockTitle,
    subTitle,
    items,
    columns,
    displayColumns,
    enableToolBar = true,
    enableColumnSort = true,
    isStickyHeader = true,
    isCollapsible = false,
    titleStyle = styles['title'],
    tableStyle = styles['table'],
    onRenderDetailsHeader,
    onRenderRow,
    onFilter,
  } = props;

  const tableTitle = blockTitle ? t(blockTitle, { ns: namespace }) : '';
  const uniqueId = dashify(tableTitle);

  const defaultOptions = {
    title: subTitle,
    namespace: namespace,
    items,
    columns,
    displayColumns,
    enableToolBar,
    enableColumnSort,
    isStickyHeader,
  };

  const pageView = { ...defaultOptions, onRenderDetailsHeader, onRenderRow, onFilter, tableStyle };
  const tileView = { ...defaultOptions, enableColumnSort: true, isStickyHeader: true, tableStyle: styles['table'] };

  if (!fullView || !isCollapsible) {
    // We do not allow the table to be collapsed if in Tile (Widget) View.
    return (
      <div className={styles['tile']}>
        {blockTitle && <div className={titleStyle}>{tableTitle}</div>}
        {fullView ? <TableViewViewController {...pageView} /> : <TableViewViewController {...tileView} />}
      </div>
    );
  }

  return (
    <Accordion collapsible={true} multiple={true} defaultOpenItems={uniqueId} data-id={`accordion-${uniqueId}`}>
      <AccordionItem value={uniqueId}>
        <AccordionHeader size="extra-large" expandIconPosition={'start'} className={styles['accordion-title']}>
          <div className={titleStyle}>{tableTitle}</div>
        </AccordionHeader>
        <AccordionPanel className={styles['accordion-panel']}>
          {fullView ? <TableViewViewController {...pageView} /> : <TableViewViewController {...tileView} />}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default TableTile;
