import React from 'react';
import { Label } from '@fluentui/react';

import { Labels, Messages, Routes } from '@/constants/AirConstants';

import '@/styles/Air.css';

export const ExperimentSuccessPage = (props: any) => {
  const experimentSummary = props.history.location.state?.data ?? null;

  const renderDetails = () => {
    if (experimentSummary) {
      return Object.keys(experimentSummary).map((detail) => (
        <div key={detail} className="form-group row">
          <Label className="form-inline col-sm-6 success-page-label">{detail}</Label>
          <div className="col-sm-6 success-page-value">{experimentSummary[detail as string]}</div>
        </div>
      ));
    }

    return null;
  };

  const redirect = () => {
    props.history.push({
      pathname: Routes.experimentsPage.link,
    });
  };

  return experimentSummary ? (
    <div className="success-page">
      <h4 className="home-title all-in-one">{Messages.experimentSuccessfullySubmitted}</h4>

      <h5 className="success-page-summary">{Labels.experimentSummary}</h5>
      <div className="success-page-data">{renderDetails()}</div>
      <p>{Messages.logInToCRC}</p>
      <p>{Messages.toReviewExperimentsStatuses}</p>
      <button className="show-actions" onClick={redirect}>
        {Labels.trackYourExperiment}
      </button>
    </div>
  ) : null;
};
