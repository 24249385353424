import React from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import BarLineGraphViewController from '@/components/Graphs/BarLineGraph/BarLineGraphViewController';
import { BarLineChartProps, MultiLineScatterChartProps } from '@/components/Graphs/GraphBaseType';
import MultiLineScatterGraphViewController from '@/components/Graphs/MultiLineScatterGraph/MultiLineScatterViewController';
import { Namespaces as NS } from '@/constants/SystemConstants';
import TeachingBubbleTemplate from '@/partials/TeachingBubble/TeachingBubbleTemplate';
import { ActionTypeVoid } from '@/types/AppSettingsTypes';

import AnnotationDetailsTemplate from './AnnotationDetailsTemplate';
import {
  AnnotationsChartData,
  ErrorPerPageChartData,
  ErrorsGraphItemProp,
  ErrorsGraphTemplateType,
  ErrorVsPageChartData,
} from './ErrorsGraphType';

import styles from './ErrorsGraph.module.css';

const ErrorsGraphTemplateFC: React.FC<ErrorsGraphTemplateType> = (props: ErrorsGraphTemplateType): React.ReactElement => {
  const errorsVsPageGraphProp: ErrorsGraphItemProp<ErrorVsPageChartData, MultiLineScatterChartProps> = props.errorVsPageGraphProp;
  const errorsPerPageGraphProp: ErrorsGraphItemProp<ErrorPerPageChartData, BarLineChartProps> = props.errorPerPageGraphProp;
  const errorsVsPageTitle = t('error-count-vs-page-views', { ns: NS.TABLE });
  const errorsPerPageTitle = t('errors-per-page-view', { ns: NS.TABLE });
  const selectedAnnotation: AnnotationsChartData = props.selectedAnnotation;
  const isTeachingBubbleOpen: boolean = props.isTeachingBubbleOpen;
  const hideTeachingBubble: ActionTypeVoid = props.hideTeachingBubble;
  const teachingBubbleTarget = useId('teachingBubble');

  return (
    <div>
      <div className="row">
        <div id={teachingBubbleTarget} className="col-7">
          <div className={styles['graph-title']}>
            {isTeachingBubbleOpen && (
              <TeachingBubbleTemplate
                onDismiss={hideTeachingBubble}
                teachingBubbleProps={{
                  hasCondensedHeadline: true,
                }}
                target={teachingBubbleTarget}
                title={selectedAnnotation.releases?.annotationName ?? ''}
              >
                <AnnotationDetailsTemplate
                  timeGenerated={selectedAnnotation.timeGenerated}
                  releases={selectedAnnotation.releases}
                />
              </TeachingBubbleTemplate>
            )}
            <Text>{errorsVsPageTitle}</Text>
          </div>
          <MultiLineScatterGraphViewController data={errorsVsPageGraphProp.data} chartProp={errorsVsPageGraphProp.chartProp} />
        </div>
        <div className="col-5">
          <div className={styles['graph-title']}>
            <Text>{errorsPerPageTitle}</Text>
          </div>
          <BarLineGraphViewController data={errorsPerPageGraphProp.data} chartProp={errorsPerPageGraphProp.chartProp} />
        </div>
      </div>
    </div>
  );
};

const ErrorsGraphTemplate = observer(ErrorsGraphTemplateFC);

export default ErrorsGraphTemplate;
