import React from 'react';
import { observer } from 'mobx-react-lite';

import TimeFilterTemplate from './TimeFilterTemplate';
import { TimeButtonData, TimeFilterType } from './TimeFilterType';

const TimeFilterViewControllerFC: React.FC<{ viewModel: TimeFilterType }> = (props: { viewModel: TimeFilterType }) => {
  const searchParams = new URLSearchParams(location.search);

  const onTimeChange = (item: string, index: number) => {
    const { setDateRange } = props.viewModel;

    setDateRange(timeButtons[index as number].text);
  };

  const timeButtons: TimeButtonData[] = [
    {
      text: '1h',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '24h',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '3d',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '7d',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '30d',
      primary: true,
      onClick: onTimeChange,
    },
    {
      text: '45d',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '60d',
      primary: false,
      onClick: onTimeChange,
    },
    {
      text: '90d',
      primary: false,
      onClick: onTimeChange,
    },
  ];

  timeButtons.forEach((time) => {
    if (time.text === searchParams.get('dateRange')) {
      timeButtons[4].primary = false;
      time.primary = true;
    }
  });

  const timeButtonLists = timeButtons.map((item) => {
    const { ...buttonProp } = item;

    return buttonProp;
  });

  return <TimeFilterTemplate buttonProps={timeButtonLists} />;
};

const TimeFilterViewController = observer(TimeFilterViewControllerFC);

export default TimeFilterViewController;
