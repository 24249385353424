import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { TextField } from '@fluentui/react';
import { t } from 'i18next';
import { Button, TextArea } from 'semantic-ui-react';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { adoQueryRequestService } from '@/services/request-services/ADOQueryRequestService';

import '@/styles/Air.css';
import styles from './ADOQueries.module.css';

const ADOQueriesTemplateFC: React.FunctionComponent = () => {
  const [output, setOutput] = React.useState('');
  const [validOutput, setValidOutput] = React.useState(true);
  const [id, setId] = React.useState('');

  const noDataError = 'ERROR: Did not receive data.';

  const runQuery: any = async () => {
    const queryParams = {
      id,
      personalaccesstoken: '', // include PAT here
    };

    const response: Promise<string> = adoQueryRequestService.runQuery(queryParams);

    try {
      response
        .then((result) => {
          setOutput(JSON.stringify(result));
          setValidOutput(true);
        })
        .catch((error) => {
          setOutput(error);
          setValidOutput(false);
        });
    } catch (error: any) {
      console.error(error);
      setOutput(noDataError);
      setValidOutput(false);
    }
  };

  return (
    <div>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>ado-queries</Trans>
      </h2>

      <TextField
        className={styles['width']}
        label={t('ado-query-id', { ns: NS.DEFAULT })}
        defaultValue={''}
        onChange={(event: any, value: any) => setId(value)}
      />

      <div>
        <Button className={styles['item']} floated="right" primary type="submit" content={'Run query'} onClick={runQuery} />
      </div>
      <br />

      <div>
        <TextArea
          style={{ minHeight: 300, minWidth: 1000, border: validOutput ? '1px solid black' : '1px solid red' }}
          value={output}
        />
      </div>
    </div>
  );
};

const ADOQueriesTemplate = observer(ADOQueriesTemplateFC);

export default ADOQueriesTemplate;
