import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { MessageBar, MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { t } from 'i18next';

import { TimeInterval } from '@/constants/DateFormatConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import AppSettingsService from '@/services/AppSettingsService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { MessageBarMode, MessageBarType, onDismissType } from './MessageBarTypes';

import styles from './MessageBar.module.css'; // Apply any SideBar level CSS Module styling here.

const MessageBarTemplateFC: React.FC<MessageBarType> = (props: MessageBarType): React.ReactElement => {
  const { draggable, multiline, type, children, id, mode, group = false } = props;
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { localStorage, systemMessageStore } = rootStore;

  const appSettings = new AppSettingsService(); // TODO: JOHN CHECK IF THIS IS CORRECT.
  const storageKey = AppSettingsService.MESSAGEBAR_SEEN_KEY;
  const rememberState = id && (mode ? !mode || mode === MessageBarMode.rememberState : false);
  const displayOnceOnly = id && mode === MessageBarMode.displayOnceOnly;
  const cannotDismiss = mode === MessageBarMode.cannotDismiss;
  const fadeAwayDelay = TimeInterval.THREE_SECONDS;

  const onDismiss: onDismissType = () => {
    const { deleteGroupMessages } = systemMessageStore;

    if (group) {
      deleteGroupMessages(id);
    } else if (id) {
      deleteGroupMessages(id);

      if (rememberState) {
        appSettings.addToStorageArray(localStorage, storageKey, id);
      }
    }
  };

  // Save to memory so it will not show up again.
  displayOnceOnly && appSettings.addToStorageArray(localStorage, storageKey, id);

  // Message Fades Away automatically after the specified time out
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    if (mode === MessageBarMode.fadeAway) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onDismiss();
      }, fadeAwayDelay);

      return () => clearTimeout(timer);
    }
  }, [MessageBarMode.fadeAway]);

  if (!isVisible) {
    return null;
  }

  // Base case: Passing in a react node directly.
  if (!Array.isArray(children)) {
    return (
      <MessageBar
        className={styles.messagebar}
        messageBarType={type || FluentMessageBarType.warning}
        draggable={draggable || false}
        isMultiline={multiline || false}
        onDismiss={cannotDismiss ? undefined : onDismiss}
        dismissButtonAriaLabel={t('close', { ns: NS.COMMON })}
      >
        {children}
      </MessageBar>
    );
  }

  // Base case: The global messages stack contains 0 messages.
  if (!children.length) {
    return <></>;
  }

  // Recursive step: Passing in the global messages stack (an array).

  // Gather the messages which DO NOT belong to a group and assign a message bar to each such message.
  const nonGrouped = children.filter((message: SystemMessageType) => message?.groupId === '');
  const items: React.ReactNode[] = nonGrouped.map((item: SystemMessageType, index: number) => {
    const content = item.namespace !== '' ? <Trans ns={item.namespace as Namespace}>{item.message}</Trans> : item.message;

    return (
      <MessageBarTemplate id={item.id} type={item.type} mode={item.mode} multiline={item.multiline} key={index} group={false}>
        <>{content}</>
      </MessageBarTemplate>
    );
  });

  // Gather the messages which do belong to a group, within the respective groups.
  // Assign a message bar to each entire group.
  const coveredGroupIds: string[] = [];
  const grouped = children.filter((message: SystemMessageType) => message?.groupId !== '');

  for (let i = 0; i < grouped.length; i++) {
    const child: SystemMessageType = grouped[i as number];

    // If the current child message's group hasn't been covered, create a message bar for this group.
    if (child?.groupId && !coveredGroupIds.includes(child.groupId)) {
      const group = grouped.filter((message: SystemMessageType) => message?.groupId === child.groupId);
      const content = group.map((value: any, index: number) => (
        <div key={index}>
          {group[index as number].namespace !== undefined ? (
            <Trans ns={group[index as number].namespace as Namespace}>{group[index as number].message}</Trans>
          ) : (
            group[index as number].message
          )}
        </div>
      ));

      items.push(
        <MessageBarTemplate
          key={child.groupId}
          id={child.groupId}
          type={child.type}
          mode={child.mode}
          multiline={child.multiline}
          group={true}
        >
          <>{content}</>
        </MessageBarTemplate>,
      );

      coveredGroupIds.push(child.groupId);
    }
  }

  return <div>{items}</div>;
};

const MessageBarTemplate = observer(MessageBarTemplateFC);

export default MessageBarTemplate;
