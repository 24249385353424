import { action, makeObservable, observable } from 'mobx';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { ErrorsPageViewData } from '@/components/Reliability/ErrorsGraph/ErrorsGraphType';
import { Reliability } from '@/constants/AppInsightsConstants';
import { RootStore } from '@/stores/RootStore';
import { BooleanTypeAny } from '@/types/AppSettingsTypes';
import { UrlArray } from '@/utils/UrlArray';
import { UrlVariable } from '@/utils/UrlVariable';

import { ReleaseAnnotationsType } from './ReleaseAnnotationsType';
import {
  DurationType,
  ErrorsType,
  GetDurationType,
  GetListType,
  GetNumberType,
  GetStringType,
  SetDurationType,
  SetListType,
  SetNumberType,
  SetStringType,
} from './ReliabilityType';

class ReliabilityStore {
  public isGraphLoading = false;
  public isPagesLoading = false;
  public isUsersLoading = false;
  public isErrorsLoading = false;
  public isGraphApiError = false;
  public isPagesApiError = false;
  public isErrorsApiError = false;
  public isUsersApiError = false;
  public selectedPages: UrlArray;
  public pages: Array<IDropdownOption> = [];
  public selectedUser: UrlVariable;
  public users: Array<IDropdownOption> = [];
  public errors: Array<ErrorsType> = [];
  public totalPageViews = 0;
  public totalErrors = 0;
  public dateRange: UrlVariable;
  public timeDuration = 30;
  public durationType = DurationType.Day;
  public bucketDuration = 24;
  public bucketType = DurationType.Hour;
  public graphData: Array<ErrorsPageViewData> = [];
  public releaseAnnotations: Array<ReleaseAnnotationsType> = [];

  protected rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.selectedPages = new UrlArray('pages', ',', []);
    this.selectedUser = new UrlVariable('user', '');
    this.dateRange = new UrlVariable('dateRange', Reliability.DEFAULT_DATE_RANGE);
    this.dateRange.get(); // Set the value

    makeObservable(this, {
      isGraphLoading: observable,
      isPagesLoading: observable,
      isErrorsLoading: observable,
      isUsersLoading: observable,
      isGraphApiError: observable,
      isPagesApiError: observable,
      isErrorsApiError: observable,
      isUsersApiError: observable,
      pages: observable,
      selectedPages: observable,
      users: observable,
      selectedUser: observable,
      errors: observable,
      totalPageViews: observable,
      totalErrors: observable,
      dateRange: observable,
      timeDuration: observable,
      durationType: observable,
      bucketDuration: observable,
      bucketType: observable,
      graphData: observable,
      releaseAnnotations: observable,

      setPages: action,
      setSelectedPages: action,
      setUsers: action,
      setSelectedUser: action,
      setErrors: action,
      setTotalPageViews: action,
      setTotalErrors: action,
      setDateRange: action,
      setTimeDuration: action,
      setDurationType: action,
      setBucketDuration: action,
      setBucketType: action,
      setGraphData: action,
      setIsGraphLoading: action,
      setIsPagesLoading: action,
      setIsUsersLoading: action,
      setIsErrorsLoading: action,
      setIsGraphApiError: action,
      setIsPagesApiError: action,
      setIsErrorsApiError: action,
      setIsUsersApiError: action,
      setReleaseAnnotations: action,
    });
  }

  public getPages: GetListType = () => {
    return this.pages;
  };

  public setPages: SetListType = (pages: Array<IDropdownOption>) => {
    if (pages) {
      this.pages = pages;
    }
  };

  public getSelectedPages: GetListType = () => {
    return this.selectedPages.get();
  };

  public setSelectedPages: SetListType = (selected: string[]) => {
    this.selectedPages.set(selected);
  };

  public getUsers: GetListType = () => {
    return this.users;
  };

  public setUsers: SetListType = (users: Array<IDropdownOption>) => {
    this.users = users;
  };

  public setSelectedUser: SetStringType = (selected: string) => {
    this.selectedUser.set(selected);
  };

  public getSelectedUser: GetStringType = () => {
    return this.selectedUser.get();
  };

  public getErrors: GetListType = () => {
    return this.errors;
  };

  public setErrors: SetListType = (errors: Array<ErrorsType>) => {
    this.errors = errors;
  };

  public getTotalPageViews: GetNumberType = () => {
    return this.totalPageViews;
  };

  public setTotalPageViews: SetNumberType = (totalPageViews: number) => {
    this.totalPageViews = totalPageViews;
  };

  public getTotalErrors: GetNumberType = () => {
    return this.totalErrors;
  };

  public setTotalErrors: SetNumberType = (totalErrors: number) => {
    this.totalErrors = totalErrors;
  };

  public getDateRange: GetStringType = () => {
    return this.dateRange.get();
  };

  public setDateRange = (dateRange: string) => {
    this.dateRange.set(dateRange);

    switch (dateRange) {
      case '1h':
        this.setTimeDuration(1);
        this.setDurationType(DurationType.Hour);
        this.setBucketDuration(3);
        this.setBucketType(DurationType.Minute);
        break;
      case '24h':
        this.setTimeDuration(24);
        this.setDurationType(DurationType.Hour);
        this.setBucketDuration(1);
        this.setBucketType(DurationType.Hour);
        break;
      case '3d':
        this.setTimeDuration(3);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(3);
        this.setBucketType(DurationType.Hour);
        break;
      case '7d':
        this.setTimeDuration(7);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(6);
        this.setBucketType(DurationType.Hour);
        break;
      case '30d':
        this.setTimeDuration(30);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(24);
        this.setBucketType(DurationType.Hour);
        break;
      case '45d':
        this.setTimeDuration(45);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(36);
        this.setBucketType(DurationType.Hour);
        break;
      case '60d':
        this.setTimeDuration(60);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(48);
        this.setBucketType(DurationType.Hour);
        break;
      case '90d':
        this.setTimeDuration(90);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(72);
        this.setBucketType(DurationType.Hour);
        break;
      default:
        this.setTimeDuration(Reliability.DEFAULT_DAYS);
        this.setDurationType(DurationType.Day);
        this.setBucketDuration(Reliability.DEFAULT_HOURS);
        this.setBucketType(DurationType.Hour);

        this.dateRange.set(Reliability.DEFAULT_DATE_RANGE);
    }
  };

  public getTimeDuration: GetNumberType = () => {
    return this.timeDuration;
  };

  public setTimeDuration: SetNumberType = (duration: number) => {
    this.timeDuration = duration;
  };

  public getDurationType: GetDurationType = () => {
    return this.durationType;
  };

  public setDurationType: SetDurationType = (durationType: DurationType) => {
    this.durationType = durationType;
  };

  public getBucketDuration: GetNumberType = () => {
    return this.bucketDuration;
  };

  public setBucketDuration: SetNumberType = (duration: number) => {
    this.bucketDuration = duration;
  };

  public getBucketType: GetDurationType = () => {
    return this.bucketType;
  };

  public setBucketType: SetDurationType = (bucketType: DurationType) => {
    this.bucketType = bucketType;
  };

  public getGraphData: GetListType = () => {
    return this.graphData;
  };

  public setGraphData: SetListType = (data: Array<ErrorsPageViewData>) => {
    this.graphData = data;
  };

  public setIsGraphLoading: BooleanTypeAny = (value: boolean) => {
    this.isGraphLoading = value;
  };

  public setIsPagesLoading: BooleanTypeAny = (value: boolean) => {
    this.isPagesLoading = value;
  };

  public setIsUsersLoading: BooleanTypeAny = (value: boolean) => {
    this.isUsersLoading = value;
  };

  public setIsErrorsLoading: BooleanTypeAny = (value: boolean) => {
    this.isErrorsLoading = value;
  };

  public setIsGraphApiError: BooleanTypeAny = (value: boolean) => {
    this.isGraphApiError = value;
  };

  public setIsPagesApiError: BooleanTypeAny = (value: boolean) => {
    this.isPagesApiError = value;
  };

  public setIsErrorsApiError: BooleanTypeAny = (value: boolean) => {
    this.isErrorsApiError = value;
  };

  public setIsUsersApiError: BooleanTypeAny = (value: boolean) => {
    this.isUsersApiError = value;
  };

  public getReleaseAnnotations = () => {
    return this.releaseAnnotations;
  };

  public setReleaseAnnotations = (annotations: Array<ReleaseAnnotationsType>) => {
    this.releaseAnnotations = annotations;
  };
}

export default ReliabilityStore;
