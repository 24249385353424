import * as React from 'react';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Avatar, Field, FluentProvider, Tag, webDarkTheme, webLightTheme } from '@fluentui/react-components';
import {
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  TagPickerProps,
  useTagPickerFilter,
} from '@fluentui/react-tag-picker';
import { t } from 'i18next';

import { TimeInterval } from '@/constants/DateFormatConstants';
import { EventKeys, Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import { TagPickerConstants } from '@/partials/TagPicker/TagPickerConstants';
import { TagPickerComponentProps } from '@/partials/TagPicker/TagPickerTypes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { useDebouncedOnChange } from '@/utils/Performance';

import styles from '@/partials/TagPicker/TagPicker.module.css';

export const TagPickerComponent: React.FC<TagPickerComponentProps> = (props: TagPickerComponentProps) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { addGlobalMessage } = systemMessageStore;

  // Props Const
  const {
    data,
    size = TagPickerConstants.DEFAULT_SIZE,
    onChange,
    onKeyDown,
    placeholder = t(Common.SEARCH_USER_PLACEHOLDER, { ns: NS.COMMON }),
    maxSelection,
    width = TagPickerConstants.DEFAULT_WIDTH,
    isDarkMode,
    failMessageGroupId,
    showMessageInPopup,
  } = props;

  // Other Const
  const [query, setQuery] = React.useState<string>('');
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const theme = isDarkMode ? webDarkTheme : webLightTheme;
  const noItemsText = t(Common.NO_ITEMS, { ns: NS.COMMON });

  const debouncedOnChange = useDebouncedOnChange(TimeInterval.THREE_HUNDRED_MILLISECONDS);

  const onOptionSelect: TagPickerProps['onOptionSelect'] = (e, data) => {
    if (data.value === TagPickerConstants.NO_MATCHES_VALUE || (maxSelection && selectedOptions.length >= maxSelection)) {
      return;
    }

    setSelectedOptions(data.selectedOptions);
    setQuery('');

    debouncedOnChange(null, onChange, data.selectedOptions);
  };

  const handleBlur = (): void => {
    const KeyDownEvent: any = new KeyboardEvent(EventKeys.KEYDOWN, { key: EventKeys.ENTER });

    handleKeyDown(KeyDownEvent);
  };

  // Allows to add new value into the TagPicker which is not in the list
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (onKeyDown) {
      const newValue: string = query?.trim();

      let isValidData = false;

      if (newValue && event.key.toLowerCase() === EventKeys.ENTER) {
        isValidData = onKeyDown(event, newValue);
      }

      if (isValidData) {
        // Commented line may be required in future when we want to set maxSelection
        // if (!selectedOptions.includes(newValue) && (!maxSelection || selectedOptions.length < maxSelection)) {
        if (selectedOptions.includes(newValue)) {
          const failMessage: SystemMessageType = {
            message: t(Common.DUPLICATE_DATA, { ns: NS.COMMON }),
            type: MessageBarType.error,
            groupId: failMessageGroupId,
            showInPopup: showMessageInPopup,
          };

          addGlobalMessage(failMessage);
        } else {
          if (!data.includes(newValue)) {
            data.push(newValue); // Adding the new value to the data set
          }

          setSelectedOptions([...selectedOptions, newValue]);
          debouncedOnChange(null, onChange, [...selectedOptions, newValue]);
        }

        setQuery('');
      }
    }
  };

  const children = useTagPickerFilter({
    query,
    options: data,
    noOptionsElement: <TagPickerOption value={TagPickerConstants.NO_MATCHES_VALUE}>{noItemsText}</TagPickerOption>,
    renderOption: (option) => (
      <TagPickerOption
        key={option}
        media={
          <Avatar
            shape={TagPickerConstants.AVATAR_SHAPE_SQUARE}
            aria-hidden
            name={option}
            color={TagPickerConstants.AVATAR_COLOR}
          />
        }
        value={option}
      >
        {option}
      </TagPickerOption>
    ),
    filter: (option) => !selectedOptions.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
  });

  return (
    <FluentProvider theme={theme}>
      <Field style={{ width }} className={styles['tag-picker-field']}>
        <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedOptions} size={size}>
          <TagPickerControl>
            <TagPickerGroup>
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape={TagPickerConstants.AVATAR_SHAPE_ROUNDED}
                  media={<Avatar aria-hidden name={option} color={TagPickerConstants.AVATAR_COLOR} />}
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput
              aria-label={t(Common.SELECT_USER, { ns: NS.COMMON })}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              placeholder={selectedOptions.length > 0 ? '' : placeholder}
            />
          </TagPickerControl>
          <TagPickerList>{children}</TagPickerList>
        </TagPicker>
      </Field>
    </FluentProvider>
  );
};
