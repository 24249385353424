import React from 'react';
import ReactPaginate from 'react-paginate';

const CustomPaginate = (props) => {
  React.useEffect(() => {
    const paginateContainer = document.querySelector('.pagination');

    if (paginateContainer) {
      paginateContainer.setAttribute('role', 'list');
    }
  });

  return <ReactPaginate {...props} />;
};

export default CustomPaginate;
