import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, ChoiceGroup, DatePicker, DefaultButton, DetailsList, SearchBox, TextField } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { t } from 'i18next';
import moment from 'moment-timezone';
import format from 'string-template';

import { Message } from '@/components/_air/common';
import { Defaults as AirDefault } from '@/constants/AirConstants';
import { Messages, NotificationMessages, Statuses } from '@/constants/AirConstants';
import { DateFormats, TimeRangeOptions, TimeRangeRemoveOptions } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { FilterOptions, Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageDivider from '@/partials/PageDivider/PageDivider';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ExecutionGoalItem, ExecutionGoalsList } from '@/types/_air';
import { processApiError } from '@/utils/_air/ErrorHandler';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';
import { formatDate } from '@/utils/Dates';
import { extractString, filterIndicator } from '@/utils/Helpers';

import '@/styles/Air.css';
import '@/styles/AirExtras.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

const ExperimentStatusListFC: React.FC = (props): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { airSettingsStore, userSettingsStore } = rootStore;
  const {
    manageAirExperimentsTimeValue,
    manageAirExperimentStartDate,
    manageAirExperimentEndDate,
    setManageAirExperimentsTimeValue,
    setManageAirExperimentStartDate,
    setManageAirExperimentEndDate,
    manageAirExperimentsTeamName,
    setManageAirExperimentsTeamName,
    manageAirExperimentsSearchValue,
    setManageAirExperimentsSearchValue,
    doManageAirExperimentReset,
  } = airSettingsStore;
  const { timeZone } = userSettingsStore;

  const [executionGoalList, setExecutionGoalList] = React.useState<ExecutionGoalItem[]>([]);
  const [isNoDataFoundMessageShown, setIsNoDataFoundMessageShown] = React.useState<boolean>(false);
  const [showShimmer, setShowShimmer] = React.useState(true);
  const cancellationToken = useCancellationToken();
  const [selectedRow, setSelectedRow] = React.useState<ExecutionGoalItem | null>(null);
  const [filteredData, setFilteredData] = React.useState<ExecutionGoalItem[]>([]);
  const allGoals = React.useRef<ExecutionGoalsList[]>();

  const history = useHistory();

  const title = t('air', { ns: NS.TITLES });
  const subTitle = t('status', { ns: NS.DEFAULT });

  React.useEffect(() => {
    experimentStatusRequestService
      .getExecutionGoalsList(manageAirExperimentsTeamName, cancellationToken)
      .then((result) => {
        const experimentData = [];

        if (!isAPIError(result) && result) {
          allGoals.current = result;
          result.forEach((executionGoal, ind) => {
            const { id: executionGoalId, teamName } = executionGoal;
            const name = executionGoal.experimentName ?? Statuses.notSpecified;
            const createdTime =
              moment.tz(executionGoal.createdTime, timeZone).format(DateFormats.STANDARD_DATE_TIME) ?? Statuses.notSpecified;
            const templateId = executionGoal.metadata?.templateId;
            const ownerList = executionGoal.metadata?.owner.split(/[;,]+/);
            const ls = [];

            const experimentName = (
              <Link to={`${Navigation.AIR.SCHEDULER_STATUS}/${teamName}/${executionGoalId}/${name}`}>{name}</Link>
            );

            ownerList.forEach((email) => {
              const name = email.substring(0, email.lastIndexOf('@'));
              ls.push(name);
            });

            const owners = ls.join(';');

            const experiment = {
              executionGoalId,
              experimentName,
              createdTime,
              owners,
              teamName,
              templateId,
              name,
            };

            experimentData.push(experiment);
          });
        }

        setExecutionGoalList(experimentData);
        setShowShimmer(false);
        setIsNoDataFoundMessageShown(true);
      })
      .catch((error) => {
        setExecutionGoalList([]);
        setShowShimmer(false);
        processApiError(error, NotificationMessages.errorOnReceivingExecutionGoalList);
      });
  }, [cancellationToken, manageAirExperimentsTeamName]);

  const filterGoalsByTime = (timeRange: string, goals: ExecutionGoalItem[]): ExecutionGoalItem[] => {
    if (!timeRange || timeRange === TimeRangeRemoveOptions.ALL) {
      return goals;
    }

    return goals.filter((goal: ExecutionGoalItem) => {
      const time = new Date(goal.createdTime);
      const matches = time >= manageAirExperimentStartDate && time <= manageAirExperimentEndDate;

      return matches;
    });
  };

  const handleClearAllFilters = () => {
    doManageAirExperimentReset();
  };

  const onSelectRange = (key: string) => {
    const now = new Date();
    const startDate = new Date();

    setManageAirExperimentsTimeValue(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      startDate.setHours(now.getHours() - Number(key));
      setManageAirExperimentStartDate(startDate);
      setManageAirExperimentEndDate(now);
    }
  };

  const searchGoals = (value: string, goals: ExecutionGoalItem[]): ExecutionGoalItem[] => {
    if (!value) return goals;

    const formattedValue = value.toLowerCase();

    const filteredExperiments = goals.filter((item: any) => {
      const name = extractString(item.experimentName)?.toLowerCase() ?? '';
      const date = moment.tz(item.createdTime, timeZone).format(DateFormats.STANDARD_DATE_TIME) ?? '';

      const owner = item.owners.toLowerCase() ?? '';

      return name.includes(formattedValue) || date.includes(formattedValue) || owner.includes(formattedValue);
    });

    return filteredExperiments;
  };

  React.useEffect(() => {
    if (executionGoalList && executionGoalList.length > 0) {
      if (!allGoals.current) {
        return;
      }

      const filteredByTime = filterGoalsByTime(manageAirExperimentsTimeValue, executionGoalList);
      const searchedGoals = searchGoals(manageAirExperimentsSearchValue, filteredByTime);

      setFilteredData(searchedGoals);
    } else {
      setFilteredData([]);
    }
  }, [
    manageAirExperimentsSearchValue,
    manageAirExperimentsTimeValue,
    manageAirExperimentStartDate,
    manageAirExperimentEndDate,
    executionGoalList,
  ]);

  const columns = [
    {
      key: 'ExperimentName',
      name: t('experiment-name', { ns: NS.TABLE }),
      fieldName: 'experimentName',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'ExecutionGoalId',
      name: t('execution-goal-id', { ns: NS.TABLE }),
      fieldName: 'executionGoalId',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'Owner',
      name: t('owner', { ns: NS.TABLE }),
      fieldName: 'owners',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'CreatedDate',
      name: t('created-date', { ns: NS.TABLE }),
      fieldName: 'createdTime',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = selection.getSelection();

      if (selectedItems.length > 0) {
        setSelectedRow(selectedItems[0] as ExecutionGoalItem | null);
      } else {
        setSelectedRow(null);
      }
    },
  });

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={manageAirExperimentsTimeValue}
            options={TimeRangeOptions}
            onChange={(ev, option) => {
              onSelectRange(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={manageAirExperimentsTimeValue !== FilterOptions.CUSTOM}
              onSelectDate={setManageAirExperimentStartDate}
              value={manageAirExperimentStartDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(manageAirExperimentStartDate.toString(), timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={manageAirExperimentsTimeValue !== FilterOptions.CUSTOM}
              onSelectDate={setManageAirExperimentEndDate}
              value={manageAirExperimentEndDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(manageAirExperimentEndDate.toString(), timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const hasFiltersApplied = () => {
    const searchFilter = !!manageAirExperimentsSearchValue.trim();
    const dateFilter = manageAirExperimentsTimeValue !== TimeRangeRemoveOptions.ALL;
    const teamFilter = manageAirExperimentsTeamName !== AirDefault.teamName;

    return searchFilter || dateFilter || teamFilter;
  };

  const items: ICommandBarItemProps[] = [
    {
      key: 'failureAnalysis',
      text: t('failure-analysis', { ns: NS.COMMON }),
      title: t('failure-analysis', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.DEVELOPER_TOOLS },
      disabled: !selectedRow,
      onClick: () => {
        const { name } = selectedRow;
        history.push(`${Navigation.AIR.EXECUTION_GOAL_FAILURE}/${name}`);
      },
    },
  ];

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-text', { ns: NS.COMMON })}
          title={t('search-text', { ns: NS.COMMON })}
          value={manageAirExperimentsSearchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event, newValue) => setManageAirExperimentsSearchValue(newValue)}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          onClear={() => {
            setManageAirExperimentsSearchValue('');
          }}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'team-name-filter',
      onRender: () => (
        <TextField
          placeholder={t('team-name', { ns: NS.TABLE })}
          title={t('team-name', { ns: NS.TABLE })}
          value={manageAirExperimentsTeamName}
          onChange={(event, newValue) => setManageAirExperimentsTeamName(newValue)}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('created-date', { ns: NS.TABLE })}
          title={t('created-date', { ns: NS.TABLE })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={menuProps}
        />
      ),
    },
    {
      key: 'clear-all-filters',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.CLEAR_FILTER }}
          onClick={handleClearAllFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
  ];

  const data = filteredData || executionGoalList;
  const hasFilters = hasFiltersApplied();
  const resultsText = filterIndicator(hasFilters, data.length);

  return (
    <div className="fullscreen">
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.AIR.MANAGE_EXPERIMENT]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent>
            <>
              <PageCommandBar items={items}></PageCommandBar>
              <PageDivider />
              <PageFilterBar items={filterItems}></PageFilterBar>
              <div>{resultsText}</div>
              {isNoDataFoundMessageShown && !executionGoalList.length ? (
                <div className="air-wrapper">
                  <Message message={Messages.noDataFound} />
                </div>
              ) : showShimmer ? (
                <LoadingSpinner />
              ) : (
                <TableViewViewController
                  tableStyle="table-scrollable air-manage-table"
                  columns={columns as IColumn[]}
                  items={data}
                  selection={selection}
                  selectionMode={SelectionMode.single}
                  selectionPreservedOnEmptyClick={true}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  enableToolBar={false}
                />
              )}
            </>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ExperimentStatusList = observer(ExperimentStatusListFC);

export default ExperimentStatusList;
