import React from 'react';
import { observer } from 'mobx-react-lite';
import { CommandBar } from '@fluentui/react';
import { IButtonProps } from '@fluentui/react/lib/Button';

import { PageCommandBarType } from './PageCommandBarTypes';

import './PageCommandBar.css';
import styles from './PageCommandBar.module.css';

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const PageCommandBarFC: React.FC<PageCommandBarType> = (props: PageCommandBarType): React.ReactElement => {
  const { items, farItems, overflowItems } = props;

  return (
    <div className={styles['pagecommandbar-wrapper']}>
      <CommandBar
        className={styles['pagecommandbar']}
        items={items}
        farItems={farItems}
        overflowItems={overflowItems}
        overflowButtonProps={overflowProps}
      ></CommandBar>
    </div>
  );
};

const PageCommandBar = observer(PageCommandBarFC);

export default PageCommandBar;
