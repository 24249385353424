type TableDataType = {
  table1: any;
  table2: any;
};

const MockTableData: TableDataType = {
  table1: {
    title: 'Example Table 1',
    items: [
      { key: 0, name: 'Item Name', value: 0, text: 'abc' },
      { key: 1, name: 'Item Name', value: 1, text: 'abc' },
      { key: 2, name: 'Item Name', value: 2, text: 'abc' },
    ],
    columns: [
      { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 1000 },
      { key: 'column2', name: 'Value', fieldName: 'value', minWidth: 100, maxWidth: 1000 },
      { key: 'column3', name: 'Text', fieldName: 'text', minWidth: 100, maxWidth: 1000 },
    ],
    displayColumns: ['Name', 'Value'],
  },
  table2: {
    title: 'Example Table 2',
    items: [
      { key: 0, name: 'Item Name', value: 3, text: 'Example text' },
      { key: 1, name: 'Item Name', value: 4, text: 'Example text' },
      { key: 2, name: 'Item Name', value: 5, text: 'Example text' },
    ],
    columns: [
      { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 1000 },
      { key: 'column2', name: 'Value', fieldName: 'value', minWidth: 100, maxWidth: 1000 },
      { key: 'column3', name: 'Text', fieldName: 'text', minWidth: 100, maxWidth: 1000 },
    ],
  },
};

export { MockTableData, TableDataType };
