import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Icon, MessageBarType as FluentMessageBarType, Panel, PanelType } from '@fluentui/react';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { ReactGridDropItemType } from '@/components/ReactGrid/ReactGridTypes';
import { Namespaces as NS } from '@/constants/SystemConstants';
import loadData from '@/scripts/ReadInitialData';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { MockWidgetPanelLibraryData, WidgetPanelLibraryType } from '@/mocks/WidgetLibrary/LibraryData';

import { WidgetPanelTemplateType } from './WidgetPanelTypes';

import styles from './WidgetPanel.module.css';

const WidgetPanelFC: React.FC<WidgetPanelTemplateType> = (props: WidgetPanelTemplateType): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage } = systemMessageStore;
  const {
    isDeveloperMode,
    isDebugMode,
    setDroppingItem,
    widgetMappings /* eslint-disable-line @typescript-eslint/no-unused-vars */,
  } = appSettingsStore;

  const { isPanelOpen, hidePanel } = props;
  const panelWidth = '400px';

  const [panelData, setPanelData] = React.useState<WidgetPanelLibraryType[]>([]);

  const widgetPanelLibraryDataSuccess = 'widget-panel-library-data-success';
  const widgetPanelLibraryDataFail = 'widget-panel-library-data-fail';
  const successGroupId = 'cosmos-initial-success';
  const failGroupId = 'cosmos-initial-fail';
  const namespace = NS.COSMOS;

  React.useEffect(() => {
    loadData()
      .then((result: any) => {
        setPanelData(JSON.parse(result).MOCK_WIDGET_PANEL_LIBRARY_DATA);

        const successMessage: SystemMessageType = {
          message: widgetPanelLibraryDataSuccess,
          type: FluentMessageBarType.success,
          groupId: successGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(successMessage);
          console.log(t(widgetPanelLibraryDataSuccess, { ns: namespace }));
        }
      })
      .catch(() => {
        setPanelData(MockWidgetPanelLibraryData);

        const failMessage: SystemMessageType = {
          message: widgetPanelLibraryDataFail,
          type: FluentMessageBarType.error,
          groupId: failGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(failMessage);
          console.log(t(widgetPanelLibraryDataFail, { ns: namespace }));
        }
      });
  }, []);

  // When a Drag event starts, we send some metadata to be used by the GridViewController.
  // Specifically, the ReactGridViewControllerFC.onAddItem() method will consume this data and use it
  // when saving the new state of the dashboard, by updating our layout and widgetMappings data.
  const onDragStart: (e: React.DragEvent<HTMLDivElement>, libraryWidget: WidgetPanelLibraryType) => boolean = (
    e: React.DragEvent<HTMLDivElement>,
    libraryWidget: WidgetPanelLibraryType,
  ) => {
    e.dataTransfer.setData('text/plain', ''); // Firefox support: @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
    e.dataTransfer.setData('droppable-widget', JSON.stringify(libraryWidget));

    if (libraryWidget) {
      const key = uuidv4();
      // Assign the layout information to the DroppingItem, for the Drag/Drop interaction.
      const dropItem: ReactGridDropItemType = {
        i: key,
        type: 'other',
        x: 0,
        y: 0,
        w: libraryWidget.layout.w,
        h: libraryWidget.layout.h,
      };

      isDebugMode && console.log('[WidgetPanelFC onDragStart] dropItem', dropItem);
      setDroppingItem(dropItem); // This saved object contains metadata for the dragged object before a drop.
    }

    isDebugMode && console.log('[WidgetPanelFC onDragStart] Widget Details:', libraryWidget.name, libraryWidget.key, libraryWidget);

    return true;
  };

  const onDragEnd: (e: React.DragEvent<HTMLDivElement>, libraryWidget: WidgetPanelLibraryType) => boolean = (
    e: React.DragEvent<HTMLDivElement>,
    libraryWidget: WidgetPanelLibraryType,
  ) => {
    isDebugMode && console.log('[WidgetPanelFC onDragEnd] Widget Details:', libraryWidget.name, libraryWidget.key, libraryWidget);

    return true;
  };

  const onRenderFooterContent: () => JSX.Element = React.useCallback(
    () => (
      <div>
        <DefaultButton className={styles.button} onClick={hidePanel}>
          <Trans ns={NS.COMMON as Namespace}>close</Trans>
        </DefaultButton>
      </div>
    ),
    [hidePanel],
  );

  return (
    <Panel
      className={styles.panel}
      headerText={t('widget-library', { ns: NS.TITLES })}
      // this prop makes the panel non-modal
      type={PanelType.custom}
      isBlocking={false}
      isOpen={isPanelOpen}
      onRenderFooterContent={onRenderFooterContent}
      onDismiss={hidePanel}
      closeButtonAriaLabel="Close"
      customWidth={panelWidth}
    >
      <div className={styles.title}>
        <Trans ns={NS.WIDGETS as Namespace}>panel-title</Trans>
      </div>
      {panelData.map((widget: WidgetPanelLibraryType) => (
        <div
          className={styles.item}
          key={widget.key}
          draggable="true"
          onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
            onDragStart(e, widget);
          }}
          onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
            onDragEnd(e, widget);
          }}
        >
          {widget.icon && <Icon className={styles.icon} iconName={widget.icon} />}
          <img src={widget.imageSrc} />
          <div className={styles.name}>{widget.name}</div>
          <div className={styles.description}>{widget.description}</div>
        </div>
      ))}
    </Panel>
  );
};

const WidgetPanel = observer(WidgetPanelFC);

export default WidgetPanel;
