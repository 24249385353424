import { DataType } from '@/constants/SystemConstants';

import BaseRequestService from './RequestService';

class TagRequestService extends BaseRequestService {
  async getTags() {
    const url = 'tags';
    const { data } = await this.get(url);

    return data;
  }

  async getSystemTags(systemId: number) {
    const url = `tags/systems/${systemId}`;
    const { data } = await this.get(url);

    return data;
  }

  async createSystemsTag(systemIds: number[], tagName: string) {
    const payload = {
      TagName: tagName,
      SystemIds: systemIds.length > 1 || typeof systemIds === DataType.OBJECT ? systemIds : [systemIds],
    };

    const url = 'tags';
    const { data } = await this.post(url, payload);

    return data;
  }

  async deleteSystemTag(systemId: number, tagId: number) {
    const url = `tags/${tagId}/systems/${systemId}`;
    const { data } = await this.delete(url);

    return data;
  }
}

export const ganymedeTagRequestService = new TagRequestService();
