import React from 'react';
import { Label } from '@fluentui/react';

import '@/styles/Air.css';

interface Props {
  label: string;
  text: string | number;
}

export const ExperimentStatusInfoField = ({ label, text }: Props): JSX.Element => (
  <div className="gbs-status-info-container">
    <Label className="gbs-status-info-text">{label}</Label>
    <p>{text}</p>
  </div>
);
