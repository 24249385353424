import React from 'react';
import moment from 'moment-timezone';

import { Labels, Statuses } from '@/constants/AirConstants';
import { DateFormats } from '@/constants/DateFormatConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { TargetGoalInfo, TargetGoalStatus } from '@/types/_air';

import { ExperimentStatusInfoField } from './ExperimentStatusInfoField';

import '@/styles/Air.css';

interface Props {
  status: TargetGoalInfo;
  experimentInstances: TargetGoalStatus[];
}

export const TargetGoalStatusInfo = ({ experimentInstances, status }: Props): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { userSettingsStore } = rootStore;
  const { timeZone } = userSettingsStore;

  const dates = [];

  const convertDate = (date: string): string => {
    const newDate = new Date(date);
    const result = `${newDate.getFullYear()}-${`0${newDate.getMonth() + 1}`.slice(-2)}-${`0${newDate.getDate()}`.slice(-2)}`;

    return result;
  };

  const getStatus = (): string => {
    const successPercentage = (status.targetGoal.success * 100) / status.targetGoal.count;

    if (successPercentage >= 100) {
      return 'Succeeded';
    }

    return 'In Progress';
  };

  const getFirstdate = (): string => {
    experimentInstances.map((instances) =>
      dates.push(moment.tz(instances.experimentStartTime, timeZone).format(DateFormats.STANDARD_DATE_TIME)),
    );

    const maxDate = dates.reduce((a, b) => (a < b ? a : b));

    return convertDate(maxDate.toString());
  };

  const getLastdate = (): string => {
    experimentInstances.map((instances) => dates.push(instances.experimentStartTime));

    const maxDate = dates.reduce((a, b) => (a > b ? a : b));

    return convertDate(maxDate.toString());
  };

  return (
    <div className="col-xl-6 gbs-status-info-row">
      <div className="row">
        <div className="col-sm-5">
          <ExperimentStatusInfoField label={Labels.runStatus} text={getStatus()} />
          <ExperimentStatusInfoField label={Labels.totalInstances} text={status.targetGoal.count} />
          <ExperimentStatusInfoField label={Labels.succeededInstances} text={status.targetGoal.success} />
          <ExperimentStatusInfoField label={Labels.inProgressInstances} text={status.targetGoal.progress} />
          <ExperimentStatusInfoField label={Labels.failedInstances} text={status.targetGoal.failed} />
        </div>

        <div className="col-sm-7">
          <ExperimentStatusInfoField label={Labels.firstInstanceRunDate} text={getFirstdate()} />
          <ExperimentStatusInfoField label={Labels.lastInstanceRunDate} text={getLastdate()} />
          <ExperimentStatusInfoField
            label={Labels.estimatedCompletionDate}
            text={status.targetGoal.eta === Statuses.notAvailable ? status.targetGoal.eta : convertDate(status.targetGoal.eta)}
          />
        </div>
      </div>
    </div>
  );
};
