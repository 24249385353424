/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';

import '@/styles/Labs.css';

const { Search } = Input;

export default function SearchBar(props) {
  const [q, setQ] = React.useState('');

  const onSearchHandler = () => {
    props.postSearchHandler(q);
  };

  const onEnterButton = (event) => {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  };

  const onChangeHandler = () => {
    var searchTerm = document.getElementById('search-box').value;

    setQ(searchTerm);

    if (props.searchChangeHandler) {
      props.searchChangeHandler(searchTerm);
    }
  };

  return (
    <div>
      <div onKeyDown={(e: any) => onEnterButton(e)}>
        <Search
          allowClear
          placeholder="search text"
          id="search-box"
          defaultValue={props.q}
          size="large"
          style={{ width: 600 }}
          onChange={onChangeHandler}
          onSearch={onSearchHandler}
        />
      </div>
    </div>
  );
}
