import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as qs from 'qs';
import { container } from 'tsyringe';

import { TimeInterval } from '@/constants/DateFormatConstants';
import { RootStore } from '@/stores/RootStore';
import { backendEndpoint } from '@/utils/Env';
import MsalAuthorization from '@/utils/MsalAuthorization';

const rootStore: RootStore = container.resolve(RootStore);
const { appSettingsStore } = rootStore;
const { isAdvancedMode } = appSettingsStore;
const ninetySeconds = TimeInterval.NINETY_SECONDS;
const twoHundredSeventySeconds = TimeInterval.NINETY_SECONDS * 3;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: backendEndpoint,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Clear-Site-Data': '*',
  },
  timeout: twoHundredSeventySeconds,
});

class BaseRequestService {
  protected httpClient: AxiosInstance;
  protected baseURL: string;
  protected xsrfToken: string = null;

  constructor(baseURL = '') {
    if (baseURL !== '') {
      this.baseURL = baseURL;
      this.httpClient = axios.create({
        baseURL: baseURL,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Clear-Site-Data': '*',
        },
        timeout: twoHundredSeventySeconds,
      });
    } else {
      this.baseURL = backendEndpoint;
      this.httpClient = axiosInstance;
    }
  }

  public static shortenToken(token: string): string {
    // NOTE: We are assuming we have a token of at least 8 characters here.
    return `${token.substring(0, 8)}...${token.substring(token.length - 8, token.length)}`;
  }

  async token(): Promise<string> {
    const token = await MsalAuthorization.generateToken();
    const shortToken = BaseRequestService.shortenToken(token);

    isAdvancedMode && console.log(`[BaseRequestService] Providing token: "${shortToken}"`);

    // if the token doesn't match the token value that's been stored, or the XSRF-TOKEN cookie cannot be found.
    // then generate a new XSRF token. (this only applies to calls made to the ganymede api backendpoint)
    if (this.baseURL === backendEndpoint) {
      const response = await fetch(backendEndpoint + 'antiforgery/token', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
          Vary: 'Origin',
          'Access-Control-Allow-Origin': '*',
        },
      });

      this.xsrfToken = await response.text();

      isAdvancedMode && console.log(`[BaseRequestService] Providing CSRFtoken: "${this.xsrfToken}"`);
    }

    return token;
  }

  async get<TParams = any, TResponse = any>(
    url = '',
    params?: TParams,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    requestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Clear-Site-Data': '*',
        Authorization: `Bearer ${await this.token()}`,
      },
    };
    const endPoint = `${url ? '/' : ''}${url}`;

    isAdvancedMode && console.info('[BaseRequestService] checking endpoint:', endPoint);

    return this.httpClient
      .get(endPoint, {
        params,
        paramsSerializer: (params: any) => {
          return qs.stringify(params);
        },
        ...requestConfig,
      })
      .catch((error: AxiosError) => {
        console.error('[BaseRequestService] get()', endPoint, error);
        throw error;
      });
  }

  async post<TData = any, TResponse = any>(
    url = '',
    data?: TData,
    requestConfig?: AxiosRequestConfig,
    contentType?: string,
  ): Promise<AxiosResponse<TResponse>> {
    requestConfig = {
      headers: {
        Authorization: `Bearer ${await this.token()}`,
        'Clear-Site-Data': '*',
        Vary: 'Origin',
        'XSRF-TOKEN': this.xsrfToken,
      },
      withCredentials: true,
    };

    if (contentType) {
      requestConfig.headers['Content-Type'] = contentType;
    }

    return this.httpClient.post(`${url ? '/' : ''}${url}`, data, requestConfig).catch((error: AxiosError) => {
      isAdvancedMode && console.error('[BaseRequestService] post()', error);
      throw error;
    });
  }

  async put<TData = any, TResponse = any>(
    url = '',
    data?: TData,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    requestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Clear-Site-Data': '*',
        Authorization: `Bearer ${await this.token()}`,
        'XSRF-TOKEN': this.xsrfToken,
      },
    };
    return this.httpClient.put(`${url ? '/' : ''}${url}`, data, requestConfig).catch((error: AxiosError) => {
      isAdvancedMode && console.error('[BaseRequestService] put()', error);
      throw error;
    });
  }

  async delete<TParams = any, TResponse = any>(
    url = '',
    params?: TParams,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    requestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Clear-Site-Data': '*',
        Authorization: `Bearer ${await this.token()}`,
      },
    };
    return this.httpClient
      .delete(`${url ? '/' : ''}${url}`, {
        params,
        ...requestConfig,
      })
      .catch((error: AxiosError) => {
        isAdvancedMode && console.error('[BaseRequestService] delete()', error);
        throw error;
      });
  }
}

export default BaseRequestService;
