/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MessageBarType } from '@fluentui/react';
import { Button, Card, Collapse } from 'antd';

import { ErrorMessages, Labels, Messages } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { FailGroupIds, SuccessGroupIds } from '@/constants/SystemConstants';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { ErrorModal } from './ErrorModal';
import { RulesTable } from './RulesTable';

const { Panel } = Collapse;

export const RulesPage = (props: any) => {
  type LocationState = {
    prevPageDetails: any;
  };

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { addGlobalMessage } = systemMessageStore;

  const [workflowData, setWorkflowData] = React.useState<any[]>();
  const [rulesSelected, setRulesSelected] = React.useState<Object>();
  const [errorData, setErrorData] = React.useState<any>();
  const [showError, setShowError] = React.useState(false);
  const [modelContentLoading, setModelContentLoading] = React.useState<boolean>(false);
  const [resetSelection, setResetSelection] = React.useState<boolean>(false);

  const location = useLocation<LocationState>();

  const dataToAddRule = {
    prevPageDetails: location.state?.prevPageDetails,
    resAnalyzerUrl: location.pathname,
  };

  React.useEffect(() => {
    getRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyRules = () => {
    if (rulesSelected) {
      const selectedRules = [];

      for (const workflow in rulesSelected) {
        if (Object.prototype.hasOwnProperty.call(rulesSelected, workflow)) {
          const rules = rulesSelected[workflow as string];

          selectedRules.push(...rules);
        }
      }

      logManagementRequestService.applyRules(props.id, selectedRules, props.companyName).then(
        (result) => {
          setRulesSelected({});
          setResetSelection(true);
          props.refreshExpInsights();

          const successMessage: SystemMessageType = {
            message: Messages.applySelectedRules,
            type: MessageBarType.success,
            groupId: SuccessGroupIds.RULE_PAGE,
          };

          addGlobalMessage(successMessage);
        },
        (error) => {
          const failMessage: SystemMessageType = {
            message: Messages.failedToApplyRules,
            type: MessageBarType.error,
            groupId: FailGroupIds.RULE_PAGE,
          };

          addGlobalMessage(failMessage);
          handleError(error);
        },
      );
    } else {
      const errorMessage = ErrorMessages.selectOneRule;

      handleError(null, errorMessage);
    }

    setResetSelection(false);
  };

  const getRules = () => {
    setModelContentLoading(true);
    logManagementRequestService.getRulesData(props.companyName).then(
      (result) => {
        const formattedData = [];

        result.forEach((rule) => {
          const subCategory = rule.SubCategory;
          const ruleObj = {
            Id: rule.Id,
            Name: rule.Name,
            Version: rule.Version,
            TimeStamp: rule.TimeStamp,
            Description: rule.Description,
            Owner: rule.Owner,
            Author: rule.Author,
            Company: rule.Company,
            Tags: rule.Tags,
            FilesToSearch: rule.FilesToSearch,
            IgnoreCase: rule.IgnoreCase,
            Expressions: rule?.Expressions,
            ErrorMessage: rule.ErrorMessage,
            SuccessMessage: rule.SuccessMessage,
            Extensions: rule.Extensions,
          };

          const existingSubCategory = formattedData.find((item) => item.subCategory === subCategory);

          if (existingSubCategory) {
            existingSubCategory.rules.push(ruleObj);
          } else {
            formattedData.push({
              subCategory,
              rules: [ruleObj],
            });
          }
        });
        setWorkflowData(formattedData);
        setModelContentLoading(false);
      },
      (error) => {
        handleError(error);
      },
    );
  };

  const handleError = (error, ErrorMessage = ErrorMessages.systemError) => {
    if (error && error.response.data !== undefined) {
      setErrorData(error.response.data);
    } else {
      setErrorData({ ErrorMessage });
    }

    setShowError(true);
    setModelContentLoading(false);
  };

  const handleBack = () => {
    setRulesSelected([]);
  };

  return (
    <div className="result-analyzer">
      <Card loading={modelContentLoading} bordered={false}>
        {workflowData && (
          <div>
            <div className="applicable-rules">{Labels.applicableRules}</div>
            <Button key="applyRule" type="primary" className="actionButton" onClick={() => applyRules()}>
              {Labels.applyRules}
            </Button>
            <Link
              to={{
                pathname: `${Navigation.LABS.ADD_RULE}/companyName=${props.companyName}`,
                state: { resAnalyzerData: dataToAddRule },
              }}
              className="clickable"
            >
              <Button key="add" type="primary" className="actionButton">
                {Labels.addRules}
              </Button>
            </Link>
            <Collapse defaultActiveKey={[0]}>
              {workflowData.map((item, index) => (
                <Panel key={index} header={item.subCategory}>
                  <RulesTable rules={item.rules} workflow={item.subCategory} setRules={setRulesSelected} reset={resetSelection} />
                </Panel>
              ))}
            </Collapse>
          </div>
        )}
      </Card>
      {showError && <ErrorModal data={errorData} isModalVisible={showError} showModal={setShowError} customClose={handleBack} />}
    </div>
  );
};
