import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import LabListViewController from '@/components/LabList/LabListViewController';
import LabListViewModel from '@/components/LabList/LabListViewModel';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

const LabsHomeFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;

  const { globalMessages, clearNonPersistentGlobalMessages } = systemMessageStore;
  const labsViewModel = React.useMemo(() => new LabListViewModel(rootStore), [rootStore]);

  const title = t(Labs.LABS, { ns: NS.LABS });
  const subTitle = t(Labs.ALL_LABS, { ns: NS.LABS });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const systemMessage: SystemMessageType[] = globalMessages?.filter((message: SystemMessageType) => !message.showInPopup);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{systemMessage}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={false}>
            <div className="fullscreen">
              <LabListViewController viewModel={labsViewModel}></LabListViewController>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const LabsHome = observer(LabsHomeFC);

export default LabsHome;
