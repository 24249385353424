import { singleton } from 'tsyringe';

@singleton()
export default class LocalStorageService {
  protected values: Map<string, any> = new Map();
  protected localStorage: Storage;

  constructor() {
    if (!window.localStorage) {
      throw new Error('Local storage not supported');
    }

    this.localStorage = window.localStorage;
  }

  // Setting the value also returns the value that was saved.
  public setValue = (key: string, value: any): any => {
    this.localStorage.setItem(key, JSON.stringify(value));
    this.values.set(key, value);

    return this.values.get(key);
  };

  public getValue = (key: string): any | null => {
    if (this.values.has(key)) {
      return this.values.get(key);
    }

    const value: string | null = this.localStorage.getItem(key);

    if (value !== null) {
      try {
        const parsedValue = JSON.parse(value);
        this.values.set(key, parsedValue);

        return parsedValue;
      } catch (e) {
        return null;
      }
    }

    return null;
  };

  public getValueAsNumber = (key: string): number => {
    const value: string = this.getValue(key) || '';

    if (typeof value === 'number') {
      return value;
    }

    const parsedValue = parseFloat(value);

    if (!Number.isNaN(parsedValue)) {
      return parsedValue;
    }

    return 0;
  };

  public getValueAsBoolean = (key: string): boolean => {
    const value: string = this.getValue(key) || '';

    return value.toString().toLowerCase() === 'true';
  };
}
