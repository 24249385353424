import React from 'react';

import { ThemeSchema, UserSettings } from '@/types/_air';

type SettingsState = {
  user: string;
  userSettings: UserSettings;
};

interface SettingsActions {
  setUser: ((_user: string) => void) | null;
  updateUserSettings: ((_updatedUserSettings: UserSettings) => Promise<UserSettings>) | null;
}

interface SettingsContext {
  state: SettingsState;
  actions: SettingsActions;
}

export const UserContext = React.createContext<SettingsContext>({
  state: {
    user: '',
    userSettings: {
      theme: ThemeSchema.Light,
      shouldDisplayNotificationHub: false,
      shouldDisplayManageExperimentsPage: false,
    },
  },
  actions: {
    setUser: null,
    updateUserSettings: null,
  },
});
