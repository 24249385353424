import React from 'react';
import Chart from 'react-apexcharts';

import { Labels } from '@/constants/AirConstants';
import { ChartType } from '@/constants/SystemConstants';
import { ExecutionGoalStatus, Goal } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  status: ExecutionGoalStatus | Goal;
}

export const ExperimentStatusDonutChart = ({ status }: Props): JSX.Element => {
  const [series, setSeries] = React.useState([
    status.successPercentage,
    status.inProgressPercentage,
    status.failedPercentage,
    status.notStartedPercentage,
  ]);
  const [options] = React.useState<any>({
    chart: {
      type: ChartType.DONUT,
    },
    dataLabels: {
      enabled: false,
    },
    labels: [Labels.success, Labels.inProgress, Labels.failed, Labels.notStarted],
    colors: ['var(--graphSuccessColor)', 'var(--graphInProgressColor)', 'var(--graphFailedColor)', 'var(--graphNotStartedColor)'],
    legend: {
      fontSize: '14',
      offsetY: -15,
      offsetX: 5,
      labels: {
        colors: 'var(--textColor)',
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: '20%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: '22',
              color: ['var(--textColor)'],
              formatter(val) {
                return `${val}%`;
              },
            },
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.7,
        },
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.7,
        },
      },
    },
  });

  React.useEffect(() => {
    setSeries([status.successPercentage, status.inProgressPercentage, status.failedPercentage, status.notStartedPercentage]);
  }, [status.inProgressPercentage, status.notStartedPercentage, status.failedPercentage, status.successPercentage]);

  return (
    <div className="col-xl-6">
      <Chart options={options} series={series} type="donut" height={400} width={500} />
    </div>
  );
};
