import React from 'react';
import { Scatter } from 'recharts';

import GraphBaseViewController from '@/components/Graphs/GraphBaseViewController';

import { MultiLineScatterTemplateType } from './MultiLineScatterType';

const MultiLineScatterGraphTemplate: React.FC<MultiLineScatterTemplateType> = (
  props: MultiLineScatterTemplateType,
): React.ReactElement => {
  const { renderMultiLines } = props;
  const { xAxisProp, yAxisProp, scatterProp, responsiveContainerProp, cartesianGridProp, tooltipProps } = props.chartProp;

  return (
    <GraphBaseViewController
      data={props.data}
      chartProp={{
        responsiveContainerProp,
        cartesianGridProp,
        xAxisProp,
        yAxisProp,
        tooltipProps,
      }}
    >
      {renderMultiLines()}
      {scatterProp && <Scatter {...scatterProp} />}
    </GraphBaseViewController>
  );
};

export default MultiLineScatterGraphTemplate;
