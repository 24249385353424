import React from 'react';
import { observer } from 'mobx-react-lite';

import { SessionPanelVMType } from '@/components/SessionPanel/SessionPanelTypes';
import SessionPanelViewController from '@/components/SessionPanel/SessionPanelViewController';
import SessionPanelViewModel from '@/components/SessionPanel/SessionPanelViewModel';

const SessionPanelFC: React.FC<SessionPanelVMType> = ({ loadMessage }) => {
  const viewModel = new SessionPanelViewModel(loadMessage);

  return <SessionPanelViewController viewModel={viewModel}></SessionPanelViewController>;
};

const SessionPanel = observer(SessionPanelFC);

export default SessionPanel;
