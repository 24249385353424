import React from 'react';
import { observer } from 'mobx-react-lite';

import ExperimentDetails from '@/components/ExperimentDetails/ExperimentDetailsWrapper';
import ExperimentInstanceModal from '@/components/ExperimentInstanceModal/ExperimentInstanceModalWrapper';
import ExperimentPropertiesPanel from '@/components/ExperimentPropertiesPanel/ExperimentPropertiesPanelWrapper';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { ExperimentVCType } from './ExperimentsTypes';

import styles from './Experiments.module.css';

const ExperimentsTemplateFC: React.FC<ExperimentVCType> = (props: ExperimentVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { paginationStore } = rootStore;
  const { paginationType } = paginationStore;

  const {
    title,
    labId,
    instanceId,
    config,
    tableItems,
    tableGroups,
    groupByColumn,
    groupByColumns,
    commandBarItems,
    overflowItems,
    filterItems,
    farItems,
    panelLoadErrorMessage,
    selection,
    selectionMode,
    selectionPreservedOnEmptyClick,
    checkboxVisibility,
    isColumnEditorOpen,
    hideColumnEditor,
    columnsList,
    setColumnsList,
    entireColumns,
    columnEditorKey,
    isLoading,
    pageName,
  } = props;

  const panelMode = !instanceId;
  const detailsMode = !!instanceId;

  return (
    <>
      {panelMode && (
        <>
          <PageCommandBar items={commandBarItems} overflowItems={overflowItems} farItems={farItems}></PageCommandBar>
          <PageDivider />
          <PageFilterBar items={filterItems}></PageFilterBar>
          <TableViewViewController
            items={tableItems}
            groups={tableGroups}
            groupByColumn={groupByColumn}
            groupByColumns={groupByColumns}
            columns={columnsList}
            displayColumns={config.experimentsDisplayFields}
            enableToolBar={false}
            enableColumnSort={true}
            tableStyle={`${styles['experiments-table']} ${styles['full-table']}`}
            selection={selection}
            selectionMode={selectionMode}
            selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
            checkboxVisibility={checkboxVisibility}
            isCompact={true}
            isStickyHeader={true}
            isLoading={isLoading}
            paginationType={paginationType}
            pageName={pageName}
          />
          <ColumnEditorPanelTemplate
            isColumnEditorOpen={isColumnEditorOpen}
            hideColumnEditor={hideColumnEditor}
            columnsList={columnsList}
            setColumnsList={setColumnsList}
            entireColumns={entireColumns}
            columnEditorKey={columnEditorKey}
          ></ColumnEditorPanelTemplate>
          <ExperimentPropertiesPanel loadMessage={panelLoadErrorMessage}></ExperimentPropertiesPanel>
          <ExperimentInstanceModal
            title={title}
            labId={labId}
            instanceId={instanceId}
            loadMessage={panelLoadErrorMessage}
          ></ExperimentInstanceModal>
        </>
      )}

      {detailsMode && (
        <ExperimentDetails
          title={title}
          labId={labId}
          instanceId={instanceId}
          loadMessage={panelLoadErrorMessage}
        ></ExperimentDetails>
      )}
    </>
  );
};

const ExperimentsTemplate = observer(ExperimentsTemplateFC);

export default ExperimentsTemplate;
