import { TagPickerSize } from '@fluentui/react-tag-picker';

export const TagPickerConstants = {
  AVATAR_COLOR: 'colorful',
  AVATAR_SHAPE_ROUNDED: 'rounded',
  AVATAR_SHAPE_SQUARE: 'square',
  BACKSPACE_TEXT: 'Backspace',
  DEFAULT_SIZE: 'extra-large' as TagPickerSize, //"medium" "large" "extra-large"
  DEFAULT_WIDTH: '500px',
  ICON_NAME: 'Cancel',
  NO_MATCHES_VALUE: 'no-matches',
  SIZE_MEDIUM: 'medium' as TagPickerSize,
  SIZE_LARGE: 'large' as TagPickerSize,
};
