/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { searchDocumentationTable } from './CharactersTable';

import '@/styles/Labs.css';

export const searchDocumentation = () => (
  <div>
    <h4> What can be searched?</h4>
    The current version of search engine supports search for a combination of one or more result log properties below:
    <ul>
      <li>File content</li>
      <li>File name</li>
      <li>File extension</li>
      <li>Last modified</li>
      <li>File Size</li>
    </ul>
    For instance:
    <code>{'<Search Term> + <File Name> '}</code>
    will show results for all files that contain the search term with name file name.
    <h4>Term Search</h4>A term search is a query of one or more terms, where any of the terms are considered a match.
    <h4>Phrase Search</h4>A phrase search is an exact phrase enclosed in quotation marks " ". For example, while{' '}
    <code> FPGA PCIe Link Training Error </code> (without quotes) would search for documents containing FPGA and/or PCIe and/or Link
    etc. anywhere in any order, <code>"FPGA PCIe Link Training Error"</code> (with quotes) will only match documents that contain
    that whole phrase together and in that order.
    <h4>Advanced Search Operators</h4>
    {searchDocumentationTable()}
  </div>
);
