import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Fabric, IconButton, Modal, TextField } from '@fluentui/react';
import { MessageBarType } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles, wrapperClassName } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels, Messages } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';

const UserTypedComponentFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [label, setLabel] = React.useState<string>('');

  const deleteLabTitleId = useId('deleteLabTitle');
  const [titleId, setTitleId] = React.useState<string>('');
  const [buttonText, setButtonText] = React.useState<string>('');

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const failGroupId = 'lab-delete-fail';
  const history = useHistory();

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (props.action === Labels.delete) {
      setTitleId(deleteLabTitleId);
      setLabel(Messages.typeExactLabNameToDelete);
      setButtonText(Buttons.deleteLab);
    }
  }, [deleteLabTitleId, props]);

  const deleteLab = async (evt: any) => {
    evt.preventDefault();

    if (props.labName !== props.userTypedName) {
      const failMessage: SystemMessageType = {
        message: Messages.labsControlLabNameNotMatch,
        type: MessageBarType.error,
        groupId: failGroupId,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);
      return;
    }

    await ganymedeLabRequestService
      .deleteLab(props.labId)
      .then((response: any) => {
        if (response) {
          history.push({
            pathname: Navigation.LABS.HOME,
          });
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.container}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
          <Fabric className={wrapperClassName}>
            <h5>{label}</h5>
            <br />
          </Fabric>
          <br />
          <div className="row text-center">
            <div className="col-sm-1" />
            <div className="col-sm-10">
              <TextField required type="text" title="text" onChange={(e) => props.setUserTypedName((e.target as any).value)} />
            </div>
          </div>
          <div className="col-sm-1" />
          <br />
          <div className="text-center">
            <DefaultButton className="bg-danger light-text" onClick={(e) => deleteLab(e)}>
              {buttonText}
            </DefaultButton>
            &nbsp;&nbsp;&nbsp;
            <DefaultButton className="" onClick={props.hideModal}>
              {Buttons.cancel}
            </DefaultButton>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};

export const UserTypedComponent = observer(UserTypedComponentFC);
