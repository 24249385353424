import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { PrimaryButton } from '@fluentui/react';

import { ExperimentStatusTableHeaders, Labels, Routes } from '@/constants/AirConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ExecutionGoalStatus } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  status: ExecutionGoalStatus;
  team: string;
  executionId: string;
  experimentName: string;
}

export const ExperimentStatusTable = ({ status, team, executionId, experimentName }: Props): JSX.Element => {
  const convertDate = (date) => {
    const newDate = new Date(date);
    const result = `${newDate.getFullYear()}-${`0${newDate.getMonth() + 1}`.slice(-2)}-${`0${newDate.getDate()}`.slice(-2)}`;

    return result;
  };

  const history = useHistory();

  const renderWorkloads = () =>
    Object.keys(status.workloads).map((workload, ind) => (
      <tr
        key={workload}
        onClick={() =>
          history.push(`${Navigation.AIR.TARGET_GOAL_STATUS}/${team}/${executionId}/${experimentName}/${workload}`, {
            targetGoal: status.workloads[workload as string],
          })
        }
      >
        <td key={`${workload}+${ind}`} className="gbs-status-table-workload">
          {workload}
        </td>
        <td>{status.workloads[workload as string].count}</td>
        <td>{status.workloads[workload as string].completed}</td>
        <td>{status.workloads[workload as string].success}</td>
        <td>{status.workloads[workload as string].failed}</td>
        <td>{status.workloads[workload as string].progress}</td>
        <td>{status.workloads[workload as string].successParcentage}</td>
        <td>
          {status.workloads[workload as string].eta
            ? convertDate(status.workloads[workload as string].eta)
            : status.workloads[workload as string].eta}
        </td>
        <td className="status-table-field" style={{ textOverflow: 'unset' }}>
          <Link
            to={{
              pathname: `${Navigation.AIR.TARGET_GOAL_STATUS}/${team}/${executionId}/${experimentName}/${workload}`,
              state: {
                targetGoal: status.workloads[workload as string],
              },
            }}
            onClick={(e) => (!(status.workloads[workload as string].completed > 0) ? e.preventDefault() : null)}
          >
            <PrimaryButton className="status-table-button">{Labels.status}</PrimaryButton>
          </Link>
        </td>
      </tr>
    ));

  const renderHeaders = () =>
    ExperimentStatusTableHeaders.map((header) => (
      <th key={header} className="gbs-status-table-label">
        {header}
      </th>
    ));

  return (
    <Table hover striped size="sm" className="gbs-status-table">
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      {status.workloads && <tbody>{renderWorkloads()}</tbody>}
    </Table>
  );
};
