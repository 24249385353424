import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';

const getMachineDetails = (labId: string, selectedMachines: any) => {
  const machines = selectedMachines;

  const agentIds: string[] = machines?.map((machine: any) => {
    const macAddress = machine.MacAddress || machine.macAddress;
    const slotNumber = machine.SlotNumber || machine.slot;
    const ipAddress = machine.IPAddress || machine.ipAddress;

    return `${labId},${macAddress},${slotNumber},${ipAddress}`;
  });

  return agentIds;
};

const getSARMIPAddress = async (labId: string, machines: string[]): Promise<string> => {
  let responseValue = null;

  try {
    const response: any = await ganymedeLabRequestService.getSARMIPAddress(labId, machines);

    if (response) {
      responseValue = response;
    }
  } catch (error) {
    console.error('Error while getting SARM IP :', error);
  }

  return responseValue;
};

export { getMachineDetails, getSARMIPAddress };
