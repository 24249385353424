/*----------------------------------------------------------------------------------------------------------------------------
# index.tsx: 
# This is the root (index) page that is accessed/loaded by the browser.
#----------------------------------------------------------------------------------------------------------------------------*/
import React from 'react';
import 'reflect-metadata'; // Required for application Dependency Injection.
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { container } from 'tsyringe';

import { NotificationsPool as AirNotificationsPool } from '@/components/_air/notifications/NotificationPool';
import { NotificationsPool as LabsNotificationsPool } from '@/components/_labs/Notifications/NotificationPool';
import { i18n } from '@/lang/i18n';
import ThemeTemplate from '@/layouts/Themes/ThemeTemplate';
import { RootStore } from '@/stores/RootStore';

import App from './App';
import { msalConfig } from './authConfig';

import 'bootstrap/dist/css/bootstrap.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

export const msalInstance = new PublicClientApplication(msalConfig);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
const rootElement = document.getElementById('root');

const rootStore: RootStore = container.resolve(RootStore);
const { appSettingsStore } = rootStore;
const { isDebugMode } = appSettingsStore;

isDebugMode && console.log('[Index] index loading');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <I18nextProvider i18n={i18n}>
      <MsalProvider instance={msalInstance}>
        <ThemeTemplate />
        <App />
        {isDebugMode && console.log('[Index] LabsNotificationsPool loading')}
        <LabsNotificationsPool />
        {isDebugMode && console.log('[Index] AirNotificationsPool loading')}
        <AirNotificationsPool />
      </MsalProvider>
    </I18nextProvider>
  </BrowserRouter>,
  rootElement,
);
