import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import {
  ColumnEditorKeys,
  FilterOptions,
  KeyTextPair,
  Labels,
  MachineTypes,
  SortedColumnPageKeys,
} from '@/constants/SystemConstants';
import EditColumnsStore from '@/partials/ColumnEditor/ColumnEditorStore';
import { paginateData } from '@/partials/Pagination/Pagination';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import TableViewStore from '@/partials/TableView/TableViewStore';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabDetailsRequestService } from '@/services/request-services/LabDetailsRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { formatDate } from '@/utils/Dates';
import { createGroupByNoneItem, setTableDataGroupBy } from '@/utils/GroupBy';
import { displayTag, getMachineIconType, populateHealthStatus } from '@/utils/Helpers';
import { processColumns } from '@/utils/Tables';

import config from './LabSystems.config.json';
import LabSystemStore from './LabSystemsStore';
import { HeartbeatType, LabSystemType } from './LabSystemsType';

class LabSystemsViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected editColumnsStore: EditColumnsStore;
  protected paginationStore: PaginationStore;
  protected systemMessageStore: SystemMessageStore;
  protected tableViewStore: TableViewStore;
  protected userSettingsStore: UserSettingsStore;

  protected labDetailsStore: LabDetailsStore;
  protected labSystemsStore: LabSystemStore;

  constructor(rootStore: RootStore) {
    const {
      appSettingsStore,
      editColumnsStore,
      paginationStore,
      systemMessageStore,
      tableViewStore,
      userSettingsStore,
      labDetailsStore,
      labSystemsStore,
    } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.editColumnsStore = editColumnsStore;
    this.paginationStore = paginationStore;
    this.tableViewStore = tableViewStore;
    this.systemMessageStore = systemMessageStore;
    this.userSettingsStore = userSettingsStore;
    this.labDetailsStore = labDetailsStore;
    this.labSystemsStore = labSystemsStore;
  }

  fetchColumnDetails = async (): Promise<void> => {
    const { getEditorColumns } = this.editColumnsStore;
    const { setLabSystemsEntireColumns, setLabSystemsColumnList } = this.labSystemsStore;

    const columnEditorKey = ColumnEditorKeys.LAB_SYSTEMS;
    const columnDefinitions: any = await this.getColumnDefinitions();
    const storedColumns = getEditorColumns(columnEditorKey);

    if (columnDefinitions) {
      const { userColumns, allColumns } = processColumns(storedColumns, columnDefinitions);

      setLabSystemsColumnList(userColumns);
      setLabSystemsEntireColumns(allColumns);
    }
  };

  fetchLabSystems = async (): Promise<void> => {
    const { selectedLab } = this.labDetailsStore;
    const { setLabSystems, setIsLoading } = this.labSystemsStore;

    setIsLoading(true);

    try {
      const results: any = await ganymedeLabDetailsRequestService.getSystemDetails(selectedLab.LabId);

      if (results?.length > 0) {
        const mappedData: LabSystemType[] = await this.buildDataMapping(results, selectedLab.LabId);

        setLabSystems(mappedData);
      } else {
        setLabSystems([]);
      }
    } catch (error) {
      console.error('Error while fetching systems :', error);

      setLabSystems([]);
    } finally {
      setIsLoading(false);
    }
  };

  protected getSystemsHeartbeat = async (): Promise<HeartbeatType[] | null> => {
    const { agentIds } = this.labSystemsStore;

    await ganymedeLabDetailsRequestService
      .getSystemsHeartbeat(agentIds)
      .then((result: HeartbeatType[]) => {
        return result;
      })
      .catch((error) => {
        console.error('Error while getting Systems Heartbeat', error);

        return null;
      });

    return null;
  };

  buildDataMapping = async (results: any[], labId: number): Promise<LabSystemType[]> => {
    const { setAgentIds, setMachineTypesList } = this.labSystemsStore;
    const { timeZone } = this.userSettingsStore;

    const machineTypes: KeyTextPair[] = [];
    const agentIds: string[] = [];

    const mappedData: LabSystemType[] = await Promise.all(
      results.map(async (result: any) => {
        const {
          HostSystemId: hostSystemId = '',
          MacAddress: macAddress,
          IPAddress: ipAddress,
          Name: systemName,
          SlotNumber: slotNumber,
          RackSerialNumber: rackSerialNumber,
          MachineId: systemId,
          OsImageName: osImageName,
          Status: state,
        } = result;

        const machineType: string = hostSystemId ? MachineTypes.VIRTUAL_MACHINE : MachineTypes.MACHINE;
        const hostMachineType: KeyTextPair = { key: machineType, text: machineType };
        const displayMachineType: JSX.Element = getMachineIconType(!!hostSystemId);
        const doesMachineTypeExist = machineTypes.some((type: KeyTextPair) => type.key === hostMachineType.key);

        const lastUpdate: string = formatDate(result.LastUpdate, timeZone);
        const hardwareInfo: JSX.Element | string = FilterOptions.NA; // This needs to be changed
        const socInfo: JSX.Element | string = FilterOptions.NA; // This needs to be changed
        const { displayHealthStatus, healthStatus } = populateHealthStatus(Labels.UNKNOWN);
        const displayMachineHealthStatus = displayHealthStatus;
        const machineHealthStatus: string = healthStatus;
        const displayTags = result.Tags.map((tag: any, index: number) => displayTag(tag?.TagName, index));
        const systemAgentId = `${labId},${macAddress},${slotNumber},${ipAddress}`;
        const agentId = systemAgentId?.toLowerCase();

        agentIds.push(agentId);

        if (!doesMachineTypeExist) {
          machineTypes.push(hostMachineType);
        }

        return {
          systemId,
          machineType,
          displayMachineType,
          systemName,
          ipAddress,
          macAddress,
          rackSerialNumber,
          hostSystemId,
          slotNumber,
          agentId,
          osImageName,
          state,
          lastUpdate,
          machineHealthStatus,
          displayMachineHealthStatus,
          displayTags,
          hardwareInfo,
          socInfo,
        };
      }),
    );

    if (machineTypes?.length > 0) {
      setMachineTypesList(machineTypes);
    }

    setAgentIds(agentIds);
    return mappedData;
  };

  getIpAddressList = async (): Promise<void> => {
    const { selectedLab } = this.labDetailsStore;
    const { setIpAddressesList } = this.labSystemsStore;

    try {
      const ipAddresses: string[] = await ganymedeExperimentRequestService.getIPAddress(selectedLab.LabId);
      const ipAddressKeyTextPair: KeyTextPair[] = ipAddresses.map((ip: string) => ({ key: ip, text: ip }));

      setIpAddressesList(ipAddressKeyTextPair);
    } catch (error) {
      console.error('Error fetching IP Address:', error);

      setIpAddressesList([]);
    }
  };

  getFilteredResult = (): LabSystemType[] => {
    const { ipAddressValues, machineTypeValues, healthStatusValues, labSystems } = this.labSystemsStore;

    const applyFilter = (
      data: LabSystemType[],
      filterValues: string[],
      fieldExtractor: (item: LabSystemType) => string,
    ): LabSystemType[] => {
      if (filterValues.length) {
        return data.filter((item) => filterValues.includes(fieldExtractor(item) || ''));
      }

      return data;
    };

    let filteredResult: LabSystemType[] = labSystems;

    filteredResult = applyFilter(filteredResult, ipAddressValues, (e: LabSystemType) => e.ipAddress);
    filteredResult = applyFilter(filteredResult, machineTypeValues, (e: LabSystemType) => e.machineType);
    filteredResult = applyFilter(filteredResult, healthStatusValues, (e: LabSystemType) => e.machineHealthStatus);

    return filteredResult;
  };

  searchData = (currentPage?: number): void => {
    const { ipAddressValues, machineTypeValues, healthStatusValues, labSystems } = this.labSystemsStore;
    const { paginationType, setPaginationType } = this.paginationStore;

    if (labSystems?.length > 0) {
      const hasFiltersApplied = ipAddressValues?.length > 0 || machineTypeValues?.length > 0 || healthStatusValues?.length > 0;
      const filteredResult: LabSystemType[] = this.getFilteredResult();

      const { paginatedItems, updatedPaginationType } = paginateData(
        filteredResult,
        paginationType,
        hasFiltersApplied,
        currentPage,
      );

      setPaginationType(updatedPaginationType);

      this.setGroupByData(paginatedItems);
    } else {
      this.setGroupByData([]);
    }
  };

  protected getHealthStatus = async (systemHeartbeats?: HeartbeatType[]): Promise<any[]> => {
    const displayStatusField = 'display-machine-health-status';
    const statusField = 'machine-health-status';

    const columnDefinitions = config?.labSystemsColumnDefinitions?.map((column: any) => {
      if (systemHeartbeats && (column.key === displayStatusField || column.key === statusField)) {
        return {
          ...column,
          onRender: (labSystem: LabSystemType) => {
            const matchingSystemHeartbeat: HeartbeatType = systemHeartbeats?.find(
              (heartbeat: HeartbeatType) => heartbeat.agentId?.toLowerCase() === labSystem.agentId,
            );

            const lastModified = matchingSystemHeartbeat?.lastModified || null;
            const { displayHealthStatus, healthStatus } = populateHealthStatus(lastModified);

            if (column.key === displayStatusField) {
              return displayHealthStatus;
            } else if (column.key === statusField) {
              return healthStatus;
            }
          },
        };
      }

      return column;
    });

    return columnDefinitions;
  };

  getColumnDefinitions = async (): Promise<any> => {
    const { agentIds } = this.labSystemsStore;

    let columnDefinitions: any;

    if (agentIds?.length > 0) {
      await ganymedeLabDetailsRequestService
        .getSystemsHeartbeat(agentIds)
        .then((systemHeartbeats: HeartbeatType[]) => {
          columnDefinitions = this.getHealthStatus(systemHeartbeats);
        })
        .catch((error) => {
          console.error('Error while getting Systems Heartbeat', error);

          return null;
        });
    } else {
      columnDefinitions = this.getHealthStatus();
    }

    return columnDefinitions;
  };

  handleClearAllFilters = (): void => {
    const { doReset } = this.labSystemsStore;
    const { doResetTableSort } = this.tableViewStore;

    const pageName = SortedColumnPageKeys.LAB_DETAILS_SYSTEMS_PAGE;

    doResetTableSort(pageName);
    doReset();
  };

  handleGroupBySelected = (groupByColumnKey: string): void => {
    const { labSystems, setGroupByValue } = this.labSystemsStore;
    const groupedSystems: LabSystemType[] = labSystems;

    setGroupByValue(groupByColumnKey);
    this.setGroupByData(groupedSystems, groupByColumnKey);
  };

  groupByNoneItem: ICommandBarItemProps = createGroupByNoneItem(this.handleGroupBySelected);

  protected setGroupByData = (systemDetails: any[], groupByColumnKey?: string): void => {
    const { groupByValue, setFilteredLabSystems, setGroupByColumn, setTableGroups } = this.labSystemsStore;

    groupByColumnKey = groupByColumnKey ?? groupByValue;
    const { returnData, groups } = setTableDataGroupBy(systemDetails, groupByColumnKey, config.labSystemsColumnDefinitions);

    setFilteredLabSystems(returnData);
    setTableGroups(groups);
    setGroupByColumn(groupByColumnKey);
  };
}

export default LabSystemsViewModel;
