import React from 'react';
import { DefaultButton, Fabric, IconButton, Modal } from '@fluentui/react';
import { MessageBarType } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles, wrapperClassName } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels, Messages } from '@/constants/LabsConstants';
import { SuccessGroupIds } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';

export const ConfirmationComponent = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const confirmationTitleId = useId('confirmationTitleId');

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const successGroupId = SuccessGroupIds.LAB_CONTROL;
  const [confirmMessage, setConfirmMessage] = React.useState<string>('');
  const [buttonText, setButtonText] = React.useState<string>('');

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (props.action === Labels.update) {
      setConfirmMessage(Messages.labsUpdateConfirmation);
      setButtonText(Buttons.updateLab);
    } else if (props.action === Labels.deleteTestPlan) {
      setConfirmMessage(Messages.deleteTestPlanConfirmation);
      setButtonText(Buttons.deleteTestPlan);
    } else if (props.action === Labels.deleteTestPlanGroup) {
      setConfirmMessage(Messages.deleteTestPlanGroupConfirmation);
      setButtonText(Buttons.deleteTestPlanGroup);
    } else if (props.action === Labels.deleteLabsUser) {
      setConfirmMessage(Messages.deleteUserConfirmation);
      setButtonText(Buttons.RemoveUser);
    } else if (props.action === Labels.deleteTestPlanUser) {
      setConfirmMessage(Messages.deleteTestPlanUserConfirmation);
      setButtonText(Buttons.RemoveUser);
    } else if (props.action === Labels.deleteTestPlanGroupUser) {
      setConfirmMessage(Messages.deleteTestPlanUserConfirmation);
      setButtonText(Buttons.RemoveUser);
    } else if (props.action === Labels.deleteNugetFeed) {
      setConfirmMessage(Messages.deleteNugetConfirmation);
      setButtonText(Buttons.deleteFeed);
    } else if (props.action === Labels.deleteNugetPkg) {
      setConfirmMessage(Messages.deleteNugetPkgConfirmation);
      setButtonText(Buttons.deletePkg);
    } else if (props.action === Labels.deleteAddedRackManager) {
      setConfirmMessage(Messages.deleteRackManagerConfirmation);
      setButtonText(Buttons.delete);
    } else if (props.action === Labels.createExperimentSteps) {
      setConfirmMessage(Messages.createExperimentStepConfirmation);
      setButtonText(Buttons.yes);
    }
  }, [props]);

  const upgradeLab = async (evt: any) => {
    evt.preventDefault();
    await labsRequestService
      .upgradeLab(props.labId)
      .then(() => {
        const successMessage: SystemMessageType = {
          message: Messages.labsControlLabUpdateSuccess,
          type: MessageBarType.success,
          groupId: successGroupId,
        };

        addGlobalMessage(successMessage);
        props.hideConfirmModal(false);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const handleDeleteLabsUser = async (evt: any) => {
    evt.preventDefault();
    await ganymedeLabRequestService
      .deleteLabUser(props.selectedItemKey, props.userName)
      .then(() => {
        const successMessage: SystemMessageType = {
          message: Messages.successfullyDeleteUser,
          type: MessageBarType.success,
          groupId: successGroupId,
          showInPopup: true,
        };

        addGlobalMessage(successMessage);
        props.fetchUsers();
        props.hideConfirmModal();
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const handleDeleteAddedRackManager = async (evt: any) => {
    evt.preventDefault();
    await labsRequestService
      .deleteRackManager(props.labId, props.selectedItemKey, props.ipAddress, props.loginId)
      .then(() => {
        const successMessage: SystemMessageType = {
          message: Messages.deleteRackManagerSuccessfully,
          type: MessageBarType.success,
          groupId: successGroupId,
          showInPopup: true,
        };

        addGlobalMessage(successMessage);
        props.fetchLists();
        props.hideConfirmModal();
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const handleCreateExperiment = () => {
    props.hideConfirmModal();
    props.showCreateExperimentModal();
  };

  return props.isConfirmModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={confirmationTitleId}
        isOpen={props.isConfirmModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.container}
        onDismiss={props.hideConfirmModal}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideConfirmModal}
          />
        </div>
        <div className={contentStyles.body}>
          <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
          <Fabric className={wrapperClassName}>
            <h5>{confirmMessage}</h5>
          </Fabric>
          <br />
          <div className="text-center">
            <DefaultButton
              className="bg-danger light-text"
              onClick={(e: any) => {
                if (props.action === Labels.update) {
                  upgradeLab(e);
                } else if (props.action === Labels.deleteLabsUser) {
                  handleDeleteLabsUser(e);
                } else if (props.action === Labels.deleteAddedRackManager) {
                  handleDeleteAddedRackManager(e);
                } else if (props.action === Labels.createExperimentSteps) {
                  handleCreateExperiment();
                }
              }}
            >
              {buttonText}
            </DefaultButton>
            &nbsp;&nbsp;&nbsp;
            <DefaultButton onClick={props.hideConfirmModal}>{Buttons.cancel}</DefaultButton>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};
