import React from 'react';
import { observer } from 'mobx-react-lite';
import { DetailsList, MessageBarType as FluentMessageBarType, PrimaryButton, SearchBox, TextField } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Delimiters, FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageDivider from '@/partials/PageDivider/PageDivider';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { ganymedeFirmwareRequestService } from '@/services/request-services/FirmwareRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { UploadPackages } from './UploadPackages';
import config from './ViewPackageSettings.config.json';

import '@/styles/Labs.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

const ViewPackagesFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [programName, setProgramName] = React.useState<string>('');
  const [fileData, setFileData] = React.useState<any>([]);
  const [fileGroups, setFileGroups] = React.useState<any>([]);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  const title = t('asset-management', { ns: NS.TITLES });
  const failGroupId = FailGroupIds.VIEW_PACKAGE;
  const dataColumns = config.dataColumns;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const processPackageData = (data: any) => {
    const splitPath = data.FileName.split(Delimiters.FORWARD_SLASH);

    if (splitPath.length === 4) {
      return {
        Program: splitPath[2],
        Filename: splitPath[3],
        LastModified: new Date(data.LastModified).toLocaleString(),
      };
    } else {
      return {
        Program: '',
        Filename: data.FileName,
        LastModified: new Date(data.LastModified).toLocaleString(),
      };
    }
  };

  const generateTable = (rawData: any) => {
    const filterString = Delimiters.FORWARD_SLASH + programName.trim() + Delimiters.FORWARD_SLASH;
    const filteredData = rawData.filter((entry: any) => entry.FileName.includes(filterString));
    const convertedData = filteredData.map((pathInfo: any) => processPackageData(pathInfo));

    return convertedData;
  };

  const createGroup = (name: string, startIndex: number, count: number) => {
    const group = {
      name: name,
      key: name,
      startIndex: startIndex,
      count: count,
      level: 0,
    };

    return group;
  };

  const generateGroups = (data: any) => {
    if (data.length <= 0) {
      return [];
    }

    const groups = [];
    let currentGroupCount = 1;
    let currentIdx = 0;
    let currentProgram = data.length > 0 ? data[0].Program : '';

    for (let i = 1; i < data.length; i++) {
      if (data[i as number].Program === currentProgram) {
        currentGroupCount++;
      } else {
        // New folder found, add the old group and start the new group
        groups.push(createGroup(currentProgram, currentIdx, currentGroupCount));

        currentIdx = i;
        currentGroupCount = 1;
        currentProgram = data[i as number].Program;
      }
    }

    // Push the last group
    groups.push(createGroup(currentProgram, currentIdx, currentGroupCount));

    return groups;
  };

  const searchFile = async (event: any) => {
    const cleanName = programName.trim();

    if (cleanName) {
      setFileData([]);
      setFileGroups([]);
      const packageList = await ganymedeFirmwareRequestService.getFirmwareList(cleanName);
      const tableData = generateTable(packageList);

      if (tableData.length === 0) {
        const message: SystemMessageType = {
          message: 'no-files',
          id: 'no-files',
          type: FluentMessageBarType.warning,
          groupId: failGroupId,
          namespace: NS.ERRORS,
        };

        addGlobalMessage(message);
      }

      setFileData(tableData);
      setFileGroups(generateGroups(tableData));
    } else {
      const message: SystemMessageType = {
        message: 'no-program',
        id: 'no-program',
        type: FluentMessageBarType.error,
        groupId: failGroupId,
        namespace: NS.ERRORS,
      };

      addGlobalMessage(message);
      setFileData([]);
      setFileGroups([]);
    }
  };

  const openPanel = () => {
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const menuItems = [
    {
      key: 'upload-files',
      text: t('upload-files', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.UPLOAD },
      onClick: openPanel,
    },
  ];

  const filterItems: ICommandBarItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-program', { ns: NS.COMMON })}
          value={programName}
          required
          iconProps={{ iconName: SystemIcons.SEARCH }}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          onClear={() => {
            setProgramName('');
            setFileData([]);
            setFileGroups([]);
          }}
          onChange={(e) => setProgramName((e.target as any).value)}
          onSearch={(e) => searchFile(e)}
          spellCheck="false"
        />
      ),
    },
  ];

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container labs-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.LABS.MANAGE_PACKAGES]}>{title}</PageHeader>
          <PageContent>
            <>
              <PageCommandBar items={menuItems}></PageCommandBar>
              <PageDivider />
              <PageFilterBar items={filterItems}></PageFilterBar>
              <TableViewViewController
                tableStyle="table-scrollable package-table"
                items={fileData}
                columns={dataColumns as IColumn[]}
                groups={fileGroups}
                selectionMode={SelectionMode.none}
                enableToolBar={false}
              />
              <UploadPackages isPanelOpen={isPanelOpen} closePanel={closePanel} />
            </>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

export const ViewPackages = observer(ViewPackagesFC);
