import React from 'react';
import { observer } from 'mobx-react-lite';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';
import moment from 'moment';
import { LineProps, ScatterProps, TooltipProps, XAxisProps } from 'recharts';

import { BarLineChartProps, MultiLineScatterChartProps } from '@/components/Graphs/GraphBaseType';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';

import ErrorsGraphTemplate from './ErrorsGraphTemplate';
import {
  AnnotationsChartData,
  ErrorPerPageChartData,
  ErrorsGraphItemProp,
  ErrorsGraphType,
  ErrorsPageViewChartData,
  ErrorVsPageChartData,
  TooltipFormatterProp,
} from './ErrorsGraphType';

const ErrorsGraphViewControllerFC: React.FC<{ viewModel: ErrorsGraphType }> = (props: { viewModel: ErrorsGraphType }) => {
  const [isTeachingBubbleOpen, { setTrue: showTeachingBubble, setFalse: hideTeachingBubble }] = useBoolean(false);

  const {
    bucket,
    bucketType,
    selectedAnnotation,
    formatTimeLabel,
    getReleaseAnnotationsGraphData,
    getErrorsPerPageGraphData,
    getErrorVsPageGraphData,
    setSelectedAnnotation,
  } = props.viewModel;
  const errorVsPageGraphData: ErrorsPageViewChartData[] = getErrorVsPageGraphData();
  const errorPerPageGraphData: ErrorPerPageChartData[] = getErrorsPerPageGraphData();
  const releaseAnnotationsData: AnnotationsChartData[] = getReleaseAnnotationsGraphData();
  const data: ErrorVsPageChartData[] = releaseAnnotationsData.concat(...errorVsPageGraphData);
  const domain: Array<any> = data.length > 0 ? [data[0].timeGenerated, data[data.length - 1].timeGenerated] : [];

  const timeFormatter = (tick: string) => {
    return formatTimeLabel(bucket, bucketType, parseInt(tick));
  };

  const tooltipFormatter = (value: any, name: string, tooltipProp: TooltipFormatterProp): string[] | string => {
    const newValue = tooltipProp?.payload?.releases?.buildNumber as string;
    const keyword = t('deployments', { ns: NS.TABLE });

    if (name.match(keyword)) {
      return [newValue, t('build-number', { ns: NS.TABLE })];
    }

    return value;
  };

  const labelFormatter = (item: string) => {
    const formattedTime = formatTimeLabel(bucket, bucketType, parseInt(item));

    return (
      <span>
        {t('date', { ns: NS.TABLE })} : {formattedTime}
      </span>
    );
  };

  const getErrorsVsPageGraphProps = (): ErrorsGraphItemProp<ErrorVsPageChartData, MultiLineScatterChartProps> => {
    const errorLineProp: LineProps = {
      dataKey: 'errors',
      type: 'monotone',
      stroke: '#006ee5',
      name: t('errors', { ns: NS.TABLE }),
    };
    const pageViewLineProp: LineProps = {
      dataKey: 'pageViews',
      type: 'monotone',
      stroke: '#afafaf',
      name: t('page-views', { ns: NS.TABLE }),
    };
    const scatterProp: ScatterProps = {
      dataKey: 'releases.yAxisValue',
      fill: 'blue',
      legendType: 'triangle',
      shape: 'triangle',
      name: t('deployments', { ns: NS.TABLE }),
      onClick: (item: any) => {
        setSelectedAnnotation(item?.payload);
        showTeachingBubble();
      },
    };
    const xAxisProp: XAxisProps = {
      dataKey: 'timeGenerated',
      type: 'number',
      scale: 'time',
      domain: domain,
      allowDataOverflow: true,
      minTickGap: 10,
      tickFormatter: timeFormatter,
    };
    const tooltipProp: TooltipProps<string[] | string, string> = {
      formatter: tooltipFormatter,
      labelFormatter: labelFormatter,
    };
    const graphProps: ErrorsGraphItemProp<ErrorVsPageChartData, MultiLineScatterChartProps> = {
      data: data,
      chartProp: {
        multiLineProps: [errorLineProp, pageViewLineProp],
        scatterProp,
        xAxisProp,
        tooltipProps: tooltipProp,
      },
    };

    return graphProps;
  };

  const getErrorsPerPageGraphProps = (): ErrorsGraphItemProp<ErrorPerPageChartData, BarLineChartProps> => {
    const lineProp: LineProps = {
      dataKey: 'errorsPerPageViews',
      type: 'monotone',
      stroke: '#006ee5',
      name: t('errors-per-page-views', { ns: NS.TABLE }),
    };
    const xAxisProp: XAxisProps = {
      dataKey: 'timeGenerated',
      scale: 'band',
      minTickGap: 10,
    };
    const tooltipProp: TooltipProps<string[] | string, string> = {
      labelFormatter: labelFormatter,
    };
    const graphProps: ErrorsGraphItemProp<ErrorPerPageChartData, BarLineChartProps> = {
      data: errorPerPageGraphData,
      chartProp: {
        lineProp,
        xAxisProp,
        tooltipProps: tooltipProp,
      },
    };

    return graphProps;
  };

  const errorsVsPageGraphProp: ErrorsGraphItemProp<ErrorVsPageChartData, MultiLineScatterChartProps> = getErrorsVsPageGraphProps();
  const errorsPerPageGraphProp: ErrorsGraphItemProp<ErrorPerPageChartData, BarLineChartProps> = getErrorsPerPageGraphProps();
  const selectedAnnotationDateFormatted: AnnotationsChartData = {
    ...selectedAnnotation,
    timeGenerated: moment(parseInt(selectedAnnotation.timeGenerated)).format(DateFormats.STANDARD_DATE_TIME),
  };
  return (
    <ErrorsGraphTemplate
      errorPerPageGraphProp={errorsPerPageGraphProp}
      errorVsPageGraphProp={errorsVsPageGraphProp}
      selectedAnnotation={selectedAnnotationDateFormatted}
      isTeachingBubbleOpen={isTeachingBubbleOpen}
      hideTeachingBubble={hideTeachingBubble}
    />
  );
};

const ErrorsGraphViewController = observer(ErrorsGraphViewControllerFC);

export default ErrorsGraphViewController;
