import React from 'react';
import Moment from 'react-moment';
import { Table } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { IconButton, Modal } from '@fluentui/react';
import { Icon } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Messages, TableHead } from '@/constants/LabsConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { firmwareRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';

const FirmwareInfoComponentFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const firmwareInfoTitleId = useId('firmwareInfoTitle');

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const [firmwareInfo, setFirmwareInfo] = React.useState<string[]>([]);
  const [updateDate, setUpdatedDate] = React.useState<string>('');
  const [isBusy, setBusy] = React.useState<boolean>(true);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const partnersPortal = () => {
    return appSettingsStore.isPartnerMode;
  };

  const sortFirmwareInfo = (data: any) => {
    const sortedData = data.sort((a: { DeviceType: string }, b: { DeviceType: string }) => {
      const nameA = a.DeviceType.toUpperCase();
      const nameB = b.DeviceType.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    setFirmwareInfo(sortedData);
  };

  const fetchFirmwareInfo = React.useCallback(async () => {
    setBusy(true);

    if (props.selectedItemKey) {
      const selectedKey = props.selectedItemKey[0] === undefined ? props.selectedItemKey : props.selectedItemKey[0];

      firmwareRequestService
        .getFirmwareInfo(selectedKey)
        .then((response: any) => {
          if (response.length > 0) {
            setUpdatedDate(response[0].TimeStamp);
            sortFirmwareInfo(JSON.parse(response[0].Data));
            setBusy(false);
          }
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            showInPopup: true,
          };

          HandleError(handleErrorProps);
        });
    }
  }, [props.selectedItemKey]);

  const renderRackManagerInfo = (name: string, value: any) => (
    <>
      <tr className="mb-3 pl-3 pr-3">
        <td>
          <b>{name}</b>
        </td>
        <td>
          <input readOnly className="text-field" defaultValue={value} type="text" title={name} />
        </td>
      </tr>
    </>
  );

  React.useEffect(() => {
    if (props.rackManagerInfo === undefined) {
      fetchFirmwareInfo();
    }
  }, [fetchFirmwareInfo, props.rackManagerInfo]);

  return props.isModalOpen ? (
    <div>
      <Modal
        titleAriaId={firmwareInfoTitleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.container}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
          <div className="text-left">
            <div className="row blue-bar rounded">
              <div className={`${updateDate === '' ? 'col-sm-10' : 'col-sm-8'}`}>
                <h5 className="tab-text ml-3 mt-2">
                  {props.rackManagerInfo === undefined ? props.selectedItemName : props.rackManagerInfo.Id}
                </h5>
              </div>
              <div className={`tab-text mt-2 ${updateDate === '' ? '' : 'col-sm-4'}`}>
                {updateDate !== '' ? (
                  <>
                    Updated: <Moment format="MMM Do YY, h:mm a">{updateDate}</Moment>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {firmwareInfo.length === 0 && props.rackManagerInfo === undefined ? (
            <div className="row text-center mt-2">
              <div className="col-sm-12">
                <Icon iconName="Error" />
                <span className="error-message"> {Messages.noDataFound}</span>
              </div>
            </div>
          ) : props.rackManagerInfo === undefined ? (
            isBusy ? (
              <div className="row text-center">
                <LoadingSpinner />
              </div>
            ) : (
              <Table borderless className="mt-3">
                <tbody>
                  {firmwareInfo.map((item: any, idx: any) => (
                    <>
                      <tr className="mb-2 pl-3 pr-3">
                        <td>
                          <b>{item.DeviceType}</b>
                        </td>
                        <td>{item.Description}</td>
                        <td>
                          <b>{TableHead.firmwareVersion}</b>
                        </td>
                        <td>{item.FirmwareVersion === null ? 'NA' : item.FirmwareVersion}</td>
                      </tr>
                      <tr>
                        <td> </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            )
          ) : (
            <Table borderless className="mt-3">
              <tbody>
                {renderRackManagerInfo(TableHead.firmwareVersion, props.rackManagerInfo.FirmwareVersion)}
                {renderRackManagerInfo(TableHead.model, props.rackManagerInfo.Model)}
                {renderRackManagerInfo(TableHead.serialNumber, props.selectedItemName)}
              </tbody>
            </Table>
          )}
        </div>
      </Modal>
    </div>
  ) : null;
};

export const FirmwareInfoComponent = observer(FirmwareInfoComponentFC);
