import React from 'react';
import { CompoundButton } from '@fluentui/react';
import { IButtonProps } from '@fluentui/react/lib/Button';

import ButtonListTemplate from './ButtonListTemplate';
import { ButtonListState, ButtonListType } from './ButtonListType';

import { ButtonContainer } from './ButtonList.css';

class ButtonListViewController extends React.Component<ButtonListType, ButtonListState> {
  constructor(props: ButtonListType) {
    super(props);
    this.state = {
      buttonsData: [],
    };
  }

  componentDidMount(): void {
    const { buttonListProps } = this.props;
    const buttonProps = buttonListProps.map((prop: IButtonProps, index: number) => {
      return {
        ...prop,
        onClick: () => this.onChangeSelection(prop, index),
      };
    });

    this.setState({
      buttonsData: buttonProps,
    });
  }

  onChangeSelection = (item: any, index: number) => {
    const { buttonsData } = this.state;

    if (buttonsData) {
      const newProps = buttonsData?.map((prop: IButtonProps, i: number) => {
        return i === index ? { ...prop, primary: true } : { ...prop, primary: false };
      });

      this.setState({
        buttonsData: newProps,
      });
      item.onClick(item.text, index);
    }
  };

  render() {
    const { buttonsData } = this.state;

    if (buttonsData.length >= 0) {
      const buttonComponents = buttonsData.map((prop: IButtonProps, index: number) => {
        return <CompoundButton key={index} {...prop} style={prop.style ? prop.style : ButtonContainer} />;
      });

      return <ButtonListTemplate buttonComponents={buttonComponents} />;
    }

    return null;
  }
}

export default ButtonListViewController;
