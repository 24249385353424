import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Dropdown } from '@fluentui/react';
import { DetailsRow } from '@fluentui/react';
import { CommandBarButton } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Divider } from '@fluentui/react-components';
import { t } from 'i18next';

import { LabIcons, SystemIcons } from '@/constants/IconConstants';
import { SytemColumnKeys } from '@/constants/LabDetailsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnEditorKeys, EnablePagination, Namespaces as NS } from '@/constants/SystemConstants';
import { Common, Default, Experiments, LabDetails, Labs } from '@/constants/TranslationConstants';
import PageContent from '@/partials/PageContent/PageContent';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { getPaginationDefaults } from '@/partials/Pagination/Pagination';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { RenderRowType } from '@/types/TableTypes';
import { groupByProps } from '@/utils/GroupBy';
import { labDownloadHelper, navigationOnClick } from '@/utils/Helpers';

import config from './LabSystems.config.json';
import { LabSystemsTemplate } from './LabSystemsTemplate';
import { LabSystemsVMType } from './LabSystemsType';

import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface LabSystemsViewControllerProps {
  viewModel: LabSystemsVMType;
}

const LabSystemsViewControllerFC: React.FC<LabSystemsViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labDetailsStore, labSystemsStore, paginationStore, systemMessageStore } = rootStore;
  const { setPaginationType } = paginationStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { isPersistedLab, selectedLab } = labDetailsStore;
  const {
    doReset,
    groupByValue,
    healthStatusValues,
    ipAddressValues,
    ipAddressesList,
    isLoading,
    machineTypeValues,
    machineTypesList,
    labSystems,
    setHealthStatusValues,
    setGroupByValue,
    setIpAddressValues,
    setMachineTypeValues,
    showLabSystemsColumnEditor,
  } = labSystemsStore;

  // Props Const
  const {
    fetchLabSystems,
    fetchColumnDetails,
    getIpAddressList,
    groupByNoneItem,
    handleClearAllFilters,
    handleGroupBySelected,
    searchData,
  } = viewModel;

  // Other Const
  const columnEditorKey = ColumnEditorKeys.LAB_SYSTEMS;
  const history = useHistory();

  React.useEffect(() => {
    const paginationDefaults = getPaginationDefaults(handlePaginationChange, EnablePagination.LAB_DETAILS_SYSTEM, true);

    setPaginationType(paginationDefaults);
  }, []);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (fetchLabSystems) {
      fetchLabSystems();
    }

    getIpAddressList();
  }, [fetchLabSystems, selectedLab]);

  React.useEffect(() => {
    fetchColumnDetails();
  }, [labSystems, selectedLab]);

  React.useEffect(() => {
    if (selectedLab && !isPersistedLab) {
      doReset();
    }
  }, [selectedLab, isPersistedLab]);

  React.useEffect(() => {
    if (searchData) {
      searchData();
    }
  }, [labSystems, ipAddressValues, healthStatusValues, machineTypeValues]);

  const handlePaginationChange = (currentPage: number): void => {
    searchData(currentPage);
  };

  const rackSystemsOnRenderRow: RenderRowType = (props: any) => {
    if (props) {
      return (
        <div data-selection-disabled={isLoading}>
          <DetailsRow {...props} />
        </div>
      );
    }

    return null;
  };

  const commandBarExperimentItems: ICommandBarItemProps[] = [
    {
      key: Default.CREATE_EXPERIMENT,
      text: t(Default.CREATE_EXPERIMENT, { ns: NS.DEFAULT }),
      title: t(Default.CREATE_EXPERIMENT, { ns: NS.DEFAULT }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        navigationOnClick(event, Navigation.GANYMEDE.EXPERIMENT_EDITOR, history);
      },
    },
    {
      key: Experiments.VIEW_EXPERIMENT_STATUS,
      text: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      title: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.VIEW_STATUS },
      onClick: () => {
        history.push({
          pathname: `${Navigation.GANYMEDE.EXPERIMENTS}/${selectedLab.LabId}`,
        });
      },
    },
  ];

  const commandBarDownloadInstallerItems: ICommandBarItemProps[] = [
    {
      key: Labs.DOWNLOAD_INSTALLER,
      text: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      title: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      subMenuProps: {
        items: [
          {
            key: Labs.DOWNLOAD_EXE,
            text: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_EXE },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, true),
          },
          {
            key: Labs.DOWNLOAD_MSI,
            text: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_MSI },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, false),
          },
        ],
      },
    },
  ];

  const commandBarLabActionItems: ICommandBarItemProps[] = [
    {
      key: LabDetails.LAB_ACTIONS,
      text: t(LabDetails.LAB_ACTIONS, { ns: NS.LAB_DETAILS }),
      title: t(LabDetails.LAB_ACTIONS, { ns: NS.LAB_DETAILS }),
      iconProps: { iconName: LabIcons.LAB_ACTIONS },
      subMenuProps: {
        items: [
          {
            key: LabDetails.MANAGE_RACK_MANGER,
            text: t(LabDetails.MANAGE_RACK_MANGER, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.MANAGE_RACK_MANGER, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: SystemIcons.RACK },
          },
          {
            key: LabDetails.MANAGE_PREBOOTED_SUT,
            text: t(LabDetails.MANAGE_PREBOOTED_SUT, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.MANAGE_PREBOOTED_SUT, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.PRE_BOOTED_SUT },
          },
          {
            key: LabDetails.ASSIGN_SYSTEM_TAG,
            text: t(LabDetails.ASSIGN_SYSTEM_TAG, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.ASSIGN_SYSTEM_TAG, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: SystemIcons.TAG },
            onClick: () => {
              history.push({
                pathname: `${Navigation.GANYMEDE.EXPERIMENTS}/${selectedLab.LabId}`,
              });
            },
          },
          {
            key: LabDetails.REPAIR_LAB,
            text: t(LabDetails.REPAIR_LAB, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.REPAIR_LAB, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.REPAIR_LAB },
            subMenuProps: {
              items: [
                {
                  key: LabDetails.CORRECT_MISMATCHING_IP,
                  text: t(LabDetails.CORRECT_MISMATCHING_IP, { ns: NS.LAB_DETAILS }),
                  title: t(LabDetails.CORRECT_MISMATCHING_IP, { ns: NS.LAB_DETAILS }),
                  iconProps: { iconName: LabIcons.REPAIR_IP_ADDRESS },
                },
                {
                  key: LabDetails.CORRECT_MISMATCHING_MAC,
                  text: t(LabDetails.CORRECT_MISMATCHING_MAC, { ns: NS.LAB_DETAILS }),
                  title: t(LabDetails.CORRECT_MISMATCHING_MAC, { ns: NS.LAB_DETAILS }),
                  iconProps: { iconName: LabIcons.REPAIR_MAC_ADDRESS },
                },
              ],
            },
          },
        ],
      },
    },
  ];

  const commandBarRefreshItems: ICommandBarItemProps[] = [
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Common.REFRESH,
      text: t(Common.REFRESH, { ns: NS.COMMON }),
      title: t(Common.REFRESH, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.REFRESH },
      itemDivider: true,
    },
  ];

  // Below lines will be uncommented once the functions are implemented
  const commandBarItems = [
    ...commandBarExperimentItems,
    ...commandBarDownloadInstallerItems,
    // ...commandBarLabActionItems,
    //  ...commandBarRefreshItems,
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: Common.RESET,
      onRender: () => (
        <CommandBarButton
          text={t(Common.RESET, { ns: NS.COMMON })}
          title={t(Common.RESET, { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAllFilters}
        />
      ),
    },
    {
      key: 'group-by-column',
      onRender: () => (
        <CommandBarButton
          text={t(groupByValue || SytemColumnKeys.RACK_MANGER_NAME, { ns: NS.TABLE })}
          key={groupByValue || setGroupByValue(SytemColumnKeys.RACK_MANGER_NAME)}
          iconProps={{ iconName: SystemIcons.GROUP_LIST }}
          menuProps={{ items: [groupByNoneItem, ...groupByProps(config.labSystemsColumnDefinitions, handleGroupBySelected)] }}
        />
      ),
    },
    {
      key: 'divider2',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Common.EDIT_COLUMNS,
      text: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      title: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        showLabSystemsColumnEditor();
      },
    },
  ];

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: LabDetails.MACHINE_TYPE,
      onRender: () => (
        <Dropdown
          placeholder={t(LabDetails.MACHINE_TYPE, { ns: NS.LAB_DETAILS })}
          title={t(LabDetails.MACHINE_TYPE, { ns: NS.LAB_DETAILS })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={machineTypeValues}
          options={machineTypesList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setMachineTypeValues(
              option.selected
                ? [...machineTypeValues, option.key as string]
                : machineTypeValues.filter((type: string) => type !== option.key),
            )
          }
        />
      ),
    },
    {
      key: Common.SELECT_IPADDRESS,
      onRender: () => (
        <Dropdown
          placeholder={t(Common.SELECT_IPADDRESS, { ns: NS.COMMON })}
          title={t(Common.SELECT_IPADDRESS, { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={ipAddressValues}
          options={ipAddressesList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setIpAddressValues(
              option.selected
                ? [...ipAddressValues, option.key as string]
                : ipAddressValues.filter((ip: string) => ip !== option.key),
            )
          }
        />
      ),
    },
    // Below  will be uncommented once the machine status update to table data change is implemented
    // {
    //   key: LabDetails.MACHINE_STATUS,
    //   onRender: () => (
    //     <Dropdown
    //       placeholder={t(LabDetails.MACHINE_STATUS, { ns: NS.LAB_DETAILS })}
    //       title={t(LabDetails.MACHINE_STATUS, { ns: NS.LAB_DETAILS })}
    //       multiSelect
    //       dropdownWidth="auto"
    //       selectedKeys={healthStatusValues}
    //       options={HealthStatusOptions}
    //       className={filterBarStyles['pagefilterbar-item']}
    //       styles={filterBar.dropdown}
    //       onChange={(event, option) =>
    //         setHealthStatusValues(
    //           option.selected
    //             ? [...healthStatusValues, option.key as string]
    //             : healthStatusValues.filter((status: string) => status !== option.key),
    //         )
    //       }
    //     />
    //   ),
    // },
  ];

  return (
    <PageContent scrollable={false}>
      <div className="fullscreen">
        <LabSystemsTemplate
          commandBarItems={commandBarItems}
          farItems={farItems}
          filterItems={filterItems}
          columnEditorKey={columnEditorKey}
          rackSystemsOnRenderRow={rackSystemsOnRenderRow}
        ></LabSystemsTemplate>
      </div>
    </PageContent>
  );
};

const LabSystemsViewController = observer(LabSystemsViewControllerFC);

export default LabSystemsViewController;
