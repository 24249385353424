import React from 'react';

const contentItem = {
  padding: '0px 0px',
};

const sessionInfo = {
  scrollableContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    position: 'relative',
    height: 'calc(100% - 122px)' /* Full height minus the Header and Footer areas */,
    overflow: 'hidden',
  },
};

const jsonBackground = {
  background: 'var(--backgroundColor)', // This covers the raw JSON background color
  backgroundColor: 'var(--backgroundColor)', // This covers the compiled JSON background color
};

const styles = { contentItem, sessionInfo, jsonBackground };

export default styles;
