import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ContextualMenuItemType, Icon, IconButton, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { t } from 'i18next';

import isMicrosoftAccount from '@/components/UserInfo/UserInfoStore';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Emails, Namespaces as NS } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { azureDevOpsTemplate } from '@/utils/Env';
import { navigationOnClick } from '@/utils/Helpers';

import styles from './HelpButton.module.css'; // Apply any Navigation level CSS Module styling here.

type HelpButtonType = {
  children?: React.ReactElement;
};

const HelpButtonFC: React.FC<HelpButtonType> = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDeveloperMode } = appSettingsStore;
  const history = useHistory();

  const icon: IIconProps = { iconName: SystemIcons.UNKNOWN };

  const mainHeader: typeof IContextualMenuItem = {
    key: 'actions',
    className: styles['contextual-menu-header'],
    itemType: ContextualMenuItemType.Header,
    text: t('system-help', { ns: NS.DEFAULT }),
  };

  const emailSupport: typeof IContextualMenuItem = {
    key: 'email-support',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.MAIL,
    },
    text: (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = `mailto:${Emails.SUPPORT_EMAIL}`;
          e.preventDefault();
        }}
        title={t('click-for-email-support', { ns: NS.TITLES })}
      >
        <Trans ns={NS.HELP as Namespace}>email-support</Trans>
      </Link>
    ),
  };

  const monitoringHeader: typeof IContextualMenuItem = {
    key: 'monitoring',
    className: styles['contextual-menu-header'],
    itemType: ContextualMenuItemType.Header,
    text: t('monitoring', { ns: NS.DEFAULT }),
  };

  const divider: typeof IContextualMenuItem = { key: 'divider', itemType: ContextualMenuItemType.Divider };
  const divider2: typeof IContextualMenuItem = { ...divider, key: 'divider2' };

  // NOTE: Do not delete.  We are currently not showing the Feedback button, which we are keeping at the top level.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const feedbackMenuItem: typeof IContextualMenuItem = {
    disabled: false,
    key: 'send-feedback',
    className: styles['contextual-menu-item'],
    onRender: () => {
      return (
        <Link
          className={styles['contextual-menu-link']}
          to={{ pathname: azureDevOpsTemplate }}
          title={t('send-feedback', { ns: NS.DEFAULT })}
          aria-label={t('send-feedback', { ns: NS.DEFAULT })}
          target="_blank"
        >
          <Icon
            className={`${styles['contextual-menu-icon']}`}
            iconName="Feedback"
            title={t('send-feedback', { ns: NS.DEFAULT })}
            aria-label={t('send-feedback', { ns: NS.DEFAULT })}
          />
          <Trans ns={NS.DEFAULT as Namespace}>send-feedback</Trans>
        </Link>
      );
    },
  };

  const documentationMenuItem: typeof IContextualMenuItem = {
    disabled: true,
    key: 'read-documentation',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.DOCUMENTATION,
    },
    name: t('read-documentation', { ns: NS.DEFAULT }),
    split: true,
  };

  const insightsMenuItem: typeof IContextualMenuItem = {
    disabled: false,
    key: 'insights',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: NavigationIcon[Navigation.GANYMEDE.INSIGHTS],
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.INSIGHTS}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.INSIGHTS, history)}
        title={t('click-for-insights', { ns: NS.TITLES })}
      >
        {t('application-insights', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const accessibilityMenuItem: typeof IContextualMenuItem = {
    disabled: false,
    key: 'accessibility-report',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: NavigationIcon[Navigation.GANYMEDE.ACCESSIBILITY_REPORT],
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.ACCESSIBILITY_REPORT}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.ACCESSIBILITY_REPORT, history)}
        title={t('click-for-accessibility-report', { ns: NS.TITLES })}
      >
        {t('accessibility-report', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedeIssues: typeof IContextualMenuItem = {
    disabled: false,
    key: 'known-issues',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.VIEW_BUGS,
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.ISSUES}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.ISSUES, history)}
        title={t('click-for-issues', { ns: NS.TITLES })}
      >
        {t('known-issues', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedeDocumentation: typeof IContextualMenuItem = {
    disabled: false,
    key: 'documentation',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.DOCUMENTATION,
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.PROJECT_DOCUMENTATION}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.PROJECT_DOCUMENTATION, history)}
        title={t('click-for-release-notes', { ns: NS.TITLES })}
      >
        {t('documentation', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedeReleases: typeof IContextualMenuItem = {
    disabled: false,
    key: 'release-notes',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.INFO,
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.RELEASE_NOTES}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.RELEASE_NOTES, history)}
        title={t('click-for-release-notes', { ns: NS.TITLES })}
      >
        {t('release-notes', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedeHistory: typeof IContextualMenuItem = {
    disabled: false,
    key: 'change-log',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: NavigationIcon[Navigation.GANYMEDE.CHANGE_HISTORY],
    },
    split: true,
    text: (
      <Link
        to={Navigation.GANYMEDE.CHANGE_HISTORY}
        onClick={(event) => navigationOnClick(event, Navigation.GANYMEDE.CHANGE_HISTORY, history)}
        title={t('click-for-change-log', { ns: NS.TITLES })}
      >
        {t('change-log', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedePrivacyStatement: typeof IContextualMenuItem = {
    disabled: false,
    key: 'privacy-statement',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.OPEN_IN_NEW_TAB,
    },
    split: true,
    text: (
      <Link
        to="https://go.microsoft.com/fwlink/?LinkId=521839"
        onClick={(event) => {
          event.preventDefault();
          window.open('https://go.microsoft.com/fwlink/?LinkId=521839', '_blank');
        }}
        title={t('click-for-privacy-statement', { ns: NS.TITLES })}
      >
        {t('privacy-statement', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const ganymedeInternalPrivacyStatement: typeof IContextualMenuItem = {
    disabled: false,
    key: 'privacy-statement',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.OPEN_IN_NEW_TAB,
    },
    split: true,
    text: (
      <Link
        to="https://go.microsoft.com/fwlink/?LinkId=518021"
        onClick={(event) => {
          event.preventDefault();
          window.open('https://go.microsoft.com/fwlink/?LinkId=518021', '_blank');
        }}
        title={t('click-for-privacy-statement', { ns: NS.TITLES })}
      >
        {t('privacy-statement', { ns: NS.TITLES })}
      </Link>
    ),
  };

  const reportBugMenuItem: typeof IContextualMenuItem = {
    disabled: true,
    key: 'report-bug',
    className: styles['contextual-menu-item'],
    iconProps: {
      iconName: SystemIcons.REPORT_BUG,
    },
    name: t('report-bug', { ns: NS.DEFAULT }),
    split: true,
  };

  const menuProps: typeof IContextualMenuProps = {
    isBeakVisible: true,
    items: [
      mainHeader,
      emailSupport,
      ganymedeIssues,
      ganymedeReleases,
      ganymedeDocumentation,
      divider,
      isMicrosoftAccount ? ganymedeInternalPrivacyStatement : ganymedePrivacyStatement,
      ...(isDeveloperMode ? [ganymedeHistory, divider, monitoringHeader, insightsMenuItem, accessibilityMenuItem] : []),
    ],
    directionalHintFixed: true,
  };

  const onRenderMenuIcon = () => {
    return <></>;
  };

  return (
    <IconButton
      className={styles['icon-button']}
      menuProps={menuProps}
      onRenderMenuIcon={onRenderMenuIcon}
      iconProps={icon}
      title={t('help', { ns: NS.TITLES })}
      ariaLabel={t('help', { ns: NS.TITLES })}
      disabled={false}
      checked={false}
    />
  );
};

const HelpButton = observer(HelpButtonFC);

export default HelpButton;
