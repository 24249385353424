import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Namespaces as NS } from '@/constants/SystemConstants';

import { AnnotationsChartData } from './ErrorsGraphType';

import styles from './ErrorsGraph.module.css';

const AnnotationDetailsTemplate: React.FC<AnnotationsChartData> = (props: AnnotationsChartData): React.ReactElement => {
  const { releases, timeGenerated } = props;

  return (
    <div>
      <div className="row">
        <span className={`${styles['annotations-font']} col`}>
          <Trans ns={NS.TABLE as Namespace}>date</Trans>:
        </span>
        <span className={`${styles['annotations-font']} col`}>{timeGenerated}</span>
      </div>
      <div className="row">
        <span className={`${styles['annotations-font']} col`}>
          <Trans ns={NS.TABLE as Namespace}>build-number</Trans>:
        </span>
        <span className={`${styles['annotations-font']} col`}>{releases?.buildNumber}</span>
      </div>
      <div className="row">
        <span className={`${styles['annotations-font']} col`}>
          <Trans ns={NS.TABLE as Namespace}>release-uri</Trans>:
        </span>
        <div className="col">
          <Link className={styles['annotations-font']} to={{ pathname: releases?.deploymentUri }} target="_blank">
            <Trans ns={NS.TABLE as Namespace}>click-here</Trans>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AnnotationDetailsTemplate;
