import { action, makeObservable, observable } from 'mobx';
import { t } from 'i18next';

import { SytemColumnKeys } from '@/constants/LabDetailsConstants';
import { KeyTextPair } from '@/constants/SystemConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeVoid } from '@/types/AppSettingsTypes';
import { TableColumnType } from '@/types/TableTypes';

import { LabSystemType } from './LabSystemsType';

class LabSystemsStore {
  protected localStorage: LocalStorageService;
  protected rootStore: RootStore;

  public agentIds: string[] = [];
  public filteredLabSystems: LabSystemType[] = [];
  public groupByColumn: string = null;
  public groupByValue: string;
  public healthStatusValues: string[] = [];
  public ipAddressValues: string[] = [];
  public ipAddressesList: KeyTextPair[] = [];
  public isHealthStatusRefreshed: boolean;
  public isLabSystemsColumnEditorOpen: boolean;
  public isLoading = false;
  public labSystemsColumnList: TableColumnType[] = [];
  public labSystemsEntireColumns: TableColumnType[] = [];
  public machineTypeValues: string[] = [];
  public machineTypesList: KeyTextPair[] = [];
  public labSystems: LabSystemType[] = [];
  public tableGroups: any[] | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      agentIds: observable,
      filteredLabSystems: observable,
      groupByColumn: observable,
      ipAddressesList: observable,
      isHealthStatusRefreshed: observable,
      isLabSystemsColumnEditorOpen: observable,
      isLoading: observable,
      labSystemsColumnList: observable,
      labSystemsEntireColumns: observable,
      machineTypesList: observable,
      labSystems: observable,
      tableGroups: observable,

      // These values are persisted.
      groupByValue: observable,
      healthStatusValues: observable,
      ipAddressValues: observable,
      machineTypeValues: observable,

      // Actions modify the state.
      hideLabSystemsColumnEditor: action,
      setAgentIds: action,
      setFilteredLabSystems: action,
      setGroupByColumn: action,
      setGroupByValue: action,
      setHealthStatusValues: action,
      setIpAddressValues: action,
      setIpAddressesList: action,
      setIsHealthStatusRefreshed: action,
      setIsLoading: action,
      setLabSystemsColumnList: action,
      setLabSystemsEntireColumns: action,
      setMachineTypeValues: action,
      setMachineTypesList: action,
      setLabSystems: action,
      setTableGroups: action,
      showLabSystemsColumnEditor: action,
    });

    this.localStorage = rootStore.localStorage;
    this.rootStore = rootStore;

    this.ipAddressValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_IPADDRESS_KEY) || [];
    this.machineTypeValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_MACHINE_TYPE_KEY) || [];
    this.healthStatusValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_HEALTH_STATUS_KEY) || [];
    this.groupByValue = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_GROUPBY_KEY) || '';
  }

  public setIpAddressesList = (values: KeyTextPair[]) => {
    this.ipAddressesList = values;
  };

  public setIsLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  public setIsHealthStatusRefreshed = (refreshed: boolean) => {
    this.isHealthStatusRefreshed = refreshed;
  };

  public setMachineTypesList = (types: KeyTextPair[]) => {
    this.machineTypesList = types;
  };

  public setLabSystems = (systems: LabSystemType[]) => {
    this.labSystems = systems || [];
  };

  public setAgentIds = (ids: string[]) => {
    this.agentIds = ids;
  };

  public setFilteredLabSystems = (items: LabSystemType[]) => {
    this.filteredLabSystems = items || [];
  };

  public setLabSystemsColumnList = (columns: TableColumnType[]) => {
    this.labSystemsColumnList = columns;
  };

  public setLabSystemsEntireColumns = (columns: TableColumnType[]) => {
    this.labSystemsEntireColumns = columns;
  };

  public showLabSystemsColumnEditor = () => {
    this.isLabSystemsColumnEditorOpen = true;
  };

  public hideLabSystemsColumnEditor = () => {
    this.isLabSystemsColumnEditorOpen = false;
  };

  public setTableGroups = (groups: any[] | null) => {
    this.tableGroups = groups;
  };

  public setGroupByColumn = (column: string) => {
    this.groupByColumn = column;
  };

  public setGroupByValue = (value: string) => {
    this.groupByValue = value;

    return this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_GROUPBY_KEY, value);
  };

  public setMachineTypeValues = (values: string[]) => {
    this.machineTypeValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_MACHINE_TYPE_KEY, values);
  };

  public setIpAddressValues = (values: string[]) => {
    this.ipAddressValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_IPADDRESS_KEY, values);
  };

  public setHealthStatusValues = (values: string[]) => {
    this.healthStatusValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_HEALTH_STATUS_KEY, values);
  };

  public doReset: ActionTypeVoid = () => {
    this.setHealthStatusValues([]);
    this.setMachineTypeValues([]);
    this.setIpAddressValues([]);
    this.setGroupByValue(SytemColumnKeys.RACK_MANGER_NAME);
  };
}

export default LabSystemsStore;
