import { action, makeObservable, observable } from 'mobx';

import { RootStore } from '@/stores/RootStore';

class PreviewExperimentStore {
  protected rootStore: RootStore;

  public convertedExperiment: string;
  public isExperimentLoading: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.convertedExperiment = null;
    this.isExperimentLoading = false;

    makeObservable(this, {
      convertedExperiment: observable,
      isExperimentLoading: observable,

      // Actions modify the state
      setConvertedExperiment: action,
      setExperimentLoading: action,
    });
  }

  public setConvertedExperiment = (experiment: string) => {
    this.convertedExperiment = experiment;

    return this.convertedExperiment;
  };

  public setExperimentLoading = (loading: boolean) => {
    this.isExperimentLoading = loading;

    return this.isExperimentLoading;
  };
}

export default PreviewExperimentStore;
