import { StepsType } from '@/components/Experiments/ExperimentsTypes';
import { filterDateAgent, filterStatus, filterStepType, filterText } from '@/utils/Text';

class ExperimentDetailsViewModel {
  public title: string;
  public labId: string;
  public instanceId: string;
  public loadMessage: string;

  constructor(title: string, labId: string, instanceId: string, loadMessage: string) {
    this.title = title;
    this.labId = labId;
    this.instanceId = instanceId;
    this.loadMessage = loadMessage;
  }

  public filterAgentStepData = (
    steps: StepsType[],
    search: string,
    status: string[],
    stepType: string[],
    startDate,
    endDate,
    timeRange,
  ): any[] => {
    let results = [...steps];

    results = filterText(results, search);
    results = filterStatus(results, status);
    results = filterStepType(results, stepType);
    results = filterDateAgent(results, startDate, endDate, timeRange);

    return results;
  };
}

export default ExperimentDetailsViewModel;
