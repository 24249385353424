const AreaLabels = {
  closePopupModal: 'Close popup modal',
};

const Buttons = {
  RemoveUser: 'Remove User',
  addFeed: 'Add Feed',
  addFirmwareGroup: 'Add to Firmware Group',
  addPackage: 'Add Package to the Lab',
  addPartnersNewUser: 'Add Partners Portal User',
  addRackManager: 'Add Rack Manager',
  addTestPlanGroup: 'Add to Test Plan Group',
  addUser: 'Add Users',
  attachFiles: 'Attach Files',
  batchOperation: 'Batch Operations',
  browseFile: 'Browse Files',
  cancel: 'Cancel',
  close: 'Close',
  createExperimentSteps: 'Create Experiment Steps',
  createFirmware: 'Create Firmware',
  createFirmwareGroup: 'Create Firmware Group',
  createLab: 'Create a New Lab',
  createLabExperimentSteps: 'Create Lab Experiment Steps',
  createTestPlan: 'Create Test Plan',
  createTestPlanGroup: 'Create Test Plan Group',
  createVirtualLab: 'Create Virtual Lab',
  delete: 'Delete',
  deleteFeed: 'Delete NuGet Feed',
  deleteFirmware: 'Delete Firmware',
  deleteFirmwareGroup: 'Delete Firmware Group',
  deleteLab: 'Delete Lab',
  deleteMachine: 'Delete Selected Machine(s)',
  deletePkg: 'Delete NuGet Package',
  deleteTestPlan: 'Delete Test Plan',
  deleteTestPlanGroup: 'Delete Test Plan Group',
  downloadExeLab: 'Download Lab (EXE)',
  downloadLab: 'Download Lab',
  downloadMsiLab: 'Download Lab (MSI)',
  editFirmware: 'Edit Firmware',
  editRackManager: 'Edit Rack Manager',
  excuteTest: 'Execute Test Plan',
  excuteTestGroup: 'Execute Test Plan Group',
  getFiles: 'Get File List',
  getMorePackages: 'Get More Packages',
  goToLab: 'Go To the lab',
  hardRebootMachine: 'Hard Reboot Machine(s)',
  labAction: 'Lab Action ',
  machineBatchOperation: 'Machine Batch Operation',
  manageRackManager: 'Manage Rack Manager',
  manageUsers: 'Manage Users',
  optionalSignOff: 'Optional',
  rebootMachine: 'Soft Reboot Machine(s)',
  refreshMachineList: 'Refresh Machine List',
  refreshRackManagerInfo: 'Refresh Rack Manager Info',
  reimageMachine: 'Reimage Machine',
  resetMachineState: 'Reset Machine State',
  save: 'Save',
  search: 'Search',
  searchFromRepo: 'CHIE Firmware Repository',
  send: 'Send',
  signOff: 'Sign Off',
  signedOff: 'Signed Off',
  updateDefaultOSImage: 'Update Default OSImage',
  updateFirmwareGroup: 'Update Firmware Group',
  updateLab: 'Update Lab',
  updateLabOSImage: 'Update Default OS Image',
  updateNow: 'Update Now',
  updateTestPlan: 'Update Test Plan',
  updateTestPlanGroup: 'Update Test Plan Group',
  updateVirtualLab: 'Update Virtual Lab',
  uploadJsonFile: 'Upload Json File',
  viewExperimentStatus: 'View Experiment Status',
  viewLogs: 'View Log Files',
  viewTest: 'View Tests',
  yes: 'Yes',
  uploadFile: 'Upload Files',
};

const BringYourOwnRules = {
  addTags: 'Provide a set of unique identifiers for the rule',
  category: 'Category can be either System or User. For user defined rules, the value should be user',
  filesToSearch: 'The Log File name for which the rule is applicable should be specified here',
  ruleDescription: 'Rule Description provides the details about the rule',
  ruleExpression:
    'Rule Expression is a combination of metric Name, operator and value. The default operation will be an AND on all the expressions',
  ruleName: 'Rule Name is the unique identifier for every rule',
  subCategory: 'Sub Category provides the type of the rule',
};

const Colors = {
  green: 'green',
  red: 'red',
  yellow: 'yellow',
};

const ColorsForStatus = {
  completed: 'green',
  error: 'red',
  loading: 'grey',
  summaryError: 'yellow',
};

const DateFormat = {
  convertToSeconds: 1000,
  dateRange: 30,
  oneDayInMilliseconds: 1000 * 60 * 60 * 24,
};

const ErrorMessages = {
  dataNotAvailable: 'Data Not Available',
  dataNotFoundText: 'Data Not Found',
  downloadError: 'Download Error',
  fileNotFoundText: 'File Not Found in Blob Storage',
  selectOneRule: 'Select at least one rule!',
  systemError: 'System Error',
};

const FilterKeys = {
  allResultsSearch: 'FILTER_ALLRESULTS_SEARCH_KEY',
  allResultsCompany: 'FILTER_ALLRESULTS_COMPANY_KEY',
  myLabResultsSearch: 'FILTER_MYLABRESULTS_SEARCH_KEY',
  myLabResultsCompany: 'FILTER_MYLABRESULTS_COMPANY_KEY',
  myResultsSearch: 'FILTER_MYRESULTS_SEARCH_KEY',
  myResultsCompany: 'FILTER_MYRESULTS_COMPANY_KEY',
};

const HealthDiffInMins = {
  unhealthy: 10,
  warning: 5,
};

const Labels = {
  AddedFeeds: 'Added Feeds',
  CSDeleted: 'CSDeleted',
  ExecuteTestPlan: 'ExecuteTestPlan',
  FTPServerURL: 'FTP Server URL: ',
  GroupName: 'Group Name',
  ID: 'User ID: ',
  InBand: 'InBand',
  OutOfBand_BMC: 'OutOfBand_BMC',
  OutOfBand_Drive: 'OutOfBand_Drive',
  OutOfBand_PSU: 'OutOfBand_PSU',
  RebootOption: 'Reboot Option:',
  SI: 'SI',
  SuperAdmin: 'SuperAdmin',
  addFirmwareGroupUser: 'addFirmwareGroupUser',
  addFirmwareUser: 'AddFirmwareUser',
  addLabUser: 'addLabUser',
  addRackManager: 'AddRackManager',
  addRules: 'Add Rules',
  addSystemTag: 'Add System Tag',
  addTags: 'Add Tags',
  addTestCase: 'Add Test Cases',
  addTestPlanUser: 'addTestPlanUser',
  addVM: 'addVM',
  admin: 'Admin',
  adminCompany: 'Microsoft',
  allTestsSignedOff: 'All tests signed off',
  applicableRules: 'Applicable Rules',
  applyNewRules: 'Apply New Rules',
  applyRules: 'Apply Rules',
  automatedSignOff: 'crc-automation@microsoft.com',
  backText: 'Back',
  batchOperations: 'Select Machines to Process Batch Operations',
  bricks: 'Bricks',
  bricksTestPlanGroupUpdate: 'Bricks testPlan group update',
  browseFiles: 'Browse for files',
  bugId: 'Bug Id',
  build: 'Build',
  buildList: 'BuildList',
  checkResult: 'Check Result',
  clearAll: 'Clear All',
  clearAllItemType: 'ClearAll',
  clearAllKey: 'clearAll',
  closeText: 'Close',
  cloudReadinessCriteria: 'CLOUD READINESS CRITERIA',
  commandLine: 'Command Line: ',
  company: 'Select Company: ',
  companyLabel: 'Company',
  completed: 'Completed',
  component: 'Component',
  container: 'container',
  createBricksGroup: 'Create Bricks Group',
  createBricksTest: 'Create Bricks Test Plan',
  createBricksTestCase: 'Create Bricks Test Case',
  createBricksTestPlanGroup: 'Create Bricks Test Plan Group',
  createExperimentSteps: 'Create Experiment Steps',
  createFirmware: 'Create Firmware',
  createFirmwareContainer: 'Create Firmware Container',
  createFirmwareGroup: 'Create Firmware Group',
  createGroupDescription: 'Order the firmware containers to preferred sequence',
  createLab: 'Create Lab',
  createNewLab: 'Create New Lab',
  createTestPlan: 'Create Test Plan',
  createTestPlanGroup: 'Create Test Plan Group',
  createTestPlanGroupDescription: 'Order the test plan to preferred sequence',
  createToolkitGroup: 'Create Toolkit Group',
  createToolkitTestPlanGroup: 'Create Toolkit Test Plan Group',
  createVM: 'Create Virtual Machine',
  createVirtualLab: 'Select Machines to Create Virtual Lab',
  createVirtualLabWithMachine: 'Create virtual lab with selected machines',
  createVirtualNewLab: 'Create Virtual Lab',
  createdExperimentId: 'Created experiment ID:',
  dashboard: 'dashboard',
  defaultAdoValue: 'ADO Details Not Provided',
  defaultOS: 'Default OS Image',
  definition: 'DEFINITION',
  delete: 'delete',
  deleteAddedRackManager: 'Delete Added Rack Manager',
  deleteFirmware: 'deleteFirmware',
  deleteFirmwareGroup: 'Delete Firmware Group',
  deleteFirmwareGroupUser: 'deleteFirmwareGroupUser',
  deleteFirmwareUser: 'deleteFirmwareUser',
  deleteLabsUser: 'deleteLabsUser',
  deleteNugetFeed: 'deleteNugetFeed',
  deleteNugetPkg: 'deleteNugetPackage',
  deleteRackManagerAndSystems: 'DeleteRackManagerAndSystems',
  deleteTestPlan: 'deleteTestPlan',
  deleteTestPlanGroup: 'deleteTestPlanGroup',
  deleteTestPlanGroupUser: 'deleteTestPlanGroupUser',
  deleteTestPlanUser: 'deleteTestPlanUser',
  detailedReport: 'Experiment Insights',
  detailedStatus: 'Detailed Status',
  devices: 'Devices',
  download: 'Download',
  downloadExe: 'EXE',
  downloadInstaller: 'Download Installer',
  downloadMsi: 'MSI',
  dropFiles: 'Drag and drop files here',
  editLabName: 'Edit Lab Name',
  editMode: 'editMode',
  editRackManager: 'EditRackManager',
  editTestPlan: 'Edit Toolkit Test Plan',
  endTime: 'End Time',
  errorText: 'Error',
  errorTextMessage: 'Error Message',
  executeTestPlanGroup: 'ExecuteTestPlanGroup',
  executedBy: 'Executed By',
  expandCollapseRow: 'Expand or Collapse Row',
  experimentApiVersion: 'Experiment Management Version: ',
  experimentInstanceId: 'Experiment Instance Id',
  experimentJson: 'Experiment Json',
  experimentManagementVersion: 'Experiment Management Version API: ',
  experimentName: 'Experiment Name',
  experimentStatus: 'Experiment Status',
  experimentSteps: 'Experiment Steps',
  extensions: 'Extensions',
  fail: 'FAIL',
  failed: 'Failed',
  failure: 'failure',
  feed: 'Feed',
  feedUri: 'Feed URI',
  feedbackIcon: 'Feedback-icon',
  fileFromLocal: 'Files from Local Machine',
  fileFromRepo: 'Files from Repository',
  fileName: 'File Names: ',
  filePath: 'File Path',
  files: 'File(s): ',
  firmware: 'Firmware',
  firmwareContainer: 'Firmware Containers',
  firmwareContainerAndGroup: 'Firmware Containers and Groups',
  firmwareFile: 'Firmware File(s): ',
  firmwareName: 'Firmware Name: ',
  firmwareRecipeFromNugetPackage: 'Upload Firmware Group Using Json File',
  firmwareSearch: 'Firmware File Search from Repository',
  firmwareType: 'Firmware Type:',
  firmwareUpdateMethod: 'Firmware Update Method:',
  firmwareVersion: 'Firmware Version: ',
  firmwareVersionTooltip: 'Please enter firmware version similar to hardware information',
  firmwarestr: 'firmware',
  general: 'General',
  generation: 'Generation',
  generationList: 'GenerationList',
  getLink: 'Get Link',
  gray: 'Gray',
  green: 'Green',
  group: 'group',
  groupUpdate: 'groupUpdate',
  hardReboot: 'hardReboot',
  healthy: 'Healthy',
  home: 'Home',
  hostName: 'Host Name',
  iconButton: 'icon-buttons',
  inProgress: 'InProgress',
  individualDevices: 'Individual Devices',
  invalid: 'Invalid',
  ipAddress: 'IP Address:',
  isAdmin: 'is Admin',
  isPublic: 'Visibility: ',
  lab: 'lab',
  labAdmin: 'Lab Admin',
  labControl: 'Lab Control',
  labFirmwareUpdate: 'labFirmwareUpdate',
  labHealth: 'Lab Health: ',
  labHealthy: 'lab-healthy',
  labID: 'Lab ID',
  labList: 'Manage Labs',
  labName: 'Lab Name',
  labNeutral: 'lab-neutral',
  labType: 'Choose Lab Type: ',
  labUnhealthy: 'lab-unhealthy',
  labUser: 'Lab user',
  labVersion: 'Lab Version',
  labWarning: 'lab-warning',
  labsList: 'Labs List',
  labsPackages: 'Labs Packages',
  lastHeartbeat: 'Last Heartbeat: ',
  live: 'Live',
  loading: 'loading...',
  loadingKey: 'loading',
  location: 'Location',
  logOut: 'Logout',
  machineStatus: 'Machine Status',
  manageFirmware: 'Manage Firmware',
  manageLabs: 'Manage Labs',
  manageTestPlan: 'Manage Test Plans',
  manageUser: 'Manage User',
  manufacturer: 'Manufacturer (Required)',
  modelList: 'ModelList',
  multipleVM: 'Multiply VM with same spec',
  myFeed: 'My Feeds',
  myPackages: 'My Packages',
  na: 'N/A',
  nameLab: 'Lab Name: ',
  no: 'No',
  noAdmin: 'No Admins Available',
  noTagsFound: 'No Tags Found',
  notContacted: ' Not contacted',
  notes: 'Notes',
  order: 'Order',
  osImages: 'Choose OS Image: ',
  osImage: 'OS Image',
  partNumber: 'Part Number',
  partNumberDetails: '(Internal): Part Number Details',
  partNumberList: 'PartNumberList',
  pass: 'PASS',
  password: 'Password: ',
  pasteExperimentJson: 'Paste Experiment Json',
  pat: 'Personal Access Token',
  pending: 'Pending',
  permissionLevel: 'Permission Level:',
  private: 'Private',
  processorList: 'ProcessorList',
  processors: 'Processor',
  program: 'Program',
  programName: 'Program Name',
  public: 'Public',
  query: 'Query',
  queued: 'Queued',
  ramAllocationInGB: 'RAM Allocation in GB',
  reboot: 'reboot',
  received: 'Received',
  recievedMsg: 'Recieved Messages',
  red: 'Red',
  regex: 'Regex supported (Optional)',
  regressionTestPassHeader: 'CSIToolKit Release Test Pass Results',
  regressionTestPassResults: 'Release TP Results',
  regressionTestPlan: 'Test Plan',
  reimage: 'reimage',
  rejected: 'Rejected',
  remove: 'Remove',
  repairMachine: 'Repair Machine',
  reset: 'reset',
  result: 'Result',
  resultReviewTool: 'Result Analyzer',
  resultSearch: 'Result Search',
  resultsExplorer: 'Result Explorer',
  reviewCreate: ' REVIEW & CREATE',
  reviewTestResults: 'Review Test Results',
  ruleExpression: 'Expression',
  ruleName: 'Rule Name',
  ruleResult: 'Rule Result',
  ruleText: 'Rule',
  rulesStatus: 'Rules Status',
  rulesTab: 'Rules',
  runDate: 'Run Date',
  runDateList: 'RunDateList',
  searchByPkg: 'Search By Package Name',
  searchInstance: 'Search for Experiment Instance ID and press Enter/Click on Search Icon...',
  searchResults: 'Search Results',
  select: 'Select',
  selectAll: 'Select All',
  selectAllItemType: 'SelectAll',
  selectAllKey: 'selectAll',
  selectDefaultOS: 'Select Labs Default OS Image',
  selectOS: 'Select OS Image',
  selectOption: 'Select an option',
  selectPackageVersion: 'Select Package Version',
  selectRow: 'select row',
  selectTestPlan: 'Select Test Plan to Execute',
  selectTestPlanGroup: 'Select Test Plan Group to Execute',
  selectedFirmwares: 'Selected Firmware Containers',
  serverBuildingBlock: 'Server Building Block',
  serverBuildingBlockDetails: '(Internal): Server Building Block Details',
  severityLevel: 'Severity Level',
  signIn: 'Sign In',
  signOff: 'Sign Off',
  signOffList: 'SignOffList',
  signOffUser: 'SignOff User',
  signoffs: 'SignOffs',
  single: 'single',
  skip: 'Skip',
  sku: 'SKU',
  skypeCircleCheck: 'SkypeCircleCheck',
  smallPanelIconClass: 'small-panel-icon',
  startTime: 'Start Time',
  started: 'Started',
  statusErrorFull: 'StatusErrorFull',
  stepDetails: 'Step Details',
  subChild: 'sub-child',
  subParent: 'sub-parent',
  success: 'Success',
  suggestedTags: 'Suggested Tags',
  summary: 'Summary',
  systems: 'Systems',
  systemEvent: 'System Events',
  systemResponse: 'System Response',
  take: 'Take',
  targetAddress: 'Target Address',
  targetName: 'Target Name',
  testCase: 'Test Case',
  testCaseName: 'Test Case Name: ',
  testCases: ' TEST CASES',
  testCommand: 'Test Command: ',
  testDetails: 'TestDetails',
  testExecution: 'Test Execution',
  testName: 'Test Name',
  testNameList: 'TestNameList',
  testOwnerList: 'TestOwnerList',
  testOwners: 'Test Owners',
  testPlan: 'testPlan',
  testPlanGroup: 'testPlanGroup',
  testPlanList: 'Test Plan List',
  testPlanListKey: 'TestPlanList',
  testPlanName: 'Testplan Name',
  testPlanStatus: 'Testplan Status:',
  testPlanSummary: 'Test Plan Summary',
  testResult: 'Test Result',
  testResultList: 'TestResultList',
  testSuite: 'Test Suite',
  testSuiteDescription: '$TestSuiteDescription',
  testSuiteDetails: '$TestSuiteDetails',
  testSuiteList: 'TestSuiteList',
  testplanName: 'Test Plan Name: ',
  testplanNameCap: 'TEST PLAN NAME ',
  toggleSelection: 'Toggle selection',
  toggleSelectionAll: 'Toggle selection for all items',
  tonextStep: 'the next step',
  toolkit: 'Toolkit',
  toolkitStatus: 'Toolkit Status',
  toolkitTestPlanGroupUpdate: 'Toolkit testPlan group update',
  unhealthy: 'Unhealthy',
  unregistered: 'Unregistered',
  update: 'update',
  updateFirmwareGroup: 'Update Firmware Group',
  updateGroupDescription: 'Reorder the firmware containers to preferred sequence',
  updateNow: 'Update Now',
  updateOSImage: 'updateOSImage',
  updateRMFirmware: 'updateRMFirmware',
  updateTestGroupDescription: 'Reorder the test plan to preferred sequence',
  updateTestPlanGroup: 'Update Test Plan Group',
  updateVirtualLab: 'Select Machines to Update Virtual Lab',
  updateVirtualLabWithMachine: 'Update virtual lab with selected machines',
  uploadFile: 'Upload Files',
  uploadFirmwareGroupFile: 'Upload Firmware Group File',
  uploadPackages: 'Upload Packages',
  uploadedFiles: 'Uploaded Files:',
  useFTP: 'Use FTP server ',
  userAddedRackManager: 'User Added Rack Manager',
  userEmail: 'User Email',
  userName: 'Username',
  version: 'Version',
  versionList: 'VersionList',
  vhdSize: 'VHD Size in GB',
  viewAnalysis: 'Experiment Insights',
  viewExamples: 'View Examples',
  viewFile: 'View File',
  viewFirmware: 'View Firmware',
  viewFirmwareGroup: 'View Firmware Group',
  viewLogs: 'View Logs',
  viewLogsForThisRun: 'View Logs For This Run',
  viewPowerBiReports: 'View Test Reports',
  viewSummary: 'View Summary',
  viewTestResults: 'View Test Results',
  virtualCPUCount: 'Virtual CPU Count',
  virtualLab: 'VirtualLab',
  virtualLabName: 'Virtual Lab Name',
  virtualLabUpdate: 'virtualLabUpdate',
  vmGeneration: 'VM Generation',
  vmName: 'Virtual Machine Name',
  warning: 'Warning',
  yes: 'Yes',
  uploadFWFile: 'Upload FW File',
};

const Messages = {
  CopyURIMsg: 'Copy the URI and share with the person to complete the user creation',
  FirmwareDeleteSuccess: 'Firmware deleted successfully!',
  FirmwareGroupDeleteFailed: 'Error: User can not delete the firmware recipe.',
  FirmwareGroupDeleteSuccess: 'Firmware group deleted successfully!',
  FirmwareRefreshSuccess: 'Firmware refresh command submitted successfully!',
  RMRefreshSuccess: 'Rack manager info refresh command submitted successfully!',
  adBlocker: 'If you use Ad-blocker, please add this to the allowed list or select "Don\'t run on this site" option.',
  addRackManagerSuccessfully: 'Add rack manager command sent successfully!',
  alreadySignedOff: 'This was already signed off',
  applySelectedRules: 'The selected rules have been applied.',
  attachAtleastOneFile: 'Please add at least one firmware file!',
  attachFile: 'Please add at least one file!',
  authError: 'An error occurred during authentication, please try again!',
  automatedSignedOff: 'This was signed off automatically',
  cancelAllExperiment: 'Cancel all pending experiment steps',
  cancelAllExperimentDefinitions: 'All Pending experiment steps has been cancelled for experimentDefinitionID =',
  cancelAllExperimentInstances: 'All Pending experiment steps has been cancelled for experimentInstanceID =',
  cancelPartial1: 'Cancellation request successfully created for ',
  cancelPartial2: ' experiment instances and failed for ',
  cancelPartial3: ' experiment instances.',
  cancellationFailed: 'Cancellation failed ',
  cancellationFailedWithError: 'Cancellation failed with error ',
  chooseItem: 'Please choose at least one item!',
  chooseOSImage: 'Please select default OS image!',
  clickToViewLogs: 'Click in the row to view logs and further details',
  companyNameFetchError: 'Error while fetching user company name',
  copyLinkForTestRun: 'Copy link to this test run.',
  copyPathToClipBoard: 'Copy path to clipboard',
  createExperimentFailure: 'Failed to create the experiment instance',
  createExperimentStepConfirmation: 'Lab is unhealthy and your experiment will be queued. Do you wish to continue?',
  createExperimentSuccess: 'Created the experiment instance',
  createNewLabError: 'Error while creating a lab. Please contact administrator for further assistance.',
  createNewLabSuccess:
    'Lab Created Successfully. Download and run the lab controller installer to dedicate a lab for your new lab.',
  createTestPlanNameError: 'ERROR: Cannot read the Test Plan file. Please check if it is a valid JSON!',
  createVMMessage: 'Create VM command successfully sent.',
  creatingFirmware: 'Processing creating firmware...',
  dateDifferenceValidation: 'End date should be greater than start date',
  deleteFirmwareConfirmation: 'Are you sure to delete this firmware?',
  deleteFirmwareGroupConfirmation: 'Are you sure to delete this firmware group?',
  deleteFirmwareGroupUserConfirmation: 'Are you sure to remove user from this firmware group?',
  deleteFirmwareUserConfirmation: 'Are you sure to remove user from this firmware?',
  deleteMachineConfirmation: 'Are you sure to delete the machine(s)?',
  deleteNugetConfirmation: 'Are you sure to delete this NuGet Feed?',
  deleteNugetPkgConfirmation: 'Are you sure to delete this NuGet Package?',
  deleteRackManagerConfirmation: 'Are you sure to delete the selected rack manager?',
  deleteRackManagerSuccessfully: 'Delete rack manager command sent successfully',
  deleteTestCaseConfirmation: 'Delete current Test Case?',
  deleteTestPlanConfirmation: 'Are you sure to delete the test plan?',
  deleteTestPlanGroupConfirmation: 'Are you sure to delete the test plan group?',
  deleteTestPlanUserConfirmation: 'Are you sure to remove user from this test plan?',
  deleteUserConfirmation: 'Are you sure to remove user from this lab?',
  differenceInMonths: 'Maximum difference in days should be 30 days',
  downloadAllLogFiles: 'Download all log files (.zip)',
  downloadFailure: 'Failed to download',
  downloadFile: 'Download file',
  downloadLabWaitTimeRequest: 'This executable download might take a while. Please be patient.',
  downloadOrRun: 'Download and run the lab controller installer to dedicate a lab for your new lab.',
  downloadWillStart: 'Your download will begin shortly.',
  endDate: 'Select an End Date',
  enterOrSelectOption: 'Enter or Select an option',
  exceededMachineCapacity: 'Provided input(s) exceeded systems capacity!',
  experimentStepCancelled: 'Experiment Step has been Cancelled',
  experimentCreationReport: 'Creation Report',
  failedToApplyRules: 'Failed to apply selected rules.',
  failedToOpen: 'Unable to open the path.',
  fetching: 'Fetching...',
  firmwareCreatedSuccessfully: 'Firmware created successfully.',
  firmwareCreationSent: 'Creating firmware... This might take for a while depending on file size. You may leave the page now.',
  firmwareUpdateConfirmation: 'Do you want to update firmware even if machine(s) already run the same firmware version?',
  firmwareUpdateSubmittedSuccessfully: 'Firmware update command submitted successfully.',
  forThisLab: ' for this lab. Refer to our',
  forcedFirmwareUpdate: 'This will update firmware without comparing firmware version with existing.',
  goToLoginPage: 'Go to Login Page',
  gotoLinkForADO: 'Go to the Test Plan ADO.',
  gotoPartnersPortal: ' Go to Partners Portal',
  groupUpdateConfirmation: 'Are you sure to update the firmware group?',
  hardRebootMachineConfirmation: 'Are you sure to hard reboot the machine(s)?',
  hardRebootOnlySupportedUnderRack: 'Hard reboot only supported on the machines under rack manager!',
  invalid: 'Invalid toolkit test script!',
  invalidBugId: 'Please enter a valid BugId',
  invalidIP: 'You have entered an invalid IP address!',
  invalidUserName: 'Invalid username!',
  labNameUpdated: 'Lab name updated successfully!',
  labNotExist: 'The lab with id ${labId} that you are trying to access does not exist.',
  labNotExistResponse: 'Unable to parse the Lab Id from the request. Value provided: ${labId}',
  labSharedMessage: ' has been shared with you. \nPlease click on the link to manage the lab.\n',
  labsControlDeleteMachineSuccess: 'Delete machine command successfully sent.',
  labsControlGroupUpdateSuccess: 'Firmware group update command successfully sent.',
  labsControlLabDeleteSuccess: 'Lab Deleted successfully!',
  labsControlLabExistError: 'Lab already registered!',
  labsControlLabNameNotMatch: 'Error: Please type the exact lab name to delete!',
  labsControlLabUpdateSuccess: 'Lab update command successfully sent.',
  labsControlMachineHardRebootSuccess:
    'Hard reboot machine(s) command successfully sent. This only applies to the machines under rack manager.',
  labsControlMachineRebootSuccess: 'Soft reboot machine(s) command successfully sent.',
  labsControlMachineResetSuccess: 'Machine reset command successfully sent.',
  labsControlOSImgSuccess: 'OS Reimage Machine command successfully sent.',
  labsControlOSImgUpdateSuccess: 'Default OS Image for this lab updated successfully.',
  labsControlTestplanSuccess: 'Execute TestPlan command successfully sent.',
  labsUpdateConfirmation: 'Are you sure to update this lab?',
  lastRefreshTime: 'Last Refresh : ',
  lengthMustBeLongerthan2: 'User Alias should be longer than 2 characters!',
  logsNotFound: 'Logs Not Found',
  logsNotFoundDescription: 'Error: Something went wrong while fetching the test results.',
  manuallyAddedRackHasIssue: 'Error: Failed to add rack manager. Please navigate to Manage Rack Manager and resolve the issue.',
  manuallyDeletedRackHasIssue:
    'Error: Failed to delete rack manager. Please navigate to Manage Rack Manager and resolve the issue.',
  missingInput: 'Please fill out all the input fields!',
  missingName: 'Please enter virtual labs name.',
  newFeature: 'We are working on bringing new features!',
  noAccessToLab:
    'The user ${userName} is not an owner of the lab with id ${labId}. Please send an email to ${mailId} to request access.',
  noAccessToLabResponse: 'The user: ${userName}, is not an owner of the lab with id: ${labId}',
  noAvailableLabsError: 'There is no available lab.',
  noDataForthePartner: 'No data available for this manufacturer',
  noDataFound: 'No data available.',
  noDevicesFound: 'No devices are available.',
  noExperimentFound: 'No pending or inprogress experiments found',
  noLabs: 'No Labs found',
  noMachinesForVLab: 'you do not have any machines to create virtual lab!',
  noMachines: 'No Machines found',
  noPending: 'No pending or inprogress experiments found',
  noPermission: 'You do not have the permission to perform this action.',
  noRecordsFound: 'No Records Found',
  noSearchResult: 'no matching names.',
  nugetFeedAddedSuccessfully: 'NuGet Package Feed added Successfully!',
  nugetPackages: 'NuGet Packages',
  onboardingWiki: 'onboarding wiki.',
  onlyNumbersAllowed: 'Only numbers allowed for CPU, RAM & VHD size!',
  openLogs: 'Click to view logs',
  openLogsNewTab: 'Click to view logs in a new tab',
  optionalSignOff: 'This is an optional sign off',
  pathCopyClipboard: 'Path Copied',
  please: ' Please ',
  pleaseLogin: 'Please login to access the site',
  provideGroupName: 'Please provide group name!',
  provideTestPlanName: 'Please provide Test Plan name!',
  rebootMachineConfirmation: 'Are you sure to soft reboot the machine(s)?',
  requireDetails: 'Please provide required details',
  requireSignOff: 'This requires sign off',
  resetMachineConfirmation: 'Are you sure to reset the machine state?',
  reviewYourTestPlan: 'Please review your test plan summary before creating it. The complete Test Plan is below.',
  ruleAdded: 'Rule has been added',
  savedTestPlanFileError: 'Error: Sorry, we could not open the Test Plan. The test plan format is not correct!',
  saving: 'Saving...',
  searchText: 'Search for any field',
  searching: 'Searching...',
  selectEvenMachineError: 'Please select even number of machines for ClientServer Workloads',
  selectMachine: 'Please select the machines.',
  selectMachineError: 'Please select one or more machines.',
  sessionExpired: 'User Session has been expired. Please login again. Redirecting...',
  setLabVersionError: 'Error while setting version for a lab. Please contact administrator for further assistance.',
  shareWithPeople: 'Share with people',
  skipFirmwareUpdate: 'This will perform firmware version comparison before updating.',
  somethingWrongTestPlan:
    'Oh no! something went wrong. This test plan might be stale, please delete this or update this test plan!',
  startDate: 'Select a Start Date',
  successfullyAddedFirmwareGroupsUser: 'Successfully added the user to the firmware group!',
  successfullyAddedFirmwareUser: 'Successfully added the user to the firmware!',
  successfullyAddedLabsUser: 'Successfully added the user to the current lab!',
  successfullyAddedPackage: 'Successfully added selected NuGet package to the lab.',
  successfullyAddedTestPlanUser: 'Successfully added the user to the test plan!',
  successfullyCreatedExperimentSteps: 'Successfully created experiment steps.',
  successfullyCreatedFirmwareGroup: 'Successfully created firmware group.',
  successfullyCreatedTestPlanGroup: 'Successfully created testplan group.',
  successfullyDeleteFeed: 'Successfully deleted the NuGet feed.',
  successfullyDeleteUser: 'Successfully deleted the user.',
  successfullyFileUploaded: ' File uploaded Successfully',
  successfullySavedSignOff: 'Successfully saved sign off details.',
  successfullyUpdatedFirmware: 'Successfully updated firmware.',
  successfullyUpdatedFirmwareGroup: 'Successfully updated firmware group.',
  successfullyUpdatedTestPlanGroup: 'Successfully updated testplan group.',
  supportEmailId: 'crc-support@microsoft.com',
  tagAddedSuccessfully: 'System tag added successfully.',
  tagDeleted: 'System tag deleted.',
  tagExist: 'The tag exists for this machine already!',
  tagInstruction: 'Select from the dropdown list or hit enter once typed.',
  testCasesHaveErrors: 'One or more Test Cases have errors. Please check again.',
  testPlanDeleteSuccess: 'Test Plan deleted successfully!',
  testPlanEditedSuccessfully: 'Test Plan Updated Successfully.',
  testPlanFileError: 'Error: Please check the Test Plan format. The right format -> Tests: [{TestCommand: ,TestName: New}]',
  testPlanGroupDeleteSuccess: 'Selected test plan group deleted successfully!',
  testPlanNotSupported: 'Error: Test plan format is no longer supported. Please delete or update this test plan.',
  testPlanSubmittedSuccessfully: 'Test Plan submitted successfully.',
  textCopied: 'Text copied!',
  typeExactLabNameToDelete: 'Please type the exact lab name to delete!',
  underConstruction: 'This page is under construction.',
  updateIsAvailable: 'Update is available. New Version: ',
  updateLabConfirmation: 'Are you sure to update this lab?',
  uploadTestCaseFileHere: 'Upload test case file here or move to',
  userAdded: 'User added successfully!',
  userCreated: 'User Created Successfully!',
  viewFile: 'View File for Details',
  viewSummaryDesc:
    'View high level summary of all the system event csv files available in the test plan which contains first, last and most frequent records.\nIf there are no warning, error or critical records, there will not be any files available.',
  virtualLabCreate: 'Successfully created virtual lab with selected machines.',
  virtualLabUpdate: 'Successfully updated virtual lab with selected machines.',
  welcomeToPartnersPortal: 'Welcome to CRC Labs Partners Portal',
  youDoNotHaveAnyTestPlan: 'It looks like you do not have any Test Plan yet.',
  youDoNotHaveLabs: 'It looks like you do not have any labs yet.',
};

const ModalHead = {
  signOffDetails: 'SignOff Details',
  toolkitResultDetails: 'ToolKit Result Details',
  viewTestLogs: 'View Test Logs',
};

const TableColumns = {
  agentId: 'agentId',
  agentStatus: 'agentStatus',
  created: 'created',
  definition: 'Definition',
  definitionStatus: 'Status',
  experimentDefinitionId: 'ExperimentDefinitionId',
  experimentName: 'ExperimentName',
  id: 'id',
  ipAddress: 'ipAddress',
  lastModified: 'lastModified',
  name: 'name',
  status: 'status',
  timestamp: 'timestamp',
};

const TableHead = {
  ExperimentInstances: 'Running Experiment Instances',
  RunningExperiments: 'Running Experiments',
  SubCategory: 'Sub Category',
  action: 'Action',
  actions: 'Actions',
  agentId: 'Agent Id',
  agentStatus: 'Agent Status',
  allResults: 'All Results',
  backupOwner: 'Backup Owner',
  build: 'Build',
  context: 'Context',
  created: 'Created',
  createdBy: 'Created By',
  description: 'Description',
  duration: 'Duration',
  ended: 'Ended',
  endTime: 'End Time',
  executedBy: 'Executed By',
  experimentDefinitionId: 'Experiment Definition Id',
  experimentInstanceId: 'Experiment Instance Id',
  experimentName: 'Experiment Name',
  firmwareVersion: 'Firmware Version',
  hardwareInfo: 'Hardware Info',
  hostName: 'Host Name',
  ipAddress: 'IP Address',
  labId: 'Lab Id',
  labName: 'Lab Name',
  lastExecutedBy: 'Last Executed By',
  lastExecutedTime: 'Last Executed Time',
  lastHeartBeat: 'Last HeartBeat',
  lastModified: 'Last Modified',
  lastRunStatus: 'Last Run Status',
  lastUpdated: 'Last Updated',
  macAddress: 'MAC Address',
  machineStatus: 'Machine Status',
  message: 'Message',
  messageDate: 'Message Date',
  messageTitle: 'Message Title',
  messageType: 'Message Type',
  model: 'Model',
  modelName: 'Model Name',
  myLabResults: 'My Lab Results',
  myResults: 'My Results',
  name: 'Name',
  notes: 'Notes',
  numOfExecution: 'Number of Executions',
  osImage: 'OS Image',
  osImageName: 'OS Image Name',
  parentLabName: 'Parent Lab',
  path: 'Path',
  processor: 'Processor',
  responseData: 'Response Data',
  responseTime: 'Response Time',
  responseType: 'Response Type',
  result: 'Result',
  reviewCreate: 'Review + Create',
  ruleExpression: 'Expression',
  serialNumber: 'Serial Number',
  signOff: 'Sign Off',
  signedOff: 'Signed Off',
  sku: 'SKU',
  slotNumber: 'Slot',
  socIPAddress: 'SoC IP Address',
  socInformation: 'SoC Info',
  socMACAddress: 'SoC MAC Address',
  started: 'Started',
  state: 'State',
  startTime: 'Start Time',
  status: 'Status',
  statusDetail: 'Status Details',
  stepName: 'Step Name',
  tags: 'Tags',
  testCaseName: 'Test Case Name',
  testCommand: 'Test Command',
  testName: 'Test Name',
  testOwner: 'Test Owner',
  testPassCreationTime: 'TestPass Creation Time',
  testPlan: 'Test Plan',
  testPlanCase: 'Test Plan Case',
  testPlanName: 'Test Plan Name',
  testPlanType: 'Test Plan Type',
  testSuite: 'Test Suite',
  timeStamp: 'Timestamp',
  type: 'Type',
  update: 'Update',
  updated: 'Updated',
  version: 'Version',
  virtualLab: 'Virtual Lab',
  workItem: 'Work item',
};

enum TabMemoryKeys {
  LOGS_VIEW_PAGE = 'logs-view',
  RESULTS_EXPLORER_PAGE = 'results-explorer-template',
  RESULTS_TRIAGE_PAGE = 'results-triage',
}

const TabPaneKeys = {
  experimentInsights: 'experimentInsights',
  rulesPage: 'rulesPage',
};

const TestExecutionStatus = {
  completed: 'Completed',
  loading: 'Loading Status..',
  summaryError: 'No Summary Found',
};

const RedirectURL = {
  serverBuildingBlockDetails:
    'https://microsoft.sharepoint.com/:x:/r/teams/CSIPPRForum/_layouts/15/doc2.aspx?sourcedoc=%7B804682AD-DF95-4600-A08F-24CB5AC9D4D6%7D&file=Building%20Block%20Naming_Convention_Rev4.1.xlsx&action=default&mobileredirect=true&share=IQGtgkaAld8ARqCPJMtaydTWAcGb1QgB08144bx6MG14DqM&isSPOFile=1&cid=7ffb0d6b-4db1-4e4f-9334-36bbb76c588a',
  partNumberDetails:
    'https://microsoft.sharepoint.com/:o:/r/teams/CRC/_layouts/15/Doc.aspx?sourcedoc=%7B1d644973-eaeb-4636-9516-4b21f5270338%7D&action=edit&wd=target(Automation%20Content%2FBlades%20For%20Team%20Use.one%7Cb4b278be-25cd-41e0-aafb-f041d291c9bf%2FBlades%20for%20Regression%20Use%7C936abfe6-0381-42ae-8b8a-90f17c89a354%2F)&share=IgFzSWQd6-o2RpUWSyH1JwM4AY5V0d5AuIDOEkMgwIwctPM',
};

const Defaults = {
  LAB_ADMIN: 'LabAdmin',
};

const TooltipInfo = {
  IP_ADDRESS: 'The ip address of the machine',
  MAC_ADDRESS: 'The mac address of the machine',
  NEW_IP_ADDRESS: 'New ip address of the machine',
  NEW_MAC_ADDRESS: 'New mac address of the machine',
  USERNAME: 'The username of the machine',
  PASSWORD_REFERENCE: 'The name of the secret in key vault that is the password to the machine',
};

export {
  AreaLabels,
  BringYourOwnRules,
  Buttons,
  Colors,
  ColorsForStatus,
  DateFormat,
  Defaults,
  ErrorMessages,
  FilterKeys,
  HealthDiffInMins,
  Labels,
  Messages,
  ModalHead,
  RedirectURL,
  TableColumns,
  TableHead,
  TabMemoryKeys,
  TabPaneKeys,
  TooltipInfo,
  TestExecutionStatus,
};
