import { FontWeights, getTheme, mergeStyles, mergeStyleSets } from '@fluentui/react';

export const theme = getTheme();

export const baseContainer = {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'stretch',
};

export const contentStyles = mergeStyleSets({
  container: baseContainer,
  firmwareContainer: {
    ...baseContainer,
    width: '40vw',
  },
  fileSearchContainer: {
    ...baseContainer,
    minWidth: '45vw',
  },
  min35Container: {
    ...baseContainer,
    minWidth: '35vw',
  },
  batchOperationContainer: {
    ...baseContainer,
    minWidth: '60vw',
  },
  nugetContainer: {
    ...baseContainer,
    maxWidth: '70vw',
  },
  tagContainer: {
    ...baseContainer,
    width: '50vw',
  },
  DetailContainer: {
    ...baseContainer,
    width: '70vw',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 10px',
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '35px 10px 10px 10px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  buttonsDiv: {
    paddingTop: '50px',
  },
  footer: {
    position: 'fixed',
    bottom: '16px',
  },
});

export const toggleStyles = { root: { marginBottom: '20px' } };

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const wrapperClassName = mergeStyles({
  display: 'flex',
  selectors: {
    '& > *': { marginRight: '20px' },
    '& .ms-ComboBox': { maxWidth: '300px' },
  },
});

export const classNames = mergeStyleSets({
  iconContainer: {
    position: 'relative',
    margin: '0 4px',
    height: 32,
    width: 14,
  },
  iconContainerFluent: {
    position: 'relative',
    margin: '0 7px',
    height: 50,
    width: 14,
  },
  logoIcon: {
    position: 'absolute',
    left: 0,
    right: 5,
    width: 17,
    top: 10,
    color: theme.palette.themeDarkAlt,
  },
  logoIconBigger: {
    position: 'absolute',
    left: 0,
    right: 20,
    width: 35,
    top: 8,
    color: theme.palette.themeDarkAlt,
  },
  logoIconMd: {
    position: 'absolute',
    left: 0,
    right: 20,
    width: 23,
    top: 8,
    color: theme.palette.themeDarkAlt,
  },
  logoIconFluent: {
    position: 'absolute',
    left: 0,
    right: 20,
    width: 35,
    top: 8,
    fontSize: 'large',
    color: theme.palette.themeDarkAlt,
  },
  logoFillIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
    color: theme.palette.white,
  },
});
