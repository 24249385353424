import React from 'react';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { TableHead } from '@/constants/LabsConstants';

import { TagComponent } from './TagComponent';

const rulesMap = [];

export const RulesTable = (props: any) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>();

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);

    const workflow = props.workflow;
    const rulesIds = rulesData.filter((rule) => selectedRowKeys.includes(rule.key)).map((item) => item.key);

    if (rulesIds.length > 0) {
      rulesMap[workflow as string] = rulesIds;
    } else if (rulesMap[workflow as string]) {
      delete rulesMap[workflow as string];
    }

    props.setRules(rulesMap);
  };

  React.useEffect(() => {
    if (props.reset) {
      setSelectedRowKeys([]);
    }
  }, [props.reset]);

  React.useEffect(() => {
    const checkbox = document.querySelectorAll('.ant-checkbox-input');

    checkbox.forEach((button) => {
      button.setAttribute('aria-label', 'row-checkox');
    });
  });

  const rulesData = props.rules.map((rule) => ({
    key: rule.Id,
    name: rule.Name,
    description: rule.Description,
    expression: rule.Expressions,
  }));

  const columns: ColumnsType<any> = [
    {
      title: TableHead.name,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <span>{text}</span>,
      width: '30%',
    },
    {
      title: TableHead.description,
      dataIndex: 'description',
      key: 'description',
      width: '30%',
    },
    {
      title: TableHead.ruleExpression,
      key: 'Expression',
      render: (rule) => <TagComponent ruleExpression={rule.expression} />,
      width: '40%',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (e) => {
      onSelectChange(e);
    },
  };
  const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;

  return (
    <div>
      <div>
        <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
      </div>
      <Table bordered rowSelection={rowSelection} columns={columns} dataSource={rulesData} pagination={false} />
    </div>
  );
};
