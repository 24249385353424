const EnvironmentSuffix = {
  CRC_PORTAL: 'crcportal',
  PROD_01: 'prod01',
  STAGING: '-staging',
  DEV: '-dev',
  LOCALHOST: 'localhost',
};

const EnvironmentNames = {
  PROD: 'production',
  STAGING: 'staging',
  DEV: 'development',
  VEGA_DEV: 'vega-dev',
  DEVELOPER_SLOT: 'developer-slot',
};

const StorageDevEnvKey = 'dev-env';

export { EnvironmentSuffix, EnvironmentNames, StorageDevEnvKey };
