import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { PowerBIEmbedInfo } from '@/types/_air/PowerBIEmbedInfo';
import { backendEndpoint } from '@/utils/Env';

class PowerBIService extends BaseRequestService {
  async getPowerBiInfo(): Promise<PowerBIEmbedInfo> {
    const { data } = await this.get('powerbiembed/GetEmbedInfo', null, { withCredentials: true });

    return data;
  }
}

export const powerBiService = new PowerBIService(backendEndpoint);
