import { action, makeObservable, observable } from 'mobx';

import { RootStore } from '@/stores/RootStore';

class DocumentationStore {
  protected rootStore: RootStore;

  public endDate: Date = new Date();
  public sasToken = '';

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      sasToken: observable,
      endDate: observable,

      // Actions modify the state.
      setEndDate: action,
      setSasToken: action,
    });

    this.rootStore = rootStore;
  }

  public setSasToken = (token: string) => {
    this.sasToken = token;
  };

  public setEndDate = (date: Date) => {
    this.endDate = new Date(date);
  };
}

export default DocumentationStore;
