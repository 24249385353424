import React from 'react';

import { FilterOptions, Namespaces as NS } from '@/constants/SystemConstants';

const filterText = <T extends { name?: string }>(rows: T[], search: string): T[] => {
  if (!search.trim()) {
    return rows;
  }

  return rows.filter((row: T) => {
    const name = row.name?.toLowerCase() || '';

    return name.includes(search.trim().toLowerCase());
  });
};

const filterStatus = <T extends { status?: string }>(rows: T[], status: string[]): T[] => {
  if (status.length === 0) {
    return rows;
  }

  return rows.filter((row: T) => {
    const matches = status.includes(row.status);

    return matches;
  });
};

const filterStepType = <T extends { stepType?: string }>(rows: T[], stepType: string[]): T[] => {
  if (stepType.length === 0) {
    return rows;
  }

  return rows.filter((row: T) => {
    const matches = stepType.includes(row.stepType);

    return matches;
  });
};

const filterDateField = <T extends { [key: string]: any }>(
  field: string,
  rows: T[],
  startDate,
  endDate,
  timeRange: string,
): T[] => {
  if (!field || timeRange === FilterOptions.ALL) {
    return rows;
  }

  return rows.filter((row: T) => {
    const lastModified = new Date(row[field as string]);
    const matches = lastModified >= startDate && lastModified <= endDate;

    return matches;
  });
};

const filterDateAgent = <T extends { [key: string]: any }>(rows: T[], startDate, endDate, timeRange: string): T[] => {
  if (!timeRange || timeRange === FilterOptions.ALL) {
    return rows;
  }

  return filterDateField('endTime', rows, startDate, endDate, timeRange);
};

export { filterText, filterStatus, filterStepType, filterDateField, filterDateAgent };
