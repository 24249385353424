import React from 'react';
import { Icon } from '@fluentui/react';
import { Embed, IReportEmbedConfiguration, models, Report, service } from 'powerbi-client';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';

import { IconNames, Messages, TelemetryMessages } from '@/constants/AirConstants';
import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { SeverityLevel } from '@/services/_air/monitoring/SeverityLevel';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { powerBiService } from '@/services/_air/request-services';
import { ThemeSchema } from '@/types/_air/Theme';
import { useUserSettings } from '@/utils/_air/Hooks/UseUserSettings';

import darkThemeJson from './DarkTheme.json';

import '@/styles/Air.css';

interface Props {
  experimentName: string;
  revision: string;
  page: string;
}

const pageNameMap = new Map([
  ['SUM', 'ReportSection'],
  ['PER', 'ReportSection31f663ae4e5ec46dae11'],
  ['AVA', 'ReportSection9e899fdbec367eb2e023'],
  ['REL', 'ReportSection71ef0ad8aa605b095022'],
  ['INF', 'ReportSection482e0ed818501969a90c'],
  ['OTH', 'ReportSectionb083f7bd6e30993edb46'],
  ['Nodelg', 'ReportSectiond2a2feadaeeb8106641b'],
  ['Debug', 'ReportSection99baaac6b7e253a32b07'],
]);

export const ReportDashboard = ({ experimentName, revision, page }: Props): JSX.Element => {
  const [isError, setIsError] = React.useState(false);
  const [report, setReport] = React.useState(undefined as unknown as Report);

  const summaryPageName = pageNameMap.get(page);
  const {
    state: {
      userSettings: { theme },
    },
  } = useUserSettings();

  const [embedConfig, setEmbedConfig] = React.useState<IReportEmbedConfiguration>({
    type: 'report',
    tokenType: models.TokenType.Embed,
    pageName: summaryPageName,
    settings: {
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
    theme: theme === ThemeSchema.Dark ? { themeJson: darkThemeJson } : undefined,
    viewMode: models.ViewMode.View,
    permissions: models.Permissions.Read,
    filters: [
      {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'Filters_ExperimentData',
          column: 'ExperimentName',
        },
        operator: 'In',
        values: [experimentName],
        filterType: models.FilterType.Basic,
      },
      {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'Filters_ExperimentData',
          column: 'Revision',
        },
        operator: 'In',
        values: [revision],
        filterType: models.FilterType.Basic,
      },
    ],
  });

  const updateEmbedConfig = async () => {
    try {
      void monitoringService.logEvent(TelemetryMessages.powerBiTryToAcquireToken);

      const embedReportInfo = await powerBiService.getPowerBiInfo();

      void monitoringService.logEvent(TelemetryMessages.powerBiAcquireTokenSuccess);

      setEmbedConfig((prevConfig) => ({
        ...prevConfig,
        type: embedReportInfo.Type,
        accessToken: embedReportInfo.EmbedToken.Token,
        embedUrl: embedReportInfo.EmbedReport.EmbedUri,
        id: embedReportInfo.EmbedReport.ReportId,
      }));
    } catch (error) {
      void monitoringService.logEvent(TelemetryMessages.powerBiAcquireTokenFailed, SeverityLevel.Error, 'error', error);

      notificationService.showError('Unable to show report. Cannot retrieve access token.');
    }
  };

  const switchTheme = React.useCallback((): void => {
    if (report) {
      report.iframe.setAttribute('aria-label', 'Report frame');

      if (theme === ThemeSchema.Dark) {
        void report.applyTheme({ themeJson: darkThemeJson });
      } else {
        void report.resetTheme();
      }
    }
  }, [report, theme]);

  const showError = (error: service.ICustomEvent<any> | undefined): void => {
    const message = error?.detail?.detailedMessage?.detailedMessage ?? error?.detail?.message ?? '';

    notificationService.showError(`Unable to show report. ${message}`);
    setIsError(true);
  };

  const renderReport = (): JSX.Element => (
    <PowerBIEmbed
      embedConfig={embedConfig}
      cssClassName="power-bi-dashboard"
      getEmbeddedComponent={(embeddedReport: Embed) => setReport(embeddedReport as Report)}
      eventHandlers={new Map<string, EventHandler>([['error', showError]])}
    />
  );

  React.useEffect(() => {
    switchTheme();
  }, [switchTheme]);

  React.useEffect(() => {
    void updateEmbedConfig();
  }, []);

  return isError ? (
    <div className="fetch-error">
      <Icon iconName={IconNames.error} />
      <span className="error-message">{Messages.noDataFound}</span>
    </div>
  ) : (
    <>
      <div className="power-bi-container">{renderReport()}</div>
    </>
  );
};
