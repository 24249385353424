import { GetEntryRequestQueryParams } from '@/components/Cosmos/CosmosTypes';
import { PortalAliases } from '@/constants/SystemConstants';
import { GANYMEDE_LINKS } from '@/routes/RouteLinks';
import { cosmosRequestService } from '@/services/request-services/CosmosRequestService';

const databaseId = 'Settings';
const dataId = 'global-id';
const dataKey = 'ganymeda-data';

const loadData = async (isOfflineMode?: boolean) => {
  // In Offline Mode, we are getting Navigation Links locally.
  if (isOfflineMode) {
    const PORTAL_NAVLINKS = {
      [PortalAliases.GANYMEDE]: GANYMEDE_LINKS,
      [PortalAliases.AIR]: GANYMEDE_LINKS,
      [PortalAliases.LABS]: GANYMEDE_LINKS,
    };

    return PORTAL_NAVLINKS;
  }

  // When not in Offline Mode, the Server is providing Nagivation Link information.
  // This means that we need to be updating our system with the correct
  // Navigation data, especially when it updates. This will be more important
  // in the future, when we support multiple environments.
  const queryParams: GetEntryRequestQueryParams = {
    databaseId: databaseId,
    id: dataId,
    key: dataKey,
  };

  const entry = await cosmosRequestService.getEntry(queryParams);

  return JSON.parse(entry);
};

export default loadData;
