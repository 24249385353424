import { action, makeObservable, observable } from 'mobx';

import { RootStore } from '@/stores/RootStore';

import { AnnotationsChartData } from './ErrorsGraphType';

class ErrorsGraphStore {
  public selectedAnnotation: AnnotationsChartData = { timeGenerated: '' };
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      selectedAnnotation: observable,
      setSelectedAnnotation: action,
    });
  }

  public getSelectedAnnotation = (): AnnotationsChartData => {
    return this.selectedAnnotation;
  };

  public setSelectedAnnotation = (selectedAnnotation: AnnotationsChartData) => {
    this.selectedAnnotation = selectedAnnotation;
  };
}

export default ErrorsGraphStore;
