import React from 'react';

const dropdown = {
  label: { fontWeight: 'normal' },
  title: { maxWidth: '200px' },
  caretDownWrapper: { height: '30px', lineHeight: '30px', marginTop: '0px' },
  caretDown: { height: '30px', lineHeight: '30px' },
};

const envSelectorCombobox = {
  label: { fontWeight: 'normal' },
  caretDownWrapper: { height: '30px', lineHeight: '30px', top: '1px', paddingTop: '0px' },
  caretDown: { height: '30px', lineHeight: '30px' },
  optionsContainerWrapper: {
    maxHeight: '50vh',
    minWidth: '346px',
  },
};

const timeZoneDropdown = {
  label: { fontWeight: 'normal' },
  caretDownWrapper: { height: '30px', lineHeight: '30px', top: '1px', paddingTop: '0px' },
  caretDown: { height: '30px', lineHeight: '30px' },
  dropdownItemsWrapper: {
    maxHeight: '50vh',
  },
};

const styles = { dropdown, envSelectorCombobox, timeZoneDropdown };

export default styles;
