import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import ResultExplorerViewController from '@/components/ResultExplorer/ResultExplorerViewController';
import ResultExplorerViewModel from '@/components/ResultExplorer/ResultExplorerViewModel';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Results } from '@/constants/TranslationConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const ResultExplorerHomeFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const resultExplorerViewModel = React.useMemo(() => new ResultExplorerViewModel(rootStore), [rootStore]);

  const title = t(Results.RESULT_EXPLORER, { ns: NS.RESULTS });
  const parentGlobalMessage = globalMessages.filter((i) => !i.showInPopup);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{parentGlobalMessage}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.RESULT_EXPLORER]}>{title}</PageHeader>
          <PageContent scrollable={false}>
            <div className="fullscreen">
              <ResultExplorerViewController viewModel={resultExplorerViewModel}></ResultExplorerViewController>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ResultExplorerHome = observer(ResultExplorerHomeFC);

export default ResultExplorerHome;
