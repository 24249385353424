import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, DefaultButton, DetailsRow, IconButton, Modal, SelectionMode } from '@fluentui/react';
import { IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import MonacoEditor from '@monaco-editor/react';
import { t } from 'i18next';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import MachineSelectViewController from '@/components/MachineSelect/MachineSelectViewController';
import MachineSelectViewModel from '@/components/MachineSelect/MachineSelectViewModel';
import PreviewExperimentViewController from '@/components/PreviewExperiment/PreviewExperimentViewController';
import PreviewExperimentViewModel from '@/components/PreviewExperiment/PreviewExperimentViewModel';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import AIRExperimentPublishViewController from '@/partials/_air/ExperimentPublish/ExperimentPublishTemplateViewController';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { ExperimentEditorVCType } from './ExperimentEditorTypes';

import './ExperimentEditor.css';
import styles from './ExperimentEditor.module.css';

// NavigationMenuViewController : a sub ViewController for the File navigation menu above the experiment editor.
const ExperimentEditorTemplateFC: React.FC<ExperimentEditorVCType> = (props: ExperimentEditorVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { isPartnerMode } = appSettingsStore;
  const { addGlobalMessage, globalMessages } = systemMessageStore;

  const {
    labId,
    hideTemplate,
    toggleHideTemplate,
    hidePublish,
    hidePreview,
    labToggle,
    toggleHidePublish,
    toggleHidePreview,
    templates,
    templateColumns,
    loadingTemplates,
    loadingExperiment,
    loadTemplate,
    filterItems,
    farItems,
    hideConfirmModal,
    isConfirmModalOpen,
    confirmedAction,
    editorMount,
    handleEditorChange,
    monacoEditorOptions,
  } = props;

  const machineSelectViewModel = new MachineSelectViewModel(rootStore);
  const previewExperimentViewModel = new PreviewExperimentViewModel(rootStore);
  const confirmationText = t('override-confirmation', { ns: NS.EDITOR });

  const onRenderRow = (rowProps: IDetailsRowProps | undefined): JSX.Element | null => {
    if (rowProps) {
      const item = rowProps.item;

      return (
        <div onClick={() => loadTemplate(item.id)}>
          <DetailsRow {...rowProps} />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="fullscreen ee-py4">
        <div className="fullscreen ee-indent">
          <PageCommandBar items={farItems}></PageCommandBar>
          <PageDivider />
          <PageFilterBar items={filterItems}></PageFilterBar>
          {loadingExperiment && <LoadingSpinner />}
          <MonacoEditor
            height="100%"
            defaultLanguage="json"
            onMount={editorMount}
            onChange={handleEditorChange}
            options={monacoEditorOptions}
          />
          <DialogBox
            title={t('experiment-templates', { ns: NS.DEFAULT })}
            subtext={''}
            minWidth={'75%'}
            body={
              <div className={styles['height']}>
                {loadingTemplates && <LoadingSpinner />}
                {!loadingTemplates &&
                  (templates.length > 0 ? (
                    <TableViewViewController
                      items={templates}
                      columns={templateColumns}
                      enableToolBar={false}
                      onRenderRow={onRenderRow}
                      selectionMode={SelectionMode.None}
                      checkboxVisibility={CheckboxVisibility.hidden}
                    />
                  ) : (
                    <Trans ns={NS.EDITOR as Namespace}>no-templates</Trans>
                  ))}
              </div>
            }
            footer={<DefaultButton onClick={toggleHideTemplate} text={t('close', { ns: NS.COMMON })} />}
            hidden={hideTemplate}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHideTemplate}
          />
          <DialogBox
            title={t('queue-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={<MachineSelectViewController viewModel={machineSelectViewModel} labId={labId} closeModal={toggleHidePublish} />}
            hidden={!(labToggle && !hidePublish)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePublish}
          />
          <DialogBox
            title={t('queue-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={<AIRExperimentPublishViewController />}
            footer={<DefaultButton onClick={toggleHidePublish} text={t('close', { ns: NS.COMMON })} />}
            hidden={!(!labToggle && !hidePublish)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePublish}
          />
          <DialogBox
            title={t('preview-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={
              <PreviewExperimentViewController
                viewModel={previewExperimentViewModel}
                closeModal={toggleHidePreview}
                toggleHidePublish={toggleHidePublish}
              />
            }
            hidden={!(labToggle && !hidePreview)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePreview}
          />
        </div>
      </div>
      {isConfirmModalOpen && (
        <Modal
          className="labs-wrapper"
          titleAriaId="editor-confirmation"
          isOpen={isConfirmModalOpen}
          isBlocking={false}
          containerClassName={contentStyles.container}
          onDismiss={hideConfirmModal}
        >
          <div className={contentStyles.header}>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: SystemIcons.CANCEL }}
              ariaLabel={AreaLabels.closePopupModal}
              onClick={isPartnerMode}
            />
          </div>
          <div className={contentStyles.body}>
            <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
            <h5 className={styles['confirm-text']}>{confirmationText}</h5>
            <br />
            <div className="text-center">
              <DefaultButton
                className={`${styles['button']} bg-danger light-text`}
                onClick={() => {
                  confirmedAction();
                }}
              >
                {Buttons.yes}
              </DefaultButton>
              <DefaultButton classStyles={styles['button']} onClick={hideConfirmModal}>
                {Buttons.cancel}
              </DefaultButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const ExperimentEditorTemplate = observer(ExperimentEditorTemplateFC);

export default ExperimentEditorTemplate;
