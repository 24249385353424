import React from 'react';

import BarLineGraphTemplate from './BarLineGraphTemplate';
import { BarLineChartProps, BarLineGraphTemplateType, BarLineGraphType } from './BarLineGraphType';

const BarLineGraphViewController: React.FC<BarLineGraphType> = (props: BarLineGraphType): React.ReactElement => {
  const chartProps: BarLineChartProps = props.chartProp;

  const templateProps: BarLineGraphTemplateType = {
    data: props.data,
    chartProp: chartProps,
  };

  return <BarLineGraphTemplate {...templateProps} />;
};

export default BarLineGraphViewController;
