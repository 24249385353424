import { MessageBarType } from '@fluentui/react';
import ls from 'local-storage';

import { Messages } from '@/constants/LabsConstants';
import { FailGroupIds, Files, SuccessGroupIds } from '@/constants/SystemConstants';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { backendEndpoint } from '@/utils/Env';
import MsalAuthorization from '@/utils/MsalAuthorization';

const downloadUrl = async (url: any, filename: string, systemMessageStore: SystemMessageStore) => {
  const xhr = new XMLHttpRequest();
  const outputFile = Files.LOGS_ZIP;

  xhr.open('GET', url);
  xhr.onreadystatechange = handler;
  xhr.responseType = 'blob';

  if (filename === outputFile) {
    xhr.setRequestHeader('Authorization', `Bearer ${(ls as any).get('resultsToken')}`);
  } else {
    const token = await MsalAuthorization.getToken();

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  xhr.send();

  function handler(this: any) {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        const element = document.createElement('a');

        element.setAttribute('href', URL.createObjectURL(this.response));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        element.click();
      } else {
        const { addGlobalMessage } = systemMessageStore;

        const failMessage: SystemMessageType = {
          message: Messages.downloadFailure + ' ' + url,
          type: MessageBarType.error,
          groupId: FailGroupIds.LAB_DOWNLOAD,
        };

        addGlobalMessage(failMessage);
      }
    }
  }
};

export const labDownloadHelper = (isExe: boolean, labId: any, systemMessageStore: SystemMessageStore) => {
  const baseUrl = backendEndpoint;

  if (isExe) {
    const { addGlobalMessage } = systemMessageStore;

    const infoMessage: SystemMessageType = {
      message: Messages.downloadLabWaitTimeRequest,
      type: MessageBarType.info,
      groupId: SuccessGroupIds.LAB_DOWNLOAD,
    };

    addGlobalMessage(infoMessage);
    downloadUrl(`${baseUrl}/labs/${labId}/installer/LabInstaller.exe`, 'LabInstaller.exe', systemMessageStore);
  } else {
    downloadUrl(`${baseUrl}/labs/${labId}/installer/CRCLabsSetup.msi`, 'CRCLabsSetup.msi', systemMessageStore);
  }

  downloadUrl(`${baseUrl}/labs/${labId}/installer/config`, 'CRCLabsConfig.json', systemMessageStore);
};

export const fetchAfterTimeout = (method: any) => {
  const timeOut = 120000;

  setTimeout(() => {
    method();
  }, timeOut);
};
