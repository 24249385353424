import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { CreateLabTemplate } from './CreateLabTemplate';
import { CreateLabVMType } from './CreateLabType';

interface CreateLabViewControllerProps {
  viewModel: CreateLabVMType;
}

const CreateLabViewControllerFC: React.FC<CreateLabViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { createLabStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { doReset } = createLabStore;

  // Prop Const
  const { getCompanies, getOSImages, getLabUsers, onCompanyChange, saveLab, isValidEmailAddress } = viewModel;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    getCompanies();
    getOSImages();
    getLabUsers();
    doReset();
  }, []);

  return (
    <CreateLabTemplate
      onCompanyChange={onCompanyChange}
      saveLab={saveLab}
      isValidEmailAddress={isValidEmailAddress}
    ></CreateLabTemplate>
  );
};

export const CreateLabViewController = observer(CreateLabViewControllerFC);
