import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import { SpinLocation, SpinProps } from './SpinnerStyles';

import './LoadingSpinner.css';

export const LoadingSpinner = (spinProps: SpinProps): any => {
  // NOTE: See https://developer.microsoft.com/en-us/fluentui#/controls/web/spinner#SpinnerSize
  const size = spinProps.size ?? SpinnerSize.large;

  if (spinProps.location === SpinLocation.BelowTableHeader) {
    return <Spinner className="tableSpinnerRoot" size={size} />;
  } else if (spinProps.location === SpinLocation.BelowTableFilter) {
    return <Spinner className="tableFilterSpinnerRoot" size={size} />;
  } else if (spinProps.location === SpinLocation.BelowAntTableHeader) {
    return {
      spinning: spinProps.spinning,
      indicator: <Spinner className="tableSpinnerRoot" size={size} />,
    };
  }

  return <Spinner className="defaultSpinnerRoot" size={size} />;
};
