import React from 'react';
import { observer } from 'mobx-react-lite';

import DocumentationTemplate from '@/components/Documentation/DocumentationTemplate';
import {
  ActiveIdType,
  AllDocumentContentsType,
  AllLinksType,
  CachedSectionsType,
  DocumentationVMType,
  DocumentType,
  LinksListType,
} from '@/components/Documentation/DocumentationTypes';
import { scrollableContainer } from '@/constants/ScrollablePageConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';

interface DocumentationViewControllerProps {
  viewModel: DocumentationVMType;
}

const DocumentationViewControllerFC: React.FC<DocumentationViewControllerProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDebugMode } = appSettingsStore;

  const [documents, setDocuments] = React.useState<DocumentType[]>([]);
  const [documentContents, setDocumentContents] = React.useState<AllDocumentContentsType>({});
  const [links, setLinks] = React.useState<AllLinksType>({});
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [activeId, setActiveId] = React.useState<ActiveIdType>();
  const [defaultActiveId, setDefaultActiveId] = React.useState<ActiveIdType>({});

  const { getSasToken, listDocuments, getDocument, transformContent, extractLinksFromMarkdown, url, pageKey } = viewModel;

  const cancellationToken = useCancellationToken();
  const sectionsCache: { [url: string]: CachedSectionsType[] } = {};
  const scrollCache: { [url: string]: HTMLElement | null } = {};

  React.useEffect(() => {
    const fetchDocuments = async () => {
      const token: string = await getSasToken(cancellationToken);
      const docs: DocumentType[] = await listDocuments(url, token);

      setDocuments(docs);
      isDebugMode && console.log('[DocumentationViewController] useFetchDocuments() received.');

      // Pre-fetch all document contents
      docs.forEach(async (doc) => {
        isDebugMode && console.log('[DocumentationViewController] getDocument() attempted: ', doc.url);
        const content: string = await getDocument(doc.url, token);
        const transformedContent: string = transformContent(content, url, token);
        const docLinks: LinksListType[] = extractLinksFromMarkdown(transformedContent);

        setDocumentContents((prev: AllDocumentContentsType) => ({ ...prev, [doc.url]: transformedContent }));
        setLinks((prev: AllLinksType) => ({ ...prev, [doc.url]: docLinks }));
        setActiveId((prev: ActiveIdType) => ({ ...prev, [doc.url]: docLinks[0].id }));
        setDefaultActiveId((prev: ActiveIdType) => ({ ...prev, [doc.url]: docLinks[0].id }));
      });
    };

    isDebugMode && console.log('[DocumentationViewController] useFetchDocuments() called on', url);
    fetchDocuments();
    setLoaded(true);
  }, []);

  const handleScroll = React.useCallback(
    (url: string) => {
      if (!(url in scrollCache)) {
        scrollCache[url as string] = document.getElementById(scrollableContainer);
      }

      if (!(url in sectionsCache)) {
        sectionsCache[url as string] = links[url as string].map((link: LinksListType) => {
          return {
            id: link.id,
            element: document.getElementById(link.id),
          };
        });
      }

      const currentSection: CachedSectionsType[] = sectionsCache[url as string];
      let currentActive = '';

      for (let i = currentSection.length - 1; i >= 0; i--) {
        const section = currentSection[i as number];
        const { id, element } = section;

        if (scrollCache[url as string].scrollTop >= element.offsetTop) {
          currentActive = id;
          break;
        }
      }

      setActiveId({ [url]: currentActive });
    },
    [scrollCache, sectionsCache, links, setActiveId],
  );

  const doScroll = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, link: LinksListType) => {
    e.preventDefault();
    const section = document.getElementById(link.id);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <DocumentationTemplate
      documents={documents}
      documentContents={documentContents}
      links={links}
      loaded={loaded}
      handleScroll={handleScroll}
      activeId={activeId}
      pageKey={pageKey}
      setActiveId={setActiveId}
      doScroll={doScroll}
      defaultActiveId={defaultActiveId}
    ></DocumentationTemplate>
  );
};

const DocumentationViewController = observer(DocumentationViewControllerFC);

export default DocumentationViewController;
