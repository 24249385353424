/* eslint-disable indent */
/* eslint-disable no-case-declarations */
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { ComboBox, Icon, IconButton, PrimaryButton, SelectableOptionMenuItemType } from '@fluentui/react';
import { IComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react/lib/ComboBox';
import { Button } from 'primereact/button';
import * as qs from 'query-string';

import { iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { Labels, Messages, RedirectURL } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { notificationService } from '@/services/_labs/NotificationService';
import { regressionTestPassRequestService } from '@/services/_labs/request-services/RegressionTestPassRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { RegressionTestPassResult } from '@/types/_labs/RegressionTestPass/RegressionTestPassResult';
import { RegressionTestPassResultList } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultList';
import { RegressionTestPassResultRequestFilters } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultRequestFilters';
import { RegressionTestPassSignOffResult } from '@/types/_labs/RegressionTestPass/RegressionTestPassSignOffResult';
import { ReleaseDetails } from '@/types/_labs/RegressionTestPass/ReleaseDetails';
import { HandleError } from '@/utils/_labs/HandleError';
import { NoRecordsFound } from '@/utils/_labs/NoRecordsFound';
import { isDevEnv } from '@/utils/Env';
import { copyToClipboard } from '@/utils/Helpers';
import MsalAuthorization from '@/utils/MsalAuthorization';

import { SaveSignOffResult } from './SaveSignOffResult';
import { TestResultDetails } from './TestResultDetails';
import { TestResultTable } from './TestResultTable';
import { ViewTestLogs } from './ViewTestLogs';

import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/Labs.css';

export const RegressionTestPassResults = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { accounts } = useMsal();
  const filterIcon = { iconName: SystemIcons.CLEAR_FILTER };
  const [toolkitResultDetailsModal, settoolkitResultDetailsModal] = React.useState(false);
  /** Sign off Test Modal start */
  const [signOffResultModal, setSignOffResultModal] = React.useState(false);
  const [signOffOwner, setSignOffOwner] = React.useState<string>('');
  const [signOffNotes, setSignOffNotes] = React.useState<string>('');
  const [isSubmit, setSubmit] = React.useState<boolean>(false);
  const [bugId, setBugId] = React.useState<string>('');
  const [newBugId, setNewBugId] = React.useState<string[]>([]);
  const [checkBugId, setCheckBugId] = React.useState<boolean>(false);
  const [invalidBugs, setInvalidBugs] = React.useState<string>('');
  const [selectedSignOffDropdown, setSelectedSignOffDropdown] = React.useState<string>();
  const [signOffDropdownOptions, setSignOffDropdownOptions] = React.useState<any>([]);
  /** Sign off Test Modal End */
  /** View Test logs Modal Start */
  const [viewTestLogsModal, setViewTestLogsModal] = React.useState<boolean>(false);
  const [resultCollectionGuid, setResultCollectionGuid] = React.useState<string>('');
  const [testName, setTestName] = React.useState<string>();
  /** View Test Logs Modal End */
  const tmpResultData = {
    VersionList: [],
    TestNameList: [],
    TestSuiteList: [],
    RunDateList: [],
    TestOwnerList: [],
    ProcessorList: [],
    TestResultList: [],
    SignOffList: [],
    ResultList: [],
    TotalRecords: 0,
  };
  const tmpReleaseDetails = {
    ReleaseVersion: '',
    ReleaseBuild: '',
    TotalCount: 0,
    SignOffCount: 0,
  };
  const tmpRegressionTestPassResultList = {
    Id: 0,
    TestPassCreationTime: '',
    ResultCollectionGUID: '',
    TestName: '',
    TestSuite: '',
    TestPlan: '',
    Result: '',
    TestOwner: '',
    BackupOwner: '',
    Processor: '',
    Path: '',
    Version: '',
    Build: '',
    Color: '',
    Bugs: [],
    BugId: '',
    Notes: '',
    SignOffUser: '',
  };
  const tmpRegressionTestPassResultRequestFilters = {
    Id: 0,
    Rows: 0,
    VersionList: [],
    BuildList: [],
    TestNameList: [],
    TestSuiteList: [],
    RunDateList: [],
    TestOwnerList: [],
    ProcessorList: [],
    TestResultList: [],
    SignOffList: [],
  };
  const [resultData, setResultData] = React.useState<RegressionTestPassResult>(tmpResultData);
  const [defaultReleaseDetails, setDefaultReleaseDetails] = React.useState<ReleaseDetails>(tmpReleaseDetails);
  const [releaseDetails, setReleaseDetails] = React.useState<ReleaseDetails>(tmpReleaseDetails);
  const [resultList, setResultList] = React.useState<RegressionTestPassResultList[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [resultDetails, setResultDetails] = React.useState<RegressionTestPassResultList>(tmpRegressionTestPassResultList);
  const [requestFilters, setRequestFilters] = React.useState<RegressionTestPassResultRequestFilters>(
    tmpRegressionTestPassResultRequestFilters,
  );
  const [pageFilters, setPageFilters] = React.useState<RegressionTestPassResultRequestFilters>(
    tmpRegressionTestPassResultRequestFilters,
  );
  const [versionList, setVersionList] = React.useState<IComboBoxOption[]>([]);
  const [versionListSelectedKeys, setVersionListSelectedKeys] = React.useState<string[] | null>([]);
  const [testSuiteList, setTestSuiteList] = React.useState<IComboBoxOption[]>([]);
  const [testSuiteListSelectedKeys, setTestSuiteListSelectedKeys] = React.useState<string[] | null>([]);
  const [testNameList, setTestNameList] = React.useState<IComboBoxOption[]>([]);
  const [testNameListSelectedKeys, setTestNameListSelectedKeys] = React.useState<string[] | null>([]);
  const [runDateList, setRunDateList] = React.useState<IComboBoxOption[]>([]);
  const [runDateListSelectedKeys, setRunDateListSelectedKeys] = React.useState<string[] | null>([]);
  const [testOwnerList, setTestOwnerList] = React.useState<IComboBoxOption[]>([]);
  const [testOwnerListSelectedKeys, setTestOwnerListSelectedKeys] = React.useState<string[] | null>([]);
  const [processorList, setProcessorList] = React.useState<IComboBoxOption[]>([]);
  const [processorListSelectedKeys, setProcessorListSelectedKeys] = React.useState<string[] | null>([]);
  const [testResultList, setTestResultList] = React.useState<IComboBoxOption[]>([]);
  const [testResultListSelectedKeys, setTestResultListSelectedKeys] = React.useState<string[] | null>([]);
  const [signOffList, setSignOffList] = React.useState<IComboBoxOption[]>([]);
  const [signOffListSelectedKeys, setSignOffListSelectedKeys] = React.useState<string[] | null>([]);
  const [tableIndex, setTableIndex] = React.useState<number>();
  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [isAdvanceFilter, setAdvanceFilter] = React.useState<boolean>(false);
  const [isAdvanceFilterChange, setAdvanceFilterChange] = React.useState<boolean>(false);
  const [isQaasLabUser, setQaasLabuser] = React.useState<boolean>(false);
  const [isControlChange, setControlChange] = React.useState<boolean>(false);
  const [pathSpinner, setPathSpinner] = React.useState<boolean>(false);
  const [tableSpinner, setTableSpinner] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const stateRef: any = React.useRef();
  const resultRef: any = React.useRef();
  const releaseRef: any = React.useRef();
  const defaultTestResultList = ['FAIL', 'REVIEW'];
  const params = new URLSearchParams(location.search);

  stateRef.current = requestFilters;
  resultRef.current = resultList;
  releaseRef.current = releaseDetails;

  const fetchResultList = React.useCallback(async () => {
    const userName = getUserName();

    await regressionTestPassRequestService
      .getResultList(userName)
      .then((result: any) => {
        setQaasLabuser(result.qaasLabUser);
        setReleaseDetails(result.releaseDetails);
        setDefaultReleaseDetails(result.releaseDetails);
        setComboBoxes(result.data as RegressionTestPassResult);
        setDefaultRequestFilters(result.data);
        fetchQueryParamResult();
      })
      .catch((error: any) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };
        HandleError(handleErrorProps);
      })
      .finally(() => {
        setTableSpinner(false);
      });
  }, []);

  const fetchQueryResult = React.useCallback(async (queryFilters: any) => {
    await regressionTestPassRequestService
      .getQueryParamResultList(queryFilters)
      .then((result: any) => {
        setQaasLabuser(result.qaasLabUser);
        setReleaseDetails(result.releaseDetails);
        setDefaultReleaseDetails(result.releaseDetails);
        setComboBoxes(result.data as RegressionTestPassResult);
        setVersionListSelectedKeys(result.versionList);

        const versionBuild = bindVersionBuild(result.versionList);

        queryFilters.VersionList = versionBuild.map((o: any) => o.version);
        queryFilters.BuildList = versionBuild.map((o: any) => o.build);

        const { User, ...filters } = queryFilters;

        setRequestFilters(filters);
        setPageFilters(filters);
      })
      .catch((error: any) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };
        HandleError(handleErrorProps);
      });
  }, []);

  const fetchQueryParamResult = () => {
    let queryFilters: any = qs.parse(location.search);

    queryFilters = setQueryParamRequestFilter(queryFilters);

    if (queryFilters.TestNameList !== undefined || queryFilters.RunDateList !== undefined) {
      setAdvanceFilter(true);
    }

    queryFilters.User = getUserName();
    fetchQueryResult(queryFilters);
  };

  const fetchResult = (separatorIndex: any) => {
    if (separatorIndex === -1) {
      fetchResultList();
    } else {
      fetchQueryParamResult();
    }
  };

  React.useEffect(() => {
    async function fetchToken() {
      const token = await MsalAuthorization.getToken();
      const checkSeparator = window.location.href.indexOf('?');

      if (token == null) {
        setTimeout(() => {
          fetchResult(checkSeparator);
        }, 2000);
      } else {
        fetchResult(checkSeparator);
      }
    }

    fetchToken();
  }, []);

  const getUserName = () => accounts[0].username.substring(0, accounts[0].username.lastIndexOf('@'));

  const setComboBoxes = (result: RegressionTestPassResult) => {
    setResultData(result);
    setVersionList(bindCombobox(result.VersionList));
    setTestSuiteList(bindCombobox(result.TestSuiteList));
    setTestNameList(bindCombobox(result.TestNameList));
    setRunDateList(bindCombobox(result.RunDateList));
    setTestOwnerList(bindCombobox(result.TestOwnerList));
    setSignOffOwner(getUserName());
    setProcessorList(bindCombobox(result.ProcessorList));
    setTestResultList(bindCombobox(result.TestResultList));
    setResultList(result.ResultList);
    setTotalRecords(result.TotalRecords);
    setSignOffList(bindCombobox(result.SignOffList));
    setBusy(false);
  };

  const updateQueryStringParameter = (key: string, value: string[]) => {
    const uri = window.location.href;

    if (value === null) value = [];

    let newUrl = '';
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    const filters = JSON.stringify(value);

    if (uri.match(re)) {
      newUrl = uri.replace(re, `$1${key}=${value}$2`);
    } else {
      newUrl = `${uri}${separator}${key}=${value}`;
    }

    if (value.length === 0) {
      newUrl = newUrl.indexOf('&') === -1 ? newUrl.replace(`?${key}=`, '') : newUrl.replace(`&${key}=`, '');
    }

    history.pushState('', '', newUrl);
  };

  const bindCombobox = (resultData: Array<string>) => {
    const options: any = [];

    if (resultData !== undefined) {
      options.push({ key: Labels.selectAllKey, text: Labels.selectAll, itemType: Labels.selectAllItemType });
      options.push({ key: Labels.clearAllKey, text: Labels.clearAll, itemType: Labels.clearAllItemType });
      resultData.map((item) => {
        if (item !== '') {
          options.push({ key: item, text: item });
        }

        return options;
      });
    }

    return options;
  };

  const loadingOptions = () => {
    const options: any = [];

    options.push({ key: Labels.loadingKey, text: Labels.loading, itemType: SelectableOptionMenuItemType.Header });

    return options;
  };

  const setDefaultRequestFilters = (result: RegressionTestPassResult) => {
    const filters = new RegressionTestPassResultRequestFilters();

    const userName = getUserName();

    setVersionListSelectedKeys([result.VersionList[0]]);

    setTestResultListSelectedKeys(['FAIL', 'REVIEW']);

    filters.VersionList = [result.VersionList[0].toString().split('-')[0]];

    filters.BuildList = [result.VersionList[0].toString().split('-')[1]];

    if (result.TestOwnerList.indexOf(userName) > -1) {
      setTestOwnerListSelectedKeys([userName]);
      filters.TestOwnerList = [userName];
      updateQueryStringParameter(Labels.testOwnerList, filters.TestOwnerList);
    }

    filters.TestResultList = arrayContains(defaultTestResultList, result.TestResultList) as string[];
    updateQueryStringParameter(Labels.versionList, filters.BuildList);
    updateQueryStringParameter(Labels.testResultList, filters.TestResultList);
    setRequestFilters(filters);
    setPageFilters(filters);
  };

  const arrayContains = (checkItems: any, itemsList: any) => {
    const result = [];

    for (let i = 0; i < checkItems.length; i++) {
      if (itemsList.indexOf(checkItems[i as number]) > -1) {
        result.push(checkItems[i as number]);
      }
    }

    return result.length > 0 ? result : null;
  };

  const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    options?: IComboBoxOption[],
    selectedKeys?: string[] | null,
    oKey?: string,
  ): void => {
    const selected = option?.selected;
    const selectableOptions = options
      ? options.filter(
          (option) =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
        )
      : [];
    const currentSelectedOptionKeys =
      selectedKeys && selectedKeys !== null ? selectedKeys.filter((key: any) => key !== 'selectAll') : [];
    const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length;

    if (option) {
      let selectedKeyList: string[] | null = [];

      if (option?.text === Labels.selectAll) {
        selectedKeyList = selectAllState ? null : [...selectableOptions.map((o) => o.key as string)];
      } else if (option?.text === Labels.clearAll) {
        selectedKeyList = null;
      } else {
        selectedKeyList = selected
          ? [...currentSelectedOptionKeys, option?.key as string]
          : currentSelectedOptionKeys.filter((k) => k !== option.key);
      }

      setControlChange(true);

      if (oKey) {
        setSelectedKeyList(oKey, selectedKeyList as string[]);
      }
    }
  };

  const bindVersionBuild = (vbuild: any) => {
    const versionBuild: any = [];

    vbuild.forEach((item: any) => {
      versionBuild.push({ version: item.split('-')[0], build: item.split('-')[1] });
    });

    return versionBuild;
  };

  const setSelectedKeyList = (key: string, selectedKeyList: any[]) => {
    const filters = stateRef.current === undefined ? new RegressionTestPassResultRequestFilters() : stateRef.current;

    let versionBuild: any = [];

    switch (key) {
      case Labels.versionList:
        const defaultVersion = [resultData.VersionList[0].toString()];

        if (selectedKeyList !== null) {
          versionBuild = bindVersionBuild(selectedKeyList);
        }

        selectedKeyList === null || selectedKeyList.length === 0
          ? setVersionListSelectedKeys(defaultVersion)
          : setVersionListSelectedKeys(selectedKeyList);
        filters.VersionList =
          selectedKeyList === null || selectedKeyList.length === 0
            ? [resultData.VersionList[0].toString().split('-')[0]]
            : versionBuild.map((o: any) => o.version);
        filters.BuildList =
          selectedKeyList === null || selectedKeyList.length === 0
            ? [resultData.VersionList[0].toString().split('-')[1]]
            : versionBuild.map((o: any) => o.build);
        setRunDateListSelectedKeys(null);
        updateQueryStringParameter(Labels.versionList, filters.BuildList);
        break;
      case Labels.testSuiteList:
        setTestSuiteListSelectedKeys(selectedKeyList);
        filters.TestSuiteList = selectedKeyList;
        updateQueryStringParameter(Labels.testSuiteList, filters.TestSuiteList);
        break;
      case Labels.testNameList:
        setTestNameListSelectedKeys(selectedKeyList);
        filters.TestNameList = selectedKeyList;
        updateQueryStringParameter(Labels.testNameList, filters.TestNameList);
        break;
      case Labels.runDateList:
        setRunDateListSelectedKeys(selectedKeyList);
        filters.RunDateList = selectedKeyList;
        updateQueryStringParameter(Labels.runDateList, filters.RunDateList);
        break;
      case Labels.testOwnerList:
        setTestOwnerListSelectedKeys(selectedKeyList);
        filters.TestOwnerList = selectedKeyList;
        updateQueryStringParameter(Labels.testOwnerList, filters.TestOwnerList);
        break;
      case Labels.processorList:
        setProcessorListSelectedKeys(selectedKeyList);
        filters.ProcessorList = selectedKeyList;
        updateQueryStringParameter(Labels.processorList, filters.ProcessorList);
        break;
      case Labels.testResultList:
        setTestResultListSelectedKeys(selectedKeyList);
        filters.TestResultList = selectedKeyList;
        updateQueryStringParameter(Labels.testResultList, filters.TestResultList);
        break;
      case Labels.signOffList:
        setSignOffListSelectedKeys(selectedKeyList);
        filters.SignOffList = selectedKeyList;
        updateQueryStringParameter(Labels.signOffList, filters.SignOffList);
        break;
      default:
        break;
    }

    setRequestFilters(filters);
  };

  const fetchFilteredResultList = React.useCallback(async (filters: RegressionTestPassResultRequestFilters) => {
    filters.Id = 0;
    await regressionTestPassRequestService
      .getFilteredResultList(filters)
      .then((result: any) => {
        setResultList(result.data.ResultList);
        setTotalRecords(result.data.TotalRecords);
        setReleaseDetails(result.releaseDetails);
      })
      .catch((error: any) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };
        HandleError(handleErrorProps);
      })
      .finally(() => {
        setTableSpinner(false);
        setLoading(false);
      });
  }, []);

  const handleSearchResult = (filters: RegressionTestPassResultRequestFilters) => {
    setLoading(true);
    setTableSpinner(true);
    setPageFilters(filters);
    setTimeout(() => {
      fetchFilteredResultList(filters);
    }, 2000);
  };

  const handleAdvanceFilter = (advanceFilter: boolean, filters: RegressionTestPassResultRequestFilters) => {
    filters.TestNameList = [];
    filters.RunDateList = [];
    setRequestFilters(filters);
    setTestNameList(loadingOptions());
    setRunDateList(loadingOptions());
    setTestNameListSelectedKeys(null);
    setRunDateListSelectedKeys(null);
    updateQueryStringParameter(Labels.testNameList, []);
    updateQueryStringParameter(Labels.runDateList, []);

    if (advanceFilter === false) {
      fetchAdvanceFilteredList(filters, advanceFilter);
    } else {
      setAdvanceFilter(false);
      setAdvanceFilterChange(false);
    }
  };

  const handleAdvanceFilterChange = (
    advanceFilter: boolean,
    controlChange: boolean,
    filters: RegressionTestPassResultRequestFilters,
  ) => {
    if (controlChange) {
      filters.TestNameList = [];
      filters.RunDateList = [];
      setRequestFilters(filters);
      setControlChange(false);
      setTestNameList(loadingOptions());
      setRunDateList(loadingOptions());
      setTestNameListSelectedKeys(null);
      setRunDateListSelectedKeys(null);

      if (advanceFilter) {
        fetchAdvanceFilteredList(filters, advanceFilter);
      }
    }
  };

  const fetchAdvanceFilteredList = React.useCallback(
    async (filters: RegressionTestPassResultRequestFilters, advanceFilter: boolean) => {
      setAdvanceFilter(true);
      setAdvanceFilterChange(true);
      filters.Id = 0;
      await regressionTestPassRequestService
        .getAdvanceFilteredList(filters)
        .then((result: any) => {
          setTestNameList(bindCombobox(result.TestNameList));
          setRunDateList(bindCombobox(result.RunDateList));
        })
        .catch((error: any) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
          };
          HandleError(handleErrorProps);
        })
        .finally(() => {
          setAdvanceFilterChange(false);
        });
    },
    [],
  );

  const saveSignOffResult = React.useCallback(async (request: RegressionTestPassSignOffResult, index: number, bgId: string) => {
    await regressionTestPassRequestService
      .saveSignOffResult(request)
      .then((response: any) => {
        if (response.result === Labels.success) {
          setSignOffResultModal(false);

          if (request.SignOffResult !== Labels.no) {
            if (
              releaseRef.current.TotalCount !== releaseRef.current.SignOffCount &&
              request.Result !== Labels.pass &&
              resultRef.current[index as number].Color !== Labels.green
            ) {
              releaseRef.current.SignOffCount++;
            }

            resultRef.current[index as number].Id = index + 1;
            resultRef.current[index as number].Color = Labels.green;
            resultRef.current[index as number].Notes = request.SignOffNotes;
          } else {
            if (
              resultRef.current[index as number].Color === Labels.green &&
              request.Result !== Labels.pass &&
              request.SignOffResult === Labels.no
            ) {
              releaseRef.current.SignOffCount--;
            }

            if (resultRef.current[index as number].Color === Labels.green && request.SignOffResult === Labels.no) {
              resultRef.current[index as number].Color = Labels.gray;
            }

            resultRef.current[index as number].Id = index + 1;

            if (request.Result !== Labels.pass) {
              resultRef.current[index as number].Color = Labels.red;
            }

            resultRef.current[index as number].Notes = request.SignOffNotes;
          }

          resultRef.current[index as number].Bugs = response.bugs;
          notificationService.showSuccess(Messages.successfullySavedSignOff);
          setResultList([...resultRef.current]);
          setReleaseDetails(releaseRef.current);
          setCheckBugId(false);
        } else if (response.result.includes(Labels.invalid)) {
          request.BugId = bgId;
          setCheckBugId(true);
          setInvalidBugs(response.result);
        }

        setSubmit(false);
      })
      .catch((error: any) => {
        request.BugId = '';
        setSubmit(false);
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };
        HandleError(handleErrorProps);
      });
  }, []);

  const setQueryParamRequestFilter = (result: any) => {
    if (result.VersionList !== null || undefined) {
      result.VersionList = result.VersionList.split(',');
      setVersionListSelectedKeys(result.VersionList);
    }

    if (result.TestSuiteList !== null || undefined) {
      result.TestSuiteList = result.TestSuiteList.split(',');
      setTestSuiteListSelectedKeys(result.TestSuiteList);
    }

    if (result.TestNameList !== null || undefined) {
      result.TestNameList = result.TestNameList.split(',');
      setTestNameListSelectedKeys(result.TestNameList);
    }

    if (result.RunDateList !== null || undefined) {
      result.RunDateList = result.RunDateList.split(',');
      setRunDateListSelectedKeys(result.RunDateList);
    }

    if (result.TestOwnerList !== null || undefined) {
      result.TestOwnerList = result.TestOwnerList.split(',');
      setTestOwnerListSelectedKeys(result.TestOwnerList);
    }

    if (result.ProcessorList !== null || undefined) {
      result.ProcessorList = result.ProcessorList.split(',');
      setProcessorListSelectedKeys(result.ProcessorList);
    }

    if (result.TestResultList !== null || undefined) {
      result.TestResultList = result.TestResultList.split(',');
      setTestResultListSelectedKeys(result.TestResultList);
    }

    if (result.SignOffList !== null || undefined) {
      result.SignOffList = result.SignOffList.split(',');
      setSignOffListSelectedKeys(result.SignOffList);
    }

    return result;
  };

  const handleResultDetailsModalClose = () => {
    setResultDetails(tmpRegressionTestPassResultList);
    settoolkitResultDetailsModal(false);
  };

  const handleSignOffResultModalClose = () => {
    setResultDetails(tmpRegressionTestPassResultList);
    setSelectedSignOffDropdown('');
    setBugId('');
    setNewBugId([]);
    setSignOffOwner(getUserName);
    setSignOffNotes('');
    setSignOffResultModal(false);
    setCheckBugId(false);
    setInvalidBugs('');
  };

  const handleViewTestLogsModalClose = () => {
    setViewTestLogsModal(false);
    setResultCollectionGuid('');
  };

  const saveSingoff = (
    evt: any,
    item: RegressionTestPassResultList,
    result: any,
    bugs: string[],
    bugid: any,
    user: any,
    notes: any,
    index: number,
  ) => {
    evt.preventDefault();
    setSubmit(true);

    let newBugs: string[] = [];

    const itemData = new RegressionTestPassResultList();

    itemData.BugId = item.BugId;

    const signOffData = item as RegressionTestPassSignOffResult;

    signOffData.Id = 0;
    signOffData.SignOffResult = result;
    newBugs = newBugs.concat(bugs);

    const checkBugId = newBugs.includes(bugId);

    if (!checkBugId) {
      newBugs.push(bugId);
    }

    signOffData.BugId = newBugs.join('\\');
    signOffData.SignOffUser = user;
    signOffData.SignOffNotes = notes;
    saveSignOffResult(signOffData, index, itemData.BugId);
  };

  const viewLogs = (evt: any, item: RegressionTestPassResultList) => {
    evt.preventDefault();

    if (isDevEnv()) {
      copyToClipboard(item.Path);
      notificationService.showSuccess(`${Messages.pathCopyClipboard} - ${item.Path}`);
    } else {
      setViewTestLogsModal(true);
      setResultCollectionGuid(item.ResultCollectionGUID);
      setTestName(item.TestName);
    }
  };

  const handleResetFilter = (result: RegressionTestPassResult, releaseDetails: ReleaseDetails) => {
    setTableSpinner(true);
    setVersionListSelectedKeys(null);
    setTestSuiteListSelectedKeys(null);
    setTestNameListSelectedKeys(null);
    setRunDateListSelectedKeys(null);
    setTestOwnerListSelectedKeys(null);
    setProcessorListSelectedKeys(null);
    setTestResultListSelectedKeys(null);
    setSignOffListSelectedKeys(null);
    setRequestFilters(tmpRegressionTestPassResultRequestFilters);
    setPageFilters(tmpRegressionTestPassResultRequestFilters);
    setComboBoxes(result);
    setDefaultRequestFilters(result);
    setReleaseDetails(releaseDetails);
    fetchResultList();
    history.pushState('', '', window.location.pathname);
  };

  const handleMenuOpen = () => {
    const comboBox = document.querySelector('[role="combobox"]');

    if (comboBox) {
      const listId = comboBox.getAttribute('aria-owns');

      if (listId) {
        comboBox.setAttribute('aria-controls', listId);
      }
    }
  };

  return (
    <div className="fullscreen">
      <main className="fullscreen container labs-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.LABS.RELEASE_TOP_RESULTS]}>{Labels.regressionTestPassHeader}</PageHeader>
          <PageContent scrollable={true}>
            {isBusy ? (
              <LoadingSpinner />
            ) : (
              <div>
                <div className="scrollable-filter">
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        {releaseDetails.TotalCount !== 0 && (
                          <>
                            {releaseDetails.TotalCount === releaseDetails.SignOffCount ? (
                              <div className="float-left">
                                <h5 className="text-success float-left mr-2">
                                  <Icon iconName="BoxCheckmarkSolid" className="float-left mr-2" />
                                  {Labels.allTestsSignedOff}
                                </h5>
                              </div>
                            ) : (
                              <div className="float-left">
                                <h5 className="text-danger float-left mr-2">
                                  <Icon iconName="BoxMultiplySolid" className="float-left mr-2" />
                                  {Labels.pending}({releaseDetails.TotalCount - releaseDetails.SignOffCount}){Labels.signoffs}
                                </h5>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <ul className="nav float-right">
                        <li className="mb-4">
                          <a
                            href={RedirectURL.serverBuildingBlockDetails}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="clickable mr-5"
                          >
                            <span>{Labels.serverBuildingBlockDetails}</span>
                          </a>
                        </li>
                        <li className="mb-4">
                          <a href={RedirectURL.partNumberDetails} target="_blank" rel="noopener noreferrer" className="clickable">
                            <span>{Labels.partNumberDetails}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.version}
                        options={versionList}
                        selectedKey={versionListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, versionList, versionListSelectedKeys, Labels.versionList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.sku}
                        options={testSuiteList}
                        selectedKey={testSuiteListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, testSuiteList, testSuiteListSelectedKeys, Labels.testSuiteList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.testOwners}
                        options={testOwnerList}
                        selectedKey={testOwnerListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, testOwnerList, testOwnerListSelectedKeys, Labels.testOwnerList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.processors}
                        options={processorList}
                        selectedKey={processorListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, processorList, processorListSelectedKeys, Labels.processorList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.result}
                        options={testResultList}
                        selectedKey={testResultListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, testResultList, testResultListSelectedKeys, Labels.testResultList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-filter">
                      <ComboBox
                        multiSelect
                        label={Labels.signOff}
                        options={signOffList}
                        selectedKey={signOffListSelectedKeys}
                        styles={comboBoxStyles}
                        onChange={(e: any, selectedOption: any) =>
                          onChange(e, selectedOption, signOffList, signOffListSelectedKeys, Labels.signOffList)
                        }
                        onMenuDismissed={() => handleAdvanceFilterChange(isAdvanceFilter, isControlChange, requestFilters)}
                        onMenuOpen={handleMenuOpen}
                      />
                    </div>
                    {isAdvanceFilter && (
                      <>
                        <div className="vertical-line-separator"> </div>
                        <div className="col-sm-filter">
                          <ComboBox
                            multiSelect
                            label={Labels.testName}
                            options={testNameList}
                            selectedKey={testNameListSelectedKeys}
                            styles={comboBoxStyles}
                            onChange={(e: any, selectedOption: any) =>
                              onChange(e, selectedOption, testNameList, testNameListSelectedKeys, Labels.testNameList)
                            }
                            onMenuOpen={handleMenuOpen}
                          />
                        </div>
                        <div className="col-sm-filter">
                          <ComboBox
                            multiSelect
                            label={Labels.runDate}
                            options={runDateList}
                            selectedKey={runDateListSelectedKeys}
                            styles={comboBoxStyles}
                            onChange={(e: any, selectedOption: any) =>
                              onChange(e, selectedOption, runDateList, runDateListSelectedKeys, Labels.runDateList)
                            }
                            onMenuOpen={handleMenuOpen}
                          />
                        </div>
                      </>
                    )}
                    <div className="float-left search-filter">
                      <Button
                        label="Search"
                        className="search-filter-btn mt-28 float-left"
                        icon="pi pi-search"
                        loading={loading}
                        onClick={() => handleSearchResult(requestFilters)}
                      />
                      <div className="float-left clear-filter">
                        <IconButton
                          styles={iconButtonStyles}
                          className="ml-3"
                          iconProps={filterIcon}
                          title="filter"
                          onClick={() => handleResetFilter(resultData, defaultReleaseDetails)}
                        />
                      </div>
                      <div className="float-left advance-filter">
                        {isAdvanceFilter ? (
                          <Icon iconName="ChevronLeft" className="chevron-advance-filter" />
                        ) : (
                          <Icon iconName="ChevronRight" className="chevron-advance-filter" />
                        )}
                        <span
                          className="clickable mt-4 ml-2"
                          role="button"
                          tabIndex={0}
                          onClick={() => handleAdvanceFilter(isAdvanceFilter, requestFilters)}
                        >
                          Advance Filters
                        </span>
                      </div>
                    </div>
                  </div>
                  {tableSpinner && <LoadingSpinner />}
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {resultList.length === 0 ? (
                      <NoRecordsFound />
                    ) : (
                      <TestResultTable
                        resultData={resultData}
                        resultList={resultList}
                        tableIndex={tableIndex}
                        tableSpinner={tableSpinner}
                        isQaasLabUser={isQaasLabUser}
                        totalRecords={totalRecords}
                        pageFilters={pageFilters}
                        setResultList={setResultList}
                        setReleaseDetails={setReleaseDetails}
                        setResultDetails={setResultDetails}
                        settoolkitResultDetailsModal={settoolkitResultDetailsModal}
                        setTableSpinner={setTableSpinner}
                        setSelectedSignOffDropdown={setSelectedSignOffDropdown}
                        setBugId={setBugId}
                        setNewBugId={setNewBugId}
                        setSignOffOwner={setSignOffOwner}
                        setSignOffNotes={setSignOffNotes}
                        setSignOffResultModal={setSignOffResultModal}
                        setSignOffDropdownOptions={setSignOffDropdownOptions}
                        setTableIndex={setTableIndex}
                        userName={getUserName()}
                        viewLogs={viewLogs}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  {resultDetails !== undefined ? (
                    <TestResultDetails
                      toolkitResultDetailsModal={toolkitResultDetailsModal}
                      handleResultDetailsModalClose={handleResultDetailsModalClose}
                      pathSpinner={pathSpinner}
                      setPathSpinner={setPathSpinner}
                      resultDetails={resultDetails}
                    />
                  ) : null}
                </div>
                <div className="row">
                  {resultDetails !== undefined ? (
                    <SaveSignOffResult
                      signOffResultModal={signOffResultModal}
                      handleSignOffResultModalClose={handleSignOffResultModalClose}
                      resultDetails={resultDetails}
                      signOffDropdownOptions={signOffDropdownOptions}
                      selectedSignOffDropdown={selectedSignOffDropdown}
                      setSelectedSignOffDropdown={setSelectedSignOffDropdown}
                      bugId={bugId}
                      setBugId={setBugId}
                      signOffOwner={signOffOwner}
                      setSignOffOwner={setSignOffOwner}
                      signOffNotes={signOffNotes}
                      setSignOffNotes={setSignOffNotes}
                      isSubmit={isSubmit}
                      saveSingoff={saveSingoff}
                      tableIndex={tableIndex}
                      checkBugId={checkBugId}
                      setCheckBugId={setCheckBugId}
                      newBugId={newBugId}
                      setNewBugId={setNewBugId}
                      invalidBugs={invalidBugs}
                      setInvalidBugs={setInvalidBugs}
                    />
                  ) : null}
                </div>
                <div className="row">
                  {viewTestLogsModal ? (
                    <ViewTestLogs
                      viewTestLogsModal={viewTestLogsModal}
                      resultCollectionGuid={resultCollectionGuid}
                      handleViewTestLogsModalClose={handleViewTestLogsModalClose}
                      testName={testName}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </PageContent>
        </div>
      </main>
    </div>
  );
};
