import React from 'react';
import { t } from 'i18next';
import format from 'string-template';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { PaginationAria } from '@/constants/TranslationConstants';

import { PaginationLabels, PaginationType } from '../Pagination/PaginationTypes';
import PaginationTemplate from './PaginationTemplate';

interface PaginationControllerProps {
  paginationType: PaginationType;
}

const PaginationViewController: React.FC<PaginationControllerProps> = ({ paginationType }) => {
  const { paginationProps, onPageChange } = paginationType;
  const { currentPage, pageSize, totalItems, hasFiltersApplied, infoTemplateKey, filterTemplateKey } = paginationProps ?? {};
  const pageCount = Math.ceil(totalItems / pageSize);

  let updatedPaginationProps = paginationProps;

  if (paginationProps) {
    updatedPaginationProps = {
      ...paginationProps,
      pageCount,
    };
  }

  const startIndex: number = currentPage * pageSize + 1;
  const endIndex: number = Math.min((currentPage + 1) * pageSize, totalItems);

  const allRecordsMessage: string = format(t(infoTemplateKey, { ns: NS.PAGINATION }), {
    startIndex: startIndex,
    endIndex: endIndex,
    totalItems: totalItems,
  });

  const filteredRecordsMessage: string = format(t(filterTemplateKey, { ns: NS.PAGINATION }), {
    startIndex: startIndex,
    endIndex: endIndex,
    totalItems: totalItems,
  });

  const recordsMessage: string = hasFiltersApplied ? filteredRecordsMessage : allRecordsMessage;
  const recordsInformation: string = paginationProps ? recordsMessage : '';

  const paginationLabels: PaginationLabels = {
    previousPage: t(PaginationAria.PREVIOUS_PAGE, { ns: NS.PAGINATION }),
    nextPage: t(PaginationAria.NEXT_PAGE, { ns: NS.PAGINATION }),
    firstPage: t(PaginationAria.FIRST_PAGE, { ns: NS.PAGINATION }),
    lastPage: t(PaginationAria.LAST_PAGE, { ns: NS.PAGINATION }),
    recordsInformation: recordsInformation,
  };

  return (
    <PaginationTemplate onPageChange={onPageChange} paginationProps={updatedPaginationProps} paginationLabels={paginationLabels} />
  );
};

export default PaginationViewController;
