import React from 'react';
import { Icon } from '@fluentui/react';

import { IconNames } from '@/constants/AirConstants';

import '@/styles/Air.css';

interface IProps {
  message: string;
}

export const Message = ({ message }: IProps): JSX.Element => (
  <div className="fetch-error">
    <Icon iconName={IconNames.sad} className="mr-2" />
    <span className="error-message">{message}</span>
  </div>
);
