export enum ThemeSchema {
  Dark = 'dark',
  Light = 'light',
}

export interface Theme {
  accentColor: string;
  accentTextColor: string;
  backgroundColor: string;
  cardBackground: string;
  donutChartColor: string;
  icon: string;
  lineColor: string;
  navbarClosedColor: string;
  navbarColor: string;
  navbarText: string;
  panelColor: string;
  tabHoverColor: string;
  textColor: string;
}
