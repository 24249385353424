import React from 'react';
import { observer } from 'mobx-react';
import { BarProps, LineProps, XAxisProps } from 'recharts';

import UseGraphTemplate from './UseGraphTemplate';
import { UseGraphTemplateType, UseGraphType } from './UseGraphType';

class UseGraphViewControllerCC extends React.Component<{ viewModel: UseGraphType }, any> {
  constructor(props: { viewModel: UseGraphType }) {
    super(props);
    this.state = {
      graphData: [],
    };
  }

  componentDidMount(): void {
    const { setGraphData } = this.props.viewModel;
    setGraphData();
  }

  render() {
    const lineProp: LineProps = {
      dataKey: 'errorCount',
      type: 'monotone',
      stroke: '#006ee5',
    };

    const barProp: BarProps = {
      dataKey: 'pageCount',
      barSize: 20,
      fill: '#afafaf',
    };

    const xAxisProp: XAxisProps = {
      dataKey: 'timeGenerated',
      scale: 'band',
      interval: 'preserveStartEnd',
    };

    const { graphData } = this.props.viewModel;

    const templateProps: UseGraphTemplateType = {
      data: graphData,
      chartProp: {
        lineProp,
        barProp,
        xAxisProp,
      },
    };

    return <UseGraphTemplate {...templateProps} />;
  }
}

const UseGraphViewController = observer(UseGraphViewControllerCC);

export default UseGraphViewController;
