import React from 'react';
import { observer } from 'mobx-react-lite';

import StatusDonutChart from '@/components/Graphs/StatusDonut/StatusDonusTemplate';
import { SessionStatusType } from '@/components/Sessions/SessionsTypes';
import { SessionStepChartVCType } from '@/components/SessionStepChart/SessionStepChartTypes';
import { StepStatusTypes } from '@/constants/ExperimentConstants';
import { ChartType } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';

import styles from '@/components/SessionStepChart/SessionStepChart.module.css';

const extractSteps = (status: SessionStatusType): number[] => {
  // Count the occurrences of each status type.
  // The StatusDonut component takes an array of these 6 values, in order.
  const stepValues: number[] = [
    status.data[StepStatusTypes.SUCCEEDED] || 0,
    status.data[StepStatusTypes.PENDING] || 0,
    status.data[StepStatusTypes.IN_PROGRESS] || 0,
    status.data[StepStatusTypes.FAILED] || 0,
    status.data[StepStatusTypes.NOT_STARTED] || 0,
    status.data[StepStatusTypes.CANCELLED] || 0,
  ];

  return stepValues;
};

const SessionStepChartTemplateFC: React.FC<SessionStepChartVCType> = (props: SessionStepChartVCType) => {
  const { type, data } = props;

  if (!data?.status) {
    return <div className={styles['no-data']}>No data</div>;
  }

  const status: SessionStatusType = data.status;
  const seriesData: number[] = extractSteps(status);
  const total: number = seriesData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (type === ChartType.DONUT) {
    const chart = <StatusDonutChart seriesData={seriesData} total={total} />;

    return <div className={styles['chart']}>{chart}</div>;
  } else {
    // Currently, we only support one type.
    const chart = <StatusDonutChart seriesData={seriesData} total={total} />;

    return <div className={styles['chart']}>{chart}</div>;
  }
};

const SessionStepChartTemplate = observer(SessionStepChartTemplateFC);

export default SessionStepChartTemplate;
