import React from 'react';
import { observer } from 'mobx-react-lite';

import { ExperimentInstanceModalVMType } from '@/components/ExperimentInstanceModal/ExperimentInstanceModalTypes';
import ExperimentInstanceModalViewController from '@/components/ExperimentInstanceModal/ExperimentInstanceModalViewController';
import ExperimentInstanceModalViewModel from '@/components/ExperimentInstanceModal/ExperimentInstanceModalViewModel';

const ExperimentInstanceModalFC: React.FC<ExperimentInstanceModalVMType> = ({ title, labId, instanceId, loadMessage }) => {
  const viewModel = React.useMemo(
    () => new ExperimentInstanceModalViewModel(title, labId, instanceId, loadMessage),
    [title, labId, instanceId, loadMessage],
  );

  return <ExperimentInstanceModalViewController viewModel={viewModel}></ExperimentInstanceModalViewController>;
};

const ExperimentInstanceModal = observer(ExperimentInstanceModalFC);

export default ExperimentInstanceModal;
