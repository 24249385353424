import { RuleDetails } from '@/utils/_labs/RuleDetails';

import ResultRequestService from './ResultExplorerRequestService';

class LogManagementRequestService extends ResultRequestService {
  async getLogs(companyName: string, labs?: string, timeStampDateFrom?: number, timeStampDateTo?: number) {
    let query = '';

    if (labs !== '') {
      const labIds = labs.split(',');

      for (const key in labIds) {
        query += `${query ? '&' : ''}LabIds=${labIds[key as string]}`;
      }
    }

    let url: any;

    if (companyName) {
      url = `parse/parsedLogs/all${companyName ? `?${query ? `${query}&` : ''}companyName=${companyName}` : ''}`;

      if (timeStampDateFrom && timeStampDateTo) {
        url += `&timeStampDateFrom=${timeStampDateFrom}&timeStampDateTo=${timeStampDateTo}`;
      }

      const result = await this.get(url);
      return result.data;
    } else {
      return [];
    }
  }

  async getLogsById(resultCollectionId: string, companyName?: string) {
    let call = `parse/parsedLogs/${resultCollectionId}`;

    if (companyName) {
      call += `?companyName=${companyName}`;
    }

    const { data } = await this.get(call);

    return data;
  }

  async checkIfLogsExist(resultCollectionIds: string[], companyName: string) {
    const body = { ExperimentIds: resultCollectionIds, CompanyName: companyName };
    const { data } = await this.post('v1/experiments/results', body);

    return data;
  }

  async getRuleAppliedResultsById(resultCollectionId: string, companyName: string) {
    const { data } = await this.get(`results/ruleAppliedResults/${resultCollectionId}?companyName=${companyName}`);

    return data;
  }

  async getFilesMetricsMetadata(companyName: string) {
    const { data } = await this.get(`results/filesMetricsMetadata?companyName=${companyName}`);

    return data;
  }

  async getFilePathsById(resultCollectionId: string, companyName: string, isRecursive?: boolean) {
    let call = `file/logFilePaths?resultCollectionId=${resultCollectionId}&companyName=${companyName}`;

    if (isRecursive !== null) {
      call += `&isRecursive=${isRecursive}`;
    }

    const { data } = await this.get(call);

    return data;
  }

  async getSpecificFileByPath(path: string, companyName: string, isRecursive?: boolean) {
    let call = `file/logFilePaths?path=${path}`;

    if (isRecursive !== null) {
      call += `&companyName=${companyName}&isRecursive=${isRecursive}`;
    }

    const { data } = await this.get(call);

    return data;
  }

  async getUserLogs(companyName: string) {
    const { data } = await this.get(`userLogs/parsedLogs?companyName=${companyName}`);

    return data;
  }
  async powerBISwitch() {
    const { data } = await this.get('results/enablePowerBi');

    return data;
  }

  async getUserLabLogs(companyName?: string, labs?: string) {
    let result = null;
    let query = '';

    if (labs !== '') {
      const labIds = labs.split(',');

      for (const key in labIds) {
        query += `${query ? '&' : ''}LabIds=${labIds[key as string]}`;
      }
    }

    const url = `userLogs/userLabLogs${companyName ? `?${query ? `${query}&` : ''}companyName=${companyName}` : ''}`;
    if (companyName) result = await this.get(url);

    return result.data;
  }

  async getCompanyName(id: string) {
    const { data } = await this.get(`groups/companyName/${id}`);

    return data;
  }

  async downloadLogFile(pathName: string, companyName?: string) {
    let call = `file/download/file?pathName=${pathName}`;

    if (companyName) {
      call += `&companyName=${companyName}`;
    }

    const { data } = await this.get(call);

    return data;
  }

  async getSystemEventsSummary(id: string) {
    const { data } = await this.get(`results/systemEventSummary/${id}`);

    return data;
  }

  async getRulesData(companyName: string) {
    const { data } = await this.get(`rules/fetchAllRules?companyName=${companyName}`);

    return data;
  }

  async applyRules(id: string, payload: string[], companyName: string) {
    const { data } = await this.post(`results/applyUserRulesOnParsedLogs/${id}?companyName=${companyName}`, payload);

    return data;
  }

  async searchRawLogs(searchRequestBody: any) {
    const { data } = await this.post('search/searchLogs', searchRequestBody);

    return data;
  }

  async getCompanyNameByUserId() {
    const { data } = await this.get('groups/company/user');

    return data;
  }

  async addRoles(emailIds: any) {
    const { data } = await this.post('role/companyAdmins', emailIds);

    return data;
  }

  async getAllCompanies() {
    const { data } = await this.get('groups/company');

    return data;
  }

  async addRules(ruleData: RuleDetails, companyName: string) {
    const { data } = await this.post(`rules/addRule?companyName=${companyName}`, ruleData);

    return data;
  }

  async getPowerBIToken(companyName: any) {
    const { data } = await this.get(`powerbiembed/GetEmbedInfo?companyName=${companyName}`);

    return data;
  }
}

export const logManagementRequestService = new LogManagementRequestService();
