import { ActionTypeWithParamReturn } from '@/types/AppSettingsTypes';

class ExperimentPropertiesPanelViewModel {
  public loadMessage: string;

  constructor(loadMessage: string) {
    this.loadMessage = loadMessage;
  }
}

export default ExperimentPropertiesPanelViewModel;
