import React from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, IconButton, Modal, TooltipHost } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Labels, Messages } from '@/constants/LabsConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';
import '../LabsHelper.css';

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const AddTagComponentFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const addTagTitleId = useId('addTagTitle');
  const [tagOptions, setTagOptions] = React.useState<any[]>([]);

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const tooltipId = useId('tooltip');

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const fetchExistingTags = React.useCallback(async () => {
    await ganymedeTagRequestService
      .getTags()
      .then((result: any) => {
        setTagOptions(result);
        props.setTagOptions(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  React.useEffect(() => {
    fetchExistingTags();
  }, [fetchExistingTags]);

  const handleChanges = async (e: any, tagName: any, reason: any) => {
    const timeoutTagChanges = TimeInterval.TWO_SECONDS;

    props.handleTagChanges(e, props.selectedItemKey, tagName, reason);

    // waiting for the handling tag changes then refetch
    setTimeout(() => {
      props.fetch();
    }, timeoutTagChanges);
    props.hideModal(true);
  };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={addTagTitleId}
        isOpen={props.isModalOpen}
        containerClassName={contentStyles.tagContainer}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>
            <Icon iconName={SystemIcons.TAG} className="circle-icon" />
            {Labels.addSystemTag}
            <TooltipHost content={<span>{Messages.tagInstruction}</span>} id={tooltipId} styles={hostStyles}>
              <h2 className="status pointer" aria-describedby={tooltipId}>
                <Icon iconName="Info" className="ml-1" />
              </h2>
            </TooltipHost>
          </h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="row">
            <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
            <div className="col-sm-3 text-right pr-2">
              <h5 className="text-grey mt-2">{props.labName}</h5>
            </div>
          </div>
          <br />
          <div>
            <Autocomplete
              multiple
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              id="tags-outlined"
              options={tagOptions}
              getOptionLabel={(option) => option.TagName}
              renderInput={(params) => (
                <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'tags-outlined' }} variant="outlined" />
              )}
              onChange={(event, newValue, reason) => {
                handleChanges(event, newValue, reason);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};

export const AddTagComponent = observer(AddTagComponentFC);
