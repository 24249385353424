import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button, Label, Stack, TextField } from '@fluentui/react';
import { IStackTokens } from '@fluentui/react/lib/Stack';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { userService } from '@/services/request-services/UserServices';
import { UserInfoType } from '@/types/UserTypes';

import styles from './GanymedeServices.module.css';

const GanymedeServicesTemplateFC: React.FunctionComponent = () => {
  const [output, setOutput] = React.useState('');
  const [validOutput, setValidOutput] = React.useState(true);
  const stackTokens: IStackTokens = { childrenGap: 40 };

  const getMyProfile: any = async () => {
    try {
      const userInfo: UserInfoType = await userService.getMyProfile();

      setOutput(JSON.stringify(userInfo, null, 2));
      setValidOutput(true);
    } catch (error: any) {
      setValidOutput(false);

      console.error(`userService.getMyProfile error: ${error}`);
    }
  };

  const clearOutput: any = () => {
    setOutput('');
    setValidOutput(true);
  };

  return (
    <div className={styles['container']}>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>ganymede-services</Trans>
      </h2>
      <div>
        <div>
          <Stack horizontal tokens={stackTokens}>
            <div>
              <Label>Get User Profile Information</Label>
              <Button type="button" onClick={getMyProfile} text={'Get Profile Info'}></Button>
            </div>
          </Stack>
        </div>
      </div>
      <div>
        <Stack horizontal tokens={stackTokens}>
          <Stack.Item align="center">
            <TextField
              multiline
              rows={12}
              className={`${styles.textarea} ${validOutput ? styles.success : styles.error}`}
              label="JSON Results"
              value={output}
            />
          </Stack.Item>
          <Stack.Item align="center">
            <Button type="button" onClick={clearOutput} text={'Clear'}></Button>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

const GanymedeServicesTemplate = observer(GanymedeServicesTemplateFC);

export default GanymedeServicesTemplate;
