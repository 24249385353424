import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SearchBox } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { LabIcons, SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnEditorKeys, EnablePagination, Namespaces as NS, Pagination } from '@/constants/SystemConstants';
import { Common, Experiments, Labs } from '@/constants/TranslationConstants';
import { getPaginationDefaults } from '@/partials/Pagination/Pagination';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { labDownloadHelper } from '@/utils/Helpers';
import { processColumns } from '@/utils/Tables';

import config from './LabList.config.json';
import { LabListTemplate } from './LabListTemplate';
import { LabListVMType, LabType } from './LabListTypes';

import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface LabListViewControllerProps {
  viewModel: LabListVMType;
}

const LabListViewControllerFC: React.FC<LabListViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labListStore, editColumnsStore, labPanelStore, paginationStore, systemMessageStore } = rootStore;
  const { openLabPanel } = labPanelStore;
  const { setPaginationType } = paginationStore;
  const {
    labs,
    searchValue,
    setIsDeleteLabOpen,
    setLabsColumnList,
    setLabsEntireColumns,
    setSearchValue,
    setSelectedLab,
    selectedLab,
    isLabRefreshed,
  } = labListStore;

  // Props Const
  const { fetchLabs, searchData } = viewModel;

  // State Const
  const [actionsDisabled, setActionsDisabled] = React.useState<boolean>(true);
  const [isColumnEditorOpen, { setTrue: showColumnEditor, setFalse: hideColumnEditor }] = useBoolean(false);

  // Other Const
  const columnEditorKey = ColumnEditorKeys.LABS;
  const columnDefinitions = config.labsColumnDefinitions;
  const history = useHistory();
  const { isPartnerMode } = appSettingsStore;

  React.useEffect(() => {
    const { getEditorColumns } = editColumnsStore;
    const storedColumns = getEditorColumns(columnEditorKey);
    const { userColumns, allColumns } = processColumns(storedColumns, columnDefinitions);
    const paginationDefaults = getPaginationDefaults(handlePaginationChange, EnablePagination.LABS, true);

    setLabsColumnList(userColumns);
    setLabsEntireColumns(allColumns);
    setPaginationType(paginationDefaults);
  }, []);

  React.useEffect(() => {
    if (fetchLabs) {
      fetchLabs();
    }
  }, [fetchLabs, isLabRefreshed]);

  React.useEffect(() => {
    searchData(Pagination.CURRENT_PAGE);
  }, [searchValue, labs]);

  const handlePaginationChange = (currentPage: number): void => {
    searchData(currentPage);
  };

  const selectRow = (item: Selection): void => {
    const selectedRowItems: IObjectWithKey[] = item.getSelection();
    const rowSelected: boolean = selectedRowItems?.length === 1;

    if (rowSelected) {
      const selection: LabType = selectedRowItems[0] as LabType;

      setSelectedLab(selection);
      setActionsDisabled(false);
    }
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      return selectRow(selection);
    },
  });

  const commandBarItems: ICommandBarItemProps[] = [];

  !isPartnerMode &&
    commandBarItems.push({
      key: Labs.CREATE_LAB,
      text: t(Labs.CREATE_LAB, { ns: NS.LABS }),
      title: t(Labs.CREATE_LAB, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: () => {
        openLabPanel();
      },
    });

  commandBarItems.push(
    {
      key: Experiments.VIEW_EXPERIMENT_STATUS,
      text: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      title: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.VIEW_STATUS },
      onClick: () => {
        history.push({
          pathname: `${Navigation.GANYMEDE.EXPERIMENTS}/${selectedLab.labId}`,
        });
      },
      disabled: actionsDisabled,
    },
    {
      key: Labs.DOWNLOAD_INSTALLER,
      text: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      title: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      disabled: actionsDisabled,
      subMenuProps: {
        items: [
          {
            key: Labs.DOWNLOAD_EXE,
            text: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_EXE },
            onClick: () => labDownloadHelper(selectedLab.labId, systemMessageStore, true),
          },
          {
            key: Labs.DOWNLOAD_MSI,
            text: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_MSI },
            onClick: () => labDownloadHelper(selectedLab.labId, systemMessageStore, false),
          },
        ],
      },
    },
    {
      key: Common.REFRESH,
      text: t(Common.REFRESH, { ns: NS.COMMON }),
      title: t(Common.REFRESH, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        fetchLabs();
      },
      itemDivider: true,
    },
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Labs.DELETE_LAB,
      text: t(Labs.DELETE_LAB, { ns: NS.LABS }),
      title: t(Labs.DELETE_LAB, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DELETE },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        setIsDeleteLabOpen(true);
        event.stopPropagation();
      },
      disabled: actionsDisabled,
    },
  );

  const farItems: ICommandBarItemProps[] = [
    {
      key: Common.EDIT_COLUMNS,
      text: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      title: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        showColumnEditor();
      },
    },
  ];

  const searchTextFilter: IOverflowSetItemProps[] = [
    {
      key: Common.SEARCH_TEXT,
      onRender: () => (
        <SearchBox
          placeholder={t(Common.SEARCH_TEXT, { ns: NS.COMMON })}
          value={searchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(e) => {
            if (e && e.target) {
              setSearchValue(e.target.value);
            }
          }}
          onSearch={(value: any) => {
            setSearchValue(value);
          }}
          className={`${filterBarStyles['pagefilterbar-item']} ${filterBarStyles['pagefilterbar-searchbox']}`}
          onClear={() => {
            setSearchValue('');
          }}
          spellCheck="false"
        />
      ),
    },
  ];

  return (
    <LabListTemplate
      commandBarItems={commandBarItems}
      filterItems={searchTextFilter}
      farItems={farItems}
      selection={selection}
      hideColumnEditor={hideColumnEditor}
      isColumnEditorOpen={isColumnEditorOpen}
      columnEditorKey={columnEditorKey}
    ></LabListTemplate>
  );
};

const LabListViewController = observer(LabListViewControllerFC);

export default LabListViewController;
