import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import ExperimentsViewController from './ExperimentsViewController';
import ExperimentsViewModel from './ExperimentsViewModel';

const ExperimentsFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const viewModel = new ExperimentsViewModel(rootStore);

  const openPanelOnRowSelect = true;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return <ExperimentsViewController viewModel={viewModel} openPanelOnRowSelect={openPanelOnRowSelect} />;
};

const Experiments = observer(ExperimentsFC);

export default Experiments;
