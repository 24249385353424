import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { ExperimentType } from '@/components/Experiments/ExperimentsTypes';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { CopyType, Delimiters, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { copyToClipboard } from '@/utils/Helpers';

import EssentialConfig from './Essentials.config.json';
import { EssentialsBlockType } from './EssentialsBlockTypes';

import styles from './EssentialsBlock.module.css';

const EssentialsBlockFC: React.FC<EssentialsBlockType> = (props: EssentialsBlockType): React.ReactElement => {
  const { experimentInstance } = props;
  const tooltipId = useId('tooltip');
  const separator = ':';

  const copyButton = (value: string): React.ReactElement => {
    const title = t('copy-to-clipboard', { ns: NS.COMMON });
    const icon = { iconName: SystemIcons.COPY };
    const onClick = () => copyToClipboard(value);

    return (
      <div className={styles['copy-button']}>
        <TooltipHost content={title} id={tooltipId}>
          <IconButton aria-label={title} iconProps={icon} onClick={onClick} />
        </TooltipHost>
      </div>
    );
  };

  const buildRow = (
    key: string,
    value: string,
    canCopy: CopyType = CopyType.CANNOT_COPY,
    url: string = null,
  ): React.ReactElement => {
    // Convert the url to a clickable link, if provided.
    const clickableText = url ? <Link to={url}>{value}</Link> : value;
    const title = t(key, { ns: NS.EXPERIMENTS });

    return (
      <div className={styles['row']} key={key}>
        <div className={styles['label']}>{title}</div>
        <div className={styles['separator']}>{separator}</div>
        <div className={styles['value']}>
          <div className={styles['text']}>
            {clickableText}
            {canCopy === CopyType.CAN_COPY && copyButton(value)}
          </div>
        </div>
      </div>
    );
  };

  const buildGrid = (item: ExperimentType) => {
    if (!item) {
      return null;
    }

    let essentialsConfig = [];

    if (item.type === SystemType.LABS) {
      essentialsConfig = EssentialConfig.labEssentials;
    } else if (item.type === SystemType.AIR) {
      essentialsConfig = EssentialConfig.airEssentials;
    }

    const rows = essentialsConfig.map((configItem) => {
      const labpath = `${Navigation.LABS.LAB_CONTROL}/labId=${item.labId}&isVirtual=${false}`;

      if (item[configItem.fieldName]) {
        return buildRow(
          configItem.key,
          item[configItem.fieldName],
          configItem.copy ? CopyType.CAN_COPY : CopyType.CANNOT_COPY,
          configItem.showUrl && labpath,
        );
      }

      // We do not have data, so we should not be able to copy.
      return buildRow(configItem.key, Delimiters.DASH, CopyType.CANNOT_COPY);
    });

    return rows;
  };

  const grid = buildGrid(experimentInstance);

  return (
    <>
      <div className={styles['essentials-content-container']}>
        <div className={styles['essentials-column-container']}>
          <div className={styles['essentials-column']}>
            <div className={styles['essentials-grid']}>{grid}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const EssentialsBlock = observer(EssentialsBlockFC);

export default EssentialsBlock;
