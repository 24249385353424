import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react';
import { Button, Card, Tabs, Tag } from 'antd';
import { t } from 'i18next';
import * as qs from 'query-string';

import { Colors, Labels, TabPaneKeys } from '@/constants/LabsConstants';
import { Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import { ExperimentInsights } from '../ResultsExplorer/ExperimentInsights';
import { RulesPage } from './RulesPage';

import headerStyles from '@/partials/PageHeader/PageHeader.module.css';

const { TabPane } = Tabs;

const ResultTriageFC = (props: any) => {
  type LocationState = {
    activeTab: string;
    prevPageData: any;
  };

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { tabMemory, setTabMemory } = appSettingsStore;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [expInsightsRefreshed, setExpInsightsRefreshed] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [testStatus, setTestStatus] = React.useState<any>('FAIL');
  const [companyName, setCompanyName] = React.useState<any>();
  const queryParams = qs.parse(props.location.pathname);
  const id = queryParams['/labs/resultTriage/id'];

  const history = useHistory();
  const location = useLocation<LocationState>();
  const insights = t('insights', { ns: NS.TITLES });
  const pageName = TabMemoryKeys.RESULTS_TRIAGE;
  const currentActiveTab: string = tabMemory[pageName as string];

  React.useEffect(() => {
    const activeTab = location.state?.activeTab;

    const updatedState = { ...location.state };
    delete updatedState.activeTab;

    if (activeTab) {
      setTabMemory(pageName, activeTab);
      history.replace({ ...location, state: updatedState });
    }
  }, [location.state?.activeTab]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, tabMemory]);

  const handleRefreshExpInsights = () => {
    // Triggered when Apply Rule button is clicked in Rules Tab
    setExpInsightsRefreshed(true);
    setTabMemory(pageName, TabPaneKeys.experimentInsights);
  };

  React.useEffect(() => {
    const company = queryParams.companyName;
    setCompanyName(company);
    logManagementRequestService
      .getLogsById(id.toString(), company.toString())
      .then((data) => {
        setData(data);
        setTestStatus(data[0].Status);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  const handleTabChange = (key) => {
    setTabMemory(pageName, key);

    if (key === TabPaneKeys.rulesPage) {
      setExpInsightsRefreshed(false);
    }
  };

  const goBackToPrevPage = () => {
    const prevPageData = props.location.state.prevPageDetails;

    history.push({
      pathname: prevPageData.url,
      state: { activeTab: prevPageData.activeTab },
    });
  };

  return (
    <div className="ganymede-wrapper">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <Card
        title={<span className={headerStyles.title}>{Labels.resultReviewTool}</span>}
        loading={loading}
        extra={<Button onClick={goBackToPrevPage}>Back</Button>}
      >
        {!loading && (
          <>
            <div className="row">
              <h6 className="col-3">{Labels.experimentName}</h6>
              <span className="col-3">{data[0].TestName}</span>
            </div>

            <div className="row">
              <h6 className="col-3">{Labels.experimentStatus}</h6>
              <span className="col-3">
                <Tag key={testStatus} color={testStatus === Labels.pass ? Colors.green : Colors.red}>
                  {testStatus}
                </Tag>
              </span>
            </div>
            <div className="row mb-4">
              <h6 className="col-3">{Labels.experimentInstanceId}</h6>
              <span className="col-3">{id}</span>
            </div>
            <Pivot defaultSelectedKey={currentActiveTab} onLinkClick={(item) => handleTabChange(item.props.itemKey)}>
              <PivotItem headerText={insights} key={TabPaneKeys.experimentInsights} itemKey={TabPaneKeys.experimentInsights}>
                <ExperimentInsights id={id} companyName={companyName} expInsightsRefreshed={expInsightsRefreshed} />
              </PivotItem>
              <PivotItem headerText={Labels.rulesTab} key={TabPaneKeys.rulesPage} itemKey={TabPaneKeys.rulesPage}>
                <RulesPage id={id} testResult={data[0]} companyName={companyName} refreshExpInsights={handleRefreshExpInsights} />
              </PivotItem>
            </Pivot>
          </>
        )}
      </Card>
    </div>
  );
};

export const ResultTriage = observer(ResultTriageFC);
