import React from 'react';
import { observer } from 'mobx-react';
import { Person, PersonCardInteraction, PersonViewType, ViewType } from '@microsoft/mgt-react';

import { PersonDetailsType } from '@/partials/UserProfile/UserProfileTypes';
import { RootStoreContext } from '@/stores/RootStore';

import styles from './UserInfo.module.css';

const UserInfoTemplateFC: React.FC = (): React.ReactElement => {
  const { userInfoStore } = React.useContext(RootStoreContext);
  const name = userInfoStore.name;
  const userName = userInfoStore.userName;
  const companyName = userInfoStore.companyName;
  const personDetails: PersonDetailsType = {
    displayName: name,
    email: userName,
  };

  return (
    <div>
      <Person
        className={styles['person-image']}
        personDetails={personDetails}
        fetchImage={true}
        avatarSize="large"
        view={ViewType.image}
      />
      <div className={`${styles['text']} ${styles['name']}`}>{name}</div>
      <div className={styles['text']}>{userName}</div>
      <div className={`${styles['text']} ${styles['id']}`}>{companyName}</div>
    </div>
  );
};

const UserInfoTemplate = observer(UserInfoTemplateFC);

export default UserInfoTemplate;
