import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { TextField } from '@fluentui/react';
import { t } from 'i18next';
import { Button, Checkbox } from 'semantic-ui-react';

import { Namespaces as NS } from '@/constants/SystemConstants';

import { CosmosType } from './CosmosTypes';

import styles from './Cosmos.module.css';

const CosmosTemplateFC: React.FC<CosmosType> = (props: CosmosType) => {
  const {
    getEntryKey,
    getEntryValue,
    createEntryKey,
    createEntryValue,
    updateEntryKey,
    updateEntryValue,
    deleteEntryKey,
    setGetEntryKey,
    setCreateEntryKey,
    setCreateEntryValue,
    setUpdateEntryKey,
    setUpdateEntryValue,
    setDeleteEntryKey,
    toggleIsGlobal,
    getEntry,
    createEntry,
    updateEntry,
    deleteEntry,
  } = props;

  return (
    <div>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>cosmos</Trans>
      </h2>
      <Checkbox className={styles['width']} label={'Global Request'} defaultValue={false} onChange={toggleIsGlobal} />
      <div className={styles['entry']}>
        <TextField
          className={styles['width']}
          label={t('get-entry-key', { ns: NS.DEFAULT })}
          defaultValue={getEntryKey}
          onChange={(event: any, value: any) => setGetEntryKey(value)}
        />
        <Button className={styles['item']} floated="right" primary type="submit" content={'Get'} onClick={getEntry}></Button>
        <div className={styles['item']}>
          <Trans>get-entry-value</Trans> {getEntryValue}
        </div>
      </div>
      <div className={styles['entry']}>
        <TextField
          className={styles['width']}
          label={t('create-entry-key', { ns: NS.DEFAULT })}
          defaultValue={createEntryKey}
          onChange={(event: any, value: any) => setCreateEntryKey(value)}
        />
        <TextField
          className={styles['width']}
          label={t('create-entry-value', { ns: NS.DEFAULT })}
          defaultValue={createEntryValue}
          onChange={(event: any, value: any) => setCreateEntryValue(value)}
        />
        <Button floated="right" primary type="submit" content={'Create'} onClick={createEntry}></Button>
      </div>
      <div className={styles['entry']}>
        <TextField
          className={styles['width']}
          label={t('update-entry-key', { ns: NS.DEFAULT })}
          defaultValue={updateEntryKey}
          onChange={(event: any, value: any) => setUpdateEntryKey(value)}
        />
        <TextField
          className={styles['width']}
          label={t('update-entry-value', { ns: NS.DEFAULT })}
          defaultValue={updateEntryValue}
          onChange={(event: any, value: any) => setUpdateEntryValue(value)}
        />
        <Button floated="right" primary type="submit" content={'Update'} onClick={updateEntry}></Button>
      </div>
      <div className={styles['entry']}>
        <TextField
          className={styles['width']}
          label={t('delete-entry-key', { ns: NS.DEFAULT })}
          defaultValue={deleteEntryKey}
          onChange={(event: any, value: any) => setDeleteEntryKey(value)}
        />
        <Button floated="right" primary type="submit" content={'Delete'} onClick={deleteEntry}></Button>
      </div>
    </div>
  );
};

const CosmosTemplate = observer(CosmosTemplateFC);

export default CosmosTemplate;
