import { CancelToken } from 'axios';

import { CreateLabType } from '@/components/CreateLab/CreateLabType';
import BaseRequestService from '@/services/request-services/RequestService';
import { labsBackendEndpoint } from '@/utils/Env';

class LabsRequestService extends BaseRequestService {
  constructor(baseURL = '') {
    super(baseURL);
  }

  async createLab(labName: string, env: any, toolkitId: any, osIagmeId: any, company: any) {
    const payload = {
      LabName: labName,
      IsInternal: env,
      CsiToolkitId: Number(toolkitId),
      DefaultOsImageId: Number(osIagmeId),
      CompanyId: company,
    };

    const { data } = await this.post('Lab', payload);

    return data;
  }

  // async createNewLab(payload: CreateLabType) {
  //   const { data } = await this.post('Lab', payload);

  //   return data;
  // }

  // async updateLab(labId: any, osImageId: any) {
  //   const { data } = await this.put(`lab/${labId}/${osImageId}`);

  //   return data;
  // }

  // async getLabs() {
  //   const { data } = await this.get('lab');

  //   return data;
  // }

  // async getUsers() {
  //   const { data } = await this.get('lab/users');

  //   return data;
  // }

  // async getLabsUser(labId: any) {
  //   const { data } = await this.get(`lab/${labId}/users`);

  //   return data;
  // }

  async getTestsBySessionId(labId: any, macAddress: any, sessionId: any) {
    const { data } = await this.get(`systems/${labId}/${macAddress}/${sessionId}/tests`);

    return data;
  }

  // async getLabDetails(labId: any) {
  //   const { data } = await this.get(`lab/${labId}`);

  //   return data;
  // }

  async getCompanyName(id: string) {
    const { data } = await this.get(`company/companyId/${id}`);
    let companyName = '';

    if (data.data !== undefined) {
      companyName = data.data.companyName;
    }

    return companyName;
  }

  async getCompanyNameByUserId() {
    const { data } = await this.get('company/user');

    return data;
  }

  async getExperimentsForVersion2(labId: any, cancelToken?: CancelToken) {
    const { data } = await this.get(
      `v2/experiments?labId=${Number(labId)}&$apply=groupby((status, name, experimentDefinitionId, description, lastModified))`,
      null,
      { cancelToken },
    );

    return data;
  }

  async getExperimentInstancesForVersion2(labId: any, pageSize: any, cancelToken?: CancelToken) {
    const { data } = await this.get(`v2/experiments?labId=${Number(labId)}&$top=${Number(pageSize)}&$orderBy=created desc`, null, {
      cancelToken,
    });

    return data;
  }

  async getPendingAndInProgressInstances(labId: any, selectedExperimentdefinition: any, cancelToken?: CancelToken) {
    // eslint-disable-next-line quotes
    const status = "(status eq 'Pending' or status eq 'InProgress')";
    const { data } = await this.get(
      `v2/experiments?labId=${Number(
        labId,
      )}&$skip=0&$top=500&$filter=(experimentDefinitionId eq '${selectedExperimentdefinition}') and ${status} &$select=id`,
      null,
      { cancelToken },
    );
    const list = data !== undefined && data.length > 0 ? data.map((item: any) => item.id) : [];

    return list;
  }

  async getExperimentInstanceDetails(labId: any, selectedExperimentInstanceId: any, cancelToken?: CancelToken) {
    const getExperimentInstanceURL = `v2/experiments?labId=${Number(labId)}&$filter=(id eq '${selectedExperimentInstanceId}')`;
    const { data } = await this.get(getExperimentInstanceURL, null, { cancelToken });
    const list = data?.length > 0 ? data : [];

    return list;
  }

  async getExperimentInstancesVersion2(
    labId: any,
    selectedExperimentdefinition: any,
    instancePageSize: number,
    instancePageNumber: number,
    instanceFilter: any,
    cancelToken?: CancelToken,
  ) {
    const skip = instancePageSize * (instancePageNumber - 1);

    let instanceFilterQuery = '';
    let statusFilterQuery = '';

    if (instanceFilter !== undefined && instanceFilter.id !== null) {
      const filter: string[] = instanceFilter.id;

      for (const key in filter) {
        if (instanceFilterQuery !== '') {
          instanceFilterQuery += `or id eq '${filter[key as string]}'`;
        } else {
          instanceFilterQuery += `id eq '${filter[key as string]}'`;
        }
      }
    }

    if (instanceFilter !== undefined && instanceFilter.status !== null) {
      const filter: string[] = instanceFilter.status;

      for (const key in filter) {
        if (statusFilterQuery !== '') {
          statusFilterQuery += `or status eq '${filter[key as string]}'`;
        } else {
          statusFilterQuery += `status eq '${filter[key as string]}'`;
        }
      }
    }

    if (instanceFilterQuery !== '' && statusFilterQuery !== '') {
      instanceFilterQuery = `(${instanceFilterQuery}) and (${statusFilterQuery})`;
    } else if (instanceFilterQuery !== '') {
      instanceFilterQuery = `(${instanceFilterQuery})`;
    } else if (statusFilterQuery !== '') {
      instanceFilterQuery = `(${statusFilterQuery})`;
    }

    let dataCall = `v2/experiments?labId=${Number(
      labId,
    )}&$skip=${skip}&$top=${instancePageSize}&$filter=experimentDefinitionId eq '${selectedExperimentdefinition}'`;

    let totalCountCall = `v2/experiments?labId=${Number(
      labId,
    )}&$filter=experimentDefinitionId eq '${selectedExperimentdefinition}'`;

    if (instanceFilterQuery !== '') {
      dataCall += ` and ${instanceFilterQuery}`;
      totalCountCall += ` and ${instanceFilterQuery}`;
    }

    dataCall += ' &$orderBy=created desc&$select=id,status,created,lastModified,experimentDefinitionId,ipAddress';

    totalCountCall += ' &$count=true';

    const data = await this.get(dataCall, null, { cancelToken });
    const totalCount = await this.get(totalCountCall, null, { cancelToken });

    return { experimentInstances: data.data, total: totalCount.data };
  }

  async getExperimentInstanceSummaryVersion2(labId: any, selectedExperimentdefinition: any, count: any, cancelToken?: CancelToken) {
    const { data } = await this.get(
      `v2/experiments?labId=${Number(
        labId,
      )}&$filter=experimentDefinitionId eq '${selectedExperimentdefinition}' &$orderBy=created desc&$select=id&$top=${count}`,
      null,
      { cancelToken },
    );
    const list = data !== undefined && data.length > 0 ? data.map((item: any) => ({ value: item.id, text: item.id })) : [];

    return list;
  }

  async getInstancesCountVersion2(labId: any, selectedExperimentdefinition: any, cancelToken?: CancelToken) {
    const { data } = await this.get(
      `v2/experiments?labId=${Number(
        labId,
      )}$filter=experimentDefinitionId eq '${selectedExperimentdefinition}' &$apply=groupby((name, experimentDefinitionId), aggregate(id with count as count))`,
      null,
      { cancelToken },
    );

    return data;
  }

  async getExperimentStepsVersion2(instanceId: any, view: any, cancelToken?: CancelToken) {
    const { data } = await this.get(`v2/experiments/${instanceId}/agent-steps?view=${view}`, null, { cancelToken });

    return data;
  }

  async getExperimentFullStepsVersion2(instanceId: any, view: any, cancelToken?: CancelToken) {
    const { data } = await this.get(`v2/experiments/${instanceId}/steps?view=${view}`, null, { cancelToken });

    return data;
  }

  async getExperimentStepFullDetailsVersion2(instanceId: any, stepId: any, cancelToken?: CancelToken) {
    const { data } = await this.get(`v2/experiments/${instanceId}/agent-steps/${stepId}`, null, { cancelToken });

    return data;
  }

  // async getSystem(labId: any) {
  //   const { data } = await this.get(`systems/${labId}`);

  //   return data;
  // }

  // async getVirtualSystem(labId: any) {
  //   const { data } = await this.get(`virtualSystems/${labId}`);

  //   return data;
  // }

  async getOSImageList(labId: any) {
    const { data } = await this.get(`lab/${labId}/osImages`);

    return data;
  }

  // async getOSImages() {
  //   const { data } = await this.get('lab/osImages');

  //   return data;
  // }

  async getExperimentsForMachines(labId: any, macAddress: any) {
    const { data } = await this.get(`experiments/${labId}/${macAddress}/experimentsForMachines`);

    return data;
  }

  // async getCompanies() {
  //   const { data } = await this.get('company');

  //   return data;
  // }

  // async getUserLabs() {
  //   const { data } = await this.get('lab/userLabs');

  //   return data;
  // }

  // async getLabManifest() {
  //   const { data } = await this.get('ManifestVersion');

  //   return data;
  // }

  // async deleteLab(labId: any) {
  //   const { data } = await this.delete(`lab/${labId}`);

  //   return data;
  // }

  // async deleteLabUser(labId: any, user: any) {
  //   const { data } = await this.delete(`lab/${labId}/user?userEmail=${user}`);

  //   return data;
  // }

  async upgradeLab(labId: any) {
    const payload = {
      TargetLabIds: [Number(labId)],
    };

    const { data } = await this.post('message/upgradelab', payload);

    return data;
  }

  async getTestSessions(labId: any, macAddress: any) {
    const { data } = await this.get(`systems/${labId}/${macAddress}/testSessions`);

    return data;
  }

  async getMessage(labId: any, macAddress: any, systemName: any) {
    const { data } = await this.get(`message/${labId}/machine/${macAddress}/${systemName}`);

    return data;
  }

  async getSystemResponse(labId: any, macAddress: any, messageId: any) {
    const { data } = await this.get(`message/${labId}/machine/${macAddress}/${messageId}/response`);

    return data;
  }

  // async addUser(labId: any, user: any, isAdmin: boolean) {
  //   const { data } = await this.post(`lab/${labId}/user/${user}/${isAdmin}`);

  //   return data;
  // }

  // async updateLabName(labId: any, labName: string) {
  //   const { data } = await this.put(`lab/${labId}/edit/${labName}`);

  //   return data;
  // }

  async createVirtualLab(virtualLabName: string, baseOnLabId: number, systems: number[], racks: number[]) {
    const payload = {
      virtualLabName,
      baseOnLabId: Number(baseOnLabId),
      systems,
      racks,
    };

    const { data } = await this.post('virtualLab', payload);

    return data;
  }

  async updateVirtualLab(virtualLabId: number, systems: number[], racks: number[]) {
    const payload = {
      virtualLabId,
      systems,
      racks,
    };

    const { data } = await this.put('virtualLab', payload);

    return data;
  }

  async getAllMachines() {
    const { data } = await this.get('allMachines');

    return data;
  }

  async getAllRacks() {
    const { data } = await this.get('allRacks');

    return data;
  }

  async getBatchSystemMessageResponse(systemMessageIdList: any) {
    const IdList = [];

    for (const systemMessage of systemMessageIdList) {
      IdList.push({ SystemId: systemMessage.SystemId, SystemMessageId: systemMessage.SystemMessageId });
    }

    const { data } = await this.post('message/systemMessageResponse', IdList);

    return data;
  }

  async addRackManager(labId: string, ipAddress: string, loginId: string, loginPassword: string) {
    const payload = {
      labId: Number(labId),
      ipAddress,
      loginId,
      loginPassword,
    };

    const { data } = await this.post('message/addUpdateRackManager', payload);

    return data;
  }

  async UpdateRackManager(labId: string, labMessageId: string, ipAddress: string, loginId: string, loginPassword: string) {
    const payload = {
      labId: Number(labId),
      LabControllerMessageId: Number(labMessageId),
      ipAddress,
      loginId,
      loginPassword,
    };

    const { data } = await this.post('message/addUpdateRackManager', payload);

    return data;
  }

  async getAddRackManagerState(labId: any) {
    const { data } = await this.get(`message/getRackManagerState?labId=${labId}`);

    return data;
  }

  async deleteRackManager(labId: string, labMessageId: string, ipAddress: string, loginId: string) {
    const { data } = await this.delete(`message/deleteRackManager/${labId}/${labMessageId}/${ipAddress}/${loginId}`);

    return data;
  }

  async deleteAddedRackManager(selectedId: string) {
    const { data } = await this.delete(`message/deleteAddedRackManager/${selectedId}`);

    return data;
  }

  async getSystemSpec(systemId: string) {
    const { data } = await this.get(`systems/${systemId}/spec`);

    return data;
  }

  async getAgentHeartbeat(agentId: string) {
    const payload = {
      agentId: agentId,
    };

    const { data } = await this.get('heartbeats', payload);

    return data;
  }
}

export const labsRequestService = new LabsRequestService(labsBackendEndpoint);
