const Reliability = {
  DEFAULT_DAYS: 30,
  DEFAULT_HOURS: 24,
  DEFAULT_DATE_RANGE: '30d',
};

const ReliabilityRegex = {
  // It will match <http[s]://anything-aything> format
  DEV_SLOTS: '^https?://[a-zA-Z-]+-[a-zA-Z-]*',
};

export { Reliability, ReliabilityRegex };
