const PivotItemKeys = {
  SUMMARY: 'summary',
  LOGS: 'logs',
};

const PivotItems = {
  ALL_RESULTS: 'allResults',
  MYLAB_RESULTS: 'myLabResults',
  MY_RESULTS: 'myResults',
};

const Statuses = {
  FAIL: 'fail',
  OTHER: 'other',
  PASS: 'pass',
};

const StatusIcons = {
  [Statuses.FAIL]: 'StatusErrorFull',
  [Statuses.OTHER]: 'SkypeCircleClock',
  [Statuses.PASS]: 'SkypeCircleCheck',
};

const TemplateConstants = {
  DEFINITION_TEMPLATE_ID: 'Experiment Definition Id',
  EXPERIMENT_DEFINITION_ID: 'experimentDefinitionId',
  EXPERIMENT_INSTANCE_ID: 'experimentInstanceId',
};

// Used in Filters
const ColumnNames = {
  EXECUTED_BY: 'executedBy',
  EXPERIMENT_NAME: 'experimentName',
  HOST_NAME: 'hostName',
  LAB_ID: 'labId',
  LAST_EXECUTED_BY: 'lastExecutedBy',
  LAST_STATUS: 'lastStatus',
  TEST_NAME: 'testName',
  STATUS: 'status',
};

export { ColumnNames, PivotItemKeys, PivotItems, Statuses, StatusIcons, TemplateConstants };
