/*----------------------------------------------------------------------------------------------------------------------------
# AccessibilityTemplate.tsx: 
# This Component is used to display the OneFleetPortal Accessibility page in an iframe.
# This Component can be routed in your application to show the external content in an embedded view.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { accessiblityInsightsEndpoint } from '@/utils/Env';

import styles from './Accessibility.module.css';

const AccessibilityTemplateFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  return (
    <div className={styles.accessibilityPage}>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <iframe src={accessiblityInsightsEndpoint} title={t('accessibility-report', { ns: NS.TITLES })} />
    </div>
  );
};

const AccessibilityReport = observer(AccessibilityTemplateFC);

export default AccessibilityReport;
