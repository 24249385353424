import React from 'react';
import { observer } from 'mobx-react-lite';
import { MessageBar, Modal, Pivot, PivotItem } from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { t } from 'i18next';

import { ExperimentStatus } from '@/components/_air/experiments/SingleEntryPoint/Pages/ExperimentStatus';
import config from '@/components/Experiments/Experiments.config.json';
import { Defaults } from '@/constants/AirConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import DataErrorTemplate from '@/partials/DataError/DataErrorTemplate';
import EssentialsBlock from '@/partials/EssentialsBlock/EssentialsBlockTemplate';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { extractDefinition, formatJsonResponse, modifiedColumnConfiguration } from '@/utils/Helpers';

import { ExperimenDetailsAccordionKeys, ExperimentDetailsVCType } from './ExperimentDetailsTypes';

import styles from './ExperimentDetails.module.css';

const ExperimentDetailsTemplateFC: React.FC<ExperimentDetailsVCType> = (props: ExperimentDetailsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, experimentDetailsStore } = rootStore;
  const { tabMemory } = appSettingsStore;
  const {
    experimentDetails,
    experimentHasData,
    isAirExperiment,
    isLabsExperiment,
    isExperimentStepPopupOpen,
    experimentStepDetails,
    closeExperimentStepsPopup,
    closeExperimentModal,
    isExperimentModalOpen,
    canViewInstanceResults,
  } = experimentDetailsStore;

  const {
    title,
    content,
    setContent,
    localMessage,
    commandItems,
    onDismissMessage,
    selection,
    copyButton,
    showModalCloseButton,
    onLinkClick,
    agentFilterItems,
    agentStepChanges,
    filteredSteps,
    chart,
    paginationType,
  } = props;

  const experimentInstanceId = experimentDetails?.instanceId;
  const isLoadingData = !experimentDetails?.agentSteps;
  const backIcon = { iconName: SystemIcons.CANCEL };
  const cancelIcon = { iconName: SystemIcons.CANCEL };

  React.useEffect(() => {
    const stepsTabContent = () => {
      if (isLoadingData) {
        return (
          <div className={styles['panel-tab']}>
            <LoadingSpinner />
          </div>
        );
      }

      return (
        <>
          <PageFilterBar items={agentFilterItems}></PageFilterBar>
          <div className={styles['panel-tab']}>
            <TableViewViewController
              items={filteredSteps}
              columns={modifiedColumnConfiguration(config.experimentDisplaySteps)}
              displayColumns={config.stepDisplayColumns}
              enableToolBar={false}
              enableColumnSort={true}
              tableStyle={styles['panel-table']}
              selection={selection}
              selectionMode={SelectionMode.single}
              checkboxVisibility={CheckboxVisibility.hidden}
              isCompact={true}
              isStickyHeader={true}
              paginationType={paginationType}
            />
            <div className={styles['logs-container']}>
              <RawLogsTreeView id={experimentDetails.instanceId} companyName={experimentDetails.companyName} showLogText={true} />
              <div className={styles['vertical-divider']}></div>
              <div className={styles['logs-view-container']}>
                <FilePreview companyName={experimentDetails.companyName} />
              </div>
            </div>
          </div>
        </>
      );
    };

    const jsonTabContent = () => {
      const definition = extractDefinition(experimentDetails?.json || {});
      let formattingError = false;
      let formattedJson;

      try {
        formattedJson = formatJsonResponse(definition);
      } catch (error) {
        const description = t('experiments-json-error', { ns: NS.ERRORS });

        formattingError = true;
        formattedJson = <>{description}</>;
      }

      return (
        <>
          {isLoadingData ? (
            <LoadingSpinner />
          ) : (
            <pre className={styles['json-block']}>
              {experimentDetails && formattedJson}
              <div className={styles['json-copy-button']}>{!formattingError && copyButton(formattedJson)}</div>
            </pre>
          )}
        </>
      );
    };

    const stepsTabItem = (
      <PivotItem headerText={t('steps', { ns: NS.EXPERIMENTS })} key={'tab-steps'} itemKey={'tab-steps'}>
        {experimentDetails && stepsTabContent()}
      </PivotItem>
    );

    const jsonTabItem = (
      <PivotItem headerText={t('json', { ns: NS.EXPERIMENTS })} key={'tab-json'} itemKey={'tab-json'}>
        <ScrollableContent scrollable={true} scrollableX={true}>
          {experimentDetails && jsonTabContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const accordionOpenItems: string[] = [ExperimenDetailsAccordionKeys.ESSENTIALS];

    const accordion = (
      <>
        <Accordion
          collapsible={true}
          multiple={true}
          defaultOpenItems={accordionOpenItems}
          className={styles['accordion-content-container']}
          data-id={'experiments-panel-accordion'}
        >
          <AccordionItem className={styles['accordion-item']} value={ExperimenDetailsAccordionKeys.ESSENTIALS}>
            <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
              {t('essentials', { ns: NS.EXPERIMENTS })}
            </AccordionHeader>
            <AccordionPanel className={styles['accordion-panel']}>
              <div className={styles['essentials-block']}>
                <div className={styles['details']}>
                  <EssentialsBlock experimentInstance={experimentDetails}></EssentialsBlock>
                </div>
                <div className={styles['divider']}></div>
                <div className={styles['chart']}>{chart || <LoadingSpinner />}</div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </>
    );

    const activeTab: string = tabMemory[TabMemoryKeys.EXPERIMENT_DETAILS];

    const content = (
      <>
        {experimentHasData && accordion}
        <div className={styles['modal-body-content']}>
          <Pivot defaultSelectedKey={activeTab} onLinkClick={onLinkClick}>
            {isLabsExperiment && stepsTabItem}
            {!isLabsExperiment && <LoadingSpinner />}
            {jsonTabItem}
          </Pivot>
        </div>
      </>
    );

    setContent(content);
  }, [
    tabMemory,
    localMessage,
    experimentHasData,
    experimentInstanceId,
    isLabsExperiment,
    experimentDetails,
    agentStepChanges,
    chart,
    canViewInstanceResults,
    filteredSteps,
  ]);

  const noStepDetails = !experimentStepDetails;
  const stepName = experimentStepDetails?.definition?.['name'];
  let formattingError = false;
  let formattedStepDetails;

  try {
    formattedStepDetails = formatJsonResponse(experimentStepDetails);
  } catch (error) {
    const description = t('experiments-json-error', { ns: NS.ERRORS });

    formattingError = true;
    formattedStepDetails = <>{description}</>;
  }

  if (isAirExperiment) {
    // NOTE: For AIR Experiments, we will show the Component from the AIR Results page.
    const teamName = Defaults.teamName;
    const goalId = experimentDetails?.instanceId || experimentDetails?.id;
    const experimentName = experimentDetails?.json?.['definition']?.['experimentName'];

    return (
      <div>
        <ExperimentStatus team={teamName} goal={goalId} experiment={experimentName} />
      </div>
    );
  }

  return (
    <>
      <div className={styles['header']}>
        {showModalCloseButton && (
          <>
            <h5 className={styles['heading']}>{title}</h5>
            <IconButton
              iconProps={cancelIcon}
              className={styles['icon']}
              title={t('close-modal', { ns: NS.COMMON })}
              ariaLabel={t('close-modal', { ns: NS.COMMON })}
              onClick={closeExperimentModal}
            />
          </>
        )}
        {isExperimentStepPopupOpen && (
          <>
            {isExperimentModalOpen && <h5 className={styles['heading']}>{title}</h5>}
            <IconButton
              iconProps={backIcon}
              className={styles['icon']}
              title={t('go-back', { ns: NS.COMMON })}
              ariaLabel={t('go-back', { ns: NS.COMMON })}
              onClick={closeExperimentStepsPopup}
            />
          </>
        )}
        <>
          {localMessage && <MessageBar onDismiss={onDismissMessage}>{localMessage}</MessageBar>}
          <PageCommandBar items={commandItems}></PageCommandBar>
        </>
      </div>
      {isExperimentStepPopupOpen && (
        <Modal
          key="steps-popup"
          titleAriaId="steps-popup"
          className="ganymede-wrapper"
          containerClassName={`${styles['steps-popup']} ${noStepDetails ? styles['loading'] : ''}`}
          isOpen={isExperimentStepPopupOpen}
          isBlocking={false}
          dragOptions={{
            moveMenuItemText: t('move', { ns: NS.COMMON }),
            closeMenuItemText: t('close', { ns: NS.COMMON }),
            menu: ContextualMenu,
            keepInBounds: false,
            dragHandleSelector: '.ms-Modal-scrollableContent .drag-handle',
          }}
          onDismiss={closeExperimentStepsPopup}
        >
          <>
            <h5 className={`drag-handle ${styles['heading']}`}>{stepName}</h5>
            <IconButton
              iconProps={cancelIcon}
              className={styles['icon']}
              title={t('close-popup', { ns: NS.COMMON })}
              ariaLabel={t('close-popup', { ns: NS.COMMON })}
              onClick={closeExperimentStepsPopup}
            />
            {noStepDetails ? (
              <DataErrorTemplate message={t('no-data-available', { ns: NS.ERRORS })} />
            ) : (
              <>
                <pre className={styles['json-block']}>
                  {formattedStepDetails}{' '}
                  <div className={styles['json-copy-button']}>{!formattingError && copyButton(formattedStepDetails)}</div>
                </pre>
              </>
            )}
          </>
        </Modal>
      )}
      <div className={styles[isExperimentModalOpen ? 'modal-body' : 'details-body']}>
        <>{content}</>
      </div>
    </>
  );
};

const ExperimentDetailsTemplate = observer(ExperimentDetailsTemplateFC);

export default ExperimentDetailsTemplate;
