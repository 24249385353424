import ResultRequestService from './ResultExplorerRequestService';

class LogManagementRequestService extends ResultRequestService {
  async getAllCompanies() {
    const { data } = await this.get('groups/company');

    return data;
  }

  async getCompanyNameByUserId() {
    const { data } = await this.get('groups/company/user');

    return data;
  }

  async getUserLogs(companyName: string) {
    const { data } = await this.get(`userLogs/parsedLogs?companyName=${companyName}`);

    return data;
  }

  async getLogs(companyName: string, labs?: string, timeStampDateFrom?: number, timeStampDateTo?: number) {
    let query = '';

    if (labs !== '') {
      const labIds = labs.split(',');

      for (const key in labIds) {
        query += `${query ? '&' : ''}LabIds=${labIds[key as string]}`;
      }
    }

    let url: string;

    if (companyName) {
      url = `parse/parsedLogs/all${companyName ? `?${query ? `${query}&` : ''}companyName=${companyName}` : ''}`;

      if (timeStampDateFrom && timeStampDateTo) {
        url += `&timeStampDateFrom=${timeStampDateFrom}&timeStampDateTo=${timeStampDateTo}`;
      }

      const result = await this.get(url);
      return result.data;
    } else {
      return [];
    }
  }

  async getUserLabLogs(companyName?: string, labs?: string) {
    let result = null;
    let query = '';

    if (labs !== '') {
      const labIds = labs.split(',');

      for (const key in labIds) {
        query += `${query ? '&' : ''}LabIds=${labIds[key as string]}`;
      }
    }

    const url = `userLogs/userLabLogs${companyName ? `?${query ? `${query}&` : ''}companyName=${companyName}` : ''}`;
    if (companyName) result = await this.get(url);

    return result.data;
  }
}

export const logManagementRequestService = new LogManagementRequestService();
