import React from 'react';

// These options limit the kinds of files that our file handler will accept.
const options = {
  types: [
    // right now, and for the forseeable future, we're only accepting JSON files
    {
      description: 'Json',
      accept: {
        'json/*': ['.json'],
      },
    },
  ],
};

// using the File System Access API : https://wicg.github.io/file-system-access/#introduction
// SaveFile : save the experiment data to a given file handle
const SaveFile = async (experiment: string, fileHandle, setHandle: React.Dispatch<React.SetStateAction<FileSystemFileHandle>>) => {
  try {
    if (fileHandle) {
      const fileStream = await fileHandle.createWritable({ keepExistingData: false });

      fileStream.write(experiment);
      fileStream.close();

      return fileHandle;
    } else {
      SaveFileAs(experiment, setHandle);
    }
  } catch (error) {
    console.error(`[App] File Save Failed: ${error}`);
  }
};

// SaveFileAs : Create a new file handle, select a location, and save the given experiment data into a new file.
const SaveFileAs = async (experiment: string, setHandle: React.Dispatch<React.SetStateAction<FileSystemFileHandle>>) => {
  try {
    const fileHandle = await (window as any).showSaveFilePicker(options);
    const fileStream = await fileHandle.createWritable({ keepExistingData: false });

    fileStream.write(experiment);
    fileStream.close();

    // if a setState function was given then set the newly created handle so that it can be access later
    if (setHandle) {
      setHandle(fileHandle);
    }

    return fileHandle;
  } catch (error: any) {
    console.error(`[App] File Save-As Failed: ${error}`);
  }
};

// SelectFile : Get a file from local storage.
const SelectFile = async (setHandle: React.Dispatch<React.SetStateAction<FileSystemFileHandle>>): Promise<File> => {
  const fileHandles = await (window as any).showOpenFilePicker(options);
  const fileHandle: FileSystemFileHandle = fileHandles[0];

  // if a setState function was given then set the newly created handle so that it can be access later
  if (setHandle) {
    setHandle(fileHandle);
  }

  return await fileHandle.getFile();
};

// LoadFile : Load data from a given file.
const LoadFile = (file: File): Promise<string> => {
  try {
    if (!file) {
      throw new Error('No file passed.');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(file);

      reader.onabort = () => {
        throw new Error('File load aborted.');
      };

      reader.onload = async () => {
        const result = reader.result as string;
        resolve(result);
      };

      reader.onerror = (e) => {
        throw e;
      };
    });
  } catch (error) {
    console.error(`[App] File Load Failed: ${error}`);
  }
};

export { LoadFile, SaveFile, SaveFileAs, SelectFile };
