import { action, makeObservable, observable } from 'mobx';

import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LabsControlStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public isSARMAddEditOpen = false;
  public isSARMAddEditClose = true;
  public selectedMachines: any[];
  public selectedLabId: string;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      isSARMAddEditOpen: observable,
      selectedMachines: observable,
      selectedLabId: observable,

      // Actions modify the state.
      setIsSARMAddEditOpen: action,
      setSelectedMachines: action,
      setSelectedLabId: action,
    });

    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;
  }

  public setIsSARMAddEditOpen = () => {
    this.isSARMAddEditOpen = true;
  };

  public setIsSARMAddEditClose = () => {
    this.isSARMAddEditOpen = false;
  };

  public setSelectedMachines = (machines: any[]) => {
    this.selectedMachines = machines || [];
  };

  public setSelectedLabId = (labId: string) => {
    this.selectedLabId = labId;
  };
}

export default LabsControlStore;
