import React from 'react';
import { IconButton, Modal, TooltipHost } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { RawLogsView } from '@/components/_labs/ResultsExplorer/RawLogsView';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Messages, ModalHead } from '@/constants/LabsConstants';

import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/Labs.css';

export const ViewTestLogs = (props: any) => {
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const logsTitleId = useId('TestLogsTitle');
  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const treeHeight = 400;

  return (
    <div>
      <Modal
        titleAriaId={logsTitleId}
        isOpen={props.viewTestLogsModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        className="labs-wrapper rounded"
        onDismiss={props.handleViewTestLogsModalClose}
      >
        <div className={contentStyles.header}>
          <TooltipHost content={Messages.openLogsNewTab} id={logsTitleId} styles={hostStyles}>
            <h6>
              <a
                href={`/testRun/resultCollectionId=${props.resultCollectionGuid}?testName=${props.testName}`}
                target="_blank"
                rel="noopener noreferrer"
                className="clickable"
              >
                {ModalHead.viewTestLogs}
              </a>
            </h6>
          </TooltipHost>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.handleViewTestLogsModalClose}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="container">
            <RawLogsView id={props.resultCollectionGuid} testName={props.testName} treeHeight={treeHeight} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
