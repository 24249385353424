import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, MessageBarType, TextField } from '@fluentui/react';

import { validateIPAddress } from '@/components/_labs/LabsHelper/LabsControlHelper';
import { Buttons, Labels, Messages } from '@/constants/LabsConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { labsRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';

const AddRackManagerFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [ipAddress, setIpAddress] = React.useState<string>(props.selectedIP ? props.selectedIP : '');
  const [loginId, setLoginId] = React.useState<string>(props.selectedIP ? props.selectedLoginId : '');
  const [password, setPassword] = React.useState<string>('');
  const [button, setButton] = React.useState<string>('');

  const failGroupId = 'add-rm-fail';

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (props.action === Labels.editRackManager) {
      setButton(Buttons.editRackManager);
    } else {
      setButton(Buttons.addRackManager);
    }
  }, []);

  const createTextFieldWithLabel = (label: string, value: string, setMethod: any) => (
    <div>
      <TextField
        required
        fullWidth
        variant="outlined"
        label={label}
        inputProps={{ 'aria-label': 'input' }}
        value={value}
        type={label.includes(Labels.password) ? 'password' : 'text'}
        margin="normal"
        onChange={(e) => setMethod(e.target.value)}
      />
    </div>
  );

  const updateAddedRackManager = async (evt: any) => {
    evt.preventDefault();

    if (validateIPAddress(ipAddress.trim()) === false) {
      addFailMessage(Messages.invalidIP);

      return;
    }

    labsRequestService
      .UpdateRackManager(props.labId, props.selectedId, ipAddress.trim(), loginId.trim(), password.trim())
      .then((response) => {
        if (response) {
          if (props.fetchLists) {
            props.fetchLists();
          }

          props.closeModal();
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const addRackManager = async (evt: any) => {
    evt.preventDefault();

    if (validateIPAddress(ipAddress.trim()) === false) {
      addFailMessage(Messages.invalidIP);

      return;
    }

    labsRequestService
      .addRackManager(props.labId, ipAddress.trim(), loginId.trim(), password.trim())
      .then((response) => {
        if (response) {
          if (props.fetchLists) {
            props.fetchLists();
          }

          props.closeModal();
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const addFailMessage = (message: string) => {
    const failMessage: SystemMessageType = {
      message: message,
      type: MessageBarType.error,
      groupId: failGroupId,
      showInPopup: true,
    };

    addGlobalMessage(failMessage);
  };

  return (
    <div>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <div className="text-left">
        <form onSubmit={props.action === Labels.editRackManager ? updateAddedRackManager : addRackManager}>
          {createTextFieldWithLabel(Labels.ipAddress, ipAddress, setIpAddress)}
          {createTextFieldWithLabel(Labels.ID, loginId, setLoginId)}
          {createTextFieldWithLabel(Labels.password, password, setPassword)}
          <br />
          <div>
            <DefaultButton type="submit">{button}</DefaultButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export const AddRackManager = observer(AddRackManagerFC);
