import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, ChoiceGroup, DatePicker, DefaultButton, DirectionalHint, Icon, SearchBox } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { t } from 'i18next';
import moment from 'moment-timezone';

import { Message, Tooltip } from '@/components/_air/common';
import { IconNames, Labels, Messages, Statuses } from '@/constants/AirConstants';
import { DateFormats, TimeRangeOptions, TimeRangeRemoveOptions } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { FilterOptions, Namespaces as NS, SystemConstants } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { experimentResultRequestService } from '@/services/_air/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ExperimentInfo, ExperimentInfoItem, Semaphore } from '@/types/_air';
import { useCancellationToken, useUserSettings } from '@/utils/_air/Hooks';
import { isAPIError } from '@/utils/_air/IsAPIError';
import resources from '@/utils/_air/Resources.json';
import { HandleError } from '@/utils/_labs/HandleError';
import { formatDate } from '@/utils/Dates';
import { filterIndicator } from '@/utils/Helpers';

import { ExperimentProgressBars } from './ExperimentProgressBar';
import { ExperimentStatusIndicator } from './ExperimentStatusIndicator';

import '@/styles/Air.css';
import './result.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

const intermediateJson = resources;

const ExperimentResultFC: React.FC = (props): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { airSettingsStore, appSettingsStore, systemMessageStore, userSettingsStore } = rootStore;
  const {
    airResultsSearchValue,
    airResultsTimeValue,
    airResultsStartDate,
    airResultsEndDate,
    setAirResultsSearchValue,
    setAirResultsTimeValue,
    setAirResultsStartDate,
    setAirResultsEndDate,
    doAirResultsReset,
  } = airSettingsStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { timeZone } = userSettingsStore;

  const [experiment, setExperiment] = React.useState<ExperimentInfoItem[]>([]);
  const [isShowNoDataFoundMessage, setIsShowNoDataFoundMessage] = React.useState<boolean>(false);
  const [showShimmer, setShowShimmer] = React.useState(true);
  const {
    state: { userSettings },
  } = useUserSettings();
  const [filteredData, setFilteredData] = React.useState<ExperimentInfoItem[]>([]);
  const cancellationToken = useCancellationToken();
  const allExperiments = React.useRef<ExperimentInfo[]>();

  const title = t('air', { ns: NS.TITLES });
  const subTitle = t('results', { ns: NS.TABLE });
  const failGroupId = 'AIR-Results-fail';

  const sortExperimentsByDate = (experiments: ExperimentInfo[]): void => {
    experiments.sort((first, second) =>
      moment(second.experimentDateUtc, DateFormats.STANDARD_DATE_TIME).diff(
        moment(first.experimentDateUtc, DateFormats.STANDARD_DATE_TIME),
      ),
    );
  };

  const getColorsByName = (experiment: ExperimentInfo): { [key: string]: string } =>
    experiment.semaphores?.reduce((map: { [index: string]: any }, el: Semaphore) => {
      map[el.shortName] = el.overallSignal.toLowerCase();

      return map;
    }, {});

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    try {
      experimentResultRequestService.getExperimentInfo().then((result) => {
        if (isAPIError(result) || result.length === 0) {
          setShowShimmer(false);
          setIsShowNoDataFoundMessage(true);
          return;
        }

        const experimentData = [];

        sortExperimentsByDate(result);
        allExperiments.current = result;

        result.forEach((executionGoal, ind) => {
          const experimentName = executionGoal.experimentName ?? Statuses.notSpecified;
          const revision = executionGoal.revision;
          const experimentDateUtc =
            moment.tz(executionGoal.experimentDateUtc, timeZone).format(DateFormats.STANDARD_DATE_TIME) ?? Statuses.notSpecified;
          const ls = [];

          const businessSignal = <ExperimentStatusIndicator experiment={executionGoal} />;

          const pathname = `${Navigation.AIR.SCENARIO}/${executionGoal.experimentName}/ ${executionGoal.revision}/${Labels.summary}`;
          const scale = (
            <Link
              aria-label={Labels.checkProgress}
              to={{
                pathname,
                state: { colorsByName: getColorsByName(executionGoal) },
              }}
            >
              <ExperimentProgressBars progress={executionGoal.progress} />
            </Link>
          );

          const experimentInfo = {
            experimentName,
            revision,
            experimentDateUtc,
            businessSignal,
            scale,
          };

          experimentData.push(experimentInfo);
        });

        setExperiment(experimentData);
        setShowShimmer(false);
        setIsShowNoDataFoundMessage(true);
      });
    } catch (error) {
      setShowShimmer(false);
      setExperiment([]);

      const handleErrorProps = {
        error,
        systemMessageStore,
        appSettingsStore,
        failGroupId,
      };

      HandleError(handleErrorProps);
    }
  }, [cancellationToken]);

  const searchExperiments = (value: string, goals: ExperimentInfoItem[]): ExperimentInfoItem[] => {
    if (!value) {
      return goals;
    }

    const formattedValue = value.toLowerCase();

    const filteredExperiments = goals.filter((item: ExperimentInfoItem) => {
      const name = item.experimentName?.toLowerCase() ?? '';
      const revision = item.revision?.toLowerCase() ?? '';

      return name.includes(formattedValue) || revision.includes(formattedValue);
    });

    return filteredExperiments;
  };

  const filterExperimentsByTime = (timeRange: string, goals: ExperimentInfoItem[]): ExperimentInfoItem[] => {
    if (!timeRange || timeRange === TimeRangeRemoveOptions.ALL) {
      return goals;
    }

    return goals.filter((goal) => {
      const time = new Date(goal.experimentDateUtc);
      const matches = time >= airResultsStartDate && time <= airResultsEndDate;

      return matches;
    });
  };

  React.useEffect(() => {
    if (experiment?.length > 0) {
      if (!allExperiments.current) {
        return;
      }

      const filteredByTime = filterExperimentsByTime(airResultsTimeValue, experiment);
      const searchedGoals = searchExperiments(airResultsSearchValue, filteredByTime);

      setFilteredData(searchedGoals);
    } else {
      setFilteredData([]);
    }
  }, [airResultsSearchValue, airResultsTimeValue, airResultsStartDate, airResultsEndDate, experiment]);

  const hasFiltersApplied = () => {
    const searchFilter = !!airResultsSearchValue.trim();
    const dateFilter = airResultsTimeValue !== TimeRangeRemoveOptions.ALL;

    return searchFilter || dateFilter;
  };

  const handleClearAllFilters = () => {
    doAirResultsReset();
  };

  const onSelectRange = (key: string) => {
    const now = new Date();
    const startDate = new Date();

    setAirResultsTimeValue(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      startDate.setHours(now.getHours() - Number(key));
      setAirResultsStartDate(startDate);
      setAirResultsEndDate(now);
    }
  };

  const tooldata = (): JSX.Element => (
    <span>
      <strong>Red:</strong>
      {intermediateJson.businessSignalHover.Red}
      <br />
      <strong>Yellow:</strong>
      {intermediateJson.businessSignalHover.Yellow}
      <br />
      <strong>Green:</strong>
      {intermediateJson.businessSignalHover.Green}
      <br />
      <strong>Blue:</strong>
      {intermediateJson.businessSignalHover.Blue}
    </span>
  );

  const columns = [
    {
      key: 'experiment-name',
      name: t('experiment-name', { ns: NS.TABLE }),
      fieldName: 'experimentName',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'revision',
      name: t('revision', { ns: NS.TABLE }),
      fieldName: 'revision',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'start-date',
      name: t('start-date', { ns: NS.TABLE }),
      fieldName: 'experimentDateUtc',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'business-signal',
      name: t('business-signal', { ns: NS.TABLE }),
      fieldName: 'businessSignal',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRenderHeader: (column) => {
        return (
          <div>
            <span>{column.column.name}</span>
            <span className="business-signal-tooltip">
              <Tooltip directionalHint={DirectionalHint.rightCenter} content={tooldata()}>
                <Icon iconName={IconNames.info} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      key: 'scale',
      name: t('scale', { ns: NS.TABLE }),
      fieldName: 'scale',
      minWidth: 100,
      maxWidth: 100,
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={airResultsTimeValue}
            options={TimeRangeOptions}
            onChange={(ev, option) => {
              onSelectRange(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={airResultsTimeValue !== FilterOptions.CUSTOM}
              onSelectDate={setAirResultsStartDate}
              value={airResultsStartDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(airResultsStartDate.toString(), timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={airResultsTimeValue !== FilterOptions.CUSTOM}
              onSelectDate={setAirResultsEndDate}
              value={airResultsEndDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(airResultsEndDate.toString(), timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-text', { ns: NS.COMMON })}
          title={t('search-text', { ns: NS.COMMON })}
          value={airResultsSearchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event, newValue) => setAirResultsSearchValue(newValue)}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          onClear={() => {
            setAirResultsSearchValue('');
          }}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('start-date', { ns: NS.TABLE })}
          title={t('start-date', { ns: NS.TABLE })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={menuProps}
        />
      ),
    },
    {
      key: 'clear-all-filters',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET_DASHBOARD }}
          onClick={handleClearAllFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
  ];

  const data = filteredData || experiment;
  const hasFilters = hasFiltersApplied();
  const resultsText = filterIndicator(hasFilters, data.length);
  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.AIR.VIEW_STATUS]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent>
            <>
              <PageFilterBar items={filterItems}></PageFilterBar>
              <div>{resultsText}</div>
              {isShowNoDataFoundMessage && !experiment?.length ? (
                <div className="air-wrapper">
                  <Message message={Messages.noDataFound} />
                </div>
              ) : showShimmer ? (
                <LoadingSpinner />
              ) : (
                <TableViewViewController
                  tableStyle="table-scrollable air-results-table"
                  columns={columns as IColumn[]}
                  items={data}
                  enableToolBar={false}
                  enableColumnSort={false}
                  isStickyHeader={true}
                  checkboxVisibility={CheckboxVisibility.hidden}
                />
              )}
            </>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ExperimentResult = observer(ExperimentResultFC);

export default ExperimentResult;
