import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { TextField } from '@fluentui/react';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { IPaginationProps, Pagination } from '@fluentui/react-experiments';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { DropdownType } from '@/partials/Dropdown/DropdownTypes';
import DropdownViewController from '@/partials/Dropdown/DropdownViewController';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { GlobalMessageIncludedType, TableType } from '@/types/TableTypes';

import PaginationViewController from '../Pagination/PaginationViewController';

import styles from './TableView.module.css';

const TableTemplate: React.FC<TableType & GlobalMessageIncludedType> = (
  props: TableType & GlobalMessageIncludedType,
): React.ReactElement => {
  const {
    checkboxVisibility,
    columns,
    displayColumns,
    enableToolBar,
    items,
    groups,
    selection,
    selectionMode,
    isSelectedOnFocus,
    selectionPreservedOnEmptyClick,
    tableStyle,
    title,
    onFilter,
    onRenderDetailsHeader,
    onRenderRow,
    onRenderCheckbox,
    isCompact,
    isLoading,
    showPagination,
    paginationType,
  } = props;

  const columnsDropdown: DropdownType | undefined = props.columnsDropdown;
  const noData = !items || items.length < 1;

  return (
    <>
      <div className={`${styles['table-wrapper']} ${tableStyle ? tableStyle : styles['table']}`}>
        {enableToolBar && (
          <div className={styles['top-bar']}>
            <div className={styles['section-toolbar']}>
              <TextField className={styles['filter-field']} onChange={onFilter} placeholder={t('filter', { ns: NS.TABLE })} />
            </div>
            <div className={styles['section-title']}>{title}</div>
            <div className={styles['section-column-options']}>
              {columnsDropdown && (
                <DropdownViewController
                  multi={columnsDropdown.multi}
                  dropdownProps={columnsDropdown.dropdownProps}
                  translationNamespace={columnsDropdown.translationNamespace}
                  callBack={columnsDropdown.callBack}
                  selectedMultiKeys={displayColumns}
                />
              )}
            </div>
          </div>
        )}
        {isLoading && (
          <div className={styles['isloading-wrapper']}>
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && (
          <>
            {groups ? (
              <DetailsList
                className={styles['table-detailslist']}
                checkboxVisibility={checkboxVisibility}
                onShouldVirtualize={() => false}
                columns={columns as IColumn[]}
                constrainMode={ConstrainMode.unconstrained}
                items={items}
                groups={groups}
                compact={isCompact}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selection={selection}
                selectionMode={selectionMode ?? SelectionMode.none}
                isSelectedOnFocus={isSelectedOnFocus ?? true}
                selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
                //onRenderDetailsHeader={onRenderDetailsHeader} // NOTE: Removed because not needed
                onRenderRow={onRenderRow}
                //onRenderCheckbox={onRenderCheckbox} // NOTE: Removed for accessibility
                ariaLabelForSelectAllCheckbox={t('aria-label-all-checkbox', { ns: NS.TABLE })}
                ariaLabelForSelectionColumn={t('aria-label-column', { ns: NS.TABLE })}
                checkButtonAriaLabel={t('aria-label-checkbox', { ns: NS.TABLE })}
              />
            ) : (
              <DetailsList
                className={styles['table-detailslist']}
                checkboxVisibility={checkboxVisibility}
                onShouldVirtualize={() => false}
                columns={columns as IColumn[]}
                constrainMode={ConstrainMode.unconstrained}
                items={items}
                compact={isCompact}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selection={selection}
                selectionMode={selectionMode ?? SelectionMode.none}
                selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
                //onRenderDetailsHeader={onRenderDetailsHeader} // NOTE: Removed because not needed
                onRenderRow={onRenderRow}
                //onRenderCheckbox={onRenderCheckbox} // NOTE: Removed for accessibility
                ariaLabelForSelectAllCheckbox={t('aria-label-all-checkbox', { ns: NS.TABLE })}
                ariaLabelForSelectionColumn={t('aria-label-column', { ns: NS.TABLE })}
                checkButtonAriaLabel={t('aria-label-checkbox', { ns: NS.TABLE })}
              />
            )}
          </>
        )}
        {showPagination && <PaginationViewController paginationType={paginationType}></PaginationViewController>}
      </div>

      {noData && (
        <div className={styles['empty-message']}>
          <Trans ns={NS.TABLE as Namespace}>empty</Trans>
        </div>
      )}
    </>
  );
};

export default TableTemplate;
