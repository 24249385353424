import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment-timezone';

import { OverflowTooltip } from '@/components/_air/common';
import CustomPaginate from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/CustomPaginate';
import { ExperimentInstanceSteps, ExperimentInstanceStepsData, Labels, Statuses } from '@/constants/AirConstants';
import { DateFormats } from '@/constants/DateFormatConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ExperimentSteps } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  experimentSteps: ExperimentSteps[];
}

export const ExperimentStepStatus = ({ experimentSteps }: Props): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { userSettingsStore } = rootStore;
  const { timeZone } = userSettingsStore;

  const [totalItem, setTotalItem] = React.useState(0);
  const itemPerPage = 10;

  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [currentPage, setcurrentPage] = React.useState(0);

  const experimentstepsData = React.useMemo(() => {
    const computedData = experimentSteps;

    if (computedData !== undefined && Array.isArray(computedData)) {
      setPageCount(Math.ceil(computedData.length / itemPerPage));
      setTotalItem(computedData.length);

      const endOffset = itemOffset + itemPerPage;

      return computedData.slice(itemOffset, endOffset);
    }

    return computedData;
  }, [experimentSteps, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemPerPage;

    setcurrentPage(event.selected);
    setItemOffset(newOffset);
  };

  const renderSteps = (): JSX.Element[] =>
    experimentstepsData.length > 0 &&
    experimentstepsData.map((step, ind) => {
      const createdDate = step.startTime
        ? moment.tz(step.startTime, timeZone).format(DateFormats.STANDARD_DATE_TIME)
        : Statuses.notSpecified;

      return (
        <tr key={ind}>
          {ExperimentInstanceStepsData.map((field) => {
            const singleField = field.split('.').reduce((r, val) => (r ? r[val as string] : Statuses.notSpecified), step);

            return (
              <td key={field} className="status-table-field">
                <OverflowTooltip>{singleField ?? Statuses.notSpecified}</OverflowTooltip>
              </td>
            );
          })}
          <td>
            <OverflowTooltip>{createdDate}</OverflowTooltip>
          </td>
        </tr>
      );
    });

  const renderHeaders = () =>
    ExperimentInstanceSteps.map((header) => (
      <th key={header} className="gbs-status-table-label">
        {header}
      </th>
    ));

  return (
    <div id="wizard-page" className="gbs-status">
      <h5 className="GBS-tab-header">{Labels.experimentInstanceSteps}</h5>

      <div>
        <Table hover striped size="sm" className="gbs-status-table text-nowrap">
          <thead>
            <tr>{renderHeaders()}</tr>
          </thead>
          {experimentSteps && <tbody>{renderSteps()}</tbody>}
        </Table>
        {experimentSteps && totalItem > 0 && (
          <CustomPaginate
            breakLabel="..."
            nextLabel="next >"
            pageRangeDisplayed={10}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            containerClassName="pagination"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            onPageChange={handlePageClick}
          />
        )}
      </div>
    </div>
  );
};
