import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import monacoSettings from '@/components/ExperimentEditor/ExperimentEditor.config';
import ExperimentEditorTemplate from '@/components/ExperimentEditor/ExperimentEditorTemplate';
import {
  ConfirmationAction,
  ExperimentEditorRouteType,
  ExperimentTemplate,
  LabUpgradeTemplateInfo,
} from '@/components/ExperimentEditor/ExperimentEditorTypes';
import { Messages, NotificationMessages } from '@/constants/AirConstants';
import { ExperimentEditorConstants, LabUpgrade } from '@/constants/ExperimentEditorConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { DataType, FileType, IconNames, Namespaces as NS, TemplateSchema } from '@/constants/SystemConstants';
import { darkBase, lightBase } from '@/layouts/Themes/ThemeConstants';
import { executionGoalsRequestService } from '@/services/_air/request-services';
import { notificationService } from '@/services/_labs/NotificationService';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { processApiError } from '@/utils/_air/ErrorHandler';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';
import { LoadFile, SaveFile, SaveFileAs, SelectFile } from '@/utils/FileIO';

declare global {
  interface Window {
    monaco: any;
  }
}

interface ExperimentEditorViewControllerProps {
  viewModel: any;
  labUpgradeTemplateInfo?: LabUpgradeTemplateInfo;
}

// This contains the sub view controllers like FileIO, and also manages the high-level business logic between the sub VCs
const ExperimentEditorViewControllerFC: React.FC<ExperimentEditorViewControllerProps> = ({ viewModel, labUpgradeTemplateInfo }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, experimentEditorStore } = rootStore;
  const { isPartnerMode, isDarkMode } = appSettingsStore;
  const {
    setFileHandle,
    setFileName,
    getExperiment,
    setTemplates,
    getTemplateCatalogue,
    experimentEditorLabsToggle,
    setExperimentEditorLabsToggle,
  } = experimentEditorStore;

  const { airDiagnosticOptions, labsDiagnosticOptions } = monacoSettings;
  const { fileHandle, fileName } = viewModel;
  const [labUpgrade, setLabUpgrade] = React.useState<boolean>(!!labUpgradeTemplateInfo);
  const { labId } = useParams<ExperimentEditorRouteType>();
  const initialLabsToggle = labUpgrade || isPartnerMode || experimentEditorLabsToggle || labId !== undefined;

  const [experiment, setExperiment] = React.useState<string>(getExperiment); // the experiment JSON data
  const [dirtyStack, setDirtyStack] = React.useState<boolean>(false); // Used to keep track if things are changed.
  const [loadedTemplate, setLoadedTemplate] = React.useState<boolean>(false); // Used to keep track if things are changed.
  const [loadingTemplates, setLoadingTemplates] = React.useState<boolean>(false); // Used to track if templates are being loaded.
  const [loadingExperiment, setLoadingExperiment] = React.useState<boolean>(false); // Used to track if an experiment is loading.
  const [saved, setSaved] = React.useState<boolean>(true); // Used to keep track if changes haven't been saved.
  const [labToggle, setLabToggle] = React.useState<boolean>(initialLabsToggle); // Used to keep of the template being set.
  const [editor, setEditor] = React.useState<any>(); // Used to keep track if things are changed.
  const [monaco, setMonaco] = React.useState<any>(); // Used to keep track if things are changed.
  const [errorsExist, setErrorsExist] = React.useState<boolean>(false);
  const [hideTemplate, { toggle: toggleHideTemplate }] = useBoolean(true);
  const [hidePublish, { toggle: toggleHidePublish }] = useBoolean(true);
  const [hidePreview, { toggle: toggleHidePreview }] = useBoolean(true);
  // Used to track what action we are confirming.
  const [confirmationAction, setConfirmationAction] = React.useState<ConfirmationAction>(ConfirmationAction.None);
  const [showConfirm, { toggle: toggleShowConfirm }] = useBoolean(false);

  const cancellationToken = useCancellationToken();

  const templateColumns = [
    {
      key: 'id',
      name: 'id',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 400,
      isResizable: true,
    },
    {
      key: 'description',
      name: 'description',
      fieldName: 'description',
      minWidth: 300,
      isResizable: true,
    },
  ];

  // Container for the menu items
  const _menuItems: ICommandBarItemProps[] = [];
  const _switcherItems: IOverflowSetItemProps[] = [];

  const monacoEditorOptions = {
    theme: ExperimentEditorConstants.MONACO_THEME,
    minimap: {
      enabled: false,
    },
  };

  const monacoThemeSettings = {
    base: isDarkMode ? 'vs-dark' : 'vs',
    inherit: true,
    rules: [],
    colors: { 'editor.background': isDarkMode ? darkBase.backgroundColor : lightBase.backgroundColor },
  };

  React.useEffect(() => {
    const initializeMonaco = () => {
      window.monaco.editor.defineTheme(ExperimentEditorConstants.MONACO_THEME, monacoThemeSettings);
    };

    const checkMonacoLoaded = () => {
      if (window.monaco) {
        initializeMonaco();
      } else {
        // Wait for Monaco in order to apply the theme until Monaco is loaded
        setTimeout(checkMonacoLoaded, ExperimentEditorConstants.TIMEOUT_TO_MONACO_LOAD);
      }
    };

    // Start checking if Monaco is loaded
    checkMonacoLoaded();
  }, [isDarkMode]);

  const HandleEditorChange = (value) => {
    setExperiment(value);
    experimentEditorStore.setExperiment(value);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (monaco) {
        const errors = monaco.editor.getModelMarkers({ owner: ExperimentEditorConstants.ERROR_OWNER });
        const filteredErrors = errors.filter((item) => {
          return item.severity >= ExperimentEditorConstants.ERROR_SEVERITY_LEVEL;
        });

        setErrorsExist(filteredErrors.length !== 0);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [monaco]);

  const EditorMount = (editor, monaco) => {
    // set the editor so that we can overwrite it's value later when we load in a file
    setEditor(editor);
    setMonaco(monaco);
    // create the editor model for the data. This requires us to give a "URI" to the file
    // but we aren't using it so I gave it a clearly incorrect value
    const modelUri = monaco.Uri.parse(ExperimentEditorConstants.FAKE_URI);
    const model = monaco.editor.createModel(experiment, FileType.JSON, modelUri);

    editor.setModel(model);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions(labToggle ? labsDiagnosticOptions : airDiagnosticOptions);

    if (labUpgrade) {
      LoadTemplate(labUpgradeTemplateInfo.templateName);
    }
  };

  // TODO: move this to it's own file "Catalogue Store" (25498787)
  const LoadTemplate = async (templateId: string) => {
    setLoadingExperiment(true);

    if (!labUpgrade) {
      toggleHideTemplate();
    }

    let template = getTemplateCatalogue(labToggle).find((p) => p.id === templateId);

    if (!template?.experiment) {
      await ganymedeExperimentRequestService.getExperimentTemplateByName(templateId).then((results: ExperimentTemplate) => {
        template = results[0];

        if (template?.experiment) {
          setValues(template.experiment, templateId);
        }
      });
    } else {
      setValues(template.experiment, templateId);
    }

    setLoadingExperiment(false);
  };

  const replaceLabUpgradeValues = async (templateExperiment: any) => {
    const updateValues = (object: any) => {
      const traverseArray = (array: any) => {
        array.forEach((item: any) => {
          if (typeof item === DataType.OBJECT) {
            updateValues(item);
          }
        });
      };

      for (const key in object) {
        if (Array.isArray(object[key])) {
          traverseArray(object[key]);
        } else if (typeof object[key] === DataType.OBJECT) {
          updateValues(object[key]);
        } else {
          if (key.toLowerCase() === LabUpgrade.LAB_ID) {
            object[key] = labUpgradeTemplateInfo.labId;
          }

          if (key.toLowerCase() === LabUpgrade.MANIFEST_VERSION) {
            object[key] = labUpgradeTemplateInfo.manifestVersion;
          }
        }
      }
    };

    templateExperiment.workflow.forEach((workflow: any) => {
      if (workflow.parameters) {
        updateValues(workflow.parameters);
      }
    });

    return templateExperiment;
  };

  const setValues = async (templateExperiment: any, templateId: string) => {
    if (labUpgrade) {
      templateExperiment = await replaceLabUpgradeValues(templateExperiment);
      setLabUpgrade(false);
    }

    await Promise.all([
      setExperiment(JSON.stringify(templateExperiment, null, '\t')),
      setDirtyStack(true),
      setFileName(templateId),

      // set the fileHandle to null so that the user doesn't overwrite their previous save with a new file.
      setFileHandle(null),
    ]);
  };

  const updateLabToggle = (value: boolean) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions(value ? labsDiagnosticOptions : airDiagnosticOptions);
    setLabToggle(value);
  };

  React.useEffect(() => {
    // if the stack was flagged as "dirty" then the experiment value was overwritten and we need to re-set the editors value
    if (dirtyStack) {
      editor.setValue(experiment);
      setDirtyStack(false);
    }

    // update the Templates List. Because we don't want to keep waiting around for this to happen, we'll run it asynchronously.
    const UpdateTemplateList = async () => {
      setLoadingTemplates(true);

      if (!isPartnerMode) {
        // New AIR Templates
        await ganymedeExperimentRequestService
          .getExperimentTemplatesInfoBySchema(TemplateSchema.AIR)
          .then((result) => {
            setTemplates(result, false);
          })
          .catch((error) => {
            processApiError(error, 'Temp');
          });

        // Old AIR Templates
        await executionGoalsRequestService
          .getExecutionGoalTemplatesSummary(cancellationToken)
          .then((result) => {
            if (!isAPIError(result)) {
              const resultSummary: any[] = JSON.parse(result[0]);

              if (isAPIError(resultSummary)) {
                notificationService.showError(Messages.templateListRetrieveError);

                return;
              }

              if (resultSummary[0]?.definition) {
                setTemplates(resultSummary, false, true);
              }
            }
          })
          .catch((error) => {
            processApiError(error, NotificationMessages.errorOnReceivingExecutionGoalTemplate);
          });
      }

      // Labs Templates
      await ganymedeExperimentRequestService
        .getExperimentTemplatesInfoBySchema(TemplateSchema.VEGA)
        .then((result) => {
          setTemplates(result, true);
        })
        .catch((error) => {
          processApiError(error, 'Temp');
        });
      setLoadingTemplates(false);
    };

    UpdateTemplateList();
  }, [dirtyStack]);

  // buildLeftNavMenuButtons : a local function for constructing the file items on the left of the commandbar
  const buildLeftNavMenuItems = () => {
    // toggleSchema : button for creating a "new" file. Basically just clears out your experiment data.

    const subMenuProps: IContextualMenuProps = {
      items: [
        {
          key: t('air', { ns: NS.DEFAULT }),
          text: t('air', { ns: NS.DEFAULT }),
          iconProps: { iconName: NavigationIcon[Navigation.AIR.CREATE_EXPERIMENT] },
          onClick: () => {
            updateLabToggle(false);
            setExperimentEditorLabsToggle(false);
          },
        },
        {
          key: t('labs', { ns: NS.DEFAULT }),
          text: t('labs', { ns: NS.DEFAULT }),
          iconProps: { iconName: NavigationIcon[Navigation.LABS.HOME] },
          onClick: () => {
            updateLabToggle(true);
            setExperimentEditorLabsToggle(true);
          },
        },
      ],
      // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
      // shouldFocusOnMount: false
    };

    const schema: IOverflowSetItemProps = {
      key: t('schema', { ns: NS.DEFAULT }),
      onRender: () => (
        <CommandBarButton
          text={
            t('schema', { ns: NS.DEFAULT }) + ' : ' + (labToggle ? t('labs', { ns: NS.DEFAULT }) : t('air', { ns: NS.DEFAULT }))
          }
          disabled={isPartnerMode}
          ariaLabel={t('schema', { ns: NS.DEFAULT })}
          title={t('schema', { ns: NS.DEFAULT }) + ':' + (labToggle ? t('labs', { ns: NS.DEFAULT }) : t('air', { ns: NS.DEFAULT }))}
          menuProps={subMenuProps}
        />
      ),
    };

    _switcherItems.push(schema);

    return _switcherItems;
  };

  // If the experiment has been updated set the saved flag to false, unless we loaded a template
  React.useEffect(() => {
    if (loadedTemplate) {
      setSaved(true);
      setLoadedTemplate(false);
    } else {
      setSaved(false);
    }
  }, [experiment]);

  const isStringEmpty = (toCheck: string) => {
    return !toCheck || /^\s*$/.test(toCheck);
  };

  // Loads local JSON file.
  const LocalLoad = async () => {
    const file = await SelectFile(setFileHandle);
    const loadResults = await LoadFile(file);

    setFileName(file.name);
    setExperiment(loadResults);
    setDirtyStack(true);
  };

  // Confirms the action in the case where a experiment wasn't saved before making a new experiment, or loading a template
  const confirmedAction = () => {
    if (confirmationAction === ConfirmationAction.New) {
      setExperiment('');
      setDirtyStack(true);

      // set the fileHandle to null so that the user doesn't overwrite their previous save with a new file.
      setFileName(t('untitled', { ns: NS.EDITOR }));
      setFileHandle(null);
    } else if (confirmationAction === ConfirmationAction.LocalFile) {
      LocalLoad();
      setLoadedTemplate(true);
    } else if (confirmationAction === ConfirmationAction.Template) {
      toggleHideTemplate();
      setLoadedTemplate(true);
    }

    setConfirmationAction(ConfirmationAction.None);
    toggleShowConfirm();
  };

  // buildRightNavMenuItems : a local function for constructing the file navigation menu buttons
  const buildRightNavMenuItems = () => {
    // newButton : button for creating a "new" file. Basically just clears out your experiment data.
    const newButton: ICommandBarItemProps = {
      key: 'new-menu',
      text: t('new', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.PAGE_ADD },
      submenuIconProps: {
        iconName: IconNames.CHEVRON_DOWN,
      },
      subMenuProps: {
        items: [
          {
            key: 'new-json-file-item',
            text: t('new-json-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.PAGE_ADD },
            onClick: () => {
              if (saved || isStringEmpty(experiment)) {
                setExperiment('');
                setDirtyStack(true);

                // set the fileHandle to null so that the user doesn't overwrite their previous save with a new file.
                setFileName(t('untitled', { ns: NS.EDITOR }));
                setFileHandle(null);
              } else {
                setConfirmationAction(ConfirmationAction.New);
                toggleShowConfirm();
              }
            },
          },
          {
            key: 'duplicate-current-file-item',
            text: t('duplicate-current-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.PAGE_ADD },
            onClick: () => {
              const newName = `${t('copy-of', { ns: NS.EDITOR })} ${fileName}`;

              setFileName(newName);
              setFileHandle(null);
            },
          },
        ],
      },
    };

    // openButton : opens a file inside of the editor
    const openButton: ICommandBarItemProps = {
      key: 'open-menu',
      text: t('open', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.FABRIC_OPEN_FOLDER_HORIZONTAL },
      subMenuProps: {
        items: [
          {
            key: 'from-local-file-item',
            text: t('from-local-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.FABRIC_OPEN_FOLDER_HORIZONTAL },
            onClick: () => {
              if (saved || isStringEmpty(experiment)) {
                LocalLoad();
                setLoadedTemplate(true);
              } else {
                setConfirmationAction(ConfirmationAction.LocalFile);
                toggleShowConfirm();
              }
            },
          },
          {
            key: 'from-template-item',
            text: t('from-template', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.FABRIC_NETWORK_FOLDER },
            onClick: () => {
              if (saved || isStringEmpty(experiment)) {
                toggleHideTemplate();
                setLoadedTemplate(true);
              } else {
                setConfirmationAction(ConfirmationAction.Template);
                toggleShowConfirm();
              }
            },
          },
        ],
      },
    };

    // saveButton : saves a file to the currently set fileHandle location.
    const saveButton: ICommandBarItemProps = {
      key: 'save-menu',
      text: t('save', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.SAVE },
      subMenuProps: {
        items: [
          {
            key: 'save-item',
            text: t('save', { ns: NS.COMMON }),
            iconProps: { iconName: IconNames.SAVE },
            disabled: !fileHandle,
            onClick: () => {
              const save = async () => {
                setSaved(true);
                await SaveFile(experiment, fileHandle, setFileHandle);
                return false;
              };

              save();
            },
          },
          {
            key: 'save-as-item',
            text: t('save-as', { ns: NS.COMMON }),
            iconProps: { iconName: IconNames.SAVE_AS },
            onClick: () => {
              const saveAs = async () => {
                setSaved(true);
                await SaveFileAs(experiment, setFileHandle);
                return false;
              };

              saveAs();
            },
          },
        ],
      },
    };

    // saveAsButton : creates a filehandle, selects a location, and then saves a file to it.
    const publishButton: ICommandBarItemProps = {
      key: t('queue', { ns: NS.EDITOR }),
      text: t('queue', { ns: NS.EDITOR }),
      disabled: isStringEmpty(experiment) || errorsExist,
      ariaLabel: t('queue', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: IconNames.QUEUE },
      title: t('queue', { ns: NS.EDITOR }),
      onClick: () => {
        toggleHidePublish();
      },
    };

    const previewButton: ICommandBarItemProps = {
      key: t('preview', { ns: NS.EDITOR }),
      text: t('preview', { ns: NS.EDITOR }),
      disabled: isStringEmpty(experiment) || errorsExist,
      ariaLabel: t('preview', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: IconNames.PREVIEW },
      title: t('preview', { ns: NS.EDITOR }),
      onClick: () => {
        toggleHidePreview();
      },
    };

    _menuItems.push(newButton, openButton, saveButton, publishButton, previewButton);

    return _menuItems;
  };

  const filterItems: IOverflowSetItemProps[] = buildLeftNavMenuItems();
  const farItems: ICommandBarItemProps[] = buildRightNavMenuItems();

  const title = `${t('experiment-editor', { ns: NS.TITLES })}${fileName ? ` - ${fileName}` : ''}`;

  return (
    <ExperimentEditorTemplate
      labId={labId ? parseInt(labId) : -1}
      hideTemplate={hideTemplate}
      toggleHideTemplate={toggleHideTemplate}
      hidePublish={hidePublish}
      hidePreview={hidePreview}
      labToggle={labToggle}
      toggleHidePublish={toggleHidePublish}
      toggleHidePreview={toggleHidePreview}
      loadingTemplates={loadingTemplates}
      loadingExperiment={loadingExperiment}
      templates={getTemplateCatalogue(labToggle)}
      templateColumns={templateColumns}
      loadTemplate={LoadTemplate}
      filterItems={filterItems}
      farItems={farItems}
      hideConfirmModal={toggleShowConfirm}
      isConfirmModalOpen={showConfirm}
      confirmedAction={confirmedAction}
      editorMount={EditorMount}
      handleEditorChange={HandleEditorChange}
      monacoEditorOptions={monacoEditorOptions}
    />
  );
};

const ExperimentEditorViewController = observer(ExperimentEditorViewControllerFC);

export default ExperimentEditorViewController;
