import { action, makeObservable, observable } from 'mobx';

import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LabPanelStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public isLabPanelOpen: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    makeObservable(this, {
      openLabPanel: action,
      closeLabPanel: action,
      isLabPanelOpen: observable,
    });

    this.isLabPanelOpen = false;
  }

  public openLabPanel = () => {
    this.setLabPanelOpen(true);
  };

  public closeLabPanel = () => {
    this.setLabPanelOpen(false);
  };

  public setLabPanelOpen = (open: boolean) => {
    this.isLabPanelOpen = open;
  };
}

export default LabPanelStore;
