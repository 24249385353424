import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { PrimaryButton } from '@fluentui/react';

import { Message } from '@/components/_air/common';
import CustomPaginate from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/CustomPaginate';
import { ExecutionFailureAnalysisHeader, Labels, Messages } from '@/constants/AirConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { ExecutionFailureAnalysis } from '@/types/_air';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import '@/styles/Air.css';

interface FailureAnalysisParams {
  experimentName: string;
}

export const ExecutionGoalFailureAnalysis = (data: any): JSX.Element => {
  const params: FailureAnalysisParams = data.match.params;
  const { experimentName } = params;
  const [FailureAnalysis, setFailureAnalysis] = React.useState<ExecutionFailureAnalysis[]>();
  const [isBusy, setIsBusy] = React.useState<boolean>(true);
  const cancellationToken = useCancellationToken();
  const [totalItem, setTotalItem] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [currentPage, setcurrentPage] = React.useState(0);
  const itemPerPage = 10;
  const isInstance = false;

  React.useEffect(() => {
    experimentStatusRequestService
      .getFailureAnalysisOfExecutionGoal(experimentName, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) setFailureAnalysis(result);
      })
      .catch((error) => {
        if (error.response?.data?.detail === Messages.noExperimentInstance) return;

        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
        void monitoringService.logError(error);
      })
      .finally(() => setIsBusy(false));
  }, [cancellationToken, experimentName]);

  const failuredata = React.useMemo(() => {
    const computedData = FailureAnalysis;

    if (computedData !== undefined) {
      setTotalItem(computedData.length);
      setPageCount(Math.ceil(computedData.length / itemPerPage));

      const endOffset = itemOffset + itemPerPage;

      return computedData.slice(itemOffset, endOffset);
    }

    return computedData;
  }, [FailureAnalysis, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemPerPage;

    setcurrentPage(event.selected);
    setItemOffset(newOffset);
  };

  const renderData = (): JSX.Element[] =>
    failuredata?.length > 0 &&
    failuredata.map((failure, ind) => (
      <tr key={ind} className="status-table-field">
        <td>{failure.experimentId}</td>
        <td>{failure.failureAnalysis.id}</td>
        <td>{failure.failureAnalysis.rca}</td>
        <td>{failure.failureAnalysis.failureCategory}</td>
        <td>{failure.failureAnalysis.mitigationStep}</td>
        <td>{failure.failureAnalysis.properties.cluster}</td>
        <td>{failure.failureAnalysis.properties.groupName}</td>
        <td className="status-table-field">
          <Link
            to={{
              pathname: `${Navigation.AIR.FAILURE_ANALYSIS_DETAILS}/${failure.failureAnalysis.id}/${isInstance}`,
              state: {
                FailureAnaysisExecutionGoal: failure,
                experimentName,
              },
            }}
          >
            <PrimaryButton className="status-table-button">{Labels.viewDetail}</PrimaryButton>
          </Link>
        </td>
      </tr>
    ));

  const renderHeaders = () =>
    ExecutionFailureAnalysisHeader.map((header) => (
      <th key={header} className="gbs-status-table-label">
        {header}
      </th>
    ));
  const renderFailureAnalysis = () => (
    <Table hover striped size="sm" className="gbs-status-table">
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      {failuredata?.length > 0 ? <tbody>{renderData()}</tbody> : <Message message={Messages.noDataFound} />}
    </Table>
  );

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <main className="fullscreen container air-wrapper">
        <div className="fullscreen padding-top padding-bottom gbs-status-data">
          <h4 className="text-left all-in-one">
            <Link to={{ pathname: Navigation.AIR.MANAGE_EXPERIMENT }} className="breadcrumbs">
              {Labels.experiments}
            </Link>
            {` ${TITLE_DIVIDER} ${experimentName} `}
          </h4>
          <PageContent scrollable={true}>
            <div id="wizard-page">
              {renderFailureAnalysis()}
              {FailureAnalysis && totalItem > 0 && (
                <CustomPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  pageRangeDisplayed={10}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  forcePage={currentPage}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  onPageChange={handlePageClick}
                />
              )}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
