import React from 'react';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { ApexOptions } from 'apexcharts';

import { Routes } from '@/constants/AirConstants';
import { DataScore } from '@/utils/_air/DashboardScore';
import { useUserSettings } from '@/utils/_air/Hooks/UseUserSettings';

import '@/styles/Air.css';

interface BarProps {
  percentData: DataScore[];
  workloadProfileName: string;
  workloadProfileFriendly: string;
  experimentName: string;
  workloadName: string;
  revision: string;
}

type Setting = {
  series: DataScore[];
  options: ApexOptions;
};

export const BarGraph = (props: BarProps): JSX.Element => {
  const history = useHistory();
  const {
    state: { userSettings },
  } = useUserSettings();

  const [setting, setSettings] = React.useState<Setting>({
    series: [],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
      },
    },
  });

  React.useEffect(() => {
    const data = props.percentData;
    const selectedWorkloadProfile = props.workloadProfileName;
    const selectedWorkload = props.workloadName;
    const selectedExperiment = props.experimentName;
    const { workloadProfileFriendly } = props;
    const { revision } = props;

    setSettings({
      series: data,
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.path[0].style.cursor = 'pointer';
            },
            click(event, chartContext, config) {
              const selected = config.seriesIndex;

              history.push({
                pathname: `${Routes.workloadInfoForColor.link}/${selectedExperiment}/${selectedWorkloadProfile}/${workloadProfileFriendly}/${selectedWorkload}/${selected}/${revision}`,
              });
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [''],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        colors: ['#ec0e3f', '#ffc107', '#595b5d', '#14a446'],
        tooltip: {
          y: {
            formatter(val: number): string {
              return `${val}%`;
            },
          },
          theme: userSettings.theme,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
      },
    });
  }, [history, props, userSettings.theme]);

  return (
    <div id="chart">
      <Chart options={setting.options} series={setting.series} type="bar" height={100} width={500} />
    </div>
  );
};
