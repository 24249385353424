import BaseRequestService from './RequestService';

class LabDetailsRequestService extends BaseRequestService {
  async getSystemDetails(labId: number) {
    const url = `lab-systems/${labId}/systems-and-racks`;
    const { data } = await this.get(url);

    return data;
  }

  async getSystemsHeartbeat(agentIds: string[]) {
    if (agentIds?.length === 0) {
      return [];
    }

    const url = 'heartbeats/systems';
    const { data } = await this.post(url, agentIds);

    return data;
  }
}

export const ganymedeLabDetailsRequestService = new LabDetailsRequestService();
