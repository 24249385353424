import React from 'react';
import { TooltipHost } from '@fluentui/react';
import { ICalloutProps } from '@fluentui/react/lib/Callout';
import { ITooltipHostProps } from '@fluentui/react/lib/Tooltip';

import '@/styles/Air.css';

const tooltipBackgroundStyles: ICalloutProps = {
  styles: {
    beakCurtain: {
      backgroundColor: 'var(--backgroundColor)',
    },
  },
  backgroundColor: 'var(--backgroundColor)',
};

export const Tooltip = (props: ITooltipHostProps) => (
  <TooltipHost
    content={<span style={{ color: 'var(--textColor)', whiteSpace: 'pre-line' }}>{props.content}</span>}
    calloutProps={{ ...tooltipBackgroundStyles, ...props.calloutProps }}
    directionalHint={props.directionalHint}
    overflowMode={props.overflowMode}
    styles={props.styles}
  >
    {props.children}
  </TooltipHost>
);
