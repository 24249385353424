import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Icon } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { Tooltip } from '@/components/_air/common';
import { Labels, NotificationMessages, Routes, TableHeaders } from '@/constants/AirConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { experimentResultRequestService } from '@/services/_air/request-services';
import { MetricsBySelectedColor } from '@/types/_air';
import { getClassName } from '@/utils/_air/DashboardScore';
import { processApiError } from '@/utils/_air/ErrorHandler';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import { WorkloadInfoSubSet } from './WorkloadInfoSubSet';

import '@/styles/Air.css';
import './result.css';

export const WorkloadInfoForColor = (): JSX.Element => {
  const {
    experiment: ExperimentName,
    profile: WorkloadProfileName,
    score: selectedIdx,
    workload: workloadName,
    friendly: workloadProfileFriendly,
    revision,
  } = useParams<{
    experiment: string;
    profile: string;
    score: string;
    workload: string;
    friendly: string;
    revision: string;
  }>();

  const [workloadTableData, setWorkloadTableData] = React.useState<JSX.Element[]>([]);
  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [selectedItem, toggleItem] = React.useState<number>(-1);
  const [workloadData, setWorkloadData] = React.useState<MetricsBySelectedColor[]>([]);
  const cancellationToken = useCancellationToken();

  const tooltipId: string = useId('tooltip');
  const revisionStr = revision.includes('/') ? revision.replaceAll('/', 'slash') : revision;

  let SelectedColor: string;

  switch (selectedIdx) {
    case '3':
      SelectedColor = 'Green';
      break;
    case '2':
      SelectedColor = 'Black';
      break;
    case '1':
      SelectedColor = 'Yellow';
      break;
    default:
      SelectedColor = 'Red';
  }

  React.useEffect(() => {
    experimentResultRequestService
      .getMetricsBySelectedColor(ExperimentName, WorkloadProfileName, workloadName, SelectedColor, revisionStr, cancellationToken)
      .then((result: any) => {
        if (isAPIError(result)) {
          return;
        }

        setWorkloadData(result);
        populateMetricData(result);
      })
      .catch((error: any) => {
        processApiError(error, NotificationMessages.errorOnReceivingMetricsInfo);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (workloadData.length !== 0) {
      populateMetricData(workloadData);
    }
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const openItem = (index: number): void => {
    toggleItem(selectedItem === index ? -1 : index);
  };

  const populateMetricData = (workload: MetricsBySelectedColor[]): void => {
    const data = Object.keys(workload).map((item, index) => (
      <tbody key={index}>
        <tr key={`tr-${index}`}>
          <td className="text-nowrap">{workloadProfileFriendly}</td>
          <td className="font-weight-bold text-nowrap">{(workload as { [index: string]: any })[item as string].workloadName}</td>
          <td className="font-weight-bold text-nowrap">{(workload as { [index: string]: any })[item as string].metricName}</td>
          {(workload as { [index: string]: any })[item as string].testedInstance.length > 50 ? (
            <td>
              <Tooltip content={(workload as { [index: string]: any })[item as string].testedInstance} id={tooltipId}>
                <span aria-describedby={tooltipId} className="text">{`${(workload as { [index: string]: any })[
                  item as string
                ].testedInstance.substring(0, 49)} ...`}</span>
              </Tooltip>
            </td>
          ) : (
            <td>{(workload as { [index: string]: any })[item as string].testedInstance}</td>
          )}
          <td className="text-nowrap">
            {Object.keys((workload as { [index: string]: any })[item as string].experimentUnits).map(
              (experimentUnit: string, experimentUnitIndex: number) => (
                <span key={experimentUnitIndex}>
                  {`${(workload as { [index: string]: any })[item as string].experimentUnits[experimentUnit as string].groupId} ${
                    (workload as { [index: string]: any })[item as string].experimentUnits[experimentUnit as string].experimentUnits
                  }`}
                </span>
              ),
            )}
          </td>
          <td className={getClassName((workload as { [index: string]: any })[item as string].color)}>
            <b className="text-light">{`${((workload as { [index: string]: any })[item as string].confidenceScore * 100).toFixed(
              2,
            )} %`}</b>
          </td>
          <td className="text-center">
            <Icon
              iconName={+selectedItem === index ? 'ChevronUp' : 'ChevronDown'}
              className="ChevronDown clickable"
              onClick={() => openItem(index)}
            />
          </td>
        </tr>
        <tr key={`item-${index}`} className={`item ${+selectedItem === index ? 'open' : ''}`}>
          <td colSpan={7} className="subset">
            <WorkloadInfoSubSet workload={(workload as { [index: string]: any })[item as string]} />
          </td>
        </tr>
      </tbody>
    ));

    setWorkloadTableData(data);
    setBusy(false);
  };

  return (
    <div className="air-wrapper">
      <div className="result-container">
        <h4 className="text-left all-in-one text-nowrap">
          <Link to={{ pathname: '/experimentResult' }} className="breadcrumbs workload-links">
            {Labels.experiments}
          </Link>
          {TITLE_DIVIDER}
          <Link
            to={{ pathname: `${Routes.scenario.link}/${ExperimentName}/${revision}` }}
            className="breadcrumbs workload-links links-left-margin"
          >
            {`${ExperimentName} (${revision})`}
          </Link>
          {`/ Selected Color: ${SelectedColor}`}
        </h4>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          {isBusy ? (
            <LoadingSpinner />
          ) : (
            <Table striped className="text-left metric-text-table">
              <thead>
                <tr>
                  <th>{TableHeaders.testScenario}</th>
                  <th>{TableHeaders.testBenchmarks}</th>
                  <th>{TableHeaders.testMetrics}</th>
                  <th>{TableHeaders.experimentProperty}</th>
                  <th className="text-nowrap">{TableHeaders.metricCount}</th>
                  <th className="text-nowrap">{TableHeaders.confidenceScores}</th>
                  <th />
                </tr>
              </thead>
              {workloadTableData}
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};
