import { ReactGridFileDataType } from '@/components/ReactGrid/ReactGridTypes';

const MockDashboardData: ReactGridFileDataType = {
  'clock-1': {
    type: 'clock',
    title: 'Pacific Standard Time',
    data: {
      tz: 'pst',
    },
  },
  'nav-1': {
    type: 'text',
    title: 'Fixed Navigation',
    data: [
      {
        time: '12:13 AM',
        value: 1244,
      },
      {
        time: '2:07 AM',
        value: 631,
      },
    ],
  },
  'graph-type-1': {
    type: 'line',
    title: 'Graph 1',
    data: [
      {
        time: '12:13 AM',
        value: 1244,
      },
      {
        time: '2:07 AM',
        value: 631,
      },
      {
        time: '7:37 AM',
        value: 2216,
      },
      {
        time: '10:00 AM',
        value: 3112,
      },
      {
        time: '11:21 PM',
        value: 4318,
      },
      {
        time: '10:38 PM',
        value: 1788,
      },
      {
        time: '2:36 PM',
        value: 4382,
      },
      {
        time: '1:13 PM',
        value: 3722,
      },
      {
        time: '4:38 AM',
        value: 1912,
      },
      {
        time: '3:55 AM',
        value: 4101,
      },
      {
        time: '1:55 PM',
        value: 3782,
      },
      {
        time: '4:59 PM',
        value: 3795,
      },
    ],
  },
  'graph-type-2': {
    type: 'bar',
    title: 'Graph 2',
    data: [
      {
        time: '11:01 PM',
        value: 2584,
      },
      {
        time: '5:27 AM',
        value: 4601,
      },
      {
        time: '12:25 AM',
        value: 2051,
      },
      {
        time: '9:44 AM',
        value: 4797,
      },
      {
        time: '3:08 PM',
        value: 1731,
      },
      {
        time: '3:35 PM',
        value: 2966,
      },
    ],
  },
  'graph-type-3': {
    type: 'area',
    title: 'Graph 3',
    data: [
      {
        time: '10:50 AM',
        value: 2876,
      },
      {
        time: '2:22 AM',
        value: 2779,
      },
      {
        time: '11:11 AM',
        value: 4147,
      },
      {
        time: '8:09 PM',
        value: 3458,
      },
    ],
  },
  'graph-type-4': {
    type: 'bar',
    title: 'Graph 4',
    data: [
      {
        time: '10:28 AM',
        value: 1285,
      },
      {
        time: '4:22 PM',
        value: 3740,
      },
      {
        time: '1:31 AM',
        value: 549,
      },

      {
        time: '6:18 AM',
        value: 2099,
      },
    ],
  },
  'graph-type-5': {
    type: 'pie',
    title: 'Graph 5',
    data: [
      {
        time: '9:12 AM',
        value: 1190,
      },
      {
        time: '10:30 AM',
        value: 3834,
      },
      {
        time: '4:39 AM',
        value: 2334,
      },
    ],
  },
  'table-type-1': {
    type: 'table',
    title: 'Active Experiment List',
    data: {
      items: [
        {
          name: 'HW_Qual_AB_GeekBenchLinux_Demo',
          status: 'In Progress',
          result: '70% Failure',
        },
        {
          name: 'OVL2.0_Mwh03prdapp19_AB',
          status: 'In Progress',
          result: '98% Fail',
        },
      ],
      columns: [
        {
          key: 'column1',
          name: 'name',
          fieldName: 'name',
          minWidth: 50,
          maxWidth: 500,
        },
        {
          key: 'column2',
          name: 'status',
          fieldName: 'status',
          minWidth: 75,
          maxWidth: 100,
        },
        {
          key: 'column3',
          name: 'result',
          fieldName: 'result',
          minWidth: 50,
          maxWidth: 100,
        },
      ],
      displayColumns: ['name', 'status', 'result'],
    },
  },
  'table-type-2': {
    type: 'table',
    title: 'Active Experiment List V2',
    data: {
      items: [
        {
          name: 'HW_Qual_AB_GeekBenchLinux_Demo',
          status: 'In Progress',
          result: '70% Failure',
        },
        {
          name: 'OVL2.0_Mwh03prdapp19_AB',
          status: 'In Progress',
          result: '98% Fail',
        },
      ],
      columns: [
        {
          key: 'column1',
          name: 'name',
          fieldName: 'name',
          minWidth: 50,
          maxWidth: 500,
        },
        {
          key: 'column2',
          name: 'status',
          fieldName: 'status',
          minWidth: 75,
          maxWidth: 100,
        },
        {
          key: 'column3',
          name: 'result',
          fieldName: 'result',
          minWidth: 50,
          maxWidth: 100,
        },
      ],
      displayColumns: ['name', 'status', 'result'],
    },
  },
  'experiment-summary-type-1': {
    type: 'experiment-summary',
    title: 'Experiment Status',
    data: {
      firstInstanceRunDate: new Date(2023, 1, 15),
      lastInstanceRunDate: new Date(2023, 1, 20),
      estimatedCompletionDate: new Date(2023, 1, 31),
    },
  },
};

export { MockDashboardData };
