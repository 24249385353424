import { RootStore } from '@/stores/RootStore';

import { GetListType, GetStringType } from '../ReliabilityType';
import ReliabilityViewModel from '../ReliabilityViewModel';

class PageUserFilterViewModel extends ReliabilityViewModel {
  protected _getSelectedPages: GetListType;
  protected _getSelectedUser: GetStringType;

  constructor(rootStore: RootStore) {
    super(rootStore);

    const { reliabilityStore } = rootStore;
    const { setSelectedPages, getSelectedPages, setSelectedUser, getSelectedUser } = reliabilityStore;

    this._setSelectedPages = setSelectedPages;
    this._getSelectedPages = getSelectedPages;
    this._setSelectedUser = setSelectedUser;
    this._getSelectedUser = getSelectedUser;
  }

  public get selectedPages() {
    return this._getSelectedPages();
  }

  public setSelectedPages = (selected: string[]) => {
    this._setSelectedPages(selected);
  };

  public get selectedUser() {
    return this._getSelectedUser();
  }

  public setSelectedUser = (selected: string) => {
    this._setSelectedUser(selected);
  };
}

export default PageUserFilterViewModel;
