import React from 'react';
import { Table } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import {
  ComboBox,
  DefaultButton,
  DirectionalHint,
  Fabric,
  IComboBoxOption,
  Icon,
  IconButton,
  Modal,
  SelectableOptionMenuItemType,
  TeachingBubble,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles, wrapperClassName } from '@/components/_labs/LabsHelper/Modal';
import { fetchAfterTimeout } from '@/components/_labs/LabsHelper/Net';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels, Messages, TableHead } from '@/constants/LabsConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';
import style from './SelectComponent.module.css';

const SelectComponentFC = (props: any) => {
  const TestPlantitleId = useId('TestPlantitle');
  const ReimageTitleId = useId('ReimageTitle');
  const updateOSTitleId = useId('UpdateOS');
  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const [header, setHeader] = React.useState<string>('');
  const [buttonText, setButtonText] = React.useState<string>('');
  const [titleId, setTitleId] = React.useState<string>('');
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
  const [initialOption, setInitialOption] = React.useState<(typeof IComboBoxOption)[]>([]);
  const [selectedKey, setSelectedKey] = React.useState<string[]>([]);
  const [testPlanType, setTestPlanType] = React.useState<string>('');

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const setMenuItem = React.useCallback(() => {
    const options: any = [];

    options.push({ key: 'Header1', text: 'Test Plan', itemType: SelectableOptionMenuItemType.Header });
    props.testPlanList.map((item: any) =>
      options.push({ key: (item as any).TestPlanId, text: (item as any).TestPlanName, id: 'single' }),
    );
    options.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
    options.push({ key: 'Header2', text: 'Test Plan Group', itemType: SelectableOptionMenuItemType.Header });
    props.testPlanGroupList.map((item: any) =>
      options.push({ key: (item as any).TestPlanGroupId, text: (item as any).TestPlanGroupName, id: 'group' }),
    );
    setInitialOption(options);
  }, [props.testPlanList, props.testPlanGroupList]);

  const setReimageItem = React.useCallback(() => {
    const options: any = [];

    props.osImageList.map((item: any) => options.push({ key: (item as any).OsImageId, text: (item as any).OsImageFriendlyName }));

    setInitialOption(options);
  }, [props.osImageList]);

  React.useEffect(() => {
    if (props.action === Labels.testPlan) {
      setMenuItem();
      setTitleId(TestPlantitleId);
      setHeader(Labels.selectTestPlan);
      setButtonText(Buttons.excuteTest);
    } else if (props.action === Labels.updateOSImage) {
      setReimageItem();
      setTitleId(updateOSTitleId);
      setHeader(Labels.selectDefaultOS);
      setButtonText(Buttons.updateLabOSImage);
      setSelectedKey(props.defaultOsImage);
    } else {
      setReimageItem();
      setTitleId(ReimageTitleId);
      setHeader(Labels.selectOS);
      setButtonText(Buttons.reimageMachine);
    }
  }, [TestPlantitleId, ReimageTitleId, updateOSTitleId, setMenuItem, setReimageItem, props]);

  const onChange = React.useCallback(
    (e: any, option: any) => {
      setSelectedKey(option?.key);

      if (props.action === Labels.testPlan) {
        setTestPlanType(option?.id);
      }
    },
    [setSelectedKey, props.action],
  );

  const UpdateOSImage = async (evt: any) => {
    evt.preventDefault();

    ganymedeLabRequestService
      .updateLab(props.labId, selectedKey)
      .then((response: any) => {
        if (response) {
          handleSuccess();
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  const handleSuccess = () => {
    props.hideModal(false);
    fetchAfterTimeout(props.fetch);
  };

  const populateTable = () =>
    props.osImageList.map((item: any, index: any) => (
      <tr key={index}>
        <td className="w-25">{(item as any).OsImageFriendlyName}</td>
        <td className="bubble">{(item as any).OsImageBuild}</td>
        <td className="bubble">{(item as any).OsImageDescription}</td>
      </tr>
    ));

  const handleMenuOpen = () => {
    const inputElement = document.querySelector('[role="combobox"]');

    if (inputElement) {
      const listId = inputElement.getAttribute('aria-owns');

      if (listId) {
        inputElement.setAttribute('aria-controls', listId);
      }
    }
  };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.container}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>{props.title}</h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
          <h5 id={titleId} className="ms-Fabric">
            {header}
          </h5>
          {props.action === Labels.updateOSImage ? (
            <h6 id="targetButton" className="accent-color pointer" onClick={toggleTeachingBubbleVisible}>
              <Icon iconName="Info" />
              Available Images
            </h6>
          ) : null}
          <Fabric className={wrapperClassName}>
            <ComboBox
              allowFreeform
              autoComplete="on"
              placeholder={Messages.enterOrSelectOption}
              selectedKey={selectedKey}
              options={initialOption}
              onMenuOpen={handleMenuOpen}
              onChange={onChange}
            />
          </Fabric>
          <br />
          <DefaultButton
            type="submit"
            onClick={(e: any) => {
              if (props.action === Labels.updateOSImage) {
                UpdateOSImage(e);
              }
            }}
          >
            {buttonText}
          </DefaultButton>
        </div>
      </Modal>
      {teachingBubbleVisible && (
        <TeachingBubble
          isWide
          calloutProps={{ directionalHint: DirectionalHint.leftBottomEdge }}
          target="#targetButton"
          onDismiss={toggleTeachingBubbleVisible}
        >
          <Table className={style['teaching-bubble-table']}>
            <thead>
              <tr>
                <th>{TableHead.osImageName}</th>
                <th>{TableHead.build}</th>
                <th>{TableHead.description}</th>
              </tr>
            </thead>
            <tbody>{populateTable()}</tbody>
          </Table>
        </TeachingBubble>
      )}
    </div>
  ) : null;
};

export const SelectComponent = observer(SelectComponentFC);
