import React from 'react';
import { observer } from 'mobx-react-lite';

import FooterTemplate from './FooterTemplate';
import { FooterType } from './FooterTypes';

const FooterViewControllerFC: React.FC<{ viewModel: FooterType }> = (props: { viewModel: FooterType }) => {
  const { isDebugMode } = props.viewModel;

  return <FooterTemplate isDebugMode={isDebugMode}></FooterTemplate>;
};

const FooterViewController = observer(FooterViewControllerFC);

export default FooterViewController;
