import React from 'react';
import axios from 'axios';

const useCancellationToken = () => {
  const [cancellationTokenSource] = React.useState(axios.CancelToken.source());

  React.useEffect(
    () => (): void => {
      cancellationTokenSource.cancel();
    },
    [cancellationTokenSource],
  );

  return cancellationTokenSource.token;
};

export { useCancellationToken };
