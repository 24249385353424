import React from 'react';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Pivot } from '@fluentui/react';
import { PivotItem } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Col, Result, Row } from 'antd';
import { t } from 'i18next';
import ls from 'local-storage';
import * as qs from 'query-string';

import { copyTestRunURL } from '@/components/_labs/ResultsExplorer/Helpers';
import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import { SystemIcons } from '@/constants/IconConstants';
import { Messages } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { PivotItemKeys, TemplateConstants } from '@/constants/ResultExplorerConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Results } from '@/constants/TranslationConstants';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { getResultExplorerAuthData } from '@/utils/Env';
import { logDownloadHelper } from '@/utils/Helpers';

import '@/styles/Labs.css';
import styles from './LogFolderView.module.css';

const LogFolderViewFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [folder, setFolder] = React.useState<string>();
  const [experimentDefinitionID, setExperimentDefinitionId] = React.useState<string>();
  const [companyNameFromUrl, setCompanyNameFromUrl] = React.useState<any>();
  const [error, setError] = React.useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const nineMinsToMillisec = 540000;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const setTokenData = React.useCallback(async () => {
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      })
      .then((response) => {
        (ls as any).set('resultsToken', response.accessToken);
        readPath();
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        }
      });
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.error('Error from Results Token Creation: ', e);
    });
  }, [instance]);

  const readPath = () => {
    const location = window.location?.pathname || '';

    if (location !== '') {
      const { '/labs/logFolderView/path': path, companyName } = qs.parse(location);

      if (companyName) {
        setCompanyNameFromUrl(companyName);
      }

      const pathString = path as string;

      if (pathString) {
        const pathParts = pathString.split('~');

        if (pathParts) {
          // path follows the format: experimentDefinitionId~experiment name~experiment instance Id
          setExperimentDefinitionId(pathParts[0]);
          setFolder(pathString);
          setBusy(false);
        }
      } else {
        setBusy(false);
      }
    } else {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  // default token expiration time is 10 mins so renewing token every 9 mins
  React.useEffect(() => {
    setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, nineMinsToMillisec);
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  const failedResult = <Result status="error" title={Messages.logsNotFound} subTitle={Messages.logsNotFoundDescription} />;

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Results.DOWNLOAD_LOGS,
      text: t(Results.DOWNLOAD_LOGS, { ns: NS.RESULTS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      onClick: () => {
        logDownloadHelper(experimentDefinitionID, systemMessageStore, companyNameFromUrl);
      },
    },
    {
      key: Results.COPY_URL,
      text: t(Results.COPY_URL, { ns: NS.RESULTS }),
      iconProps: { iconName: SystemIcons.COPY_URL },
      onClick: () => {
        copyTestRunURL(experimentDefinitionID, TemplateConstants.EXPERIMENT_DEFINITION_ID, companyNameFromUrl);
      },
    },
  ];

  return isBusy ? (
    <LoadingSpinner />
  ) : folder ? (
    <>
      <div className="fullscreen">
        <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
        <main className="fullscreen container ganymede-wrapper">
          <div className="fullscreen padding-top padding-bottom">
            <PageHeader icon={NavigationIcon[Navigation.LABS.RESULT_SEARCH]}>Experiment Run</PageHeader>
            <PageCommandBar items={commandBarItems}></PageCommandBar>
            <PageContent scrollable={true}>
              <div>
                <Row>
                  <Col span={10}>
                    <span className={styles['title-text-size']}>
                      {TemplateConstants.DEFINITION_TEMPLATE_ID} : {experimentDefinitionID}
                    </span>
                  </Col>
                </Row>
                <Pivot defaultSelectedKey={PivotItemKeys.LOGS}>
                  <PivotItem headerText={t('logs', { ns: NS.RESULT_EXPLORER })} itemKey={PivotItemKeys.LOGS}>
                    <div className={styles['logs-container']}>
                      <RawLogsTreeView
                        id={experimentDefinitionID}
                        path={folder}
                        companyName={companyNameFromUrl}
                        showLogText={true}
                      />
                      <div className={styles['vertical-divider']}></div>
                      <div className={styles['logs-view-container']}>
                        <FilePreview companyName={companyNameFromUrl} />
                      </div>
                    </div>
                  </PivotItem>
                </Pivot>
              </div>
            </PageContent>
          </div>
        </main>
      </div>
    </>
  ) : (
    failedResult
  );
};

export const LogFolderView = observer(LogFolderViewFC);
