import React from 'react';
import { observer } from 'mobx-react-lite';
import { MessageBar } from '@fluentui/react';
import { AccordionItem } from '@fluentui/react-components';
import { Accordion } from '@fluentui/react-components';
import { AccordionHeader } from '@fluentui/react-components';
import { AccordionPanel } from '@fluentui/react-components';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import EssentialsBlock from '@/partials/EssentialsBlock/EssentialsBlockTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { extractDefinition, formatJsonResponse } from '@/utils/Helpers';

import { ExperimentPanelAccordionKeys, ExperimentPropertiesPanelVCType } from './ExperimentPropertiesPanelTypes';

import styles from './ExperimentPropertiesPanel.module.css';

const ExperimentPropertiesPanelTemplateFC: React.FC<ExperimentPropertiesPanelVCType> = (props: ExperimentPropertiesPanelVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { experimentDetailsStore } = rootStore;
  const { experimentDetails, experimentHasData } = experimentDetailsStore;
  const { content, setContent, localMessage, onDismissMessage, copyButton, activeKey, userSettings } = props;

  const isLoadingData = !experimentDetails?.agentSteps;

  React.useEffect(() => {
    const definition = extractDefinition(experimentDetails?.json || {});
    let formattingError = false;
    let formattedJson;

    try {
      formattedJson = formatJsonResponse(definition);
    } catch (error) {
      const description = t('experiments-json-error', { ns: NS.ERRORS });

      formattingError = true;
      formattedJson = <>{description}</>;
    }

    const jsonBlock = (
      <>
        {isLoadingData && <LoadingSpinner />}
        {!isLoadingData && (
          <pre className={styles['json-block']}>
            {experimentDetails && formattedJson}
            <div className={styles['json-copy-button']}>{!formattingError && copyButton(formattedJson)}</div>
          </pre>
        )}
      </>
    );

    // NOTE: For now, these do not have memory and will be a lower priority.
    // There could be scenarios where a person never cares about the metadata or JSON, and never wants them open.
    const accordionOpenItems: string[] = [ExperimentPanelAccordionKeys.ESSENTIALS, ExperimentPanelAccordionKeys.JSON];

    const accordion = (
      <>
        <Accordion
          className={styles['accordion']}
          collapsible={true}
          multiple={true}
          defaultOpenItems={accordionOpenItems}
          data-id={'experiments-panel-accordion'}
        >
          <AccordionItem className={styles['accordion-item']} value={ExperimentPanelAccordionKeys.ESSENTIALS}>
            <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
              {t('essentials', { ns: NS.EXPERIMENTS })}
            </AccordionHeader>
            <AccordionPanel className={styles['accordion-panel']}>
              <EssentialsBlock experimentInstance={experimentDetails}></EssentialsBlock>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            className={`${styles['accordion-item']} ${styles['accordion-json']}`}
            value={ExperimentPanelAccordionKeys.JSON}
          >
            <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
              {t('json', { ns: NS.EXPERIMENTS })}
            </AccordionHeader>
            <ScrollableContent scrollable={true} scrollableX={true}>
              <AccordionPanel className={styles['accordion-panel']}>{jsonBlock}</AccordionPanel>
            </ScrollableContent>
          </AccordionItem>
        </Accordion>
      </>
    );

    const panel = (
      <>
        {localMessage && <MessageBar onDismiss={onDismissMessage}>{localMessage}</MessageBar>}
        {experimentHasData && accordion}
      </>
    );

    setContent(panel);
  }, [activeKey, localMessage, experimentHasData, experimentDetails]);

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

const ExperimentPropertiesPanelTemplate = observer(ExperimentPropertiesPanelTemplateFC);

export default ExperimentPropertiesPanelTemplate;
