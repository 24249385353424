import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { AIRExperimentPublishVCType } from '@/partials/_air/ExperimentPublish/ExperimentPublishTypes';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from './ExperimentPublish.module.css';

const AIRExperimentPublishTemplateFC: React.FC<AIRExperimentPublishVCType> = (props: AIRExperimentPublishVCType) => {
  const { loading, header, message } = props;

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  return (
    <div className={styles['height']}>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      {loading && (
        <div>
          <h4 className="mb-4">
            <Trans ns={NS.EDITOR as Namespace}>checking-for-validation-errors</Trans>
          </h4>
          <LoadingSpinner />
        </div>
      )}
      {!loading && (
        <div>
          <h4 className="mb-4">{header}</h4>
          <h6 className="mb-4">{message}</h6>
        </div>
      )}
    </div>
  );
};

const AIRExperimentPublishTemplate = observer(AIRExperimentPublishTemplateFC);

export default AIRExperimentPublishTemplate;
