import { ExperimentType } from '@/components/Experiments/ExperimentsTypes';
import { ActionTypeVoid, ActionTypeWithParamReturn } from '@/types/AppSettingsTypes';

class ExperimentInstanceModalViewModel {
  public title: string;
  public labId: string;
  public instanceId: string;
  public loadMessage: string;

  constructor(title: string, labId: string, instanceId: string, loadMessage: string) {
    this.title = title;
    this.labId = labId;
    this.instanceId = instanceId;
    this.loadMessage = loadMessage;
  }
}

export default ExperimentInstanceModalViewModel;
