import { Pagination } from '@/constants/SystemConstants';

import { PaginationProps, PaginationType } from './PaginationTypes';

// To paginate data on client side
const paginateData = (
  results: any,
  paginationType: PaginationType,
  hasFiltersApplied = false,
  currentPage = 0,
): {
  paginatedItems: any;
  updatedPaginationType: PaginationType;
} => {
  const { paginationProps, isClientSide, showPagination } = paginationType || {};
  const { pageSize } = paginationProps || {};

  if (showPagination && isClientSide) {
    const totalItems = results?.length || 0;
    const startIndex = currentPage * pageSize;
    const endIndex = Math.min((currentPage + 1) * pageSize, totalItems);

    const updatedPaginationProps: PaginationProps = {
      ...paginationProps,
      pageSize: pageSize,
      currentPage,
      totalItems,
      hasFiltersApplied,
    };

    const paginatedItems = results?.slice(startIndex, endIndex);
    paginationType.paginationProps = updatedPaginationProps;

    return { paginatedItems, updatedPaginationType: paginationType };
  } else {
    return { paginatedItems: results, updatedPaginationType: null };
  }
};

// This can be called when we do not need values except the default ones
const getPaginationDefaults = (
  handlePaginationChange?: (currentPage: number) => void,
  showPagination = true, // Passing false from the component will not show the pagination and a way to remove pagination quickly
  isClientSide = true,
) => {
  const paginationDefaults: PaginationType = {
    paginationProps: {
      infoTemplateKey: Pagination.STANDARD_INFO_TEMPLATE_KEY,
      filterTemplateKey: Pagination.STANDARD_FILTER_TEMPLATE_KEY,
      pageSize: Pagination.DEFAULT_PAGE_SIZE,
      hasFiltersApplied: false,
    },
    isClientSide,
    showPagination,
    onPageChange: handlePaginationChange,
  };

  return paginationDefaults;
};

// Adding additional params when we need customization
const setAdditionsToPagination = (
  handlePaginationChange: (currentPage: number) => void,
  showPagination = true, // Passing false from the component will not show the pagination and a way to remove pagination quickly
  isClientSide = true,
  selectedPageSize?: number,
  currentPage?: number,
  totalItems?: number,
  hasFiltersApplied?: boolean,
  infoTemplateKey?: string,
  filterTemplateKey?: string,
): PaginationType => {
  const { paginationProps, ...prevPaginationDefaults } = getPaginationDefaults(
    handlePaginationChange,
    showPagination,
    isClientSide,
  );

  const updatedPaginationType: PaginationType = {
    ...prevPaginationDefaults,
    paginationProps: {
      ...paginationProps,
      pageSize: selectedPageSize || Pagination.DEFAULT_PAGE_SIZE,
      currentPage: currentPage || Pagination.CURRENT_PAGE,
      totalItems: totalItems || 0,
      hasFiltersApplied: hasFiltersApplied || false,
      infoTemplateKey: infoTemplateKey || paginationProps.infoTemplateKey,
      filterTemplateKey: filterTemplateKey || paginationProps.filterTemplateKey,
    },
  };

  return updatedPaginationType;
};

export { paginateData, getPaginationDefaults, setAdditionsToPagination };
