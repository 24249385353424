import React from 'react';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import classNames from 'classnames';
import { t } from 'i18next';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';

import documentationStyles from '@/components/Documentation/DocumentationStyles';
import { DocumentType, LinksListType } from '@/components/Documentation/DocumentationTypes';
import { DocumentationVCType } from '@/components/Documentation/DocumentationTypes';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from '@/components/Documentation/Documentation.module.css';

const DocumentationTemplateFC: React.FC<DocumentationVCType> = (props: DocumentationVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { tabMemory, setTabMemory } = appSettingsStore;

  const { activeId, defaultActiveId, documents, documentContents, links, loaded, pageKey, handleScroll, doScroll, setActiveId } =
    props;
  const activeTab: string = tabMemory[pageKey as string];

  return (
    <>
      {loaded ? (
        <Pivot
          className={styles['pivot']}
          defaultSelectedKey={activeTab}
          onLinkClick={(item) => {
            setTabMemory(pageKey, item.props.itemKey);
            setActiveId(defaultActiveId);
          }}
        >
          {documents.map((doc: DocumentType) => {
            const { title, url } = doc;
            const navLinks = links[url as string];

            return (
              <PivotItem headerText={title} key={url} itemKey={url} className={styles['pivot-item']}>
                <PageContent
                  scrollable={true}
                  componentStyles={documentationStyles.defaultPage}
                  handleScroll={() => {
                    handleScroll(url);
                  }}
                >
                  <div className={styles['container']}>
                    <div className={styles['main-content']}>
                      <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSlug] as any} remarkPlugins={[remarkGfm]}>
                        {documentContents[url as string]}
                      </ReactMarkdown>
                    </div>
                    <div className={styles['sidebar']}>
                      <h3>{t('on-this-page', { ns: NS.DOCUMENTATION })}</h3>
                      <ul>
                        {navLinks && activeId ? (
                          navLinks.map((link: LinksListType) => (
                            <li key={link.id}>
                              <a
                                onClick={(e) => doScroll(e, link)}
                                className={classNames(styles[`nav-link-level-${link.level}`], {
                                  [styles['active']]: activeId[url as string] === link.id,
                                })}
                              >
                                {link.title}
                              </a>
                            </li>
                          ))
                        ) : (
                          <LoadingSpinner />
                        )}
                      </ul>
                    </div>
                  </div>
                </PageContent>
              </PivotItem>
            );
          })}
        </Pivot>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

const DocumentationTemplate = observer(DocumentationTemplateFC);

export default DocumentationTemplate;
