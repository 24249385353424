import axios, { AxiosError } from 'axios';

import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { SeverityLevel } from '@/services/_air/monitoring/SeverityLevel';
import { notificationService } from '@/services/_air/notification-services/NotificationService';

const isAxiosError = (error: any) => (error as AxiosError).isAxiosError !== undefined;

const processApiError = (error: AxiosError, notificationMessage?: string): void => {
  if (axios.isCancel(error)) {
    void monitoringService.logEvent('axios request was cancelled');
  } else if (isAxiosError(error)) {
    void monitoringService.logEvent(
      `axios ${error.config.method} request "${error.config.url}" failed with code ${error.response?.status}`,
      SeverityLevel.Error,
      'error',
      error,
    );

    if (error.response !== undefined) {
      notificationService.showError(
        notificationMessage ? `${notificationMessage}. ${error.response.data}.` : `${error.response.data}`,
      );
    } else {
      notificationService.showError(notificationMessage ? `${notificationMessage}. ${error.message}.` : error.message);
    }
  } else {
    throw error;
  }
};

export { processApiError };
