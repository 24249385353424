/*----------------------------------------------------------------------------------------------------------------------------
# 404Page.tsx: 
# This page tells the user the requested page was not found.
# Future versions would tell them the deviant URL that was requested.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Namespace, Trans } from 'react-i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';

import styles from './404Page.module.css';

const PageNotFound: React.FC = () => {
  return (
    <div className={`fullscreen container ${styles.notfound}`}>
      <div className={`${styles['fullpage-message']}`}>
        <Trans ns={NS.HELP as Namespace}>page-not-found-help</Trans>
      </div>
    </div>
  );
};

export default PageNotFound;
