import React from 'react';
import { IconButton, Label, Modal, PrimaryButton, TextField } from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels, Messages, ModalHead } from '@/constants/LabsConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { regressionTestPassRequestService } from '@/services/_labs/request-services/RegressionTestPassRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/Labs.css';

export const SaveSignOffResult = (props: any) => {
  const signOffTitleId = useId('signOffResultTitle');
  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const DeleteIcon: IIconProps = { iconName: SystemIcons.DELETE };
  const AddIcon: IIconProps = { iconName: SystemIcons.ADD };
  const [checkBugId, setCheckBugId] = React.useState<string>('');
  const [bugSpinner, setBugSpinner] = React.useState<boolean>(false);
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;

  const onDropdownChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      props.setSelectedSignOffDropdown(item.text);
    }
  };

  const handleDelete = (id: string, bugs: any) => {
    const removedBugs = bugs.filter((bugId: any) => bugId !== id);

    props.setNewBugId(removedBugs);

    if (removedBugs.length >= 1) {
      props.setBugId(removedBugs[removedBugs.length - 1]);
    }

    props.setCheckBugId(false);
  };

  const handleAdd = (bugId: string, bugs: string[]) => {
    verifyBugId(bugId, bugs);
  };

  const verifyBugId = React.useCallback(
    async (bugId: string, bugs: string[]) => {
      setBugSpinner(true);
      await regressionTestPassRequestService
        .verifyBugId(bugId)
        .then((result: any) => {
          if (result === Labels.success) {
            const checkBugId = bugs.includes(bugId);

            if (!checkBugId && bugId !== '') {
              const newBugs = [...bugs, bugId];

              props.setNewBugId(newBugs);
            }

            props.setCheckBugId(false);
          } else if (result.includes(Labels.invalid)) {
            props.setCheckBugId(true);
            props.setInvalidBugs(result);
          }
        })
        .catch((error: any) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
          };

          HandleError(handleErrorProps);
        })
        .finally(() => {
          setBugSpinner(false);
        });
    },
    [props],
  );

  return (
    <div>
      <Modal
        titleAriaId={signOffTitleId}
        isOpen={props.signOffResultModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        className="labs-wrapper rounded"
        onDismiss={props.handleSignOffResultModalClose}
      >
        <div className={contentStyles.header}>
          <h6>{ModalHead.signOffDetails}</h6>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.handleSignOffResultModalClose}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="container">
            <form
              onSubmit={(e: any) =>
                props.saveSingoff(
                  e,
                  props.resultDetails,
                  props.selectedSignOffDropdown,
                  props.newBugId,
                  props.bugId,
                  props.signOffOwner,
                  props.signOffNotes,
                  props.tableIndex,
                )
              }
            >
              <div className="form-group">
                <Label>{Labels.signOff}</Label>
                <Dropdown
                  required
                  defaultSelectedKey={props.selectedSignOffDropdown}
                  placeholder="Select an option"
                  options={props.signOffDropdownOptions}
                  onChange={onDropdownChange}
                />
              </div>
              <div className="form-group">
                <Label>{Labels.bugId}</Label>
                <div className="multiple-bug">
                  <div className="form-inline">
                    {props.resultDetails.Result !== Labels.fail ? (
                      <TextField
                        type="text"
                        className="add-bug-w"
                        value={props.bugId}
                        onChange={(e: any) => props.setBugId((e.target as any).value)}
                      />
                    ) : (
                      <TextField
                        required
                        type="text"
                        className="add-bug-w"
                        value={props.bugId}
                        onChange={(e: any) => props.setBugId((e.target as any).value)}
                      />
                    )}
                    {bugSpinner ? (
                      <LoadingSpinner />
                    ) : (
                      <IconButton
                        iconProps={AddIcon}
                        title="Add"
                        className="ml-3"
                        ariaLabel="add"
                        onClick={() => handleAdd(props.bugId, props.newBugId)}
                      />
                    )}
                  </div>
                  {props.newBugId.map((item: string, index: any) => (
                    <div key={index} className="form-group ml-2">
                      <span className="mr-2">{item}</span>
                      <IconButton
                        iconProps={DeleteIcon}
                        title="Delete"
                        ariaLabel="delete"
                        onClick={() => handleDelete(item, props.newBugId)}
                      />
                    </div>
                  ))}
                  {props.checkBugId && (
                    <div className="form-group mt-2">
                      <span className="text-danger">{`${props.invalidBugs}.${Messages.invalidBugId}`}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <Label>{Labels.signOffUser}</Label>
                <TextField
                  required
                  type="text"
                  value={props.signOffOwner}
                  onChange={(e: any) => props.setSignOffOwner((e.target as any).value)}
                />
              </div>
              <div className="form-group">
                <Label>{Labels.notes}</Label>
                <TextField
                  multiline
                  type="text"
                  value={props.signOffNotes}
                  resizable={false}
                  onChange={(e: any) => props.setSignOffNotes((e.target as any).value)}
                />
              </div>
              <br />
              <div className="row ml-0">
                <PrimaryButton disabled={props.isSubmit} className="h30px w-100" type="submit">
                  {props.isSubmit ? (
                    <>
                      <LoadingSpinner />
                      {Messages.saving}
                    </>
                  ) : (
                    <>{Buttons.save}</>
                  )}
                  <br />
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
