import { Configuration } from '@azure/msal-browser';

import { authTenantId, backendScope, clientId } from './utils/Env';

// Config object to be passed to Msal on creation
export const msalConfig: typeof Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${authTenantId}`, // Results Explorer is only single tenant, so for now we need to be as well
    clientId: clientId,
    redirectUri: window.location.origin,
    silentRedirectUri: `${window.location.origin}/blank.html`,
  },
};

// API Scopes
export const GanymedeApiScope = {
  scopes: [backendScope],
};
// Microsoft Graph Scope (currently unused)
export const graphMeScope = {
  scopes: ['User.Read'],
};
