import React from 'react';
import { observer } from 'mobx-react-lite';

import { ExperimentDetailsVMType } from '@/components/ExperimentDetails/ExperimentDetailsTypes';
import ExperimentDetailsViewController from '@/components/ExperimentDetails/ExperimentDetailsViewController';
import ExperimentDetailsViewModel from '@/components/ExperimentDetails/ExperimentDetailsViewModel';

const ExperimentDetailsFC: React.FC<ExperimentDetailsVMType> = ({ title, labId, instanceId, loadMessage }) => {
  const viewModel = React.useMemo(
    () => new ExperimentDetailsViewModel(title, labId, instanceId, loadMessage),
    [title, labId, instanceId, loadMessage],
  );

  return <ExperimentDetailsViewController viewModel={viewModel}></ExperimentDetailsViewController>;
};

const ExperimentDetails = observer(ExperimentDetailsFC);

export default ExperimentDetails;
