import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { EventHubSettings } from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class SettingsRequestService extends BaseRequestService {
  async getEventHubSettings(cancelToken?: CancelToken): Promise<EventHubSettings> {
    const { data } = await this.get('settings/GetEventHubSettings', null, { cancelToken });

    return data;
  }
}

export const settingsRequestService = new SettingsRequestService(backendEndpoint);
