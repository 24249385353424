import React from 'react';
import { Tag } from 'antd';

interface MetricDescription {
  metricKeyword: string;
  metricOperation: string;
  metricValue: string;
}

type Props = {
  ruleExpression: any;
};

export const TagComponent = (props: Props) => {
  const { ruleExpression } = props;

  return (
    <div>
      {ruleExpression &&
        ruleExpression.map((expressions: MetricDescription[], i: number) => (
          <>
            {i > 0 ? <span style={{ color: 'blue', fontWeight: 'bold' }}> OR </span> : ''}
            <Tag>
              {expressions.map((expression, index) => (
                <span key={index}>
                  {index > 0 ? <span style={{ color: 'blue', fontWeight: 'bold' }}> AND </span> : ''}
                  {expression.metricKeyword} {expression.metricOperation} {expression.metricValue}
                </span>
              ))}
            </Tag>
          </>
        ))}
    </div>
  );
};
