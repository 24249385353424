import React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider } from '@fluentui/react-components';

import styles from './PageDivider.module.css';

const PageDividerFC: React.FC = () => {
  return (
    <div className={styles['page-divider']}>
      <Divider orientation={'horizontal'} />
    </div>
  );
};

const PageDivider = observer(PageDividerFC);

export default PageDivider;
