import React from 'react';
import { observer } from 'mobx-react-lite';

import ButtonListViewController from '@/partials/ButtonList/ButtonListViewController';

import { TimeFilterTemplateType } from './TimeFilterType';

const TimeFilterTemplateFC: React.FC<TimeFilterTemplateType> = (props: TimeFilterTemplateType): React.ReactElement => {
  return <ButtonListViewController buttonListProps={props.buttonProps} />;
};

const TimeFilterTemplate = observer(TimeFilterTemplateFC);

export default TimeFilterTemplate;
