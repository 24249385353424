import React from 'react';
import { Table } from 'reactstrap';

import { DataKeys, Labels, TableHeaders, TableRaws } from '@/constants/AirConstants';
import { MetricsBySelectedColor, Stats } from '@/types/_air';
import { getThreshholdClassName } from '@/utils/_air/DashboardScore';

import { WorkloadGraph } from './WorkloadGraph';

import '@/styles/Air.css';
import './result.css';

interface Props {
  workload: MetricsBySelectedColor;
}

export const WorkloadInfoSubSet = ({ workload }: Props): JSX.Element => {
  const createStatElement = (data: Stats, objName: string, isHigherBetter: boolean) => {
    const item = [];

    if (data !== null) {
      for (const key in data) {
        if (key !== DataKeys.experimentGroup) {
          if (objName === 'Group A/Group B Diff') {
            const className = getThreshholdClassName((data as { [index: string]: any })[key as string], isHigherBetter);

            item.push(
              <tr key={key}>
                <td className={className}>
                  <span className="text-light">
                    {`${
                      typeof (data as { [index: string]: any })[key as string] === 'number'
                        ? ((data as { [index: string]: any })[key as string] * 100).toFixed(2)
                        : (data as { [index: string]: any })[key as string]
                    } %`}
                  </span>
                </td>
              </tr>,
            );
          } else {
            item.push(
              <tr key={key}>
                <td>
                  {typeof (data as { [index: string]: any })[key as string] === 'number'
                    ? (data as { [index: string]: any })[key as string].toFixed(2)
                    : (data as { [index: string]: any })[key as string]}
                </td>
              </tr>,
            );
          }
        }
      }

      return item;
    }
  };

  const renderStatElements = (): JSX.Element[] =>
    Object.keys(workload.stats).map((item: string, index: number) => (
      <td key={index}>
        <Table borderless>
          <tbody>
            {createStatElement(
              (workload.stats as { [index: string]: any })[item as string],
              (workload.stats as { [index: string]: any })[item as string].experimentGroup,
              workload.isHigherBetter,
            )}
          </tbody>
        </Table>
      </td>
    ));

  return (
    <>
      <Table borderless className="text-left">
        <thead>
          <tr>
            <th>{TableHeaders.stats}</th>
            <th>{TableHeaders.aStats}</th>
            <th>{TableHeaders.bStats}</th>
            <th>{TableHeaders.abStats}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Table borderless>
                <tbody className="text-right font-weight-bold">
                  <tr>
                    <td>{TableRaws.mean}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.min}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.p25}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.p50}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.p75}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.p95}</td>
                  </tr>
                  <tr>
                    <td>{TableRaws.max}</td>
                  </tr>
                </tbody>
              </Table>
            </td>
            {renderStatElements()}
          </tr>
        </tbody>
      </Table>
      <div className="text-left">
        <h4>{Labels.avsBDivergence}</h4>
      </div>
      <div className="chart">
        <WorkloadGraph aGroupProp={workload.stats[0]} bGroupProp={workload.stats[1]} />
      </div>
      <hr />
    </>
  );
};
