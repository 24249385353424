import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

import CosmosTemplate from './CosmosTemplate';
import { CosmosType } from './CosmosTypes';

const CosmosViewControllerFC: React.FC<{ viewModel: CosmosType }> = (props: { viewModel: CosmosType }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { userInfoStore } = rootStore;
  const cosmosViewModel: CosmosType = props.viewModel;

  const getEntryKey = cosmosViewModel.getEntryKey;
  const getEntryValue = cosmosViewModel.getEntryValue;
  const createEntryKey = cosmosViewModel.createEntryKey;
  const createEntryValue = cosmosViewModel.createEntryValue;
  const updateEntryKey = cosmosViewModel.updateEntryKey;
  const updateEntryValue = cosmosViewModel.updateEntryValue;
  const deleteEntryKey = cosmosViewModel.deleteEntryKey;
  const isGlobal = cosmosViewModel.isGlobal;

  const setGetEntryKey: ActionTypeWithParam = cosmosViewModel.setGetEntryKey;
  const setGetEntryValue: ActionTypeWithParam = cosmosViewModel.setGetEntryValue;
  const setCreateEntryKey: ActionTypeWithParam = cosmosViewModel.setCreateEntryKey;
  const setCreateEntryValue: ActionTypeWithParam = cosmosViewModel.setCreateEntryValue;
  const setUpdateEntryKey: ActionTypeWithParam = cosmosViewModel.setUpdateEntryKey;
  const setUpdateEntryValue: ActionTypeWithParam = cosmosViewModel.setUpdateEntryValue;
  const setDeleteEntryKey: ActionTypeWithParam = cosmosViewModel.setDeleteEntryKey;
  const setIsGlobal: ActionTypeWithParam = cosmosViewModel.setIsGlobal;
  const toggleIsGlobal: ActionTypeWithParam = cosmosViewModel.toggleIsGlobal;

  const userId = userInfoStore.userName;
  const globalId = 'global-id';

  const databaseId = 'Settings';

  const computeId = (global: boolean) => {
    return global ? globalId : userId;
  };

  const getEntry = () => {
    const id = computeId(isGlobal);

    cosmosViewModel.getEntry(databaseId, id, getEntryKey);
  };

  const createEntry = () => {
    const id = computeId(isGlobal);

    cosmosViewModel.createEntry(databaseId, id, createEntryKey, createEntryValue);
  };

  const updateEntry = () => {
    const id = computeId(isGlobal);

    cosmosViewModel.updateEntry(databaseId, id, updateEntryKey, updateEntryValue);
  };

  const deleteEntry = () => {
    const id = computeId(isGlobal);

    cosmosViewModel.deleteEntry(databaseId, id, deleteEntryKey);
  };

  return (
    <CosmosTemplate
      getEntryKey={getEntryKey}
      getEntryValue={getEntryValue}
      createEntryKey={createEntryKey}
      createEntryValue={createEntryValue}
      updateEntryKey={updateEntryKey}
      updateEntryValue={updateEntryValue}
      deleteEntryKey={deleteEntryKey}
      isGlobal={isGlobal}
      setGetEntryKey={setGetEntryKey}
      setGetEntryValue={setGetEntryValue}
      setCreateEntryKey={setCreateEntryKey}
      setCreateEntryValue={setCreateEntryValue}
      setUpdateEntryKey={setUpdateEntryKey}
      setUpdateEntryValue={setUpdateEntryValue}
      setDeleteEntryKey={setDeleteEntryKey}
      setIsGlobal={setIsGlobal}
      toggleIsGlobal={toggleIsGlobal}
      createEntry={createEntry}
      getEntry={getEntry}
      updateEntry={updateEntry}
      deleteEntry={deleteEntry}
    ></CosmosTemplate>
  );
};

const CosmosViewController = observer(CosmosViewControllerFC);

export default CosmosViewController;
