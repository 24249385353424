import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogFooter } from '@fluentui/react';

import { DialogType } from './DialogTypes';

const DialogFC: React.FC<DialogType> = (props: DialogType): React.ReactElement => {
  const { title, subtext, body, footer, modalProps, minWidth, hidden, onDismiss } = props;
  const maxWidth = '90%';

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      modalProps={modalProps}
      dialogContentProps={{
        title: title,
        subText: subtext || '',
      }}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      {body}
      {footer && <DialogFooter>{footer}</DialogFooter>}
    </Dialog>
  );
};

const DialogBox = observer(DialogFC);

export default DialogBox;
