import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { InteractionStatus } from '@azure/msal-browser';
import { Callout, IconButton } from '@fluentui/react';
import { Person, ViewType } from '@microsoft/mgt-react';
import { t } from 'i18next';

import UserInfoTemplate from '@/components/UserInfo/UserInfoTemplate';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { UserProfileTemplateType } from '@/partials/UserProfile/UserProfileTypes';

import styles from './UserProfile.module.css';

const UserProfileTemplate: React.FC<UserProfileTemplateType> = (props: UserProfileTemplateType): React.ReactElement => {
  const {
    inProgress,
    isAuthenticated,
    userIcon,
    personDetails,
    buttonId,
    showLoginInfo,
    toggleShowLoginInfo,
    handleLogin,
    handleLogout,
  } = props;

  if (isAuthenticated) {
    // Sign Out Button
    return (
      <>
        <Person
          id={buttonId}
          className={styles['pointer']}
          fetchImage={true}
          personDetails={personDetails}
          onClick={toggleShowLoginInfo}
          avatarSize="auto"
          view={ViewType.image}
        ></Person>
        {showLoginInfo && (
          <Callout
            className={`${styles.callout} ${styles['callout-profile']}`}
            target={`#${buttonId}`}
            onDismiss={toggleShowLoginInfo}
          >
            <UserInfoTemplate />
            <div
              className={styles['button']}
              onClick={handleLogout}
              title={t('click-to-signout', { ns: NS.DEFAULT })}
              aria-label={t('click-to-signout', { ns: NS.DEFAULT })}
            >
              <Trans ns={NS.TITLES as Namespace}>sign-out</Trans>
            </div>
            <></>
          </Callout>
        )}
      </>
    );
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    // Sign In Button
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in.
    // Processing the server response takes a render cycle or two
    return (
      <IconButton
        className={styles['icon']}
        iconProps={userIcon}
        onClick={handleLogin}
        title={t('click-to-signin', { ns: NS.DEFAULT })}
        aria-label={t('click-to-signin', { ns: NS.DEFAULT })}
      />
    );
  } else {
    return <></>;
  }
};

export default UserProfileTemplate;
