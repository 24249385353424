import React from 'react';
import { observer } from 'mobx-react-lite';
import { Panel, PanelType } from '@fluentui/react';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';

import { SidePanelType } from './SidePanelTypes';

import styles from './SidePanel.module.css';

export const SidePanelDefaults = {
  className: styles['panel'],
  isBlocking: false,
  isFooterAtBottom: true,
  isLightDismiss: true,
  closeButtonAriaLabel: t('close', { ns: NS.COMMON }),
  type: PanelType.custom, // this prop makes the panel non-modal
};

const SidePanelFC: React.FC<SidePanelType> = (props: SidePanelType): React.ReactElement => {
  const { userSettings, content, style } = props;

  return (
    <Panel {...SidePanelDefaults} {...userSettings} style={style}>
      {content}
    </Panel>
  );
};

const SidePanel = observer(SidePanelFC);

export default SidePanel;
