/*----------------------------------------------------------------------------------------------------------------------------
# Home.tsx: 
# This Component is our Home Component. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { ExperimentRouteType } from '@/components/Experiments/ExperimentsTypes';
import ExperimentsWrapper from '@/components/Experiments/ExperimentsWrapper';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const HomeFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { isMockDataMode } = appSettingsStore;
  const { addGeneralFadingMessage, addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const { instanceId } = useParams<ExperimentRouteType>();
  const panelMode = !instanceId;
  const fullscreenMode = !panelMode;

  const title = t('experiments', { ns: NS.EXPERIMENTS });
  const experimentsLink = (
    <>
      <Link to={{ pathname: Navigation.GANYMEDE.EXPERIMENTS }}>{t('all-experiments', { ns: NS.EXPERIMENTS })}</Link> {TITLE_DIVIDER}{' '}
      {instanceId}
    </>
  );
  const subTitle = fullscreenMode ? experimentsLink : t('all-experiments', { ns: NS.EXPERIMENTS });
  const mockDataMessageKey = isMockDataMode ? 'mock-data-on' : 'mock-data-off';

  React.useEffect(() => {
    addGeneralFadingMessage(t(mockDataMessageKey, { ns: NS.EXPERIMENTS }), mockDataMessageKey);
  }, [isMockDataMode]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={false}>
            <div className="fullscreen">
              <ExperimentsWrapper></ExperimentsWrapper>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const Home = observer(HomeFC);

export default Home;
