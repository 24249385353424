import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import moment from 'moment';

import { DateFormats } from '@/constants/DateFormatConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import { reliabilityRequestService } from '@/services/request-services/ReliabilityRequestService';
import { RootStore } from '@/stores/RootStore';
import { BooleanTypeAny } from '@/types/AppSettingsTypes';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableType } from '@/types/TableTypes';
import { UrlArray } from '@/utils/UrlArray';
import { UrlVariable } from '@/utils/UrlVariable';

import ReliabilityStore from './ReliabilityStore';
import {
  AnnotationsQueryParams,
  DurationType,
  ErrorsCountType,
  ErrorsGraphRequestQueryParams,
  ErrorsRequestQueryParams,
  ErrorsType,
  GetDurationType,
  GetListType,
  GetNumberType,
  GetStringType,
  NameType,
  PagesRequestQueryParams,
  SetListType,
  SetNumberType,
  SetStringType,
  UsersRequestQueryParams,
} from './ReliabilityType';

class ReliabilityViewModel {
  protected _isDebugMode: boolean;
  protected _isGraphLoading: boolean;
  protected _isPagesLoading: boolean;
  protected _isErrorsLoading: boolean;
  protected _isUsersLoading: boolean;
  protected _isGraphApiError: boolean;
  protected _isPagesApiError: boolean;
  protected _isErrorsApiError: boolean;
  protected _isUsersApiError: boolean;
  protected _selectedPages: UrlArray;
  protected _selectedUser: UrlVariable;
  protected _getPages: GetListType;
  protected _setPages: SetListType;
  protected _setSelectedPages: SetListType;
  protected _getUsers: GetListType;
  protected _setUsers: SetListType;
  protected _setSelectedUser: SetStringType;
  protected _getErrors: GetListType;
  protected _setErrors: SetListType;
  protected _getTotalPageViews: GetNumberType;
  protected _setTotalPageViews: SetNumberType;
  protected _getTotalErrors: GetNumberType;
  protected _setTotalErrors: SetNumberType;
  protected _getDateRange: GetStringType;
  protected _setDateRange: SetStringType;
  protected _getTimeDuration: GetNumberType;
  protected _getDurationType: GetDurationType;
  protected _getBucketDuration: GetNumberType;
  protected _getBucketType: GetDurationType;
  protected _setGraphData: SetListType;
  protected _setIsGraphLoading: BooleanTypeAny;
  protected _setIsPagesLoading: BooleanTypeAny;
  protected _setIsErrorsLoading: BooleanTypeAny;
  protected _setIsUsersLoading: BooleanTypeAny;
  protected _setIsGraphApiError: BooleanTypeAny;
  protected _setIsPagesApiError: BooleanTypeAny;
  protected _setIsErrorsApiError: BooleanTypeAny;
  protected _setIsUsersApiError: BooleanTypeAny;
  protected _setReleaseAnnotations: SetListType;

  protected _errorListTable: TableType;
  protected _errorCountTable: TableType;
  protected _addApiError: any;
  protected _addGlobalMessage: any;
  protected _addHelpMessage: any;

  protected readonly _apiErrorGroup = 'api-error';
  protected readonly _apiSuccessGroup = 'api-success';

  constructor(rootStore: RootStore) {
    const { appSettingsStore, reliabilityStore, systemMessageStore } = rootStore;
    const { addGlobalMessage, addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
    const { isDebugMode } = appSettingsStore;

    const {
      isGraphLoading,
      isPagesLoading,
      isErrorsLoading,
      isUsersLoading,
      isGraphApiError,
      isPagesApiError,
      isErrorsApiError,
      isUsersApiError,
      selectedPages,
      selectedUser,
      getPages,
      setPages,
      setSelectedPages,
      getUsers,
      setUsers,
      setSelectedUser,
      getErrors,
      setErrors,
      getTotalErrors,
      setTotalErrors,
      getTotalPageViews,
      setTotalPageViews,
      getDurationType,
      setDateRange,
      getDateRange,
      getTimeDuration,
      getBucketDuration,
      getBucketType,
      setGraphData,
      setIsGraphLoading,
      setIsPagesLoading,
      setIsErrorsLoading,
      setIsUsersLoading,
      setIsGraphApiError,
      setIsPagesApiError,
      setIsErrorsApiError,
      setIsUsersApiError,
      setReleaseAnnotations,
    } = reliabilityStore;

    this._isDebugMode = isDebugMode;
    this._isGraphLoading = isGraphLoading;
    this._isPagesLoading = isPagesLoading;
    this._isErrorsLoading = isErrorsLoading;
    this._isUsersLoading = isUsersLoading;
    this._isGraphApiError = isGraphApiError;
    this._isPagesApiError = isPagesApiError;
    this._isErrorsApiError = isErrorsApiError;
    this._isUsersApiError = isUsersApiError;
    this._selectedPages = selectedPages;
    this._selectedUser = selectedUser;
    this._getPages = getPages;
    this._setPages = setPages;
    this._setSelectedPages = setSelectedPages;
    this._getUsers = getUsers;
    this._setUsers = setUsers;
    this._setSelectedUser = setSelectedUser;
    this._getErrors = getErrors;
    this._setErrors = setErrors;
    this._getTotalPageViews = getTotalPageViews;
    this._setTotalPageViews = setTotalPageViews;
    this._getTotalErrors = getTotalErrors;
    this._setTotalErrors = setTotalErrors;
    this._getDateRange = getDateRange;
    this._setDateRange = setDateRange;
    this._getTimeDuration = getTimeDuration;
    this._getDurationType = getDurationType;
    this._getBucketDuration = getBucketDuration;
    this._getBucketType = getBucketType;
    this._setGraphData = setGraphData;
    this._setIsGraphLoading = setIsGraphLoading;
    this._setIsPagesLoading = setIsPagesLoading;
    this._setIsErrorsLoading = setIsErrorsLoading;
    this._setIsUsersLoading = setIsUsersLoading;
    this._setIsGraphApiError = setIsGraphApiError;
    this._setIsPagesApiError = setIsPagesApiError;
    this._setIsErrorsApiError = setIsErrorsApiError;
    this._setIsUsersApiError = setIsUsersApiError;
    this._setReleaseAnnotations = setReleaseAnnotations;
    this._addGlobalMessage = addGlobalMessage;
    this._addHelpMessage = addHelpMessage;

    this._errorListTable = {
      title: '',
      items: this.errors,
      columns: [
        {
          key: 'column1',
          name: 'type',
          fieldName: 'exceptionType',
          minWidth: 200,
        },
        {
          key: 'column2',
          name: 'error-message',
          fieldName: 'errorMessage',
          targetWidthProportion: 1,
          minWidth: 250,
        },
        {
          key: 'column3',
          name: 'count',
          fieldName: 'count',
          minWidth: 40,
          data: 'number',
        },
        {
          key: 'column4',
          name: 'users-impacted',
          fieldName: 'usersImpacted',
          minWidth: 60,
          data: 'number',
        },
        {
          key: 'column5',
          name: 'pages-impacted',
          fieldName: 'pagesImpacted',
          minWidth: 60,
          data: 'number',
        },
        {
          key: 'column6',
          name: 'first-seen-[utc]',
          fieldName: 'firstSeen',
          minWidth: 90,
        },
        {
          key: 'column7',
          name: 'last-seen-[utc]',
          fieldName: 'lastSeen',
          minWidth: 90,
        },
      ],
      displayColumns: ['type', 'error-message', 'count', 'users-impacted', 'pages-impacted', 'first-seen-[utc]', 'last-seen-[utc]'],
    };

    const totalErrors = this._getTotalErrors();
    const totalPageViews = this._getTotalPageViews();

    const errorCountItem: ErrorsCountType = {
      totalErrors: totalErrors,
      totalPageViews: totalPageViews,
      errorsPerPageView: totalPageViews === 0 ? '0.00' : (totalErrors / totalPageViews).toFixed(2),
    };

    this._errorCountTable = {
      title: '',
      items: [errorCountItem],
      columns: [
        {
          key: 'column1',
          name: 'errors',
          fieldName: 'totalErrors',
          minWidth: 150,
          maxWidth: 350,
          data: 'number',
        },
        {
          key: 'column2',
          name: 'page-views',
          fieldName: 'totalPageViews',
          minWidth: 150,
          maxWidth: 300,
          data: 'number',
        },
        {
          key: 'column3',
          name: 'errors-per-page-view',
          fieldName: 'errorsPerPageView',
          minWidth: 150,
          maxWidth: 300,
          data: 'number',
        },
      ],
      displayColumns: ['errors', 'page-views', 'errors-per-page-view'],
    };
  }

  public get pages(): Array<IDropdownOption> {
    return this._getPages();
  }

  filterPages(data: string, pages: { name: string }[]) {
    let found = false;

    pages.forEach((page: { name: string }) => {
      if (data === page.name) {
        found = true;
      }
    });

    return found;
  }

  public setPages = async (): Promise<void> => {
    const queryParams: PagesRequestQueryParams = {
      durationType: this.durationType,
    };

    if (this._selectedUser.get() !== this._selectedUser.default) {
      queryParams.userName = this._selectedUser.get();
    }

    try {
      this._isDebugMode && console.log('[ReliabilityViewModel] Calling the Reliability getPages() service.');
      this._setIsPagesLoading(true);

      const pages = await reliabilityRequestService.getPages(this.timeDuration, queryParams);

      // If the currently selected pages doesn't exist in the array remove it from the array
      const foundPages = this._selectedPages.get().filter((data: string) => this.filterPages(data, pages));
      this._setSelectedPages(foundPages);

      this._setPages(this.formatToDropdownOptions(pages));

      if (this._isDebugMode) {
        console.log('[ReliabilityViewModel] getPages() data received.');

        const getPagesSuccess: SystemMessageType = {
          message: 'get-pages-success',
          id: 'get-pages-success',
          namespace: NS.INSIGHTS,
          type: FluentMessageBarType.info,
          groupId: this._apiSuccessGroup,
        };
        this._addGlobalMessage(getPagesSuccess);
      }
    } catch (error) {
      this._isDebugMode && console.error('[ReliabilityViewModel] Error calling the Reliability getPages() service:', error);

      this._setPages(this.formatToDropdownOptions([]));
      // Append an error to the message bar.
      this._setIsPagesApiError(true);
      const message: SystemMessageType = {
        message: 'reliability-pages-error',
        id: 'reliability-pages-error',
        namespace: NS.ERRORS,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.displayOnceOnly,
        groupId: this._apiErrorGroup,
        error,
      };
      this._addGlobalMessage(message);
    } finally {
      this._setIsPagesLoading(false);
    }
  };

  public get users(): Array<IDropdownOption> {
    return this._getUsers();
  }

  public setUsers = async (): Promise<void> => {
    const queryParams: UsersRequestQueryParams = {
      durationType: this.durationType,
    };

    if (this._selectedPages && this._selectedPages.get().length >= 0) {
      queryParams.pageNames = this._selectedPages.get();
    }

    try {
      this._isDebugMode && console.log('[ReliabilityViewModel] Calling the Reliability getUsers() service.');
      this._setIsUsersLoading(true);

      const users = await reliabilityRequestService.getUsers(this.timeDuration, queryParams);

      // If the currently selected user doesn't exist in the array reset to the default value
      if (users.filter((data) => data.name == this._selectedUser.get()).length === 0) {
        this._setSelectedUser(this._selectedUser.default);
      }

      this._setUsers(this.formatToDropdownOptions(users));

      if (this._isDebugMode) {
        console.log('[ReliabilityViewModel] getUsers() data received.');

        const getUsersSuccess: SystemMessageType = {
          message: 'get-users-success',
          id: 'get-users-success',
          namespace: NS.INSIGHTS,
          type: FluentMessageBarType.info,
          groupId: this._apiSuccessGroup,
        };
        this._addGlobalMessage(getUsersSuccess);
      }
    } catch (error) {
      this._isDebugMode && console.error('[ReliabilityViewModel] Error calling the Reliability getUsers() service:', error);

      this._setUsers(this.formatToDropdownOptions([]));
      // Append an error to the message bar.
      this._setIsUsersApiError(true);
      const message: SystemMessageType = {
        message: 'reliability-users-error',
        id: 'reliability-users-error',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.displayOnceOnly,
        namespace: NS.ERRORS,
        groupId: this._apiErrorGroup,
        error,
      };
      this._addGlobalMessage(message);
    } finally {
      this._setIsUsersLoading(false);
    }
  };

  public get errors(): Array<ErrorsType> {
    const errorsData = this._getErrors().map((item: ErrorsType) => {
      const firstSeenUTC = moment(item.firstSeen).format(DateFormats.STANDARD_DATE_TIME);
      const lastSeenUTC = moment(item.lastSeen).format(DateFormats.STANDARD_DATE_TIME);

      return {
        ...item,
        firstSeen: firstSeenUTC,
        lastSeen: lastSeenUTC,
      };
    });

    return errorsData;
  }

  public setErrors = async (): Promise<void> => {
    const queryParams: ErrorsRequestQueryParams = {
      durationType: this.durationType,
    };

    if (this._selectedPages && this._selectedPages.get().length >= 0) {
      queryParams.pageNames = this._selectedPages.get();
    }

    if (this._selectedUser && this._selectedUser.get() !== this._selectedUser.default) {
      queryParams.userName = this._selectedUser.get();
    }

    try {
      this._isDebugMode && console.log('[ReliabilityViewModel] Calling the Reliability getErrorsData() service.');
      this._setIsErrorsLoading(true);

      const data = await reliabilityRequestService.getErrorsData(this.timeDuration, queryParams);

      this._isDebugMode && console.log('[ReliabilityViewModel] getErrorsData() data received.');

      if (this._isDebugMode) {
        console.log('[ReliabilityViewModel] getErrorsData() data received.');

        const getErrorsSuccess: SystemMessageType = {
          message: 'get-errors-success',
          id: 'get-errors-success',
          namespace: NS.INSIGHTS,
          type: FluentMessageBarType.info,
          groupId: this._apiSuccessGroup,
        };
        this._addGlobalMessage(getErrorsSuccess);
      }

      this._setTotalErrors(data.totalErrors);
      this._setTotalPageViews(data.totalPageViews);
      this._setErrors(data.errors);
    } catch (error) {
      this._isDebugMode && console.error('[ReliabilityViewModel] Error calling the Reliability getErrorsData() service:', error);

      this._setTotalErrors(0);
      this._setTotalPageViews(0);
      this._setErrors([]);
      // Append an error to the message bar.
      this._setIsErrorsApiError(true);
      const message: SystemMessageType = {
        message: 'reliability-errors-error',
        id: 'reliability-errors-error',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.displayOnceOnly,
        namespace: NS.ERRORS,
        groupId: this._apiErrorGroup,
        error,
      };
      this._addGlobalMessage(message);
    } finally {
      this._setIsErrorsLoading(false);
    }
  };

  public get dateRange(): string {
    return this._getDateRange();
  }

  public setDateRange = (dateRange: string): void => {
    const ret = this._setDateRange(dateRange);

    this.setPages();
    this.setUsers();
    this.setErrors();
    this.setGraphData();
    this.setReleaseAnnotations();

    return ret;
  };

  public get timeDuration(): number {
    return this._getTimeDuration();
  }

  public get durationType(): DurationType {
    return this._getDurationType();
  }

  public get bucketDuration(): number {
    return this._getBucketDuration();
  }

  public get bucketType(): DurationType {
    return this._getBucketType();
  }

  public setGraphData = async (): Promise<void> => {
    const queryParams: ErrorsGraphRequestQueryParams = {
      durationType: this.durationType,
      timeBucket: this.bucketDuration,
      timeBucketType: this.bucketType,
    };

    if (this._selectedPages && this._selectedPages.get().length >= 0) {
      queryParams.pageNames = this._selectedPages.get();
    }

    if (this._selectedUser && this._selectedUser.get() !== this._selectedUser.default) {
      queryParams.userName = this._selectedUser.get();
    }

    try {
      this._isDebugMode && console.log('[ReliabilityViewModel] Calling the Reliability getGraphData() service.');
      this._setIsGraphLoading(true);

      const data = await reliabilityRequestService.getGraphData(this.timeDuration, queryParams);

      this._isDebugMode && console.log('[ReliabilityViewModel] getGraphData() data received.');

      if (this._isDebugMode) {
        console.log('[ReliabilityViewModel] getGraphData() data received.');

        const getGraphDataSuccess: SystemMessageType = {
          message: 'get-graph-data-success',
          id: 'get-graph-data-success',
          namespace: NS.INSIGHTS,
          type: FluentMessageBarType.info,
          groupId: this._apiSuccessGroup,
        };
        this._addGlobalMessage(getGraphDataSuccess);
      }

      return this._setGraphData(data);
    } catch (error) {
      // Append an error to the message bar.
      this._setIsGraphApiError(true);
      const message: SystemMessageType = {
        message: 'reliability-graph-error',
        id: 'reliability-graph-error',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.displayOnceOnly,
        namespace: NS.ERRORS,
        groupId: this._apiErrorGroup,
        error,
      };
      this._addGlobalMessage(message);
    } finally {
      this._setIsGraphLoading(false);
    }

    return this._setGraphData([]);
  };

  private formatToDropdownOptions<Type extends NameType>(list: Array<Type>): IDropdownOption[] {
    if (list) {
      const Options: IDropdownOption[] = list.map((item) => {
        return {
          key: item.name,
          text: item.name,
        };
      });
      return Options;
    }

    return [];
  }

  public get errorsListTable(): TableType {
    return this._errorListTable;
  }

  public get errorsCountTable(): TableType {
    return this._errorCountTable;
  }

  public setReleaseAnnotations = async (): Promise<void> => {
    const queryParams: AnnotationsQueryParams = {
      timeDuration: this.timeDuration,
      durationType: this.durationType,
    };

    try {
      this._isDebugMode && console.log('[ReliabilityViewModel] Calling the Reliability getReleaseAnnotations() service.');

      const annotations = await reliabilityRequestService.getReleaseAnnotations(queryParams);

      this._isDebugMode && console.log('[ReliabilityViewModel] getReleaseAnnotations() data received.');

      return this._setReleaseAnnotations(annotations);
    } catch (error) {
      this._isDebugMode &&
        console.error('[ReliabilityViewModel] Error calling the Reliability getReleaseAnnotations() service:', error);

      return this._setReleaseAnnotations([]);
    }
  };
}

export default ReliabilityViewModel;
