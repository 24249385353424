import BaseRequestService from '@/services/request-services/RequestService';
import { RegressionTestPassResultList } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultList';
import { RegressionTestPassResultRequestFilters } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultRequestFilters';
import { RegressionTestPassSignOffResult } from '@/types/_labs/RegressionTestPass/RegressionTestPassSignOffResult';
import { testPassUri } from '@/utils/Env';

class RegressionTestPassRequestService extends BaseRequestService {
  constructor() {
    super(testPassUri);
  }

  async getResultList(user: any) {
    const { data } = await this.get(`RegressionTestPassResult/GetList/${user}`);

    return data;
  }

  async getQueryParamResultList(filters: any) {
    const { data } = await this.put('RegressionTestPassResult/GetQueryParamResultList', filters);

    return data;
  }

  async getAdvanceFilteredList(filters: RegressionTestPassResultRequestFilters) {
    const { data } = await this.post('RegressionTestPassResult/GetAdvanceFilteredList', filters);

    return data;
  }

  async getFilteredResultList(filters: RegressionTestPassResultRequestFilters) {
    const { data } = await this.post('RegressionTestPassResult/GetFilteredResultList', filters);

    return data;
  }

  async getSignOffResultData(request: RegressionTestPassResultList) {
    const { data } = await this.post('RegressionTestPassSignOff/GetSignOffResultData', request);

    return data;
  }

  async verifyBugId(request: string) {
    const { data } = await this.get(`RegressionTestPassSignOff/CheckWorkItemById/${request}`);

    return data;
  }

  async saveSignOffResult(request: RegressionTestPassSignOffResult) {
    const { data } = await this.post('RegressionTestPassSignOff/SaveSignOffResults', request);

    return data;
  }
}

export const regressionTestPassRequestService = new RegressionTestPassRequestService();
