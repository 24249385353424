import { action, makeObservable, observable } from 'mobx';

import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';
import { TableColumnType } from '@/types/TableTypes';

import config from './LabList.config.json';
import { LabType } from './LabListTypes';

class LabListStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public isDeleteLabOpen: boolean;
  public filteredLabs: LabType[] = [];
  public isLoading = false;
  public labs: LabType[] = [];
  public labsColumnList: TableColumnType[];
  public labsEntireColumns: TableColumnType[];
  public searchValue: string;
  public selectedLab: LabType = null;
  public isLabRefreshed: boolean;
  public isDeletingLab = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      isDeleteLabOpen: observable,
      filteredLabs: observable,
      isLoading: observable,
      labs: observable,
      labsEntireColumns: observable,
      selectedLab: observable,
      isLabRefreshed: observable,
      isDeletingLab: observable,

      // These values are persisted.
      searchValue: observable,

      // Actions modify the state.
      setFilteredLabs: action,
      setIsDeleteLabOpen: action,
      setIsDeleteLabClose: action,
      setIsLoading: action,
      setLabs: action,
      setLabsColumnList: action,
      setLabsEntireColumns: action,
      setIsLabRefreshed: action,
      setIsDeletingLab: action,
      setSelectedLab: action,
    });

    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    this.labsColumnList = [];
    this.labsEntireColumns = config.labsColumnDefinitions;

    this.searchValue = this.localStorage.getValue(AppSettingsService.FILTER_LABS_SEARCH_KEY) || '';
  }

  public setIsDeleteLabOpen = (open: boolean) => {
    this.isDeleteLabOpen = open;
  };

  public setIsDeleteLabClose = () => {
    this.isDeleteLabOpen = false;
  };

  public setFilteredLabs = (rows: LabType[] | null) => {
    this.filteredLabs = rows;
  };

  public setLabs = (rows: LabType[] | null) => {
    this.labs = rows;
  };

  public setIsLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  public setLabsColumnList = (columns: TableColumnType[]) => {
    this.labsColumnList = columns;
  };

  public setLabsEntireColumns = (columns: TableColumnType[]) => {
    this.labsEntireColumns = columns;
  };

  public setSelectedLab = (lab: LabType) => {
    this.selectedLab = lab;
  };

  public setIsLabRefreshed = (refreshed: boolean) => {
    this.isLabRefreshed = refreshed;
  };

  public setIsDeletingLab = (deleting: boolean) => {
    this.isDeletingLab = deleting;
  };

  public setSearchValue = (value: string) => {
    this.searchValue = value;
    this.localStorage.setValue(AppSettingsService.FILTER_LABS_SEARCH_KEY, value);
  };
}

export default LabListStore;
