import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { ExperimentStatusProgressSteps, Statuses } from '@/constants/AirConstants';
import { ExecutionGoalStatus, Goal } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  status: ExecutionGoalStatus | Goal;
}

export const ExperimentStatusProgress = ({ status }: Props): JSX.Element => {
  let activeStep = 0;

  if (status.status === Statuses.inProgress) {
    activeStep = 2;
  }

  if (status.status === Statuses.succeeded) {
    activeStep = 3;
  }

  return (
    <div className="gbs-status-progress">
      <Stepper alternativeLabel activeStep={activeStep}>
        {ExperimentStatusProgressSteps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
