import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert, { AlertClassKey } from '@material-ui/lab/Alert';

import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { NotificationPositions, NotificationSeverities, NotificationSeverityType } from './utilities';

import '@/styles/Air.css';
import './notifications.css';

const notificationStyles: Partial<Record<AlertClassKey, string>> = {
  message: 'notification',
  action: 'notification',
};

export const NotificationsPool = (): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDebugMode } = appSettingsStore;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [severity, setSeverity] = React.useState<NotificationSeverityType>('success');
  const [position, setPosition] = React.useState<SnackbarOrigin>(NotificationPositions.topRight);

  const showNotification = (
    message = 'Something went wrong',
    severity: NotificationSeverityType = NotificationSeverities.warning,
    position: SnackbarOrigin = NotificationPositions.topRight,
  ) => {
    setPosition(position);
    setMessage(message);
    setSeverity(severity);
    setIsOpen(true);
  };

  React.useEffect(() => {
    isDebugMode && console.log('[NotificationPool] AIR subscribing notifications');
    notificationService.subscribeNotifications(showNotification);

    return () => {
      isDebugMode && console.log('[NotificationPool] AIR unsubscribing notifications');
      notificationService.unsubscribeNotifications(showNotification);
    };
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    setIsOpen(false);
  };

  return (
    <>
      <Snackbar className="notification-pool" anchorOrigin={position} open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert classes={notificationStyles} variant="outlined" severity={severity} onClose={() => setIsOpen(false)}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
