import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';

import { ActionTypeVoid, ActionTypeWithParam } from '@/types/AppSettingsTypes';
import { RenderRowType } from '@/types/TableTypes';

enum AccordionKeys {
  LABS = 'labs',
  MACHINES = 'machines',
}

type ExperimentSuccessReportType = {
  machineId?: string;
  machineName?: string;
  ipAddress?: string;
  labId?: string;
  instanceId: number;
};

type ExperimentFailedReportType = {
  machineId?: string;
  machineName?: string;
  ipAddress?: string;
  labId?: string;
  error: string;
};

type LabsType = {
  LabId: number;
  LabName: string;
  LabStatus: string;
  LastHeartBeat: Date | string;
};

type MachineGroupType = {
  key: string;
  name: string;
  ipAddress: string;
  startIndex: number;
  count: number;
  level: number;
};

type MachineSelectVMType = {
  searchValue: string;
  labs: LabsType[];
  isLabsLoading: boolean;
  machines: MachinesType[];
  machinesGroups: any[];
  isMachinesLoading: boolean;

  experiment: string;
  isExperimentLoading: boolean;
  createdName: string;
  creationMessage: string;
  successfulExperiments: ExperimentSuccessReportType[];
  failedExperiments: ExperimentFailedReportType[];

  setSearchValue: ActionTypeWithParam;
  loadLabs: ActionTypeWithParam;
  loadMachines: ActionTypeWithParam;
  createExperiment: ActionTypeWithParam;
};

type MachineSelectVCType = {
  commandItems: ICommandBarItemProps[];
  buttons: JSX.Element;
  windowState: number; // O for labs, 1 for machines
  labLoading: boolean;
  machineLoading: boolean;
  experimentLoading: boolean;
  accordionOpenItems: AccordionKeys[];

  labs: LabsType[];
  labSelected: boolean;
  selectedLabId: number;
  labExperiment: boolean;
  labsColumns: IColumn[];
  labSelection: Selection;

  machines: MachinesType[];
  selectedMachines: MachinesType[];
  machinesColumns: IColumn[];
  machinesGroupsColumns: IColumn[];
  machinesGroups: any[];
  machinesSelection: Selection;
  createdName: string;
  creationMessage: string;
  successfulExperiments: ExperimentSuccessReportType[];
  failedExperiments: ExperimentFailedReportType[];

  isConfirmModalOpen: boolean;
  maxReportSize: number;

  setWindowState: ActionTypeWithParam;
  createExperiment: ActionTypeWithParam;
  hideConfirmModal: ActionTypeVoid;
  accordionToggle: ActionTypeWithParam;
  labsOnRenderRow: RenderRowType;
};

type MachinesType = {
  Description: string;
  HostSystemId: number;
  LabControllerId: number;
  LabName: string;
  RackId: number;
  SlotNumber: number;
  MachineId: number;
  ModelName: string;
  Name: string;
  IPAddress: string;
  Tags: string;
  MacAddress: string;
  OsImageName: string;
  Status: string;
  LastHeartBeat: Date;
  SocIPAddress: string;
  SocMACAddress: string;
};

type OSImagesType = {
  IsInternal: boolean;
  OsImageId: number;
  OsImageBuild: string;
  OsImageDescription: string;
  OsImageFriendlyName: string;
  OsImageName: string;
};

type RacksType = {
  Data: string;
  FirmwareInfo: string;
  IpAddress: string;
  LabControllerId: number;
  Name: string;
  RackId: number;
  SerialNumber: string;
  machines: MachinesType[];
};

export {
  AccordionKeys,
  ExperimentFailedReportType,
  ExperimentSuccessReportType,
  LabsType,
  MachineGroupType,
  MachineSelectVCType,
  MachineSelectVMType,
  MachinesType,
  OSImagesType,
  RacksType,
};
