import { CancelToken } from 'axios';
import ghSlugger from 'github-slugger';
import { load } from 'js-yaml';

import DocumentationStore from '@/components/Documentation/DocumentationStore';
import { DocumentListType, DocumentType, LinksListType } from '@/components/Documentation/DocumentationTypes';
import { documentationRequestService } from '@/services/request-services/DocumentationRequestService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

const navSlugger: ghSlugger = new ghSlugger();
const idSlugger: ghSlugger = new ghSlugger();

class DocumentationViewModel {
  protected _isDebugMode: boolean;
  protected _sasToken: string;
  protected _endDate: Date;

  protected _setSasToken: ActionTypeWithParam;
  protected _setEndDate: ActionTypeWithParam;

  public url: string;
  public pageKey: string;

  constructor(rootStore: RootStore, url: string, pageKey: string) {
    const { appSettingsStore, documentationStore } = rootStore;
    const { isDebugMode } = appSettingsStore;
    const { sasToken, endDate, setSasToken, setEndDate } = documentationStore;

    this._isDebugMode = isDebugMode;
    this._sasToken = sasToken;
    this._endDate = endDate;

    this._setSasToken = setSasToken;
    this._setEndDate = setEndDate;

    this.url = url;
    this.pageKey = pageKey;
  }

  public getSasToken = async (cancellationToken: CancelToken): Promise<string> => {
    this._isDebugMode &&
      console.log('Debugging getSasToken:', this._sasToken, this._endDate, typeof this._sasToken, typeof this._endDate);

    if (this._sasToken === '' || this._endDate < new Date()) {
      try {
        const token: string = await documentationRequestService.getDocumentationToken(cancellationToken);
        this.setSasToken(token);

        this._isDebugMode && console.log('[DocumentationViewModel] getDocumentationToken() data received.');
      } catch (error) {
        console.error('[DocumentationViewModel] Error calling the Reliability getDocumentationToken() service:', error);
      }
    }

    return this._sasToken;
  };

  public listDocuments = async (url: string, token: string): Promise<DocumentType[]> => {
    try {
      const response: Response = await fetch(url + 'toc.yml' + token);

      this._isDebugMode && console.log(response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const ymlText: string = await response.text();
      const docList: DocumentListType[] = load(ymlText) as DocumentListType[];

      return docList.map((doc: DocumentListType) => ({
        title: doc.name,
        url: url + doc.href,
      }));
    } catch (error) {
      console.error('[DocumentationViewModel] Error fetching or parsing YAML:', error);

      return Promise.reject([]);
    }
  };

  public getDocument = async (url: string, token: string): Promise<string> => {
    try {
      const response: Response = await fetch(url + token);

      this._isDebugMode && console.log(response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.text();
    } catch (error) {
      console.error('[DocumentationViewModel] Error fetching document:', error);

      return Promise.reject('');
    }
  };

  public transformContent = (content: string, baseUrl: string, token: string): string => {
    // Regex to find Markdown image syntax ![alt text](image-path)
    const imgRegex = /!\[([^\]]*)\]\((.*?)\)/g;

    return content.replace(imgRegex, (match: string, altText: string, imagePath: string) => {
      // Construct the absolute URL for the image
      const absoluteUrl = `${baseUrl}${imagePath}${token}`;

      // Replace the relative path with the absolute URL in the Markdown
      return `![${altText}](${absoluteUrl})`;
    });
  };

  public extractLinksFromMarkdown = (markdownContent: string): LinksListType[] => {
    // Define a regex to match Markdown headers (atx-style)
    const headerRegex = /^(#+) (.*)$/gm;
    const links: LinksListType[] = [];
    let match;

    navSlugger.reset();

    // Loop over the content to find headers
    while ((match = headerRegex.exec(markdownContent)) !== null) {
      // Destructure the match result
      const [, hashes, headerText] = match;

      // Generate an ID similar to what rehype-slug would generate
      const id = navSlugger.slug(headerText.trim());
      const level = hashes.length;

      // Push the extracted header and generated ID into the links array
      if (level <= 2) {
        links.push({ id, level, title: headerText });
      }
    }

    return links;
  };

  private setSasToken = (token: string) => {
    this._sasToken = token;

    // Extract the 'se' parameter value which contains the expiry date
    const params: URLSearchParams = new URLSearchParams(token);
    const endDateStr: string = params.get('se');

    if (endDateStr) {
      // Parse the end date string to a Date object
      this.setEndDate(new Date(endDateStr));
    }
  };

  private setEndDate = (endDate: Date) => {
    this._setEndDate(endDate);
  };
}

export default DocumentationViewModel;
