import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DropdownMenuItemType, TextField, Toggle } from '@fluentui/react';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Button, TextArea } from 'semantic-ui-react';

import { Namespaces as NS } from '@/constants/SystemConstants';
import {
  executionGoalsRequestService,
  experimentRequestService,
  experimentResultRequestService,
  experimentStatusRequestService,
  powerBiService,
  providersRequestService,
  settingsRequestService,
  userSettingsRequestService,
} from '@/services/_air/request-services';

import '@/styles/Air.css';
import styles from './AirServices.module.css';

const AirServicesTemplateFC: React.FunctionComponent = () => {
  const [output, setOutput] = React.useState('');
  const [validOutput, setValidOutput] = React.useState(true);

  const [dropdownKey, setDropdownKey] = React.useState('');

  const [payload, setPayload] = React.useState('');
  const [strictvalidate, setStrictvalidate] = React.useState(false);
  const [executionGoalId, setExecutionGoalId] = React.useState('');
  const [experimentName, setExperimentName] = React.useState('');
  const [goalTeamName, setGoalTeamName] = React.useState('');
  const [goalId, setGoalId] = React.useState('');
  const [templateId, setTemplateId] = React.useState('');
  const [teamName, setTeamName] = React.useState('');
  const [profileName, setProfileName] = React.useState('');
  const [platform, setPlatform] = React.useState('');
  const [cpuId, setCpuId] = React.useState('');
  const [cluster, setCluster] = React.useState('');
  const [profileNames, setProfileNames] = React.useState('');
  const [artifact, setArtifact] = React.useState('');
  const [dataArr, setDataArr] = React.useState('');
  const [workloadProfile, setWorkloadProfile] = React.useState('');
  const [workloadName, setWorkloadName] = React.useState('');
  const [revision, setRevision] = React.useState('');
  const [testedInstance, setTestedInstance] = React.useState('');
  const [workloadProfileName, setWorkloadProfileName] = React.useState('');
  const [color, setColor] = React.useState('');
  const [experimentId, setExperimentId] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [userSettings, setUserSettings] = React.useState('');

  const noDataError = 'ERROR: Did not receive data.';

  const runService: any = async () => {
    let value: Promise<any>;

    try {
      switch (dropdownKey) {
        case 'getExecutionGoalFullTemplateList':
          value = executionGoalsRequestService.getExecutionGoalFullTemplateList();
          break;
        case 'getExecutionGoalTemplatesSummary':
          value = executionGoalsRequestService.getExecutionGoalTemplatesSummary();
          break;
        case 'createExperimentFromTemplate':
          value = executionGoalsRequestService.createExperimentFromTemplate(JSON.parse(payload));
          break;
        case 'validateExperimentFromTemplate':
          value = executionGoalsRequestService.validateExperimentFromTemplate(strictvalidate, JSON.parse(payload));
          break;
        case 'editExperimentFromTemplate':
          value = executionGoalsRequestService.editExperimentFromTemplate(JSON.parse(payload), executionGoalId, experimentName);
          break;
        case 'getFullExecutionGoalTemplate':
          value = executionGoalsRequestService.getFullExecutionGoalTemplate(goalTeamName, goalId);
          break;
        case 'getExecutionGoalTemplateParameters':
          value = executionGoalsRequestService.getExecutionGoalTemplateParameters(templateId, teamName);
          break;
        case 'getWorkloadRecommendations':
          value = executionGoalsRequestService.getWorkloadRecommendations(profileName, platform, cpuId);
          break;
        case 'getWorkloadRecommendationByCluster':
          value = executionGoalsRequestService.getWorkloadRecommendationByCluster(profileName, platform, cluster);
          break;
        case 'getWorkloadDescription':
          value = executionGoalsRequestService.getWorkloadDescription(profileNames);
          break;
        case 'validateExperimentName':
          value = executionGoalsRequestService.validateExperimentName(experimentName);
          break;
        case 'getVersions':
          value = executionGoalsRequestService.getVersions(artifact);
          break;
        case 'getListData':
          value = experimentRequestService.getListData(JSON.parse(dataArr));
          break;
        case 'getWorkload':
          value = experimentResultRequestService.getWorkload();
          break;
        case 'getExperimentInfo':
          value = experimentResultRequestService.getExperimentInfo();
          break;
        case 'getWorkloadInfo':
          value = experimentResultRequestService.getWorkloadInfo(
            experimentName,
            workloadProfile,
            workloadName,
            revision,
            testedInstance,
          );
          break;
        case 'getMetricsBySelectedColor':
          value = experimentResultRequestService.getMetricsBySelectedColor(
            experimentName,
            workloadProfileName,
            workloadName,
            color,
            revision,
          );
          break;
        case 'getWorkloadProfileInfo':
          value = experimentResultRequestService.getWorkloadProfileInfo(experimentName, workloadProfile, revision);
          break;
        case 'getStatusOfExecutionGoal':
          value = experimentStatusRequestService.getStatusOfExecutionGoal(teamName, executionGoalId);
          break;
        case 'getDetailsOfExecutionGoal':
          value = experimentStatusRequestService.getDetailsOfExecutionGoal(teamName, executionGoalId);
          break;
        case 'getStatusOfTargetGoals':
          value = experimentStatusRequestService.getStatusOfTargetGoals(teamName, executionGoalId, workloadName);
          break;
        case 'getStatusOfExperimentResources':
          value = experimentStatusRequestService.getStatusOfExperimentResources(experimentId);
          break;
        case 'getFailureAnalysisOfExpeimentInstance':
          value = experimentStatusRequestService.getFailureAnalysisOfExpeimentInstance(experimentId);
          break;
        case 'getFailureAnalysisOfExecutionGoal':
          value = experimentStatusRequestService.getFailureAnalysisOfExecutionGoal(experimentName);
          break;
        case 'getStatusOfExperimentSteps':
          value = experimentStatusRequestService.getStatusOfExperimentSteps(experimentId);
          break;
        case 'getExecutionGoalsList':
          value = experimentStatusRequestService.getExecutionGoalsList(teamName);
          break;
        case 'getPowerBiInfo':
          value = powerBiService.getPowerBiInfo();
          break;
        case 'getProvidersList':
          value = providersRequestService.getProvidersList();
          break;
        case 'getEventHubSettings':
          value = settingsRequestService.getEventHubSettings();
          break;
        case 'getUserSettings':
          value = userSettingsRequestService.getUserSettings(userId);
          break;
        case 'createOrUpdateUserSettings':
          value = userSettingsRequestService.createOrUpdateUserSettings(userId, JSON.parse(userSettings));
          break;
      }

      value
        .then((result) => {
          setOutput(JSON.stringify(result));
          setValidOutput(true);
        })
        .catch((error) => {
          setOutput(error);
          setValidOutput(false);
        });
    } catch (error: any) {
      console.error(error);
      setOutput(noDataError);
      setValidOutput(false);
    }
  };

  const dropdownOptions: IDropdownOption[] = [
    { key: 'executionGoalsHeader', text: 'Execution Goals Requst Service', itemType: DropdownMenuItemType.Header },
    { key: 'getExecutionGoalFullTemplateList', text: 'getExecutionGoalFullTemplateList' },
    { key: 'getExecutionGoalTemplatesSummary', text: 'getExecutionGoalTemplatesSummary' },
    { key: 'createExperimentFromTemplate', text: 'createExperimentFromTemplate' },
    { key: 'validateExperimentFromTemplate', text: 'validateExperimentFromTemplate' },
    { key: 'editExperimentFromTemplate', text: 'editExperimentFromTemplate' },
    { key: 'getFullExecutionGoalTemplate', text: 'getFullExecutionGoalTemplate' },
    { key: 'getExecutionGoalTemplateParameters', text: 'getExecutionGoalTemplateParameters' },
    { key: 'getWorkloadRecommendations', text: 'getWorkloadRecommendations' },
    { key: 'getWorkloadRecommendationByCluster', text: 'getWorkloadRecommendationByCluster' },
    { key: 'getWorkloadDescription', text: 'getWorkloadDescription' },
    { key: 'validateExperimentName', text: 'validateExperimentName' },
    { key: 'getVersions', text: 'getVersions' },

    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'experimentHeader', text: 'Experiment Request Service', itemType: DropdownMenuItemType.Header },
    { key: 'getListData', text: 'getListData' },

    { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'experimentResultHeader', text: 'Experiment Result Requst Service', itemType: DropdownMenuItemType.Header },
    { key: 'getWorkload', text: 'getWorkload' },
    { key: 'getExperimentInfo', text: 'getExperimentInfo' },
    { key: 'getWorkloadInfo', text: 'getWorkloadInfo' },
    { key: 'getMetricsBySelectedColor', text: 'getMetricsBySelectedColor' },
    { key: 'getWorkloadProfileInfo', text: 'getWorkloadProfileInfo' },

    { key: 'divider_3', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'experimentStatusHeader', text: 'Experiment Status Requst Service', itemType: DropdownMenuItemType.Header },
    { key: 'getStatusOfExecutionGoal', text: 'getStatusOfExecutionGoal' },
    { key: 'getDetailsOfExecutionGoal', text: 'getDetailsOfExecutionGoal' },
    { key: 'getStatusOfTargetGoals', text: 'getStatusOfTargetGoals' },
    { key: 'getStatusOfExperimentResources', text: 'getStatusOfExperimentResources' },
    { key: 'getFailureAnalysisOfExpeimentInstance', text: 'getFailureAnalysisOfExpeimentInstance' },
    { key: 'getFailureAnalysisOfExecutionGoal', text: 'getFailureAnalysisOfExecutionGoal' },
    { key: 'getStatusOfExperimentSteps', text: 'getStatusOfExperimentSteps' },
    { key: 'getExecutionGoalsList', text: 'getExecutionGoalsList' },

    { key: 'divider_4', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'powerBIHeader', text: 'Power BI Service', itemType: DropdownMenuItemType.Header },
    { key: 'getPowerBiInfo', text: 'getPowerBiInfo' },

    { key: 'divider_5', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'providersHeader', text: 'Providers Request Service', itemType: DropdownMenuItemType.Header },
    { key: 'getProvidersList', text: 'getProvidersList' },

    { key: 'divider_6', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'settingsHeader', text: 'Settings Request Service', itemType: DropdownMenuItemType.Header },
    { key: 'getEventHubSettings', text: 'getEventHubSettings' },

    { key: 'divider_7', text: '-', itemType: DropdownMenuItemType.Divider },

    { key: 'userSettingsHeader', text: 'User Settings Request Service', itemType: DropdownMenuItemType.Header },
    { key: 'getUserSettings', text: 'getUserSettings' },
    { key: 'createOrUpdateUserSettings', text: 'createOrUpdateUserSettings' },
  ];

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownItems: { maxHeight: 400, overflowY: 'scroll' },
  };

  return (
    <div>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>air-services</Trans>
      </h2>
      <Dropdown
        className={styles['dropdown']}
        label="AIR service method"
        placeholder="Select a method"
        options={dropdownOptions}
        styles={dropdownStyles}
        onChange={(_: any, event: any) => {
          setDropdownKey(event.key);
          setOutput('');
        }}
      />

      <div>
        {dropdownKey == 'createExperimentFromTemplate' && (
          <>
            <TextField
              className={styles['item']}
              label="payload (JSON format)"
              defaultValue={payload}
              onChange={(_: any, event: any) => setPayload(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'validateExperimentFromTemplate' && (
          <>
            <Toggle
              className={styles['item']}
              label="strictvalidate"
              defaultChecked={strictvalidate}
              onChange={(_: any, event: any) => setStrictvalidate(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="payload (JSON format)"
              defaultValue={payload}
              onChange={(_: any, event: any) => setPayload(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'editExperimentFromTemplate' && (
          <>
            <TextField
              className={styles['item']}
              label="payload (JSON format)"
              defaultValue={payload}
              onChange={(_: any, event: any) => setPayload(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="executionGoalId"
              defaultValue={executionGoalId}
              onChange={(_: any, event: any) => setExecutionGoalId(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getFullExecutionGoalTemplate' && (
          <>
            <TextField
              className={styles['item']}
              label="goalTeamName"
              defaultValue={goalTeamName}
              onChange={(_: any, event: any) => setGoalTeamName(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="goalId"
              defaultValue={goalId}
              onChange={(_: any, event: any) => setGoalId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getExecutionGoalTemplateParameters' && (
          <>
            <TextField
              className={styles['item']}
              label="templateId"
              defaultValue={templateId}
              onChange={(_: any, event: any) => setTemplateId(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="teamName"
              defaultValue={teamName}
              onChange={(_: any, event: any) => setTeamName(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getWorkloadRecommendations' && (
          <>
            <TextField
              className={styles['item']}
              label="profileName"
              defaultValue={profileName}
              onChange={(_: any, event: any) => setProfileName(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="platform"
              defaultValue={platform}
              onChange={(_: any, event: any) => setPlatform(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="cpuId"
              defaultValue={cpuId}
              onChange={(_: any, event: any) => setCpuId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getWorkloadRecommendationByCluster' && (
          <>
            <TextField
              className={styles['item']}
              label="profileName"
              defaultValue={profileName}
              onChange={(_: any, event: any) => setProfileName(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="platform"
              defaultValue={platform}
              onChange={(_: any, event: any) => setPlatform(event)}
            />
            <br />
            <TextField
              className={styles['item']}
              label="cluster"
              defaultValue={cluster}
              onChange={(_: any, event: any) => setCluster(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getWorkloadDescription' && (
          <>
            <TextField
              className={styles['item']}
              label="profileNames"
              defaultValue={profileNames}
              onChange={(_: any, event: any) => setProfileNames(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'validateExperimentName' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getVersions' && (
          <>
            <TextField
              className={styles['item']}
              label="artifact"
              defaultValue={artifact}
              onChange={(_: any, event: any) => setArtifact(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getListData' && (
          <>
            <TextField
              className={styles['item']}
              label="dataArr (JSON format)"
              defaultValue={dataArr}
              onChange={(_: any, event: any) => setDataArr(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getWorkloadInfo' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadProfile"
              defaultValue={workloadProfile}
              onChange={(_: any, event: any) => setWorkloadProfile(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadName"
              defaultValue={workloadName}
              onChange={(_: any, event: any) => setWorkloadName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="revision"
              defaultValue={revision}
              onChange={(_: any, event: any) => setRevision(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="testedInstance"
              defaultValue={testedInstance}
              onChange={(_: any, event: any) => setTestedInstance(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getMetricsBySelectedColor' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadProfileName"
              defaultValue={workloadProfileName}
              onChange={(_: any, event: any) => setWorkloadProfileName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadName"
              defaultValue={workloadName}
              onChange={(_: any, event: any) => setWorkloadName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="revision"
              defaultValue={revision}
              onChange={(_: any, event: any) => setRevision(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="color"
              defaultValue={color}
              onChange={(_: any, event: any) => setColor(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getWorkloadProfileInfo' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadProfile"
              defaultValue={workloadProfile}
              onChange={(_: any, event: any) => setWorkloadProfile(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="revision"
              defaultValue={revision}
              onChange={(_: any, event: any) => setRevision(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getStatusOfExecutionGoal' && (
          <>
            <TextField
              className={styles['item']}
              label="teamName"
              defaultValue={teamName}
              onChange={(_: any, event: any) => setTeamName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="executionGoalId"
              defaultValue={executionGoalId}
              onChange={(_: any, event: any) => setExecutionGoalId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getDetailsOfExecutionGoal' && (
          <>
            <TextField
              className={styles['item']}
              label="teamName"
              defaultValue={teamName}
              onChange={(_: any, event: any) => setTeamName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="executionGoalId"
              defaultValue={executionGoalId}
              onChange={(_: any, event: any) => setExecutionGoalId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getStatusOfTargetGoals' && (
          <>
            <TextField
              className={styles['item']}
              label="teamName"
              defaultValue={teamName}
              onChange={(_: any, event: any) => setTeamName(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="executionGoalId"
              defaultValue={executionGoalId}
              onChange={(_: any, event: any) => setExecutionGoalId(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="workloadName"
              defaultValue={workloadName}
              onChange={(_: any, event: any) => setWorkloadName(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getStatusOfExperimentResources' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentId"
              defaultValue={experimentId}
              onChange={(_: any, event: any) => setExperimentId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getFailureAnalysisOfExpeimentInstance' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentId"
              defaultValue={experimentId}
              onChange={(_: any, event: any) => setExperimentId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getFailureAnalysisOfExecutionGoal' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentName"
              defaultValue={experimentName}
              onChange={(_: any, event: any) => setExperimentName(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getStatusOfExperimentSteps' && (
          <>
            <TextField
              className={styles['item']}
              label="experimentId"
              defaultValue={experimentId}
              onChange={(_: any, event: any) => setExperimentId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'getUserSettings' && (
          <>
            <TextField
              className={styles['item']}
              label="userId"
              defaultValue={userId}
              onChange={(_: any, event: any) => setUserId(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        {dropdownKey == 'createOrUpdateUserSettings' && (
          <>
            <TextField
              className={styles['item']}
              label="userId"
              defaultValue={userId}
              onChange={(_: any, event: any) => setUserId(event)}
            />
            <br />

            <TextField
              className={styles['item']}
              label="userSettings (JSON format)"
              defaultValue={userSettings}
              onChange={(_: any, event: any) => setUserSettings(event)}
            />
            <br />
          </>
        )}
      </div>

      <div>
        <Button
          className={styles['item']}
          floated="right"
          primary
          type="submit"
          content={'Run service method'}
          onClick={runService}
        />
      </div>
      <br />

      <div>
        <TextArea
          style={{ minHeight: 300, minWidth: 1000, border: validOutput ? '1px solid black' : '1px solid red' }}
          value={output}
        />
      </div>
    </div>
  );
};

const AirServicesTemplate = observer(AirServicesTemplateFC);

export default AirServicesTemplate;
