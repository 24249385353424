import React from 'react';
import { Link } from 'react-router-dom';

import { Labels, Messages } from '@/constants/AirConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { Parameters, TargetGoalStatus } from '@/types/_air';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import { ExperimentStatusDonutChart } from './ExperimentStatusDonutChart';
import { ExperimentStatusProgress } from './ExperimentStatusProgress';
import { TargetGoalsInstancesTable } from './TargetGoalsInstancesTable';
import { TargetGoalStatusInfo } from './TargetGoalStatusInfo';

import '@/styles/Air.css';

interface TargetGoalStatusParams {
  team: string;
  goalId: string;
  workload: string;
  experimentName: string;
}

export const ExperimentTargetGoalsStatus = (data: any): JSX.Element => {
  const params: TargetGoalStatusParams = data.match.params;
  const { team: teamName, goalId: executionId, experimentName, workload: workloadName } = params;
  const { state } = data.location;
  const [experimentInstances, setExperimentInstances] = React.useState<TargetGoalStatus[]>();
  const [isBusy, setIsBusy] = React.useState<boolean>(true);
  const cancellationToken = useCancellationToken();

  const parameter: Parameters = {
    team: teamName,
    goalId: executionId,
    experimentName,
    workload: workloadName,
  };

  const sp = (state.targetGoal.success * 100) / state.targetGoal.count;

  state.targetGoal.successPercentage = Math.round((sp + Number.EPSILON) * 100) / 100;

  const pp = (state.targetGoal.progress * 100) / state.targetGoal.count;

  state.targetGoal.inProgressPercentage = Math.round((pp + Number.EPSILON) * 100) / 100;

  const fp = (state.targetGoal.failed * 100) / state.targetGoal.count;

  state.targetGoal.failedPercentage = Math.round((fp + Number.EPSILON) * 100) / 100;

  const np =
    ((state.targetGoal.count - state.targetGoal.success - state.targetGoal.failed - state.targetGoal.progress) * 100) /
    state.targetGoal.count;

  state.targetGoal.notStartedPercentage = Math.round((np + Number.EPSILON) * 100) / 100;

  if (state.targetGoal.successPercentage >= 100) {
    state.targetGoal.status = 'Succeeded';
  } else {
    state.targetGoal.status = 'In Progress';
  }

  React.useEffect(() => {
    experimentStatusRequestService
      .getStatusOfTargetGoals(teamName, executionId, workloadName, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) {
          setExperimentInstances(result);
        }
      })
      .catch((error) => {
        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
      })
      .finally(() => setIsBusy(false));
  }, [cancellationToken, executionId, teamName, workloadName]);

  const renderStatus = () =>
    experimentInstances ? (
      <div className="gbs-status-data">
        <ExperimentStatusProgress status={state.targetGoal} />

        <div className="gbs-chart-info-status row">
          <ExperimentStatusDonutChart status={state.targetGoal} />
          <TargetGoalStatusInfo status={state} experimentInstances={experimentInstances} />
        </div>

        <TargetGoalsInstancesTable experimentInstances={experimentInstances} parameter={parameter} targetGoalInfo={state} />
      </div>
    ) : (
      <p className="gbs-status-error">{Messages.noStatusDataAvailable}</p>
    );

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <main className="fullscreen container air-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <h5 className="text-left all-in-one">
            <Link to={{ pathname: Navigation.AIR.MANAGE_EXPERIMENT }} className="breadcrumbs">
              {Labels.experiments}
            </Link>
            {` ${TITLE_DIVIDER}`}
            <Link
              to={{
                pathname: `${Navigation.AIR.SCHEDULER_STATUS}/${teamName}/${executionId}/${experimentName}`,
              }}
              className="breadcrumbs"
            >
              {` ${experimentName} `}
            </Link>
            {` ${TITLE_DIVIDER} ${workloadName} `}
          </h5>
          <PageContent scrollable={true}>
            <div id="wizard-page" className="gbs-status">
              <h3 className="home-title all-in-one">{Labels.targetGoalStatus}</h3>
              {renderStatus()}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
