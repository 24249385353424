import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay-ts';
import { observer } from 'mobx-react-lite';
import { ScrollablePane, Separator } from '@fluentui/react';
import { t } from 'i18next';

import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import DataErrorTemplate from '@/partials/DataError/DataErrorTemplate';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { BreadcrumbsType } from '@/types/BreadcrumbTypes';

import { ErrorsGraphViewController, ErrorsGraphViewModel } from './ErrorsGraph';
import { PageUserFiltersViewController, PageUserFilterViewModel } from './PageUserFilters';
import { ReliabilityVCType } from './ReliabilityType';
import { TimeFilterViewController, TimeFilterViewModel } from './TimeFilter';

import styles from './Reliability.module.css';

const ReliabilityTemplateFC: React.FC<ReliabilityVCType & BreadcrumbsType> = (
  props: ReliabilityVCType & BreadcrumbsType,
): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { reliabilityStore, systemMessageStore } = rootStore;
  const { addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { isGraphLoading, isPagesLoading, isErrorsLoading, isUsersLoading } = reliabilityStore;

  const timeFilterViewModel = new TimeFilterViewModel(rootStore);
  const pageUserViewModel = new PageUserFilterViewModel(rootStore);
  const errorsGraphViewModel = new ErrorsGraphViewModel(rootStore);
  const { breadcrumbs, errorsCountTable, errorsListTable } = props;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
    addHelpMessage('insights-help');
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={''}>
        <Trans ns={NS.TITLES as Namespace}>insights</Trans>
      </PageHeader>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageContent>
            <>
              <TimeFilterViewController viewModel={timeFilterViewModel} />
              <LoadingOverlay active={isUsersLoading || isPagesLoading} spinner>
                <PageUserFiltersViewController viewModel={pageUserViewModel} />
              </LoadingOverlay>

              <div className={styles.insights}>
                <ScrollableContent scrollable={true}>
                  <>
                    <LoadingOverlay active={isErrorsLoading} spinner>
                      <TableViewViewController
                        tableStyle={styles['table-scrollable']}
                        title={errorsCountTable.title}
                        items={errorsCountTable.items}
                        columns={errorsCountTable.columns}
                        displayColumns={errorsCountTable.displayColumns}
                        enableToolBar={false}
                        enableColumnSort={true}
                        isStickyHeader={true}
                      />
                    </LoadingOverlay>

                    <LoadingOverlay active={isGraphLoading} spinner>
                      <ErrorsGraphViewController viewModel={errorsGraphViewModel} />
                    </LoadingOverlay>

                    <h6>
                      <Trans ns={NS.TITLES as Namespace}>top-errors</Trans>
                    </h6>
                    {errorsListTable.items.length > 0 ? (
                      <TableViewViewController
                        tableStyle={styles['table-scrollable']}
                        title={errorsListTable.title}
                        items={errorsListTable.items}
                        columns={errorsListTable.columns}
                        displayColumns={errorsListTable.displayColumns}
                        enableToolBar={false}
                        enableColumnSort={true}
                        isStickyHeader={true}
                      />
                    ) : (
                      <>
                        {!isErrorsLoading && (
                          <>
                            <Separator />
                            <DataErrorTemplate message={t('no-data-error-msg', { ns: NS.ERRORS })} />
                          </>
                        )}
                      </>
                    )}
                  </>
                </ScrollableContent>
              </div>
            </>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ReliabilityTemplate = observer(ReliabilityTemplateFC);

export default ReliabilityTemplate;
