import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { IComboBoxOption, LabDetailsType, OsImageType, Tag, UserRole } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import { DateFormats } from '@/constants/DateFormatConstants';
import { KeyTextPair, SystemConstants } from '@/constants/SystemConstants';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LabGeneralStore {
  protected localStorage: LocalStorageService;
  protected rootStore: RootStore;

  public adminMenu: boolean;
  public companyValue: number;
  public isInternal: boolean;
  public labAdminList: UserRole[] = [];
  public labDetailsData: LabDetailsType = null;
  public labHealthStatus: string;
  public labUserList: UserRole[] = [];
  public lastRefresh: string;
  public osImageList: OsImageType[] = [];
  public osImageValue: string;
  public osImages: KeyTextPair[] = [];
  public selectedTags: IComboBoxOption[] = [];
  public tagOptions: Tag[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      adminMenu: observable,
      companyValue: observable,
      isInternal: observable,
      labAdminList: observable,
      labDetailsData: observable,
      labHealthStatus: observable,
      labUserList: observable,
      lastRefresh: observable,
      osImageList: observable,
      osImageValue: observable,
      osImages: observable,
      selectedTags: observable,
      tagOptions: observable,

      // Actions modify the state.
      setAdminMenu: action,
      setCompanyValue: action,
      setIsInternal: action,
      setLabAdminList: action,
      setLabDetailsData: action,
      setLabHealthStatus: action,
      setLabUserList: action,
      setLastRefresh: action,
      setOsImageList: action,
      setOsImageValue: action,
      setOsImages: action,
      setSelectedTags: action,
      setTagOptions: action,

      // Computed values.
      osImageFilteredList: computed,
    });

    this.localStorage = rootStore.localStorage;
    this.rootStore = rootStore;

    this.companyValue = SystemConstants.MS_COMPANY_ID;
    this.lastRefresh = moment(Date()).format(DateFormats.STANDARD_DATE_TIME);
  }

  public setAdminMenu = (menu: boolean) => {
    this.adminMenu = menu;
  };

  public setCompanyValue = (company?: number) => {
    this.companyValue = company || SystemConstants.MS_COMPANY_ID;
  };

  public setIsInternal = (internal: boolean) => {
    this.isInternal = internal;
  };

  public setLabAdminList = (labAdmin: UserRole[]) => {
    this.labAdminList = labAdmin;
  };

  public setLabDetailsData = (lab: LabDetailsType) => {
    this.labDetailsData = lab;
  };

  public setLabHealthStatus = (health: string) => {
    this.labHealthStatus = health;
  };

  public setLabUserList = (users: UserRole[]) => {
    this.labUserList = users;
  };

  public setLastRefresh = () => {
    this.lastRefresh = moment(Date()).format(DateFormats.STANDARD_DATE_TIME);
  };

  public setOsImageList = (images: OsImageType[]) => {
    this.osImageList = images || [];
  };

  public setOsImageValue = (image?: string) => {
    this.osImageValue = image || '';
  };

  public setOsImages = (images: KeyTextPair[]) => {
    this.osImages = images || [];
  };

  public setSelectedTags = (tags: IComboBoxOption[]) => {
    this.selectedTags = tags;
  };

  public setTagOptions = (options: Tag[]) => {
    this.tagOptions = options;
  };

  get osImageFilteredList(): OsImageType[] {
    return this.isInternal ? this.osImageList : this.osImageList.filter((osImage) => !osImage.isInternal);
  }
}

export default LabGeneralStore;
