/*----------------------------------------------------------------------------------------------------------------------------
# Playground.tsx: 
# This Component is our Playground Component. 
# This is a component used for testing and is one of our React playgrounds.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DefaultButton, DetailsRow, IDetailsRowProps, MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';
import { Button } from 'semantic-ui-react';

import AirServicesTemplate from '@/components/_air/AirServices/AirServicesTemplate';
import LabsServicesTemplate from '@/components/_labs/LabsServices/LabsServicesTemplate';
import CosmosViewController from '@/components/Cosmos/CosmosViewController';
import CosmosViewModel from '@/components/Cosmos/CosmosViewModel';
import UseGraphViewController from '@/components/UseGraph/UseGraphViewController';
import UseGraphViewModel from '@/components/UseGraph/UseGraphViewModel';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import ADOQueriesTemplate from '@/partials/ADOQueries/ADOQueriesTemplate';
import Breadcrumbs, { BreadcrumbsService } from '@/partials/Breadcrumbs/BreadcrumbsTemplate';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import MessageBarTesterViewController from '@/partials/MessageBarTester/MessageBarTesterViewController';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import GanymedeServicesTemplate from '@/partials/ServiceTesting/GanymedeServicesTemplate';
import SplitPaneTester from '@/partials/SplitPaneTester/SplitPaneTester';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import loadData from '@/scripts/ReadInitialData';
import { authRequestService } from '@/services/_air/request-services';
import { playgroundService } from '@/services/request-services/PlaygroundService';
import { userService } from '@/services/request-services/UserServices';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { MockTableData, TableDataType } from '@/mocks/Table/TableData';

import styles from '@/pages/Playground/Playground.module.css'; // Apply any top-level Playground CSS Module styling here.

const PlaygroundFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { isDeveloperMode } = appSettingsStore;
  const { addGlobalMessage, globalMessages } = systemMessageStore;

  const title = t(Navigation.GANYMEDE.PLAYGROUND, { ns: NS.NAVIGATION });

  const graphViewModel = new UseGraphViewModel(rootStore);
  const cosmosViewModel = new CosmosViewModel(rootStore);
  const items: IBreadcrumbItem[] = [BreadcrumbsService.quickItem(Navigation.GANYMEDE.PLAYGROUND, BreadcrumbsService.IS_TITLE_ITEM)];
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const [tableData, setTableData] = React.useState<TableDataType>({
    table1: { title: '', items: [], columns: [], displayColumns: [] },
    table2: { title: '', items: [], columns: [] },
  });

  const tableDataSuccess = 'table-data-success';
  const tableDataFail = 'table-data-fail';
  const successGroupId = 'cosmos-initial-success';
  const failGroupId = 'cosmos-initial-fail';
  const namespace = NS.COSMOS;

  const [CSRFToken, setCSRFToken] = React.useState<string>('No CSRFToken');
  const [errorMessage, setErrorMessage] = React.useState<any>('No Error Message');
  const { clearNonPersistentGlobalMessages } = systemMessageStore;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const TestCSRFTokenRetrieval: any = async () => {
    const result = await authRequestService.getXSRFToken();
    setCSRFToken(JSON.stringify(result));
  };

  const TestPostLink: any = async () => {
    userService.postLink('123');
  };

  const Get404Error: any = async () => {
    const result = await playgroundService.get404();
    setErrorMessage(result);
  };

  React.useEffect(() => {
    loadData()
      .then((result: any) => {
        setTableData(JSON.parse(result).MOCK_TABLE_DATA);

        const successMessage: SystemMessageType = {
          message: tableDataSuccess,
          type: FluentMessageBarType.success,
          groupId: successGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(successMessage);
          console.log(t(tableDataSuccess, { ns: namespace }));
        }
      })
      .catch(() => {
        setTableData(MockTableData);

        const failMessage: SystemMessageType = {
          message: tableDataFail,
          type: FluentMessageBarType.error,
          groupId: failGroupId,
          namespace,
        };

        if (isDeveloperMode) {
          addGlobalMessage(failMessage);
          console.log(t(tableDataFail, { ns: namespace }));
        }
      });
  }, []);

  // Custom render function for the table component
  const onRenderRow = (rowProps: typeof IDetailsRowProps | undefined): JSX.Element | null => {
    if (rowProps) {
      const item = rowProps.item;
      const value = item.value;

      return (
        <div onClick={() => alert(`${value}`)}>
          <DetailsRow {...rowProps} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="fullscreen">
      <Breadcrumbs items={items} />
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.PLAYGROUND]}>{title}</PageHeader>
          <PageContent scrollable={true}>
            <div>
              <Accordion collapsible={true} multiple={true}>
                <AccordionItem value="MoveableDivider">
                  <AccordionHeader size="extra-large">Moveable Divider Component</AccordionHeader>
                  <AccordionPanel>
                    <SplitPaneTester></SplitPaneTester>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="GanymedeServices">
                  <AccordionHeader size="extra-large">Ganymede API Services Tester</AccordionHeader>
                  <AccordionPanel>
                    <GanymedeServicesTemplate />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="LabsServices">
                  <AccordionHeader size="extra-large">Labs Services Tester</AccordionHeader>
                  <AccordionPanel>
                    <LabsServicesTemplate />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="AirServices">
                  <AccordionHeader size="extra-large">AIR Services Tester</AccordionHeader>
                  <AccordionPanel>
                    <AirServicesTemplate />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="ADOQueries">
                  <AccordionHeader size="extra-large">ADO Queries Tester</AccordionHeader>
                  <AccordionPanel>
                    <ADOQueriesTemplate />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="CosmosDB">
                  <AccordionHeader size="extra-large">Cosmos DB Test Tool</AccordionHeader>
                  <AccordionPanel>
                    <CosmosViewController viewModel={cosmosViewModel} />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="GraphView">
                  <AccordionHeader size="extra-large">GraphView Controller</AccordionHeader>
                  <AccordionPanel>
                    <UseGraphViewController viewModel={graphViewModel} />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="MessageBar">
                  <AccordionHeader size="extra-large">MessageBar Tester</AccordionHeader>
                  <AccordionPanel>
                    <MessageBarTesterViewController />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="Modals">
                  <AccordionHeader size="extra-large">Modal Dialogs</AccordionHeader>
                  <AccordionPanel>
                    <DefaultButton text="Open Modal" onClick={toggleHideDialog} />
                    <DialogBox
                      title={'Sample Modal window'}
                      subtext={'Sample subtext'}
                      minWidth={'50%'}
                      body={
                        <div className={styles['modal-body']}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Blandit libero volutpat sed cras ornare. Tincidunt vitae semper quis lectus.
                          Scelerisque fermentum dui faucibus in. Id eu nisl nunc mi ipsum faucibus. Turpis massa tincidunt dui ut
                          ornare lectus sit amet. Nec ullamcorper sit amet risus nullam eget felis eget nunc. Interdum velit euismod
                          in pellentesque massa. At tempor commodo ullamcorper a lacus vestibulum sed. Euismod nisi porta lorem
                          mollis aliquam ut. Amet risus nullam eget felis eget nunc lobortis mattis.
                        </div>
                      }
                      footer={<DefaultButton onClick={toggleHideDialog} text={t('close', { ns: NS.COMMON })} />}
                      hidden={hideDialog}
                      modalProps={{
                        isBlocking: true,
                      }}
                      onDismiss={toggleHideDialog}
                    />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="TableView1">
                  <AccordionHeader size="extra-large">Tables with Data</AccordionHeader>
                  <AccordionPanel>
                    <TableViewViewController
                      autoTranslations={false}
                      title={'Table Example'}
                      items={tableData.table2.items}
                      columns={tableData.table2.columns}
                    />
                    <TableViewViewController
                      autoTranslations={false}
                      title={tableData.table1.title}
                      items={tableData.table1.items}
                      columns={tableData.table1.columns}
                      displayColumns={tableData.table1.displayColumns}
                      onRenderRow={onRenderRow}
                    />
                    <TableViewViewController
                      autoTranslations={false}
                      title={tableData.table2.title}
                      items={tableData.table2.items}
                      columns={tableData.table2.columns}
                    />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="Routing">
                  <AccordionHeader size="extra-large">Routing Tests</AccordionHeader>
                  <AccordionPanel>
                    <ul>
                      <li>
                        <Link to="/link-to-page-that-does-not-exist">Click here to go to a page that does not exist</Link> - 404
                        page in Root.
                      </li>
                      <li>
                        <Link to="/about"> Click to visit the hidden About page</Link> - regular About page in Root.
                      </li>
                      <li>
                        <Link to="/air">Click to visit the AIR Home Page</Link> - regular AIR landing page.
                      </li>
                      <li>
                        <Link to="/air/path-that-does-not-exist">Click to visit the AIR page that does not exist</Link> - 404 page
                        under the AIR landing page.
                      </li>
                      <li>
                        <Link to="/labs">Click to visit the Labs Home Page</Link> - regular Labs landing page.
                      </li>
                      <li>
                        <Link to="/labs/path-that-does-not-exist">Click to visit the Labs page that does not exist</Link> - 404 page
                        under the Labs landing page.
                      </li>
                      <li>
                        <Link to="/test/path-that-does-not-exist">
                          Click to visit the page that does not exist under a non-existing folder
                        </Link>{' '}
                        - 404 page under the Test folder.
                      </li>
                    </ul>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="CSRF tests">
                  <AccordionHeader size="extra-large">CSRF tests</AccordionHeader>
                  <AccordionPanel>
                    <Button
                      className={styles['item']}
                      floated="right"
                      primary
                      type="submit"
                      content={'Test Empty Link Post'}
                      onClick={TestPostLink}
                    ></Button>
                    <Button
                      className={styles['item']}
                      floated="right"
                      primary
                      type="submit"
                      content={'Test Getting CSRF Token'}
                      onClick={TestCSRFTokenRetrieval}
                    ></Button>
                    <p> {CSRFToken} </p>
                    <br />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="Failure Log tests">
                  <AccordionHeader size="extra-large">Failure Logging Tests</AccordionHeader>
                  <AccordionPanel>
                    <Button
                      className={styles['item']}
                      floated="right"
                      primary
                      type="submit"
                      content={'Get error 404'}
                      onClick={Get404Error}
                    ></Button>
                    <p> {errorMessage} </p>
                    <br />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const Playground = observer(PlaygroundFC);

export default Playground;
