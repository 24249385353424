// URI of the schema that the JSON will be validated against+
import ExecutionGoal from '@/components/ExperimentEditor/ExecutionGoal.schema.json';
import Experiment from '@/components/ExperimentEditor/Experiment.json';
import { ExperimentEditorConstants } from '@/constants/ExperimentEditorConstants';

const schemaURI = ExperimentEditorConstants.SCHEMA_URI;

const monacoSettings = {
  airDiagnosticOptions: {
    validate: true,
    allowComments: true,
    schemas: [
      {
        uri: schemaURI,
        fileMatch: ['*.json'],
        schema: ExecutionGoal,
      },
    ],
  },
  labsDiagnosticOptions: {
    validate: true,
    allowComments: true,
    schemas: [
      {
        uri: schemaURI,
        fileMatch: ['*.json'],
        schema: Experiment,
      },
    ],
  },
};

export default monacoSettings;
