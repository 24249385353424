/*----------------------------------------------------------------------------------------------------------------------------
# Dashboards.tsx: 
# This Component is our Dashboards Component. 
# This is the top-most Component when the user visits the "/dashboards" route.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { observer } from 'mobx-react-lite';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { useBoolean } from '@fluentui/react-hooks';

import ReactGridViewController from '@/components/ReactGrid/ReactGridViewController';
import ReactGridViewModel from '@/components/ReactGrid/ReactGridViewModel';
import { Navigation } from '@/constants/NavigationConstants';
import Breadcrumbs, { BreadcrumbsService } from '@/partials/Breadcrumbs/BreadcrumbsTemplate';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import WidgetCommandBar from '@/partials/WidgetCommandBar/WidgetCommandBarTemplate';
import WidgetPanel from '@/partials/WidgetPanel/WidgetPanelTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { MockDashboardData as DemoData } from '@/mocks/Dashboards/DashboardData';

import './Dashboards.css'; // NOTE: To be phased out.

const DashboardsFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const dashboardsViewerViewModel = new ReactGridViewModel(rootStore);
  const { isEditMode, doReset, doDashboardReset, toggleEditMode } = appSettingsStore;
  const { addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const rootItem = BreadcrumbsService.quickItem(Navigation.GANYMEDE.DASHBOARDS, BreadcrumbsService.IS_BREADCRUMB_LINK);
  const currentItem = BreadcrumbsService.quickItem(Navigation.GANYMEDE.DASHBOARDS_MYDASHBOARD, BreadcrumbsService.IS_TITLE_ITEM);
  const items: IBreadcrumbItem[] = [rootItem, currentItem];
  const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
    addHelpMessage('dashboards-help');
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className={'fullscreen container'}>
      <Breadcrumbs items={items} />
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <WidgetCommandBar
        doDashboardReset={doDashboardReset}
        doReset={doReset}
        hidePanel={hidePanel}
        isEditMode={isEditMode}
        isPanelOpen={isPanelOpen}
        showPanel={showPanel}
        toggleEditMode={toggleEditMode}
      ></WidgetCommandBar>
      <ReactGridViewController viewModel={dashboardsViewerViewModel} data={DemoData} />
      <WidgetPanel
        hidePanel={hidePanel}
        isEditMode={isEditMode}
        isPanelOpen={isPanelOpen}
        showPanel={showPanel}
        toggleEditMode={toggleEditMode}
      ></WidgetPanel>
    </div>
  );
};

const Dashboards = observer(DashboardsFC);

export default Dashboards;
