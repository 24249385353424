import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ls from 'local-storage';
import { container } from 'tsyringe';

import { RootStore } from '@/stores/RootStore';
import { resultsExplorerEndpoint } from '@/utils/Env';

const rootStore: RootStore = container.resolve(RootStore);
const { appSettingsStore } = rootStore;
const { isDebugMode } = appSettingsStore;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: resultsExplorerEndpoint,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Clear-Site-Data': '*',
  },
});

class ResultRequestService {
  httpClient: AxiosInstance;

  baseURL: string;

  constructor(baseURL = '') {
    this.httpClient = axiosInstance;
    this.baseURL = baseURL;
  }

  async get<TParams = any, TResponse = any>(
    url = '',
    params?: TParams,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    this.httpClient.defaults.headers['Authorization'] = `Bearer ${(ls as any).get('resultsToken')}`;

    return this.httpClient
      .get(`${this.baseURL}${url ? '/' : ''}${url}`, {
        params,
        ...requestConfig,
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          // adding details to get further information
          isDebugMode &&
            console.log(`GET request failed with code ${error.response.status}, Details: ${JSON.stringify(error.response)}`);
        }

        throw error;
      });
  }

  async post<TData = any, TResponse = any>(
    url = '',
    data?: TData,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    this.httpClient.defaults.headers['Authorization'] = `Bearer ${(ls as any).get('resultsToken')}`;

    return this.httpClient.post(`${this.baseURL}${url ? '/' : ''}${url}`, data, requestConfig).catch((error: AxiosError) => {
      if (error.response) {
        isDebugMode &&
          console.log(`POST request failed with code ${error.response.status}, Details: ${JSON.stringify(error.response)}`);
      }

      throw error;
    });
  }

  async put<TData = any, TResponse = any>(
    url = '',
    data?: TData,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    this.httpClient.defaults.headers['Authorization'] = `Bearer ${(ls as any).get('resultsToken')}`;

    return this.httpClient.put(`${this.baseURL}${url ? '/' : ''}${url}`, data, requestConfig).catch((error: AxiosError) => {
      if (error.response) {
        isDebugMode &&
          console.log(`PUT request failed with code ${error.response.status}, Details: ${JSON.stringify(error.response)}`);
      }

      throw error;
    });
  }

  async delete<TParams = any, TResponse = any>(
    url = '',
    params?: TParams,
    requestConfig?: AxiosRequestConfig,
  ): Promise<AxiosResponse<TResponse>> {
    this.httpClient.defaults.headers['Authorization'] = `Bearer ${(ls as any).get('resultsToken')}`;

    return this.httpClient
      .delete(`${this.baseURL}${url ? '/' : ''}${url}`, {
        params,
        ...requestConfig,
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          isDebugMode &&
            console.log(`DELETE request failed with code ${error.response.status}, Details: ${JSON.stringify(error.response)}`);
        }

        throw error;
      });
  }
}

export default ResultRequestService;
