import { action, makeObservable, observable } from 'mobx';

import { PaginationType } from '@/partials/Pagination/PaginationTypes';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class PaginationStore {
  protected rootStore: RootStore;

  public paginationType: PaginationType;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      paginationType: observable,

      // Actions modify the state.
      setPaginationType: action,
    });

    this.rootStore = rootStore;
  }

  public setPaginationType = (pagination: PaginationType) => {
    this.paginationType = pagination;
  };
}

export default PaginationStore;
