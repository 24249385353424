import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import { DataType } from '@/constants/SystemConstants';
import BaseRequestService from '@/services/request-services/RequestService';
import { APIErrorInterface, ExperimentInfo, MetricsBySelectedColor, Workload, WorkloadProfile } from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class ExperimentResultRequestService extends BaseRequestService {
  async getWorkload(cancelToken?: CancelToken): Promise<Workload[] | APIErrorInterface> {
    const { data } = await this.get('ExperimentResult/GetWorkloadNames', null, { cancelToken });

    return data;
  }

  async getExperimentInfo(cancelToken?: CancelToken): Promise<ExperimentInfo[] | APIErrorInterface> {
    const { data } = await this.get('ExperimentResult/GetExperimentInfo', null, { cancelToken });

    return data;
  }

  async getWorkloadInfo(
    experimentName: string | string[],
    workloadProfile: string | string[],
    workloadName: string | string[],
    revision: string | string[],
    testedInstance: string | string[],
    cancelToken?: CancelToken,
  ): Promise<MetricsBySelectedColor[] | APIErrorInterface | undefined> {
    if (
      typeof experimentName === DataType.STRING &&
      typeof workloadName === DataType.STRING &&
      typeof workloadProfile === DataType.STRING &&
      typeof testedInstance === DataType.STRING &&
      typeof revision === DataType.STRING
    ) {
      const { data } = await this.get(
        `ExperimentResult/GetWorkloadInfo/experimentName=${experimentName}/workloadProfile=${workloadProfile}/workloadName=${workloadName}/revision=${revision}/testedInstance=${testedInstance}`,
        null,
        { cancelToken },
      );

      return data;
    }
  }

  async getMetricsBySelectedColor(
    experimentName: string | string[],
    workloadProfileName: string | string[],
    workloadName: string | string[],
    color: string | string[],
    revision: string | string[],
    cancelToken?: CancelToken,
  ): Promise<MetricsBySelectedColor[] | APIErrorInterface | undefined> {
    if (
      typeof experimentName === DataType.STRING &&
      typeof workloadName === DataType.STRING &&
      typeof workloadProfileName === DataType.STRING &&
      typeof color === DataType.STRING &&
      typeof revision === DataType.STRING
    ) {
      const { data } = await this.get(
        `ExperimentResult/GetMetricsBySelectedColor/experimentName=${experimentName}/workloadProfileName=${workloadProfileName}/workloadName=${workloadName}/color=${color}/revision=${revision}`,
        null,
        { cancelToken },
      );

      return data;
    }
  }

  async getWorkloadProfileInfo(
    experimentName: string | string[],
    workloadProfile: string | string[],
    revision: string | string[],
    cancelToken?: CancelToken,
  ): Promise<WorkloadProfile | APIErrorInterface> {
    const { data } = await this.get(
      `ExperimentResult/GetWorkloadProfileInfo/experimentName=${experimentName}/workloadProfile=${workloadProfile}/revision=${revision}`,
      null,
      { cancelToken },
    );

    return data;
  }
}

export const experimentResultRequestService = new ExperimentResultRequestService(backendEndpoint);
