import { SnackbarOrigin } from '@material-ui/core/Snackbar';

import { NotificationSeverities, NotificationSeverityType } from '@/utils/_labs/Notifications';

type ShowNotificationType =
  // eslint-disable-next-line no-unused-vars
  ((message: string, severity: NotificationSeverityType, position: SnackbarOrigin) => void) | null;

class NotificationService {
  showNotification: ShowNotificationType = null;

  subscribeNotifications(showNotificationMethod: ShowNotificationType) {
    this.showNotification = showNotificationMethod;
  }

  unsubscribeNotifications(showNotificationMethod: ShowNotificationType) {
    if (this.showNotification !== showNotificationMethod) return;

    this.showNotification = null;
  }

  emitNotification(message: string, severity: NotificationSeverityType, position: SnackbarOrigin) {
    if (!this.showNotification) throw new Error('The notification pool is not subscribed');

    this.showNotification(message, severity, position);
  }

  showSuccess(message: string, position?: SnackbarOrigin) {
    if (position) {
      this.emitNotification(message, NotificationSeverities.success, position);
    } else {
      const pos: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
      };
      this.emitNotification(message, NotificationSeverities.success, pos);
    }
  }

  showInfo(message: string, position?: SnackbarOrigin) {
    if (position) {
      this.emitNotification(message, NotificationSeverities.info, position);
    } else {
      const pos: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
      };
      this.emitNotification(message, NotificationSeverities.info, pos);
    }
  }

  showWarning(message: string, position?: SnackbarOrigin) {
    if (position) {
      this.emitNotification(message, NotificationSeverities.warning, position);
    } else {
      const pos: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
      };
      this.emitNotification(message, NotificationSeverities.warning, pos);
    }
  }

  showError(message: string, position?: SnackbarOrigin) {
    if (position) {
      this.emitNotification(message, NotificationSeverities.error, position);
    } else {
      const pos: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
      };

      this.emitNotification(message, NotificationSeverities.error, pos);
    }
  }
}

export const notificationService = new NotificationService();
