import React from 'react';
import { observer } from 'mobx-react-lite';

import { scrollableContainer, scrollThrottleTime } from '@/constants/ScrollablePageConstants';
import { ScrollableContentType } from '@/partials/ScrollableContent/ScrollableContentTypes';

import styles from './ScrollableContent.module.css'; // Apply any Navigation level CSS Module styling here.

const ScrollableContentFC: React.FC<ScrollableContentType> = (props: ScrollableContentType): React.ReactElement => {
  const { children, scrollable, scrollableX, handleScroll } = props;

  const scrollableClass = scrollable ? ` ${styles['scrollable']}` : '';
  const scrollableXClass = scrollableX ? ` ${styles['scrollable-x']}` : '';

  const throttle = (callback, timeFrame) => {
    let lastTime = 0;

    return (...args) => {
      const now = new Date().getMilliseconds();

      if (now - lastTime >= timeFrame) {
        callback(...args);
        lastTime = now;
      }
    };
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    throttle(handleScroll, scrollThrottleTime)(e);
  };

  return (
    <div
      className={`${styles['content']} ${scrollableClass} ${scrollableXClass}`}
      id={scrollableContainer}
      onScroll={handleScroll ? onScroll : undefined}
    >
      {children}
    </div>
  );
};

const ScrollableContent = observer(ScrollableContentFC);

export default ScrollableContent;
