import { RootStore } from '@/stores/RootStore';

import graphData from '@/mocks/Graph/GraphData.json';

import { ChartData, GetListType, SetListType } from './UseGraphType';

class UseGraphViewModel {
  protected _getGraphData: GetListType;
  protected _setGraphData: SetListType;

  constructor(rootStore: RootStore) {
    const { useGraphStore } = rootStore;
    const { setGraphData, getGraphData } = useGraphStore;

    this._getGraphData = getGraphData;
    this._setGraphData = setGraphData;
  }

  public get graphData(): Array<ChartData> {
    return this._getGraphData();
  }

  public setGraphData = (): void => {
    return this._setGraphData(graphData);
  };
}

export default UseGraphViewModel;
