import React from 'react';
import { IDropdownOption, IDropdownProps } from '@fluentui/react/lib/Dropdown';
import { t } from 'i18next';

import DropdownTemplate from './DropdownTemplate';
import { DropdownState, DropdownType } from './DropdownTypes';

class DropdownViewController extends React.Component<DropdownType, DropdownState> {
  constructor(props: DropdownType) {
    super(props);
    this.state = {
      selectedMultiOptionKeys: [],
      selectedSingleOption: { key: '', text: '' },
    };

    this.state = {
      selectedMultiOptionKeys: props.selectedMultiKeys ?? [],
      selectedSingleOption: props.selectedKey ?? { key: '', text: '' },
    };
  }

  componentDidUpdate(
    prevProps: Readonly<DropdownType> /* eslint-disable-line @typescript-eslint/no-unused-vars */,
    prevState: Readonly<DropdownState>,
    snapshot?: any /* eslint-disable-line @typescript-eslint/no-unused-vars */,
  ): void {
    if (this.props.selectedMultiKeys && prevState.selectedMultiOptionKeys !== this.props.selectedMultiKeys) {
      this.setState({
        selectedMultiOptionKeys: this.props.selectedMultiKeys ?? [],
      });
      return;
    }

    if (prevState.selectedSingleOption?.key !== this.props.selectedKey?.key) {
      this.setState({
        selectedSingleOption: this.props.selectedKey,
      });

      return;
    }
  }

  onMultiChange = (
    event: React.FormEvent<HTMLDivElement> /* eslint-disable-line @typescript-eslint/no-unused-vars */,
    item: IDropdownOption | undefined,
  ): void => {
    if (item) {
      const { selectedMultiOptionKeys } = this.state;
      const selectedKeyList: string[] = item.selected
        ? [...selectedMultiOptionKeys, item.key as string]
        : selectedMultiOptionKeys.filter((key) => key !== item.key);

      this.setState({
        selectedMultiOptionKeys: selectedKeyList,
      });
      const { callBack } = this.props;

      if (callBack) {
        callBack(selectedKeyList);
      }
    }
  };

  onSingleChange = (
    event: React.FormEvent<HTMLDivElement> /* eslint-disable-line @typescript-eslint/no-unused-vars */,
    item: IDropdownOption | undefined,
  ): void => {
    const { selectedSingleOption } = this.state;
    let selectedItem = item;

    if (selectedSingleOption?.key == item?.key) {
      selectedItem = { key: '', text: '' };
    }

    this.setState({
      selectedSingleOption: selectedItem,
    });
    const { callBack } = this.props;

    if (callBack) {
      callBack(selectedItem);
    }
  };

  onRenderOption = (option?: IDropdownOption): JSX.Element => {
    if (option) {
      const { translationNamespace } = this.props;
      const text = translationNamespace ? t(option.text, { ns: translationNamespace || '' }) : option.text;

      return <div>{text}</div>;
    }

    return <></>;
  };

  render() {
    const { selectedMultiOptionKeys, selectedSingleOption } = this.state;
    const { multi, dropdownProps } = this.props;

    if (multi) {
      const multiProps: IDropdownProps = {
        ...dropdownProps,
        multiSelect: true,
        selectedKeys: selectedMultiOptionKeys,
        onChange: this.onMultiChange,
        onRenderOption: this.onRenderOption,
      };

      return <DropdownTemplate {...multiProps} />;
    }

    const singleProps: IDropdownProps = {
      ...dropdownProps,
      selectedKey: selectedSingleOption ? selectedSingleOption.key : undefined,
      onChange: this.onSingleChange,
      onRenderOption: this.onRenderOption,
    };

    return <DropdownTemplate {...singleProps} />;
  }
}

export default DropdownViewController;
