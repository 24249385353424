import { HubNotification, NotificationType } from '@/types/_air/HubNotification';

type Subscription = (_notifications: HubNotification[], _isNewNotifications: boolean) => void;

class NotificationHubService {
  private isNewNotifications: boolean;

  private notifications: HubNotification[];

  private subscriptions: Subscription[];

  constructor() {
    this.isNewNotifications = false;
    this.notifications = [];
    this.subscriptions = [];
  }

  public resetNotifications = (): void => {
    this.notifications = [];
    this.notify();
  };

  public deleteNotification = (id: string): void => {
    this.notifications = this.notifications.filter((notification) => notification.id !== id);
    this.notify();
  };

  public resetNewNotificationsNumber = (): void => {
    this.isNewNotifications = false;
    this.notify();
  };

  public subscribe = (callback: Subscription): void => {
    this.subscriptions.push(callback);
  };

  public unsubscribe = (callback: Subscription): void => {
    this.subscriptions = this.subscriptions.filter((cb) => cb !== callback);
  };

  public addNotification = (type: NotificationType, title: string, description?: string): void => {
    this.isNewNotifications = true;

    const id = Date.now().toString(16);

    this.notifications.unshift({
      id,
      title,
      description: description ?? title,
      type,
    });

    this.notify();
  };

  private notify = (): void => {
    this.subscriptions.forEach((sub) => sub(this.notifications, this.isNewNotifications));
  };
}

export const notificationHubService = new NotificationHubService();
