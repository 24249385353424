import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import {
  APIErrorInterface,
  ExecutionFailureAnalysis,
  ExecutionGoalsList,
  ExecutionGoalStatus,
  ExperimentInstanceFailureAnaylsis,
  ExperimentResources,
  ExperimentSteps,
  TargetGoalStatus,
} from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class ExperimentStatusRequestService extends BaseRequestService {
  async getStatusOfExecutionGoal(
    teamName: string,
    executionGoalId: string,
    cancelToken?: CancelToken,
  ): Promise<ExecutionGoalStatus | APIErrorInterface> {
    const { data } = await this.get(
      `experiment-status/GetStatusOfExecutionGoal/teamName=${teamName}&executionGoalId=${executionGoalId}`,
      null,
      {
        cancelToken,
      },
    );

    return data;
  }

  async getDetailsOfExecutionGoal(teamName: string, executionGoalId: string, cancelToken?: CancelToken): Promise<any> {
    const { data } = await this.get(
      `experiment-status/GetExecutionGoalDetail/teamName=${teamName}&executionGoalId=${executionGoalId}`,
      null,
      {
        cancelToken,
      },
    );

    return data;
  }

  async getStatusOfTargetGoals(
    teamName: string,
    executionGoalId: string,
    workloadName: string,
    cancelToken?: CancelToken,
  ): Promise<TargetGoalStatus[] | APIErrorInterface> {
    const { data } = await this.get(
      `experiment-status/GetStatusOfTargetGoals/teamName=${teamName}&executionGoalId=${executionGoalId}&workload=${workloadName}`,
      null,
      { cancelToken },
    );

    return data;
  }

  async getStatusOfExperimentResources(
    experimentId: string,
    cancelToken?: CancelToken,
  ): Promise<ExperimentResources[] | APIErrorInterface> {
    const { data } = await this.get(`experiment-status/GetStatusOfExperimentResources/experimentId=${experimentId}`, null, {
      cancelToken,
    });

    return data;
  }

  async getFailureAnalysisOfExpeimentInstance(
    experimentId: string,
    cancelToken?: CancelToken,
  ): Promise<ExperimentInstanceFailureAnaylsis[] | APIErrorInterface> {
    const { data } = await this.get(`experiment-status/GetFailureAnalysisForExperimentId/experimentId=${experimentId}`, null, {
      cancelToken,
    });

    return data;
  }

  async getFailureAnalysisOfExecutionGoal(
    experimentName: string,
    cancelToken?: CancelToken,
  ): Promise<ExecutionFailureAnalysis[] | APIErrorInterface> {
    const { data } = await this.get(`experiment-status/GetFailureAnalysisForExperiment/experimentName=${experimentName}`, null, {
      cancelToken,
    });

    return data;
  }

  async getStatusOfExperimentSteps(
    experimentId: string,
    cancelToken?: CancelToken,
  ): Promise<ExperimentSteps[] | APIErrorInterface> {
    const { data } = await this.get(`experiment-status/GetStatusOfExperimentSteps/id=${experimentId}`, null, {
      cancelToken,
    });

    return data;
  }

  async getExecutionGoalsList(teamName: string, cancelToken?: CancelToken): Promise<ExecutionGoalsList[] | APIErrorInterface> {
    const { data } = await this.get(`experiment-status/GetExecutionGoalsList/teamName=${teamName}/summary`, null, { cancelToken });

    return data;
  }
}

export const experimentStatusRequestService = new ExperimentStatusRequestService(backendEndpoint);
