import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon } from '@fluentui/react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { Badge } from '@fluentui/react-components';

import SessionPanelStyles from '@/components/SessionPanel/SessionPanelStyles';
import { SystemIcons } from '@/constants/IconConstants';
import { Units } from '@/constants/SystemConstants';
import { SidePanelUserSettingsType } from '@/partials/SidePanel/SidePanelTypes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SessionPanelTemplate from './SessionPanelTemplate';
import { SessionPanelVMType } from './SessionPanelTypes';

import styles from './SessionPanel.module.css';
import sessionDetailsStyles from '@/components/SessionDetails/SessionDetails.module.css';

interface SessionPanelVCProps {
  viewModel: SessionPanelVMType;
}

const SessionPanelViewControllerFC: React.FC<SessionPanelVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore } = rootStore;
  const { isAdvancedMode, isSidebarCollapsed } = appSettingsStore;
  const { isSessionDataLoaded, selectedSession, selectedSessionId, selectedSessionTitle } = sessionsStore;
  const {
    closeSessionPanel,
    isLogWindowItemSelected,
    isSessionPanelOpen,
    isSessionStepWindowSelected,
    selectedSessionRoutePath,
    sessionStep,
  } = sessionDetailsStore;

  const { loadMessage } = viewModel;
  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [localMessage, setLocalMessage] = React.useState<string>(null);
  const status = selectedSession?.status;

  // Build the debug footer content.
  const onRenderFooterContent: () => React.ReactElement = React.useCallback(() => {
    return (
      <div className={styles['footer-container']}>
        {isAdvancedMode && (
          <>
            <Badge>Steps: {isSessionStepWindowSelected.toString()}</Badge> &nbsp;
            <Badge>Logs: {isLogWindowItemSelected.toString()}</Badge> &nbsp;
          </>
        )}
        {isAdvancedMode && sessionStep && (
          <>
            <Badge>StepId: {sessionStep?.id}</Badge> &nbsp;
            <Badge>InstanceId: {sessionStep?.experimentId}</Badge> &nbsp;
          </>
        )}
      </div>
    );
  }, [selectedSession, sessionStep, isSessionStepWindowSelected, isLogWindowItemSelected]);

  const footerContent = isAdvancedMode ? onRenderFooterContent : null;

  const panelHeader = (
    <div className={`${sessionDetailsStyles['auto-collapsing-header']} no-flex`}>
      <div className={`${sessionDetailsStyles['header-row']} no-flex`} title={selectedSessionTitle}>
        <div className={sessionDetailsStyles['title-text']}>
          <div className={sessionDetailsStyles['title-truncate']}>{selectedSessionTitle}</div>
        </div>
        {isSessionDataLoaded && (
          <Link
            className={sessionDetailsStyles['title-link']}
            to={{
              pathname: selectedSessionRoutePath,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={sessionDetailsStyles['title-icon']} iconName={SystemIcons.OPEN_IN_NEW_WINDOW} />
          </Link>
        )}
      </div>
    </div>
  );

  const userSettings: SidePanelUserSettingsType = {
    headerText: panelHeader,
    type: PanelType.custom,
    customWidth: Units.Eighty_Percent,
    isOpen: isSessionPanelOpen,
    onDismiss: closeSessionPanel,
    onRenderFooterContent: footerContent,
    styles: isSidebarCollapsed ? SessionPanelStyles.wideSessionPanel : SessionPanelStyles.regularSessionPanel,
  };

  const resetPanel = () => {
    const message: string = loadMessage || null;

    setLocalMessage(message); // Clear any messages in this Panel.
  };

  React.useEffect(() => {
    selectedSession && resetPanel();
  }, [selectedSession, isSessionDataLoaded, status, loadMessage]);

  return (
    <SessionPanelTemplate
      content={content}
      localMessage={localMessage}
      sessionId={selectedSessionId}
      setContent={setContent}
      userSettings={userSettings}
    ></SessionPanelTemplate>
  );
};

const SessionPanelViewController = observer(SessionPanelViewControllerFC);

export default SessionPanelViewController;
