import React from 'react';
import { observer } from 'mobx-react-lite';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { InputOnChangeData } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import AppSettingsService from '@/services/AppSettingsService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import MessageBarTester from './MessageBarTesterTemplate';
import { MessageBarTesterStorage } from './MessageBarTesterType';

const MessageBarTesterViewControllerFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, localStorage, systemMessageStore } = rootStore;
  const { debugModeMessage } = appSettingsStore;
  const { addHelpMessage, addGlobalMessage, clearNonPersistentGlobalMessages, clearAllGlobalMessages } = systemMessageStore;

  React.useEffect(() => {
    addHelpMessage('playground-help');

    setMessageButtonStorage();
  }, []);

  const messageButtonsKey = AppSettingsService.MESSAGE_BUTTONS_KEY;

  const setMessageButtonStorage = () => {
    const messageButtonsStored: MessageBarTesterStorage = {
      message: '',
      persistent: false,
      namespace: false,
      type: FluentMessageBarType.info,
      mode: MessageBarMode.rememberState,
      group: false,
      groupId: '',
    };

    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const addMessage = () => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);
    const { message, persistent, namespace, type, mode, group, groupId } = messageButtonsStored;

    const globalMessage: SystemMessageType = {
      message,
      id: uuidv4(),
      namespace: namespace ? NS.PLAYGROUND : undefined,
      type,
      mode,
      persistent,
      groupId: group ? groupId : undefined,
    };
    addGlobalMessage(globalMessage);

    if (debugModeMessage) {
      console.log('[MessageBarTester] globalMessage addMessage(): ', globalMessage);
    }
  };

  const clearNonPersistentMessages = () => {
    clearNonPersistentGlobalMessages();
  };

  const clearAllMessages = () => {
    clearAllGlobalMessages();
  };

  const togglePersistent = () => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);
    messageButtonsStored.persistent = !messageButtonsStored.persistent;

    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const toggleNamespace = () => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);
    messageButtonsStored.namespace = !messageButtonsStored.namespace;

    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const setType = (input: string) => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);

    if (input === 'info') {
      messageButtonsStored.type = FluentMessageBarType.info;
    } else if (input === 'warning') {
      messageButtonsStored.type = FluentMessageBarType.warning;
    } else {
      messageButtonsStored.type = FluentMessageBarType.error;
    }

    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const setMode = (input: string) => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);

    if (input === 'displayOnceOnly') {
      messageButtonsStored.mode = MessageBarMode.displayOnceOnly;
    } else if (input === 'cannotDismiss') {
      messageButtonsStored.mode = MessageBarMode.cannotDismiss;
    } else {
      messageButtonsStored.mode = MessageBarMode.rememberState;
    }

    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const setMessage = (input: string) => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);

    messageButtonsStored.message = input;
    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const toggleGroup = () => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);

    messageButtonsStored.group = !messageButtonsStored.group;
    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const setGroupId = (input: string) => {
    const messageButtonsStored: MessageBarTesterStorage = localStorage.getValue(messageButtonsKey);

    messageButtonsStored.groupId = input;
    localStorage.setValue(messageButtonsKey, messageButtonsStored);
  };

  const onModeSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setMode(JSON.parse(JSON.stringify(option))['optionValue']);
  };

  const onTypeSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setType(JSON.parse(JSON.stringify(option))['optionValue']);
  };

  const onMessageChange = (event: React.FormEvent<HTMLDivElement>, data?: InputOnChangeData) => {
    setMessage(JSON.parse(JSON.stringify(data))['value']);
  };

  const onGroupIdChange = (event: React.FormEvent<HTMLDivElement>, data?: InputOnChangeData) => {
    setGroupId(JSON.parse(JSON.stringify(data))['value']);
  };

  return (
    <MessageBarTester
      addMessage={addMessage}
      clearAllMessages={clearAllMessages}
      clearNonPersistentMessages={clearNonPersistentMessages}
      togglePersistent={togglePersistent}
      toggleNamespace={toggleNamespace}
      toggleGroup={toggleGroup}
      setMode={setMode}
      setType={setType}
      setMessage={setMessage}
      setGroupId={setGroupId}
      onModeSelect={onModeSelect}
      onTypeSelect={onTypeSelect}
      onMessageChange={onMessageChange}
      onGroupIdChange={onGroupIdChange}
    />
  );
};

const MessageBarTesterViewController = observer(MessageBarTesterViewControllerFC);

export default MessageBarTesterViewController;
