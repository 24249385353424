import { action, makeObservable, observable } from 'mobx';

import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeWithParam, ActionTypeWithParamReturn, BooleanTypeAny } from '@/types/AppSettingsTypes';

export class PublishMachineCatalogue {
  public Name: string;
  public Ip: string;
  public Id: string;
  public LabId: string;

  constructor(Name: string, Ip: string, Id: string, LabId: string) {
    this.Name = Name;
    this.Ip = Ip;
    this.Id = Id;
    this.LabId = LabId;
  }
}
class ExperimentEditorStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public getLabs: Map<any, any[]>;
  public getMachinesCatalogue: PublishMachineCatalogue[] = [];

  public fileHandle: FileSystemFileHandle;
  public fileName: string;

  public airTemplatesCatalogue: any[] = [];
  public labsTemplatesCatalogue: any[] = [];

  public getExperiment = '';

  public static readonly EXPERIMENT_EDITOR_LABS_TOGGLE = 'editor-labs-toggle';
  public static readonly DEFAULT_FALSE_VALUE: boolean = false;
  public experimentEditorLabsToggle: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    this.experimentEditorLabsToggle =
      this.localStorage.getValue(ExperimentEditorStore.EXPERIMENT_EDITOR_LABS_TOGGLE) || ExperimentEditorStore.DEFAULT_FALSE_VALUE;

    makeObservable(this, {
      getLabs: observable,
      getMachinesCatalogue: observable,

      fileHandle: observable,
      fileName: observable,

      airTemplatesCatalogue: observable,
      labsTemplatesCatalogue: observable,

      getTemplateCatalogue: observable,
      getExperiment: observable,

      setFileHandle: action,
      setFileName: action,
      setTemplates: action,
      setExperiment: action,
    });
  }

  public setExperimentEditorLabsToggle: BooleanTypeAny = (value: boolean) => {
    this.experimentEditorLabsToggle = value;
    return this.localStorage.setValue(ExperimentEditorStore.EXPERIMENT_EDITOR_LABS_TOGGLE, value);
  };

  public getTemplateCatalogue: ActionTypeWithParamReturn = (labs: boolean) => {
    return labs ? this.labsTemplatesCatalogue : this.airTemplatesCatalogue;
  };

  public setFileHandle = (fileHandle: FileSystemFileHandle) => {
    this.fileHandle = fileHandle;
  };

  public setFileName = (fileName: string) => {
    this.fileName = fileName;
  };

  public setTemplates: ActionTypeWithParam = (templates: any[], labs: boolean, oldAIRTemplates = false) => {
    // To handle the old AIR templates coming from Juno, need to support this for at least a while
    if (oldAIRTemplates) {
      this.airTemplatesCatalogue = this.airTemplatesCatalogue.concat(
        templates.map((template) => ({
          id: template.id,
          description: template.definition.description,
          experiment: template,
        })),
      );

      return;
    }

    if (labs) {
      this.labsTemplatesCatalogue = templates;
    } else {
      this.airTemplatesCatalogue = templates;
    }
  };

  public setLab: ActionTypeWithParam = (Lab: any) => {
    this.getLabs.set(Lab, []);
  };

  public setMachinesCatalogue: ActionTypeWithParam = (Labs: PublishMachineCatalogue[]) => {
    this.getMachinesCatalogue = Labs;
  };

  public setLabMachines: ActionTypeWithParam = (Lab: any, Machines: any[]) => {
    this.getLabs.set(Lab, Machines);
  };

  public setExperiment: ActionTypeWithParam = (experiment: string) => {
    this.getExperiment = experiment;
  };
}

export default ExperimentEditorStore;
