import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';

class SplitPanelViewModel {
  public addGlobalMessage;
  public isDebugMode;

  protected rootStore: RootStore;
  protected appSettingsStore: AppSettingsStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;
    const { isDebugMode } = appSettingsStore;

    this.rootStore = rootStore;
    this.appSettingsStore = appSettingsStore;

    this.isDebugMode = isDebugMode;
  }
}

export default SplitPanelViewModel;
