import React from 'react';

import '@/styles/Labs.css';
import './Pager.css';

export default function Pager(props: any) {
  const [selectedPage, setSelectedPage] = React.useState(props.currentPage);
  const totalPages = Math.ceil(props.resultCount / props.resultsPerPage);

  React.useEffect(() => {
    props.setCurrentPage(selectedPage);
  }, [selectedPage, props]);

  const goToNextPage = () => {
    setSelectedPage(selectedPage + 1);
  };

  const goToPreviousPage = () => {
    setSelectedPage(selectedPage - 1);
  };

  let i = 0;
  const page_links = [];

  let minPage = 1;
  let maxPage = totalPages;

  if (selectedPage - minPage > 2) {
    minPage = selectedPage - 2;
  }

  if (maxPage - selectedPage > 2) {
    maxPage = parseInt(selectedPage, 10) + 2;
  }

  for (i = minPage; i <= maxPage; i++) {
    if (i === parseInt(selectedPage, 10)) {
      page_links.push(
        <li key={i} className="page-item active">
          <span className="page-link page-list">{i}</span>
        </li>,
      );
    } else {
      page_links.push(
        <li key={i} className="page-item">
          <button className="page-link page-list" id={i.toString()} onClick={(e: any) => setSelectedPage(e.currentTarget.id)}>
            {i}
          </button>
        </li>,
      );
    }
  }

  let previousButton;

  if (parseInt(selectedPage, 10) === 1) {
    previousButton = (
      <li key="prev" className="page-item disabled">
        <span className="page-link page-list">Previous</span>
      </li>
    );
  } else {
    previousButton = (
      <li key="prev" className="page-item" onClick={goToPreviousPage}>
        <button className="page-link page-list">Previous</button>
      </li>
    );
  }

  let nextButton;

  if (parseInt(selectedPage, 10) === totalPages) {
    nextButton = (
      <li key="next" className="page-item disabled">
        <span className="page-link">Next</span>
      </li>
    );
  } else {
    nextButton = (
      <li key="next" className="page-item">
        <button className="page-link page-list" onClick={goToNextPage}>
          Next
        </button>
      </li>
    );
  }

  return (
    <nav aria-label="..." className="pager">
      <ul className="pagination item">
        {previousButton}
        {page_links}
        {nextButton}
      </ul>
    </nav>
  );
}
