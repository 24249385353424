import { RootStore } from '@/stores/RootStore';

class FooterViewModel {
  public isDebugMode: boolean;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;
    const { isDebugMode } = appSettingsStore;

    this.isDebugMode = isDebugMode;
  }
}

export default FooterViewModel;
