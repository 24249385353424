import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '@/constants/AirConstants';
import { BreadcrumbsParam } from '@/types/_air';

import '@/styles/Air.css';

interface InstanceStatusParams {
  experimentId: string;
  isInstance: string;
}

export const FailureAnalysisFullDetails = (data: any): JSX.Element => {
  const params: InstanceStatusParams = data.match.params;
  const { experimentId, isInstance } = params;
  const { state } = data.location;
  const { parameter, targetGoalInfo } = data.location.state;

  return isInstance === 'true' ? (
    <div className="air-wrapper gbs-status-data">
      <h5 className="text-left all-in-one">
        <Link
          to={{
            pathname: `${Routes.experimentInstanceFailureAnalysis.link}/${experimentId}`,
            state: {
              parameter,
              targetGoalInfo,
            },
          }}
          className="breadcrumbs"
        >
          {` ${experimentId} `}
        </Link>
        {` / ${state.FailureAnaysis.id} `}
      </h5>
      <div style={{ marginLeft: '10px' }}>
        <pre>{JSON.stringify(state.FailureAnaysis, null, 2).replace(/[{}]/g, '')}</pre>
      </div>
    </div>
  ) : (
    <div className="air-wrapper gbs-status-data">
      <h5 className="text-left all-in-one">
        <Link
          to={{
            pathname: `${Routes.executionGoalFailureAnalysis.link}/${state.experimentName}`,
          }}
          className="breadcrumbs"
        >
          {` ${state.experimentName} `}
        </Link>
        {` / ${state.FailureAnaysisExecutionGoal.failureAnalysis.id} `}
      </h5>
      <div style={{ marginLeft: '10px' }}>
        <pre>{JSON.stringify(state.FailureAnaysisExecutionGoal, null, 2).replace(/[{}]/g, '')}</pre>
      </div>
    </div>
  );
};
