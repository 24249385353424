/*----------------------------------------------------------------------------------------------------------------------------
# Routes.tsx: 
# This page controls the specific routing for the AIR sub-application.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Scenario } from '@/components/_air/experiments/Result/Scenario';
import { WorkloadInfo } from '@/components/_air/experiments/Result/WorkloadInfo';
import { WorkloadInfoForColor } from '@/components/_air/experiments/Result/WorkloadInfoForColor';
import { WorkloadProfileInfo } from '@/components/_air/experiments/Result/WorkloadProfileInfo';
import { ExecutionGoalFailureAnalysis } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExecutionGoalFailureAnalysis';
import { ExperimentInstanceFailureAnalysis } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentInstanceFailureAnalysis';
import { ExperimentInstancesStatus } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentInstancesStatus';
import { ExperimentTargetGoalsStatus } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentTargetGoalsStatus';
import { FailureAnalysisFullDetails } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/FailureAnalysisFullDetails';
import { ExperimentStatus } from '@/components/_air/experiments/SingleEntryPoint/Pages/ExperimentStatus';
import { ExperimentSuccessPage } from '@/components/_air/experiments/SingleEntryPoint/Pages/ExperimentSuccessPage';
import { ExperimentsPage } from '@/components/_air/experimentsPage/ExperimentsPage';
import { ManageExperimentsPage } from '@/components/_air/manageExperimentsPage/ManageExperimentsPage';
import { Navigation } from '@/constants/NavigationConstants';
import PageNotFound from '@/pages/404Page/404Page';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

export const AirRedirects = (): React.ReactElement => {
  return (
    <Switch>
      <Route path={Navigation.JUNO.HOME}>
        <Redirect to={Navigation.AIR.HOME} />
      </Route>
      <Route exact path={Navigation.AIR.HOME}>
        <Redirect to={Navigation.AIR.VIEW_STATUS} />
      </Route>
    </Switch>
  );
};

export const AirLandingPages = (): React.ReactElement => {
  return <></>;
};

// These MUST be defined in order of overriding route preference.
const AirRoutes = (): React.ReactElement => {
  return (
    // Here we make sure we are only dealing with a single AIR Route.
    <Route path={Navigation.AIR.HOME}>
      <Switch>
        <Route path={Navigation.AIR.SUCCESS_PAGE} component={ExperimentSuccessPage} />
        <Route path={`${Navigation.AIR.SCENARIO}/:name/:revision/:tab`} component={Scenario} />
        <Route
          path={`${Navigation.AIR.WORKLOAD_PROFILE}/:profile/:friendly/:experiment/:revision`}
          component={WorkloadProfileInfo}
        />
        <Route
          path={`${Navigation.AIR.WORKLOAD_PROFILE}/:profile/:friendly/:experiment/:revision`}
          component={WorkloadProfileInfo}
        />
        <Route
          path={`${Navigation.AIR.WORKLOAD_INFO}/:experiment/:profile/:friendly/:workload/:revision/:instance`}
          component={WorkloadInfo}
        />
        <Route
          path={`${Navigation.AIR.WORKLOAD_INFO_COLOR}/:experiment/:profile/:friendly/:workload/:score/:revision`}
          component={WorkloadInfoForColor}
        />
        <Route path={`${Navigation.AIR.SCHEDULER_STATUS}/:team/:goal/:experiment`} component={ExperimentStatus} />
        <Route
          path={`${Navigation.AIR.TARGET_GOAL_STATUS}/:team/:goalId/:experimentName/:workload`}
          component={ExperimentTargetGoalsStatus}
        />
        <Route path={`${Navigation.AIR.INSTANCE_STATUS}/:experimentId`} component={ExperimentInstancesStatus} />
        <Route path={`${Navigation.AIR.INSTANCE_FAILURE}/:experimentId`} component={ExperimentInstanceFailureAnalysis} />
        <Route path={`${Navigation.AIR.EXECUTION_GOAL_FAILURE}/:experimentName`} component={ExecutionGoalFailureAnalysis} />
        <Route
          path={`${Navigation.AIR.FAILURE_ANALYSIS_DETAILS}/:experimentId/:isInstance`}
          component={FailureAnalysisFullDetails}
        />
        <Route path={Navigation.AIR.VIEW_STATUS} component={ExperimentsPage} />
        <Route path={Navigation.AIR.MANAGE_EXPERIMENT} component={ManageExperimentsPage} />

        {/* Show the 404 page and keep the path intact. */}
        <Route component={PageNotFound} />
      </Switch>
    </Route>
  );
};

export default AirRoutes;
