import React from 'react';
import { Checkbox } from '@fluentui/react';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { t, TOptions } from 'i18next';
import format from 'string-template';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { ColumnEditorTypes } from './ColumnEditorTypes';

import styles from './ColumnEditor.module.css';

export const ColumnEditor: React.FC<ColumnEditorTypes> = (props: ColumnEditorTypes) => {
  const { columnsList, setColumnsList, hideColumnEditor, entireColumns, columnEditorKey, translationOptions } = props;

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { editColumnsStore, systemMessageStore } = rootStore;
  const { setEditorColumns } = editColumnsStore;
  const { addGlobalMessage } = systemMessageStore;

  const [selectedColList, setSelectedColList] = React.useState<any[]>(columnsList);
  const [selectAll, setSelectAll] = React.useState(false);
  const [orderedColumns, setOrderedColumns] = React.useState<any[]>([]);
  const sortedColumns: any[] = entireColumns.sort((a: any, b: any) => a.index - b.index) || [];

  React.useEffect(() => {
    const tOptions: TOptions = translationOptions || { ns: NS.TABLE };
    const tColumns = sortedColumns
      .filter((column: any) => !column.isHidden)
      .map((column) => {
        return { ...column, name: t(column.name, tOptions) };
      });

    setOrderedColumns(tColumns);
  }, [sortedColumns]);

  React.useEffect(() => {
    setSelectedColList(columnsList);
  }, [columnsList]);

  React.useEffect(() => {
    const allChecked = orderedColumns && selectedColList ? selectedColList.length === orderedColumns.length : false;

    setSelectAll(allChecked);
  }, [selectedColList, orderedColumns]);

  // Gets triggered when check box is checked/unchecked to push and pop the selected / deselected columns into the array
  const onCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean, value?: any) => {
    if (checked) {
      value.isOptional = false;
      setSelectedColList([...selectedColList, value]);
    } else {
      value.isOptional = true;

      const selectedColumns = selectedColList.filter((i) => i.key !== value.key);

      setSelectedColList(selectedColumns);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColList([]);
    } else {
      const checkAllColumns = orderedColumns?.map((item: any) => {
        item.isOptional = false;
        return item;
      });
      setSelectedColList(checkAllColumns);
    }

    setSelectAll(!selectAll);
  };

  // Saves the currently selected columns into local storage
  // and sets them up in the columnsList to reflect in the table list of the parent page.
  const saveList = () => {
    const selectedColKey = selectedColList.filter((item: any) => !item.isOptional).map((item: any) => item.key);

    setEditorColumns(columnEditorKey, selectedColKey);

    setColumnsList(selectedColList);
    hideColumnEditor();
  };

  const columnLabel = (column) => {
    return column.name;
  };

  // Sets the checkbox selected when the key matches to the current column
  const isColumnChecked = (column: any) => {
    return selectedColList.some((f: any) => f.key === column.key && !f.isOptional);
  };

  // Do some basic error handling to prevent the app from crashing.
  if (!columnsList || !entireColumns || !columnEditorKey) {
    const message = 'Error! Missing properties required to show the Column Editor.';
    const systemMessage: SystemMessageType = {
      message: format(t(message, { ns: NS.EXPERIMENTS })),
      type: FluentMessageBarType.error,
      mode: MessageBarMode.normal,
      groupId: 'experiments-labs-group',
    };
  }

  // Do some basic error handling to prevent the app from crashing.
  if (!columnsList || !entireColumns || !columnEditorKey) {
    const message = 'Error! Missing properties required to show the Column Editor.';
    const systemMessage: SystemMessageType = {
      message: format(t(message, { ns: NS.EXPERIMENTS })),
      type: FluentMessageBarType.error,
      mode: MessageBarMode.normal,
      groupId: 'experiments-labs-group',
    };

    console.error('[ColumnEditorPanelTemplate]:', message);
    addGlobalMessage(systemMessage);

    return null;
  }

  return (
    <div className={`${styles['edit-column-wrapper']}`}>
      <Checkbox
        className={`${styles['checkbox-wrapper']} ${styles['select-all']}`}
        label={t('select-all', { ns: NS.COMMON })}
        checked={selectAll}
        onChange={handleSelectAll}
      />
      <div className={`${styles['columns']}`}>
        {orderedColumns.map((item, index) => (
          <li key={index}>
            <Checkbox
              className={`${styles['checkbox-wrapper']}`}
              checked={isColumnChecked(item)}
              onChange={(ev, checked) => onCheckBoxChange(ev, checked, item)}
              label={columnLabel(item)}
            ></Checkbox>
          </li>
        ))}
      </div>
      <div className={`${styles['footer']}`}>
        <PrimaryButton text={t('save', { ns: NS.COMMON })} disabled={selectedColList.length < 2} onClick={saveList} />
        <DefaultButton text={t('cancel', { ns: NS.COMMON })} onClick={hideColumnEditor} />
      </div>
    </div>
  );
};
