import React from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Pivot, PivotItem } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Badge, Card, Col, Descriptions, Row, Tabs } from 'antd';
import { t } from 'i18next';
import ls from 'local-storage';
import * as qs from 'query-string';

import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { ResultExplorerIcons, SystemIcons } from '@/constants/IconConstants';
import { ColorsForStatus, Labels, TestExecutionStatus } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { PivotItemKeys, TemplateConstants } from '@/constants/ResultExplorerConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Results } from '@/constants/TranslationConstants';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { getResultExplorerAuthData } from '@/utils/Env';
import { logDownloadHelper } from '@/utils/Helpers';

import { copyTestRunURL } from '../../components/_labs/ResultsExplorer/Helpers';
import { TestCaseCollapse } from '../../components/_labs/ResultsExplorer/TestCaseCollapse';

import '@/styles/Labs.css';
import styles from '@/partials/TestRun/TestRun.module.css';

const TestRunFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [isToken, setIsToken] = React.useState<boolean>(false);
  const [parsedData, setParsedData] = React.useState<any>();
  const [status, setStatus] = React.useState<string>(TestExecutionStatus.loading);
  const [collectionId, setCollectionId] = React.useState<string>();
  const [templateId, setTemplateId] = React.useState<string>();
  const [testName, setTestName] = React.useState<string>();
  const [color, setColor] = React.useState<string>(ColorsForStatus.loading);
  const [metaData, setMetadata] = React.useState<any>();
  const [companyNameFromUrl, setCompanyNameFromUrl] = React.useState<any>();
  const [error, setError] = React.useState<boolean>(false);
  const [summaryDetails, setSummaryDetails] = React.useState<any>();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const title = t('experiment-run-report', { ns: NS.TITLES });
  const summary = t('summary', { ns: NS.RESULT_EXPLORER });
  const logs = t('logs', { ns: NS.RESULT_EXPLORER });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const dataToResAnalyzer = {
    url: location.pathname,
  };

  const setTokenData = React.useCallback(async () => {
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      })
      .then((response) => {
        (ls as any).set('resultsToken', response.accessToken);
        setIsToken(true);
      })
      .catch((error) => {
        setBusy(false);
        setError(true);

        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        }
      });
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.log('error from Results token creation ', e);
    });
  }, [instance]);

  const extractTemplate = (url, path, templateId) => {
    const queryParams = qs.parse(url);
    const companyName = queryParams.companyName;
    const templateValue = queryParams[path as string];

    setTemplateId(templateId);

    if (companyName) {
      setCompanyNameFromUrl(companyName);
    }

    setCollectionId(templateValue as string);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  // default token expiration time is 10 mins so renewing token every 9 mins
  React.useEffect(() => {
    setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, TimeInterval.NINE_MINS);
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  React.useEffect(() => {
    if (isToken) {
      if (props.location !== undefined) {
        const queryURL = props.location.pathname;

        if (queryURL !== undefined && queryURL.includes('resultCollectionId')) {
          extractTemplate(queryURL, `${Navigation.LABS.TEST_RUN}/resultCollectionId`, 'Result Collection Id');
        } else if (queryURL !== undefined && queryURL.includes('experimentDefinitionId')) {
          extractTemplate(queryURL, `${Navigation.LABS.TEST_RUN}/experimentDefinitionId`, 'Experiment Definition Id');
        } else {
          extractTemplate(queryURL, `${Navigation.LABS.TEST_RUN}/experimentInstanceId`, 'Experiment Instance Id');
        }

        const queryParams: any = qs.parse(location.search);
        const testNameParam = queryParams.testName !== undefined ? queryParams.testName : undefined;

        setTestName(testNameParam);
      } else {
        setCollectionId(props.resultCollectionId);
      }

      if (collectionId) {
        logManagementRequestService
          .getLogsById(collectionId, companyNameFromUrl)
          .then((results) => {
            if (results !== '' && results !== undefined && results.length > 0) {
              const testDetailsResult = results[0][Labels.testDetails];

              delete results[0][Labels.testDetails];
              delete results[0][Labels.extensions];

              const parseResults = Object.keys(results[0]).map((key) => [key, results[0][key as string]]);

              setBusy(false);
              setParsedData(testDetailsResult);
              setMetadata(results);
              setSummaryDetails(parseResults);
              setStatus(TestExecutionStatus.completed);
              setColor(ColorsForStatus.completed);
            } else {
              setBusy(false);
              setStatus(TestExecutionStatus.summaryError);
              setColor(ColorsForStatus.summaryError);
            }
          })
          .catch((error) => {
            setError(true);
            setColor(ColorsForStatus.error);
            setBusy(false);

            if (error.response) {
              setStatus(error.response.status);
            } else {
              setStatus(error.message);
            }
          });
      }
    }
  }, [collectionId, props.location, props.resultCollectionId, companyNameFromUrl, isToken]);

  const copyURL = () => {
    if (templateId !== TemplateConstants.DEFINITION_TEMPLATE_ID) {
      copyTestRunURL(collectionId, TemplateConstants.EXPERIMENT_INSTANCE_ID, companyNameFromUrl);
    } else {
      copyTestRunURL(collectionId, TemplateConstants.EXPERIMENT_DEFINITION_ID, companyNameFromUrl);
    }
  };

  const resultAnalyzerCommandBarItem: ICommandBarItemProps = {
    key: Results.RESULT_ANALYZER,
    text: t(Results.RESULT_ANALYZER, { ns: NS.RESULTS }),
    iconProps: { iconName: ResultExplorerIcons.ANALYTICS },
    onClick: () => {
      history.push({
        pathname: `${Navigation.LABS.RESULT_TRIAGE}/id=${collectionId}&companyName=${companyNameFromUrl}`,
        state: { prevPageDetails: dataToResAnalyzer },
      });
    },
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Results.DOWNLOAD_LOGS,
      text: t(Results.DOWNLOAD_LOGS, { ns: NS.RESULTS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      onClick: () => {
        logDownloadHelper(collectionId, systemMessageStore, companyNameFromUrl);
      },
    },
    {
      key: Results.RESULT_EXPLORER,
      text: t(Results.RESULT_EXPLORER, { ns: NS.RESULTS }),
      iconProps: { iconName: ResultExplorerIcons.EXPLORE_DATA },
      onClick: () => {
        history.push(Navigation.GANYMEDE.RESULT_EXPLORER);
      },
    },
    templateId !== TemplateConstants.DEFINITION_TEMPLATE_ID ? resultAnalyzerCommandBarItem : undefined,
    {
      key: Results.COPY_URL,
      text: t(Results.COPY_URL, { ns: NS.RESULTS }),
      iconProps: { iconName: SystemIcons.COPY_URL },
      onClick: () => {
        copyURL();
      },
    },
  ].filter((item) => item !== undefined);

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container labs-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.LABS.RESULT_SEARCH]}>{title}</PageHeader>
          {testName === undefined && <PageCommandBar items={commandBarItems}></PageCommandBar>}
          <PageContent scrollable={true}>
            <div>
              <Row>
                <Col span={10}>
                  <span className="test-run-text-size">
                    {templateId} : {collectionId}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {templateId !== TemplateConstants.DEFINITION_TEMPLATE_ID ? (
                    <div className="test-run-badge">
                      Run Status: <Badge color={color} text={status} />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Pivot defaultSelectedKey={PivotItemKeys.LOGS}>
                <PivotItem headerText={logs} itemKey={PivotItemKeys.LOGS} className="background">
                  <div className={styles['logs-container']}>
                    <RawLogsTreeView id={collectionId} companyName={companyNameFromUrl} showLogText={true} />
                    <div className={styles['vertical-divider']}></div>
                    <div className={styles['logs-view-container']}>
                      <FilePreview companyName={companyNameFromUrl} />
                    </div>
                  </div>
                </PivotItem>
                {status === TestExecutionStatus.completed && testName === undefined && (
                  <PivotItem headerText={summary} itemKey={PivotItemKeys.SUMMARY} className="background">
                    <div>
                      <Descriptions bordered>
                        {summaryDetails.map((pair, index) => (
                          <Descriptions.Item key={index} label={pair[0]}>
                            {pair[1]}
                          </Descriptions.Item>
                        ))}
                      </Descriptions>
                      <Card title={Labels.testDetails} style={{ marginTop: 20 }}>
                        {parsedData && parsedData.map((result) => TestCaseCollapse(result))}
                      </Card>
                    </div>
                  </PivotItem>
                )}
              </Pivot>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

export const TestRun = observer(TestRunFC);
