import { action, makeObservable, observable } from 'mobx';

import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LogsViewPanelStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;
  public isLogsViewPanelOpen: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;
    this.isLogsViewPanelOpen = false;

    makeObservable(this, {
      openLogsViewPanel: action,
      closeLogsViewPanel: action,
      isLogsViewPanelOpen: observable,
    });
  }

  public openLogsViewPanel = () => {
    this.setIsLogsViewPanelOpen(true);
  };

  public closeLogsViewPanel = () => {
    this.setIsLogsViewPanelOpen(false);
  };

  public setIsLogsViewPanelOpen = (open: boolean) => {
    this.isLogsViewPanelOpen = open;
  };
}

export default LogsViewPanelStore;
