import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Icon } from '@fluentui/react';
import { Modal } from 'antd';
import ls from 'local-storage';

import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import { Labels } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';
import { getResultExplorerAuthData } from '@/utils/Env';

import { searchDocumentation } from './components/Documentation/SearchDocumentation';
import Pager from './components/Pager/Pager';
import Results from './components/Results/Results';
import SearchBar from './components/SearchBar/SearchBar';

import '@/styles/Labs.css';
import './components/Search.css';

const Search = observer(() => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const { search } = useLocation();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const nineMinsToMillisec = 540000;

  const [isTokenGenerated, setIsTokenGenerated] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [resultCount, setResultCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [q, setQ] = React.useState<string>(new URLSearchParams(search).get('q') ?? '*');
  const [top] = React.useState<any>(new URLSearchParams(search).get('top') ?? 10);
  const [skip, setSkip] = React.useState<any>(new URLSearchParams(search).get('skip') ?? 0);
  const [filters, setFilters] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [infoModalVisible, setInfoModalVisible] = React.useState(false);
  const resultsPerPage = top;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const setTokenData = React.useCallback(async () => {
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      })
      .then((response) => {
        setIsTokenGenerated(true);
        (ls as any).set('resultsToken', response.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        }
      });
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.log('error from Results token creation ', e);
    });
  }, [instance]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  // default token expiration time is 10 mins so renewing token every 9 mins
  React.useEffect(() => {
    setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, nineMinsToMillisec);
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  React.useEffect(() => {
    setIsLoading(true);
    setSkip((currentPage - 1) * top);

    const body = {
      SearchText: q,
      Size: top,
      Skip: skip,
    };

    if (isTokenGenerated) {
      logManagementRequestService
        .searchRawLogs(body)
        .then((response: any) => {
          setResults(response.Results);
          setResultCount(response.Count);
          setIsLoading(false);
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
          };

          HandleError(handleErrorProps);
          setIsLoading(false);
        });
    }
  }, [q, top, skip, filters, currentPage, isTokenGenerated]);

  // pushing the new search term to history when q is updated
  // allows the back button to work as expected when coming back from the details page
  React.useEffect(() => {
    history.push(`${Navigation.LABS.RESULT_SEARCH}?q=${q}`);
    setCurrentPage(1);
    setFilters([]);
  }, [q]);

  const postSearchHandler = (searchTerm: any) => {
    setQ(searchTerm);
  };

  let body;

  if (isLoading) {
    body = <LoadingSpinner />;
  } else {
    body = results && (
      <div className="padding-bottom rules-height">
        <Results documents={results} top={top} skip={skip} count={resultCount} />
        <Pager
          className="pager-style"
          currentPage={currentPage}
          resultCount={resultCount}
          resultsPerPage={resultsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  }

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container labs-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.LABS.RESULT_SEARCH]}>{Labels.resultSearch}</PageHeader>
          <PageContent>
            <div className="text-center fullscreen background results-table">
              <SearchBar postSearchHandler={postSearchHandler} q={q} />
              &nbsp; &nbsp;
              <h6
                id="targetButton"
                className="accent-color pointer"
                onClick={() => {
                  setInfoModalVisible(true);
                }}
              >
                <Icon iconName="Info" />
                &nbsp;
                {Labels.viewExamples}
              </h6>
              &nbsp; &nbsp;
              {body}
              <Modal
                className="labs-wrapper"
                footer={null}
                visible={infoModalVisible}
                onCancel={() => {
                  setInfoModalVisible(false);
                }}
              >
                {searchDocumentation()}
              </Modal>
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
});

export default Search;
