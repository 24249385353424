import React from 'react';
import { observer } from 'mobx-react-lite';
import { PartialTheme } from '@fluentui/react';
import { teamsDarkTheme, teamsLightTheme } from '@fluentui/react-components';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import MessagePageTemplate from './MessagePageTemplate';
import { MessagePageVMType } from './MessagePageTypes';

const MessagePageViewControllerFC: React.FC<MessagePageVMType> = (props: MessagePageVMType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDarkMode } = appSettingsStore;

  const theme: typeof PartialTheme = isDarkMode ? teamsDarkTheme : teamsLightTheme;
  const message: string = props.message;

  return <MessagePageTemplate theme={theme} message={message} />;
};

const MessagePageViewController = observer(MessagePageViewControllerFC);

export default MessagePageViewController;
