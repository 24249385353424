import React from 'react';
import { observer } from 'mobx-react-lite';

import { DocumentationWrapper } from '@/components/Documentation/DocumentationTypes';
import DocumentationViewController from '@/components/Documentation/DocumentationViewController';
import DocumentationViewModel from '@/components/Documentation/DocumentationViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const DocumentationFC: React.FC<DocumentationWrapper> = ({ url, pageKey }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const documentationViewModel = new DocumentationViewModel(rootStore, url, pageKey);

  return <DocumentationViewController viewModel={documentationViewModel}></DocumentationViewController>;
};

const Documentation = observer(DocumentationFC);

export default Documentation;
