import BaseRequestService from '@/services/request-services/RequestService';
import { labsBackendEndpoint } from '@/utils/Env';

class FirmwareRequestService extends BaseRequestService {
  constructor(baseURL = '') {
    super(baseURL);
  }

  async getFirmware(firmwareId: any) {
    const { data } = await this.get(`firmware/${firmwareId}`);

    return data;
  }

  async getFirmwareInfo(systemId: any) {
    const { data } = await this.get(`firmwareSystems/${systemId}`);

    return data;
  }

  async refreshRMInfo(rackId: any) {
    const payload = {
      TargetRackIds: [Number(rackId)],
    };
    const { data } = await this.post('message/refreshRackManagerInfo', payload);

    return data;
  }
}

export const firmwareRequestService = new FirmwareRequestService(labsBackendEndpoint);
