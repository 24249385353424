import React from 'react';

import { DataErrorTemplateType } from './DataErrorType';

import styles from './DataError.module.css';

const DataErrorTemplate: React.FC<DataErrorTemplateType> = (props: DataErrorTemplateType): React.ReactElement => {
  return (
    <div className={styles.ContainerStyle}>
      <span className={styles.TextStyle}>{props.message}</span>
    </div>
  );
};

export default DataErrorTemplate;
