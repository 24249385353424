import { CancelToken } from 'axios';

import { ErrorsGraphType } from '@/components/Reliability/ErrorsGraph/ErrorsGraphType';
import { ReleaseAnnotationsType } from '@/components/Reliability/ReleaseAnnotationsType';
import { ErrorsDataType, PagesType, UsersType } from '@/components/Reliability/ReliabilityType';

import BaseRequestService from './RequestService';

class ReliabilityRequestService extends BaseRequestService {
  async getPages(timeDuration: number, queryParams: object, cancelToken?: CancelToken): Promise<PagesType[]> {
    const response = await this.get(`telemetry/pages/${timeDuration}`, queryParams, { cancelToken });

    return response?.data as PagesType[];
  }

  async getUsers(timeDuration: number, queryParams: object, cancelToken?: CancelToken): Promise<UsersType[]> {
    const response = await this.get(`telemetry/users/${timeDuration}`, queryParams, { cancelToken });

    return response?.data as UsersType[];
  }

  async getErrorsData(timeDuration: number, queryParams: object, cancelToken?: CancelToken): Promise<ErrorsDataType> {
    const response = await this.get(`telemetry/errorSummary/${timeDuration}`, queryParams, { cancelToken });

    return response?.data as ErrorsDataType;
  }

  async getGraphData(timeDuration: number, queryParams: object, cancelToken?: CancelToken): Promise<ErrorsGraphType[]> {
    const response = await this.get(`telemetry/errorBuckets/${timeDuration}`, queryParams, { cancelToken });

    return response?.data as ErrorsGraphType[];
  }

  async getReleaseAnnotations(queryParams: object, cancelToken?: CancelToken): Promise<ReleaseAnnotationsType[]> {
    const response = await this.get('telemetry/releaseAnnotations', queryParams, { cancelToken });

    return response?.data as ReleaseAnnotationsType[];
  }
}

export const reliabilityRequestService = new ReliabilityRequestService();
