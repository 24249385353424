/* eslint-disable react/jsx-key */
import React from 'react';
import { Collapse, Descriptions, Tag } from 'antd';

import { Colors, Labels } from '@/constants/LabsConstants';

import '@/styles/Labs.css';

const { Panel } = Collapse;
const generateKey = (pre: any) => `${pre}_${new Date().getTime()}`;

const getResultStatus = (result: any) => (result.Result ? result.Result : result.Status);

const resultStatus = (result: any) => {
  const resultStatus = getResultStatus(result);

  return (
    <span>
      {result.TEC}
      &nbsp;&nbsp;
      {resultStatus && (
        <Tag
          key={getResultStatus(result)}
          color={resultStatus === Labels.pass ? Colors.green : resultStatus === Labels.fail ? Colors.red : Colors.yellow}
        >
          {resultStatus.toUpperCase()}
        </Tag>
      )}
    </span>
  );
};

export const TestCaseCollapse = (result: any) => (
  <Collapse key={result} defaultActiveKey={['1']}>
    <Panel key={generateKey(result.TEC)} header={resultStatus(result)}>
      <Descriptions bordered column={1} contentStyle={{ backgroundColor: '#ffffff' }}>
        {Object.keys(result)
          .map((key) => [key, result[key as string]])
          .map((pair, index) => (
            <Descriptions.Item key={`descriptionItems-${index}`} label={pair[0]?.trim()}>
              {pair[1]?.trim()}
            </Descriptions.Item>
          ))}
      </Descriptions>
    </Panel>
  </Collapse>
);
