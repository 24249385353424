import React from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Line, LineChart, Pie, PieChart, XAxis, YAxis } from 'recharts';

import { GraphRendererFCType } from '@/partials/TileRenderers/TileRendererTypes';

import styles from '@/partials/TileRenderers/TileRenderer.module.css';

const GraphTile: React.FC<GraphRendererFCType> = ({ type, data, width, height, margin }): React.ReactElement => {
  let render: React.ReactElement;

  switch (type) {
    case 'pie': {
      render = (
        <PieChart width={width} height={height}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="time"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#82ca9d"
            isAnimationActive={false}
          />
        </PieChart>
      );
      break;
    }

    case 'line': {
      render = (
        <BarChart width={width} height={height} data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
          <XAxis dataKey="time" />
          <YAxis dataKey="value" mirror />
          <Bar dataKey="value" fill="#82ca9d" isAnimationActive={false} />
        </BarChart>
      );
      break;
    }

    case 'bar': {
      render = (
        <LineChart width={width} height={height} data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
          <XAxis dataKey="time" />
          <YAxis dataKey="value" mirror />
          <Line type="monotone" dataKey="value" stroke="#82ca9d" dot={false} isAnimationActive={false} />
        </LineChart>
      );
      break;
    }

    case 'area': {
      render = (
        <AreaChart width={width} height={height} data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
          <XAxis dataKey="time" />
          <YAxis dataKey="value" mirror />
          <Area dataKey="value" fill="#82ca9d" stroke="#82ca9d" isAnimationActive={false} />
        </AreaChart>
      );
      break;
    }

    default:
      render = <></>;
      break;
  }

  return <div className={styles['tile']}>{render}</div>;
};

export default GraphTile;
