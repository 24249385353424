import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Breadcrumb } from '@fluentui/react';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';

import { BreadcrumbsType } from './BreadcrumbsTypes';

import styles from './Breadcrumbs.module.css';

export class BreadcrumbsService {
  public static readonly IS_TITLE_ITEM: boolean = true;
  public static readonly IS_BREADCRUMB_LINK: boolean = false;

  public static quickItem(key: string, isCurrentItem?: boolean): IBreadcrumbItem {
    // TODO: Look into refactor to eliminate this warning (17236856).
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();
    const item: IBreadcrumbItem = {
      text: t(key, { ns: NS.NAVIGATION }),
      key: key,
      isCurrentItem: isCurrentItem || false,
    };

    if (!isCurrentItem) {
      item.onClick = () => {
        history.push(key);
      };
    }

    return item;
  }
}

const BreadcrumbsFC: React.FC<BreadcrumbsType> = (props: BreadcrumbsType): React.ReactElement => {
  const { children, items } = props;
  const itemCount = items?.length || 0;
  const maxDisplayedItems = Math.min(itemCount, 3);
  const overflowIndex = itemCount > 1 ? 1 : 0;

  if (items) {
    const breadcrumbs: any = (
      <Breadcrumb
        className={styles.breadcrumbs}
        items={items}
        maxDisplayedItems={maxDisplayedItems} // We will only display 3 items total.
        overflowIndex={overflowIndex} // We can show overflow breadcrumbs.
        ariaLabel=""
        overflowAriaLabel=""
      />
    );

    return <div className={styles.container}>{breadcrumbs}</div>;
  } else {
    return <div className={styles.container}>{children}</div>;
  }
};

const Breadcrumbs = observer(BreadcrumbsFC);

export default Breadcrumbs;
