const defaultPage = {
  height: '100%',
  paddingTop: '10px',
  position: 'relative',
  scrollBehavior: 'smooth',
};

const styles = { defaultPage };

export default styles;
