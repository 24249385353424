import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { SessionType } from '@/components/Sessions/SessionsTypes';
import { SystemIcons } from '@/constants/IconConstants';
import { CopyType, Delimiters, Namespaces as NS } from '@/constants/SystemConstants';
import { copyToClipboard } from '@/utils/Helpers';

import Config from './SessionInfoBlock.config.json';
import { SessionInfoBlockType } from './SessionInfoBlockTypes';

import styles from './SessionInfoBlock.module.css';

const SessionInfoBlockFC: React.FC<SessionInfoBlockType> = (props: SessionInfoBlockType): React.ReactElement => {
  const { session } = props;
  const tooltipId = useId('tooltip');

  const copyButton = (value: string): React.ReactElement => {
    const title = t('copy-to-clipboard', { ns: NS.COMMON });
    const icon = { iconName: SystemIcons.COPY };
    const onClick = () => copyToClipboard(value);

    return (
      <div className={styles['copy-button']}>
        <TooltipHost content={title} id={tooltipId}>
          <IconButton aria-label={title} iconProps={icon} onClick={onClick} />
        </TooltipHost>
      </div>
    );
  };

  const buildRow = (key: string, value: string, canCopy: CopyType = CopyType.CANNOT_COPY): React.ReactElement => {
    const text = value;
    const title = t(key, { ns: NS.EXPERIMENTS });

    return (
      <div className={styles['row']} key={key}>
        <div className={styles['label']}>{title}</div>
        <div className={styles['separator']}>{Delimiters.COLON}</div>
        <div className={styles['value']}>
          <div className={styles['text']}>
            {text}
            {canCopy === CopyType.CAN_COPY && copyButton(value)}
          </div>
        </div>
      </div>
    );
  };

  const buildGrid = (item: SessionType) => {
    if (!item) {
      return null;
    }

    const config = Config.sessionFields;

    const rows = config.map((configItem) => {
      const data = item[configItem.fieldName];

      if (data) {
        return buildRow(configItem.key, data, configItem.copy ? CopyType.CAN_COPY : CopyType.CANNOT_COPY);
      }

      // We do not have data, so we should not be able to copy.
      return buildRow(configItem.key, Delimiters.DASH, CopyType.CANNOT_COPY);
    });

    return rows;
  };

  const grid = buildGrid(session);

  return (
    <>
      <div className={styles['block-container']}>
        <div className={styles['block-columns']}>
          <div className={styles['block-column']}>
            <div className={styles['block-grid']}>{grid}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const SessionInfoBlock = observer(SessionInfoBlockFC);

export default SessionInfoBlock;
