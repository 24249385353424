import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { ChoiceGroup, DatePicker, DefaultButton, IconButton, SearchBox, TooltipHost } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/Selection';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';
import ls from 'local-storage';
import format from 'string-template';

import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import ExperimentDetailsTemplate from '@/components/ExperimentDetails/ExperimentDetailsTemplate';
import { ExperimentDetailsVMType } from '@/components/ExperimentDetails/ExperimentDetailsTypes';
import { ExperimentStepType, ExperimentType, StepDetailsType, StepsType } from '@/components/Experiments/ExperimentsTypes';
import StatusDonutChart from '@/components/Graphs/StatusDonut/StatusDonusTemplate';
import { TimeInterval, TimeRangeOptions } from '@/constants/DateFormatConstants';
import { Statuses, StepStatusTypes, TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { EnablePagination, FilterOptions, Namespaces as NS, Pagination } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { paginateData, setAdditionsToPagination } from '@/partials/Pagination/Pagination';
import { PaginationType } from '@/partials/Pagination/PaginationTypes';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { useCancellationToken } from '@/services/_labs/screen-service';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { formatDate } from '@/utils/Dates';
import { getResultExplorerAuthData } from '@/utils/Env';
import { copyToClipboard } from '@/utils/Helpers';

import styles from '@/components/ExperimentDetails/ExperimentDetails.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface ExperimentDetailsVCProps {
  viewModel: ExperimentDetailsVMType;
}

const ExperimentDetailsViewControllerFC: React.FC<ExperimentDetailsVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, experimentDetailsStore, userSettingsStore } = rootStore;
  const { isDebugMode, setTabMemory } = appSettingsStore;
  const {
    canViewInstanceResults,
    experimentDetails,
    setExperimentDetails,
    setExperimentDetailStatus,
    setExperimentLogs,
    canCancelExperiment,
    setExperimentStep,
    setExperimentStepDetails,
    clearSelectedExperimentStep,
    openExperimentStepsPopup,
    isExperimentModalOpen,
    isExperimentStepPopupOpen,
  } = experimentDetailsStore;
  const { timeZone } = userSettingsStore;
  const { title, labId, instanceId, loadMessage, filterAgentStepData } = viewModel;

  const cancellationToken = useCancellationToken();

  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [localMessage, setLocalMessage] = React.useState<string>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [stepsPaginationType, setStepsPaginationType] = React.useState<PaginationType>(null);
  const [filteredSteps, setFilteredSteps] = React.useState<any>([]);
  const [chart, setChart] = React.useState<React.ReactElement>();
  const tooltipId = useId('tooltip');
  const results = t(Common.RESULTS, { ns: NS.EXPERIMENTS });

  const [experimentPanelAgentStepSearchValue, setExperimentPanelAgentStepSearchValue] = React.useState<string>('');
  const [experimentPanelAgentStepStatusValues, setExperimentPanelAgentStepStatusValues] = React.useState<string[]>([]);
  const [experimentPanelAgentStepTypeValues, setExperimentPanelAgentStepTypeValues] = React.useState<string[]>([]);
  const [experimentPanelAgentStepStartDate, setExperimentPanelAgentStepStartDate] = React.useState<Date>(new Date());
  const [experimentPanelAgentStepEndDate, setExperimentPanelAgentStepEndDate] = React.useState<Date>(new Date());
  const [experimentPanelAgentStepTimeRange, setExperimentPanelAgentStepTimeRange] = React.useState<string>(FilterOptions.ALL);

  const agentStatusList = experimentDetails?.agentStatusList;
  const agentStepTypeList = experimentDetails?.agentStepTypeList;
  const showModalCloseButton = isExperimentModalOpen && !isExperimentStepPopupOpen;

  const handleClearAgentStepFilters = () => {
    setExperimentPanelAgentStepSearchValue('');
    setExperimentPanelAgentStepStatusValues([]);
    setExperimentPanelAgentStepTypeValues([]);
    setExperimentPanelAgentStepStartDate(new Date());
    setExperimentPanelAgentStepEndDate(new Date());
    setExperimentPanelAgentStepTimeRange(FilterOptions.ALL);
  };

  const agentStepChanges: string | string[] | Date =
    experimentPanelAgentStepSearchValue +
    experimentPanelAgentStepStatusValues +
    experimentPanelAgentStepTypeValues +
    experimentPanelAgentStepTimeRange +
    experimentPanelAgentStepStartDate +
    experimentPanelAgentStepEndDate;

  const hasAgentStepsFiltersApplied = () => {
    const searchFilter = !!experimentPanelAgentStepSearchValue.trim();
    const statusFilter = experimentPanelAgentStepStatusValues.length > 0;
    const stepTypeFilter = experimentPanelAgentStepTypeValues.length > 0;
    const dateFilter = experimentPanelAgentStepTimeRange !== FilterOptions.ALL;

    return searchFilter || statusFilter || stepTypeFilter || dateFilter;
  };

  const getStepDisplayItems = (steps: StepsType[]): StepsType[] => {
    const showStepInfo = (step: StepsType, event: React.MouseEvent<HTMLSpanElement>): void => {
      openExperimentStepsPopup();
      setExperimentStepDetails(step.stepJSON);
      event.stopPropagation();
    };

    const items: StepsType[] = steps?.map((step: StepsType) => {
      const displayName: React.ReactElement = (
        <div>
          <span className={styles['link']} onClick={(event) => showStepInfo(step, event)}>
            {step.name}
          </span>
        </div>
      );
      const newStep: StepsType = { ...step, displayName };

      return newStep;
    });

    return items;
  };

  React.useEffect(() => {
    handleClearAgentStepFilters();
    clearSelectedExperimentStep();
    setExperimentDetails(experimentDetails);
  }, [experimentDetails]);

  React.useEffect(() => {
    // Steps information to update the chart.
    if (experimentDetails?.agentSteps) {
      const seriesData: number[] = extractSteps(experimentDetails.agentSteps);
      const total: number = seriesData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      const donutChart = <StatusDonutChart seriesData={seriesData} total={total} />;

      setChart(donutChart);
    } else {
      setChart(null);
    }
  }, [experimentDetails?.agentSteps]);

  const copyButton = (value: string): React.ReactElement => {
    const title = t('copy-to-clipboard', { ns: NS.COMMON });
    const icon = { iconName: SystemIcons.COPY };
    const onClick = () => copyToClipboard(value);

    return (
      <div className={styles['copy-button']}>
        <TooltipHost content={title} id={tooltipId}>
          <IconButton aria-label={title} iconProps={icon} onClick={onClick} />
        </TooltipHost>
      </div>
    );
  };

  const cancelConfirmation = t('cancel-experiment-confirmation', { ns: NS.EXPERIMENTS });

  const onSelectRangeAgentSteps = (key: string) => {
    const now = new Date();
    const startDate = new Date();

    setExperimentPanelAgentStepTimeRange(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      startDate.setHours(now.getHours() - Number(key));
      setExperimentPanelAgentStepStartDate(startDate);
      setExperimentPanelAgentStepEndDate(now);
    }
  };

  const filteredDataAgentStep = (agentSteps: StepsType[], pageIndex?: number) => {
    if (agentSteps?.length > 0) {
      const filteredResult = filterAgentStepData(
        agentSteps,
        experimentPanelAgentStepSearchValue,
        experimentPanelAgentStepStatusValues,
        experimentPanelAgentStepTypeValues,
        experimentPanelAgentStepStartDate,
        experimentPanelAgentStepEndDate,
        experimentPanelAgentStepTimeRange,
      );

      const hasFiltersApplied = hasAgentStepsFiltersApplied();

      const { paginatedItems, updatedPaginationType } = paginateData(
        filteredResult,
        stepPaginationType,
        hasFiltersApplied,
        pageIndex,
      );

      setStepsPaginationType(updatedPaginationType);
      setFilteredSteps(paginatedItems);
    }
  };

  const stepDisplayItems = React.useMemo(() => getStepDisplayItems(experimentDetails?.agentSteps), [experimentDetails?.agentSteps]);

  React.useEffect(() => {
    filteredDataAgentStep(stepDisplayItems);
  }, [
    experimentPanelAgentStepSearchValue,
    experimentPanelAgentStepStatusValues,
    experimentPanelAgentStepTypeValues,
    experimentPanelAgentStepStartDate,
    experimentPanelAgentStepEndDate,
    experimentPanelAgentStepTimeRange,
  ]);

  const handlePaginationChange = (pageIndex: number) => {
    filteredDataAgentStep(stepDisplayItems, pageIndex);
  };

  const stepPaginationType = setAdditionsToPagination(
    handlePaginationChange,
    EnablePagination.EXPERIMENTS_STEPS,
    true,
    Pagination.EXPERIMENTS_STEPS_PAGE_SIZE,
  );

  const loadLogs = async (instanceId: string, companyName: string) => {
    const data = await logManagementRequestService.checkIfLogsExist([instanceId], companyName);
    const results = data.Results?.map((item: { Id: string; LogsBaseFolderPath: string }) => {
      // Massage the Logs data to fit our model.
      return { id: item.Id, logsBaseFolderPath: item.LogsBaseFolderPath };
    });

    setExperimentLogs(results);
  };

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const [isToken, setIsTokenGenerated] = React.useState<boolean>(false);
  const nineMinsToMillisec = TimeInterval.NINE_MINS;

  const setTokenData = React.useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      });
      setIsTokenGenerated(true);
      (ls as any).set('resultsToken', response.accessToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        } catch (redirectError) {
          console.error('Error acquiring token via redirect:', redirectError);
        }
      } else {
        console.error('Error acquiring token silently:', error);
      }
    }
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.error('error from Results token creation ', e);
    });
  }, [instance]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, nineMinsToMillisec);

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  const openTestUrlInNewTab = async (labId: number, instanceId: string) => {
    const labData = await ganymedeLabRequestService.getLabDetails(labId);
    const companyId = labData.CompanyId;
    const labs = await ganymedeLabRequestService.getCompanies();
    const filteredLab = labs.filter((value) => value.CompanyId === companyId);
    const companyName = filteredLab[0].CompanyName;

    const url = `${Navigation.LABS.TEST_RUN}/experimentInstanceId=${instanceId}&companyName=${companyName}`;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const cancelExperiment = async (row: ExperimentType, event: any) => {
    if (!row) {
      return;
    }

    const instanceId = row.instanceId;

    isDebugMode && console.log('[ExperimentDetailsViewControllerFC] Cancelling ExperimentInstanceId:', instanceId);

    try {
      setIsLoading(true);
      // NOTE: We may need to call: await fetchExperimentV2();
      await ganymedeExperimentRequestService.cancelExperiment(instanceId.toString());

      const message = format(t('cancel-experiment-success-template', { ns: NS.EXPERIMENTS }), {
        instanceId,
      });

      setLocalMessage(message);
      setIsLoading(false);
      setExperimentDetailStatus(Statuses.CANCELLED); // Button will be disabled after this is set.
    } catch (error) {
      console.error('[ExperimentDetailsViewControllerFC] Cancel failure:', error.message);

      setLocalMessage(error.message);
      setIsLoading(false);
    }
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      clearSelectedExperimentStep();

      return selectStep(selection);
    },
  });

  const selectStep = (item: Selection) => {
    const selectedData: IObjectWithKey[] = item.getSelection();
    const selection: any | null = selectedData[0] as any | null;

    setExperimentStep(selection as StepsType);
  };

  const extractSteps = (steps: ExperimentStepType[]): number[] => {
    // Count the occurrences of each status type.
    const stepCounts = steps.reduce((accumulator, step) => {
      const status = step.status;

      accumulator[status as string] = (accumulator[status as string] || 0) + 1;

      return accumulator;
    }, {});

    // The StatusDonut component takes an array of these 6 values, in order.
    const stepValues: number[] = [
      stepCounts[StepStatusTypes.SUCCEEDED] || 0,
      stepCounts[StepStatusTypes.PENDING] || 0,
      stepCounts[StepStatusTypes.IN_PROGRESS] || 0,
      stepCounts[StepStatusTypes.FAILED] || 0,
      stepCounts[StepStatusTypes.NOT_STARTED] || 0,
      stepCounts[StepStatusTypes.CANCELLED] || 0,
    ];

    return stepValues;
  };

  const menuPropsAgentSteps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={experimentPanelAgentStepTimeRange}
            options={TimeRangeOptions}
            onChange={(ev: any, option: any) => {
              onSelectRangeAgentSteps(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={experimentPanelAgentStepTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setExperimentPanelAgentStepStartDate}
              value={experimentPanelAgentStepStartDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(experimentPanelAgentStepStartDate.toString(), timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={experimentPanelAgentStepTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setExperimentPanelAgentStepEndDate}
              value={experimentPanelAgentStepEndDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDate(experimentPanelAgentStepEndDate.toString(), timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const commandItems: ICommandBarItemProps[] = [
    {
      key: t('results', { ns: NS.EXPERIMENTS }),
      text: results,
      title: results,
      iconProps: { iconName: SystemIcons.RESULTS },
      onClick: (event: any) => {
        openTestUrlInNewTab(experimentDetails.labId, experimentDetails.instanceId);
      },
      disabled: !canViewInstanceResults,
    },
    {
      key: t('cancel-experiment-instance', { ns: NS.EXPERIMENTS }),
      text: t('cancel-experiment-instance', { ns: NS.EXPERIMENTS }),
      title: t('cancel-experiment-instance', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.CANCEL_INSTANCE },
      onClick: (event: any) => {
        if (confirm(cancelConfirmation)) {
          cancelExperiment(experimentDetails, event);
        }
      },
      disabled: !canCancelExperiment,
    },
  ];

  const agentFilterItems: IOverflowSetItemProps[] = [
    {
      key: 'panel-search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-step-name', { ns: NS.EXPERIMENTS })}
          title={t('search-step-name', { ns: NS.EXPERIMENTS })}
          role={'none'}
          value={experimentPanelAgentStepSearchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event: any, newValue: string) => setExperimentPanelAgentStepSearchValue(newValue)}
          onSearch={(newValue: string) => setExperimentPanelAgentStepSearchValue(newValue)}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'status-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-status', { ns: NS.COMMON })}
          title={t('select-status', { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={experimentPanelAgentStepStatusValues}
          options={agentStatusList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event: any, option: any) => {
            if (option.selected && !experimentPanelAgentStepStatusValues.includes(option.key)) {
              setExperimentPanelAgentStepStatusValues([...experimentPanelAgentStepStatusValues, option.key as string]);
            } else if (!option.selected && experimentPanelAgentStepStatusValues.includes(option.key)) {
              setExperimentPanelAgentStepStatusValues(
                experimentPanelAgentStepStatusValues.filter((key: string) => key !== option.key),
              );
            }
          }}
        />
      ),
    },
    {
      key: 'step-type-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-step-type', { ns: NS.EXPERIMENTS })}
          title={t('select-step-type', { ns: NS.EXPERIMENTS })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={experimentPanelAgentStepTypeValues}
          options={agentStepTypeList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event: any, option: any) => {
            if (option.selected && !experimentPanelAgentStepTypeValues.includes(option.key)) {
              setExperimentPanelAgentStepTypeValues([...experimentPanelAgentStepTypeValues, option.key as string]);
            } else if (!option.selected && experimentPanelAgentStepTypeValues.includes(option.key)) {
              setExperimentPanelAgentStepTypeValues(experimentPanelAgentStepTypeValues.filter((key: string) => key !== option.key));
            }
          }}
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('end-time', { ns: NS.TABLE })}
          title={t('end-time', { ns: NS.TABLE })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={menuPropsAgentSteps}
        />
      ),
    },
    {
      key: 'reset',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAgentStepFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
  ];

  const onLinkClick = (item: React.ReactElement) => {
    setTabMemory(TabMemoryKeys.EXPERIMENT_INSTANCE_MODAL, item.props.itemKey);
    clearSelectedExperimentStep();
  };

  const onDismissMessage: onDismissType = () => {
    setLocalMessage(null);
  };

  const resetPanel = (row: ExperimentType) => {
    const message = loadMessage || null;

    setExperimentDetails(row);
    setLocalMessage(message); // Clear any messages in this Panel.
    clearSelectedExperimentStep();
  };

  React.useEffect(() => {
    experimentDetails && resetPanel(experimentDetails);
    experimentDetails && loadLogs(experimentDetails.instanceId, experimentDetails.companyName);
  }, [experimentDetails, loadMessage, canCancelExperiment]);

  return (
    <ExperimentDetailsTemplate
      labId={labId}
      instanceId={instanceId}
      title={title}
      content={content}
      setContent={setContent}
      localMessage={localMessage}
      setLocalMessage={setLocalMessage}
      onDismissMessage={onDismissMessage}
      showModalCloseButton={showModalCloseButton}
      selection={selection}
      copyButton={copyButton}
      commandItems={commandItems}
      isLoading={isLoading}
      onLinkClick={onLinkClick}
      agentFilterItems={agentFilterItems}
      agentStepChanges={agentStepChanges}
      filteredSteps={filteredSteps}
      chart={chart}
      paginationType={stepsPaginationType}
    ></ExperimentDetailsTemplate>
  );
};

const ExperimentDetailsViewController = observer(ExperimentDetailsViewControllerFC);

export default ExperimentDetailsViewController;
