import React from 'react';
import { observer } from 'mobx-react-lite';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';

import { Navigation } from '@/constants/NavigationConstants';
import { BreadcrumbsService } from '@/partials/Breadcrumbs/BreadcrumbsTemplate';

import ReliabilityTemplate from './ReliabilityTemplate';
import { ReliabilityVMType } from './ReliabilityType';

const ReliabilityViewControllerFC: React.FC<{ viewModel: ReliabilityVMType }> = (props: { viewModel: ReliabilityVMType }) => {
  const { setDateRange, dateRange, errorsListTable, errorsCountTable } = props.viewModel;
  const breadcrumbs: IBreadcrumbItem[] = [
    BreadcrumbsService.quickItem(Navigation.GANYMEDE.INSIGHTS, BreadcrumbsService.IS_TITLE_ITEM),
  ];

  React.useEffect(() => {
    setDateRange(dateRange);
  }, []);

  return <ReliabilityTemplate breadcrumbs={breadcrumbs} errorsListTable={errorsListTable} errorsCountTable={errorsCountTable} />;
};

const ReliabilityViewController = observer(ReliabilityViewControllerFC);

export default ReliabilityViewController;
