import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

const BoolClasses = {
  boolbad: 'boolbad',
  boolbadWorkload: 'boolbad-workload',
  boolgood: 'boolgood',
  boolgoodWorkload: 'boolgood-workload',
  boolneutral: 'boolneutral',
  boolneutralWorkload: 'boolneutral-workload',
  boolyellow: 'boolyellow',
};

const Choices = {
  error: 'error',
  success: 'success',
};

const Colors = {
  green: 'green',
  red: 'red',
  yellow: 'yellow',
};

const DataKeys = {
  experimentGroup: 'experimentGroup',
  mean: 'mean',
};

const Directories = {
  experimentInfols: 'experimentInfols',
};

const ExecutionGoalParam = {
  environmentQuery: 'environmentQuery',
  experimentName: 'experiment.name',
  experimentTemplateFile: 'experimentTemplateFile',
  metadataWorkload: 'metadata.workload',
  vcCommandArguments: 'vcCommandArguments',
};

const ExperimentStatusKeys = {
  AVA: 'AVA',
  INF: 'INF',
  PER: 'PER',
  REL: 'REL',
};

const ExperimentStatusLabels = {
  AVA: 'Availability',
  INF: 'Infrastructure',
  PER: 'Performance',
  REL: 'Reliability',
};

const Expressions = {
  profile: 'PROFILE=',
};

const ExternalDependency = {
  nugetPackage: {
    type: 'Juno.Execution.Providers.Dependencies.NuGetPackageProvider',
    name: 'Download NuGet Package',
    description: 'Downloads a NuGet package dependency to the physical nodes/blades or VMs in the experiment group',
    group: 'Group A',
    parameters: {},
  },
};

const IconNames = {
  back: 'NavigateBack',
  calculatorEqualTo: 'CalculatorEqualTo',
  cancel: 'Cancel',
  caretSolidDown: 'CaretSolidDown',
  chevronDown: 'ChevronDown',
  chevronUnfold10: 'ChevronUnfold10',
  chevronUp: 'ChevronUp',
  circlePlus: 'CirclePlus',
  delete: 'Delete',
  dependencyAdd: 'DependencyAdd',
  doubleChevronLeft: 'DoubleChevronLeft',
  doubleChevronRight: 'DoubleChevronRight',
  error: 'Error',
  errorBadge: 'ErrorBadge',
  exploreContent: 'ExploreContent',
  fabricFolder: 'FabricFolder',
  fabricNetworkFolder: 'FabricNetworkFolder',
  fabricNewFolder: 'FabricNewFolder',
  fabricOpenFolderHorizontal: 'FabricOpenFolderHorizontal',
  feedback: 'Feedback',
  fullScreen: 'FullScreen',
  info: 'info',
  onboarding: 'Onboarding',
  pageAdd: 'PageAdd',
  radioOff: 'RadioBtnOff',
  radioOn: 'RadioBtnOn',
  sad: 'Sad',
  save: 'Save',
  saveAs: 'SaveAs',
  settings: 'Settings',
  queue: 'BuildQueueNew',
};

const Labels = {
  addExperiment: 'Add Experiment',
  addParam: '+ Add New Parameter',
  autoStart: 'auto-start',
  avsBDivergence: 'A vs B Divergence',
  betaSup: '(beta)',
  bladeDays: 'Blade days',
  cancel: 'Cancel',
  checkProgress: 'Check Progress',
  chooseTheme: 'Choose a Theme',
  clearSelection: 'Clear Selection',
  clone: 'Clone',
  cloneExperiment: 'Clone Experiment',
  closeAdditionalOptionalParams: '- Close additional optional parameters',
  closeOptionalParams: '- Close optional parameters',
  closePopupModal: 'Close popup modal',
  cloudReadinessCriteria: 'CLOUD READINESS CRITERIA',
  collapseMenu: 'CollapseMenu',
  compare: 'Compare',
  comparisonList: 'Comparison list',
  crcLabsReports: 'CRC Labs Reports',
  createExperiment: 'Create Experiment',
  dashboard: 'Dashboard',
  debug: 'Debug',
  dropdown: 'dropdown',
  edit: 'Edit',
  editExperiment: 'Edit Experiment',
  emptyNotificationHub: 'No new notifications from this session',
  enableManageExperimentsPage: 'Enable "Manage Experiments" page',
  enterExperimentName: 'Enter experiment name',
  error: 'error',
  estimatedCompletionDate: 'Estimated Completion Date',
  estimatedCost: 'Estimated Cost',
  expandAll: 'Expand all',
  experimentCategory: 'Experiment Category',
  experimentDetails: 'Experiment Details',
  experimentInstanceResources: 'Experiment Instance Resources',
  experimentInstanceSteps: 'Experiment Instance Steps',
  experimentName: 'Experiment Name',
  experimentStatus: 'Experiment Status',
  experimentSummary: 'Experiment Summary',
  experimentTemplate: 'Experiment Template',
  experiments: 'Experiments',
  failed: 'Failed',
  failedInstances: 'Failed Instances',
  failureAnalysis: 'Analysis',
  feedBack: 'Feedback',
  firstInstanceRunDate: 'First Instance Run Date',
  flightCenter: 'CRC AIR Flight Center',
  generation: 'Generation',
  group: 'Group',
  hideDependencies: 'Hide Dependencies',
  home: 'Home',
  inProgress: 'In Progress',
  inProgressInstances: 'In Progress Instances',
  insertWorkflow: 'Insert Workflow',
  intelCpuID: 'Intel CPU ID',
  lastInstanceRunDate: 'Last Instance Run Date',
  launchExperiment: 'Launch CRC AIR Experiment',
  login: 'Login',
  logout: 'Logout',
  makeParallel: 'Make Parallel',
  metadata: 'Metadata',
  minimizeAll: 'Minimize all',
  newExperimentResultView: 'Enable new "Experiment Result" view',
  noData: 'No Data',
  noTemplatesLoaded: 'There are no loaded templates',
  nodelg: 'Nodelg',
  notStarted: 'Not Started',
  notification: 'Notification',
  notificationHubSetting: 'Enable notification hub',
  okay: 'Okay',
  parameters: 'Parameters',
  paylodTypes: 'Paylod Types',
  portalSettings: 'Portal Settings',
  portalVersion: 'Portal Ver.:',
  previewFeatures: 'Preview features',
  previous: '< Previous',
  publishedOn: 'Published:',
  removeParallel: 'Remove Parallel',
  results: 'Results',
  reviewAndCreate: 'Review + create',
  runStatus: 'Run Status',
  saveExperiment: 'Save Experiment',
  scheduleAndCost: 'Schedule and Cost',
  searchPlaceholder: 'Search',
  select: 'Select',
  selectExperiments: 'Select Experiments',
  selectGroup: 'Select Group',
  selectTemplate: 'Select template',
  selectWorkflowTypes: 'SELECT WORKFLOW TYPES',
  setComparison: 'Set comparison',
  setting: 'Settings',
  showAdditionalOptionParams: '+ Show additional optional parameters',
  showDependencies: 'Show Dependencies',
  showOptionalParams: '+ Show optional parameters',
  status: 'Status',
  strictvalidation:
    'Some validation rules are non-deterministic, meaning that one day the rule may pass, and the next day the rule may fail. The reason for introducing rules as non-deterministic is because the validation logic may rely on a non-deterministic and ever-changing data source. All non-determinstic validation is optional, and can be enabled by requesting for a Strict validation. eg :  It validates that the environment queries supplied in the execution goal produce non-zero counts.',
  submit: 'Submit',
  succeededInstances: 'Succeeded Instances',
  success: 'Success',
  summary: 'Summary',
  targetGoalStatus: 'Target Goal Status',
  teamName: 'Team Name',
  template: 'Template',
  timeDropdownPlaceholder: 'Select time range',
  toFeedbackPage: 'Go to Dashboard V2 Feedback Page',
  totalInstances: 'Target Successful Instances',
  trackExperiments: 'Track Experiments',
  trackYourExperiment: 'Track your experiment',
  validateExperiment: 'Validate Experiment',
  value: 'Value',
  viewDetail: 'View Details',
  viewExperiments: 'View Experiments',
  viewResult: 'View Result',
  viewStatus: 'View Status',
  workflows: 'Workflows',
  workloads: 'Workloads',
};

const Messages = {
  attemptToLaunchExperiment: 'when you attempt to launch an experiment.',
  authError: 'An error occurred during authentication, please try again!',
  changeExperimentTemplate: 'Do you want to change the experiment template? All custom changes will disappear.',
  cloudScaleExperimentation: 'All-in-one cloud-scale experimentation',
  completeJsonBelow: 'The complete json file is below.',
  deleteParam: 'Do you want to delete the parameter?',
  deleteParamConfirmation: 'Are you sure you want to delete parameter?',
  deleteWorkflow: 'Do you want to delete the workflow?',
  deleteWorkload: 'Do you want to delete the workload?',
  dragAndDropWorkflow: 'Drag and drop workflows here',
  errorWhileFetchingData: 'Uh oh! Something went wrong while fetching the data.',
  experimentAddedToComparisonQueue: 'Experiment was added to the comparison queue',
  experimentNotInitiated: 'You have not initiated any Experiment yet',
  experimentStatusLoadError: 'Unable to load the experiment status',
  experimentSuccessfullySubmitted: 'Experiment successfully submitted!',
  fillOutAllFields: 'Please fill out all of the fields.',
  logInToCRC: 'After your first expected execution date, please log in to the CRC Flight Center',
  noAvailableTemplates: 'No available templates for this team.',
  noDataFound: 'No Data Found!',
  noInstanceDataFound: 'No Instance Data Found!',
  noExperimentInstance: 'No Experiment Instances available for this target goal',
  noInitiatedExperiments: 'You have not initiated any Experiment yet',
  noStatusDataAvailable: 'No status data available for this experiment.',
  recentHistory: 'Your Recent History',
  reviewExperimentTemplateBeforeCreating: 'Please review your experiment template before creating it.',
  selectedTemplateDoesNotExist: 'Selected Template does not exist',
  settingsSaveError: 'Unable to save settings',
  somethingWentWrong: 'Something went wrong with writing to database.',
  templateCreatedSuccess: 'Experiment Template was successfully created. Select',
  templateListRetrieveError: 'Unable to retrieve template list',
  templateParametersDoesNotExist: 'Selected experiment cannot be edited. Parameters are missing from template',
  toReviewExperimentsStatuses: 'to review the status and results of your experiment.',
  unassignedRoleError: 'Unable to login. You need to be assigned to a role for the application!',
  wrongValue: 'Wrong value for execution frequency.',
  paramAlreadyExist: 'This parameter already exists. Do you want to change the value?',
};

const NotificationMessages = {
  errorOnExperimentCreation: 'There is an error on creating new experiment from template',
  errorOnFetchingData: 'There is an error on fetching data',
  errorOnReceivingExecutionGoalList: 'There is an error receiving Execution Goal List',
  errorOnReceivingExecutionGoalTemplate: 'There is an error receiving Execution Goal Template',
  errorOnReceivingExperimentHistoryData: 'There is an error receiving experiment history data',
  errorOnReceivingExperimentInfo: 'There is an error receiving experiment info',
  errorOnReceivingMetricsInfo: 'There is an error receiving metrics info',
  errorOnReceivingProvidersList: 'There is an error receiving Providers List',
  errorOnReceivingUserSettings: 'There is an error receiving user settings',
  errorOnReceivingWorkloadInfo: 'There is an error receiving workload info',
  errorOnReceivingWorkloadProfileInfo: 'There is an error receiving workload profile info',
  errorOnReceivingWorkloads: 'There is an error receiving Workloads',
};

const ParameterValues = {
  generation: 'generation',
  experimentName: 'experiment.name',
  intelCpuId: 'intelCpuId',
  targetInstances: 'targetInstances',
  targetGoal: 'targetGoal',
  nodeCpuId: 'nodeCpuId',
  includeVmSku: 'includeVmSku',
  baselineVmSku: 'baselineVmSku',
  treatmentVmSku: 'treatmentVmSku',
  vmSku: 'vmSku',
  guestAgentVersion: 'guestAgentVersion',
  workloadVersion: 'workloadVersion',
  gaVersion: 'gaVersion',
  vcVersion: 'vcVersion',
  haPFServicePath: 'haPFServicePath',
  hostAgentPFServicePath: 'hostAgentPFServicePath',
  vmCount: 'vmCount',
  osSku: 'osSku',
  budget: 'budget',
};

const RegExps = {
  int: /^-{0,1}\d+$/,
  word: /[A-Z][a-z]+/g,
  charsAndDigits: /[^a-zA-Z0-9]/g,
};

const PropTypes = {
  parallelExecution: 'ParallelExecution',
};

const Workflows = {
  items: 'items',
};

const Providers = {
  selected: 'selected',
  name: 'name',
  description: 'description',
};

const Routes = {
  experimentEditor: {
    link: '/experimentEditor',
    bigTitle: 'Experiment Editor',
    smallTitle: 'Create and edit experiments in the experiment editor',
  },
  experimentInstanceFailureAnalysis: {
    link: '/air/manageExperiments/experimentInstanceFailureAnalysis',
  },
  experimentInstancesStatus: {
    link: '/air/manageExperiments/experimentInstancesStatus',
  },
  executionGoalFailureAnalysis: {
    link: '/air/manageExperiments/executionGoalFailureAnalysis',
  },
  experimentsPage: {
    link: '/air/experimentResults',
    bigTitle: 'Experiments results',
    smallTitle: 'Track results of your experiments',
  },
  experimentTemplate: {
    link: '/air/createExperiment/experimentTemplate/create',
    bigTitle: 'Create experiment',
    smallTitle: 'Create experiment using a template',
  },
  failureAnalysisFullDetails: {
    link: '/air/manageExperiments/failureAnalysisFullDetails',
  },
  manageExperimentsPage: {
    link: '/air/manageExperiments',
    bigTitle: 'Manage experiments',
    smallTitle: 'Track statuses and manage your experiments',
  },
  newExperiment: {
    link: '/air/newExperiment',
    bigTitle: 'Create a new template',
    smallTitle: 'Build a new experiment step by step.',
  },
  scenario: {
    link: '/air/experimentResults/scenario',
  },
  schedulerStatus: {
    link: '/air/manageExperiments/schedulerStatus',
  },
  successPage: {
    link: '/air/createExperiment/experimentTemplate/create/successPage',
  },
  targetGoalsStatus: {
    link: '/air/manageExperiments/targetGoalStatus',
  },
  workloadInfo: {
    link: '/air/WorkloadInfo',
  },
  workloadInfoForColor: {
    link: '/air/WorkloadInfoForColor',
  },
  workloadProfile: {
    link: '/air/workloadProfile',
  },
};

const Statuses = {
  failed: 'Failed',
  fetchFailed: 'fetch failed',
  inProgress: 'In Progress',
  notAvailable: 'Not Available',
  notSpecified: 'Not Specified',
  pending: 'Pending',
  pendingLaunch: 'Pending Launch',
  succeeded: 'Succeeded',
};

const TabLabels = {
  cost: 'COST',
  definition: 'DEFINITION',
  experiment: 'EXPERIMENT',
  experimentSelection: 'Experiment Template Selection',
  metadata: 'METADATA',
  parameters: 'PARAMETERS',
  reviewAndCreate: 'REVIEW & CREATE',
  settings: 'SETTINGS',
  status: 'STATUS',
  testCases: 'TEST CASES',
  workflow: 'WORKFLOW',
};

const TableHeaders = {
  aStats: 'A Stats',
  abStats: 'A / B Stats',
  bStats: 'B Stats',
  businessSignal: 'Business Signal',
  confidenceScores: 'Confidence Scores',
  created: 'Created',
  createdBy: 'Created By',
  createdDate: 'Created Date',
  description: 'Description',
  experimentName: 'Experiment Name',
  experimentProperty: 'Experiment Property',
  experiments: 'Experiments',
  group: 'Group',
  histogram: 'Histogram',
  kusto: 'Kusto',
  lastUpdated: 'Last Updated',
  messageDate: 'Message Date',
  messageType: 'Message Type',
  metricCount: 'Metric Count',
  name: 'Name',
  numberOfExecutions: 'Number of Executions',
  profileName: 'Profile Name',
  progress: 'Progress',
  revision: 'Revision',
  scale: 'Scale',
  startDate: 'Start Date',
  stats: 'Stats',
  status: 'Status',
  statusDetail: 'Status Detail',
  testBenchmarks: 'Test Benchmarks',
  testMetrics: 'Test Metrics',
  testScenario: 'Test Scenario',
  units: 'Units',
  updated: 'Updated',
};

const TableRaws = {
  max: 'Max',
  mean: 'Mean',
  min: 'Min',
  p25: 'P25',
  p50: 'P50',
  p75: 'P75',
  p95: 'P95',
};

const TelemetryMessages = {
  monitoringServiceInitialized: 'Monitoring service is initialized',
  powerBiAcquireTokenFailed: 'There is an error on acquiring access token for Power Bi scope',
  powerBiAcquireTokenSuccess: 'Access token for Power Bi scope successfully acquire',
  powerBiTokenExpired: 'Access token for Power Bi scope is expired.',
  powerBiTryToAcquireToken: 'Try to acquire access token for Power Bi scope',
};

const TenantIds = {
  AME: '33e01921-4d64-4f8c-a055-5bdaffd5e33d',
  MS: '72f988bf-86f1-41af-91ab-2d7cd011db47',
};

const Urls = {
  validationDocUrl:
    'https://eng.ms/docs/cloud-ai-platform/ahsi-organization/ahsi/redsa-team/cloud-readiness-criteria-air/team-documentation/experiments/authoring/kb-executiongoalvalidationrules',
};

const Workloads = {
  virtualClientWorkloadProvider: 'Juno.Execution.Providers.Workloads.VirtualClientWorkloadProvider',
};

const Defaults = {
  timeDropdownValue: '2-weeks',
  teamName: 'CRC AIR',
};

const ExecutionFailureAnalysisHeader = ['ExperimentId', 'FailureID', 'RCA', 'Category', 'Mitigation Step', 'Cluster', 'Group', ''];

const ExperimentFailureAnalysisHeader = ['ID', 'RCA', 'Category', 'Mitigation Step', 'Cluster', 'Group', ''];

const ExperimentStatusListPageTable = ['id', 'metadata.owner'];

const ExperimentStatusListPageTableHeaders = ['Experiment Name', 'Execution Goal Id', 'Owner', 'Created Date', '', '', '', ''];

const ExperimentStatusProgressSteps = ['Submitted', 'Pending Launch', 'In Progress', 'Complete'];

const ExperimentStatusTableHeaders = [
  'Target Goal',
  'Target Experiment Count',
  'Completed',
  'Successful',
  'Failed',
  'In Progress',
  'Successful Rate',
  'Estimated Completion Date',
  '',
];

const ExperimentInstanceResources = ['Id', 'Type', 'Group'];

const ExperimentInstanceResourceData = ['id', 'entityType', 'environmentGroup'];

const ExperimentInstanceSteps = ['StepId', 'StepName', 'Status', 'Start Time'];

const ExperimentInstanceStepsData = ['id', 'name', 'status'];

const TargetGoalInstancesPageTable = ['experimentId', 'experimentStatus'];

const TargetGoalInstancesPageTableHeaders = [
  { key: 'experimentId', value: 'Experiment Instance Id', sortable: false },
  { key: 'experimentStartTime', value: 'Start Time', sortable: true },
  { key: 'experimentStatus', value: 'Status', sortable: true },
  { key: 'status', value: '', sortable: false },
  { key: 'failureAnalysis', value: '', sortable: false },
];

const TemplateHeader = [
  { key: 'radio', value: '', filterable: false },
  { key: 'intent', value: 'Intent', filterable: true },
  { key: 'payloadType', value: 'Payload', filterable: true },
  { key: 'experimentCategory', value: 'Category', filterable: true },
  { key: 'teamName', value: 'Team Name', filterable: true },
  { key: 'templateOwner', value: 'Template Owners', filterable: true },
  { key: 'description', value: 'Description', filterable: false },
];

const WorkflowColumns = ['Name', 'Description', 'Group'];

const WorkloadColumns = ['Name', 'Description', 'Platform'];

const TimeDropdownOptions: IDropdownOption[] = [
  { key: 'all', text: 'all', id: 'all' },
  { key: 'day', text: '1 Day', id: 'day' },
  { key: 'week', text: '1 Week', id: 'week' },
  { key: '2-weeks', text: '2 Weeks', id: '2-weeks' },
  { key: 'month', text: '1 Month', id: 'month' },
];

export {
  BoolClasses,
  Colors,
  DataKeys,
  Defaults,
  ExecutionFailureAnalysisHeader,
  Expressions,
  ExperimentFailureAnalysisHeader,
  ExperimentInstanceSteps,
  ExperimentInstanceStepsData,
  ExperimentInstanceResourceData,
  ExperimentInstanceResources,
  ExperimentStatusKeys,
  ExperimentStatusLabels,
  ExperimentStatusProgressSteps,
  ExperimentStatusTableHeaders,
  IconNames,
  Labels,
  Messages,
  NotificationMessages,
  Routes,
  TabLabels,
  TableHeaders,
  TableRaws,
  TargetGoalInstancesPageTableHeaders,
  TelemetryMessages,
  TemplateHeader,
  TenantIds,
  TimeDropdownOptions,
  Statuses,
  WorkflowColumns,
  WorkloadColumns,
};
