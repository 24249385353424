import React from 'react';

import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import { ActionTypeVoid, ActionTypeWithParamReturn, ActionTypeWithTParam } from '@/types/AppSettingsTypes';

enum ExperimentPanelAccordionKeys {
  ESSENTIALS = 'essentials',
  JSON = 'json',
}

type ExperimentPropertiesPanelVMType = {
  loadMessage?: string;
};

type ExperimentPropertiesPanelVCType = {
  content: React.ReactElement;
  setContent: any;
  localMessage: string;
  setLocalMessage: ActionTypeWithTParam<string>;
  onDismissMessage: onDismissType;
  copyButton: ActionTypeWithParamReturn;
  isLoading: boolean;
  activeKey: string;
  onLinkClick: any;
  userSettings: any;
};

export { ExperimentPanelAccordionKeys, ExperimentPropertiesPanelVCType, ExperimentPropertiesPanelVMType };
