import {
  IAutoExceptionTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-common';
import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { container, singleton } from 'tsyringe';

import { RootStore } from '@/stores/RootStore';

import ILogger from './ILogger';
import { getAppInsights } from './TelemetryService';

/**
 * @implements {ILogger}
 * @description Telemetry class which provides functions to log in application insights and console.
 */
@singleton()
class TelemetryLogger implements ILogger {
  private _appInsights: ApplicationInsights;
  private _isConsoleEnabled: boolean;
  private _consoleSeverityLevel: number;
  private _telemetrySeverityLevel: number;

  /**
   * @constructor
   * @description Takes single arguement as application insights instance. By default uses {@link getAppInsights()}.
   */
  constructor(appInsights: ApplicationInsights = getAppInsights()) {
    const rootStore: RootStore = container.resolve(RootStore);
    const { appSettingsStore } = rootStore;
    const { isConsoleLogging, consoleSeverityLevel, telemetrySeverityLevel } = appSettingsStore;

    this._appInsights = appInsights;
    this._isConsoleEnabled = isConsoleLogging;
    this._consoleSeverityLevel = consoleSeverityLevel;
    this._telemetrySeverityLevel = telemetrySeverityLevel;
  }

  /**
   * @description Logs messages as info to application insight and to console if enabled.
   * @param message - The input message to log
   * @param properties - Optional properties to log additional details
   * @memberof TelemetryLogger
   */
  info = (message: string, properties: ICustomProperties = {}): void => {
    if (this._isConsoleEnabled && this._consoleSeverityLevel <= SeverityLevel.Information) {
      console.log(message, properties ?? '');
    }

    const obj: ITraceTelemetry = {
      message: message,
      severityLevel: SeverityLevel.Information,
    };

    properties.message = message;

    if (this._appInsights && this._telemetrySeverityLevel <= SeverityLevel.Information) {
      this._appInsights.trackTrace(obj, properties);
    }
  };

  /**
   * @description Logs messages as warning to application insight and to console if enabled.
   * @param message - The input message to log
   * @param properties - Optional properties to log additional details
   * @memberof TelemetryLogger
   */
  warning = (message: string, properties: ICustomProperties = {}): void => {
    if (this._isConsoleEnabled && this._consoleSeverityLevel <= SeverityLevel.Warning) {
      console.warn(message, properties ?? '');
    }

    const obj: ITraceTelemetry = {
      message: message,
      severityLevel: SeverityLevel.Warning,
    };

    properties.message = message;

    if (this._appInsights && this._telemetrySeverityLevel <= SeverityLevel.Warning) {
      this._appInsights.trackTrace(obj, properties);
    }
  };

  /**
   * @description Logs messages as exception to application insight and to console if enabled.
   * @param message - The input message to log
   * @param properties - Optional properties to log additional details
   * @memberof TelemetryLogger
   */
  exception = (message?: string, exception?: IAutoExceptionTelemetry, properties: ICustomProperties = {}): void => {
    if (this._isConsoleEnabled && this._consoleSeverityLevel <= SeverityLevel.Error) {
      console.error(message, exception, properties ?? '');
    }

    if (exception && message) {
      exception.message = `${message}, ${exception.message}`;
      properties.message = `${message}, ${exception.message}`;
    }

    const obj: IExceptionTelemetry = {
      exception: exception ?? new Error(message),
      severityLevel: SeverityLevel.Error,
    };

    if (this._appInsights && this._telemetrySeverityLevel <= SeverityLevel.Error) {
      this._appInsights.trackException(obj, properties);
    }
  };

  /**
   * @description Logs events to application insight's telemetry.
   * @param message - The name of event
   * @param properties - Optional properties to log additional details
   * @memberof TelemetryLogger
   */
  event = (name: string, properties?: ICustomProperties) => {
    const eventTelemetry: IEventTelemetry = {
      name,
    };

    if (this._appInsights) {
      this._appInsights.trackEvent(eventTelemetry, properties);
    }
  };
}

export default TelemetryLogger;
