import React from 'react';
import { observer } from 'mobx-react-lite';

import { CreateLabViewController } from '@/components/CreateLab/CreateLabViewController';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';

import { LabPanelVCType } from './LabPanelType';

const LabPanelTemplateFC: React.FC<LabPanelVCType> = (props: LabPanelVCType) => {
  const { userSettings, createLabViewModel } = props;

  const content = <CreateLabViewController viewModel={createLabViewModel}></CreateLabViewController>;

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

const LabPanelTemplate = observer(LabPanelTemplateFC);

export default LabPanelTemplate;
