import { PartnerDomains } from './SystemConstants';

const PartnerThemes = {
  [PartnerDomains.INTEL]: '#1271c5',
  [PartnerDomains.LENOVO]: '#074280',
  [PartnerDomains.MICROSOFT]: '#006ee5',
  [PartnerDomains.VSEI]: '#4ec5d8',
  [PartnerDomains.WIWYNN]: '#8ed300',
  [PartnerDomains.ZT]: '#de4827',
};

export { PartnerThemes };
