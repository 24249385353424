import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';

let appInsights: any = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function, setAuthenticatedUserContext: Function}} - Object
 */
const CreateTelemetryService = () => {
  const reactPlugin = new ReactPlugin();

  /**
   * Initialize the Application Insights class
   * @param {string} connectionString - Application Insights Connection string
   * @return {void}
   */
  const initialize = (connectionString: string) => {
    const connectionStringError = t('connection-string-error', { ns: NS.ERRORS });

    if (!connectionString) {
      throw new Error(connectionStringError);
    }

    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        maxBatchInterval: 0,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });

    appInsights.loadAppInsights();
  };

  /**
   * Initialize the Application Insights class
   * @param {string} userName - client's user name
   * @return {void}
   */
  const setAuthenticatedUserContext = (userName: string) => {
    appInsights.setAuthenticatedUserContext(userName, '');
  };

  return {
    reactPlugin,
    appInsights,
    initialize,
    setAuthenticatedUserContext,
  };
};

export const ai = CreateTelemetryService();
export const getAppInsights = () => appInsights;
