import { CancelToken } from 'axios';

import BaseRequestService from './RequestService';

class DocumentationRequestService extends BaseRequestService {
  async getDocumentationToken(cancelToken?: CancelToken): Promise<string> {
    const response = await this.get('documentation', { cancelToken });

    return response?.data as string;
  }
}

export const documentationRequestService = new DocumentationRequestService();
