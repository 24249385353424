const ExperimentEditorConstants = {
  SCHEMA_URI: 'https://crcportal.azure.com/schemas/executionGoal',
  FAKE_URI: 'a://FAKE/URI.json',
  ERROR_OWNER: 'json',
  ERROR_SEVERITY_LEVEL: 8,
  TIMEOUT_TO_MONACO_LOAD: 100,
  MONACO_THEME: 'monaco-theme',
};

const LabUpgrade = {
  LAB_UPGRADE_TEMPLATE_NAME: 'Vega_LabUpgrade',
  LAB_ID: 'labid',
  MANIFEST_VERSION: 'manifestversion',
};

export { ExperimentEditorConstants, LabUpgrade };
