/* eslint-disable react/no-danger */
import React from 'react';
import { Button, Collapse } from 'antd';

import { decodeBase64 } from '@/components/_labs/ResultsExplorer/Helpers';
import { Labels } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';

const { Panel } = Collapse;

import '@/styles/Labs.css';

export default function Result(props: any) {
  const decodedPath = decodeBase64(props.document.metadata_storage_path);
  const resultCollectionId = decodedPath.split('/')[4];
  const searchResultHeader = (fileName: any, path: any) => (
    <div>
      {fileName}
      &nbsp;&nbsp;
      {`Date Modified: ${props.document.metadata_storage_last_modified}`}
      &nbsp;&nbsp;
      <div
        className="ant-btn ant-btn-default background result-link"
        onClick={() => {
          window.location.href = `/testRun/resultCollectionId=${path}`;
        }}
      >
        {Labels.viewLogsForThisRun}
      </div>
    </div>
  );

  return (
    <div>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          key={props.document.metadata_storage_path}
          header={searchResultHeader(props.document.metadata_storage_name, resultCollectionId)}
        >
          <div dangerouslySetInnerHTML={{ __html: props.content.length > 0 ? props.content : props.document.content }} />
        </Panel>
      </Collapse>
      &nbsp;&nbsp;
    </div>
  );
}
