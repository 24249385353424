import React from 'react';
import { observer } from 'mobx-react-lite';
import { Stack } from '@fluentui/react';
import { IStackTokens } from '@fluentui/react/lib/Stack';

import { ButtonListTemplateType } from './ButtonListType';

const stackTokens: IStackTokens = { childrenGap: 10, padding: 5 };

const ButtonListTemplateFC: React.FC<ButtonListTemplateType> = (props: ButtonListTemplateType): React.ReactElement => {
  const { buttonComponents } = props;

  return (
    <Stack horizontal tokens={stackTokens} verticalAlign="end">
      {buttonComponents}
    </Stack>
  );
};

const ButtonListTemplate = observer(ButtonListTemplateFC);

export default ButtonListTemplate;
