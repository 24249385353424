import React from 'react';
import { CancelToken } from 'axios';
import { container } from 'tsyringe';

import { RootStore } from '@/stores/RootStore';
import { UserInfoType } from '@/types/UserTypes';

import BaseRequestService from './RequestService';

export class UserService extends BaseRequestService {
  protected _isAdvancedMode: boolean;
  protected _isDebugMode: boolean;
  protected _isDeveloperMode: boolean;

  constructor() {
    super();

    const rootStore: RootStore = container.resolve(RootStore);
    const { appSettingsStore, systemMessageStore } = rootStore;
    const { addGlobalMessage, addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
    const { isAdvancedMode, isDebugMode, isDeveloperMode } = appSettingsStore;

    this._isAdvancedMode = isAdvancedMode;
    this._isDebugMode = isDebugMode;
    this._isDeveloperMode = isDeveloperMode;
  }

  async getMyProfile(cancelToken?: CancelToken): Promise<UserInfoType> {
    this._isAdvancedMode && console.log('[UserService] getMyProfile()');

    const endpoint = 'user/info';
    const { data } = await this.get(endpoint, {}, { cancelToken });

    this._isAdvancedMode && console.log('[UserService] getMyProfile', data);

    return (data || { userId: null, groupId: null, companyName: null }) as UserInfoType;
  }

  async postLink(linkId: string) {
    this._isAdvancedMode && console.log('[UserService] postLink()');

    const endpoint = `links/${linkId}`;
    const { data } = await this.post(endpoint);

    this._isAdvancedMode && console.log('[UserService] postLink', data);

    return data;
  }
}

export const userService = new UserService();
