// Here we keep track the positining of all the widgets.
// This mock data is currently used in the following scenarios:
// - the user visits the site for the first time
// - the user clears their cache
// - the user resets the application
// Eventually, this sort of data will come from our Preferences/Settings APIs.

import { LayoutItemType } from '@/components/ReactGrid/ReactGridTypes';

const MockLayoutItemData: LayoutItemType[] = [
  { w: 7, h: 6, x: 0, y: 0, i: '1111118d-ae93-49ba-ad11-8464ffaaaaaa', moved: false, static: false },
  { w: 7, h: 6, x: 0, y: 6, i: '99999942-50f1-4b10-b7c7-a8f7aaffffff', moved: false, static: false },
  // { w: 11, h: 7, x: 19, y: 0, i: 'graph-type-3', moved: false, static: false },
  // { w: 11, h: 6, x: 19, y: 7, i: 'graph-type-4', moved: false, static: false },
  // { w: 9, h: 7, x: 39, y: 0, i: 'graph-type-5', moved: false, static: false },
  // { w: 48, h: 6, x: 0, y: 13, i: 'table-1', moved: false, static: false },
  // { w: 9, h: 7, x: 30, y: 0, i: 'clock-1', moved: false, static: false },
];

export { MockLayoutItemData };
