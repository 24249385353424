import React from 'react';
import { Line } from 'recharts';

import MultiLineScatterGraphTemplate from './MultiLineScatterGraphTemplate';
import { MultiLineScatterChartProps, MultiLineScatterTemplateType, MultiLineScatterType } from './MultiLineScatterType';

const MultiLineScatterGraphViewController: React.FC<MultiLineScatterType> = (props: MultiLineScatterType): React.ReactElement => {
  const chartProps: MultiLineScatterChartProps = props.chartProp;

  const renderMultiLines = () => {
    return chartProps.multiLineProps?.map((element, index) => <Line key={index} {...element} />);
  };

  const templateProps: MultiLineScatterTemplateType = {
    data: props.data,
    chartProp: chartProps,
    renderMultiLines,
  };

  return <MultiLineScatterGraphTemplate {...templateProps} />;
};

export default MultiLineScatterGraphViewController;
