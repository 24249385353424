import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button } from 'semantic-ui-react';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from './LabsServices.module.css';

const LabsServicesTemplateFC: React.FunctionComponent = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  const [labsList, setLabsList] = React.useState([]);
  const [firmwareList, setFirmwareList] = React.useState([]);

  const getLabsList: any = async () => {
    try {
      const value = await ganymedeLabRequestService.getLabs();
      setLabsList(value);
    } catch (error: any) {
      console.error(`Labs List Request Error: ${error}`);
    }
  };

  const labsListRender = labsList.map((lab, i) => (
    <li key={i}>
      {`Id: ${lab['LabId']} Name: ${lab['LabName']} LabStatus: ${lab['LabStatus']} LastHeartBeat: ${lab['LastHeartBeat']} CompanyId: ${lab['CompanyId']} `}
    </li>
  ));

  return (
    <div>
      <h2>
        <Trans ns={NS.TITLES as Namespace}>labs-services</Trans>
      </h2>
      <div>
        <Button
          className={styles['item']}
          floated="right"
          primary
          type="submit"
          content={'Get Labs List'}
          onClick={getLabsList}
        ></Button>
        <br />
        <div className={styles['item']}>{labsListRender}</div>
      </div>
    </div>
  );
};

const LabsServicesTemplate = observer(LabsServicesTemplateFC);

export default LabsServicesTemplate;
