export const FriendlyStatus: { [key: string]: any } = {
  CSNotFound: 'Machine not found',
  CSUnknown: 'Unknown state',
  CSPxeBoot: 'Network booting',
  CSWinPEDownloadStarted: 'Downloading WinPE started',
  CSAttemptWinPEBoot: 'Attempting to boot WinPE',
  CSBootsrapAgentDownloadStarted: 'Downloading bootstrap agent',
  CSDiskPartitionScriptDownloadStarted: 'Downloading disk partitioning script',
  CSOSImageDownloadStarted: 'Downloading full image',
  CSFullOSImageDownloadCompleted: 'Completed full image download',
  CSFullOSImageDownloadFailed: 'Failed to download full image',
  CSFullOSBootUp: 'Booting up full OS',
  CSWaitForOnline: 'Waiting for machine to become online',
  CSIdling: 'Idling',
  CSTestPlanRunning: 'Running a test plan',
  CSRebooting: 'Rebooting',
  CSReimaging: 'Reimaging',
};
