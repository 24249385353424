import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import ReliabilityViewController from './ReliabilityViewController';
import ReliabilityViewModel from './ReliabilityViewModel';

const ReliabilityFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const reliabilityViewModel = new ReliabilityViewModel(rootStore);

  return <ReliabilityViewController viewModel={reliabilityViewModel} />;
};

const Reliability = observer(ReliabilityFC);

export default Reliability;
