import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SplitPanelTemplate from './SplitPanelTemplate';
import { SplitPanelVCType, SplitPanelViewControllerProps, SplitPanelVMType } from './SplitPanelTypes';

const SplitPanelViewControllerFC: React.FC<SplitPanelViewControllerProps> = ({
  viewModel,
  config,
  children,
  index,
  isAllowResize,
  split,
}) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, userSettingsStore } = rootStore;
  // const {} = viewModel;

  return (
    <SplitPanelTemplate config={config} index={index} isAllowResize={isAllowResize} split={split}>
      {children}
    </SplitPanelTemplate>
  );
};

const SplitPanelViewController = observer(SplitPanelViewControllerFC);

export default SplitPanelViewController;
