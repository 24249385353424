import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import AIRExperimentPublishTemplate from '@/partials/_air/ExperimentPublish/ExperimentPublishTemplate';
import { AIRExperimentPublishVMType } from '@/partials/_air/ExperimentPublish/ExperimentPublishTypes';
import { executionGoalsRequestService } from '@/services/_air/request-services/ExecutionGoalsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const AIRExperimentPublishViewControllerFC: React.FC<AIRExperimentPublishVMType> = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { experimentEditorStore } = rootStore;
  const { getExperiment } = experimentEditorStore;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [header, setHeader] = React.useState<string>('None');
  const [message, setMessage] = React.useState<string>('None');

  React.useEffect(() => {
    createExperiment();
  }, []);

  const createExperiment = async () => {
    setLoading(true);
    setHeader(t('experiment-queued-header', { ns: NS.EDITOR }));

    await executionGoalsRequestService
      .createExecutionGoal(getExperiment)
      .then((response) => {
        if (response.type || response.Type) {
          setMessage(response.Detail);
        } else {
          setMessage(t('experiment-queued-success', { ns: NS.EDITOR }));
        }
      })
      .catch((error) => {
        let errorMessage = `${t('experiment-queued-failed', { ns: NS.EDITOR })}: ${error}`;

        if (error.response.data.type || error.response.data.Type) {
          if (error.response.data.Detail) {
            errorMessage = error.response.data.Detail;
          } else if (error.response.data.errors && error.response.data.errors.executionGoal) {
            errorMessage = `${t('experiment-queued-failed', { ns: NS.EDITOR })}: ${error.response.data.errors.executionGoal}`;
          }
        }

        setMessage(errorMessage);
      });

    setLoading(false);
  };

  return <AIRExperimentPublishTemplate loading={loading} message={message} header={header} />;
};

const AIRExperimentPublishViewController = observer(AIRExperimentPublishViewControllerFC);

export default AIRExperimentPublishViewController;
