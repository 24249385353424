import React from 'react';
import { observer } from 'mobx-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { SystemIcons } from '@/constants/IconConstants';
import UserProfileTemplate from '@/partials/UserProfile/UserProfileTemplate';
import { RootStoreContext } from '@/stores/RootStore';

import { PersonDetailsType } from './UserProfileTypes';

const UserProfileViewControllerFC: React.FC = (): React.ReactElement => {
  const { userInfoStore } = React.useContext(RootStoreContext);
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const name = userInfoStore.name;
  const userName = userInfoStore.userName;
  const userIcon: IIconProps = { iconName: SystemIcons.CONTACT };
  const personDetails: PersonDetailsType = {
    displayName: name,
    email: userName,
    query: 'me',
  };
  const buttonId = useId('targetButton');

  const handleLogin = () => {
    instance.loginRedirect();
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const [showLoginInfo, { toggle: toggleShowLoginInfo }] = useBoolean(false);

  return (
    <>
      <UserProfileTemplate
        inProgress={inProgress}
        isAuthenticated={isAuthenticated}
        userIcon={userIcon}
        personDetails={personDetails}
        buttonId={buttonId}
        showLoginInfo={showLoginInfo}
        toggleShowLoginInfo={toggleShowLoginInfo}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
    </>
  );
};

const UserProfileViewController = observer(UserProfileViewControllerFC);

export default UserProfileViewController;
