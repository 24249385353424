import React from 'react';
import { t } from 'i18next';
import JSON5 from 'json5';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { RootStore } from '@/stores/RootStore';

import PreviewExperimentStore from './PreviewExperimentStore';

class PreviewExperimentViewModel {
  protected _convertedExperiment: string;
  protected _isExperimentLoading: boolean;

  protected _experiment: string;

  private previewExperimentStore: PreviewExperimentStore;

  constructor(rootStore: RootStore) {
    const { experimentEditorStore, previewExperimentStore } = rootStore;
    const { getExperiment } = experimentEditorStore;
    const { convertedExperiment, isExperimentLoading } = previewExperimentStore;

    this._experiment = getExperiment;

    this._convertedExperiment = convertedExperiment;
    this._isExperimentLoading = isExperimentLoading;

    this.previewExperimentStore = previewExperimentStore;
  }

  public get convertedExperiment(): string {
    return this._convertedExperiment;
  }

  public get isExperimentLoading(): boolean {
    return this._isExperimentLoading;
  }

  public previewExperiment = async () => {
    const { setConvertedExperiment, setExperimentLoading } = this.previewExperimentStore;
    const json = JSON5.parse(this._experiment);

    setExperimentLoading(true);

    try {
      const response = await ganymedeExperimentRequestService.previewExperiment(json);

      setConvertedExperiment(JSON.stringify(response, null, '\t'));
    } catch (error) {
      setConvertedExperiment('An error has occurred: ' + this.getErrorMessage(error));
    }

    setExperimentLoading(false);
  };

  getErrorMessage = (error) => {
    let errorMessage = t('experiment-queued-failed', { ns: NS.EDITOR });

    if (error.response) {
      if (error.response.data?.Detail) {
        errorMessage = error.response.data.Detail;
      } else if (error.response.data?.detail) {
        errorMessage = error.response.data.detail;
      } else {
        errorMessage = error.response.data || t('experiment-queued-failed', { ns: NS.EDITOR });
      }
    } else {
      errorMessage = error.message;
    }

    return errorMessage;
  };
}

export default PreviewExperimentViewModel;
