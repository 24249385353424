import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, IconButton, Label, MessageBarType, Modal, SpinnerSize, TextField } from '@fluentui/react';
import { t } from 'i18next';
import format from 'string-template';

import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons } from '@/constants/LabsConstants';
import { Delimiters, FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import style from './DeleteLab.module.css';

interface DeleteLabProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

const DeleteLabFC: React.FC<DeleteLabProps> = (props: DeleteLabProps) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labListStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { isDeleteLabOpen, selectedLab, setIsLabRefreshed, setIsDeletingLab, isDeletingLab } = labListStore;

  const [enteredName, setEnteredName] = React.useState<string>('');
  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const labName = selectedLab.labName;
  const deleteSuccess = format(t(Labs.DELETE_SUCCESS_TEMPLATE, { ns: NS.LABS }), {
    labName: labName,
  });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const deleteLab = async (event: any): Promise<void> => {
    event.preventDefault();

    if (labName !== enteredName) {
      const failMessage: SystemMessageType = {
        message: t(Labs.LAB_NAME_MISMATCH, { ns: NS.LABS }),
        type: MessageBarType.error,
        groupId: FailGroupIds.LAB_DELETE,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);
      return;
    }

    setIsDeletingLab(true);

    await ganymedeLabRequestService
      .deleteLab(selectedLab.labId)
      .then((response: any) => {
        if (response) {
          const successMessage: SystemMessageType = {
            message: deleteSuccess,
            type: MessageBarType.success,
            groupId: SuccessGroupIds.LAB,
          };

          addGlobalMessage(successMessage);

          setIsLabRefreshed(true);
          setIsDeletingLab(false);
          props.hideModal();
        }
      })
      .catch((error) => {
        setIsDeletingLab(false);
        console.error('Error while deleting lab :', error);
      });
  };

  return (
    <Modal isOpen={isDeleteLabOpen} isBlocking={false} className={`${style['delete-lab-wrapper']}`} onDismiss={props.hideModal}>
      <div className={`${style['header']}`}>
        <IconButton
          className={`${style['iconButton']}`}
          iconProps={cancelIcon}
          ariaLabel={AreaLabels.closePopupModal}
          onClick={props.hideModal}
        />
      </div>
      <div className={`${style['body']}`}>
        <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
        <div className={`${style['row']} ${style['title']}`}>
          <h5>{t(Labs.LAB_NAME_TO_DELETE, { ns: NS.LABS })}</h5>
        </div>
        <div className={`${style['row']}`}>
          <Label className={style['label']}>{t(Labs.LAB_NAME, { ns: NS.LABS })}</Label>
          <Label className={`${style['label']} ${style['label-colon']}`}>{Delimiters.COLON}</Label>
          <Label className={style['label']}>{labName}</Label>
        </div>
        <div className={`${style['row']}`}>
          <TextField
            className={`${style['text-field']}`}
            required
            type="text"
            title={t(Labs.LAB_NAME, { ns: NS.LABS })}
            onChange={(e) => setEnteredName((e.target as any).value)}
          />
        </div>
        <div className={`${style['row']}`}>
          <DefaultButton className={`${style['default-button']} ${style['danger-button']}`} onClick={deleteLab}>
            {t(Labs.DELETE_LAB, { ns: NS.LABS })}
          </DefaultButton>
          <DefaultButton className={`${style['default-button']}`} onClick={props.hideModal}>
            {Buttons.cancel}
          </DefaultButton>
        </div>
        {isDeletingLab && (
          <div className={style['overlay-spinner']}>
            <LoadingSpinner size={SpinnerSize.medium} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export const DeleteLab = observer(DeleteLabFC);
