/*----------------------------------------------------------------------------------------------------------------------------
# RegularPageTemplate.tsx: 
# This Component takes the HTML passed to it by its calling element, and passes that content to the Container element. 
# (React takes that HTML and stores it into the children property, found in "this.props.children".)
# This page is our topmost Layout page, and the highest level where HTML and styling will occur. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react-lite';

import FooterViewController from '@/layouts/Footer/FooterViewController';
import FooterViewModel from '@/layouts/Footer/FooterViewModel';
import Header from '@/layouts/Header/HeaderTemplate';
import SideBarViewController from '@/partials/SideBar/SideBarViewController';
import SideBarViewModel from '@/partials/SideBar/SideBarViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { RegularPageVCType } from './RegularPageTypes';

import styles from './RegularPage.module.css'; // Apply any Layout level CSS Module styling here.

const RegularPageTemplateFC: React.FC<RegularPageVCType> = (props: RegularPageVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const sideBarViewModel = new SideBarViewModel(rootStore);
  const footerViewModel = new FooterViewModel(rootStore);

  const styleCollapsed: string = props.styleCollapsed;

  return (
    <div className={`${styles['layout']} ${styleCollapsed}`}>
      <div className={styles.topnav}>
        <Header />
      </div>
      <div className={styles.sidebar}>
        <SideBarViewController viewModel={sideBarViewModel} />
      </div>
      <div className={styles.main}>
        <Container className={`fullscreen ${styles['full-container']}`} fluid>
          {props.children}
        </Container>
      </div>
      <div className={styles.footer}>
        <FooterViewController viewModel={footerViewModel} />
      </div>
    </div>
  );
};

const RegularPage = observer(RegularPageTemplateFC);

export default RegularPage;
