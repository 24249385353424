import React from 'react';
import { CancelToken } from 'axios';
import { t } from 'i18next';
import moment from 'moment-timezone';
import format from 'string-template';

import {
  AirServiceType,
  ExperimentDetailsType,
  ExperimentRequestType,
  ExperimentType,
  GanymedeServiceType,
  LabsServiceType,
} from '@/components/Experiments/ExperimentsTypes';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Statuses } from '@/constants/ExperimentConstants';
import { FilterOptions, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { RootStore } from '@/stores/RootStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { formatDateToISOStandard, getRunTimeRangeDates } from '@/utils/Dates';
import { filterInternalProperties } from '@/utils/Helpers';

import ExperimentsStore from './ExperimentsStore';

class ExperimentsViewModel {
  public addGlobalMessage;
  public isDebugMode;

  protected experimentsStore: ExperimentsStore;
  protected userSettingsStore: UserSettingsStore;

  protected _searchValue: string;
  protected _locationValue: string;
  protected _statusValues: string[];
  protected _ipAddressValues: string[];
  protected _startDate: Date;
  protected _endDate: Date;
  protected _lastRunTime: string;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, experimentsStore, systemMessageStore, userSettingsStore } = rootStore;
    const { isDebugMode } = appSettingsStore;
    const { addGlobalMessage } = systemMessageStore;
    const { searchValue, locationValue, statusValues, ipAddressValues, startDate, endDate, lastRunTimeRange } = experimentsStore;

    this.addGlobalMessage = addGlobalMessage;
    this.experimentsStore = experimentsStore;
    this.isDebugMode = isDebugMode;
    this.userSettingsStore = userSettingsStore;

    this._endDate = endDate;
    this._ipAddressValues = ipAddressValues;
    this._lastRunTime = lastRunTimeRange;
    this._locationValue = locationValue;
    this._searchValue = searchValue;
    this._startDate = startDate;
    this._statusValues = statusValues;
  }

  public isRecentExperiment = (date: string, days: number): boolean => {
    const currentTime = moment.tz(this.userSettingsStore.timeZone);
    const compareDate = moment.tz(date, DateFormats.STANDARD_DATE_TIME, this.userSettingsStore.timeZone);

    const diff = moment.duration(currentTime.diff(compareDate));

    return diff.asDays() < days;
  };

  public calculateStatus = (data: any): string => {
    let finalStatus = t('not-available', { ns: NS.COMMON });

    if (data.length > 0) {
      if (data.every((i) => i.status?.toLowerCase() === Statuses.PENDING)) {
        finalStatus = t('pending', { ns: NS.COMMON });
      } else if (
        data.some((i) => i.status?.toLowerCase() === Statuses.INPROGRESS) ||
        data.some((i) => i.status?.toLowerCase() === Statuses.PENDING)
      ) {
        finalStatus = t('in-progress', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.FAILED)) {
        finalStatus = t('failed', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.CANCELLED)) {
        finalStatus = t('cancelled', { ns: NS.COMMON });
      } else if (data.every((i) => i.status?.toLowerCase() === Statuses.SUCCEEDED)) {
        finalStatus = t('succeeded', { ns: NS.COMMON });
      }
    }

    return finalStatus;
  };

  // Let us know when any filters have been applied.
  public get hasFiltersApplied(): boolean {
    const searchFilter = !!this._searchValue.trim();
    const locationFilter = !!this._locationValue;
    const statusFilter = this._statusValues.length > 0;
    const ipAddressFilter = this._ipAddressValues.length > 0;
    const dateFilter = this._lastRunTime !== FilterOptions.ALL;

    return searchFilter || locationFilter || statusFilter || ipAddressFilter || dateFilter;
  }

  public async experimentAirQuery(
    airService: AirServiceType,
    teamName: string,
    id: string,
    cancellationToken: CancelToken,
  ): Promise<ExperimentDetailsType> {
    const output: ExperimentDetailsType = {
      json: null,
      agentSteps: [],
      fullSteps: [],
    };

    try {
      const json = await airService.getDetailsOfExecutionGoal(teamName, id, cancellationToken);

      output.json = filterInternalProperties(json);
    } catch (error) {
      console.error(error);
    }

    return output;
  }

  public async experimentLabsQuery(
    labsService: LabsServiceType,
    ganymedeService: GanymedeServiceType,
    id: string,
    labId: number,
    instanceId: string,
    cancellationToken: CancelToken,
  ): Promise<ExperimentDetailsType> {
    const output: ExperimentDetailsType = {
      json: null,
      agentSteps: [],
      fullSteps: [],
    };

    try {
      const json = await ganymedeExperimentRequestService.getExperimentInstanceDetails(labId, id, cancellationToken);

      output.json = filterInternalProperties(json);
      output.agentSteps = await ganymedeService.getExperimentSteps(instanceId, cancellationToken);
      output.fullSteps = await labsService.getExperimentFullStepsVersion2(instanceId, 'Summary', cancellationToken);
    } catch (error) {
      console.error(error);
    }

    return output;
  }

  public getQueryParams = (
    currentPage: number,
    endDate: Date,
    ipAddressValues: string[],
    lastRunTimeRange: string,
    locationType: SystemType,
    locationValue: string,
    pageSize: number,
    startDate: Date,
    statusValues: string[],
    timeZone: string,
  ) => {
    const { searchValue } = this.experimentsStore;

    const queryParams: ExperimentRequestType = {
      from: currentPage * pageSize,
      size: pageSize,
      filters: {
        location: locationValue,
        type: locationType,
      },
    };

    if (lastRunTimeRange !== FilterOptions.ALL) {
      let lastRunTimeStartDate: Date;
      let lastRunTimeEndDate: Date;

      if (lastRunTimeRange === FilterOptions.CUSTOM) {
        lastRunTimeStartDate = startDate;
        lastRunTimeEndDate = endDate;
      } else {
        const { runTimeStartDate, runTimeEndDate } = getRunTimeRangeDates(lastRunTimeRange, timeZone);

        lastRunTimeStartDate = runTimeStartDate;
        lastRunTimeEndDate = runTimeEndDate;
      }

      queryParams.dateRange = {
        lastModified: {
          gt: formatDateToISOStandard(lastRunTimeStartDate),
          lt: formatDateToISOStandard(lastRunTimeEndDate),
        },
      };
    }

    if (searchValue) {
      queryParams.filters.searchInput = searchValue;
    }

    if (statusValues?.length > 0) {
      queryParams.filters.instanceStatus = statusValues;
    }

    if (ipAddressValues?.length > 0) {
      queryParams.filters.ipAddress = ipAddressValues;
    }

    return queryParams;
  };
}

export default ExperimentsViewModel;
