import React from 'react';
import { IconButton, Modal } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, ModalHead } from '@/constants/LabsConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { RegressionTestPassResultList } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultList';

import 'bootstrap/dist/css/bootstrap.css';
import '@/styles/Labs.css';

export const TestResultDetails = (props: any) => {
  const detasilsTitleId = useId('toolkitResultDetailsTitle');
  const cancelIcon = { iconName: SystemIcons.CANCEL };

  return (
    <div>
      <Modal
        titleAriaId={detasilsTitleId}
        isOpen={props.toolkitResultDetailsModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        className="labs-wrapper rounded"
        onDismiss={props.handleResultDetailsModalClose}
      >
        <div className={contentStyles.header}>
          <h5>{ModalHead.toolkitResultDetails}</h5>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.handleResultDetailsModalClose}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <b>Path</b>
              </div>
              <div className="col-sm-8">
                {props.pathSpinner && <LoadingSpinner />}
                <span>{(props.resultDetails as RegressionTestPassResultList).Path}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
