import React from 'react';
import Moment from 'react-moment';
import { Icon, TooltipHost } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

import { HealthDiffInMins, Labels } from '@/constants/LabsConstants';

import '@/styles/Labs.css';

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const populateHealthStatus = (item: any, location: any, styles?: any) => {
  const currentDate = new Date().getTime();
  const heartbeat = new Date(`${item}Z`).getTime();
  const diffInMs = Math.abs(currentDate - heartbeat) / 60000;
  let labHealth;
  let labHealthstr;
  let labStatusIcon;

  if (item === null || item === undefined) {
    labHealth = Labels.unhealthy;
    labHealthstr = Labels.labUnhealthy;
    labStatusIcon = Labels.statusErrorFull;
  } else if (diffInMs <= HealthDiffInMins.warning) {
    labHealth = Labels.healthy;
    labHealthstr = Labels.labHealthy;
    labStatusIcon = Labels.skypeCircleCheck;
  } else if (diffInMs <= HealthDiffInMins.unhealthy) {
    labHealth = Labels.warning;
    labHealthstr = Labels.labWarning;
    labStatusIcon = Labels.skypeCircleCheck;
  } else {
    labHealth = Labels.unhealthy;
    labHealthstr = Labels.labUnhealthy;
    labStatusIcon = Labels.statusErrorFull;
  }

  return (
    <TooltipHost
      content={
        item === null || item === undefined ? (
          <span>
            {Labels.lastHeartbeat}
            {Labels.na}
          </span>
        ) : (
          <span>
            {Labels.lastHeartbeat}
            <Moment format="MMM Do YY, h:mm a">{new Date(`${item}Z`)}</Moment>
          </span>
        )
      }
      id={`tooltip${item}`}
      styles={hostStyles}
    >
      {styles ? (
        <p className="status pointer" aria-describedby="tooltip">
          {location === 'main' ? Labels.labHealth : null}
          <Icon iconName={labStatusIcon} className={`${styles[labHealthstr as string]}`} />
          <b className={`${styles[labHealthstr as string]}`}>{labHealth}</b>
        </p>
      ) : (
        <p className="status pointer" aria-describedby="tooltip">
          {location === 'main' ? Labels.labHealth : null}
          <Icon iconName={labStatusIcon} className={labHealth} />
          <b className={labHealthstr}>{labHealth}</b>
        </p>
      )}
    </TooltipHost>
  );
};

export const validateIPAddress = (ip: string) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ip,
    )
  ) {
    return true;
  }

  return false;
};
