import React from 'react';
import { CsvToHtmlTable } from 'react-csv-to-table';
import JSONPretty from 'react-json-pretty';
import { DefaultButton, IconButton, Modal, SpinnerSize } from '@fluentui/react';
import { Card, Collapse, Space, Table, Tooltip } from 'antd';
import saveAs from 'file-saver';
import { t } from 'i18next';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { TagComponent } from '@/components/_labs/ResultTriage/TagComponent';
import { SystemIcons } from '@/constants/IconConstants';
import { Labels, Messages, TableHead } from '@/constants/LabsConstants';
import { MimeType, Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import './MyPanel.css';

export const ExperimentInsights = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState('');
  const [selectedFilePath, setSelectedFilePath] = React.useState('');
  const [modalContentLoading, setModalContentLoading] = React.useState(true);
  const [content, setContent] = React.useState<any>();
  const [ruleAnalyserResult, setRuleAnalyserResult] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const failGroupId = 'Experiment-insights-fail';
  const fileNameEndsWith = 'metrics.csv';

  React.useEffect(() => {
    setLoading(true);
    logManagementRequestService
      .getRuleAppliedResultsById(props.id.toString(), props.companyName)
      .then((response) => {
        formatData(response);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expInsightsRefreshed, props.id]);

  const formatData = (response) => {
    const invalidDesc = ['[NaN]', '["]'];
    const tempResponse = response;

    if (tempResponse !== '') {
      Object.keys(tempResponse.rules).forEach((ruleId) => {
        const ruleResult = [];
        let count = 0;

        tempResponse.rules[ruleId as string].ruleResults.forEach((item) => {
          count++;

          const keySplit = item.source.split('/');
          const parseFileName = keySplit[keySplit.length - 1];

          item.metric.forEach((metric) => {
            ruleResult.push({
              source: item.source,
              fileName: parseFileName,
              parsedLogId: item.parsedLogId,
              metricName: metric.name,
              metricValue: metric.value,
              message: [],
              description: invalidDesc.includes(metric.description) ? '' : formatMetricData(metric.description),
            });
          });
        });

        tempResponse.rules[ruleId as string].fileCount = count;
        tempResponse.rules[ruleId as string].ruleResults = ruleResult;
      });
      console.log(tempResponse);
      setRuleAnalyserResult(tempResponse);
    }

    setLoading(false);
  };

  const formatMetricData = (metricDescription) => {
    const descriptionLines = metricDescription
      .replace(/^[\\[\]]+|[\\[\]]+$/g, '')
      .trim()
      .split('||');
    const formattedDescription = descriptionLines.map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />} • {line.trim()}
      </React.Fragment>
    ));
    return <>{formattedDescription}</>;
  };

  // Columns for the nested table (inside the expandable row)
  const columns = [
    {
      title: 'Metric Name',
      key: 'metricName',
      dataIndex: 'metricName',
      width: '10%',
    },
    {
      title: 'Metric Value',
      key: 'metricValue',
      dataIndex: 'metricValue',
      width: '10%',
    },
    {
      title: 'Experiment Diagnostics Report',
      key: 'description',
      width: '55%',
      dataIndex: 'description',
    },
    {
      title: 'File Name',
      key: 'fileName',
      width: '15%',
      render: (record) => (
        <Tooltip
          overlayInnerStyle={{
            background: 'rgba(0, 0, 0, 1)',
          }}
          title={record.source}
        >
          <span>{record.fileName}</span>
        </Tooltip>
      ),
    },
    {
      title: TableHead.actions,
      key: 'action',
      width: '10%',
      render: (_text, record) => (
        <div className="results-table">
          <Space>
            {!record.fileName.endsWith(fileNameEndsWith) && (
              <IconButton
                iconProps={{ iconName: SystemIcons.VIEW_FILE }}
                title={Messages.viewFile}
                aria-label={Labels.viewFile}
                onClick={() => handleViewFile(record.source)}
              />
            )}
            <IconButton
              aria-label={Labels.download}
              title={Messages.downloadFile}
              iconProps={{ iconName: SystemIcons.DOWNLOAD }}
              onClick={() => handleDownloadFile(record.source, props.companyName)}
            />
          </Space>
        </div>
      ),
    },
  ];

  // Handle view file action
  const handleViewFile = (record) => {
    const keySplit = record.split('/');
    const key = keySplit[keySplit.length - 1];

    setSelectedFileName(key);
    setModalContentLoading(true);
    setIsModalVisible(true);

    setSelectedFilePath(record);
    logManagementRequestService.downloadLogFile(record, props.companyName).then((downloadedContent) => {
      setContent(downloadedContent);
      setModalContentLoading(false);
    });
  };

  // Handle download file action
  const handleDownloadFile = (record, id) => {
    setSelectedFilePath(record);
    downloadBlob();
  };

  const downloadBlob = () => {
    setDownloadLoading(true);
    logManagementRequestService
      .downloadLogFile(selectedFilePath, props.companyName)
      .then((res) => {
        setDownloadLoading(false);

        if (selectedFileName.endsWith('json')) {
          res = JSON.stringify(res);
        }

        const blob = new Blob([res], {
          type: MimeType.BINARY_FILE,
        });

        saveAs(blob, selectedFileName);
      })
      .catch((error) => {
        setDownloadLoading(false);

        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  };

  if (loading) {
    return <LoadingSpinner size={SpinnerSize.medium} />;
  }

  const title = `${t('view-file', { ns: NS.TITLES })} ${TITLE_DIVIDER} ${selectedFileName}`;

  return (
    <div className="result-analyzer">
      <Card loading={loading} style={SidePanelStyles.logView}>
        {!loading && ruleAnalyserResult && Object.keys(ruleAnalyserResult).length > 0 ? (
          <Collapse accordion>
            {Object.keys(ruleAnalyserResult.rules).length > 0 &&
              Object.keys(ruleAnalyserResult.rules).map((ruleId) => (
                <Collapse.Panel
                  key={ruleAnalyserResult.rules[ruleId as string].ruleId}
                  header={renderHeader(ruleAnalyserResult.rules[ruleId as string])}
                >
                  <Modal
                    className="ganymede-wrapper"
                    isOpen={isModalVisible}
                    titleAriaId="basicModal"
                    isBlocking={false}
                    containerClassName={contentStyles.min35Container}
                    onDismiss={() => setIsModalVisible(false)}
                  >
                    <div className={contentStyles.header}>
                      <h2 className={contentStyles.heading}>{title}</h2>
                      <IconButton styles={iconButtonStyles} iconProps={cancelIcon} onClick={() => setIsModalVisible(false)} />
                    </div>
                    <div className={contentStyles.body}>
                      <Card loading={modalContentLoading} className="results-table background text-color logs-data">
                        {selectedFileName.endsWith('.csv') ? (
                          <CsvToHtmlTable
                            data={content}
                            csvDelimiter=","
                            tableClassName="table table-striped table-hover background text-color"
                          />
                        ) : (
                          <JSONPretty id="json-pretty" data={content} className="background text-color" />
                        )}
                      </Card>
                      {!modalContentLoading && (
                        <DefaultButton title={t('download-file', { ns: NS.DEFAULT })} onClick={downloadBlob}>
                          {t('download-file', { ns: NS.DEFAULT })}
                        </DefaultButton>
                      )}
                    </div>
                  </Modal>
                  <Table dataSource={ruleAnalyserResult.rules[ruleId as string].ruleResults} columns={columns} pagination={false} />
                </Collapse.Panel>
              ))}
          </Collapse>
        ) : (
          'No data found'
        )}
      </Card>
    </div>
  );
};

const renderHeader = (ruleData) => {
  const headerData = {
    name: ruleData.ruleName,
    description: ruleData.description,
    fileCount: ruleData.fileCount,
    ruleExpression: ruleData.ruleExpression,
  };

  return (
    <div className="columns-3">
      <div>
        <b>
          {headerData.name} (File Count: {headerData.fileCount})
        </b>
      </div>
      <div>{headerData.description}</div>
      <TagComponent ruleExpression={headerData.ruleExpression} />
    </div>
  );
};
