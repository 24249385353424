import { CancelToken } from 'axios';

import { DataType } from '@/constants/SystemConstants';
import BaseRequestService from '@/services/request-services/RequestService';
import { labsBackendEndpoint } from '@/utils/Env';
class ExperimentRequestService extends BaseRequestService {
  constructor(baseURL = '') {
    super(baseURL);
  }

  async createExperimentStepsVersion2(labId: string, experimentJson: string, targetMachineIds: any, cancelToken?: CancelToken) {
    const systemIds =
      targetMachineIds.length > 1 || typeof targetMachineIds === DataType.OBJECT ? targetMachineIds.join(',') : targetMachineIds;

    const { data } = await this.post(`v2/experiments?labId=${Number(labId)}&machineIds=${systemIds}`, experimentJson, {
      cancelToken,
    });

    return data;
  }

  async createLabExperimentSteps(labId: string, experimentJson: string, cancelToken?: CancelToken) {
    const { data } = await this.post(`v2/experiments?labId=${Number(labId)}`, experimentJson, { cancelToken });

    return data;
  }

  async cancelExperimentForVersion2(experimentInstanceID: any, cancelToken?: CancelToken) {
    const { data } = await this.put(`v2/experiments/${experimentInstanceID}?cancel=true`, null, { cancelToken });

    return data;
  }
}

export const experimentRequestService = new ExperimentRequestService(labsBackendEndpoint);
