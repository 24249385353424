import React from 'react';
import { observer } from 'mobx-react-lite';

import { NavigationIcon } from '@/constants/NavigationConstants';
import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import NavLink from '@/partials/NavLinkItem/NavLinkItemViewController';
import { NavLinksFTType, NavLinkType } from '@/partials/NavLinks/NavLinksTypes';

import styles from './NavLinks.module.css';

const NavLinksTemplateFC: React.FC<NavLinksFTType> = (props: NavLinksFTType): React.ReactElement => {
  const { isSidebarCollapsed, location, level, children, rootLinks, onToggle, onClick } = props;

  return (
    <>
      {
        // Rootlinks are always displayed and not hidden.
        rootLinks.map((navLink: NavLinkItemType, i: number) => {
          const rootKey = navLink.key;
          const isDivider = navLink.type === NavLinkType.Divider;
          const iconName = NavigationIcon[rootKey as string];
          const rootChildren: string[] = navLink.children ? navLink.children : [];
          const hasChildren = rootChildren.length > 0;
          const childLinks: NavLinkItemType[] | undefined = hasChildren
            ? children.filter((child: NavLinkItemType) => rootChildren.includes(child.key))
            : undefined;

          if (isDivider) {
            return <div className={styles.divider} key={rootKey}></div>;
          }

          return (
            <NavLink
              location={location}
              link={navLink}
              links={children}
              childLinks={childLinks}
              key={rootKey}
              endpoint={rootKey}
              iconName={iconName}
              level={level}
              isSidebarCollapsed={isSidebarCollapsed}
              onToggle={onToggle}
              onClick={onClick}
            ></NavLink>
          );
        })
      }
    </>
  );
};

const NavLinksTemplate = observer(NavLinksTemplateFC);

export default NavLinksTemplate;
