/*----------------------------------------------------------------------------------------------------------------------------
# ReactGridTemplate.tsx: 
# This Component is our Dashboards Component. 
# This is the top-most Component when the user visits the "/dashboards" route.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { observer } from 'mobx-react-lite';

import { ReactGridViewType } from './ReactGridTypes';

import './ReactGrid.css'; // NOTE: To be phased out.

const ReactGridLayout = WidthProvider(RGL);

const ReactGridTemplateFC: React.FC<ReactGridViewType> = (props: ReactGridViewType): React.ReactElement => {
  const { isEditMode, allowOverlap, isBounded, rows, cols, children, compactType, layout, onDrop, onDropDragOver, onLayoutChange } =
    props;

  return (
    <div>
      <ReactGridLayout
        className="layout"
        rowHeight={rows}
        maxRows={rows}
        cols={cols}
        compactType={compactType}
        onDrop={onDrop}
        onDropDragOver={onDropDragOver}
        onLayoutChange={onLayoutChange}
        allowOverlap={allowOverlap}
        isBounded={isBounded}
        isDroppable={isEditMode}
        isDraggable={isEditMode}
        isResizable={isEditMode}
        layout={layout}
      >
        {children}
      </ReactGridLayout>
    </div>
  );
};

const ReactGridTemplate = observer(ReactGridTemplateFC);

export default ReactGridTemplate;
