/*----------------------------------------------------------------------------------------------------------------------------
# MessagePageTemplate.tsx: 
# This Component takes the HTML passed to it by its calling element, and passes that content to the Container element. 
# (React takes that HTML and stores it into the children property, found in "this.props.children".)
# This page is our topmost Layout page, and the highest level where HTML and styling will occur. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { PartialTheme } from '@fluentui/react';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import FooterViewController from '@/layouts/Footer/FooterViewController';
import FooterViewModel from '@/layouts/Footer/FooterViewModel';
import Header from '@/layouts/Header/HeaderTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { MessagePageVCType } from './MessagePageTypes';

import Logo from '@/images/crc-logo.png';

import styles from './MessagePage.module.css';

const MessagePageTemplateFC: React.FC<MessagePageVCType> = (props: MessagePageVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const footerViewModel = new FooterViewModel(rootStore);

  const theme: typeof PartialTheme = props.theme;
  const message: string = props.message;

  return (
    <div className={`${styles['layout']}`}>
      <div className={styles.topnav}>
        <Header />
      </div>
      <div className={styles.main}>
        <Container className={`fullscreen ${styles['full-container']}`} fluid>
          <div className={`fullscreen row align-items-md-stretch ${styles['centered']}`}>
            <div className={`${styles['application']}`}>
              <p className="text-center">
                <img className={styles['logo']} src={Logo} alt={t('ms-logo', { ns: NS.DEFAULT })} />
              </p>
              <div className="text-center">{message}</div>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.footer}>
        <FooterViewController viewModel={footerViewModel} />
      </div>
    </div>
  );
};

const MessagePageTemplate = observer(MessagePageTemplateFC);

export default MessagePageTemplate;
