import { MessageBarType } from '@fluentui/react';
import ls from 'local-storage';

import { SystemMessageType } from '@/types/SystemMessageTypes';

export const HandleError = (props) => {
  const { error, systemMessageStore, appSettingsStore, failGroupId = '', showInPopup = false } = props;

  const { isDebugMode } = appSettingsStore;
  const { addGlobalMessage } = systemMessageStore;

  // added try catch block to handle the case when response doesn't contain some extensions and it was throwing the error on local
  isDebugMode && console.error(error);

  const addFailMessage = (message: string) => {
    const failMessage: SystemMessageType = {
      message,
      type: MessageBarType.error,
      groupId: failGroupId,
      showInPopup,
    };

    addGlobalMessage(failMessage);
  };

  try {
    if (error.response?.data?.length > 0) {
      addFailMessage(`${error.response.status} : ${error.response.data}`);
    } else if (error.response?.statusText?.length > 0) {
      addFailMessage(`${error.response.status} : ${error.response.statusText}`);
    } else if (error.message?.length > 0) {
      addFailMessage(`${error.message}`);
    } else {
      addFailMessage(error);
    }
  } catch (ex) {
    console.error(`Notification Failed on Error: ${ex}`); // logging exception message on console
  }
};

export const initLogin = () => {
  const userTheme = (ls as any).get('userTheme');

  (ls as any).clear();

  if (userTheme !== null) {
    (ls as any).set('userTheme', userTheme);
  }
};
