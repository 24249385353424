import React from 'react';
import { observer } from 'mobx-react-lite';

import pageContentStyles from '@/partials/PageContent/PageContentStyles';
import { PageContentType } from '@/partials/PageContent/PageContentTypes';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';

import styles from './PageContent.module.css'; // Apply any Navigation level CSS Module styling here.

const PageContentFC: React.FC<PageContentType> = (props: PageContentType): React.ReactElement => {
  const { children, scrollable, scrollableX, componentStyles, handleScroll } = props;

  // Apply any custom Component styles.
  const rootStyle = componentStyles ? componentStyles : pageContentStyles.defaultPage;

  return (
    <div className={styles['content']} style={rootStyle}>
      <ScrollableContent scrollable={scrollable} scrollableX={scrollableX} handleScroll={handleScroll}>
        {children}
      </ScrollableContent>
    </div>
  );
};

const PageContent = observer(PageContentFC);

export default PageContent;
