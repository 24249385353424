import React from 'react';
import { observer } from 'mobx-react-lite';
import { MessageBarType } from '@fluentui/react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { t } from 'i18next';

import { Messages } from '@/constants/LabsConstants';
import { FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import { SidePanelUserSettingsType } from '@/partials/SidePanel/SidePanelTypes';
import UploadFilePanelTemplate from '@/partials/UploadFilePanel/UploadFilePanelTemplate';
import { ganymedeFirmwareRequestService } from '@/services/request-services/FirmwareRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { FilePathType } from './UploadPackageTypes';

import '@/styles/Labs.css';

const UploadPackagesFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const { isPanelOpen, closePanel } = props;

  const [siName, setSIName] = React.useState<string>('');
  const [programName, setProgramName] = React.useState<string>('');
  const [componentName, setComponentName] = React.useState<string>('');
  const [FWFiles, setFWFiles] = React.useState<File[]>([]);
  const [fileNames, setFileNames] = React.useState<string[]>([]);
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const hiddenFileInput = React.useRef<any>(null);

  const title = t('upload-fw-file', { ns: NS.TITLES });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const isRequiredData = () => {
    if (siName === '' || programName === '' || componentName === '' || fileNames.length === 0) {
      return false;
    }

    return true;
  };

  const uploadFile = (e: any) => {
    e.preventDefault();

    const filesToAdd = e.target.files;

    setFWFiles([...FWFiles, ...filesToAdd]);
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const displayFileNames = React.useCallback(() => {
    const names: string[] = [];

    for (let i = 0; i < FWFiles.length; i++) {
      names.push(FWFiles[i as number].name);
    }

    setFileNames(names);
  }, [FWFiles]);

  React.useEffect(() => {
    displayFileNames();
  }, [displayFileNames, FWFiles]);

  const handleLocalDelete = React.useCallback(
    (idx: number) => {
      if (fileNames.length > 0) {
        const names = [...fileNames];
        const files = [...FWFiles];

        names.splice(idx, 1);
        files.splice(idx, 1);
        setFileNames(names);
        setFWFiles(files);
      }
    },
    [fileNames, FWFiles],
  );

  const handleUpload = async (evt: any) => {
    const filePath: FilePathType = {
      si: siName.trim(),
      program: programName.trim(),
      component: componentName.trim(),
    };

    evt.preventDefault();
    setBusy(true);

    if (!isRequiredData()) {
      const failMessage: SystemMessageType = {
        message: Messages.requireDetails,
        type: MessageBarType.error,
        groupId: FailGroupIds.UPLOAD_PACKAGE,
      };
      addGlobalMessage(failMessage);
      setBusy(false);

      return;
    }

    const CHUNK_SIZE = 4 * 1024 * 1024; // 4 MB (you can adjust this as needed)
    let count = 0;

    for (const file of FWFiles) {
      const TOTAL_CHUNKS = Math.ceil(file.size / CHUNK_SIZE);
      let currentChunk = 0;

      while (currentChunk < TOTAL_CHUNKS) {
        const start = currentChunk * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);

        const chunk = file.slice(start, end);
        const formData = new FormData();

        formData.append('file', chunk, file.name);
        formData.append('filepath', JSON.stringify(filePath));

        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await ganymedeFirmwareRequestService.uploadFirmware(formData);

          const percentage = ((currentChunk + 1) / TOTAL_CHUNKS) * 100;

          if (percentage === 100) {
            count++;
            const successMessage: SystemMessageType = {
              message: count + Messages.successfullyFileUploaded,
              type: MessageBarType.success,
              groupId: SuccessGroupIds.UPLOAD_PACKAGE,
            };
            addGlobalMessage(successMessage);
          }

          setUploadPercentage(percentage);
        } catch (error) {
          console.error('Error uploading chunk:', error);
        }

        currentChunk++;
      }

      setUploadPercentage(0);
    }

    setBusy(false);
  };

  const userSettings: SidePanelUserSettingsType = {
    headerText: t('upload-files', { ns: NS.COMMON }),
    type: PanelType.medium,
    isOpen: isPanelOpen,
    onDismiss: closePanel,
  };

  const content = (
    <UploadFilePanelTemplate
      isBusy={isBusy}
      uploadPercentage={uploadPercentage}
      siName={siName}
      programName={programName}
      componentName={componentName}
      fileNames={fileNames}
      handleClick={handleClick}
      uploadFile={uploadFile}
      handleUpload={handleUpload}
      handleLocalDelete={handleLocalDelete}
      setSIName={setSIName}
      setProgramName={setProgramName}
      setComponentName={setComponentName}
      isRequiredData={isRequiredData}
      hiddenFileInput={hiddenFileInput}
    ></UploadFilePanelTemplate>
  );

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

export const UploadPackages = observer(UploadPackagesFC);
