/* eslint-disable indent */
import React from 'react';
import { Icon, TooltipHost } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';

import { Labels, Messages, TableHead } from '@/constants/LabsConstants';
import { notificationService } from '@/services/_labs/NotificationService';
import { regressionTestPassRequestService } from '@/services/_labs/request-services/RegressionTestPassRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { Bugs } from '@/types/_labs/RegressionTestPass/Bugs';
import { RegressionTestPassResultList } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultList';
import { RegressionTestPassResultRequestFilters } from '@/types/_labs/RegressionTestPass/RegressionTestPassResultRequestFilters';
import { RegressionTestPassSignOffResult } from '@/types/_labs/RegressionTestPass/RegressionTestPassSignOffResult';
import { HandleError } from '@/utils/_labs/HandleError';
import { copyToClipboard } from '@/utils/Helpers';

import 'bootstrap/dist/css/bootstrap.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '@/styles/Labs.css';

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const TestResultTable = (props: any) => {
  const tooltipId = useId('tooltip');
  const [expandedRows, setExpandedRows] = React.useState();
  const toast = React.useRef(null);
  let loadLazyTimeout: any = null;
  const [loading, setLoading] = React.useState(false);
  const [lazyParams, setLazyParams] = React.useState({
    first: 0,
    rows: 500,
    page: 1,
  });
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;

  const rowExpansionTemplate = (item: RegressionTestPassResultList) => {
    const bugLength = item.Bugs === null ? 0 : item.Bugs.length;

    return (
      <div className="orders-subtable">
        {item.Bugs !== null && (
          <>
            <div className="form-inline">
              <h6>
                <Icon iconName="Bug" />
              </h6>
              <h6 className="ml-2">Bugs ({bugLength})</h6>
            </div>
            <div className="ml-4">
              <DataTable stripedRows showGridlines value={item.Bugs} size="small" responsiveLayout="scroll" className="bug-table">
                <Column style={{ width: '4%' }} field="BugId" header="Id" body={bugURLBodyTemplate} />
                <Column style={{ width: '30%' }} field="BugTitle" header="Title" />
              </DataTable>
            </div>
          </>
        )}
        {item.Notes !== '' && (
          <>
            <div className="form-inline mt-3">
              <h6>
                <Icon iconName="Comment" />
              </h6>
              <h6 className="ml-2">Notes</h6>
            </div>
            <div className="ml-4 notes-panel">{item.Notes}</div>
          </>
        )}
        <div className="form-inline mt-3">
          <h6>
            <Icon iconName="Info" />
          </h6>
          <h6 className="ml-2">Info</h6>
        </div>
        <div className="ml-4 info-panel">
          <div className="form-group row">
            <span className="ml-2">Creation Time :</span>
            <span className="ml-4">{item.TestPassCreationTime}</span>
          </div>
        </div>
        <div className="ml-4 info-panel">
          <div className="form-group row">
            <span className="ml-2">Path :</span>
            <TooltipHost content={Messages.copyPathToClipBoard} id={tooltipId} styles={hostStyles}>
              <span className="clickable ml-3" role="button" tabIndex={0} onClick={(e: any) => copyPathToClipBoard(e, item)}>
                {item.Path}
              </span>
            </TooltipHost>
          </div>
        </div>
        <div className="ml-4 info-panel">
          <div className="form-group row">
            <span className="ml-2">Signed off by :</span>
            <span className="ml-4">{item.SignOffUser}</span>
          </div>
        </div>
      </div>
    );
  };

  const signOffBodyTemplate = (item: any, options: any) => {
    const isQaasLabUser = props.isQaasLabUser;
    const signOffBody: any =
      item.Color === Labels.green
        ? item.SignOffUser === Labels.automatedSignOff
          ? { content: Messages.automatedSignedOff, class: 'p-button-warning' }
          : { content: Messages.alreadySignedOff, class: 'p-button-success' }
        : item.Color === Labels.red
        ? { content: Messages.requireSignOff, class: 'p-button-danger' }
        : item.Color === Labels.gray
        ? { content: Messages.optionalSignOff, class: 'p-button-secondary' }
        : null;

    return (
      <>
        {isQaasLabUser ? (
          <TooltipHost content={signOffBody.content} id={tooltipId} styles={hostStyles}>
            <Button
              icon="pi pi-power-off"
              className={`p-button-sm p-button-rounded ${signOffBody.class} p-button-text p-button-icon-only`}
              iconPos="right"
              onClick={() => handleSignOffResult(item, options.rowIndex)}
            />
          </TooltipHost>
        ) : (
          <div className="text-center">
            <i
              className={
                item.Color === Labels.green
                  ? item.SignOffUser === Labels.automatedSignOff
                    ? 'pi pi-check-circle text-warning'
                    : 'pi pi-check-circle text-success'
                  : item.Color === Labels.red
                  ? 'pi pi-times-circle text-danger'
                  : item.Color === Labels.gray
                  ? 'pi pi-times-circle text-secondary'
                  : ''
              }
            />
          </div>
        )}
      </>
    );
  };

  const resultBodyTemplate = (item: RegressionTestPassResultList) => (
    <span className={item.Result === Labels.pass ? 'text-success' : 'text-danger'}>{item.Result}</span>
  );

  const testOwnerBodyTemplate = (item: RegressionTestPassResultList) => (
    <>
      <span className="mr-3">{item.TestOwner}</span>
      <span>{item.BackupOwner}</span>
    </>
  );

  const bugBodyTemplate = (item: RegressionTestPassResultList) => {
    const bugLength = item.Bugs === null ? 0 : item.Bugs.length;

    return (
      <>
        <span className="fs-large">{bugLength}</span>
        <span className="ml-2">
          <Icon iconName="BugSolid" className={`${bugLength > 0 && 'text-danger'}`} />
        </span>
      </>
    );
  };

  const notesBodyTemplate = (item: RegressionTestPassResultList) => (
    <div className="text-center">
      <i className={item.Notes !== '' ? 'pi pi-check' : 'pi pi-times'} />
    </div>
  );

  const bugURLBodyTemplate = (item: Bugs) => (
    <a
      href={`https://msazure.visualstudio.com/One/_workitems/edit/${item.BugId}/`}
      target="_blank"
      rel="noopener noreferrer"
      className="clickable"
    >
      <span>{item.BugId}</span>
    </a>
  );

  const testSuiteBodyTemplate = (item: RegressionTestPassResultList) => (
    <a
      href={`https://msazure.visualstudio.com/One/_git/CSI-Automation-Toolkit?version=GBdevelop&path=/src/CSIToolKit/TestController/TestSuites/Regression/${item.TestSuite}.ps1`}
      target="_blank"
      rel="noopener noreferrer"
      className="clickable"
    >
      <span>{item.TestSuite}</span>
    </a>
  );

  const testNameBodyTemplate = (item: RegressionTestPassResultList) => (
    <span className="clickable" role="button" tabIndex={0} onClick={(e: any) => props.viewLogs(e, item)}>
      {item.TestName}
    </span>
  );

  const copyPathToClipBoard = (evt: any, item: RegressionTestPassResultList) => {
    evt.preventDefault();
    copyToClipboard(item.Path);
    notificationService.showSuccess(`${Messages.pathCopyClipboard} - ${item.Path}`);
  };

  const handleResultDetailsModalShow = (item: RegressionTestPassResultList) => {
    props.setResultDetails(item);
    props.settoolkitResultDetailsModal(true);
  };

  const handleSignOffResult = (item: RegressionTestPassResultList, index: number) => {
    populateSignOffDropdown();
    props.setResultDetails(item);
    props.setTableIndex(index);
    fetchSignOffResults(item);
  };

  const fetchSignOffResults = React.useCallback(
    async (request: RegressionTestPassResultList) => {
      props.setTableSpinner(true);
      await regressionTestPassRequestService
        .getSignOffResultData(request)
        .then((result: RegressionTestPassSignOffResult) => {
          if (result.SignOffUser !== undefined) {
            props.setSelectedSignOffDropdown(result.SignOffResult);

            const bugs = result.BugId.split('\\');

            if (bugs.length > 1) {
              props.setBugId(bugs[0]);
              props.setNewBugId(bugs);
            } else {
              props.setBugId(result.BugId);
              props.setNewBugId([]);
            }

            props.setSignOffOwner(result.SignOffUser);
            props.setSignOffNotes(result.SignOffNotes);
          } else {
            props.setBugId('');
            props.setNewBugId([]);
            props.setSelectedSignOffDropdown(Labels.yes);
            props.setSignOffOwner(props.userName);
            props.setSignOffNotes('');
          }
        })
        .catch((error: any) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
          };

          HandleError(handleErrorProps);
        })
        .finally(() => {
          props.setTableSpinner(false);
          props.setSignOffResultModal(true);
        });
    },
    [props],
  );

  const populateSignOffDropdown = () => {
    const options: any = [];

    options.push({ key: Labels.yes, text: Labels.yes });
    options.push({ key: Labels.no, text: Labels.no });

    props.setSignOffDropdownOptions(options);
  };

  const onPage = (event: any) => {
    setLazyParams(event);
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    const filters = props.pageFilters as RegressionTestPassResultRequestFilters;

    filters.Id = event.first;

    loadLazyTimeout = setTimeout(() => {
      fetchPageResultList(filters);
    }, Math.random() * 1000 + 250);
  };

  const fetchPageResultList = React.useCallback(async (filters: RegressionTestPassResultRequestFilters) => {
    await regressionTestPassRequestService
      .getFilteredResultList(filters)
      .then((result: any) => {
        props.setResultList(result.data.ResultList);
        props.setReleaseDetails(result.releaseDetails);
        setLoading(false);
      })
      .catch((error: any) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  React.useEffect(() => {
    const expanderButtons = document.querySelectorAll('.p-row-toggler.p-link');

    expanderButtons.forEach((button) => {
      button.setAttribute('aria-label', Labels.expandCollapseRow);
    });
  });

  return (
    <div className="card">
      <Toast ref={toast} />
      <div className="data-table-wrapper">
        <DataTable
          lazy
          stripedRows
          autoLayout
          paginator
          aria-busy
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={props.totalRecords}
          value={props.resultList}
          dataKey="Id"
          expandedRows={expandedRows}
          loading={loading}
          rowExpansionTemplate={rowExpansionTemplate}
          onPage={onPage}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
        >
          <Column expander style={{ width: '3em' }} />
          <Column field="TestOwner" header="Test owner" body={testOwnerBodyTemplate} />
          <Column field="TestSuite" header="SKU" body={testSuiteBodyTemplate} />
          <Column field="TestName" header="Test Name" body={testNameBodyTemplate} />
          <Column field="Processor" header="Processor" />
          <Column field="TestPlan" header="TestPlan" />
          <Column field="Result" header="Result" body={resultBodyTemplate} />
          <Column header="Bugs" body={bugBodyTemplate} />
          <Column header="Notes" body={notesBodyTemplate} />
          <Column header={`${props.isQaasLabUser ? TableHead.signOff : TableHead.signedOff}`} body={signOffBodyTemplate} />
        </DataTable>
      </div>
    </div>
  );
};
