/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import '@/styles/Labs.css';

export const searchDocumentationTable = () => (
  <table aria-label="Advanced Query Operators" className="table table-sm">
    <thead>
      <tr>
        <th>Character</th>
        <th>Example</th>
        <th>Usage</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <code>+</code>
        </td>
        <td>
          <code>pool + ocean</code>
        </td>
        <td>
          An AND operation. For example, <code>pool + ocean</code> stipulates that a document must contain both terms.
        </td>
      </tr>
      <tr>
        <td>
          <code>|</code>
        </td>
        <td>
          <code>pool | ocean</code>
        </td>
        <td>
          An OR operation finds a match when either term is found. In the example, the query engine will return match on documents
          containing either <code>pool</code> or <code>ocean</code> or both. Because OR is the default conjunction operator, you
          could also leave it out, such that <code>pool ocean</code> is the equivalent of <code>pool | ocean</code>.
        </td>
      </tr>
      <tr>
        <td>
          <code>-</code>
        </td>
        <td>
          <code>pool – ocean</code>
        </td>
        <td>
          A NOT operation returns matches on documents that exclude the term. <p />
          <p />
        </td>
      </tr>
      <tr>
        <td>
          <code>*</code>
        </td>
        <td>
          <code>lingui*</code> will match on "linguistic" or "linguini"
        </td>
        <td>
          The asterisk (<code>*</code>) represents one or more characters of arbitrary length, ignoring case.
        </td>
      </tr>
    </tbody>
  </table>
);
