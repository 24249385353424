import { debounce } from 'lodash';

const useDebouncedOnChange = (timeInterval: number) => {
  return debounce((e, onChange, selectedOptions) => {
    if (onChange) {
      onChange(e as React.SyntheticEvent, selectedOptions);
    }
  }, timeInterval);
};

export { useDebouncedOnChange };
