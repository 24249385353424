import React from 'react';
import { Table } from 'reactstrap';
import { IconButton, Modal } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, TableHead } from '@/constants/LabsConstants';

import { contentStyles, iconButtonStyles } from '../Modal';

export const SocInfoComponent = (props: any) => {
  const socInfoTitleId = useId('socInfoTitle');
  const cancelIcon = { iconName: SystemIcons.CANCEL };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={socInfoTitleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.container}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>{props.title}</h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <Table borderless className="mt-3">
            <tbody>
              <tr className="mb-2 pl-3 pr-3">
                <td>
                  <b>{TableHead.socIPAddress}</b>
                </td>
                <td>{props.machineInfo.SocIPAddress}</td>
              </tr>
              <tr className="mb-2 pl-3 pr-3">
                <td>
                  <b>{TableHead.socMACAddress}</b>
                </td>
                <td>{props.machineInfo.SocMACAddress}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
    </div>
  ) : null;
};
