import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Icon } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import * as Ls from 'local-storage';

import { Tooltip } from '@/components/_air/common';
import { IconNames, Labels, Messages, NotificationMessages, Routes, TableHeaders } from '@/constants/AirConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { experimentResultRequestService } from '@/services/_air/request-services';
import { WorkloadProfile } from '@/types/_air';
import { getClassName, getWorkloadProfileScoreArr } from '@/utils/_air/DashboardScore';
import { processApiError } from '@/utils/_air/ErrorHandler';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import { BarGraph } from './BarGraph';

import '@/styles/Air.css';
import './result.css';

export const WorkloadProfileInfo = (): JSX.Element => {
  const {
    profile: workloadProfile,
    experiment: profileExperimentName,
    friendly: workloadProfileFriendly,
    revision,
  } = useParams<{
    experiment: string;
    profile: string;
    friendly: string;
    revision: string;
  }>();

  const [profileData, setProfileData] = React.useState<Element[]>();
  const [workloadProfileData, setWorkloadProfileData] = React.useState<WorkloadProfile>();
  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [selectedItem, toggleItem] = React.useState<number>(-1);
  const [dataExist, setDataExist] = React.useState<boolean>(true);
  const cancellationToken = useCancellationToken();

  const tooltipId = useId('tooltip');
  const revisionStr = revision.includes('/') ? revision.replaceAll('/', 'slash') : revision;
  const arrayPercentData: any[] = [];

  window.onbeforeunload = (): void => {
    Ls.remove(workloadProfile as string);
  };

  React.useEffect(() => {
    const lsWorkloadProfile: any = Ls.get(workloadProfile as string);

    if (lsWorkloadProfile !== null) {
      setWorkloadProfileData(lsWorkloadProfile);
      separateScore(lsWorkloadProfile);
      populateData(lsWorkloadProfile);

      return;
    }

    experimentResultRequestService
      .getWorkloadProfileInfo(profileExperimentName, workloadProfile, revisionStr, cancellationToken)
      .then((result: any) => {
        if (isAPIError(result) || result.workloads.length === 0) {
          setDataExist(false);

          return;
        }

        Ls.set(workloadProfile as string, result);
        setWorkloadProfileData(result);
        separateScore(result);
        populateData(result);
      })
      .catch((error: any) => {
        processApiError(error, NotificationMessages.errorOnReceivingWorkloadProfileInfo);
      })
      .finally(() => {
        setBusy(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (workloadProfileData && workloadProfileData.workloads.length !== 0) {
      separateScore(workloadProfileData);
      populateData(workloadProfileData);
    }
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const separateScore = (data: WorkloadProfile): void => {
    for (let i = 0; i < data.workloads.length; i++) {
      const percentile = getWorkloadProfileScoreArr(data.workloads[i as number].metrics);
      const eachObjData: any = {};

      eachObjData.experimentName = profileExperimentName;
      eachObjData.workloadProfileName = data.workloadProfileName;
      eachObjData.experimentUnits = data.workloads[i as number].experimentUnits;
      eachObjData.workloadName = data.workloads[i as number].workloadName;
      eachObjData.ConfidenceScores = percentile;
      eachObjData.testedInstance = data.workloads[i as number].testedInstance;
      arrayPercentData.push(eachObjData);
    }
  };

  const openItem = (index: number): void => {
    toggleItem(selectedItem === index ? -1 : index);
  };

  const populateData = (Profile: WorkloadProfile): void => {
    const data = Object.keys(Profile.workloads).map((item: string, index: number) => (
      <tbody key={index}>
        <tr key={`tr-${index}`}>
          <td className="font-weight-bold">
            <Link
              to={{
                pathname: `${Routes.workloadInfo.link}/${profileExperimentName}/${workloadProfile}/${workloadProfileFriendly}/${
                  (Profile.workloads as { [index: string]: any })[item as string].workloadName
                }/${revision}/${(Profile.workloads as { [index: string]: any })[item as string].testedInstance}`,
              }}
            >
              {(Profile.workloads as { [index: string]: any })[item as string].workloadName}
            </Link>
          </td>
          {(Profile.workloads as { [index: string]: any })[item as string].testedInstance.length > 50 ? (
            <td>
              <Tooltip content={(Profile.workloads as { [index: string]: any })[item as string].testedInstance} id={tooltipId}>
                <span aria-describedby={tooltipId} className="text">{`${(Profile.workloads as { [index: string]: any })[
                  item as string
                ].testedInstance.substring(0, 49)} ...`}</span>
              </Tooltip>
            </td>
          ) : (
            <td>{(Profile.workloads as { [index: string]: any })[item as string].testedInstance}</td>
          )}
          <td>{(Profile.workloads as { [index: string]: any })[item as string].experimentUnits}</td>
          <td>
            {Object.keys(arrayPercentData).map((percentData: string, percentDataIndex: number) => {
              if (
                (arrayPercentData as { [index: string]: any })[percentData as string].workloadName ===
                  (Profile.workloads as { [index: string]: any })[item as string].workloadName &&
                (arrayPercentData as { [index: string]: any })[percentData as string].testedInstance ===
                  (Profile.workloads as { [index: string]: any })[item as string].testedInstance
              ) {
                return (
                  <BarGraph
                    key={percentDataIndex}
                    percentData={(arrayPercentData as { [index: string]: any })[percentData as string].ConfidenceScores}
                    experimentName={profileExperimentName}
                    workloadProfileName={workloadProfile}
                    workloadProfileFriendly={workloadProfileFriendly}
                    workloadName={(Profile.workloads as { [index: string]: any })[item as string].workloadName}
                    revision={revision}
                  />
                );
              }

              return null;
            })}
          </td>
          <td>
            <Icon
              iconName={selectedItem === index ? IconNames.chevronUp : IconNames.chevronDown}
              className="ChevronDown clickable"
              onClick={() => {
                openItem(index);
              }}
            />
          </td>
        </tr>
        <tr key={`item-${index}`} className={`item ${+selectedItem === index ? 'open' : ''}`}>
          <td className="subset" colSpan={5}>
            <Table striped className="text-left">
              <thead className="text-nowrap">
                <tr>
                  <th>{TableHeaders.testBenchmarks}</th>
                  <th>{TableHeaders.metricCount}</th>
                  <th className="margin-left">{TableHeaders.confidenceScores}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys((Profile.workloads as { [index: string]: any })[item as string].metrics).map(
                  (metric: string, metricIndex: number) => (
                    <tr key={metricIndex}>
                      <td>
                        {(Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string].metricName}
                      </td>
                      <td>
                        {Object.keys(
                          (Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string].experimentUnits,
                        ).map((experimentUnit: string, experimentUnitIndex: number) => (
                          <span key={experimentUnitIndex}>
                            {`${
                              (Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string]
                                .experimentUnits[experimentUnit as string].groupId
                            }: ${
                              (Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string]
                                .experimentUnits[experimentUnit as string].experimentUnits
                            }   `}
                          </span>
                        ))}
                      </td>
                      <td
                        className={getClassName(
                          (Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string].confidenceScore
                            .color,
                        )}
                      >
                        <b className="text-light">
                          {`${(
                            (Profile.workloads as { [index: string]: any })[item as string].metrics[metric as string]
                              .confidenceScore.score * 100
                          ).toFixed(2)} %`}
                        </b>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </td>
        </tr>
        <tr />
      </tbody>
    ));

    setProfileData(data as any[]);
    setBusy(false);
  };

  return dataExist ? (
    <div className="air-wrapper">
      <div className="result-container">
        <h4 className="text-left all-in-one">
          <Link to={{ pathname: Routes.experimentsPage.link }} className="breadcrumbs workload-links">
            {Labels.experiments}
          </Link>
          {TITLE_DIVIDER}
          <Link
            to={{ pathname: `${Routes.scenario.link}/${profileExperimentName}/${revision}` }}
            className="breadcrumbs workload-links links-left-margin"
          >
            {`${profileExperimentName} (${revision})`}
          </Link>
          {`/ ${workloadProfileFriendly}`}
        </h4>
      </div>
      <br />
      {isBusy ? (
        <LoadingSpinner />
      ) : (
        <Table striped className="text-left result-table">
          <thead className="text-nowrap">
            <tr>
              <th>{TableHeaders.testBenchmarks}</th>
              <th>{TableHeaders.experimentProperty}</th>
              <th>{TableHeaders.metricCount}</th>
              <th className="margin-left">{TableHeaders.confidenceScores}</th>
              <th />
            </tr>
          </thead>
          {profileData}
        </Table>
      )}
    </div>
  ) : (
    <div className="air-wrapper">
      <div className="fetch-error">
        <Icon iconName={IconNames.error} />
        <span className="error-message"> {Messages.noDataFound}</span>
      </div>
    </div>
  );
};
