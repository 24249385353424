/*----------------------------------------------------------------------------------------------------------------------------
# AboutTemplate.tsx: 
# This Component is our About Component.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Icon } from '@fluentui/react';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import Breadcrumbs, { BreadcrumbsService } from '@/partials/Breadcrumbs/BreadcrumbsTemplate';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const AboutFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages, addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const items: IBreadcrumbItem[] = [BreadcrumbsService.quickItem(Navigation.GANYMEDE.ABOUT, BreadcrumbsService.IS_TITLE_ITEM)];

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
    addHelpMessage('about-help');
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className={'fullscreen container'}>
      <Breadcrumbs items={items} />
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>

      <h2>
        <Trans ns={NS.TITLES as Namespace}>overview</Trans>
      </h2>

      <ul>
        <li>
          We use the Microsoft <a href="https://developer.microsoft.com/en-us/fluentui#/">Fluent UI V8</a> (alongside the new{' '}
          <a href="https://react.fluentui.dev/">Fluent UI React V9</a>) framework for all our cross-platform controls and
          components. This includes things like Accordions, Buttons, Tables, Modal Dialogs, MessageBars, Toggles and Tooltips.
        </li>
        <li>
          Our development IDE of choice is <a href="https://code.visualstudio.com/">VS.Code</a>. The codebase has a{' '}
          <a href="https://get.asp.net/">ASP.NET Core</a> and uses{' '}
          <a href="https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx">C#</a> for cross-platform server-side code. Visual Studio
          can also be used, however the built-in Linting and Prettification we have in VS.Code is not available.
        </li>
        <li>
          For package managers, we have adopted <a href="https://github.com/pnpm/pnpm">PNPM</a> over NPM, as it is more performant
          and efficient over its predecessors. NPM and Yarn can still be used, however it is recommended to make the switch like we
          have.
        </li>
        <li>
          We have adopted the{' '}
          <a href="https://medium.cobeisfresh.com/level-up-your-react-architecture-with-mvvm-a471979e3f21">MVVM</a> architectural
          design pattern with <a href="https://www.typescriptlang.org/">MobX</a> for our state management.
        </li>
        <li>
          <a href="https://www.typescriptlang.org/">TypeScript</a> if the scripting language of choice and{' '}
          <a href="https://dev.to/brayanarrieta/new-javascript-features-ecmascript-2022-with-examples-4nhg">ECMAcript 2022</a> is
          the JavaScript standard used. We do not use any jQuery or Underscore/Lodash.
        </li>
        <li>
          We use <a href="https://eslint.org/">ESLint</a> for a majority of our Linting rules to make sure we are using best
          practices. Code is linted as it is developed, and also checked with Git Pre-Commit Hooks using Husky.
        </li>
        <li>
          Opinionated prettification with <a href="https://prettier.io/">Prettier</a> helps make sure our code looks the same and
          follows the same standards.
        </li>
        <li>
          We are using two Font systems for displaying Icons:
          <ul>
            <li>
              A majority of our fonts come from <a href="https://fontawesome.com/docs/web/use-with/react/">FontAwesome</a> and look
              like <FontAwesomeIcon icon={faUser} title="User" aria-label="User" />
            </li>
            <li>
              We also use FluentUI Icons like <Icon iconName="Contact" />.
            </li>
          </ul>
          Once we have determined if one system can be used over the other, we will merge and simplify.
        </li>
        <li>
          <a href="http://getbootstrap.com/">Bootstrap</a> is used for our general layout and styling, while the Dashboard uses the{' '}
          <a href="https://github.com/react-grid-layout/react-grid-layout">React Grid Layout</a> library for Widget layout support.
        </li>
        <li>
          The <a href="https://grid.layoutit.com/">LayoutIt!</a> Grid system is the tool we use to create the complex layouts for
          the webpage.
        </li>
        <li>
          Our site supports full Internationalization (i18n) from the start, using the powerful{' '}
          <a href="https://react.i18next.com/">react-i18next</a> library (with an excellent{' '}
          <a href="https://www.youtube.com/watch?v=SA_9i4TtxLQ&amp;t=711s">crash course</a> here). Language files are stored as JSON
          allowing for easy handoff to a 3rd Party Translation Service.
        </li>
        <li>
          For our prototyping, we have been using the popular and powerful <a href="https://www.figma.com/">Figma</a> collaborative
          interface design tool.
        </li>
      </ul>
    </div>
  );
};

const About = observer(AboutFC);

export default About;
