import React from 'react';
import { SpinnerSize } from '@fluentui/react';
import { Card } from 'antd';

import { Labels } from '@/constants/LabsConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import { TestCaseCollapse } from './TestCaseCollapse';

import 'antd/dist/antd.css';
import '@/styles/Labs.css';

export const LogsSummary = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;

  const [results, setResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const latestValue = React.useRef(results);
  const loadingVal = React.useRef(loading);
  const id = props.id;
  const company = props.companyName;

  React.useEffect(() => {
    setLoading(true);
    fetchLogsById();
  }, [id]);

  const fetchLogsById = React.useCallback(async () => {
    if (id) {
      logManagementRequestService
        .getLogsById(id, company)
        .then((res) => {
          if (res?.length > 0) {
            const jsonResult = res[0];

            setResults(jsonResult[Labels.testDetails]);
            latestValue.current = jsonResult[Labels.testDetails];
          }

          setLoading(false);
          loadingVal.current = false;
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
          };
          setLoading(false);
          HandleError(handleErrorProps);
        });
    }
  }, [id]);

  if (loading) {
    return <LoadingSpinner size={SpinnerSize.medium} />;
  }

  return (
    <Card loading={loading}>
      {!loading && results && results.length > 0 ? (
        results.map((result: any) => TestCaseCollapse(result))
      ) : (
        <div>No Summary Found</div>
      )}
    </Card>
  );
};
