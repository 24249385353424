import React from 'react';
import { Button, Modal } from 'antd';

import { contentStyles } from '@/components/_labs/LabsHelper/Modal';
import { Labels } from '@/constants/LabsConstants';

export const ErrorModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = React.useState(props.isModalVisible);
  const { ErrorMessage } = props.data;

  const handleClose = () => {
    if (props.customClose) {
      props.customClose();
    }

    setIsModalVisible(false);
    props.showModal(false);
  };

  return (
    <Modal
      className="labs-wrapper"
      visible={isModalVisible}
      footer={[
        <Button key="back" onClick={handleClose}>
          {Labels.closeText}
        </Button>,
      ]}
      width={500}
      onCancel={handleClose}
    >
      <div>
        <div className={contentStyles.header}>{Labels.errorText}</div>
        <div className={contentStyles.body}>{ErrorMessage}</div>
      </div>
    </Modal>
  );
};
