import React from 'react';

import { Labels, Statuses } from '@/constants/AirConstants';
import { ExecutionGoalStatus } from '@/types/_air';

import { ExperimentStatusInfoField } from './ExperimentStatusInfoField';

import '@/styles/Air.css';

interface Props {
  status: ExecutionGoalStatus;
}

export const ExperimentStatusInfo = ({ status }: Props): JSX.Element => {
  const convertDate = (date: string): string => {
    const newDate = new Date(date);
    const result = `${newDate.getFullYear()}-${`0${newDate.getMonth() + 1}`.slice(-2)}-${`0${newDate.getDate()}`.slice(-2)}`;

    return result;
  };

  return (
    <div className="col-xl-6 gbs-status-info-row">
      <div className="row">
        <div className="col-sm-5">
          <ExperimentStatusInfoField label={Labels.runStatus} text={status.status} />
          <ExperimentStatusInfoField label={Labels.totalInstances} text={status.total} />
          <ExperimentStatusInfoField label={Labels.succeededInstances} text={status.success} />
          <ExperimentStatusInfoField label={Labels.inProgressInstances} text={status.progress} />
          <ExperimentStatusInfoField label={Labels.failedInstances} text={status.failed} />
        </div>

        <div className="col-sm-7">
          <ExperimentStatusInfoField
            label={Labels.firstInstanceRunDate}
            text={status.firstDate === '' ? status.firstDate : convertDate(status.firstDate)}
          />
          <ExperimentStatusInfoField
            label={Labels.lastInstanceRunDate}
            text={status.lastDate === '' ? status.lastDate : convertDate(status.lastDate)}
          />
          <ExperimentStatusInfoField
            label={Labels.estimatedCompletionDate}
            text={status.eta.etaDate === Statuses.notAvailable ? status.eta.etaDate : convertDate(status.eta.etaDate)}
          />
        </div>
      </div>
    </div>
  );
};
