import React from 'react';
import { observer } from 'mobx-react-lite';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Divider } from '@fluentui/react-components';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { PeoplePickerComponent } from '@/components/_labs/LabsHelper/ModalComponents/PeoplePickerComponent';
import { LabGeneralTemplate } from '@/components/ManageLab/LabGeneral/LabGeneralTemplate';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { LabIcons, SystemIcons } from '@/constants/IconConstants';
import { Buttons, Labels } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import PageContent from '@/partials/PageContent/PageContent';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { labDownloadHelper } from '@/utils/Helpers';

import { IComboBoxOption, LabGeneralVMType } from './LabGeneralTypes';

import style from '@/components/ManageLab/LabGeneral/LabGeneral.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface LabGeneralViewControllerProps {
  viewModel: LabGeneralVMType;
}

const LabGeneralViewControllerFC: React.FC<LabGeneralViewControllerProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore, labDetailsStore, labGeneralStore } = rootStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { isPartnerMode } = appSettingsStore;
  const { mapLabDetailsData, getLabUser, fetchExistingTags, getOsImages } = viewModel;
  const { selectedLab } = labDetailsStore;
  const { labUserList, labDetailsData, setLastRefresh, selectedTags, setSelectedTags } = labGeneralStore;

  const [actionsDisabled, setActionsDisabled] = React.useState<boolean>(true);
  const [isAdminMenu, setAdminMenu] = React.useState<boolean>(true);
  const [isLabAddUserModalOpen, { setTrue: showLabAddUserModal, setFalse: hideLabAddUserModal }] = useBoolean(false);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    fetchExistingTags();
    getOsImages();
  }, [fetchExistingTags, getOsImages]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLastRefresh();
    }, TimeInterval.FIVE_MINS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    mapLabDetailsData(selectedLab);
    getLabUser();
  }, [selectedLab, mapLabDetailsData, getLabUser]);

  const handleTagDelete = (_e, { value }) => {
    setSelectedTags([...selectedTags].filter((tag) => tag.key !== value));
  };

  const handleTagChange = (items: IComboBoxOption[]) => {
    const newTags = items.filter((item) => !selectedTags.some((tag) => tag.key === item.key));
    setSelectedTags([...selectedTags, ...newTags]);
  };

  const labAdminTooltipContent = labDetailsData ? (
    <div>
      {labDetailsData.labAdminList?.length > 0 ? (
        labDetailsData.labAdminList.map((admin, index) => <div key={index}>{admin.UserName}</div>)
      ) : (
        <div>{Labels.noAdmin}</div>
      )}
      <div className={style.manageUserLink} onClick={showLabAddUserModal}>
        {Labels.manageUser}
      </div>
    </div>
  ) : null;

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Labs.MANAGE_USERS,
      text: Buttons.manageUsers,
      title: Buttons.manageUsers,
      iconProps: { iconName: SystemIcons.MANAGE_USER },
      onClick: showLabAddUserModal,
      hidden: isPartnerMode || !isAdminMenu,
    },
    {
      key: Labs.DOWNLOAD_INSTALLER,
      text: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      title: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      //   disabled: actionsDisabled,
      subMenuProps: {
        items: [
          {
            key: Labs.DOWNLOAD_EXE,
            text: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_EXE },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, true),
          },
          {
            key: Labs.DOWNLOAD_MSI,
            text: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_MSI },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, false),
          },
        ],
      },
    },
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Labs.SAVE_LAB,
      text: t(Labs.SAVE_LAB, { ns: NS.LABS }),
      title: t(Labs.SAVE_LAB, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.SAVE },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
      },
      disabled: actionsDisabled,
    },
  ];

  const getModalComponent = () => {
    if (isLabAddUserModalOpen) {
      return (
        <PeoplePickerComponent
          isModalOpen={isLabAddUserModalOpen}
          hideModal={hideLabAddUserModal}
          selectedItemKey={selectedLab.LabId}
          titleName={selectedLab.LabName}
          action={Labels.addLabUser}
          labUserList={labUserList}
          getLabUser={getLabUser}
          title={t(Labs.MANAGE_USERS, { ns: NS.TITLES })}
        />
      );
    }
  };

  return (
    <PageContent scrollable={false}>
      <div className="fullscreen">
        <LabGeneralTemplate
          commandBarItems={commandBarItems}
          selectedTags={selectedTags}
          handleTagDelete={handleTagDelete}
          handleTagChange={handleTagChange}
          labAdminTooltipContent={labAdminTooltipContent}
        />
        {getModalComponent()}
      </div>
    </PageContent>
  );
};

const LabGeneralViewController = observer(LabGeneralViewControllerFC);

export default LabGeneralViewController;
