export class RegressionTestPassResultRequestFilters {
  Id = 0;

  Rows = 0;

  VersionList: Array<string> = [];

  BuildList: Array<string> = [];

  TestNameList: Array<string> = [];

  TestSuiteList: Array<string> = [];

  RunDateList: Array<string> = [];

  TestOwnerList: Array<string> = [];

  ProcessorList: Array<string> = [];

  TestResultList: Array<string> = [];

  SignOffList: Array<string> = [];
}
