import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { PrimaryButton } from '@fluentui/react';
import moment from 'moment-timezone';

import { OverflowTooltip } from '@/components/_air/common';
import CustomPaginate from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/CustomPaginate';
import { Labels, TargetGoalInstancesPageTableHeaders, TenantIds } from '@/constants/AirConstants';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { Parameters, TargetGoalInfo, TargetGoalStatus } from '@/types/_air';

import { SeachDataForm } from './SeachDataForm';
import { TableHeader } from './TableHeader';

import '@/styles/Air.css';

interface Props {
  experimentInstances: TargetGoalStatus[];
  parameter: Parameters;
  targetGoalInfo: TargetGoalInfo;
}

export const TargetGoalsInstancesTable = ({ experimentInstances, parameter, targetGoalInfo }: Props): JSX.Element => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { userInfoStore, userSettingsStore } = rootStore;
  const { timeZone } = userSettingsStore;

  const [search, setSearch] = React.useState('');
  const [microsoftUser, setMicrosoftUser] = React.useState(false);
  const itemPerPage = 10;

  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [currentPage, setcurrentPage] = React.useState(0);

  const [sorting, setSorting] = React.useState({ key: '', order: '' });

  React.useEffect(() => {
    setMicrosoftUser([TenantIds.MS].includes(userInfoStore.tenantId) || [TenantIds.AME].includes(userInfoStore.tenantId));
  });

  const experimentInstancesData = React.useMemo(() => {
    let computedData = experimentInstances;

    if (computedData !== undefined) {
      if (search) {
        computedData = computedData.filter(
          (data) =>
            data.experimentStartTime.toLowerCase().includes(search.toLowerCase()) ||
            data.experimentStatus.toLowerCase().includes(search.toLowerCase()),
        );
      }

      setPageCount(Math.ceil(computedData.length / itemPerPage));

      if (sorting.key) {
        const reversed = sorting.order === 'asc' ? 1 : -1;

        computedData.sort((a, b) => reversed * a[sorting.key].localeCompare(b[sorting.key]));
      }

      const endOffset = itemOffset + itemPerPage;

      return computedData.slice(itemOffset, endOffset);
    }

    return computedData;
  }, [experimentInstances, search, sorting.key, sorting.order, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemPerPage;

    setcurrentPage(event.selected);
    setItemOffset(newOffset);
  };

  const renderInstances = (): JSX.Element[] =>
    experimentInstancesData.length > 0 &&
    experimentInstancesData.map((instances, ind) => {
      const { experimentId, experimentStatus } = instances;
      const createdDate = moment.tz(instances.experimentStartTime, timeZone).format(DateFormats.STANDARD_DATE_TIME);

      return (
        <tr key={ind}>
          <td className="status-table-field">
            <OverflowTooltip>{experimentId}</OverflowTooltip>
          </td>
          <td>{createdDate}</td>
          <td className="status-table-field">{experimentStatus}</td>
          <td className="status-table-field" style={{ textOverflow: 'unset' }}>
            <Link
              to={{
                pathname: `${Navigation.AIR.INSTANCE_STATUS}/${experimentId}`,
                state: {
                  parameter,
                  targetGoalInfo,
                },
              }}
              onClick={(e) => (!microsoftUser ? e.preventDefault() : null)}
            >
              <PrimaryButton className="status-table-button" disabled={!microsoftUser}>
                {Labels.status}
              </PrimaryButton>
            </Link>
          </td>
          {experimentStatus === 'Failed' ? (
            <td className="status-table-field" style={{ textOverflow: 'unset' }}>
              <Link
                to={{
                  pathname: `${Navigation.AIR.INSTANCE_FAILURE}/${experimentId}`,
                  state: {
                    parameter,
                    targetGoalInfo,
                  },
                }}
              >
                <PrimaryButton className="status-table-button">{Labels.failureAnalysis}</PrimaryButton>
              </Link>
            </td>
          ) : (
            <td />
          )}
        </tr>
      );
    });

  return (
    <div>
      {experimentInstances && (
        <SeachDataForm
          placeholder="Search By Date and Status"
          style={{ width: '30%', float: 'right', marginBottom: '20px' }}
          onSearch={(value) => {
            setSearch(value);
            setItemOffset(0);
            setcurrentPage(0);
          }}
        />
      )}
      <Table hover striped size="sm" className="gbs-status-table text-nowrap">
        <thead>
          <TableHeader headers={TargetGoalInstancesPageTableHeaders} onSorting={(key, order) => setSorting({ key, order })} />
        </thead>
        {experimentInstances && <tbody>{renderInstances()}</tbody>}
      </Table>
      <CustomPaginate
        breakLabel="..."
        nextLabel="next >"
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        forcePage={currentPage}
        containerClassName="pagination"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        onPageChange={handlePageClick}
      />
    </div>
  );
};
