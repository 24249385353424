/*----------------------------------------------------------------------------------------------------------------------------
# ReleaseNotesTemplate.tsx: 
# This Component is our Release Notes Component.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import Documentation from '@/components/Documentation/DocumentationWrapper';
import { DocumentationLinks } from '@/constants/DocumentationConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const ReleaseNotesFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  const title = t('release-notes', { ns: NS.TITLES });

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top column">
          <PageHeader icon={SystemIcons.INFO}>{title}</PageHeader>
          <Documentation url={DocumentationLinks.RELEASE_BLOB_URL} pageKey="release-notes" />
        </div>
      </main>
    </div>
  );
};

const ReleaseNotes = observer(ReleaseNotesFC);

export default ReleaseNotes;
