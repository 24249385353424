import { BoolClasses, Colors } from '@/constants/AirConstants';
import { Metrics } from '@/types/_air';

interface DataScore {
  name: string;
  data: number[];
}

interface ColorsScores {
  redScores: string[];
  blackScores: string[];
  greenScores: string[];
  yellowScores: string[];
}

const getColorsScores = (colors: string[]): ColorsScores => {
  const redScores: string[] = [];
  const blackScores: string[] = [];
  const greenScores: string[] = [];
  const yellowScores: string[] = [];

  colors.forEach((color) => {
    switch (color) {
      case 'green':
        greenScores.push(color);
        break;
      case 'red':
        redScores.push(color);
        break;
      case 'yellow':
        yellowScores.push(color);
        break;
      default:
        blackScores.push(color);
    }
  });

  return {
    redScores,
    blackScores,
    greenScores,
    yellowScores,
  };
};

const getWorkloadProfileScoreArr = (props: Metrics[]): DataScore[] => {
  const colors = props.map((item) => item.confidenceScore.color.toLowerCase());
  const { redScores, blackScores, greenScores, yellowScores } = getColorsScores(colors);

  return createPercentTile(redScores, greenScores, blackScores, yellowScores);
};

const createPercentTile = (
  redScores: string[],
  greenScores: string[],
  blackScores: string[],
  yellowScores: string[],
): DataScore[] => {
  const total = redScores.length + greenScores.length + blackScores.length + yellowScores.length;
  let red = calculatePercent(redScores.length, total);
  let green = calculatePercent(greenScores.length, total);
  let black = calculatePercent(blackScores.length, total);
  let yellow = calculatePercent(yellowScores.length, total);

  if (red > 0 && red < 1) {
    red += 1;
    black -= 1;
  }

  if (green > 0 && green < 1) {
    green += 1;
    black -= 1;
  }

  if (yellow > 0 && yellow < 1) {
    yellow += 1;
    black -= 1;
  }

  const dataScores: DataScore[] = [
    {
      name: `(${redScores.length})`,
      data: [red],
    },
    {
      name: `(${yellowScores.length})`,
      data: [yellow],
    },
    {
      name: `(${blackScores.length})`,
      data: [black],
    },
    {
      name: `(${greenScores.length})`,
      data: [green],
    },
  ];

  return dataScores;
};

const calculatePercent = (partialValue: number, totalValue: number): number => +((100 * partialValue) / totalValue).toFixed(2);

const getClassName = (color: string): string => {
  color = color.toLowerCase();

  switch (color) {
    case Colors.green:
      return BoolClasses.boolgood;
    case Colors.red:
      return BoolClasses.boolbad;
    case Colors.yellow:
      return BoolClasses.boolyellow;
    default:
      return BoolClasses.boolneutral;
  }
};

const getThreshholdClassName = (num: number, isHigherBetter: boolean): string => {
  num *= 100;

  if (num > 2) {
    return !isHigherBetter ? 'boolbad-workload' : 'boolgood-workload';
  }

  if (num < -2) {
    return !isHigherBetter ? 'boolgood-workload' : 'boolbad-workload';
  }

  return 'boolneutral-workload';
};

export { DataScore, getClassName, getThreshholdClassName, getWorkloadProfileScoreArr };
