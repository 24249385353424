import React from 'react';
import { observer } from 'mobx-react-lite';

import { ExperimentPropertiesPanelVMType } from '@/components/ExperimentPropertiesPanel/ExperimentPropertiesPanelTypes';
import ExperimentPropertiesPanelViewController from '@/components/ExperimentPropertiesPanel/ExperimentPropertiesPanelViewController';
import ExperimentPropertiesPanelViewModel from '@/components/ExperimentPropertiesPanel/ExperimentPropertiesPanelViewModel';

const ExperimentPropertiesPanelFC: React.FC<ExperimentPropertiesPanelVMType> = ({ loadMessage }) => {
  const viewModel = new ExperimentPropertiesPanelViewModel(loadMessage);

  return <ExperimentPropertiesPanelViewController viewModel={viewModel}></ExperimentPropertiesPanelViewController>;
};

const ExperimentPropertiesPanel = observer(ExperimentPropertiesPanelFC);

export default ExperimentPropertiesPanel;
