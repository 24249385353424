export class UrlVariable {
  public default: any;
  private key: any;
  private value?: any;

  constructor(key: any, defaultValue: any) {
    this.key = key;
    this.default = defaultValue;
    this.value = this.get();
  }

  set(val: any) {
    const searchParams = new URLSearchParams(location.search);

    this.value = val;

    if (val === this.default) {
      searchParams.delete(this.key);
    } else {
      searchParams.set(this.key, this.value);
    }

    history.replaceState({}, '', `${location.pathname}?${searchParams}`);
  }

  get() {
    const searchParams = new URLSearchParams(location.search);

    this.value = searchParams.get(this.key);

    if (this.value) {
      return this.value;
    } else {
      return this.default;
    }
  }
}
