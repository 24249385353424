import React from 'react';
import SplitPane from 'react-split-pane';
import { observer } from 'mobx-react-lite';

import '@/styles/SplitPanel.css';
import styles from './SplitPaneTester.module.css';

const LayoutTesterFC: React.FC = (): any => {
  return (
    <div className={styles.root}>
      <h3>SplitPane Tester</h3>
      <div>This is the SplitPane Tester</div>
      <div>
        <SplitPane className="primary" split="vertical" defaultSize={200} minSize={200}>
          <div className={styles.panel}>this is my content top</div>
          <SplitPane split="horizontal" defaultSize={200} minSize={200}>
            <div className={styles.panel}>this is my content bottom 1</div>
            <div className={styles.panel}>this is my content bottom 2</div>
          </SplitPane>
        </SplitPane>
      </div>
    </div>
  );
};

const LayoutTester = observer(LayoutTesterFC);

export default LayoutTester;
