import { action, computed, makeObservable, observable } from 'mobx';
import { injectable } from 'tsyringe';

import { UserSettingsDefaults, UserSettingsKeys } from '@/constants/UserSettingsConstants';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

@injectable()
class UserSettingsStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public timeZone: string;
  public devEnvironment: string;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      timeZone: observable,

      setTimeZone: action,
    });

    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    this.timeZone = this.localStorage.getValue(UserSettingsKeys.USER_TIME_ZONE_KEY) || UserSettingsDefaults.DEFAULT_USER_TIME_ZONE;

    this.save(); // We shall persist for first-hit scenarios.
  }

  public setTimeZone = (timeZone: string) => {
    this.timeZone = timeZone;

    return this.localStorage.setValue(UserSettingsKeys.USER_TIME_ZONE_KEY, timeZone);
  };

  private save = () => {
    this.localStorage.setValue(UserSettingsKeys.USER_TIME_ZONE_KEY, this.timeZone);
  };
}

export default UserSettingsStore;
