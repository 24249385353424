import { Bugs } from './Bugs';

export class RegressionTestPassResultList {
  Id = 0;

  TestPassCreationTime = '';

  ResultCollectionGUID = '';

  TestName = '';

  TestSuite = '';

  TestPlan = '';

  Result = '';

  TestOwner = '';

  BackupOwner = '';

  Processor = '';

  Path = '';

  Version = '';

  Build = '';

  Color = '';

  Bugs: Array<Bugs> = [];

  BugId = '';

  Notes = '';

  SignOffUser = '';
}
