import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon } from '@fluentui/react';

import NavLinks from '@/partials/NavLinks/NavLinksViewController';

import { SideBarVCType } from './SideBarTypes';

import styles from './SideBar.module.css'; // Apply any SideBar level CSS Module styling here.

const SideBarTemplateFC: React.FC<SideBarVCType> = (props: SideBarVCType) => {
  const { navLinks, toggleSidebarCollapsed, isSidebarCollapsed, getSideBarClass, getToggleIcon } = props;
  const { pathname } = useLocation();

  return (
    <header className={styles['sidebar']}>
      <div className={`${styles['sidebar-navigation']} ${getSideBarClass()}`}>
        <NavLinks isSidebarCollapsed={isSidebarCollapsed} location={pathname}>
          {navLinks}
        </NavLinks>
      </div>
      <div className={styles['sidebar-controls']}>
        <Icon className={styles['icon-bottom']} onClick={toggleSidebarCollapsed} iconName={getToggleIcon()} />
      </div>
    </header>
  );
};

const SideBarTemplate = observer(SideBarTemplateFC);

export default SideBarTemplate;
