import React from 'react';
import { observer } from 'mobx-react';
import { IDropdownOption, IDropdownProps } from '@fluentui/react/lib/Dropdown';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';

import PageUserFiltersTemplate from './PageUserFiltersTemplate';
import { DropdownType, PageUserFiltersState, PageUserFiltersType } from './PageUserFiltersType';

const stackTokens: IStackTokens = { childrenGap: 20 };

class PageUserFiltersViewControllerCC extends React.Component<{ viewModel: PageUserFiltersType }, PageUserFiltersState> {
  protected selectedUserKey: IDropdownOption;

  constructor(props: { viewModel: PageUserFiltersType }) {
    super(props);
    const { selectedUser, selectedPages } = props.viewModel;
    this.selectedUserKey = { key: selectedUser, text: selectedUser };

    this.state = {
      selectedKeys: [],
      selectedUser: { key: selectedUser, text: selectedUser },
      selectedPageNames: selectedPages ?? [],
      resetPages: new Date().getTime(),
      resetUsers: new Date().getTime(),
    };
  }

  compare(array1: any[], array2: any[]): boolean {
    // If one array exists and the other doesn't return false, if neither exits return true
    if (array1 && !array2) {
      return false;
    }

    if (!array1 && array2) {
      return false;
    }

    if (!array1 && !array2) {
      return true;
    }

    // If the lengths don't match return false
    if (array1.length !== array2.length) {
      return false;
    }

    // Compare elements independent of place in array
    array1.forEach((element1) => {
      let found = false;

      array2.forEach((element2) => {
        if (element1 === element2) {
          found = true;
        }
      });

      if (!found) {
        return false;
      }
    });

    return true;
  }

  // This is required to keep the dropdowns synced up with the selected pages and users
  // if they change due to a change in the selected date range
  componentDidUpdate(
    prevProps: Readonly<{ viewModel: PageUserFiltersType }> /* eslint-disable-line @typescript-eslint/no-unused-vars */,
    prevState: Readonly<PageUserFiltersState>,
    snapshot?: any /* eslint-disable-line @typescript-eslint/no-unused-vars */,
  ): void {
    const { selectedUser, selectedPages } = this.props.viewModel;

    if (prevState.selectedUser?.text !== selectedUser) {
      this.setState({
        selectedUser: { key: selectedUser, text: selectedUser },
      });
    }

    if (!this.compare(prevState.selectedPageNames, selectedPages)) {
      const pageNames = selectedPages.filter((item) => this.state.selectedKeys.includes(item));

      this.setState({
        selectedKeys: pageNames,
        selectedPageNames: selectedPages,
      });
    }

    this.selectedUserKey = { key: selectedUser, text: selectedUser };
  }

  onPagesChange = (selectedOptions: string[]) => {
    const { setSelectedPages } = this.props.viewModel;

    this.setState({
      selectedKeys: selectedOptions,
    });

    setSelectedPages(selectedOptions);
  };

  onUserChange = (item: IDropdownOption) => {
    const { setSelectedUser } = this.props.viewModel;

    this.setState({
      selectedUser: item,
    });

    setSelectedUser(item.text);
  };

  onClosePagesList = () => {
    const { pages, selectedUser, setUsers, setSelectedPages } = this.props.viewModel;
    const { selectedKeys } = this.state;
    const pageNames = pages.filter((item) => selectedKeys.includes(item.key.toString())).map((filteredItem) => filteredItem.text);

    // Update the users to match the selected pages
    setUsers();
    this.setState({
      selectedUser: { key: selectedUser, text: selectedUser },
    });

    this.setState({
      selectedPageNames: pageNames,
    });

    setSelectedPages(pageNames);
  };

  onCloseUsersList = () => {
    const viewModel: PageUserFiltersType = this.props.viewModel;
    const { selectedUser } = this.state;
    const { selectedPages, setPages, setSelectedUser } = viewModel;
    const propsSelectedUser = viewModel.selectedUser;

    // Update the pages to match the selected user
    setPages();

    this.setState({
      selectedPageNames: selectedPages ?? [],
    });

    setSelectedUser(selectedUser?.text);

    this.setState({
      selectedUser: { key: propsSelectedUser, text: propsSelectedUser },
    });
  };

  onButtonClick = () => {
    const viewModel: PageUserFiltersType = this.props.viewModel;
    const { setErrors, setGraphData } = viewModel;

    setErrors();
    setGraphData();
  };

  render() {
    const viewModel: PageUserFiltersType = this.props.viewModel;
    const { resetPages, resetUsers } = this.state;
    const { pages, users, selectedPages, selectedUser } = viewModel;
    const pagesProp: IDropdownProps = {
      placeholder: 'All',
      label: t('page-selection', { ns: NS.TABLE }) + ':',
      options: pages,
      onDismiss: this.onClosePagesList,
    };
    const pagesDropDown: DropdownType = {
      dropdownProps: pagesProp,
      callBack: this.onPagesChange,
    };
    const usersProp: IDropdownProps = {
      placeholder: '',
      label: t('user-selection', { ns: NS.TABLE }) + ':',
      options: users,
      onDismiss: this.onCloseUsersList,
    };
    const usersDropDown: DropdownType = {
      dropdownProps: usersProp,
      callBack: this.onUserChange,
    };

    return (
      <PageUserFiltersTemplate
        selectedPages={selectedPages}
        pagesDropDown={pagesDropDown}
        selectedUser={selectedUser ?? ''}
        selectedUserKey={this.selectedUserKey}
        usersDropDown={usersDropDown}
        stackTokens={stackTokens}
        onButtonClick={this.onButtonClick}
        resetPages={resetPages}
        resetUsers={resetUsers}
      />
    );
  }
}

const PageUserFiltersViewController = observer(PageUserFiltersViewControllerCC);

export default PageUserFiltersViewController;
