import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { APIErrorInterface, ProvidersList } from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class ProvidersRequestService extends BaseRequestService {
  async getProvidersList(cancelToken?: CancelToken): Promise<ProvidersList[] | APIErrorInterface> {
    const { data } = await this.get('providers/GetProvidersList', null, { cancelToken });

    return data;
  }
}

export const providersRequestService = new ProvidersRequestService(backendEndpoint);
