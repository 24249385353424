import { action, makeObservable, observable } from 'mobx';

import { Defaults } from '@/constants/AirConstants';
import { TimeRangeRemoveOptions } from '@/constants/DateFormatConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeNull } from '@/types/AppSettingsTypes';

class AirSettingsStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  protected now = new Date();

  public manageAirExperimentStartDate: Date = this.now;
  public manageAirExperimentEndDate: Date = this.now;
  public manageAirExperimentsTimeValue = TimeRangeRemoveOptions.ALL;
  public manageAirExperimentsTeamName = Defaults.teamName;
  public manageAirExperimentsSearchValue = '';
  public airResultsStartDate: Date = this.now;
  public airResultsEndDate: Date = this.now;
  public airResultsTimeValue = TimeRangeRemoveOptions.ALL;
  public airResultsSearchValue = '';

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      manageAirExperimentsTimeValue: observable,
      manageAirExperimentsTeamName: observable,
      manageAirExperimentsSearchValue: observable,
      manageAirExperimentStartDate: observable,
      manageAirExperimentEndDate: observable,
      airResultsTimeValue: observable,
      airResultsStartDate: observable,
      airResultsEndDate: observable,
      airResultsSearchValue: observable,

      setManageAirExperimentsSearchValue: action,
      setManageAirExperimentsTimeValue: action,
      setManageAirExperimentsTeamName: action,
      setManageAirExperimentStartDate: action,
      setManageAirExperimentEndDate: action,
      setAirResultsTimeValue: action,
      setAirResultsSearchValue: action,
      setAirResultsStartDate: action,
      setAirResultsEndDate: action,
      doAirResultsReset: action,
      doManageAirExperimentReset: action,
    });

    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    this.manageAirExperimentsSearchValue =
      this.localStorage.getValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_SEARCH_VALUE) || '';
    this.manageAirExperimentsTimeValue =
      this.localStorage.getValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_TIME_KEY) || TimeRangeRemoveOptions.ALL;
    this.manageAirExperimentStartDate =
      new Date(this.localStorage.getValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_START_DATE_KEY)) || this.now;
    this.manageAirExperimentEndDate =
      new Date(this.localStorage.getValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_END_DATE_KEY)) || this.now;
    this.airResultsTimeValue =
      this.localStorage.getValue(AppSettingsService.FILTER_AIR_RESULTS_TIME_KEY) || Defaults.timeDropdownValue;
    this.airResultsStartDate =
      new Date(this.localStorage.getValue(AppSettingsService.FILTER_AIR_RESULTS_START_DATE_KEY)) || this.now;
    this.airResultsEndDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_AIR_RESULTS_END_DATE_KEY)) || this.now;
    this.airResultsSearchValue = this.localStorage.getValue(AppSettingsService.FILTER_AIR_RESULTS_SEARCH_KEY) || '';

    this.save();
  }

  public setManageAirExperimentsTimeValue = (filterTimeOpt: string) => {
    this.manageAirExperimentsTimeValue = filterTimeOpt;

    return this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_TIME_KEY, filterTimeOpt);
  };

  public setManageAirExperimentStartDate = (date: Date) => {
    this.manageAirExperimentStartDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_START_DATE_KEY, date);
  };

  public setManageAirExperimentEndDate = (date: Date) => {
    this.manageAirExperimentEndDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_END_DATE_KEY, date);
  };

  public setManageAirExperimentsTeamName = (filterTeamName: string) => {
    this.manageAirExperimentsTeamName = filterTeamName;

    return this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_TEAM_NAME, filterTeamName);
  };

  public setManageAirExperimentsSearchValue = (filterValue: string) => {
    this.manageAirExperimentsSearchValue = filterValue;

    return this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_SEARCH_VALUE, filterValue);
  };

  public setAirResultsTimeValue = (filterTimeOpt: string) => {
    this.airResultsTimeValue = filterTimeOpt;

    return this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_TIME_KEY, filterTimeOpt);
  };

  public setAirResultsStartDate = (date: Date) => {
    this.airResultsStartDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_START_DATE_KEY, date);
  };

  public setAirResultsEndDate = (date: Date) => {
    this.airResultsEndDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_END_DATE_KEY, date);
  };

  public setAirResultsSearchValue = (filterValue: string) => {
    this.airResultsSearchValue = filterValue;

    return this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_SEARCH_KEY, filterValue);
  };

  public doManageAirExperimentReset: ActionTypeNull = () => {
    this.setManageAirExperimentsTimeValue(TimeRangeRemoveOptions.ALL);
    this.setManageAirExperimentEndDate(this.now);
    this.setManageAirExperimentStartDate(this.now);
    this.setManageAirExperimentsTeamName(Defaults.teamName);
    this.setManageAirExperimentsSearchValue('');
    this.save();

    return null;
  };

  public doAirResultsReset: ActionTypeNull = () => {
    this.setAirResultsTimeValue(TimeRangeRemoveOptions.ALL);
    this.setAirResultsEndDate(this.now);
    this.setAirResultsStartDate(this.now);
    this.setAirResultsSearchValue('');
    this.save();

    return null;
  };

  protected save = () => {
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_SEARCH_VALUE, this.manageAirExperimentsSearchValue);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_TIME_KEY, this.manageAirExperimentsTimeValue);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_START_DATE_KEY, this.manageAirExperimentStartDate);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_END_DATE_KEY, this.manageAirExperimentEndDate);
    this.localStorage.setValue(AppSettingsService.FILTER_MANAGE_AIR_EXPERIMENTS_TEAM_NAME, this.manageAirExperimentsTeamName);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_TIME_KEY, this.airResultsTimeValue);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_SEARCH_KEY, this.airResultsSearchValue);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_START_DATE_KEY, this.airResultsStartDate);
    this.localStorage.setValue(AppSettingsService.FILTER_AIR_RESULTS_END_DATE_KEY, this.airResultsEndDate);
  };
}

export default AirSettingsStore;
