import React from 'react';
import { CsvToHtmlTable } from 'react-csv-to-table';
import JSONPretty from 'react-json-pretty';
import { observer } from 'mobx-react-lite';
import { IconButton, SpinnerSize } from '@fluentui/react';
import { Icon } from '@fluentui/react';
import { t } from 'i18next';

import { ResultWithSubTitle } from '@/components/Experiments/ResultMessage/ResultMessage';
import { SystemIcons } from '@/constants/IconConstants';
import { FailGroupIds, FileType, MimeType, Namespaces as NS } from '@/constants/SystemConstants';
import { LogsView, Results } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { resultsExplorerEndpoint } from '@/utils/Env';
import { downloadUrl } from '@/utils/Helpers';

import { FilePreviewTypeProps } from './FilePreviewTypes';

import styles from './FilePreview.module.css';

const FilePreviewFC: React.FC<FilePreviewTypeProps> = (props) => {
  const { companyName } = props;

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore, rawLogsTreeViewStore } = rootStore;
  const { previewFileName, previewFilePath, filePreviewContent, fileContentLoading } = appSettingsStore;
  const { isLargeFile } = rawLogsTreeViewStore;
  const downloadFile = t(Results.DOWNLOAD_FILE, { ns: NS.RESULTS });
  const largeFileMessage = t(Results.LARGE_FILE_MESSAGE, { ns: NS.RESULTS });
  const downloadText = t(Results.DOWNLOAD_TEXT, { ns: NS.RESULTS });

  const isJSONFile = (fileContent: any): boolean => {
    const trimmedStr = String(fileContent).trim();
    const jsonRegex = /^[{[][\s\S]*[}\]]$/; // Regex to check if it looks like JSON

    return jsonRegex.test(trimmedStr);
  };

  const downloadBlob = () => {
    const baseUrl = resultsExplorerEndpoint;
    const downloadPath = companyName
      ? `${baseUrl}/file/download/file?pathName=${previewFilePath}&companyName=${companyName}`
      : `${baseUrl}/file/download/file?pathName=${previewFilePath}`;

    downloadUrl(downloadPath, previewFileName, systemMessageStore, FailGroupIds.LOG_DOWNLOAD, true);
  };

  const renderNoPreview = (
    <ResultWithSubTitle
      mainTitle={t(LogsView.PREVIEW_NOT_AVAILABLE, { ns: NS.LOGS_VIEW })}
      subTitle={t(LogsView.DOWNLOAD_TO_VIEW, { ns: NS.LOGS_VIEW })}
      iconName={SystemIcons.PREVIEW}
    />
  );

  const renderMessage = largeFileMessage.split('{download}').map((part, index) =>
    index === 1 ? (
      <React.Fragment key={index}>
        <span className={styles['download-text-link']} onClick={downloadBlob}>
          {downloadText}
        </span>
        {part}
      </React.Fragment>
    ) : (
      <React.Fragment key={index}>{part}</React.Fragment>
    ),
  );

  const renderCsvView = (
    <div className={styles['csv-table-container']}>
      {isLargeFile ? (
        <div className={styles['file-container']}>
          <Icon className={styles['ms-IconInfo']} iconName={SystemIcons.INFO} />
          <span>{renderMessage}</span>
        </div>
      ) : (
        <CsvToHtmlTable
          data={filePreviewContent}
          csvDelimiter=","
          tableClassName="table table-striped table-hover background text-color"
        />
      )}
    </div>
  );

  if (fileContentLoading) {
    return (
      <>
        <LoadingSpinner size={SpinnerSize.medium} />
      </>
    );
  }

  return (
    <>
      {filePreviewContent && !fileContentLoading && (
        <div className={styles['header']}>
          <div className={styles['title']}>{previewFileName}</div>
          <div className={styles['floating-icon']}>
            <IconButton
              aria-label={downloadFile}
              title={downloadFile}
              iconProps={{ iconName: SystemIcons.DOWNLOAD }}
              onClick={downloadBlob}
            />
          </div>
        </div>
      )}
      <div className={styles['content']}>
        {previewFileName?.endsWith(FileType.ZIP) && renderNoPreview}
        {filePreviewContent && previewFileName.endsWith(FileType.CSV) && renderCsvView}
        {!previewFileName?.endsWith(FileType.ZIP) && !previewFileName?.endsWith(FileType.CSV) && (
          <JSONPretty data={filePreviewContent} className={styles['json-view']} />
        )}
      </div>
    </>
  );
};

export const FilePreview = observer(FilePreviewFC);
