import React from 'react';
import { observer } from 'mobx-react-lite';

import BarLineGraphViewController from '@/components/Graphs/BarLineGraph/BarLineGraphViewController';

import { ChartData, ChartProps, UseGraphTemplateType } from './UseGraphType';

const UseGraphTemplateFC: React.FC<UseGraphTemplateType> = (props: UseGraphTemplateType): React.ReactElement => {
  const data: Array<ChartData> = props.data;
  const chartProps: ChartProps = props.chartProp;

  return <BarLineGraphViewController data={data} chartProp={chartProps} />;
};

const UseGraphTemplate = observer(UseGraphTemplateFC);

export default UseGraphTemplate;
