import React from 'react';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { CancelToken } from 'axios';
import { t } from 'i18next';
import ls from 'local-storage';
import format from 'string-template';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionFailureType, SessionGUIType, SessionStepType, SessionType } from '@/components/Sessions/SessionsTypes';
import SessionsViewModel from '@/components/Sessions/SessionsViewModel';
import {
  Delimiters,
  FailGroupIds,
  Files,
  KeyTextPair,
  Namespaces as NS,
  SuccessGroupIds,
  UniqueListKey,
} from '@/constants/SystemConstants';
import { LogsView } from '@/constants/TranslationConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { resultsExplorerEndpoint } from '@/utils/Env';
import { getLogPath, getToolkitCommand, getUniqueList } from '@/utils/Helpers';
import MsalAuthorization from '@/utils/MsalAuthorization';
import { filterDateAgent, filterStatus, filterStepType, filterText } from '@/utils/Text';

import SessionDetailsStore from './SessionDetailsStore';

class SessionDetailsViewModel {
  protected rootStore: RootStore;
  protected appSettingsStore: AppSettingsStore;
  protected sessionsStore: SessionsStore;
  protected sessionDetailsStore: SessionDetailsStore;
  protected systemMessageStore: SystemMessageStore;

  public sessionId: string;

  constructor(rootStore: RootStore, sessionId: string) {
    const { appSettingsStore, sessionsStore, sessionDetailsStore, systemMessageStore } = rootStore;

    this.rootStore = rootStore;
    this.appSettingsStore = appSettingsStore;
    this.sessionDetailsStore = sessionDetailsStore;
    this.sessionsStore = sessionsStore;
    this.systemMessageStore = systemMessageStore;

    this.sessionId = sessionId;
  }

  public loadSession = async (sessionId: string, cancelToken: CancelToken): Promise<void> => {
    const { isDebugMode } = this.appSettingsStore;
    const { clearSelectedSession, selectedSession, setSelectedSession, setSelectedExperimentFailure } = this.sessionsStore;
    const { setLabCompanyName, labCompanyName } = this.sessionsStore;
    const { addGlobalMessage } = this.systemMessageStore;
    const isValidSessionId = !!sessionId;

    isDebugMode && console.log(`[SessionsViewModel:loadSession] Loading SessionId: ${sessionId}.`);

    // Check if we already have this sessionId loaded in the store.
    if (selectedSession && selectedSession.id === sessionId) {
      isDebugMode &&
        console.log(`[SessionsViewModel:loadSession] Data already loaded for SessionId: ${sessionId}:`, selectedSession);
      return;
    }

    try {
      if (!isValidSessionId) {
        // No session data to load. Clear the list.
        clearSelectedSession();
      } else {
        const sessionResponse = await GanymedeSessionRequestService.getExperimentSession(sessionId, cancelToken);
        const data: SessionType = sessionResponse?.data as SessionType;

        isDebugMode && console.log('[SessionsViewModel:loadSession] Data received:', data);
        const session: SessionType & SessionGUIType = SessionsViewModel.buildSession(this.rootStore, data);

        if (session) {
          setSelectedSession(session);
        }

        const company: any = await this.loadCompany(session.location.labName, cancelToken);
        setLabCompanyName(company?.CompanyName);
      }
    } catch (error) {
      console.error('[SessionsViewModel:loadSession] Error Loading Session data:', error);

      const message = error.message;
      const systemMessage: SystemMessageType = {
        id: 'session-api-error',
        message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'session-api-group',
      };

      clearSelectedSession();
      addGlobalMessage(systemMessage);
    }

    isDebugMode && console.log(`[SessionsViewModel:loadSession] Data loaded for SessionId: ${sessionId}.`);
  };

  public loadCompany = async (locationValue: string, cancelToken: CancelToken): Promise<string> => {
    const labs: LabType[] = await ganymedeLabRequestService.getLabs();
    const labExists: boolean = labs.some((f) => String(f.LabName) === locationValue);

    if (labExists) {
      const selectedLab: LabType = labs?.find((f) => String(f.LabName) === locationValue);

      const companies: any[] = await ganymedeLabRequestService.getCompanies();
      const labCompany: any = companies.find((activeCompany) => activeCompany.CompanyId === selectedLab.CompanyId);
      return labCompany;
    }

    return null;
  };

  public loadSessionSteps = async (sessionId: string, cancelToken: CancelToken): Promise<void> => {
    const { isDebugMode } = this.appSettingsStore;
    const { addGlobalMessage } = this.systemMessageStore;
    const { selectedSession, setSelectedSession } = this.sessionsStore;
    const { addSessionPanelMessage } = this.sessionDetailsStore;

    const item: SessionType = selectedSession;

    isDebugMode && console.log('[SessionsViewModel:loadSessionSteps] Loading Session Steps for SessionId:', sessionId);

    // Check if we already have this sessionId loaded in the store.
    if (selectedSession?.id === sessionId && selectedSession?.steps?.length > 0) {
      isDebugMode && console.log('[SessionsViewModel:loadSessionSteps] Session Steps already loaded for SessionId:', sessionId);
      return;
    }

    try {
      const details = await GanymedeSessionRequestService.getSessionSteps(sessionId, cancelToken);
      isDebugMode && console.log('[SessionsViewModel:loadSessionSteps] Steps Data received for SessionId:', sessionId, details);

      if (details) {
        const steps = details?.map((step: SessionStepType) => SessionsViewModel.buildSessionStep(this.rootStore, step));
        const statusList: KeyTextPair[] = getUniqueList(
          steps,
          UniqueListKey.STATUS,
          Delimiters.DASH,
          t('no-status', { ns: NS.DEFAULT }),
        );
        const stepTypeList: KeyTextPair[] = getUniqueList(steps, UniqueListKey.STEP_TYPE);
        const newRow: SessionType | null = {
          ...item,
          steps: steps,
          stepsStatusList: statusList,
          stepsTypeList: stepTypeList,
        };

        setSelectedSession(newRow as SessionType);
      }
    } catch (error) {
      // An error happened loading the session steps, so we need to display an error message.
      const newRow: SessionType | null = {
        ...item,
        steps: [],
      };

      setSelectedSession(newRow as SessionType);

      const failMessage: SystemMessageType = {
        message: error.response?.data || error.message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        groupId: FailGroupIds.SESSION_PANEL_ERROR,
      };
      console.error(error);
      addSessionPanelMessage(failMessage);
    }

    isDebugMode && console.log('[SessionsViewModel:loadSessionSteps] Session Steps loaded and done for SessionId:', sessionId);
  };

  public filterStepData = (
    steps: SessionStepType[],
    search: string,
    status: string[],
    stepType: string[],
    startDate,
    endDate,
    timeRange,
  ): any[] => {
    let results = [...steps];

    results = filterText(results, search);
    results = filterStatus(results, status);
    results = filterStepType(results, stepType);
    results = filterDateAgent(results, startDate, endDate, timeRange);

    return results;
  };

  public getLogPathFromExperimentInstance = async (cancellationToken: CancelToken): Promise<void> => {
    const { selectedSession } = this.sessionsStore;
    const { sessionStep, setLogPath } = this.sessionDetailsStore;
    const sessionStepJSON: any = sessionStep.stepJSON;
    const toolkitCommand: string = getToolkitCommand(sessionStepJSON);
    const labId: string = selectedSession?.location?.labId?.toString();

    if (toolkitCommand) {
      await ganymedeExperimentRequestService
        .getExperimentInstanceDetails(labId, sessionStep?.experimentId, cancellationToken)
        .then((response: any) => {
          const toolKitLogPath: string = getLogPath(response, toolkitCommand);

          setLogPath(toolKitLogPath);
        })
        .catch((error) => {
          setLogPath(null);
        });
    } else {
      setLogPath(null);
    }
  };

  public getExperimentFailures = async (cancelToken: CancelToken): Promise<void> => {
    const { sessionStep } = this.sessionDetailsStore;
    const experimentId: any = sessionStep.experimentId;
    const { setSelectedExperimentFailure, selectedExperimentFailure } = this.sessionsStore;

    await ganymedeExperimentRequestService
      .getExperimentFailureAnalysis(experimentId, cancelToken)
      .then((response: any) => {
        if (response) {
          const failureAnalysis: SessionFailureType = response;
          setSelectedExperimentFailure(failureAnalysis);
        } else {
          const empty: SessionFailureType = {} as SessionFailureType;
          setSelectedExperimentFailure(empty);
        }
      })
      .catch((error) => {
        const empty: SessionFailureType = {} as SessionFailureType;
        setSelectedExperimentFailure(empty);
      });
  };

  public logDownloader = (path: string, companyName?: string) => {
    const { addSessionPanelMessage, sessionPanelMessages } = this.sessionDetailsStore;
    const outputFile = Files.LOGS_ZIP;

    const startMessage: SystemMessageType = {
      message: t(LogsView.DOWNLOAD_LOG_START, { ns: NS.EXPERIMENTS }),
      namespace: NS.EXPERIMENTS,
      type: FluentMessageBarType.info,
      groupId: SuccessGroupIds.SESSION_DETAILS,
    };

    addSessionPanelMessage(startMessage);

    const baseUrl = resultsExplorerEndpoint;
    const downloadPath = companyName
      ? `${baseUrl}/file/download/folder?resultCollectionId=${path}&companyName=${companyName}`
      : `${baseUrl}/file/download/folder?resultCollectionId=${path}`;

    this.downloadUrl(downloadPath, outputFile);
  };

  private downloadUrl = async (url: any, filename: string) => {
    const { addSessionPanelMessage, enableDownloadButton } = this.sessionDetailsStore;
    const outputFile = Files.LOGS_ZIP;
    const sessionDetailsErrorGroupId = '';

    const headers = new Headers();

    if (filename === outputFile) {
      headers.append('Authorization', `Bearer ${(ls as any).get('resultsToken')}`);
    } else {
      const token = await MsalAuthorization.getToken();

      headers.append('Authorization', `Bearer ${token}`);
    }

    try {
      const response = await fetch(url, { headers });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const successMessage: SystemMessageType = {
        message: format(t('download-logs-complete', { ns: NS.EXPERIMENTS }), {
          url,
          status: response.status,
          statusText: response.statusText,
        }),
        namespace: NS.EXPERIMENTS,
        type: FluentMessageBarType.success,
        groupId: SuccessGroupIds.SESSION_DETAILS,
      };

      const element = document.createElement('a');

      element.setAttribute('href', URL.createObjectURL(blob));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      element.click();

      addSessionPanelMessage(successMessage);
      enableDownloadButton();
    } catch (error) {
      console.error('[SessionsViewModel:downloadUrl] Download failed:', error);

      const failMessage: SystemMessageType = {
        message: format(t('download-failed-template', { ns: NS.EXPERIMENTS }), {
          url,
          status: error.status,
          statusText: error.statusText,
        }),
        namespace: NS.EXPERIMENTS,
        type: FluentMessageBarType.error,
        groupId: FailGroupIds.SESSION_DETAILS,
      };

      addSessionPanelMessage(failMessage);
      enableDownloadButton();
    }
  };
}

export default SessionDetailsViewModel;
