import { SnackbarOrigin } from '@material-ui/core/Snackbar';

import { NotificationSeverities, NotificationSeverityType } from '@/components/_air/notifications/utilities';

import { notificationHubService } from './NotificationHubService';

type ShowNotificationType = ((message: string, severity: NotificationSeverityType, position: SnackbarOrigin) => void) | null;

const defaultPosition: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

class NotificationService {
  showNotification: ShowNotificationType = null;

  subscribeNotifications(showNotificationMethod: ShowNotificationType): void {
    this.showNotification = showNotificationMethod;
  }

  unsubscribeNotifications(showNotificationMethod: ShowNotificationType): void {
    if (this.showNotification !== showNotificationMethod) return;

    this.showNotification = null;
  }

  emitNotification(message: string, severity: NotificationSeverityType, position: SnackbarOrigin): void {
    if (!this.showNotification) throw new Error('The notification pool is not subscribed');

    this.showNotification(message, severity, position);
  }

  showSuccess(message: string, position?: SnackbarOrigin): void {
    this.emitNotification(message, NotificationSeverities.success, position || defaultPosition);
    notificationHubService.addNotification('success', message);
  }

  showInfo(message: string, position?: SnackbarOrigin): void {
    this.emitNotification(message, NotificationSeverities.info, position || defaultPosition);
    notificationHubService.addNotification('info', message);
  }

  showWarning(message: string, position?: SnackbarOrigin): void {
    this.emitNotification(message, NotificationSeverities.warning, position || defaultPosition);
    notificationHubService.addNotification('warning', message);
  }

  showError(message: string, position?: SnackbarOrigin): void {
    this.emitNotification(message, NotificationSeverities.error, position || defaultPosition);
    notificationHubService.addNotification('error', message);
  }
}

export const notificationService = new NotificationService();
