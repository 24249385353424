/*----------------------------------------------------------------------------------------------------------------------------
# PortalSettings.tsx: 
# This Component is our Settings Component. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ComboBox, DefaultButton, Dropdown, Label, Toggle } from '@fluentui/react';
import { IComboBox, IComboBoxOption } from '@fluentui/react/lib/ComboBox';
import { t } from 'i18next';
import moment from 'moment-timezone';

import { EnvironmentNames, StorageDevEnvKey } from '@/constants/EnvironmentConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS, PageSizeOptions } from '@/constants/SystemConstants';
import PortalSettingsStyles from '@/pages/PortalSettings/PortalSettingsStyles';
import AppSettingsService from '@/services/AppSettingsService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { isDevEnv } from '@/utils/Env';

import styles from './PortalSettings.module.css'; // Apply any Settings Page level CSS Module styling here.

const PortalSettingsFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, experimentsStore, userInfoStore, userSettingsStore } = rootStore;
  const {
    doReset,
    enableAirNotificationHub,
    isAdvancedMode,
    isAirHidden,
    isDarkMode,
    isDebugMode,
    isDeveloperMode,
    isLabsHidden,
    isMockDataMode,
    isOfflineMode,
    isOutlookMode,
    isPartnerMode,
    toggleAdvancedMode,
    toggleAirHidden,
    toggleAirNotificationHub,
    toggleDarkMode,
    toggleDebugMode,
    toggleDeveloperMode,
    togglePartnerMode,
    toggleLabsHidden,
    toggleMockDataMode,
    toggleOfflineMode,
    toggleOutlookMode,
  } = appSettingsStore;
  const { pageSize, setPageSize } = experimentsStore;
  const { timeZone, setTimeZone } = userSettingsStore;
  const { isInternalUser, isMicrosoftAccount } = userInfoStore;

  const localStorage = rootStore.localStorage;

  const timeZoneArray = moment.tz.names();
  const timeZoneOptions = Array.from(new Set(timeZoneArray)).map((timeZone: string) => ({
    key: timeZone,
    text: timeZone,
  }));

  const envOptions: IComboBoxOption[] = [
    { key: EnvironmentNames.DEV, text: t(EnvironmentNames.DEV, { ns: NS.DEV_ENVIRONMENTS }) },
    { key: EnvironmentNames.VEGA_DEV, text: t(EnvironmentNames.VEGA_DEV, { ns: NS.DEV_ENVIRONMENTS }) },
  ];

  const devEnvValue = localStorage.getValue(StorageDevEnvKey);
  let currentKey: string;

  if (devEnvValue === EnvironmentNames.DEV) {
    currentKey = EnvironmentNames.DEV;
  } else if (devEnvValue === EnvironmentNames.VEGA_DEV) {
    currentKey = EnvironmentNames.VEGA_DEV;
  } else {
    currentKey = EnvironmentNames.DEVELOPER_SLOT;
    envOptions.push({ key: currentKey, text: devEnvValue });
  }

  const setEnv = React.useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
      if (!option && value) {
        // Custom value (Developer Slot)
        localStorage.setValue(StorageDevEnvKey, value);
      } else if (option) {
        // Dev or VegaDev
        localStorage.setValue(StorageDevEnvKey, option.key);
      }

      window.location.reload();
    },
    [],
  );

  const resetClick = () => {
    if (confirm(t('confirm-reset', { ns: NS.ERRORS }))) {
      doReset();
    }
  };

  return (
    <div className={`fullscreen ${styles['portal-settings']}`}>
      <Label>
        <Trans ns={NS.TITLES as Namespace}>user-settings</Trans>
      </Label>
      <p>
        <Trans ns={NS.HELP as Namespace}>theme-options-text</Trans>
      </p>
      <Dropdown
        title={t('time-locale', { ns: NS.TITLES })}
        label={t('time-locale', { ns: NS.TITLES })}
        dropdownWidth="auto"
        selectedKey={timeZone}
        options={timeZoneOptions}
        styles={PortalSettingsStyles.timeZoneDropdown}
        onChange={(_event, option) => {
          setTimeZone(option.text);
        }}
      />
      <p>
        <Trans ns={NS.HELP as Namespace}>time-zone-text</Trans>
      </p>
      <div>
        <Toggle label={t('dark-mode', { ns: NS.TITLES })} checked={isDarkMode} onChange={toggleDarkMode} inlineLabel />
      </div>

      {isMicrosoftAccount && (
        <>
          <Label>
            <Trans ns={NS.TITLES as Namespace}>developer-settings</Trans>
          </Label>
          <p>
            <Trans ns={NS.HELP as Namespace}>developer-settings-text</Trans>
          </p>
          <div>
            <Toggle label={t('debug-mode', { ns: NS.TITLES })} checked={isDebugMode} onChange={toggleDebugMode} inlineLabel />
          </div>
          <div>
            <Toggle
              label={t('developer-mode', { ns: NS.TITLES })}
              checked={isDeveloperMode}
              onChange={toggleDeveloperMode}
              inlineLabel
            />
          </div>
        </>
      )}

      {isDeveloperMode && (
        <>
          <Label>
            <Trans ns={NS.TITLES as Namespace}>air-settings</Trans>
          </Label>
          <p>
            <Trans ns={NS.HELP as Namespace}>air-settings-text</Trans>
          </p>
          <div>
            <Toggle
              label={t('air-enable-notification-hub', { ns: NS.TITLES })}
              checked={enableAirNotificationHub}
              onChange={toggleAirNotificationHub}
              inlineLabel
            />
          </div>

          <Label>
            <Trans ns={NS.TITLES as Namespace}>labs-settings</Trans>
          </Label>
          <p>
            <Trans ns={NS.HELP as Namespace}>labs-settings-text</Trans>
          </p>
          <p>
            {/* 
            NOTE: The Reset Settings option below will not reset this value. This is in part due 
            because this setting comes from the Experiments store, while most other options come 
            from an AppSettings store. This is an edge case that we can look at in the future. 
            */}
            {isPartnerMode && (
              <Dropdown
                placeholder={t('select-page-size', { ns: NS.EXPERIMENTS })}
                title={t('select-page-size', { ns: NS.EXPERIMENTS })}
                value={pageSize}
                selectedKey={pageSize || AppSettingsService.DEFAULT_PAGE_SIZE}
                options={PageSizeOptions}
                className={styles['dropdown']}
                styles={PortalSettingsStyles.dropdown}
                onChange={(event, option) => setPageSize(option.key as number)}
              />
            )}
          </p>

          <Label>
            <Trans ns={NS.TITLES as Namespace}>internal-tools</Trans>
          </Label>
          <p>
            <Trans ns={NS.HELP as Namespace}>internal-tools-text</Trans>
          </p>
          {isDevEnv() && (
            <>
              <Label>
                <Trans ns={NS.TITLES as Namespace}>env-selection</Trans>
              </Label>
              <ComboBox
                title={t('env-selection', { ns: NS.TITLES })}
                selectedKey={currentKey}
                allowFreeform={true}
                options={envOptions}
                styles={PortalSettingsStyles.envSelectorCombobox}
                onChange={setEnv}
              />
              <p>
                <Trans ns={NS.HELP as Namespace}>environment-selection-text</Trans>
              </p>
            </>
          )}
          <p>
            <Link to={Navigation.GANYMEDE.PLAYGROUND} title={t('playground', { ns: NS.TITLES })}>
              <DefaultButton text={t('playground', { ns: NS.TITLES })} disabled={false} checked={false} />
            </Link>{' '}
            <DefaultButton text={t('reset-settings', { ns: NS.TITLES })} disabled={false} checked={false} onClick={resetClick} />
          </p>
          <div>
            <Toggle
              label={t('advanced-mode', { ns: NS.TITLES })}
              checked={isAdvancedMode}
              onChange={toggleAdvancedMode}
              inlineLabel
            />
            <p>
              <Trans ns={NS.HELP as Namespace}>advanced-mode-text</Trans>
            </p>
            {isAdvancedMode && (
              <div>
                <Toggle
                  label={t('mock-data', { ns: NS.TITLES })}
                  checked={isMockDataMode}
                  onChange={toggleMockDataMode}
                  inlineLabel
                />
              </div>
            )}
          </div>

          {isAdvancedMode && isInternalUser && (
            // This functionality is currently for Internal use only.
            <>
              <Label>
                <Trans ns={NS.TITLES as Namespace}>hackathon</Trans>
              </Label>
              <p>
                <Trans ns={NS.HELP as Namespace}>hackathon-text</Trans>
              </p>
              <p>
                <Link to={Navigation.GANYMEDE.HACKATHON_DASHBOARD} title={t('hackdash', { ns: NS.TITLES })}>
                  <DefaultButton text={t('hackdash', { ns: NS.TITLES })} disabled={false} checked={false} />
                </Link>
              </p>
            </>
          )}

          {isInternalUser && (
            // This functionality is currently for Internal use only.
            <>
              <div>
                <Toggle
                  label={t('offline-mode', { ns: NS.TITLES })}
                  checked={isOfflineMode}
                  onChange={toggleOfflineMode}
                  inlineLabel
                />
              </div>
            </>
          )}

          <Label>
            <Trans ns={NS.TITLES as Namespace}>feature-flags</Trans>
          </Label>
          <p>
            <Trans ns={NS.HELP as Namespace}>feature-flags-text</Trans>
          </p>
          <div>
            <Toggle label={t('hide-air', { ns: NS.TITLES })} checked={isAirHidden} onChange={toggleAirHidden} inlineLabel />
          </div>
          <div>
            <Toggle label={t('hide-labs', { ns: NS.TITLES })} checked={isLabsHidden} onChange={toggleLabsHidden} inlineLabel />
          </div>
          <div>
            <Toggle label={t('outlook-mode', { ns: NS.TITLES })} checked={isOutlookMode} onChange={toggleOutlookMode} inlineLabel />
          </div>

          {isMicrosoftAccount && (
            // TODO: [25555525]
            // This functionality should move to the Internal User flag once that is
            // confirmed to work in Production.
            <div>
              <Toggle
                label={t('toggle-is-partner', { ns: NS.TITLES })}
                checked={isPartnerMode}
                onChange={togglePartnerMode}
                inlineLabel
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const PortalSettings = observer(PortalSettingsFC);

export default PortalSettings;
