import React from 'react';

const regularSessionPanel = {
  header: {
    overflow: 'hidden',
  },
  headerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  main: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    width: 'calc(100% - 522px)',
  },
  scrollableContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
  contentInner: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
  detailsBody: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
};

const wideSessionPanel = {
  header: {
    overflow: 'hidden',
  },
  headerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  main: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    width: 'calc(100% - 325px)',
  },
  scrollableContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
  contentInner: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
  detailsBody: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    overflow: 'hidden',
  },
};

const noWrapDefaultButton = {
  textContainer: {
    overflow: 'hidden',
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const styles = {
  noWrapDefaultButton,
  regularSessionPanel,
  wideSessionPanel,
};

export default styles;
