import React from 'react';

import '@/styles/Air.css';

export const SeachDataForm = (props: any): JSX.Element => {
  const [search, setSearch] = React.useState('');

  const onInputChange = (value) => {
    setSearch(value);
    props.onSearch(value);
  };

  return (
    <input
      type="text"
      className="form-control"
      placeholder={props.placeholder}
      value={search}
      style={props.style}
      onChange={(e) => onInputChange(e.target.value)}
    />
  );
};
