import React from 'react';
import { observer } from 'mobx-react';
import { PartialTheme } from '@fluentui/react/lib/Theme'; // FluentUI 8
import { createDarkTheme, createLightTheme, Theme } from '@fluentui/react-components'; // FluentUI 9
import { brandWeb, createV8Theme } from '@fluentui/react-migration-v8-v9';

import { darkBase, lightBase } from '@/layouts/Themes/ThemeConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const ThemeTemplateFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { setV9Theme, setV8Theme, isDarkMode } = appSettingsStore;

  const lightThemeV9: typeof Theme = createLightTheme(brandWeb);
  const darkThemeV9: typeof Theme = createDarkTheme(brandWeb);

  const lightThemeV8: PartialTheme = createV8Theme(brandWeb, lightThemeV9, false);
  const darkThemeV8: PartialTheme = createV8Theme(brandWeb, darkThemeV9, true);

  React.useEffect(() => {
    const v8Theme = isDarkMode ? darkThemeV8 : lightThemeV8;
    const v9Theme = isDarkMode ? darkThemeV9 : lightThemeV9;

    setV8Theme(v8Theme);
    setV9Theme(v9Theme);
  }, [isDarkMode]);

  const setCSSVariables = (theme: any) => {
    for (const value in theme) {
      document.documentElement.style.setProperty(`--${value}`, theme[value as string]);
    }
  };

  setCSSVariables(isDarkMode ? darkBase : lightBase);

  return <></>;
};

const ThemeTemplate = observer(ThemeTemplateFC);

export default ThemeTemplate;
