import React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, SelectionMode } from '@fluentui/react/lib/DetailsList';

import { SortedColumnPageKeys } from '@/constants/SystemConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import config from './LabSystems.config.json';
import { LabSystemsVCType } from './LabSystemsType';

const LabSystemsTemplateFC: React.FC<LabSystemsVCType> = (props: LabSystemsVCType) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labSystemsStore, paginationStore } = rootStore;
  const { paginationType } = paginationStore;
  const {
    filteredLabSystems,
    groupByColumn,
    hideLabSystemsColumnEditor,
    isLabSystemsColumnEditorOpen,
    isLoading,
    labSystemsColumnList,
    labSystemsEntireColumns,
    setLabSystemsColumnList,
    tableGroups,
  } = labSystemsStore;

  // Props Const
  const { commandBarItems, farItems, filterItems, columnEditorKey, rackSystemsOnRenderRow } = props;

  return (
    <>
      <PageCommandBar items={commandBarItems} farItems={farItems}></PageCommandBar>
      <PageFilterBar items={filterItems}></PageFilterBar>

      <TableViewController
        columns={modifiedColumnConfiguration(labSystemsColumnList)}
        items={filteredLabSystems}
        displayColumns={config.labSystemsDisplayColumns}
        groups={tableGroups}
        groupByColumn={groupByColumn}
        groupByColumns={config.labSystemsColumnDefinitions}
        selectionMode={SelectionMode.single}
        checkboxVisibility={CheckboxVisibility.onHover}
        enableToolBar={false}
        isStickyHeader={true}
        isCompact={true}
        isLoading={isLoading}
        paginationType={paginationType}
        selectionPreservedOnEmptyClick={true}
        onRenderRow={rackSystemsOnRenderRow}
        pageName={SortedColumnPageKeys.LAB_DETAILS_SYSTEMS_PAGE}
      />

      <ColumnEditorPanelTemplate
        isColumnEditorOpen={isLabSystemsColumnEditorOpen}
        columnsList={labSystemsColumnList}
        setColumnsList={setLabSystemsColumnList}
        hideColumnEditor={hideLabSystemsColumnEditor}
        entireColumns={labSystemsEntireColumns}
        columnEditorKey={columnEditorKey}
      ></ColumnEditorPanelTemplate>
    </>
  );
};

export const LabSystemsTemplate = observer(LabSystemsTemplateFC);
