import { MessageBarType } from '@fluentui/react';
import { t } from 'i18next';
import format from 'string-template';

import { TimeInterval } from '@/constants/DateFormatConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnNames, PivotItems } from '@/constants/ResultExplorerConstants';
import { FailGroupIds, Files, FilterOptions, Namespaces as NS } from '@/constants/SystemConstants';
import { paginateData } from '@/partials/Pagination/Pagination';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { ActionTypeWithParamReturn } from '@/types/AppSettingsTypes';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { copyToClipboard } from '@/utils/Helpers';

import ResultExplorerStore from './ResultExplorerStore';
import { ResultExplorerType } from './ResultExplorerTypes';

class ResultExplorerViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected userSettingsStore: UserSettingsStore;
  protected resultExplorerStore: ResultExplorerStore;
  protected paginationStore: PaginationStore;

  protected addGlobalMessage: ActionTypeWithParamReturn;
  protected deleteGroupMessages: ActionTypeWithParamReturn;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, paginationStore, resultExplorerStore, systemMessageStore, userSettingsStore } = rootStore;
    const { addGlobalMessage, deleteGroupMessages } = systemMessageStore;

    this.appSettingsStore = appSettingsStore;
    this.userSettingsStore = userSettingsStore;
    this.resultExplorerStore = resultExplorerStore;
    this.paginationStore = paginationStore;

    this.addGlobalMessage = addGlobalMessage;
    this.deleteGroupMessages = deleteGroupMessages;
  }

  public copyTestRunURL = (resultCollectionId: string, templateId: string, successGroupId: string, companyName: string) => {
    const copyText = `${window.location.origin.toLowerCase()}${
      Navigation.LABS.TEST_RUN
    }/${templateId}=${resultCollectionId}&companyName=${companyName}`;

    copyToClipboard(copyText);

    const copyMessage = format(t('link-copy-template', { ns: NS.RESULT_EXPLORER }), {
      copyText: copyText,
    });
    const successMessage: SystemMessageType = {
      message: copyMessage,
      type: MessageBarType.success,
      groupId: successGroupId,
    };

    this.addGlobalMessage(successMessage);
  };

  public validateDates = (resultExplorerStartDate: Date, resultExplorerEndDate: Date) => {
    this.deleteGroupMessages(FailGroupIds.RESULT_EXPLORER);

    const differenceInMilliseconds = resultExplorerEndDate.getTime() - resultExplorerStartDate.getTime();
    const tmpDifferenceInHours = differenceInMilliseconds / TimeInterval.ONE_DAY;
    const differenceInHours = Math.round(tmpDifferenceInHours);

    if (differenceInHours > parseInt(FilterOptions.LAST_THIRTY_DAYS)) {
      const failMessage = {
        message: t('difference-in-months', { ns: NS.RESULT_EXPLORER }),
        type: MessageBarType.error,
        groupId: FailGroupIds.RESULT_EXPLORER,
      };

      this.addGlobalMessage(failMessage);
      return false;
    } else if (differenceInHours < 0) {
      const failMessage = {
        message: t('date-difference-validation', { ns: NS.RESULT_EXPLORER }),
        type: MessageBarType.error,
        groupId: FailGroupIds.RESULT_EXPLORER,
      };

      this.addGlobalMessage(failMessage);

      return false;
    }

    return true;
  };

  public buildDropDownData = (activeTab: string) => {
    const { allData, setExperimentNameList, setHostNameList, setExecutedByList, setLabIdList, setRunStatusList } =
      this.resultExplorerStore;

    let names = [];
    let hostNames = [];
    let executedByMails = [];
    let labIds = [];
    let runStatus = [];

    const getUniqueValues = (key: string) => {
      const uniqueValuesSet = new Set(
        allData?.map((result) => result[key as keyof typeof result])?.filter((value) => value !== undefined && value !== null),
      );

      if (uniqueValuesSet?.size > 0) {
        return Array.from(uniqueValuesSet).map((value) => ({ key: value, text: value }));
      }

      return [];
    };

    if (activeTab === PivotItems.MYLAB_RESULTS) {
      names = getUniqueValues(ColumnNames.EXPERIMENT_NAME);
      executedByMails = getUniqueValues(ColumnNames.LAST_EXECUTED_BY);
      labIds = getUniqueValues(ColumnNames.LAB_ID);
      runStatus = getUniqueValues(ColumnNames.LAST_STATUS);
    } else {
      names = getUniqueValues(ColumnNames.TEST_NAME);
      hostNames = getUniqueValues(ColumnNames.HOST_NAME);
      runStatus = getUniqueValues(ColumnNames.STATUS);

      if (activeTab === PivotItems.ALL_RESULTS) {
        executedByMails = getUniqueValues(ColumnNames.EXECUTED_BY);
      }
    }

    setExperimentNameList(names);
    setHostNameList(hostNames);
    setExecutedByList(executedByMails);
    setLabIdList(labIds);
    setRunStatusList(runStatus);
  };

  public getFilteredResult = () => {
    const { searchValue, allData, experimentNames, hostNames, labIds, statuses, executedByEmails } = this.resultExplorerStore;

    if (!allData?.length) {
      return [];
    }

    const lowerCaseValue = searchValue?.toLowerCase();
    let filteredResult: ResultExplorerType[] = allData;

    const applyFilter = (
      data: ResultExplorerType[],
      filterValues: string[],
      fieldExtractor: (item: ResultExplorerType) => string,
    ) => {
      if (filterValues.length) {
        return data.filter((item) => filterValues.includes(fieldExtractor(item) || ''));
      }

      return data;
    };

    if (lowerCaseValue?.length) {
      filteredResult = filteredResult.filter(
        (e) =>
          e.id?.toLowerCase().includes(lowerCaseValue) ||
          e.experimentName?.toLowerCase().includes(lowerCaseValue) ||
          e.testName?.toLowerCase().includes(lowerCaseValue),
      );
    }

    filteredResult = applyFilter(filteredResult, experimentNames, (e) => e.experimentName || e.testName);
    filteredResult = applyFilter(filteredResult, hostNames, (e) => e.hostName);
    filteredResult = applyFilter(filteredResult, executedByEmails, (e) => e.lastExecutedBy || e.executedBy);
    filteredResult = applyFilter(filteredResult, labIds, (e) => e.labId);
    filteredResult = applyFilter(filteredResult, statuses, (e) => e.status || e.lastStatus);

    return filteredResult;
  };

  searchData = (currentPage?: number): void => {
    const { allData, setFilteredData } = this.resultExplorerStore;
    const { paginationType, setPaginationType } = this.paginationStore;

    if (allData?.length > 0) {
      const filteredResult: ResultExplorerType[] = this.getFilteredResult() || allData;
      const hasFiltersApplied = filteredResult?.length !== allData?.length;

      const { paginatedItems, updatedPaginationType } = paginateData(
        filteredResult,
        paginationType,
        hasFiltersApplied,
        currentPage,
      );

      setFilteredData(paginatedItems);
      setPaginationType(updatedPaginationType);
    }
  };
}

export default ResultExplorerViewModel;
