export enum ScreenPortNames {
  xLarge = 'xLarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  xSmall = 'xSmall',

  Mobile = 'mobile',
}

class ViewPortObserver {
  private viewPort!: ScreenPortNames;

  private enterSubscriptions = {
    xLarge: [],
    large: [],
    medium: [],
    small: [],
    xSmall: [],
    mobile: [],
  };

  private leaveSubscriptions = {
    xLarge: [],
    large: [],
    medium: [],
    small: [],
    xSmall: [],
    mobile: [],
  };

  constructor() {
    window.addEventListener('resize', this.onWindowResize);
  }

  public subscribeOnPortEnter = (screenPortName: ScreenPortNames, callback: () => void) => {
    this.enterSubscriptions[screenPortName as string].push(callback as never);
  };

  public unsubscribeOnPortEnter = (screenPortName: ScreenPortNames, callback: () => void) => {
    this.enterSubscriptions[screenPortName as string] = this.enterSubscriptions[screenPortName as string].filter(
      (cb) => cb !== callback,
    );
  };

  public subscribeOnPortLeave = (screenPortName: ScreenPortNames, callback: () => void) => {
    this.leaveSubscriptions[screenPortName as string].push(callback as never);
  };

  public unsubscribeOnPortLeave = (screenPortName: ScreenPortNames, callback: () => void) => {
    this.leaveSubscriptions[screenPortName as string] = this.leaveSubscriptions[screenPortName as string].filter(
      (cb) => cb !== callback,
    );
  };

  public checkViewPort = () => {
    this.setViewPort(window.innerWidth);
  };

  private onWindowResize = () => {
    this.checkViewPort();
  };

  private setViewPort = (width: number) => {
    if (width > 1200) {
      this.setAndNotify(ScreenPortNames.xLarge);
    }

    if (width <= 1200) {
      this.setAndNotify(ScreenPortNames.Large);
    }

    if (width <= 850) {
      this.setAndNotify(ScreenPortNames.Medium);
    }

    if (width <= 600) {
      this.setAndNotify(ScreenPortNames.Small);
    }

    if (width <= 570) {
      this.setAndNotify(ScreenPortNames.xSmall);
    }

    if (width <= 450) {
      this.setAndNotify(ScreenPortNames.Mobile);
    }
  };

  private setAndNotify = (screenPort: ScreenPortNames) => {
    if (this.viewPort === screenPort) return;

    this.viewPort && this.leaveSubscriptions[this.viewPort].forEach((sub: any) => sub());
    this.enterSubscriptions[screenPort as string].forEach((sub: any) => sub());

    this.viewPort = screenPort;
  };
}

export const viewPortObserver = new ViewPortObserver();
