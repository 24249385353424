import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { APIErrorInterface, UserSettings } from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class UserSettingsRequestService extends BaseRequestService {
  async getUserSettings(userId: any, cancelToken?: CancelToken): Promise<UserSettings | null> {
    const response = await this.get(`UserSettings/GetUserSettings/${userId}`, null, { cancelToken });

    return response?.data?.definition as UserSettings | null;
  }

  async createOrUpdateUserSettings(
    userId: any,
    userSettings: UserSettings,
    cancelToken?: CancelToken,
  ): Promise<UserSettings | APIErrorInterface> {
    const payload = {
      user: userId,
      ...userSettings,
    };

    const { data } = await this.post('UserSettings/CreateOrUpdateUserSettings', payload, { cancelToken });

    return data;
  }
}

export const userSettingsRequestService = new UserSettingsRequestService(backendEndpoint);
