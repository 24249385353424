import React from 'react';
import RGL, { DragOverEvent } from 'react-grid-layout';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { GridView } from '@/constants/GridViewConstants';
import WidgetTile from '@/partials/WidgetTile/WidgetTileTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { WidgetMappingType } from '@/mocks/Dashboards/LayoutWidgets';
import { WidgetPanelLibraryType } from '@/mocks/WidgetLibrary/LibraryData';

import ReactGridTemplate from './ReactGridTemplate';
import {
  GenerateDomType,
  LayoutItemType,
  OnDropType,
  OnLayoutChangeType,
  OnRemoveItemType,
  ReactGridDataType,
  ReactGridDropItemType,
  ReactGridViewCompactType,
  ReactGridViewControllerType,
} from './ReactGridTypes';

const ReactGridViewControllerFC: React.FC<ReactGridViewControllerType> = (props: ReactGridViewControllerType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const {
    widgetMappings,
    addWidgetMapping,
    deleteWidgetMapping,
    isDebugMode,
    isDeveloperMode,
    isEditMode,
    toggleEditMode,
    editModeMessage,
    droppingItem,
  } = appSettingsStore;

  const { viewModel, data: userData } = props;
  const { layout, setLayout } = viewModel;

  const cols = GridView.DEFAULT_COLS;
  const rows = GridView.DEFAULT_ROWS;
  const allowOverlap = false;
  const isBounded = true;
  const compactType: ReactGridViewCompactType = 'vertical';

  // This method is called when the user moves items around, not when anything is dropped or deleted.
  const onLayoutChange: OnLayoutChangeType = React.useCallback(
    (layout: LayoutItemType[]) => {
      setLayout(layout);

      return true;
    },
    [setLayout],
  );

  // We simply filter out the unwanted item and re-write the new layout.
  const onRemoveItem: OnRemoveItemType = (item: RGL.Layout, key: string) => {
    const removeCallback = (item: LayoutItemType) => item.i !== key;
    const newLayout: LayoutItemType[] = layout.filter(removeCallback);

    isDebugMode && console.log('[ReactGridViewControllerFC removeItem] New layout:', newLayout);

    setLayout(newLayout);
    deleteWidgetMapping(key);
  };

  // Take the new Item from the layout, modify some properties and set the new layout.
  // Once the user stops dragging and lands on our target dashboard area, the onAddItem() method
  // grabs the metadata that was created by the WidgetPanelFC.onDragStart() method and uses the
  // data to update our layout and widgetMappings data.
  const onAddItem: OnDropType = React.useCallback(
    (newLayout: RGL.Layout[], item: RGL.Layout, e: DragEvent) => {
      const metadata: string | undefined = e.dataTransfer?.getData('droppable-widget');
      const libraryWidget: WidgetPanelLibraryType = JSON.parse(metadata || '');

      isDebugMode && console.log('[ReactGridViewControllerFC onAddItem] Started for item:', libraryWidget);

      if (metadata) {
        const dragItem: RGL.Layout | undefined = newLayout.pop(); // This is the dragging Library item to add.
        const widgetId: string = dragItem?.i || uuidv4();
        const libraryKey: string = libraryWidget.key;
        const newLayoutItem: LayoutItemType = { ...dragItem, ...(libraryWidget.layout as RGL.Layout), i: widgetId };

        if (newLayoutItem) {
          const newMapping = { [widgetId]: libraryKey };

          isDebugMode && console.log('[ReactGridViewControllerFC onAddItem] newLayoutItem/newMapping:', newLayoutItem, newMapping);

          newLayout.push(newLayoutItem);
          setLayout(newLayout);
          addWidgetMapping(newMapping);
        }
      }
    },
    [isDebugMode, onLayoutChange],
  );

  const onDrop: OnDropType = onAddItem;

  const getTiles: GenerateDomType = (layout: LayoutItemType[], widgetMappings: WidgetMappingType): React.ReactElement[] => {
    const items = layout.map((item: LayoutItemType) => {
      const dataId: string = item.i;
      const widgetKey: string = widgetMappings[dataId as string];
      const record: ReactGridDataType | null = userData[widgetKey as string] || null;

      // Make sure the tiles are only moveable and editable in Edit mode.
      item.isDraggable = isEditMode;
      item.isResizable = isEditMode;

      return (
        <div key={dataId} data-id={dataId} data-grid={item}>
          <WidgetTile
            key={dataId}
            item={item}
            dataId={dataId}
            record={record}
            widgetMappings={widgetMappings}
            isEditMode={isEditMode}
            toggleEditMode={toggleEditMode}
            onRemoveItem={onRemoveItem}
          />
        </div>
      );
    });

    return items;
  };

  const children: React.ReactElement[] = getTiles(layout, widgetMappings);

  const onDropDragOver: (e: DragOverEvent) => false | ReactGridDropItemType | undefined = (
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    e: DragOverEvent,
  ) => {
    // NOTE: For now, please keep this commented code below, as we may want an example of using the dataTransfer object,
    // which may be useful for more complex interactions in our Dashboard.
    // const metadata: string | undefined = e.dataTransfer?.getData('droppable-widget');

    const dropItem: ReactGridDropItemType = {
      type: droppingItem?.type,
      i: droppingItem?.i,
      x: droppingItem?.x,
      y: droppingItem?.y,
      w: droppingItem?.w,
      h: droppingItem?.h,
    };

    // NOTE: When this is showing, it will log each time the user drags any item in the canvas, meaning it will quickly
    // clog up the console. We should only have this active during a Developer Mode debugging session.
    isDebugMode && isDeveloperMode && console.log('[WidgetPanelFC onDropDragOver] dropItem', dropItem);

    return dropItem;
  };

  return (
    <ReactGridTemplate
      isEditMode={isEditMode}
      editModeMessage={editModeMessage}
      rows={rows}
      cols={cols}
      layout={layout}
      allowOverlap={allowOverlap}
      isBounded={isBounded}
      compactType={compactType}
      onDrop={onDrop}
      onDropDragOver={onDropDragOver}
      onLayoutChange={onLayoutChange}
      onRemoveItem={onRemoveItem}
    >
      {children}
    </ReactGridTemplate>
  );
};

const ReactGridViewController = observer(ReactGridViewControllerFC);

export default ReactGridViewController;
