import { RootStore } from '@/stores/RootStore';

import ReliabilityViewModel from '../ReliabilityViewModel';

class TimeFilterViewModel extends ReliabilityViewModel {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }
}

export default TimeFilterViewModel;
