import React from 'react';
import { Link } from 'react-router-dom';

import { Message } from '@/components/_air/common/Message';
import { Labels, Messages, Routes } from '@/constants/AirConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { ExperimentResources, ExperimentSteps } from '@/types/_air';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import { ExperimentResourcesStatus } from './ExperimentResourcesStatus';
import { ExperimentStepStatus } from './ExperimentStepStatus';

import '@/styles/Air.css';

interface InstanceStatusParams {
  experimentId: string;
}

export const ExperimentInstancesStatus = (data: any): JSX.Element => {
  const params: InstanceStatusParams = data.match.params;
  const { experimentId } = params;
  const [experimentResources, setExperimentResources] = React.useState<ExperimentResources[]>();
  const [experimentSteps, setExperimentSteps] = React.useState<ExperimentSteps[]>();
  const cancellationToken = useCancellationToken();
  const [isBusy, setIsBusy] = React.useState<boolean>(true);
  const { state } = data.location;

  React.useEffect(() => {
    experimentStatusRequestService
      .getStatusOfExperimentResources(experimentId, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) {
          setExperimentResources(result);
        }
      })
      .catch((error) => {
        if (error.response?.data?.detail === Messages.noExperimentInstance) {
          return;
        }

        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
        void monitoringService.logError(error);
      })
      .finally(() => setIsBusy(false));

    experimentStatusRequestService
      .getStatusOfExperimentSteps(experimentId, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) {
          setExperimentSteps(result);
        }
      })
      .catch((error) => {
        if (error.response?.data?.detail === Messages.noExperimentInstance) {
          return;
        }

        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
        void monitoringService.logError(error);
      })
      .finally(() => setIsBusy(false));
  }, [cancellationToken, experimentId]);

  const dataExists = state?.parameter !== undefined;
  const title = Labels.experiments;
  const subTitle = dataExists ? `${state.parameter.experimentName} ${TITLE_DIVIDER} ${experimentId}` : experimentId;

  // NOTE: Keep these for now, as they will be used when we start implementing Ganymede BreadCrumb Component.
  const subTitlePath1 = dataExists
    ? `${Navigation.AIR.SCHEDULER_STATUS}/${state.parameter.team}/${state.parameter.goalId}/${state.parameter.experimentName}`
    : null;
  const subTitlePath2 =
    Routes?.targetGoalsStatus && dataExists
      ? `${Routes.targetGoalsStatus.link}/${state.parameter.team}/${state.parameter.goalId}/${state.parameter.experimentName}/${state.parameter.workload}`
      : null;

  // Render both scenarios
  const renderData = dataExists && (
    <div>
      <ExperimentResourcesStatus experimentResources={experimentResources} />
      <ExperimentStepStatus experimentSteps={experimentSteps} />
    </div>
  );

  const renderNoData = !dataExists && (
    <div>
      <Message message={Messages.noInstanceDataFound} />
    </div>
  );

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <main className="fullscreen container air-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.AIR.MANAGE_EXPERIMENT]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={true}>
            <div>{renderData || renderNoData}</div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
