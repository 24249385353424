import React from 'react';

// Applied to all pages, when a style is not specified.
const defaultPage = {
  // No declarations here.
};

const pivot = {
  height: '100%',
  root: { height: '44px' },
  itemContainer: {
    // Sets the height to allow the pivot tabs
    height: 'calc(100% - 64px)',
  },
};

const pivotItem = { height: '100%' };

const styles = { defaultPage, pivot, pivotItem };

export default styles;
