import React from 'react';
import { TabPanel, TabPanelProps } from 'react-tabs';

import '@/styles/Air.css';

export const LazyTabPanel = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<TabPanel> &
    Readonly<TabPanelProps> &
    Readonly<{ children?: React.ReactNode }>,
): JSX.Element => {
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (props.selected && !initialized) {
      setInitialized(true);
    }
  }, [props.selected, initialized]);

  return <TabPanel forceRender={initialized} {...props} />;
};

LazyTabPanel.tabsRole = 'TabPanel';
