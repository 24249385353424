import React from 'react';
import { observer } from 'mobx-react-lite';

import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SideBarTemplate from './SideBarTemplate';
import { SideBarVMType } from './SideBarTypes';

import styles from './SideBar.module.css'; // Apply any SideBar level CSS Module styling here.

const SideBarViewControllerFC: React.FC<{ viewModel: SideBarVMType }> = (props: { viewModel: SideBarVMType }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { portalLinks } = appSettingsStore;
  const { isSidebarCollapsed, toggleSidebarCollapsed, portal } = props.viewModel;
  const navLinks: NavLinkItemType[] = portalLinks;

  const getSideBarClass = (): string => {
    return isSidebarCollapsed ? styles['is-collapsed'] : '';
  };

  const getToggleIcon = (): string => {
    return isSidebarCollapsed ? 'DoubleChevronLeftMedMirrored' : 'DoubleChevronLeftMed';
  };

  return (
    <SideBarTemplate
      portal={portal}
      navLinks={navLinks}
      isSidebarCollapsed={isSidebarCollapsed}
      toggleSidebarCollapsed={toggleSidebarCollapsed}
      getSideBarClass={getSideBarClass}
      getToggleIcon={getToggleIcon}
    ></SideBarTemplate>
  );
};

const SideBarViewController = observer(SideBarViewControllerFC);

export default SideBarViewController;
