import React from 'react';
import { Link } from 'react-router-dom';

import { ExperimentStatusDonutChart } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentStatusDonutChart';
import { ExperimentStatusInfo } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentStatusInfo';
import { ExperimentStatusProgress } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentStatusProgress';
import { ExperimentStatusTable } from '@/components/_air/experiments/SingleEntryPoint/ExperimentCommonComponents/ExperimentStatusTable';
import { Labels, Messages, Routes } from '@/constants/AirConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { ExecutionGoalStatus } from '@/types/_air';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import '@/styles/Air.css';

interface ExprimentStatusParams {
  team: string;
  goal: string;
  experiment: string;
}

export const ExperimentStatus = (data: any): JSX.Element => {
  // We have two ways to view this page - the regular AIR way, and now through the
  // Ganymede Experiments page Modal.
  const isRegularMode = !!data.match;
  const params: ExprimentStatusParams = isRegularMode ? data.match.params : data;

  const { team: teamName, goal: executionGoalId, experiment: experimentName } = params;
  const [status, setStatus] = React.useState<ExecutionGoalStatus>();
  const [isBusy, setIsBusy] = React.useState<boolean>(true);
  const cancellationToken = useCancellationToken();

  const title = Labels.experiments;
  const subTitle = experimentName;

  React.useEffect(() => {
    experimentStatusRequestService
      .getStatusOfExecutionGoal(teamName, executionGoalId, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) {
          setStatus(result);
        }
      })
      .catch((error) => {
        if (error.response?.data?.detail === Messages.noStatusDataAvailable) {
          return;
        }

        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
        void monitoringService.logError(error);
      })
      .finally(() => setIsBusy(false));
  }, [cancellationToken, executionGoalId, teamName]);

  const renderStatus = () =>
    status && status.workloads ? (
      <div className="gbs-status-data">
        <ExperimentStatusProgress status={status} />

        <div className="gbs-chart-info-status row">
          <ExperimentStatusDonutChart status={status} />
          <ExperimentStatusInfo status={status} />
        </div>

        <ExperimentStatusTable status={status} team={teamName} executionId={executionGoalId} experimentName={experimentName} />
      </div>
    ) : (
      <p className="gbs-status-error">{Messages.noStatusDataAvailable}</p>
    );

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <main className="fullscreen container air-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.AIR.CREATE_EXPERIMENT]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={true}>
            <div id="wizard-page" className="gbs-status">
              {renderStatus()}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
