import React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';

import LabPanelViewController from '@/components/LabPanel/LabPanelViewController';
import { SortedColumnPageKeys } from '@/constants/SystemConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { DeleteLab } from '@/partials/LabComponents/DeleteLab/DeleteLab';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import config from './LabList.config.json';
import { LabListVCType } from './LabListTypes';

const LabListTemplateFC: React.FC<LabListVCType> = (props: LabListVCType) => {
  const { commandBarItems, filterItems, farItems, selection, isColumnEditorOpen, columnEditorKey, hideColumnEditor } = props;

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labListStore, paginationStore } = rootStore;
  const { paginationType } = paginationStore;
  const { filteredLabs, isDeleteLabOpen, isLoading, labsColumnList, labsEntireColumns, setLabsColumnList, setIsDeleteLabClose } =
    labListStore;

  return (
    <>
      <PageCommandBar items={commandBarItems} farItems={farItems}></PageCommandBar>
      <PageFilterBar items={filterItems}></PageFilterBar>

      <TableViewController
        columns={modifiedColumnConfiguration(labsColumnList)}
        items={filteredLabs}
        displayColumns={config.labsDisplayColumns}
        selection={selection}
        selectionMode={SelectionMode.single}
        checkboxVisibility={CheckboxVisibility.hidden}
        enableToolBar={false}
        isStickyHeader={true}
        isLoading={isLoading}
        paginationType={paginationType}
        selectionPreservedOnEmptyClick={true}
        pageName={SortedColumnPageKeys.LAB_LIST_PAGE}
      />
      <LabPanelViewController></LabPanelViewController>
      <ColumnEditorPanelTemplate
        isColumnEditorOpen={isColumnEditorOpen}
        columnsList={labsColumnList}
        setColumnsList={setLabsColumnList}
        hideColumnEditor={hideColumnEditor}
        entireColumns={labsEntireColumns}
        columnEditorKey={columnEditorKey}
      ></ColumnEditorPanelTemplate>
      {isDeleteLabOpen && <DeleteLab isModalOpen={isDeleteLabOpen} hideModal={setIsDeleteLabClose} />}
    </>
  );
};

export const LabListTemplate = observer(LabListTemplateFC);
