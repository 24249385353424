import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';

import DropdownViewController from '@/partials/Dropdown/DropdownViewController';

import { PageUserFiltersTemplateProp } from './PageUserFiltersType';

const PageUserFiltersTemplateFC: React.FC<PageUserFiltersTemplateProp> = (
  props: PageUserFiltersTemplateProp,
): React.ReactElement => {
  const { pagesDropDown, selectedPages, usersDropDown, selectedUserKey, stackTokens, resetPages, resetUsers, onButtonClick } =
    props;

  return (
    <div>
      <Stack horizontal tokens={stackTokens} verticalAlign="end">
        <div className="col-3">
          <DropdownViewController
            key={resetPages}
            multi
            dropdownProps={pagesDropDown.dropdownProps}
            selectedMultiKeys={selectedPages}
            callBack={pagesDropDown.callBack}
          />
        </div>
        <div className="col-2">
          <DropdownViewController
            key={resetUsers}
            selectedKey={selectedUserKey}
            dropdownProps={usersDropDown.dropdownProps}
            callBack={usersDropDown.callBack}
          />
        </div>
        <div className="col-1">
          <DefaultButton text="Filter" onClick={onButtonClick} />
        </div>
      </Stack>
    </div>
  );
};

const PageUserFiltersTemplate = observer(PageUserFiltersTemplateFC);

export default PageUserFiltersTemplate;
