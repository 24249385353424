import { CancelToken } from 'axios';

import BaseRequestService from './RequestService';

class CosmosRequestService extends BaseRequestService {
  async createEntry(queryParams: object, cancelToken?: CancelToken): Promise<boolean> {
    const response = await this.post('cosmos', queryParams, { cancelToken });
    return response?.data as boolean;
  }

  async updateEntry(queryParams: object, cancelToken?: CancelToken): Promise<boolean> {
    const response = await this.put('cosmos', queryParams, { cancelToken });
    return response?.data as boolean;
  }

  async getEntry(queryParams: object, cancelToken?: CancelToken): Promise<string> {
    const response = await this.get('cosmos', queryParams, { cancelToken });
    return response?.data as string;
  }

  async deleteEntry(queryParams: object, cancelToken?: CancelToken): Promise<boolean> {
    const response = await this.delete('cosmos', queryParams, { cancelToken });
    return response?.data as boolean;
  }
}

export const cosmosRequestService = new CosmosRequestService();
