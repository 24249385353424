/* eslint-disable indent */
import React from 'react';
import { DefaultButton, GroupHeader, Icon, IconButton, IObjectWithKey, MarqueeSelection, Modal, SearchBox } from '@fluentui/react';
import { CheckboxVisibility, DetailsList } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';

import '@/styles/Labs.css';

export interface IDetailsListItem {
  key: number;
  slot: number;
  hostName: string;
  ipAddress: string;
  macAddress: string;
  modelName: string;
  osImage: string;
  status: string;
  rackManager: string;
}

export const BatchOperationComponent = (props: any) => {
  const [machineList, setMachineList] = React.useState<any[]>([]);
  const [groupItem, setGroupItem] = React.useState<any>({});
  const [filterText, setFilterText] = React.useState<string>('');

  const batchTitleId = useId('batchTitle');
  const cancelIcon = { iconName: SystemIcons.CANCEL };

  const selection: Selection = new Selection({
    onSelectionChanged: () => addSelectedMachines(selection),
  });

  const onRenderGroupHeader = (props: any) => (
    <GroupHeader
      styles={{ check: { display: 'flex', opacity: 1 } }}
      {...props}
      onRenderGroupHeaderCheckbox={(props) => <input type="checkbox" {...props} aria-label="checkbox" />}
    />
  );

  const handleChange = (event?: React.ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      setFilterText(event.target?.value);
    }
  };

  const results = !filterText
    ? machineList
    : machineList.filter((item) => item.tags.some((item2: string) => item2.toLowerCase().includes(filterText.toLowerCase())));

  const columns = [
    {
      key: 'column1',
      name: 'Slot',
      fieldName: 'slot',
      minWidth: 25,
      maxWidth: 35,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Host Name',
      fieldName: 'hostName',
      minWidth: 130,
      maxWidth: 140,
      isResizable: true,
    },
    {
      key: 'column3',
      name: 'IP Address',
      fieldName: 'ipAddress',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: 'column4',
      name: 'Tags',
      fieldName: 'tags',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column5',
      name: 'MAC Address',
      fieldName: 'macAddress',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: 'column6',
      name: 'OS Image Name',
      fieldName: 'osImage',
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'column7',
      name: 'Status',
      fieldName: 'status',
      minWidth: 180,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const ListHelper = (
    arr: {
      key: any;
      slot: any;
      hostName: any;
      tags: any;
      ipAddress: any;
      macAddress: any;
      osImage: any;
      status: any;
      rackManager: any;
    }[],
    item: string | any[],
    device: string,
  ) => {
    let skippedIdx = 0;

    for (let i = 0; i < item.length; i++) {
      const tag = item[i as number].Tags
        ? item[i as number].Tags.map((e: { TagName: any }, idx: number) =>
            idx === item[i as number].Tags.length - 1 ? `${e.TagName}` : `${e.TagName}, `,
          )
        : null;
      const osFriendlyName = props.getosFriendlyName(item[i as number].OsImageName);

      if (item[i as number].Status !== Labels.CSDeleted) {
        arr.push({
          key: item[i as number].MachineId,
          slot: item[i as number].SlotNumber,
          hostName: item[i as number].Name,
          tags: tag,
          ipAddress: item[i as number].IPAddress,
          macAddress: item[i as number].MacAddress,
          osImage: osFriendlyName,
          status: item[i as number].Status,
          rackManager: device,
        });
      } else {
        skippedIdx++;
      }
    }

    const returnObj = {
      populatedArr: arr,
      skippedIdx,
    };

    return returnObj;
  };

  const GroupHelper = React.useCallback(() => {
    const groupList = [];
    let deviceList: any = [];
    let trackedIndex = 0; // group defined by the start index so keeping track of accumulated idx

    // populating rack manager devices
    for (let i = 0; i < props.rackMachineList.length; i++) {
      if (i !== 0 && props.rackMachineList[i - 1].machines.length > 0) {
        trackedIndex += props.rackMachineList[i - 1].machines.length;
      }

      if (props.rackMachineList[i as number].machines.length > 0) {
        const returnedObj = ListHelper(
          deviceList,
          props.rackMachineList[i as number].machines,
          props.rackMachineList[i as number].SerialNumber,
        );

        groupList.push({
          key: props.rackMachineList[i as number].RackId,
          name: `${props.rackMachineList[i as number].Name}: "${props.rackMachineList[i as number].SerialNumber}"`,
          ipAddress: props.rackMachineList[i as number].IpAddress,
          startIndex: trackedIndex,
          count: props.rackMachineList[i as number].machines.length - returnedObj.skippedIdx,
          level: 0,
        });

        deviceList = returnedObj.populatedArr;
        trackedIndex -= returnedObj.skippedIdx;
      }
    }

    // populating individual devices
    if (props.machineList.length > 0) {
      groupList.push({
        key: Labels.individualDevices + groupList.length,
        name: Labels.individualDevices,
        ipAddress: '',
        startIndex: deviceList.length,
        count: props.machineList.length,
        level: 0,
      });

      const returnedObj = ListHelper(deviceList, props.machineList, Labels.individualDevices);

      deviceList = returnedObj.populatedArr;
      trackedIndex -= returnedObj.skippedIdx;
    }

    setGroupItem(groupList);
    setMachineList(deviceList);
  }, [props.machineList, props.rackMachineList]);

  const populateRackArr = (item: { getSelectedIndices: () => any }) => {
    const selectedIdx = item.getSelectedIndices();

    // Adding all RM when all machines are selected
    if (selectedIdx.length === machineList.length) {
      const selectedRack = props.rackMachineList
        .filter((item: { machines: string | any[] }) => item.machines.length > 0)
        .map((item: { RackId: any }) => item.RackId);

      props.setSelectedRackKey(selectedRack);
    } else {
      // populating RM key list based on selected indices and group items' start idx and count.
      const rack = [];

      for (let i = 0; i < groupItem.length; i++) {
        if (selectedIdx.includes(groupItem[i as number].startIndex)) {
          const startIdx = selectedIdx.indexOf(groupItem[i as number].startIndex);

          if (
            selectedIdx[startIdx + groupItem[i as number].count - 1] ===
            groupItem[i as number].startIndex + groupItem[i as number].count - 1
          ) {
            if (!groupItem[i as number].key.toString().includes(Labels.individualDevices)) {
              rack.push(groupItem[i as number].key);
            }
          }
        }

        props.setSelectedRackKey(rack);
      }
    }
  };

  // NOTE: The input parameter was changed from "item: typeof Selection<IObjectWithKey>"
  const addSelectedMachines = (item: any) => {
    const machines = (item as Selection).getSelection();

    populateRackArr(item);

    const selectedMachines = [];

    for (let i = 0; i < machines.length; i++) {
      selectedMachines.push(machines[i as number].key);
    }

    props.setSelectedMachineKey(selectedMachines);
  };

  React.useEffect(() => {
    GroupHelper();
  }, [props, GroupHelper]);

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={batchTitleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.batchOperationContainer}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>{Labels.batchOperations}</h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="text-right">
            <DefaultButton
              className="btn-lg pt-2 pb-2 lab-action-btn"
              menuProps={props.menuProps}
              onClick={(e: any) => addSelectedMachines(e)}
            >
              {Buttons.batchOperation}
            </DefaultButton>
          </div>
          <br />
          <div className="text-left">
            <div className="row blue-bar">
              <div className="col-md-4 col-sm-12">
                <SearchBox
                  placeholder={t('search-tag', { ns: NS.COMMON })}
                  value={filterText}
                  className="blue-search-box"
                  styles={filterBar.searchBox}
                  onChange={handleChange}
                  onClear={() => {
                    setFilterText('');
                  }}
                />
              </div>
            </div>
            <MarqueeSelection selection={selection} className="mb-4">
              <div aria-hidden="true">
                <DetailsList
                  selectionPreservedOnEmptyClick
                  items={results}
                  columns={columns}
                  groups={filterText.length > 0 ? '' : groupItem}
                  groupProps={{ onRenderHeader: onRenderGroupHeader }}
                  setKey="items"
                  selection={selection}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  checkboxVisibility={CheckboxVisibility.always}
                  onRenderCheckbox={(props) => <input type="checkbox" {...props} aria-label="checkbox" />}
                />
              </div>
            </MarqueeSelection>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};
