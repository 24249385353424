import { action, computed, makeObservable, observable } from 'mobx';

import { ExperimentLogResultsType, ExperimentType, StepDetailsType, StepsType } from '@/components/Experiments/ExperimentsTypes';
import { CancellableStatuses, StatusesWithLogs } from '@/constants/ExperimentConstants';
import { SystemType } from '@/constants/SystemConstants';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class ExperimentDetailsStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  public experimentDetails: ExperimentType;
  public experimentLogs: ExperimentLogResultsType[];
  public experimentStep: StepsType;
  public experimentStepDetails: StepDetailsType;
  public isExperimentStepPopupOpen: boolean;
  public isExperimentModalOpen: boolean;
  public isExperimentPanelOpen: boolean;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables for individual Experiments.
      experimentDetails: observable,
      experimentLogs: observable,
      experimentStep: observable,
      experimentStepDetails: observable,
      isExperimentStepPopupOpen: observable,
      isExperimentModalOpen: observable,
      isExperimentPanelOpen: observable,

      // Actions modify the state.
      setExperimentDetails: action,
      setExperimentDetailStatus: action,
      setExperimentLogs: action,
      setExperimentStep: action,
      setExperimentStepDetails: action,
      setIsExperimentStepsOpen: action,
      setIsExperimentModalOpen: action,
      setIsExperimentPanelOpen: action,

      clearSelectedExperimentStep: action,
      openExperimentStepsPopup: action,
      closeExperimentStepsPopup: action,
      openExperimentModal: action,
      closeExperimentModal: action,
      openExperimentPanel: action,
      closeExperimentPanel: action,

      // Set computed properties.
      experimentHasData: computed,
      experimentHasLogs: computed,
      isAirExperiment: computed,
      isLabsExperiment: computed,
      isExperimentStepsSelected: computed,
      isExperimentStepsButtonDisabled: computed,
      canCancelExperiment: computed,
      canViewExperimentSteps: computed,
      canViewInstanceResults: computed,
      agentStepCount: computed,
      fullStepCount: computed,
    });

    this.rootStore = rootStore;
    this.localStorage = rootStore.localStorage;

    // Set initial state for these observables.
    this.experimentDetails = undefined;
    this.experimentLogs = undefined;
    this.experimentStep = undefined;
    this.experimentStepDetails = null;
    this.isExperimentStepPopupOpen = false;
    this.isExperimentModalOpen = false;
    this.isExperimentPanelOpen = false;
  }

  public setExperimentDetails = (details: ExperimentType) => {
    this.experimentDetails = details;
  };

  public setExperimentDetailStatus = (status: string) => {
    this.experimentDetails.status = status;
  };

  public setExperimentLogs = (data: ExperimentLogResultsType[]) => {
    this.experimentLogs = data;
  };

  public setExperimentStep = (step: StepsType) => {
    this.experimentStep = step;
  };

  public setExperimentStepDetails = (details: StepDetailsType) => {
    this.experimentStepDetails = details;
  };

  public setIsExperimentStepsOpen = (open: boolean) => {
    this.isExperimentStepPopupOpen = open;
  };

  public setIsExperimentModalOpen = (open: boolean) => {
    this.isExperimentModalOpen = open;
  };

  public setIsExperimentPanelOpen = (open: boolean) => {
    this.isExperimentPanelOpen = open;
  };

  public clearSelectedExperimentStep = () => {
    this.setExperimentStep(null);
    this.setExperimentStepDetails(null);
    this.setIsExperimentStepsOpen(false);
  };

  public openExperimentStepsPopup = () => {
    if (this.canViewExperimentSteps) {
      this.setIsExperimentStepsOpen(true);
    }
  };

  public closeExperimentStepsPopup = () => {
    this.setIsExperimentStepsOpen(false);
  };

  public openExperimentModal = () => {
    this.closeExperimentPanel();
    this.setIsExperimentModalOpen(true);
  };

  public closeExperimentModal = () => {
    this.setIsExperimentModalOpen(false);
  };

  public openExperimentPanel = () => {
    this.setIsExperimentPanelOpen(true);
  };

  public closeExperimentPanel = () => {
    this.setIsExperimentPanelOpen(false);
  };

  public get experimentHasData(): boolean {
    // If the object is not empty, we have some data.
    const hasData = !!this.experimentDetails;

    return hasData;
  }

  public get experimentHasLogs(): boolean {
    // If the logs object is not empty, we have some logs.
    const hasLogs = this.experimentLogs?.length > 0 || false;

    return hasLogs;
  }

  public get isAirExperiment(): boolean {
    return this.experimentDetails?.type === SystemType.AIR || false;
  }

  public get isLabsExperiment(): boolean {
    return this.experimentDetails?.type === SystemType.LABS || false;
  }

  public get canCancelExperiment(): boolean {
    if (!this.experimentDetails || this.isAirExperiment) {
      return false;
    }

    const status = this.experimentDetails.status.toLowerCase();
    const canCancel = CancellableStatuses.includes(status);

    return canCancel;
  }

  public get isExperimentStepsSelected(): boolean {
    // If the step details exists, it has been selected.
    const selected = !!this.experimentStep;

    return selected;
  }

  public get canViewExperimentSteps(): boolean {
    // If a step is selected, and the status is failed or succeeded, it can also be viewed.
    const canView = this.isExperimentStepsSelected;

    return canView;
  }

  public get canViewInstanceResults(): boolean {
    // If the Experiment Instance has logs, and the status is failed or succeeded,
    // the results can be viewed.
    if (!this.experimentDetails) {
      return false;
    }

    const hasLogs = !!this.experimentHasLogs;
    const status = this.experimentDetails.status.toLowerCase();
    const validStatus = StatusesWithLogs.includes(status);
    const canView = hasLogs && validStatus;

    return canView;
  }

  public get isExperimentStepsButtonDisabled(): boolean {
    // Disabled if a step cannot be viewed or the popup is already open.
    const disabled = !this.canViewExperimentSteps || this.isExperimentStepPopupOpen;

    return disabled;
  }

  public get agentStepCount(): number {
    const count = this.experimentDetails?.agentSteps?.length || 0;

    return count;
  }

  public get fullStepCount(): number {
    const count = this.experimentDetails?.fullSteps?.length || 0;

    return count;
  }
}

export default ExperimentDetailsStore;
