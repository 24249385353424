import { CancelToken } from 'axios';

import { GanymedeApiScope } from '@/authConfig';
import BaseRequestService from '@/services/request-services/RequestService';
import { APIErrorInterface, ExecutionGoalsList, ParameterNames, VirtualMachineList, WorkloadInfo } from '@/types/_air';
import { backendEndpoint } from '@/utils/Env';

class ExecutionGoalsRequestService extends BaseRequestService {
  async getExecutionGoalFullTemplateList(cancelToken?: CancelToken) {
    const { data } = await this.get('execution-goals/GetExecutionGoalTemplateList/full', null, { cancelToken });

    return data;
  }

  async getExecutionGoalTemplatesSummary(cancelToken?: CancelToken): Promise<string[] | APIErrorInterface> {
    const { data } = await this.get('execution-goals/GetExecutionGoalTemplateList/summary', null, {
      withCredentials: true,
      cancelToken,
    });

    return data;
  }

  async createExperimentFromTemplate(payload: ExecutionGoalsList, cancelToken?: CancelToken): Promise<any> {
    const { data } = await this.post('execution-goals/CreateExperimentFromTemplate', payload, {
      withCredentials: true,
      cancelToken,
    });

    return data;
  }

  async createExecutionGoal(executionGoal: string, validate?: boolean, cancelToken?: CancelToken): Promise<any> {
    if (validate) {
      const { data } = await this.post(
        'execution-goals/CreateExecutionGoal?validate=true',
        executionGoal,
        {
          withCredentials: true,
          cancelToken,
        },
        'application/json',
      );

      return data;
    } else {
      const { data } = await this.post(
        'execution-goals/CreateExecutionGoal',
        executionGoal,
        {
          withCredentials: true,
          cancelToken,
        },
        'application/json',
      );

      return data;
    }
  }

  async validateExperimentFromTemplate(
    strictvalidate: boolean,
    payload: ExecutionGoalsList,
    cancelToken?: CancelToken,
  ): Promise<any> {
    const { data } = await this.post(`execution-goals/ValidateExperimentFromTemplate/${strictvalidate}/`, payload, {
      withCredentials: true,
      cancelToken,
    });

    return data;
  }

  async editExperimentFromTemplate(
    payload: ExecutionGoalsList,
    executionGoalId: string,
    experimentName: string,
    cancelToken?: CancelToken,
  ): Promise<any> {
    const { data } = await this.put(
      `execution-goals/EditExperimentFromTemplate/executionGoalId=${executionGoalId}&experimentName=${experimentName}`,
      payload,
      { cancelToken },
    );

    return data;
  }

  async getFullExecutionGoalTemplate(
    goalTeamName: string,
    goalId: string,
    cancelToken?: CancelToken,
  ): Promise<string[] | APIErrorInterface> {
    const { data } = await this.get(`execution-goals/GetFullExecutionGoalTemplate/${goalTeamName}/${goalId}`, null, {
      cancelToken,
    });

    return data;
  }

  async getExecutionGoalTemplateParameters(
    templateId: string,
    teamName: string,
    cancelToken?: CancelToken,
  ): Promise<ParameterNames | APIErrorInterface> {
    const { data } = await this.get(`execution-goals/GetExecutionGoalTemplateParameters/${templateId}/${teamName}`, null, {
      cancelToken,
    });

    return data;
  }

  async getWorkloadRecommendations(
    profileName: string,
    platform: string,
    cpuId: string,
    cancelToken?: CancelToken,
  ): Promise<VirtualMachineList[] | APIErrorInterface> {
    const { data } = await this.post(`execution-goals/GetWorkloadRecommendations/${profileName}/${platform}/${cpuId}`, null, {
      cancelToken,
    });

    return data;
  }

  async getWorkloadRecommendationByCluster(
    profileName: string,
    platform: string,
    cluster: string,
    cancelToken?: CancelToken,
  ): Promise<VirtualMachineList[] | APIErrorInterface> {
    const { data } = await this.post(
      `execution-goals/GetWorkloadRecommendationByCluster/${profileName}/${platform}/${cluster}`,
      null,
      {
        cancelToken,
      },
    );

    return data;
  }

  async getWorkloadDescription(profileNames: string, cancelToken?: CancelToken): Promise<WorkloadInfo[]> {
    const { data } = await this.post(`execution-goals/GetWorkloadDescription/${profileNames}`, null, { cancelToken });

    return data;
  }

  async validateExperimentName(experimentName: string, cancelToken?: CancelToken): Promise<any> {
    const { data } = await this.post(`execution-goals/ValidateExperimentName/${experimentName}`, null, { cancelToken });

    return data;
  }

  async getVersions(artifact: string, cancelToken?: CancelToken): Promise<any> {
    const { data } = await this.post(`execution-goals/GetArtifcatetVerisons/${artifact}`, null, { cancelToken });

    return data;
  }
}

export const executionGoalsRequestService = new ExecutionGoalsRequestService(backendEndpoint);
