import { CancelToken } from 'axios';
import { container } from 'tsyringe';

import { RootStore } from '@/stores/RootStore';
import { CompanyNameType } from '@/types/UserTypes';

import BaseRequestService from './RequestService';

export class PlaygroundService extends BaseRequestService {
  constructor() {
    super();
  }

  async get404() {
    try {
      await this.post('NotARealEndPoint');
      return 'ERROR: function failed by succeeding.';
    } catch (err) {
      return err.toString();
    }
  }
}

export const playgroundService = new PlaygroundService();
