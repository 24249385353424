import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { appInsightConnectionString } from '@/utils/Env';

import { ai } from './TelemetryService';
import TelemetryTemplate from './TelemetryTemplate';
import { TelemetryType } from './TelemetryTypes';

let initialized = false;

const TelemetryViewController: React.FC<TelemetryType> = (props: TelemetryType) => {
  const { children }: TelemetryType = props;
  const { accounts } = useMsal();
  const small = 1;
  const isAuthenticated = useIsAuthenticated();
  const emptyUserName = t('no-user', { ns: NS.DEFAULT });
  const getUserName = () => (accounts.length >= 1 ? accounts[0].username : emptyUserName);
  const userName: string = getUserName();
  const appInsightsConnectionString: string = appInsightConnectionString;

  if (!initialized) {
    ai.initialize(appInsightsConnectionString);
    initialized = true;
  }

  if (isAuthenticated) {
    ai.setAuthenticatedUserContext(userName);
  }

  return <TelemetryTemplate>{children}</TelemetryTemplate>;
};

export default TelemetryViewController;
