import React from 'react';
import { BaseButton, Button, MessageBarType as FluentMessageBarType } from '@fluentui/react';

import { SystemMessageType } from '@/types/SystemMessageTypes';

type onDismissType = ((ev?: React.MouseEvent<HTMLElement | typeof BaseButton | typeof Button>) => any) | undefined;

enum MessageBarMode {
  // Normal mode can be dismissed but will show up again.
  normal = 0,
  // Display Once Only mode will not show up the next time the page is loaded.
  displayOnceOnly = 1,
  // Cannot be dismissed mode cannot be closed and is a permanent message.
  cannotDismiss = 2,
  // Remember State mode will remember if the Message is closed and not show again.
  rememberState = 3,
  // The Fade Away mode will automatically fade out the message after the specified time.
  fadeAway = 4,
}

type MessageBarType = {
  draggable?: boolean;
  multiline?: boolean;
  type?: typeof FluentMessageBarType;
  children: SystemMessageType[] | React.ReactNode; // Accept the global message stack or just a react node for a simple message.
  id?: string;
  mode?: MessageBarMode;
  group?: boolean;
};

export { onDismissType, MessageBarMode, MessageBarType };
