import React from 'react';
import { DirectionalHint, IButtonProps, TeachingBubble } from '@fluentui/react';

import { TeachingBubbleType } from './TeachingBubbleType';

const TeachingBubbleTemplate: React.FC<TeachingBubbleType> = (props: TeachingBubbleType): React.ReactElement => {
  const { title, teachingBubbleProps, children, enableButtons, target, onDismiss } = props;
  const defaultPrimaryButtonProps: typeof IButtonProps = {
    children: 'Try it out',
    onClick: () => {
      console.log('Clicked');
    },
  };

  const defaultSecondaryButtonProps: typeof IButtonProps = React.useMemo(
    () => ({
      children: 'Maybe later',
      onClick: onDismiss,
    }),
    [onDismiss],
  );

  let primaryButtonProps: typeof IButtonProps | undefined = undefined;
  let secondaryButtonProps: typeof IButtonProps | undefined = undefined;

  if (enableButtons) {
    primaryButtonProps = teachingBubbleProps?.primaryButtonProps ?? defaultPrimaryButtonProps;
    secondaryButtonProps = teachingBubbleProps?.secondaryButtonProps ?? defaultSecondaryButtonProps;
  }

  return (
    <TeachingBubble
      headline={title}
      calloutProps={
        teachingBubbleProps?.calloutProps ? teachingBubbleProps?.calloutProps : { directionalHint: DirectionalHint.topCenter }
      }
      target={`#${target}`}
      isWide={teachingBubbleProps?.isWide ? teachingBubbleProps.isWide : false}
      hasCloseButton={teachingBubbleProps?.hasCloseButton ? teachingBubbleProps.hasCloseButton : true}
      closeButtonAriaLabel="Close"
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      onDismiss={onDismiss}
      {...teachingBubbleProps}
    >
      {children ? children : props?.description}
    </TeachingBubble>
  );
};

export default TeachingBubbleTemplate;
