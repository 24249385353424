import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { PrimaryButton } from '@fluentui/react';

import { ExperimentFailureAnalysisHeader, Labels, Messages, Routes } from '@/constants/AirConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageContent from '@/partials/PageContent/PageContent';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { monitoringService } from '@/services/_air/monitoring/MonitoringService';
import { notificationService } from '@/services/_air/notification-services/NotificationService';
import { experimentStatusRequestService } from '@/services/_air/request-services';
import { ExperimentInstanceFailureAnaylsis } from '@/types/_air';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import '@/styles/Air.css';

interface FailureAnalysisParams {
  experimentId: string;
}

export const ExperimentInstanceFailureAnalysis = (data: any): JSX.Element => {
  const params: FailureAnalysisParams = data.match.params;
  const { experimentId } = params;
  const [FailureAnalysis, setFailureAnalysis] = React.useState<ExperimentInstanceFailureAnaylsis[]>();
  const [isBusy, setIsBusy] = React.useState<boolean>(true);
  const cancellationToken = useCancellationToken();
  const { state } = data.location;
  const { parameter, targetGoalInfo } = data.location.state;
  const isInstance = true;

  React.useEffect(() => {
    experimentStatusRequestService
      .getFailureAnalysisOfExpeimentInstance(experimentId, cancellationToken)
      .then((result) => {
        if (!isAPIError(result)) setFailureAnalysis(result);
      })
      .catch((error) => {
        if (error.response?.data?.detail === Messages.noExperimentInstance) return;

        notificationService.showError(error.response?.data?.detail ?? Messages.experimentStatusLoadError);
        void monitoringService.logError(error);
      })
      .finally(() => setIsBusy(false));
  }, [cancellationToken, experimentId]);

  const renderData = (): JSX.Element[] =>
    FailureAnalysis?.length > 0 &&
    FailureAnalysis.map((failure, ind) => (
      <tr key={ind} className="status-table-field">
        <td>{failure.id}</td>
        <td>{failure.rca}</td>
        <td>{failure.failureCategory}</td>
        <td>{failure.mitigationStep}</td>
        <td>{failure.properties.cluster}</td>
        <td>{failure.properties.groupName}</td>
        <td className="status-table-field">
          <Link
            to={{
              pathname: `${Navigation.AIR.FAILURE_ANALYSIS_DETAILS}/${experimentId}/${isInstance}`,
              state: {
                FailureAnaysis: failure,
                parameter,
                targetGoalInfo,
              },
            }}
          >
            <PrimaryButton className="status-table-button">{Labels.viewDetail}</PrimaryButton>
          </Link>
        </td>
      </tr>
    ));

  const renderHeaders = () =>
    ExperimentFailureAnalysisHeader.map((header) => (
      <th key={header} className="gbs-status-table-label">
        {header}
      </th>
    ));
  const renderFailureAnalysis = () => (
    <Table hover striped size="sm" className="gbs-status-table">
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      {FailureAnalysis && <tbody>{renderData()}</tbody>}
    </Table>
  );

  return isBusy ? (
    <LoadingSpinner />
  ) : (
    <div className="fullscreen">
      <main className="fullscreen container air-wrapper">
        <div className="fullscreen padding-top padding-bottom gbs-status-data">
          <h5 className="text-left all-in-one">
            <Link to={{ pathname: Navigation.AIR.MANAGE_EXPERIMENT }} className="breadcrumbs">
              {Labels.experiments}
            </Link>
            {` ${TITLE_DIVIDER}`}
            <Link
              to={{
                pathname: `${Navigation.AIR.SCHEDULER_STATUS}/${state.parameter.team}/${state.parameter.goalId}/${state.parameter.experimentName}`,
              }}
              className="breadcrumbs"
            >
              {` ${state.parameter.experimentName} `}
            </Link>
            {` ${TITLE_DIVIDER}`}
            <Link
              to={{
                pathname: `${Navigation.AIR.TARGET_GOAL_STATUS}/${state.parameter.team}/${state.parameter.goalId}/${state.parameter.experimentName}/${state.parameter.workload}`,
                state: {
                  targetGoal: state.targetGoalInfo.targetGoal,
                },
              }}
              className="breadcrumbs"
            >
              {` ${state.parameter.workload} `}
            </Link>
            {` ${TITLE_DIVIDER} ${experimentId} `}
          </h5>
          <PageContent scrollable={true}>
            <div id="wizard-page">{renderFailureAnalysis()}</div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
