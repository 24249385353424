/*----------------------------------------------------------------------------------------------------------------------------
# Alerts.tsx: 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';

import { Navigation } from '@/constants/NavigationConstants';
import Breadcrumbs, { BreadcrumbsService } from '@/partials/Breadcrumbs/BreadcrumbsTemplate';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const Alerts: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages, addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const items: IBreadcrumbItem[] = [BreadcrumbsService.quickItem(Navigation.GANYMEDE.ALERTS, BreadcrumbsService.IS_TITLE_ITEM)];

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
    addHelpMessage('alerts-help');
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className={'fullscreen container'}>
      <Breadcrumbs items={items} />
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
    </div>
  );
};

export default Alerts;
