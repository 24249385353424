import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Icon, IconButton, Label, MessageBarType, Modal, PrimaryButton, TextField } from '@fluentui/react';
import { DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';
import format from 'string-template';

import { Tooltip } from '@/components/_air/common';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, TooltipInfo } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { useCancellationToken } from '@/services/_labs/screen-service/UseCancellationToken';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import { RepairLabMachineRequest } from './RepairLabMachineTypes';

import style from './RepairLabMachine.module.css';

const RepairLabMachineFC = (props: any) => {
  const { hideModal, labId, isIPAddressFix, machineInfo } = props;
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages, addGlobalMessage } = systemMessageStore;
  const cancellationToken = useCancellationToken();
  const groupId = 'repair-lab';

  const infoIcon = 'info';

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const [newIPAddress, setNewIPAddress] = React.useState<string>('');
  const [newMacAddress, setNewMacAddress] = React.useState<string>('');
  const [username, setUsername] = React.useState<string>('');
  const [passwordReference, setPasswordReference] = React.useState<string>('');
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);
  const [isBusy, setBusy] = React.useState<boolean>(false);
  const [createMessage, setCreateMessage] = React.useState<string>('');
  const machineIPAddress = machineInfo[0].IPAddress;
  const machineMacAddress = machineInfo[0].MacAddress;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const repairLab = async (event: any) => {
    event.preventDefault();
    setSubmitted(false);

    const ipAddressMatch = isIPAddressFix && newIPAddress.trim() === machineIPAddress;
    const macAddressMatch = !isIPAddressFix && newMacAddress.trim() === machineMacAddress;

    if (ipAddressMatch || macAddressMatch) {
      const errorMessage = isIPAddressFix
        ? t(Labs.IP_ADDRESS_ERROR, { ns: NS.ERRORS })
        : t(Labs.MAC_ADDRESS_ERROR, { ns: NS.ERRORS });
      const failMessage: SystemMessageType = {
        message: errorMessage,
        type: MessageBarType.error,
        groupId: groupId,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);
      return;
    }

    setBusy(true);

    const requestBody: RepairLabMachineRequest = {
      userName: username,
      passwordReference: passwordReference,
      ipAddress: isIPAddressFix ? newIPAddress.trim() : machineIPAddress,
      machineName: machineInfo[0].Name,
      macAddress: isIPAddressFix ? machineMacAddress : newMacAddress.trim(),
      oldMacAddress: machineMacAddress,
    };

    try {
      const response = await ganymedeLabRequestService.repairLabMachine(
        labId.toString(),
        isIPAddressFix,
        requestBody,
        cancellationToken,
      );
      const result = JSON.parse(response[0].second);

      if (result.created) {
        const message = format(t(Labs.REPAIR_REQUEST_SUCCESS_TEMPLATE, { ns: NS.LABS }), {
          id: result.id,
        });
        setCreateMessage(message);
      } else {
        const errorMessage = result?.Detail || result?.detail || '';

        const message = format(t(Labs.REPAIR_REQUEST_ERROR_TEMPLATE, { ns: NS.LABS }), {
          errorMessage: errorMessage,
        });
        setCreateMessage(message);
      }

      setSubmitted(true);
    } catch (error) {
      console.error('[LabControl] Repair Lab Machine Failure :', error.message);
      const handleErrorProps = {
        error,
        systemMessageStore,
        appSettingsStore,
        groupId,
        showInPopup: true,
      };
      HandleError(handleErrorProps);
    } finally {
      setBusy(false);
    }
  };

  const createReadOnlyTextWithLabel = (label: string, value: string, tooltip: string) => (
    <>
      <div className={style['row']}>
        <div>
          <Label className={style['label']}>
            {label}
            <Tooltip content={tooltip} directionalHint={DirectionalHint.rightCenter}>
              <Icon iconName={infoIcon} className={style['tooltip-icon']} />
            </Tooltip>
          </Label>
        </div>
        <div className={style['input']} title={label}>
          {value}
        </div>
      </div>
    </>
  );

  const createTextFieldWithLabel = (label: string, setMethod: any, tooltip: string) => (
    <>
      <div className={style['row']}>
        <div>
          <Label className={style['label']}>
            {label}
            <Tooltip content={tooltip} directionalHint={DirectionalHint.rightCenter}>
              <Icon iconName={infoIcon} className={style['tooltip-icon']} />
            </Tooltip>
          </Label>
        </div>
        <TextField
          required
          type="text"
          title={tooltip}
          className={style['input']}
          placeholder={tooltip}
          onChange={(e) => setMethod((e.target as any).value)}
        />
      </div>
    </>
  );

  return (
    <div className={style['repair-lab-wrapper']}>
      {isSubmitted ? (
        <h5>{createMessage}</h5>
      ) : (
        <div>
          <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
          <form onSubmit={repairLab}>
            {isIPAddressFix ? (
              <>
                {createReadOnlyTextWithLabel(t(Labs.MAC_ADDRESS, { ns: NS.LABS }), machineMacAddress, TooltipInfo.MAC_ADDRESS)}
                {createReadOnlyTextWithLabel(t(Labs.OLD_IP_ADDRESS, { ns: NS.LABS }), machineIPAddress, TooltipInfo.IP_ADDRESS)}
                {createTextFieldWithLabel(t(Labs.NEW_IP_ADDRESS, { ns: NS.LABS }), setNewIPAddress, TooltipInfo.NEW_IP_ADDRESS)}
              </>
            ) : (
              <>
                {createReadOnlyTextWithLabel(t(Labs.IP_ADDRESS, { ns: NS.LABS }), machineIPAddress, TooltipInfo.IP_ADDRESS)}
                {createReadOnlyTextWithLabel(t(Labs.OLD_MAC_ADDRESS, { ns: NS.LABS }), machineMacAddress, TooltipInfo.MAC_ADDRESS)}
                {createTextFieldWithLabel(t(Labs.NEW_MAC_ADDRESS, { ns: NS.LABS }), setNewMacAddress, TooltipInfo.NEW_MAC_ADDRESS)}
              </>
            )}
            {createTextFieldWithLabel(t(Labs.USERNAME, { ns: NS.LABS }), setUsername, TooltipInfo.USERNAME)}
            {createTextFieldWithLabel(
              t(Labs.PASSWORD_REFERENCE, { ns: NS.LABS }),
              setPasswordReference,
              TooltipInfo.PASSWORD_REFERENCE,
            )}
            <div className={`${style['row']}`}>
              <span>
                <PrimaryButton type="submit" title={t('submit', { ns: NS.COMMON })} className={`${style['button']}`}>
                  {t('submit', { ns: NS.COMMON })}
                </PrimaryButton>
                <DefaultButton className={`${style['button']}`} onClick={hideModal}>
                  {Buttons.cancel}
                </DefaultButton>
              </span>
              <span>{isBusy && <LoadingSpinner />}</span>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export const RepairLabMachine = observer(RepairLabMachineFC);
