import { ISpinnerStyleProps, ISpinnerStyles, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { IStyleFunctionOrObject } from '@fluentui/react/lib/Utilities';

const SpinLocation = {
  BelowTableHeader: 'BelowTableHeader',
  BelowAntTableHeader: 'BelowAntTableHeader',
  BelowTableFilter: 'BelowTableFilter',
};

type SpinProps = {
  size?: SpinnerSize;
  styles?: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles>;
  location?: string;
  label?: string;
  spinning?: boolean;
  indicator?: JSX.Element;
};

export { SpinLocation, SpinProps };
