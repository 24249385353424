import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Icon, SearchBox } from '@fluentui/react';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Messages } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import PageContent from '@/partials/PageContent/PageContent';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import PageHeader from '@/partials/PageHeader/PageHeader';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';

import reports from './PowerBIData.json';

import '@/styles/Labs.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

export const ReportList = () => {
  const [filterText, setFilterText] = React.useState<string>('');

  const title = t('labs', { ns: NS.TITLES });
  const subTitle = t('all-reports', { ns: NS.TITLES });

  const handleChange = (event?: React.ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      setFilterText(event?.target?.value);
    }
  };

  const getReportName = (name: any, replaceName: any) => {
    if (name.includes('CRC-BM-BladeHealth')) {
      return name.replace('CRC-BM-BladeHealth', replaceName);
    }

    if (name.includes('CRC-BM-EarlyFailure')) {
      return name.replace('CRC-BM-EarlyFailure', replaceName);
    }

    if (name.includes('CRC-BM-CSIDiagErrors-BSLFactoryRuns')) {
      return name.replace('CRC-BM-CSIDiagErrors-BSLFactoryRuns', replaceName);
    }

    if (name.includes('CRC-BM-CSIToolkitResults')) {
      return name.replace('CRC-BM-CSIToolkitResults', replaceName);
    }

    return name;
  };

  const getName = (item) => {
    return getReportName(item.ReportName, item.FriendlyName);
  };

  const results = !filterText
    ? reports.PowerBIReport
    : reports.PowerBIReport.filter((item: any) => getName(item).toLowerCase().includes(filterText.toLowerCase()));

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-name', { ns: NS.COMMON })}
          title={t('search-name', { ns: NS.COMMON })}
          value={filterText}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={handleChange}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          onClear={() => {
            setFilterText('');
          }}
          spellCheck="false"
        />
      ),
    },
  ];

  return (
    <div className="fullscreen">
      <main className="fullscreen container labs-wrapper">
        <div className="fullscreen padding-top">
          <PageHeader icon={NavigationIcon[Navigation.LABS.VIEW_REPORT]} subTitle={subTitle}>
            {title}
          </PageHeader>
          <PageContent scrollable={false} scrollableX={false}>
            <div className="padding-bottom fullscreen">
              <PageFilterBar items={filterItems}></PageFilterBar>
              {results.length === 0 ? (
                <div className="text-center text-secondary mt-5">
                  <h5>
                    <Icon iconName="Error" /> {Messages.noSearchResult}
                  </h5>
                </div>
              ) : (
                <ScrollableContent scrollable={true} scrollableX={true}>
                  <Table className="sticky-header" striped hover>
                    <thead>
                      <tr>
                        <th>Report Name</th>
                        <th>Users</th>
                        <th>Description</th>
                        <th>Scope</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.map((item: any, index: any) => (
                        <tr key={index}>
                          <td className="ms-DetailsRow-cell">
                            <Link to={`${Navigation.LABS.VIEW_REPORT}/url=${encodeURIComponent(item.EmbedUrl)}`}>
                              {getReportName(item.ReportName, item.FriendlyName)}
                            </Link>
                          </td>
                          <td>{item.Users}</td>
                          <td>
                            <ul>
                              {Object.keys(item.Description).map((key, index) => (
                                <li key={index}>{item.Description[key as string]}</li>
                              ))}
                            </ul>
                          </td>
                          <td>{item.Scope}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ScrollableContent>
              )}
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};
