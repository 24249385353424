import moment from 'moment';

import { DurationType, GetDurationType, GetListType, GetNumberType } from '@/components/Reliability/ReliabilityType';
import { DateFormats } from '@/constants/DateFormatConstants';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeWithParam, ActionTypeWithReturn } from '@/types/AppSettingsTypes';

import { ReleaseAnnotationsType } from '../ReleaseAnnotationsType';
import { AnnotationsChartData, ErrorPerPageChartData, ErrorsPageViewData, ErrorVsPageChartData } from './ErrorsGraphType';

class ErrorsGraphViewModel {
  protected _getGraphData: GetListType;
  protected _getBucketType: GetDurationType;
  protected _getBucketDuration: GetNumberType;
  protected _getReleaseAnnotations: GetListType;
  protected _getSelectedAnnotation: ActionTypeWithReturn<AnnotationsChartData>;
  protected _setSelectedAnnotation: ActionTypeWithParam;
  protected _maxErrorCount = 0;
  protected _maxPageViewCount = 0;

  constructor(rootStore: RootStore) {
    const { errorGraphStore } = rootStore;
    const { reliabilityStore } = rootStore;
    const { getGraphData, getBucketDuration, getBucketType, getReleaseAnnotations } = reliabilityStore;
    const { getSelectedAnnotation, setSelectedAnnotation } = errorGraphStore;

    this._getGraphData = getGraphData;
    this._getBucketDuration = getBucketDuration;
    this._getBucketType = getBucketType;
    this._getReleaseAnnotations = getReleaseAnnotations;
    this._getSelectedAnnotation = getSelectedAnnotation;
    this._setSelectedAnnotation = setSelectedAnnotation;
    this._maxErrorCount = Math.max(...this._getGraphData().map((item: ErrorsPageViewData) => item.errorCount));
    this._maxPageViewCount = Math.max(...this._getGraphData().map((item: ErrorsPageViewData) => item.pageCount));
  }

  public get graphData(): Array<ErrorsPageViewData> {
    return this._getGraphData();
  }

  public get bucket(): number {
    return this._getBucketDuration();
  }

  public get bucketType(): DurationType {
    return this._getBucketType();
  }

  public formatTimeLabel = (bucket: number, bucketType: DurationType, timeGenerated: string) => {
    let label = '';

    if (bucket >= 24 && bucketType === DurationType.Hour) {
      label = moment(timeGenerated).format(DateFormats.MONTH_DAY);
    } else if (bucketType === DurationType.Minute) {
      label = moment(timeGenerated).format(DateFormats.HOUR24_MIN);
    } else {
      label = moment(timeGenerated).format(DateFormats.MONTH_DAY_HOUR24_MIN);
    }

    return label;
  };

  public get releaseAnnotations(): Array<ReleaseAnnotationsType> {
    return this._getReleaseAnnotations();
  }

  public getReleaseAnnotationsGraphData = (): Array<AnnotationsChartData> => {
    const maxDataValue = Math.max(this._maxErrorCount, this._maxPageViewCount);

    if (this.releaseAnnotations) {
      return this.releaseAnnotations.map((item: ReleaseAnnotationsType) => {
        return {
          timeGenerated: `${moment(item.eventTime).valueOf()}`,
          releases: {
            id: item.id,
            annotationName: item.annotationName,
            deploymentUri: item.properties.deploymentUri,
            buildNumber: item.properties.buildNumber,
            yAxisValue: maxDataValue + maxDataValue * 0.1,
          },
        };
      });
    }

    return [];
  };

  public getErrorsPerPageGraphData = (): Array<ErrorPerPageChartData> => {
    if (this.graphData) {
      return this.graphData.map((item: ErrorsPageViewData) => {
        const errorsPerPage = item.pageCount > 0 ? parseFloat((item.errorCount / item.pageCount).toFixed(2)) : 0;
        const label = this.formatTimeLabel(this.bucket, this.bucketType, item.timeGenerated);

        return {
          errorsPerPageViews: errorsPerPage,
          timeGenerated: `${label}`,
        };
      });
    }

    return [];
  };

  public getErrorVsPageGraphData = (): Array<ErrorVsPageChartData> => {
    if (this.graphData) {
      return this.graphData.map((item: ErrorsPageViewData) => {
        return {
          timeGenerated: `${moment(item.timeGenerated).valueOf()}`,
          errors: item.errorCount,
          pageViews: item.pageCount,
        };
      });
    }

    return [];
  };

  public get selectedAnnotation(): AnnotationsChartData {
    return this._getSelectedAnnotation();
  }

  public setSelectedAnnotation = (selectedAnnotation: AnnotationsChartData) => {
    this._setSelectedAnnotation(selectedAnnotation);
  };
}

export default ErrorsGraphViewModel;
