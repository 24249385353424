import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import LabDetailsViewController from '@/components/ManageLab/LabDetails/LabDetailsViewController';
import LabDetailsViewModel from '@/components/ManageLab/LabDetails/LabDetailsViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const LabDetailsHomeFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);

  const labsViewModel = useMemo(() => new LabDetailsViewModel(rootStore), [rootStore]);

  return (
    <div className="fullscreen">
      <LabDetailsViewController viewModel={labsViewModel} />
    </div>
  );
};

const LabDetailsHome = observer(LabDetailsHomeFC);

export default LabDetailsHome;
