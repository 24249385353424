/*----------------------------------------------------------------------------------------------------------------------------
# Routes.tsx: 
# This page controls the specific routing for the main CRC Portal application.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AirServicesTemplate from '@/components/_air/AirServices/AirServicesTemplate';
import LabsServicesTemplate from '@/components/_labs/LabsServices/LabsServicesTemplate';
import Insights from '@/components/Reliability/ReliabilityWrapper';
import { Navigation } from '@/constants/NavigationConstants';
import About from '@/pages/About/AboutTemplate';
import AccessibilityReport from '@/pages/AccessibilityReport/AccessibilityTemplate';
import Alerts from '@/pages/Alerts/AlertsTemplate';
import ChangeHistory from '@/pages/ChangeHistory/ChangeHistoryTemplate';
import Dashboards from '@/pages/Dashboards/DashboardsTemplate';
import ExperimentEditor from '@/pages/ExperimentEditor/ExperimentEditorTemplate';
import Favorites from '@/pages/Favorites/FavoritesTemplate';
import GanymedeHome from '@/pages/Home/HomeTemplate';
import Issues from '@/pages/Issues/IssuesTemplate';
import LabDetailsHome from '@/pages/LabDetails/LabDetailsHomeTemplate';
import LabsHome from '@/pages/Labs/LabsHomeTemplate';
import Playground from '@/pages/Playground/PlaygroundTemplate';
import Settings from '@/pages/PortalSettings/PortalSettingsTemplate';
import ProjectDocumentation from '@/pages/ProjectDocumentation/ProjectDocumentationTemplate';
import ReleaseNotes from '@/pages/ReleaseNotes/ReleaseNotesTemplate';
import ResultExplorerHome from '@/pages/ResultExplorer/ResultExplorerHomeTemplate';
import SessionsHome from '@/pages/Sessions/SessionsTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const GanymedeRedirects = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, userInfoStore } = rootStore;
  const { isAirHidden, isLabsHidden } = appSettingsStore;
  const { hasAirPermissions, hasLabsPermissions } = userInfoStore;

  const showAirApplication = hasAirPermissions && !isAirHidden;
  const showLabsApplication = hasLabsPermissions && !isLabsHidden;
  const noAppsVisible = !showAirApplication && !showLabsApplication;

  return (
    <Switch>
      {noAppsVisible && (
        <Route path="*">
          <Redirect to={Navigation.PAGE_NOT_FOUND} />
        </Route>
      )}
      {showAirApplication && !showLabsApplication && (
        <>
          <Route exact path={Navigation.ROOT}>
            <Redirect to={Navigation.AIR.HOME} />
          </Route>
          <Route path={Navigation.HOME}>
            <Redirect to={Navigation.AIR.HOME} />
          </Route>
          <Route path={Navigation.LABS.HOME}>
            <Redirect to={Navigation.PAGE_NOT_FOUND} />
          </Route>
        </>
      )}
      {showLabsApplication && !showAirApplication && (
        <>
          <Route exact path={Navigation.ROOT}>
            <Redirect to={Navigation.LABS.HOME} />
          </Route>
          <Route path={Navigation.HOME}>
            <Redirect to={Navigation.LABS.HOME} />
          </Route>
          <Route path={Navigation.AIR.HOME}>
            <Redirect to={Navigation.PAGE_NOT_FOUND} />
          </Route>
        </>
      )}
      <Route path={Navigation.RELIABILITY}>
        <Redirect to={Navigation.GANYMEDE.INSIGHTS} />
      </Route>
      <Route path={Navigation.HOME}>
        <Redirect to={Navigation.ROOT} />
      </Route>
    </Switch>
  );
};

// These do NOT need to be in order of overriding route preference.
const LandingPagePaths: string[] = [
  Navigation.ROOT,
  Navigation.GANYMEDE.ABOUT,
  Navigation.GANYMEDE.ACCESSIBILITY_REPORT,
  Navigation.GANYMEDE.ALERTS,
  Navigation.GANYMEDE.CHANGE_HISTORY,
  Navigation.GANYMEDE.HACKATHON_DASHBOARD,
  Navigation.GANYMEDE.INSIGHTS,
  Navigation.GANYMEDE.ISSUES,
  Navigation.GANYMEDE.LABS,
  Navigation.GANYMEDE.PLAYGROUND,
  Navigation.GANYMEDE.PLAYGROUND_AIR,
  Navigation.GANYMEDE.PLAYGROUND_LABS,
  Navigation.GANYMEDE.RELEASE_NOTES,
  Navigation.GANYMEDE.RESULT_EXPLORER,
  Navigation.GANYMEDE.SETTINGS,
];

// These MUST be defined in order of overriding route preference.
const GanymedeRoutes = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);

  const { appSettingsStore, userInfoStore } = rootStore;
  const { isDeveloperMode } = appSettingsStore;
  const { isMicrosoftAccount } = userInfoStore;

  return (
    <>
      <Route exact path={Navigation.GANYMEDE.ABOUT} component={About} />
      <Route exact path={Navigation.GANYMEDE.ALERTS} component={Alerts} />
      <Route exact path={Navigation.GANYMEDE.DASHBOARDS} component={Dashboards} />

      {/* // TODO: Implement temporary code for the Lab Details Page LHS. 
          // This method should be deprecated once active development on the Lab Details Page is complete.
          // Related ticket: https://msazure.visualstudio.com/One/_workitems/edit/28985759 */}
      {isDeveloperMode && (
        <>
          <Route exact path={Navigation.GANYMEDE.LAB_DETAILS} component={LabDetailsHome} />
        </>
      )}

      <Route exact path={Navigation.GANYMEDE.SESSIONS} component={SessionsHome} />
      <Route exact path={`${Navigation.GANYMEDE.SESSIONS}/:experimentId`} component={SessionsHome} />
      <Route exact path={`${Navigation.GANYMEDE.SESSIONS}/browse`} component={SessionsHome} />
      <Route exact path={Navigation.GANYMEDE.EXPERIMENTS} component={GanymedeHome} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENTS}/:type/:labId/:instanceId`} component={GanymedeHome} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENTS}/:labId`} component={GanymedeHome} />
      <Route exact path={Navigation.GANYMEDE.EXPERIMENT_EDITOR} component={ExperimentEditor} />
      <Route exact path={`${Navigation.GANYMEDE.EXPERIMENT_EDITOR}/:labId`} component={ExperimentEditor} />
      <Route exact path={Navigation.GANYMEDE.FAVORITES} component={Favorites} />
      <Route exact path={Navigation.GANYMEDE.PROJECT_DOCUMENTATION} component={ProjectDocumentation} />
      <Route exact path={Navigation.GANYMEDE.RELEASE_NOTES} component={ReleaseNotes} />
      <Route exact path={Navigation.GANYMEDE.RESULT_EXPLORER} component={ResultExplorerHome} />
      {isMicrosoftAccount && (
        <>
          <Route exact path={Navigation.GANYMEDE.CHANGE_HISTORY} component={ChangeHistory} />
          <Route exact path={Navigation.GANYMEDE.ACCESSIBILITY_REPORT} component={AccessibilityReport} />
          <Route exact path={Navigation.GANYMEDE.INSIGHTS} component={Insights} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND_AIR} component={AirServicesTemplate} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND_LABS} component={LabsServicesTemplate} />
          <Route exact path={Navigation.GANYMEDE.PLAYGROUND} component={Playground} />
        </>
      )}
      <Route exact path={Navigation.GANYMEDE.ISSUES} component={Issues} />
      <Route exact path={Navigation.GANYMEDE.SETTINGS} component={Settings} />
      {/* The Root route must be last, as part of it is found in every other route. */}
      <Route exact path={Navigation.ROOT} component={GanymedeHome} />
    </>
  );
};

export { GanymedeRoutes, GanymedeRedirects, LandingPagePaths };
