import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, DefaultButton, IconButton, Modal, SpinnerSize } from '@fluentui/react';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { t } from 'i18next';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import config from '@/components/MachineSelect/MachineSelect.config.json';
import { AccordionKeys, MachineSelectVCType } from '@/components/MachineSelect/MachineSelectTypes';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from './MachineSelect.module.css';

const MachineSelectTemplateFC: React.FC<MachineSelectVCType> = (props: MachineSelectVCType) => {
  const {
    commandItems,
    buttons,
    labs,
    labsColumns,
    labSelection,
    labSelected,
    machines,
    machinesColumns,
    machinesGroups,
    machinesGroupsColumns,
    machinesSelection,
    windowState,
    labLoading,
    machineLoading,
    experimentLoading,
    successfulExperiments,
    failedExperiments,
    createdName,
    creationMessage,
    isConfirmModalOpen,
    maxReportSize,
    accordionOpenItems,
    setWindowState,
    hideConfirmModal,
    createExperiment,
    accordionToggle,
    labsOnRenderRow,
  } = props;

  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  return (
    <>
      <div className={styles.height}>
        {windowState === 1 && <PageCommandBar items={commandItems} />}
        <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
        {experimentLoading && windowState === 1 && (
          <div>
            <h6 className="mb-6">
              <Trans ns={NS.EDITOR as Namespace}>queuing-for-execution</Trans>
            </h6>
            <LoadingSpinner />
          </div>
        )}
        {windowState === 0 && (
          <Accordion
            className={styles.full}
            collapsible={true}
            multiple={true}
            openItems={accordionOpenItems}
            data-id={'experiments-panel-accordion'}
            onToggle={accordionToggle}
          >
            <AccordionItem
              className={`${styles['accordion-item']}  ${accordionOpenItems.includes(AccordionKeys.LABS) && styles['expanded']}`}
              value={AccordionKeys.LABS}
            >
              <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                {t('lab-selection', { ns: NS.EDITOR })}
              </AccordionHeader>
              <AccordionPanel className={styles['accordion-panel']}>
                {labLoading && (
                  <div>
                    <LoadingSpinner size={SpinnerSize.small} />
                  </div>
                )}
                {!labLoading &&
                  (labs && labs.length !== 0 ? (
                    <>
                      <h6 className="mb-6">
                        <Trans ns={NS.EDITOR as Namespace}>select-lab</Trans>
                      </h6>
                      <TableViewController
                        items={labs}
                        columns={labsColumns}
                        enableToolBar={false}
                        selectionMode={SelectionMode.single}
                        selection={labSelection}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        onRenderRow={labsOnRenderRow}
                      />
                    </>
                  ) : (
                    <div>
                      <h6 className={`${styles.error} mb-6`}>
                        <Trans ns={NS.ERRORS as Namespace}>no-labs</Trans>
                      </h6>
                    </div>
                  ))}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem
              className={`${styles['accordion-item']} ${accordionOpenItems.includes(AccordionKeys.MACHINES) && styles['expanded']}`}
              value={AccordionKeys.MACHINES}
            >
              <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                {t('machine-selection', { ns: NS.EDITOR })}
              </AccordionHeader>
              <AccordionPanel className={styles['accordion-panel']}>
                {machineLoading && (
                  <div>
                    <LoadingSpinner size={SpinnerSize.small} />
                  </div>
                )}
                {!machineLoading && !labSelected && (
                  <h6 className="mb-6">
                    <Trans ns={NS.EDITOR as Namespace}>select-lab-before-machines-text</Trans>
                  </h6>
                )}
                {!machineLoading &&
                  labSelected &&
                  (machines && machines.length !== 0 ? (
                    <>
                      <h6 className="mb-6">
                        <Trans ns={NS.EDITOR as Namespace}>select-machines-text</Trans>
                      </h6>
                      <TableViewController
                        items={machines}
                        columns={machinesColumns}
                        groupByColumn={t(machinesGroupsColumns[0].name, { ns: NS.TABLE })}
                        groupByColumns={machinesGroupsColumns}
                        groups={machinesGroups}
                        selection={machinesSelection}
                        enableColumnSort={true}
                        checkboxVisibility={CheckboxVisibility.always}
                        selectionMode={SelectionMode.multiple}
                        isSelectedOnFocus={false}
                        enableToolBar={false}
                      />
                    </>
                  ) : (
                    <h6 className={`${styles.error} mb-6`}>
                      <Trans ns={NS.ERRORS as Namespace}>no-machines</Trans>
                    </h6>
                  ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
        {windowState === 1 && !experimentLoading && (
          <div className={styles.report}>
            <div>
              <h6 className="mb-6">{`${createdName} ${t('experiment-creation-report', { ns: NS.EDITOR })}`}</h6>
            </div>
            <h5>{creationMessage}</h5>
            {successfulExperiments.length + failedExperiments.length <= maxReportSize && (
              <>
                {successfulExperiments.length !== 0 && (
                  <>
                    <h6>
                      <Trans ns={NS.TITLES as Namespace}>success-experiment-report</Trans>
                    </h6>
                    <TableViewController
                      items={successfulExperiments}
                      columns={config.creationSuccessfulColumns}
                      enableToolBar={false}
                      selectionMode={SelectionMode.none}
                    />
                  </>
                )}

                {failedExperiments.length !== 0 && (
                  <>
                    <h6>
                      <Trans ns={NS.TITLES as Namespace}>failed-experiment-report</Trans>
                    </h6>
                    <TableViewController
                      items={failedExperiments}
                      columns={config.creationFailedColumns}
                      enableToolBar={false}
                      selectionMode={SelectionMode.none}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
        {isConfirmModalOpen && (
          <Modal
            className="labs-wrapper"
            titleAriaId="labs creation confirmation"
            isOpen={isConfirmModalOpen}
            isBlocking={false}
            containerClassName={contentStyles.container}
            onDismiss={hideConfirmModal}
          >
            <div className={contentStyles.header}>
              <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: SystemIcons.CANCEL }}
                ariaLabel={AreaLabels.closePopupModal}
                onClick={hideConfirmModal}
              />
            </div>
            <div className={contentStyles.body}>
              <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
              <h5>
                <Trans ns={NS.EDITOR as Namespace}>lab-unhealthy-confirmation</Trans>
              </h5>
              <br />
              <div className="text-center">
                <DefaultButton
                  className={`${styles['button']} bg-danger light-text`}
                  onClick={() => {
                    setWindowState(1);
                    createExperiment();

                    hideConfirmModal();
                  }}
                >
                  <Trans ns={NS.COMMON as Namespace}>yes</Trans>
                </DefaultButton>
                <DefaultButton classStyles={styles['button']} onClick={hideConfirmModal}>
                  <Trans ns={NS.COMMON as Namespace}>cancel</Trans>
                </DefaultButton>
              </div>
            </div>
          </Modal>
        )}
      </div>
      {buttons}
    </>
  );
};

const MachineSelectTemplate = observer(MachineSelectTemplateFC);

export default MachineSelectTemplate;
