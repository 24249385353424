// Here we keep track of all the widgets that were dragged into the Dashboard.
// Each Widget has a unique Id that is mapped to a Library Widget Id.
// Widget configuration information would most likely be contained here as well.
// Eventually, this sort of data will come from our Preferences/Settings APIs.

type WidgetMappingType = {
  [id: string]: string;
};

const MockWidgetData: WidgetMappingType = {
  '1111118d-ae93-49ba-ad11-8464ffaaaaaa': 'graph-type-1',
  '99999942-50f1-4b10-b7c7-a8f7aaffffff': 'graph-type-2',
};

export { MockWidgetData, WidgetMappingType };
