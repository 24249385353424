import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip } from '@/components/_air/common';

import '@/styles/Air.css';
interface Props {
  progress: number;
}

const useStyles = makeStyles({
  root: {
    height: 25,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#cccccc',
  },
  bar: ({ color }: { color: string }) => ({
    borderRadius: 5,
    backgroundColor: color,
  }),
});

export const ExperimentProgressBars = ({ progress }: Props): JSX.Element => {
  const styleProps = { color: '#0bbd32' };
  const classes = useStyles(styleProps);

  return (
    <div style={{ flexGrow: 1 }}>
      <Tooltip content={`${progress ?? 0}%`}>
        <LinearProgress aria-label="Progress Bar" variant="determinate" value={progress ?? 0} classes={classes} />
      </Tooltip>
    </div>
  );
};
