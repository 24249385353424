import React from 'react';

import { TelemetryType } from './TelemetryTypes';

const TelemetryTemplate: React.FC<TelemetryType> = (props: TelemetryType) => {
  const { children } = props;

  return <React.Fragment>{children}</React.Fragment>;
};

export default TelemetryTemplate;
