import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SessionsViewController from './SessionsViewController';
import SessionsViewModel from './SessionsViewModel';

const SessionsFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore, systemMessageStore } = rootStore;
  const { isAdvancedMode } = appSettingsStore;
  const { unmountSessions } = sessionsStore;
  const { addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const { unmountSessionDetails } = sessionDetailsStore;
  const viewModel = new SessionsViewModel(rootStore);

  const openPanelOnRowSelect = true;

  React.useEffect(() => {
    // Perform page initialization tasks.
    isAdvancedMode && console.log('[SessionsFC] Mounting Sessions');
    clearNonPersistentGlobalMessages();

    return () => {
      // Perform page cleanup tasks.
      isAdvancedMode && console.log('[SessionsFC] Unmounting Sessions');
      unmountSessions();
      unmountSessionDetails();
    };
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return <SessionsViewController viewModel={viewModel} openPanelOnRowSelect={openPanelOnRowSelect}></SessionsViewController>;
};

const Sessions = observer(SessionsFC);

export default Sessions;
