import React from 'react';
import { ThemeKeys } from 'react-json-view';
import { observer } from 'mobx-react-lite';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { t } from 'i18next';

import PreviewExperimentTemplate from '@/components/PreviewExperiment/PreviewExperimentTemplate';
import { PreviewExperimentVMType } from '@/components/PreviewExperiment/PreviewExperimentType';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ActionTypeVoid } from '@/types/AppSettingsTypes';

import styles from './PreviewExperiment.module.css';

const PreviewExperimentViewControllerFC: React.FC<{
  viewModel: PreviewExperimentVMType;
  closeModal: ActionTypeVoid;
  toggleHidePublish: ActionTypeVoid;
}> = (props: { viewModel: PreviewExperimentVMType; closeModal: ActionTypeVoid; toggleHidePublish: ActionTypeVoid }) => {
  const { closeModal, toggleHidePublish, viewModel } = props;
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDarkMode } = appSettingsStore;

  const previewExperimentViewModel: PreviewExperimentVMType = viewModel;
  const { convertedExperiment, isExperimentLoading, previewExperiment } = previewExperimentViewModel;
  const [compiledJsonStyle, setCompiledJsonStyle] = React.useState<ThemeKeys>('bright:inverted');

  React.useEffect(() => {
    previewExperiment();
  }, []);

  React.useEffect(() => {
    setCompiledJsonStyle(isDarkMode ? 'bright' : 'bright:inverted');
  }, [isDarkMode]);

  const buttons = (
    <div>
      <PrimaryButton
        onClick={(event) => {
          closeModal();
          toggleHidePublish();
        }}
        className={styles.button}
        text={t('queue', { ns: NS.EDITOR })}
      />
      <DefaultButton onClick={closeModal} className={styles.button} text={t('close', { ns: NS.COMMON })} />
    </div>
  );

  return (
    <PreviewExperimentTemplate
      jsonStyle={compiledJsonStyle}
      convertedExperiment={convertedExperiment}
      isExperimentLoading={isExperimentLoading}
      buttons={buttons}
    />
  );
};

const PreviewExperimentViewController = observer(PreviewExperimentViewControllerFC);

export default PreviewExperimentViewController;
