import BaseRequestService from './RequestService';

class FirmwareRequestService extends BaseRequestService {
  async getFirmwareList(program: string) {
    const url = `firmware/${program}`;
    const { data } = await this.get(url);

    return data;
  }

  async uploadFirmware(formData: FormData) {
    const url = 'firmware';
    const { data } = await this.post(url, formData);

    return data;
  }
}

export const ganymedeFirmwareRequestService = new FirmwareRequestService();
