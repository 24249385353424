import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Icon } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import * as Ls from 'local-storage';

import { Tooltip } from '@/components/_air/common';
import { Labels, NotificationMessages, Routes, TableHeaders } from '@/constants/AirConstants';
import { DataType } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { experimentResultRequestService } from '@/services/_air/request-services';
import { APIErrorInterface, MetricsBySelectedColor } from '@/types/_air';
import { getClassName } from '@/utils/_air/DashboardScore';
import { processApiError } from '@/utils/_air/ErrorHandler';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { isAPIError } from '@/utils/_air/IsAPIError';

import { WorkloadInfoSubSet } from './WorkloadInfoSubSet';

import '@/styles/Air.css';
import './result.css';

export const WorkloadInfo = (): JSX.Element => {
  const {
    experiment: InfoExperimentName,
    workload: InfoWorkloadName,
    profile: InfoWorkloadProfile,
    friendly: workloadProfileFriendly,
    revision,
    instance: testedInstance,
  } = useParams<{
    experiment: string;
    profile: string;
    score: string;
    workload: string;
    friendly: string;
    revision: string;
    instance: string;
  }>();

  const [workloadTableData, setWorkloadTableData] = React.useState<Element[]>([]);
  const [isBusy, setBusy] = React.useState<boolean>(true);
  const [selectedItem, toggleItem] = React.useState<number>(-1);
  const [workloadData, setWorkloadData] = React.useState<MetricsBySelectedColor[]>([]);
  const cancellationToken = useCancellationToken();

  const tooltipId: string = useId('tooltip');
  const testedInstanceStr = testedInstance === '/' ? 'slash' : testedInstance;
  const revisionStr = revision.includes('/') ? revision.replaceAll('/', 'slash') : revision;

  window.onbeforeunload = (): void => {
    Ls.remove(InfoWorkloadName as string);
  };

  React.useEffect(() => {
    if (typeof InfoWorkloadName === DataType.STRING) {
      const lsInfoWorkload: MetricsBySelectedColor[] | null = Ls.get<MetricsBySelectedColor[] | null>(InfoWorkloadName);

      if (lsInfoWorkload !== null) {
        setWorkloadData(lsInfoWorkload);
        populateMetricData(lsInfoWorkload);
        setBusy(false);

        return;
      }
    }

    experimentResultRequestService
      .getWorkloadInfo(InfoExperimentName, InfoWorkloadProfile, InfoWorkloadName, revisionStr, testedInstanceStr, cancellationToken)
      .then((result: any) => {
        if (isAPIError(result)) {
          return;
        }

        if (typeof InfoWorkloadName === DataType.STRING) {
          Ls.set(InfoWorkloadName, result);
        }

        setWorkloadData(result);
        populateMetricData(result);
      })
      .catch((error: any) => {
        processApiError(error, NotificationMessages.errorOnReceivingWorkloadInfo);
      })
      .finally(() => {
        setBusy(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (workloadData.length !== 0) {
      populateMetricData(workloadData);
    }
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const openItem = (index: number): void => {
    toggleItem(selectedItem === index ? -1 : index);
  };

  const populateMetricData = (workload: MetricsBySelectedColor[]): void => {
    const data = Object.keys(workload).map((item, index) => (
      <tbody key={index}>
        <tr key={`tr-${index}`} className="workload-table-row">
          <td className="font-weight-bold">{(workload as { [index: string]: any })[item as string].metricName}</td>
          {testedInstance.length > 50 ? (
            <td>
              <Tooltip content={testedInstance as string} id={tooltipId}>
                <span aria-describedby={tooltipId} className="text">
                  {`${testedInstance.substring(0, 49)} ...`}
                </span>
              </Tooltip>
            </td>
          ) : (
            <td>{testedInstance}</td>
          )}
          <td className="text-nowrap">
            {Object.keys((workload as { [index: string]: any })[item as string].experimentUnits).map(
              (experimentUnit, experimentUnitIndex) => (
                <span key={experimentUnitIndex}>
                  {`${(workload as { [index: string]: any })[item as string].experimentUnits[experimentUnit as string].groupId}: ${
                    (workload as { [index: string]: any })[item as string].experimentUnits[experimentUnit as string].experimentUnits
                  }   `}
                </span>
              ),
            )}
          </td>
          <td className={getClassName((workload as { [index: string]: any })[item as string].color)}>
            <b className="text-light">
              {typeof (workload as { [index: string]: any })[item as string].confidenceScore === 'number'
                ? `${((workload as { [index: string]: any })[item as string].confidenceScore * 100).toFixed(2)} %`
                : (workload as { [index: string]: any })[item as string].confidenceScore}
            </b>
          </td>
          <td className="text-center">
            <Icon
              iconName={+selectedItem === index ? 'ChevronUp' : 'ChevronDown'}
              className="ChevronDown clickable"
              onClick={() => {
                openItem(index);
              }}
            />
          </td>
        </tr>
        <tr key={`item-${index}`} className={`item ${+selectedItem === index ? 'open' : ''}`}>
          <td colSpan={5} className="subset">
            <WorkloadInfoSubSet workload={(workload as { [index: string]: any })[item as string]} />
          </td>
        </tr>
      </tbody>
    ));

    setWorkloadTableData(data as any[]);
  };

  return (
    <div className="air-wrapper">
      <div className="result-container">
        <h4 className="text-left all-in-one">
          <Link to={{ pathname: '/experimentResult' }} className="breadcrumbs workload-links">
            {Labels.experiments}
          </Link>
          {TITLE_DIVIDER}
          <Link
            to={{ pathname: `${Routes.scenario.link}/${InfoExperimentName}/${revision}` }}
            className="breadcrumbs workload-links links-left-margin"
          >
            {`${InfoExperimentName} (${revision})`}
          </Link>
          {TITLE_DIVIDER}
          <Link
            to={{
              pathname: `${Routes.workloadProfile.link}/${InfoWorkloadProfile}/${workloadProfileFriendly}/${InfoExperimentName}/${revision}`,
            }}
            className="breadcrumbs workload-links links-left-margin"
          >
            {workloadProfileFriendly}
          </Link>
          {TITLE_DIVIDER}
          {` ${InfoWorkloadName}`}
        </h4>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          {isBusy ? (
            <LoadingSpinner />
          ) : (
            <Table striped className="text-left metric-text-table">
              <thead>
                <tr>
                  <th>{TableHeaders.testBenchmarks}</th>
                  <th>{TableHeaders.experimentProperty}</th>
                  <th className="text-nowrap">{TableHeaders.metricCount}</th>
                  <th className="text-nowrap margin-left">{TableHeaders.confidenceScores}</th>
                  <th />
                </tr>
              </thead>
              {workloadTableData}
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};
