import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, IconButton, Label, SpinnerSize, TextField } from '@fluentui/react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { FailGroupIds, KeyTextPair, Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import { OSImages } from '@/partials/LabComponents/OSImages/OSImages';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { TagPickerComponent } from '@/partials/TagPicker/TagPickerComponent';
import { TagPickerConstants } from '@/partials/TagPicker/TagPickerConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { CreateLabVCType } from './CreateLabType';

import '@/styles/Fluent.css';
import style from './CreateLab.module.css';

const CreateLabTemplateFC: React.FC<CreateLabVCType> = (props: CreateLabVCType) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDarkMode } = appSettingsStore;
  const { createLabStore, systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;
  const {
    companyValue,
    companies,
    osImageValue,
    osImages,
    labUsers,
    setLabNameValue,
    setAdminsValue,
    setOsImageValue,
    isCreatingLab,
  } = createLabStore;

  // Props Const
  const { onCompanyChange, isValidEmailAddress } = props;

  // State Const
  const [open, setOpen] = React.useState(false);

  return (
    <div className={style['create-lab-wrapper']}>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <div className={style['container']}>
        <div className={style['row']}>
          <Label className={style['label']}>{t(Labs.LAB_NAME, { ns: NS.LABS })}</Label>
          <TextField
            required
            type="text"
            title={t(Labs.LAB_NAME, { ns: NS.LABS })}
            onChange={(e) => setLabNameValue((e.target as any).value)}
            className={style['input']}
          />
        </div>
        <div className={style['row']}>
          <Label className={style['label']}>{t(Labs.COMPANY, { ns: NS.LABS })}</Label>
          <Dropdown
            required
            selectedKey={companyValue}
            placeholder={t(Labs.SELECT_COMPANY, { ns: NS.LABS })}
            title={t(Labs.COMPANY, { ns: NS.LABS })}
            options={companies}
            onChange={(event: React.FormEvent<HTMLDivElement>, item: KeyTextPair) => onCompanyChange(event, item)}
            className={style['input']}
          />
        </div>

        <div className={style['row']}>
          <Label className={style['label']}>{t(Labs.ADMIN, { ns: NS.LABS })}</Label>

          <TagPickerComponent
            data={labUsers}
            size={TagPickerConstants.SIZE_MEDIUM}
            isDarkMode={isDarkMode}
            onChange={(event, newValue) => {
              setAdminsValue(newValue);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
              return isValidEmailAddress(event, newValue);
            }}
            failMessageGroupId={FailGroupIds.LAB}
            showMessageInPopup={true}
          />
        </div>
        <div className={style['row']}>
          <Label className={style['label']}>{t(Labs.OS_IMAGE, { ns: NS.LABS })}</Label>
          <Dropdown
            required
            selectedKey={osImageValue}
            placeholder={t(Labs.SELECT_OS_IMAGE, { ns: NS.LABS })}
            title={t(Labs.SELECT_OS_IMAGE, { ns: NS.LABS })}
            options={osImages}
            className={style['input']}
            onChange={(event: React.FormEvent<HTMLDivElement>, item: KeyTextPair) => setOsImageValue(item.key)}
          />
        </div>
        <div className={style['imagerow']}>
          <DefaultButton className={style['link-button']} onClick={() => setOpen(!open)}>
            {t(Labs.VIEW_ALL_IMAGES, { ns: NS.LABS })}
          </DefaultButton>
          <IconButton
            className={style['icon-button']}
            iconProps={{ iconName: open ? SystemIcons.ARROW_DOWN : SystemIcons.ARROW_UP }}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
      {open && (
        <div className={style['os-images']}>
          <OSImages setOpen={setOpen} open={open} />
        </div>
      )}
      {isCreatingLab && (
        <div className={style['overlay-spinner']}>
          <LoadingSpinner size={SpinnerSize.medium} />
        </div>
      )}
    </div>
  );
};

export const CreateLabTemplate = observer(CreateLabTemplateFC);
