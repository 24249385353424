import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

enum NavLinkType {
  Item = 'NavLinkItem',
  Divider = 'Divider',
}

type NavLinksFCType = {
  location: string;
  isSidebarCollapsed: boolean;
  children: NavLinkItemType[];
};

type NavLinksFTType = {
  location: string;
  level: number;
  isSidebarCollapsed: boolean;
  children: NavLinkItemType[];

  rootLinks: NavLinkItemType[];
  onToggle: ActionTypeWithParam;
  onClick: ActionTypeWithParam;
};

export { NavLinkType, NavLinksFCType, NavLinksFTType };
