import React from 'react';
import { Tooltip, YAxis } from 'recharts';
import { Margin } from 'recharts/types/util/types';

import GraphBaseTemplate from './GraphBaseTemplate';
import { BaseChartProps, GraphBaseTemplateType, GraphBaseType } from './GraphBaseType';

const GraphBaseViewController: React.FC<GraphBaseType> = (props: GraphBaseType): React.ReactElement => {
  const chartProps: BaseChartProps = props.chartProp;

  if (!chartProps.cartesianGridProp) {
    chartProps.cartesianGridProp = {
      stroke: '#f5f5f5',
    };
  }

  if (!chartProps.responsiveContainerProp) {
    chartProps.responsiveContainerProp = {
      width: '100%',
      height: 300,
    };
  }

  const marginBox: Margin = {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
  };

  const renderYAxis = chartProps.yAxisProp ? <YAxis {...chartProps.yAxisProp} /> : <YAxis />;
  const renderTooltip = chartProps.tooltipProps ? <Tooltip {...chartProps.tooltipProps} /> : <Tooltip />;

  const templateProps: GraphBaseTemplateType = {
    data: props.data,
    chartProp: chartProps,
    marginBox,
    children: props.children,
    renderYAxis,
    renderTooltip,
  };

  return <GraphBaseTemplate {...templateProps} />;
};

export default GraphBaseViewController;
