/* eslint-disable max-len */
import React from 'react';
import * as qs from 'query-string';

import { Navigation } from '@/constants/NavigationConstants';

import '@/styles/Labs.css';

export const ReportView = (props: any) => {
  const routeString = `${Navigation.LABS.VIEW_REPORT}/url`;
  const { [routeString]: url } = qs.parse(window.location.pathname);

  return (
    <iframe
      title="powerBIReport"
      id="powerBIReport"
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | string[] | null' is not... Remove this comment to see the full error message
      src={decodeURIComponent(url)}
      frameBorder="0"
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
      allowFullScreen="true"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    />
  );
};
