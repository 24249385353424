// Here we store our Widget Library data.
// All information here is used to render our Widget Tray.
// The keys are used to map a user's Widgets to our Widget Library.

import { WidgetLayoutType } from '@/partials/WidgetPanel/WidgetPanelTypes';

type WidgetPanelLibraryType = {
  key: string;
  name: string /* Display Name */;
  description: string;
  type: 'chart' | 'table' | 'clock' | 'experiment-summary' | 'other';
  layout: WidgetLayoutType;
  icon?: any /* Fluent Icon */;
  imageSrc?: string /* Preview Image */;
  otherProperties?: any /* Reserved for future use */;
  data?: any /* Data used for demonstration use */;
};

const MockWidgetPanelLibraryData: WidgetPanelLibraryType[] = [
  {
    key: 'clock-1',
    name: 'Clock',
    description: 'Display a digital clock from the time zone of your choice.',
    icon: 'Clock',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'clock',
    layout: { i: 'clock-1', w: 4, h: 4, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'table-type-1',
    name: 'Table Widget 1',
    description: 'This is the 8x20 Table Widget. This widget does many things.',
    icon: 'Table',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'table',
    layout: { i: 'table-type-1', h: 8, w: 20, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'table-type-2',
    name: 'Table Widget 2',
    description: 'This is the 4x12 Table Widget. This widget does many things.',
    icon: 'TableComputed',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'table',
    layout: { i: 'table-type-2', h: 4, w: 12, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'experiment-summary-type-1',
    name: 'Experiment Summary Widget 1',
    description: 'This is the 6x14 Experiment Summary Widget. This widget does many things.',
    icon: 'Assign',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'experiment-summary',
    layout: { i: 'experiment-summary-type-1', h: 6, w: 14, minH: 6, minW: 14, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'graph-type-1',
    name: 'Chart Widget 1',
    description: 'This is the 6x6 Chart Widget. This widget does many things.',
    icon: 'Chart',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'chart',
    layout: { i: 'graph-type-xyz', w: 6, h: 6, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'graph-type-2',
    name: 'Chart Widget 2',
    description: 'This is the 8x8 Chart Widget. This widget does many things.',
    icon: 'AreaChart',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'chart',
    layout: { i: 'graph-type-aaa', w: 8, h: 8, isDraggable: true },
    otherProperties: {},
    data: [],
  },
  {
    key: 'graph-type-3',
    name: 'Chart Widget 3',
    description: 'This is the 12x12 Chart Widget. This widget does many things.',
    icon: 'BarChartHorizontal',
    // imageSrc: 'https://via.placeholder.com/80x40',
    type: 'chart',
    layout: { i: 'graph-type-supergraph', w: 12, h: 12, isDraggable: true },
    otherProperties: {},
    data: [],
  },
];

export { MockWidgetPanelLibraryData, WidgetPanelLibraryType };
