import React from 'react';
import { ISpinnerProps, Spinner } from '@fluentui/react/lib/Spinner';

import '@/styles/Air.css';

const defaultSpinnerStyles = (() => {
  const spinnerSize = 50;

  return {
    root: {
      marginTop: '5px',
      height: '100%',
    },
    circle: {
      width: spinnerSize,
      height: spinnerSize,
      borderWidth: 2,
    },
  };
})();

export const LoadingSpinner = (props: ISpinnerProps): JSX.Element => (
  <Spinner label={props.label} labelPosition="right" styles={{ ...defaultSpinnerStyles, ...props.styles }} />
);
