/* eslint-disable prefer-const */
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { DataKeys } from '@/constants/AirConstants';
import { Stats } from '@/types/_air';

import '@/styles/Air.css';

interface Props {
  aGroupProp: Stats;
  bGroupProp: Stats;
}

export const WorkloadGraph = ({ aGroupProp, bGroupProp }: Props): JSX.Element => {
  const [series, setSeries] = React.useState([
    {
      name: 'A Group Mean',
      data: [] as any[],
    },
    {
      name: 'B Group Mean',
      data: [] as any[],
    },
    {
      name: 'A Group',
      data: [] as any[],
    },
    {
      name: 'B Group',
      data: [] as any[],
    },
  ]);
  const [options, setOptions] = React.useState<ApexOptions>({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          customIcons: [],
        },
      },
      type: 'line',
      height: 300,
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 5, 5],
      curve: 'straight',
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: [0.8, 0.8, 1, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: [0, 0, 5, 5],
      hover: {
        size: 10,
      },
    },
    tooltip: {
      y: {
        formatter(val: number): string {
          return val.toString();
        },
      },
    },
  });

  React.useEffect(() => {
    const aData = [];
    const aMean = [];
    const bData = [];
    const bMean = [];
    const category = [];

    for (const key in aGroupProp) {
      let aMeanVal;

      if (key !== DataKeys.experimentGroup) {
        if (key === DataKeys.mean) {
          aMeanVal = (aGroupProp as { [index: string]: any })[key as string];
        } else {
          if (aMeanVal !== undefined) {
            aMean.push(aMeanVal.toFixed(2));
          }

          aData.push((aGroupProp as { [index: string]: any })[key as string].toFixed(2));
        }

        if (key !== DataKeys.mean) {
          category.push(key);
        }
      }
    }

    for (const key in bGroupProp) {
      let bMeanVal;

      if (key !== DataKeys.experimentGroup) {
        if (key === DataKeys.mean) {
          bMeanVal = (bGroupProp as { [index: string]: any })[key as string];
        } else {
          if (bMeanVal !== undefined) {
            bMean.push(bMeanVal.toFixed(2));
          }

          bData.push((bGroupProp as { [index: string]: any })[key as string].toFixed(2));
        }
      }
    }

    setSeries([
      {
        name: 'A Group Mean',
        data: aMean,
      },
      {
        name: 'B Group Mean',
        data: bMean,
      },
      {
        name: 'A Group',
        data: aData,
      },
      {
        name: 'B Group',
        data: bData,
      },
    ]);
    setOptions({
      chart: {
        foreColor: 'var(--textColor)',
        background: 'var(--backgroundColor)',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            customIcons: [],
          },
        },
        type: 'line',
        height: 300,
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 5, 5],
        curve: 'straight',
      },
      xaxis: {
        categories: category,
      },
      yaxis: {
        title: {
          text: '',
        },
      },
      fill: {
        opacity: [0.8, 0.8, 1, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      colors: ['#A3CF62', '#43408E', '#1374BC', '#F79548'],
      markers: {
        size: [0, 0, 5, 5],
        hover: {
          size: 10,
        },
      },
      tooltip: {
        y: {
          formatter(val: number): string {
            return val.toString();
          },
        },
      },
    });
  }, [aGroupProp, bGroupProp]);

  return (
    <div>
      <Chart options={options} series={series} type="line" width="900" />
    </div>
  );
};
