import { EventHubProducerClient } from '@azure/event-hubs';
import moment from 'moment';
import { NIL, v4 as uuidv4 } from 'uuid';

import { settingsRequestService } from '../request-services';
import { SeverityLevel } from './SeverityLevel';

class MonitoringService {
  producerClient!: EventHubProducerClient;

  connectionString!: string;

  eventHubName!: string;

  operationParentId!: string;

  applicationName!: string;

  sdkVersion: string;

  logToConsole: boolean;

  constructor() {
    this.sdkVersion = '5.5.0';
    this.logToConsole = false;
  }

  public async initialize(): Promise<void> {
    const data = await settingsRequestService.getEventHubSettings();

    this.connectionString = data.connectionString;
    this.eventHubName = data.eventHub;
    this.operationParentId = data.clientParentOperationID || NIL;
    this.applicationName = data.clientApplicationName || 'Juno.Portal.Website.ClientApp';

    this.producerClient = new EventHubProducerClient(this.connectionString, this.eventHubName);
  }

  public async logEvent(
    name: string,
    severityLevel: SeverityLevel = SeverityLevel.Information,
    eventType = 'info',
    customDimensions?: any,
  ): Promise<void> {
    return this.log(name, severityLevel, eventType, customDimensions);
  }

  public async logError(
    error: Error,
    severityLevel: SeverityLevel = SeverityLevel.Error,
    eventType = 'error',
    customDimensions?: any,
  ): Promise<void> {
    return this.log(`${error.name}: ${error.message}`, severityLevel, eventType, customDimensions || error);
  }

  public async logTrace(
    message: string,
    severityLevel: SeverityLevel = SeverityLevel.Verbose,
    eventType = 'trace',
    customDimensions?: any,
  ): Promise<void> {
    return this.log(message, severityLevel, eventType, customDimensions);
  }

  private async log(message: string, severityLevel: SeverityLevel, eventType: string, customDimensions?: any): Promise<void> {
    if (this.logToConsole) {
      this.logConsole(severityLevel, eventType, message, customDimensions);

      return;
    }

    const operationId = this.getOrCreateOperationId();

    try {
      const batchOptions = {};

      const batch = await this.producerClient?.createBatch(batchOptions);

      batch?.tryAdd({
        body: {
          timestamp: moment.utc(Date.now()).format(),
          message,
          severityLevel,
          itemType: eventType,
          customDimensions,
          operation_Id: operationId,
          operation_ParentId: this.operationParentId,
          sdkVersion: this.sdkVersion,
          appName: this.applicationName,
          appHost: window.location.origin,
        },
      });

      await this.producerClient?.sendBatch(batch);
    } catch (error: any) {
      this.logConsole(SeverityLevel.Error, error);
    }
  }

  private getOrCreateOperationId = (): string => {
    const storage = window.sessionStorage;

    let operationId = storage.getItem('telemetry_operation_Id');

    if (operationId) {
      return operationId;
    }

    operationId = uuidv4();
    storage.setItem('telemetry_operation_Id', operationId || '');

    return operationId || '';
  };

  private logConsole = (severity: SeverityLevel, ...args: any[]): void => {
    /* eslint-disable no-console */
    switch (severity) {
      case SeverityLevel.Warning:
        console.warn('Warning', ...args);
        break;
      case SeverityLevel.Information:
        console.info('Info', ...args);
        break;
      case SeverityLevel.Error:
        console.error('Error', ...args);
        break;
      case SeverityLevel.Critical:
        console.error('Fatal Error', ...args);
        break;
      default:
        console.debug('Verbose', ...args);
        break;
    }
    /* eslint-enable */
  };
}

export const monitoringService = new MonitoringService();
