import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Label, MessageBarType, PrimaryButton, SpinnerSize, TextField } from '@fluentui/react';
import { t } from 'i18next';
import format from 'string-template';

import { Buttons } from '@/constants/LabsConstants';
import { FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { Common, Default, Labs } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { getMachineDetails, getSARMIPAddress } from './AddEditSARMIPAddressModel';

import style from './AddEditSARMIPAddress.module.css';

interface SARMIPProps {
  hideModal: () => void;
}

const AddEditSARMIPAddressFC: React.FC<SARMIPProps> = (props: SARMIPProps) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labsControlStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
  const { setIsSARMAddEditClose, selectedMachines, selectedLabId } = labsControlStore;

  const [SARMIPAddress, setSARMIPAddress] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [agentIds, setAgentIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (selectedMachines) {
      const machines: string[] = getMachineDetails(selectedLabId, selectedMachines);

      setAgentIds(machines);
      getSARMIP(machines);
    }
  }, [selectedMachines]);

  const getSARMIP = async (machines: string[]) => {
    setIsLoading(true);

    try {
      const response: any = await getSARMIPAddress(selectedLabId, machines);

      if (response) {
        setSARMIPAddress(response);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateIPAddress = (): boolean => {
    let message = '';

    if (SARMIPAddress.length === 0) {
      message = t(Common.ENTER_FIELD, { ns: NS.COMMON });
    } else {
      const ipPattern =
        /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
      const isValidIP = ipPattern.test(SARMIPAddress);

      if (!isValidIP) {
        message = t(Common.INVALID_IP, { ns: NS.COMMON });
      }
    }

    if (message) {
      const failMessage: SystemMessageType = {
        message: message,
        type: MessageBarType.error,
        groupId: FailGroupIds.SARM_IP,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);
      return false;
    }

    return true;
  };

  const saveSARMIPAddress = async (event: any): Promise<void> => {
    event.preventDefault();

    const isValidIP = validateIPAddress();

    if (isValidIP) {
      const labEntities: string[] = agentIds;

      setIsLoading(true);
      labEntities.push(SARMIPAddress);

      await ganymedeLabRequestService
        .saveSARMIPDetails(labEntities)
        .then((response: any) => {
          if (response) {
            const sarmAddMessage = format(t(Labs.SARM_ADD_SUCCESS_TEMPLATE, { ns: NS.LABS }), {
              item: selectedMachines[0]?.Name,
            });

            const successMessage: SystemMessageType = {
              message: sarmAddMessage,
              type: MessageBarType.success,
              groupId: SuccessGroupIds.LAB_CONTROL,
            };

            addGlobalMessage(successMessage);
          }

          labEntities.pop();

          setSARMIPAddress(response);
          setIsLoading(true);
          setIsSARMAddEditClose();
        })
        .catch((error) => {
          console.error('Error while saving SARM IP :', error);
          labEntities.pop();

          setIsLoading(true);
        });
    }
  };

  return (
    <div className={style['sarm-wrapper']}>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      {isLoading && (
        <div className={style['overlay-spinner']}>
          <LoadingSpinner size={SpinnerSize.medium} />
        </div>
      )}
      <div>
        <div className={style['row']}>
          <Label className={style['label']}>{t(Default.IP_ADDRESS, { ns: NS.DEFAULT })}</Label>
          <TextField
            required
            type="text"
            title={t(Default.IP_ADDRESS, { ns: NS.DEFAULT })}
            value={SARMIPAddress}
            onChange={(e) => setSARMIPAddress((e.target as any).value)}
            className={style['input']}
          />
        </div>
        <div className={`${style['row']}`}>
          <PrimaryButton className={`${style['button']}`} onClick={saveSARMIPAddress}>
            {t(Common.SAVE, { ns: NS.COMMON })}
          </PrimaryButton>
          <DefaultButton className={`${style['button']}`} onClick={props.hideModal}>
            {Buttons.cancel}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export const AddEditSARMIPAddress = observer(AddEditSARMIPAddressFC);
