import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pivot } from '@fluentui/react';
import { PivotItem } from '@fluentui/react';
import { t } from 'i18next';

import { LogsViewPanelVCType } from '@/components/LogsViewPanel/LogsViewPanelTypes';
import { Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import { LogsView } from '@/constants/TranslationConstants';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import style from '@/components/LogsViewPanel/LogsViewPanel.module.css';

const LogsViewPanelTemplateFC: React.FC<LogsViewPanelVCType> = (props: LogsViewPanelVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, resultExplorerStore, systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;
  const { setTabMemory, isLogError } = appSettingsStore;
  const { selectedCompanyValue, isSummary, selectedId } = resultExplorerStore;

  const { components, userSettings, activeTab, commandBarItems } = props;

  const pageName = TabMemoryKeys.LOGS_VIEW;
  const logsHeaderText = t(LogsView.LOGS, { ns: NS.LOGS_VIEW });

  const filePreview = !isLogError && (
    <>
      <div className={style['vertical-divider']}></div>
      <div className={style['logs-view-container']}>
        <FilePreview companyName={selectedCompanyValue} />
      </div>
    </>
  );

  const summaryContent = isSummary && (
    <Pivot defaultSelectedKey={activeTab} onLinkClick={(item) => setTabMemory(pageName, item.props.itemKey)}>
      {components.map(({ headerText, component: Component }, index) => (
        <PivotItem
          key={index}
          itemKey={headerText}
          headerText={headerText}
          className={headerText === logsHeaderText ? style['tab-container'] : ''}
        >
          <Component id={selectedId} companyName={selectedCompanyValue} />
          {headerText === logsHeaderText && filePreview}
        </PivotItem>
      ))}
    </Pivot>
  );

  const nonSummaryContent = !isSummary && (
    <div className={style['tab-container']}>
      <RawLogsTreeView id={selectedId} companyName={selectedCompanyValue} showLogText={true} />
      {filePreview}
    </div>
  );

  const content = (
    <>
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <PageCommandBar items={commandBarItems}></PageCommandBar>
      {summaryContent || nonSummaryContent}
    </>
  );

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

const LogsViewPanelTemplate = observer(LogsViewPanelTemplateFC);

export default LogsViewPanelTemplate;
