import moment from 'moment';
import { singleton } from 'tsyringe';

import { LayoutItemType } from '@/components/ReactGrid/ReactGridTypes';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Pagination, ReadingPanePositionType, SystemConstants } from '@/constants/SystemConstants';
import LocalStorageService from '@/services/LocalStorageService';
import { env } from '@/utils/Env';
import { loggingConfig } from '@/utils/Env';

// Our Mock Data which will ultimately be replaced with API data.
import { MockLayoutItemData } from '@/mocks/Dashboards/LayoutData';
import { MockWidgetData, WidgetMappingType } from '@/mocks/Dashboards/LayoutWidgets';
import { MockWidgetPanelLibraryData, WidgetPanelLibraryType } from '@/mocks/WidgetLibrary/LibraryData';

import packageJson from '../../package.json';

@singleton()
export default class AppSettingsService {
  // Ganymede System Settings keys - All keys have the _KEY suffix.
  public static readonly ADVANCED_MODE_KEY: string = 'advanced-mode';
  public static readonly AIR_HIDDEN_KEY: string = 'hide-air';
  public static readonly APP_SETTINGS_KEY: string = 'app-settings';
  public static readonly CONFIG_FILE_NAME: string = 'config.settings.json';
  public static readonly COMPRESSED_MODE_KEY: string = 'compressed-mode';
  public static readonly CONSOLE_LOGGING_KEY: string = 'console-logging';
  public static readonly CONSOLE_SEVERITY_LEVEL_KEY: string = 'console-severity-level';
  public static readonly COPILOT_OPEN_KEY: string = 'copilot-open';
  public static readonly DARK_MODE_KEY: string = 'dark-mode';
  public static readonly DEBUG_MODE_KEY: string = 'debug-mode';
  public static readonly DEVELOPER_MODE_KEY: string = 'developer-mode';
  public static readonly EDIT_MODE_KEY: string = 'edit-mode';
  public static readonly LABS_HIDDEN_KEY: string = 'hide-labs';
  public static readonly LAYOUT_KEY: string = 'layout';
  public static readonly MESSAGEBAR_SEEN_KEY: string = 'messagebar-seen';
  public static readonly MESSAGE_BUTTONS_KEY: string = 'messagebuttons';
  public static readonly MOCK_DATA_KEY: string = 'mock-data';
  public static readonly PAGE_LOADING_KEY: string = 'page-loading';
  public static readonly PAGE_SIZE_KEY: string = 'page-limit';
  public static readonly PORTAL_KEY: string = 'portal';
  public static readonly READING_PANE_POSITION_KEY: string = 'preview-position';
  public static readonly OFFLINE_MODE_KEY: string = 'offline-mode';
  public static readonly OUTLOOK_MODE_KEY: string = 'outlook-mode';
  public static readonly SETTINGS_OPEN_KEY: string = 'settings-open';
  public static readonly SESSION_PAGE_SIZE_KEY: string = 'session-page-limit';
  public static readonly SIDEBAR_STATE_KEY: string = 'sidebar-collapsed';
  public static readonly SORTED_COLUMN_MEMORY_KEY: string = 'sorted-column-memory';
  public static readonly TAB_MEMORY_KEY: string = 'tab-memory';
  public static readonly TELEMETRY_SEVERITY_LEVEL_KEY: string = 'telemetry-severity-level';
  public static readonly WIDGET_MAPPINGS_KEY: string = 'widget-mappings';
  public static readonly VERSION_KEY: string = 'version';

  // System-wide Default values - All keys have the DEFAULT_ prefix.
  public static readonly DEFAULT_FALSE_VALUE: boolean = false;
  public static readonly DEFAULT_PAGE_SIZE: number = Pagination.DEFAULT_PAGE_SIZE;
  public static readonly DEFAULT_PORTAL_VALUE: string = SystemConstants.DEFAULT_PORTAL;
  public static readonly DEFAULT_READING_PAGE_POSITION: ReadingPanePositionType = ReadingPanePositionType.BOTTOM;
  public static readonly DEFAULT_SESSION_PAGE_SIZE: number = Pagination.DEFAULT_SESSION_PAGE_SIZE;
  public static readonly DEFAULT_SORTED_COLUMN_MEMORY_VALUE = {};
  public static readonly DEFAULT_TAB_MEMORY_VALUE = {};
  public static readonly DEFAULT_TRUE_VALUE: boolean = true;

  // Ganymede Filters for Experiments, Sessions, and Steps - All keys have the FILTER_ prefix.
  public static readonly FILTER_END_DATE_KEY = 'end-date-value';
  public static readonly FILTER_EXPERIMENTS_GROUPBY_KEY = 'groupby-value';
  public static readonly FILTER_IP_ADDRESS_KEY = 'ip-address-values';
  public static readonly FILTER_IPADDRESS_KEY = 'ipaddress-values';
  public static readonly FILTER_LAST_RUNTIME_KEY = 'last-runtime-value';
  public static readonly FILTER_LOCATION_KEY = 'location-value';
  public static readonly FILTER_NAME_SEARCH_KEY = 'search-value';
  public static readonly FILTER_SESSIONS_GROUPBY_KEY = 'sessions-groupby-value';
  public static readonly FILTER_START_DATE_KEY = 'start-date-value';
  public static readonly FILTER_STATUS_KEY = 'status-values';
  public static readonly FILTER_STEPS_GROUPBY_KEY = 'steps-groupby-value';

  // AIR related Keys and Filters.
  public static readonly AIR_COMPARE_EXPERIMENTS_KEY: string = 'air-experiments';
  public static readonly AIR_NOTIFICATIONS_STATE_KEY: string = 'air-notifications';
  public static readonly FILTER_AIR_RESULTS_END_DATE_KEY: string = 'filter-air-results-end-date-value';
  public static readonly FILTER_AIR_RESULTS_SEARCH_KEY: string = 'filter-air-results-search-value';
  public static readonly FILTER_AIR_RESULTS_START_DATE_KEY: string = 'filter-air-results-start-date-value';
  public static readonly FILTER_AIR_RESULTS_TIME_KEY: string = 'filter-air-results-time-value';
  public static readonly FILTER_MANAGE_AIR_EXPERIMENTS_END_DATE_KEY: string = 'filter-manage-air-experiments-end-date-value';
  public static readonly FILTER_MANAGE_AIR_EXPERIMENTS_SEARCH_VALUE: string = 'filter-manage-air-experiments-search-value';
  public static readonly FILTER_MANAGE_AIR_EXPERIMENTS_START_DATE_KEY: string = 'filter-manage-air-experiments-start-date-value';
  public static readonly FILTER_MANAGE_AIR_EXPERIMENTS_TIME_KEY: string = 'filter-manage-air-experiments-time-value';
  public static readonly FILTER_MANAGE_AIR_EXPERIMENTS_TEAM_NAME: string = 'filter-manage-air-experiments-team-name-value';

  // Lab
  public static readonly FILTER_LABS_SEARCH_KEY = 'labs-search-value';

  // Result Explorer
  public static readonly FILTER_ALLRESULTS_ENDDATE_KEY: string = 'filter-allresults-enddate';
  public static readonly FILTER_ALLRESULTS_RUNTIME_KEY: string = 'filter-allresults-last-runtime';
  public static readonly FILTER_ALLRESULTS_STARTDATE_KEY: string = 'filter-allresults-startdate';
  public static readonly FILTER_RESULT_EXPLORER_TAB_KEY: string = 'filter-result-explorer-active-tab';
  public static readonly FILTER_RESULT_EXPLORER_COMPANY_KEY: string = 'filter-result-explorer-company-value';
  public static readonly FILTER_RESULT_EXPLORER_SEARCH_KEY: string = 'filter-result-explorer-search-value';

  // Logging levels statements.
  public static readonly DEFAULT_CONSOLE_LOGGING_VALUE: boolean = loggingConfig.CONSOLE_LOGGING;
  public static readonly DEFAULT_CONSOLE_SEVERITY_LEVEL_VALUE: number = loggingConfig.CONSOLE_SEVERITY_LEVEL;
  public static readonly DEFAULT_TELEMETRY_SEVERITY_LEVEL_VALUE: number = loggingConfig.TELEMETRY_SEVERITY_LEVEL;

  // Mock data declarations.
  public static readonly DEFAULT_LAYOUT_VALUE: LayoutItemType[] = MockLayoutItemData;
  public static readonly DEFAULT_WIDGET_MAPPINGS_VALUE: WidgetMappingType = MockWidgetData;
  public static readonly DEFAULT_WIDGET_VALUE: WidgetPanelLibraryType[] = MockWidgetPanelLibraryData;

  // Filter Edit Column keys.
  public static readonly FILTER_MYRESULTS_COLUMN_KEY = 'filter-myresult-column-key';
  public static readonly FILTER_MYLABRESULTS_COLUMN_KEY = 'filter-mylabresult-column-key';
  public static readonly FILTER_ALLRESULTS_COLUMN_KEY = 'filter-allresult-column-key';

  // Column Editor keys.
  public static readonly EXPERIMENTS_COLUMN_KEY = 'filter-experiments-column-key';
  public static readonly SESSIONS_COLUMN_KEY = 'filter-sessions-column-key';
  public static readonly LABS_COLUMN_KEY = 'filter-labs-column-key';

  // Lab Details keys
  public static readonly LAB_DETAILS_LAB_KEY = 'filter-lab-details-lab-id';

  // Lab Details - Systems keys
  public static readonly LAB_SYSTEMS_COLUMN_KEY = 'filter-lab-systems-column-key';
  public static readonly LAB_SYSTEMS_IPADDRESS_KEY = 'lab-systems-ip-address-values';
  public static readonly LAB_SYSTEMS_HEALTH_STATUS_KEY = 'lab-systems-health-status-values';
  public static readonly LAB_SYSTEMS_MACHINE_TYPE_KEY = 'lab-systems-machine-type-values';
  public static readonly LAB_SYSTEMS_GROUPBY_KEY = 'lab-systems-groupby-value';

  constructor() {
    return;
  }

  public get environment() {
    return env;
  }

  public get lastUpdated(): number {
    return packageJson.lastUpdated;
  }

  public get lastUpdatedDate(): string {
    return moment(this.lastUpdated).format(DateFormats.LONG_DATE_FORMAT);
  }

  public get version(): string {
    return packageJson.version;
  }

  public loadValueFromStorage(localStorage: LocalStorageService, key: string): string | null {
    return localStorage.getValue(key);
  }

  public loadNumberFromStorage(localStorage: LocalStorageService, key: string): number {
    return localStorage.getValueAsNumber(key);
  }

  public loadBooleanFromStorage(localStorage: LocalStorageService, key: string): boolean {
    return localStorage.getValueAsBoolean(key);
  }

  public loadStorageArray(localStorage: LocalStorageService, key: string): string[] {
    const messagesOnLoad: string | null = localStorage.getValue(key) || null;

    return messagesOnLoad?.split(',') || [];
  }

  public storageArrayHasValue(localStorage: LocalStorageService, key: string, value: string): boolean {
    return this.loadStorageArray(localStorage, key).includes(value);
  }

  public addToStorageArray(localStorage: LocalStorageService, key: string, value: string): boolean {
    const list: string[] = this.loadStorageArray(localStorage, key);

    if (!this.loadStorageArray(localStorage, key).includes(value)) {
      list.push(value);
      list.sort();
      localStorage.setValue(key, list.join(','));

      return true;
    }

    return false;
  }

  // This extracts the name of the portal from the path. This means we expect the path to start with the name
  // of the portal. For example, /air/create/experiments should return "air" for the portal root value.
  public getPortalRootFromPath(path: string) {
    const portalAlias = (path.startsWith('/') ? path.split('/')[1] : path).toLowerCase();

    // Make sure to return a valid portal, if it isn't in the Portal list.
    const portal = portalAlias in SystemConstants.ALIAS_MAPPINGS ? portalAlias : SystemConstants.DEFAULT_PORTAL;

    return portal;
  }
}
