import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { DrawerBody, InlineDrawer, useRestoreFocusSource } from '@fluentui/react-components';

import { OSImageType } from '@/components/CreateLab/CreateLabType';
import config from '@/components/LabList/LabList.config.json';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import style from './OSImages.module.css';

const OSImagesFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { createLabStore } = rootStore;
  const { osImageFilteredList, setOsImageValue } = createLabStore;

  const restoreFocusSourceAttributes = useRestoreFocusSource();

  const selectRow = (item: Selection) => {
    const selectedRowItems: IObjectWithKey[] = item.getSelection();
    const rowSelected: boolean = selectedRowItems?.length === 1;

    if (rowSelected) {
      const selection: OSImageType = selectedRowItems[0] as OSImageType;
      const imageValue: string = selection?.imageId.toString();

      setOsImageValue(imageValue);
    }
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      return selectRow(selection);
    },
  });

  return (
    <div className={style['os-images-wrapper']}>
      <InlineDrawer className={style['drawer']} position="bottom" {...restoreFocusSourceAttributes} {...props}>
        <DrawerBody className={style['body']}>
          <TableViewController
            columns={modifiedColumnConfiguration(config.OsImageColumns)}
            items={osImageFilteredList}
            displayColumns={config.OSImageDisplayColumns}
            enableToolBar={false}
            isStickyHeader={true}
            selection={selection}
            selectionMode={SelectionMode.single}
            checkboxVisibility={CheckboxVisibility.hidden}
            selectionPreservedOnEmptyClick={true}
          />
        </DrawerBody>
      </InlineDrawer>
    </div>
  );
};

export const OSImages = observer(OSImagesFC);
