import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { t } from 'i18next';

import { ExperimentType } from '@/components/Experiments/ExperimentsTypes';
import { Defaults } from '@/constants/AirConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import ExperimentInstanceModalTemplate from './ExperimentInstanceModalTemplate';
import { ExperimentInstanceModalUserSettingsType, ExperimentInstanceModalVMType } from './ExperimentInstanceModalTypes';

import styles from './ExperimentInstanceModal.module.css';

interface ExperimentInstanceModalVCProps {
  viewModel: ExperimentInstanceModalVMType;
}

const ExperimentInstanceModalViewControllerFC: React.FC<ExperimentInstanceModalVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { experimentDetailsStore } = rootStore;
  const {
    experimentDetails,
    closeExperimentStepsPopup,
    isExperimentStepPopupOpen,
    closeExperimentModal,
    isExperimentModalOpen,
    isAirExperiment,
  } = experimentDetailsStore;

  const { title, labId, instanceId, loadMessage } = viewModel;
  const [pathname, setPathname] = React.useState<string>('');
  const [localMessage, setLocalMessage] = React.useState<string>(null);

  const status = experimentDetails?.status;

  React.useEffect(() => {
    closeExperimentStepsPopup();
  }, [experimentDetails]);

  const modalFooter = (
    <>
      <PrimaryButton
        type="button"
        onClick={closeExperimentModal}
        text={t('close', { ns: NS.COMMON })}
        title={t('close-modal', { ns: NS.COMMON })}
        ariaLabel={t('close-modal', { ns: NS.COMMON })}
        className={styles.button}
      ></PrimaryButton>

      <Link
        to={{
          pathname: pathname,
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DefaultButton
          iconProps={{ iconName: SystemIcons.OPEN_IN_NEW_WINDOW }}
          type="button"
          text={t('open-new-window', { ns: NS.EXPERIMENTS })}
          title={t('open-new-window', { ns: NS.EXPERIMENTS })}
          ariaLabel={t('open-new-window', { ns: NS.EXPERIMENTS })}
          className={styles.button}
          disabled={!experimentDetails}
        ></DefaultButton>
      </Link>
    </>
  );

  const userSettings: ExperimentInstanceModalUserSettingsType = {
    headerText: title,
    type: PanelType.custom,
    isOpen: isExperimentModalOpen,
    onDismiss: isExperimentStepPopupOpen ? closeExperimentStepsPopup : closeExperimentModal,
    onRenderFooterContent: modalFooter,
    styles: SidePanelStyles.experimentInfo,
  };

  const onDismissMessage: onDismissType = () => {
    setLocalMessage(null);
  };

  const resetPanel = (row: ExperimentType, status: string) => {
    let pathname;
    const labId = row.labId || SystemType.AIR;
    const message = loadMessage || null;

    if (isAirExperiment) {
      // NOTE: This is a temporary solution until we have data that will give us better AIR results.
      const teamName = Defaults.teamName;
      const goalId = experimentDetails?.instanceId || experimentDetails?.id;
      const experimentName = experimentDetails?.json?.['definition']?.['experimentName'];

      pathname = `${Navigation.AIR.SCHEDULER_STATUS}/${teamName}/${goalId}/${experimentName}`;
    } else {
      pathname = `${Navigation.GANYMEDE.EXPERIMENTS}/${row.type}/${labId}/${row.instanceId}`;
    }

    setPathname(pathname);
    setLocalMessage(message); // Clear any messages in this Panel.
    closeExperimentStepsPopup();
  };

  React.useEffect(() => {
    experimentDetails && resetPanel(experimentDetails, status);
  }, [experimentDetails, status, loadMessage, pathname]);

  return (
    <ExperimentInstanceModalTemplate
      title={title}
      labId={labId}
      instanceId={instanceId}
      localMessage={localMessage}
      setLocalMessage={setLocalMessage}
      onDismissMessage={onDismissMessage}
      userSettings={userSettings}
    ></ExperimentInstanceModalTemplate>
  );
};

const ExperimentInstanceModalViewController = observer(ExperimentInstanceModalViewControllerFC);

export default ExperimentInstanceModalViewController;
