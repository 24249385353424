import React from 'react';
import { IconButton, Modal } from '@fluentui/react';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Labels } from '@/constants/LabsConstants';

import '@/styles/Labs.css';

export const BasicModalComponent = (props: any) => {
  const cancelIcon = { iconName: SystemIcons.CANCEL };

  const renderPage = () => {
    if (props.action === Labels.toolkit) {
      return (
        <props.page
          closeModal={props.hideModal}
          testPlanId={props.testPlanId}
          testPlanName={props.testPlanName}
          fetchLists={props.fetchLists}
        />
      );
    }

    if (props.action === Labels.viewLogs) {
      return (
        <props.page closeModal={props.hideModal} isModalOpen={props.isModalOpen} resultCollectionId={props.resultCollectionId} />
      );
    }

    if (props.action === Labels.addRackManager) {
      return (
        <props.page
          closeModal={props.hideModal}
          isModalOpen={props.isModalOpen}
          labId={props.labId}
          fetchLists={props.fetchLists}
        />
      );
    }

    if (props.action === Labels.editRackManager) {
      return (
        <props.page
          closeModal={props.hideModal}
          isModalOpen={props.isModalOpen}
          selectedId={props.selectedId}
          labId={props.labId}
          fetchLists={props.fetchLists}
          selectedIP={props.ipAddress}
          selectedLoginId={props.loginId}
          action={props.action}
        />
      );
    }

    if (props.action === Labels.experimentSteps) {
      return (
        <props.page
          closeModal={props.hideModal}
          isModalOpen={props.isModalOpen}
          selectedItemKey={props.selectedItemKey}
          labId={props.labId}
          isLabOnly={props.isLabOnly}
          action={props.action}
          systemData={props.systemData}
        />
      );
    }

    return <props.page closeModal={props.hideModal} fetchLists={props.fetchLists} />;
  };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId="basicModal"
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.min35Container}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>{props.title}</h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>{renderPage()}</div>
      </Modal>
    </div>
  ) : null;
};
